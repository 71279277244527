import { AppBar, Button, Theme, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router'
import { AdministrationClient } from '../api/clients/identity/AdministrationClient'
import { toastService } from '../services/notifications/ToastService'

export const HomePage = () => {
	const administrationClient = new AdministrationClient()
	const styles = useStyles()

	const history = useHistory()

	return (
		<div>
			<AppBar position="absolute" className={styles.appBar} elevation={0}>
				<Toolbar className={styles.toolbar}>
					<div>
						<Button
							variant="contained"
							onClick={() => {
								administrationClient
									.ValidateDatabase()
									.then((v) => {
										toastService.displayToast({
											message: 'Database validated',
											area: 'global',
											severity: 'success',
										})
									})
									.catch((e) => {
										console.error(e)
										toastService.displayToast({
											message: 'Error validating database',
											area: 'global',
											severity: 'error',
										})
									})
							}}
						>
							Validate DB
						</Button>
					</div>
					<div>
						<Button
							variant="contained"
							onClick={() => {
								history.push('/_login')
							}}
						>
							Sign In
						</Button>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},

	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}))
