
import { AxiosResponse } from 'axios'
import { SelfRegister } from "../../DTO"
import { ResetPasswordModel } from "../../DTOtemp"
import ClientBase from '../ClientBase'

class AnonymousUserClient extends ClientBase {

	/**
	 * Create a new user account
	 * @param createUser 
	 */
	public SelfRegister = (createUser: SelfRegister):
		Promise<AxiosResponse> => {
		return this._api.post('/Users/SelfRegister',
			JSON.stringify(createUser),{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				}
			})
	}

	/**
	 * Resets password and sends a notification on success
	 */
	public ResetPassword(resetPassword: ResetPasswordModel, userEmail: string)
		: Promise<AxiosResponse> {
		return this._api.post(
			`Users/${userEmail}/ResetPassword`,
			JSON.stringify(resetPassword),
			{
				headers: {
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Issues a reset token to the user and sends a notification
	 * @param userEmail
	 */
	public IssueResetToken(userEmail: string)
		: Promise<AxiosResponse> {
		return this._api.post(
			`Users/${userEmail}/IssueResetToken`
		)
	}

}
export default AnonymousUserClient