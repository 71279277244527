

const narrowWidth = 375  // iPhone X
const mediumWidth = 768  // iPad

export const mediaWidths = {
	narrow: { minWidth: 0, maxWidth: narrowWidth },
	medium: { minWidth: narrowWidth + 1, maxWidth: mediumWidth },
	wide: { minWidth: mediumWidth + 1 },
	notNarrow: { minWidth: narrowWidth + 1 },
	notWide: { minWidth: 0, maxWidth: mediumWidth }
}
