import {
	FilterAlt,
	InfoOutlined,
	KeyboardArrowDown,
	KeyboardArrowUp,
	Restore,
	Save,
	Settings,
} from '@mui/icons-material'
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Tooltip,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { cloneDeep } from 'lodash'
import React, { useMemo, useState } from 'react'
import { FormsClient } from '../../../../../../../api/clients/identity'
import { DetailForm } from '../../../../../../../api/DTOtemp'
import { CellDefinition } from '../../../../../../../modules/FormHost/Types/CellDefinition'
import FieldType from '../../../../../../../modules/FormHost/Types/FieldType'
import { toastService } from '../../../../../../../services/notifications/ToastService'
import {
	MergeDefinitionOverrides,
	RemoveUnusedOverrides,
} from '../../../../../../../utils/PdfOverrides'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import { FormSectionProps } from '../FormPage'

type FilterOption = {
	label: string
	value: number
}

function isConfigurable(type: FieldType, tag: string) {
	return (
		(type === FieldType.String && tag !== 'Radio') || // radio buttons are typed as String field type but have "Radio" as tag
		type === FieldType.Number ||
		type === FieldType.DateTime
	)
}

type PDFFormFieldsProps = {
	onUpdateForm: (newForm: DetailForm) => void
} & FormSectionProps

export const PDFFormFields = (props: PDFFormFieldsProps) => {
	const styles = useStyles()
	const [saveDisabled, setSaveDisabled] = useState(true)
	const [formCells, setFormCells] = useState(
		MergeDefinitionOverrides(
			props.form.metadata.cellDefinitions,
			props.form.metadata.cellOverrides,
		),
	)
	const [cellOverrides, setCellOverrides] = useState(
		props.form.metadata.cellOverrides ?? [],
	)

	function handleUpdateCellType(id: string, value: FieldType, mask: string) {
		const newList = formCells.map((cell) => {
			if (cell.id === id) {
				const overriddenCell = cloneDeep(cell)
				// @ts-expect-error: properties is of unknown type, ts doesn't like assigning mask to that
				overriddenCell.properties.mask = mask
				overriddenCell.fieldType = value
				overriddenCell.elementTag =
					value === FieldType.String // text fields have a different elementTag than the FieldType name; unsure why
						? 'Text'
						: FieldType[value]
				const overrideIndex = cellOverrides.findIndex((override) => {
					return override.id === cell.id
				})
				// add updated cell to override array
				if (overrideIndex === -1)
					setCellOverrides([...cellOverrides, overriddenCell])
				else {
					// cell already exists as override, just update it
					cellOverrides[overrideIndex].fieldType = overriddenCell.fieldType
					cellOverrides[overrideIndex].elementTag = overriddenCell.elementTag
					// @ts-expect-error: properties is of unknown type, ts doesn't like assigning mask to that
					cellOverrides[overrideIndex].properties.mask = mask
					setCellOverrides(cellOverrides)
				}

				return overriddenCell
			}

			return cell
		})

		setFormCells(newList)
		// sets the save button to be available whenever a value is changed
		setSaveDisabled(false)
	}

	const removeOverride = (id: string) => {
		setCellOverrides(cellOverrides.filter((cell) => cell.id !== id))

		setFormCells(
			MergeDefinitionOverrides(
				props.form.metadata.cellDefinitions,
				cellOverrides.filter((cell) => cell.id !== id),
			),
		)
		setSaveDisabled(false)
	}

	function saveChanges() {
		const formClient = new FormsClient(
			props.packageId,
			props.packageVersionNumber,
		)

		const submitOverrides = RemoveUnusedOverrides(
			props.form.metadata.cellDefinitions,
			cellOverrides,
		)

		formClient
			.UpdateCellOverrides(props.form.id, submitOverrides)
			.then(() => {
				toastService.displayToast({
					message: 'Overrides saved',
					area: 'global',
				})
				setSaveDisabled(true)

				// updates the values so changes are saved upon swapping tabs back and forth
				const newForm = cloneDeep(props.form)
				newForm.metadata.cellOverrides = cellOverrides.map((v) => v)
				props.onUpdateForm(newForm)
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Failed to save changes',
					area: 'global',
				})
			})
	}

	// FILTER FIELDS
	const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null)
	const [selectedFilter, setSelectedFilter] = useState(0)

	const filterOptions: FilterOption[] = [
		{
			label: 'All',
			value: 0,
		},
		{
			label: 'Static',
			value: 1,
		},
		{
			label: 'Configurable',
			value: 2,
		},
	]

	const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setFilterAnchorEl(event.currentTarget)
	}

	const handleFilterUsers = (option: FilterOption) => {
		setSelectedFilter(option.value)
		setFilterAnchorEl(null)
	}

	// SORT FIELDS
	enum SortOptions {
		Default,
		Alpha,
		AlphaRev,
	}

	const [selectedSort, setSelectedSort] = useState(SortOptions.Default)

	const sortedCells = useMemo(() => {
		// return to default order
		if (selectedSort === SortOptions.Default) return formCells

		// puts cells in alphabetical order
		const temp = formCells.map((v) => v) // have to map otherwise formCells itself gets changed
		return temp.sort((a, b) => {
			// I thought if statements would be more readable than this ternary mess
			// but it's about the same, just know it sorts in alphabetical order/reverse
			// return a.name.toLowerCase() === b.name.toLowerCase() ? 0 : a.name.toLowerCase() > b.name.toLowerCase() ? option.value == 1 ? 1 : -1 : option.value == 1 ? -1 : 1
			if (a.name.toLowerCase() === b.name.toLowerCase()) return 0
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				if (selectedSort === SortOptions.Alpha) return 1
				return -1
			}
			if (selectedSort === SortOptions.Alpha) return -1
			return 1
		})
	}, [selectedSort, formCells])

	return (
		<AdministrationPageContainer
			title={props.form.name}
			actions={
				<div>
					<Chip
						label={`Filter: ${filterOptions[selectedFilter].label}`}
						className={styles.filterItems}
					/>
					<Button
						className={styles.filterItems}
						startIcon={<FilterAlt />}
						color="primary"
						variant="outlined"
						size="medium"
						onClick={handleFilterClick}
						endIcon={<KeyboardArrowDown />}
					>
						Filter
					</Button>
					<Menu
						anchorEl={filterAnchorEl}
						open={filterAnchorEl !== null}
						onClose={() => setFilterAnchorEl(null)}
					>
						{filterOptions.map((option) => (
							<MenuItem
								onClick={() => handleFilterUsers(option)}
								key={option.value}
							>
								{option.label}
							</MenuItem>
						))}
					</Menu>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Save />}
						onClick={saveChanges}
						disabled={saveDisabled}
					>
						Save
					</Button>
				</div>
			}
		>
			<div className={styles.formContentRoot}>
				<AdministrationPageTabs
					currentTab={4}
					tabLabels={props.tabLabels}
					onTabChanged={props.onTabChanged}
				/>
				<Divider />
				<Fade in unmountOnExit>
					<TableContainer className={styles.tableContainer} component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>
										<Button
											className={styles.filterItems}
											onClick={() => setSelectedSort((selectedSort + 1) % 3)}
											endIcon={
												selectedSort ===
												SortOptions.Default ? undefined : selectedSort ===
												  SortOptions.Alpha ? (
													<KeyboardArrowDown />
												) : (
													<KeyboardArrowUp />
												)
											}
										>
											Name
										</Button>
									</TableCell>
									<TableCell>Field Type</TableCell>
									<TableCell align="right">Settings</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedCells.map((cell) => {
									// only renders row if it is of correct field type
									if (
										selectedFilter == 2 // Configurable
											? isConfigurable(cell.fieldType, cell.elementTag)
											: selectedFilter == 1 // Static fields
											? !isConfigurable(cell.fieldType, cell.elementTag)
											: true // All
									) {
										return (
											<FieldSettingsRow
												key={cell.name}
												cell={cell}
												updateCell={handleUpdateCellType}
												overridden={cellOverrides.includes(cell)}
												resetCell={removeOverride}
											/>
										)
									}
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Fade>
			</div>
		</AdministrationPageContainer>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	actionButtonsLeftSpacing: {
		marginRight: theme.spacing(2),
	},

	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),

		overflow: 'hidden',
	},

	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflowY: 'auto',
	},

	filterItems: {
		marginRight: theme.spacing(2),
	},
}))

type FieldSettingsDialogProps = {
	cell: CellDefinition
	updateCell: (id: string, value: FieldType, mask: string) => void
	overridden: boolean
	resetCell: (id: string) => void
}

const FieldSettingsRow = (props: FieldSettingsDialogProps) => {
	const [fieldDialog, setFieldDialog] = useState(false)
	const [fieldType, setFieldType] = useState(props.cell.fieldType)

	const properties: any = props.cell.properties
	const [mask, setMask] = useState(properties.mask ?? '')
	const [hint, setMaskHint] = useState<string | undefined>(undefined)

	const styles = dialogStyles()

	return (
		<TableRow key={props.cell.name}>
			<TableCell>{props.cell.name}</TableCell>
			<TableCell>
				<Box display="flex" alignItems="center">
					{props.cell.elementTag}
					{props.overridden && (
						<Tooltip title={'This field is being overridden'}>
							<Box fontSize="16px">
								<InfoOutlined
									sx={{ marginLeft: '8px' }}
									fontSize="inherit"
									color="primary"
								/>
							</Box>
						</Tooltip>
					)}
				</Box>
			</TableCell>
			<TableCell align="right">
				{isConfigurable(props.cell.fieldType, props.cell.elementTag) ? (
					<div>
						{props.overridden && (
							<IconButton onClick={() => props.resetCell(props.cell.id)}>
								<Restore />
							</IconButton>
						)}
						<IconButton onClick={() => setFieldDialog(true)}>
							<Settings />
						</IconButton>
					</div>
				) : (
					<Tooltip title="This field cannot be changed">
						<span>
							<IconButton disabled>
								<Settings />
							</IconButton>
						</span>
					</Tooltip>
				)}
			</TableCell>
			<Dialog
				open={fieldDialog}
				onClose={() => setFieldDialog(false)}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>
					<Typography>
						Override field properties of {props.cell.name}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Select className={styles.dialogWidth} value={fieldType}>
						<MenuItem
							key={FieldType.String}
							value={FieldType.String}
							onClick={() => {
								setFieldType(FieldType.String)
							}}
						>
							Text
						</MenuItem>
						<MenuItem
							key={FieldType.Number}
							value={FieldType.Number}
							onClick={() => {
								setFieldType(FieldType.Number)
							}}
						>
							Number
						</MenuItem>
						<MenuItem
							key={FieldType.DateTime}
							value={FieldType.DateTime}
							onClick={() => {
								setFieldType(FieldType.DateTime)
							}}
						>
							Date
						</MenuItem>
					</Select>
					{fieldType === FieldType.String && (
						// only allows masking on text fields
						<TextField
							className={styles.dialogWidth}
							margin="normal"
							label="Input Mask"
							value={mask}
							error={hint !== undefined}
							helperText={hint}
							onChange={(v) => {
								//checks for input mask validation
								if (v.target.value.includes('{')) {
									// this will always cause a max call size exceeded for the mask
									setMaskHint('Invalid character')
									return
								}

								if (
									v.target.value.startsWith(')') ||
									v.target.value.startsWith(']') ||
									v.target.value.startsWith('|')
								) {
									setMaskHint('Invalid start character')
									return
								}

								// if there are more ]'s than ['s or )'s than ('s
								if (
									(v.target.value.match(/\]/g) ?? []).length >
										(v.target.value.match(/\[/g) ?? []).length ||
									(v.target.value.match(/\)/g) ?? []).length >
										(v.target.value.match(/\(/g) ?? []).length
								) {
									setMaskHint(
										'Closing characters must have a matching opening character',
									)
									return
								}
								setMaskHint(undefined)
								setMask(v.target.value)
							}}
						/>
					)}
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => setFieldDialog(false)}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						// wont allow change if field type or mask hasn't been altered
						disabled={
							fieldType === props.cell.fieldType &&
							mask === (properties.mask ?? '')
						}
						onClick={() => {
							props.updateCell(props.cell.id, fieldType, mask)
							setFieldDialog(false)
						}}
					>
						Change
					</Button>
				</DialogActions>
			</Dialog>
		</TableRow>
	)
}

const dialogStyles = makeStyles((theme: Theme) => ({
	dialogWidth: {
		width: percent(100),
	},
}))
