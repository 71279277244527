import { AxiosResponse } from 'axios'
import SecureClient from '../SecureClient'
import { BaseSecretModel, Secret, SecretMetadata } from './DTO'

export default class SecretsClient extends SecureClient {
	public getSecrets = (vaultTypeId?: string): Promise<AxiosResponse<Secret[]>> => {
		return this._api.get<Secret[]>('Secrets', {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			},
			params: {
				vaultTypeId
			}
		})
	}

	public getSecretMetadata = (): Promise<AxiosResponse<SecretMetadata[]>> => {
		return this._api.get<SecretMetadata[]>('Secrets/Metadata', {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			}
		})
	}

	public getSecret = (secretId: number): Promise<AxiosResponse<Secret>> => {
		return this._api.get<Secret>(`Secrets/${secretId}`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			}
		})
	}

	public createSecret = (baseModel: BaseSecretModel): Promise<AxiosResponse<Secret>> => {
		return this._api.post<Secret>('Secrets', baseModel, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			}
		})
	}

	public updateSecret = (secretId: number, baseModel: BaseSecretModel): Promise<AxiosResponse<Secret>> => {
		return this._api.put<Secret>(`Secrets/${secretId}`, baseModel, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			}
		})
	}

	public deleteSecret = (secretId: number): Promise<AxiosResponse> => {
		return this._api.delete(`Secrets/${secretId}`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader
			}
		})
	}
}
