import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import {
	Box,
	ButtonBase,
	Card,
	Divider,
	Fade,
	Theme,
	Typography,
	useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactNode, useRef } from 'react'
import { useHistory } from 'react-router'
import { OverflowTooltip } from '../../components/tooltips/OverflowTooltip'

type PackageCardProps = {
	name: string
	description: ReactNode
	route: string
	chips?: ReactNode
	actions?: ReactNode
}

export const PackageCard = (props: PackageCardProps) => {
	const styles = useStyles()
	const history = useHistory()

	const theme = useTheme()

	return (
		<Fade in>
			<Box component={Card} marginY={1} className={styles.formCard}>
				<Box display="flex" alignItems={'center'}>
					<Box
						flex="1"
						display="flex"
						flexDirection={'column'}
						padding={2}
						component={ButtonBase}
						onClick={() => {
							history.push(props.route)
						}}
					>
						<Box
							flex="1"
							width="100%"
							height="100%"
							display="flex"
							alignItems={'center'}
						>
							<Box paddingRight={1} color={theme.palette.text.secondary}>
								<InsertDriveFileOutlinedIcon />
							</Box>

							<Box component={Divider} flexItem orientation="vertical" />
							<Box
								marginLeft={2}
								display="flex"
								flexDirection="column"
								flex="1"
								alignItems={'flex-start'}
							>
								<Typography variant="h6" fontWeight={'500'}>
									{props.name}
								</Typography>
								<Typography
									variant="caption"
									textOverflow="ellipsis"
									overflow="hidden"
									whiteSpace="nowrap"
								>
									{props.description !== undefined &&
									props.description !== '' ? (
										props.description
									) : (
										<Typography fontStyle={'italic'}>No Description</Typography>
									)}
								</Typography>
							</Box>

							<Box display="flex" gap={1} paddingRight={5}>
								{props.chips}
							</Box>
							<Box display="flex" height="100%" gap="1">
								{props.actions}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Fade>
	)
}

type PackageDescriptionProps = {
	description: string
	italic: boolean
}

// this allows us to display a tooltip only if the description is too long for the container
export const PackageDescription = (props: PackageDescriptionProps) => {
	const descriptionRef = useRef<HTMLSpanElement>(null)

	return (
		<OverflowTooltip tooltipText={props.description} childRef={descriptionRef}>
			<Typography
				variant="body2"
				color="textSecondary"
				ref={descriptionRef}
				fontSize={'inherit'}
				fontStyle={props.italic ? 'italic' : 'inherit'}
				noWrap
			>
				{props.description !== '' ? props.description : '\u00a0'}
			</Typography>
		</OverflowTooltip>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formCard: {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	formCardContentSection: {
		flex: '1',
	},

	cardActionAreaButtons: {
		display: 'flex',

		marginLeft: 'auto',
	},

	formCardHeader: {
		padding: theme.spacing(4, 3, 0, 3),
	},

	formCardDescription: {
		padding: theme.spacing(1, 3, 3, 3),
	},

	cardActionArea: {
		padding: theme.spacing(0, 2, 1, 2),
	},
}))
