import { makeAutoObservable } from 'mobx'
import * as React from 'react'
import { createContext, PropsWithChildren, useMemo } from 'react'
import { CellType } from '../../../../FormHost/Types/CellType'
import { IConcreteFormBuilderCellInstance } from '../../FormBuilderCellInstance'
import { ConcreteElement } from '../../FormBuilderCellTree'
import { FormBuilderElementContextProps } from './ContextTypes'

type VoidContextType = {
	readonly cellInstance: IConcreteFormBuilderCellInstance
}

const cellContext = createContext<VoidContextType | undefined>(undefined)

export const VoidContextProvider = (
	props: PropsWithChildren<FormBuilderElementContextProps>,
) => {
	const manager = useMemo(() => {
		const node = props.node

		if (node.cellType !== CellType.Concrete)
			throw new Error('cell type must be concrete for void context')

		return new VoidContextManager(node)
	}, [props.node])

	return (
		<cellContext.Provider value={manager}>
			{props.children}
		</cellContext.Provider>
	)
}

export function useVoidCellContext() {
	const context = React.useContext(cellContext)
	if (context === undefined) throw Error('void context could not be created')
	return context
}

class VoidContextManager implements VoidContextType {
	public get cellInstance(): IConcreteFormBuilderCellInstance {
		return this.node.cell
	}

	readonly node: ConcreteElement

	constructor(node: ConcreteElement) {
		this.node = node

		makeAutoObservable(this)
	}
}
