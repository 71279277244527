
import { Program } from 'estree'
import { CellDefinition } from '../modules/FormHost/Types/CellDefinition'
import { CellInstance } from '../modules/FormHost/Types/CellInstance'
import { ValidationEventRegistration } from '../modules/FormHost/Validation/Types'
import { PdfCellInstance } from '../modules/FormIntegrations/pdfFormHost/Types/PdfCellInstance'
import { WithFormsInfo } from '../modules/VisualScripting'
import { ResourceType } from '../pages/PackageHostPage/Attachments/AttachFilesDialog'
import {
	BaseGroup, BaseOrganization, BaseRole, BaseUser,
	TrackingEntity
} from './DTO'

export type GroupBase = {
	name: string
	createdDate: string
	createdBy: string
	lastModifiedDate: string
	lastModifiedBy: string
	description: string
}

export type GenericGroup = {
	name: string
	description: string
	groupId: string
	associationId?: number
}

export type GroupReduced = {
	name: string
	description: string
}

export type GroupDetails = GroupBase & {
	users: BaseUser[]
	roles: BaseRole[]
}

export type BasePackage = TrackingEntity & {
	name: string
	description: string
	forms: DetailForm[]
}

export type BaseFormPackageInfoModel = TrackingEntity & {
	name: string,
	description: string,
	authenticationRequired: boolean,
	versionNumber: number
	formPackageId: number
}

export type FormPackageSettings = {
	attachmentsEnabled: boolean
	showClientActionBar: boolean
	enableLocationServices: boolean
	wizardMode: boolean
	offlineEnabled: boolean
	digitallySign: boolean
	disableSubmission: boolean
}

export type LinkedField = {
	formId: number
	cellDefinitionId: string
}

export type FieldMapping = {
	id: string
	name: string
	description: string
	value: unknown
	linkedFields: LinkedField[]
}

export type FormPackageEventAction = {
	id: string
	definitionId: string
	name: string
	description: string
	eventId: string
	value: unknown | undefined
}

export type FormPackageAttachmentType = {
	name: string
	description: string
	required: boolean
	acceptedFileTypes: string,
	maxAttachmentCount?: number
}

export type FormPackageAdvancedEvent = {
	eventProgram: WithFormsInfo<Program>
	eventScript: string
}

export type FormPackageConfiguration = {
	settings: FormPackageSettings
	fieldMappings: FieldMapping[]
	eventActions: FormPackageEventAction[]
	validationEventRegistrations: ValidationEventRegistration[]
	attachmentTypes: FormPackageAttachmentType[]
	advancedEvents: FormPackageAdvancedEvent[]
}

export type FormPackageInfo = BaseFormPackageInfoModel & {
	configuration: FormPackageConfiguration
	versionNumber: number
	versionId: number
}

export type FormPackageVersion = TrackingEntity & {
	versionNumber: number
	isActive: boolean
	isPurged: boolean
	formPackageId: number
}

enum FormPackageStatus {
	Committing,
	Committed
}

export enum CommitIntent {
	Submit = 0,
	Save = 1,
	Approve = 2,
	Reject = 3,
}

export type BaseFormPackageInstanceModel = {
	id: number
	uniqueId: string
	packageId: number
	versionId: number
	index: FormPackageIndex
	description?: string
	previousInstanceId?: number
	lifetimeId?: string
	concurrencyToken?: string
}

export type NewFormPackageInstanceModel = {
	id: number
	uniqueId: string
	packageId: number
	versionId: number
	status: FormPackageStatus
	description: string
	index: FormPackageIndex
}

export type FormPackageInstance = {
	id: number
	uniqueId: string
	packageId: number
	versionId: number
	status: FormPackageStatus
	index: FormPackageIndex
	intent: CommitIntent
	name: string
	description: string
	previousInstanceId?: number
	lifetimeId?: string
	concurrencyToken?: string
}

export type UploadFormPackageModel = {
	blobId: number
	packageName: string
}

export type ChunkedUploadRequest = {
	fileBytes: number
	fileName: string
	contentType: string
	fileHash: string
}

export type ChunkedUploadResponse = {
	blobId: number
	chunkSize: number
	chunkCount: number
} 

export type FormInstance = {
	formId: number
	formData: Blob
}

export enum FormType {
	FormBuilder = 'FormBuilder',
	Html = 'Html',
	Pdf = 'Pdf'
}

export type FormIndex = {
	type: FormType
	formId: number
	formName: string
	definitions: CellDefinition[]
	instances: CellInstance[]
	values: Record<string, unknown>
	signedFields: string[]
}

export type SupportingDocumentIndex = {
	resourceId: string
	type: ResourceType
	name: string
	packageAttachmentType: string
	contentType: string
	relationalId?: string
}

export type FormPackageIndex = {
	formIndices: FormIndex[]
	supportingDocumentIndices: SupportingDocumentIndex[]
}

export type UpdatedPdfFormModel = {
	cellInstances: PdfCellInstance[]
	existingForm?: Blob
}

export type SignatureValue = {
	image: string
	timestamp: Date
	ipAddress: string
	emailAddress: string
	fullName: string
	status: SignatureStatus
}

export enum SignatureStatus {
	Untracked = 0,
	Tracked = 1
}

export type RoleDetails = BaseRole & {
	description: string
}

export type GroupPackageSimpleAssociation = {
	packageId: number
	groupId: number
}

export type SimplePackage = {
	name: string
	description: string
}

export type BaseGroupPackageAssociation = TrackingEntity & {
	packageId: number
	groupId: number
}

export type GroupPackageAssociation = TrackingEntity & {
	packageId: number
	package: BasePackage
	groupId: number
	group: BaseGroup
}

export type GenericPackage = {
	name: string
	packageId: number
	description?: string
	associationId?: number
}

export type GroupUserAssociation = TrackingEntity & {
	userId: number
	user: BaseUser
	groupId: number
	group: BaseGroup
}

export type GroupUserSimpleAssociation = {
	userId: number
	groupId: number
}

export type GroupRoleAssociation = TrackingEntity & {
	groupId: number
	group: BaseGroup
	roleId: number
	role: BaseRole
}

export type GroupRoleSimpleAssociation = {
	roleId: number
	groupId: number
}

export type UserPropertySimple = {
	name: string
	userEditable: boolean
}

export type UserProperty = TrackingEntity & UserPropertySimple

export type UserPropertySimpleAssociation = {
	userId: number
	propertyId: number
	value: string
}

export type UserPropertyAssociation = TrackingEntity & {
	userId: number
	user: BaseUser
	propertyId: number
	property: UserProperty
	propertyValue: string
}

export type ResetPasswordModel = ResetToken & {
	password: string
}

export type ResetToken = {
	resetToken: string
}

export type ChangePasswordData = {
	currentPassword: string
	newPassword: string
}

export type UserList = BaseUser & {
	associationId?: number
}

export type RoleList = BaseRole & {
	associationId?: number
}

export type BaseForm = {
	id: number
	name: string
	formBinary: BinaryType
}
export enum AccessStatus {
	awaitingApproval = 0,
	lockedOut = 1,
	accessPermitted = 2,
}

export type LoginResultModel = {
	accessStatus: AccessStatus
}

export type LockedOutLoginResultModel = {
	lockoutEnd?: Date
} & LoginResultModel

export type RelationshipUpdateModel = {
	organizationId: number
	userId: number
}
export type BaseOrganizationUserModel = RelationshipUpdateModel & {
	accessStatus: AccessStatus
}

export type CurrentOrganizationUserModel = {
	userId: number
	accessStatus: AccessStatus
}

export type CurrentOrganizationUserAssociation = CurrentOrganizationUserModel & {
	user: BaseUser
}

export type OrganizationUserAssociation = BaseOrganizationUserModel & {
	user: BaseUser
	organization: BaseOrganization
}

export type UserOrganizationFilter = {
	userId?: number
	organizationId?: number
}

export type DetailForm = TrackingEntity & {
	name: string
	contentType: string
	contentDisposition: string
	ordinalPosition: number
	activeVersion: number
	versionId: number
	metadata: FormMetadata
	formConfiguration: FormConfiguration
}

export type FormMetadata = {
	cellDefinitions: CellDefinition[]
	cellOverrides: CellDefinition[]
	dynamicProperties: { [key: string]: unknown }
}

export type FormInfoModel = {
	name: string
	formData: Blob
	metadata: FormMetadata
	formConfiguration: FormConfiguration
}

export type FormConfiguration = {
	downloadImageOnSubmit: boolean
	disableSignatureEmails: boolean
	widthSetting: FormWidthSetting
}

export enum FormWidthSetting {
	Paper = 0,
	FullWidth = 1
}

export type FormVersion = TrackingEntity & {
	versionNumber: number
	purged: boolean
	addedDate: boolean
	updatedDate: boolean
	formId: number
}

export type NewForm = {
	name: string
	formData: string
}

export type BaseSignatureModel = {
	signatureData: string
	id: number
	createdDate: Date
	fullName: string
	initials: string
	email: string
}

export type NewSignatureModel = {
	fullName: string
	initials: string
	email: string
	signatureData: string
}

/**
 * Portal DTOs
 */
export type Portal = TrackingEntity & {
	contentType: string
	contentDisposition: string
	organizationId: number
	authenticationRequired: boolean
}

export type PortalVersion = TrackingEntity & {
	name: string
	description: string
	versionNumber: number
	portalId: number
}

export type PortalData = {
	name: string
	portalData: Blob
}

export type NewPortal = {
	name: string
	description: string
	authenticationRequired: boolean
	portalData: Blob
}

export type PortalSettings = {
	name: string
	description: string
	authenticationRequired: boolean
}