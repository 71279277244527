import { Check, Circle, Publish, Settings } from '@mui/icons-material'
import { Box, Button, SvgIconProps, Typography } from '@mui/material'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useMemo } from 'react'
import { LogEntry, WorkflowStatus } from '../../api/clients/workflows/DTOs'
import { useModals } from '../../services/notifications/ModalService'
import { LogDialog } from './LogDialog'
import { EditorDisplayType, WorkflowSettings } from './Types'
import { UpdateWorkflowSettingsDialog } from './UpdateWorkflowSettingsDialog'
import { workflowStatuses } from './WorkflowInstanceStatus'
import { useWorkflowStore } from './stores/WorkflowStore'

type WorkflowStatusBoxProps = {
	displayType: EditorDisplayType
}

export const WorkflowStatusBox = observer((props: WorkflowStatusBoxProps) => {
	const { workflowContext } = useWorkflowStore()

	const localStore = useLocalObservable(() => ({
		showLogs: false,
	}))

	const statusColor = useMemo(() => {
		if (props.displayType === EditorDisplayType.ReadonlyVersion)
			return 'disabled'

		if (props.displayType === EditorDisplayType.Instance)
			return workflowStatuses[
				workflowContext.instance?.status as WorkflowStatus
			].color as SvgIconProps['color']

		if (workflowContext.workflow?.workflow.enabled) return 'success'

		return 'error'
	}, [workflowContext.workflow?.workflow.enabled])

	const statusText = useMemo(() => {
		if (props.displayType === EditorDisplayType.ReadonlyVersion)
			return 'Inactive'

		if (
			props.displayType === EditorDisplayType.Instance &&
			workflowContext.instance !== undefined
		)
			return workflowStatuses[workflowContext.instance.status]?.label

		if (workflowContext.workflow?.workflow.enabled) return 'Enabled'

		return 'Disabled'
	}, [workflowContext.workflow?.workflow.enabled, workflowContext.instance])

	return (
		<Box
			zIndex={(theme) => theme.zIndex.speedDial}
			position="absolute"
			top={0}
			right={0}
			padding={2}
		>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="flex-start"
				border={1.5}
				borderColor={(theme) =>
					theme.palette.grey[theme.palette.mode === 'light' ? 700 : 600]
				}
				bgcolor={(theme) =>
					theme.palette.neutral?.[theme.palette.mode === 'light' ? 200 : 800]
				}
				sx={{
					opacity: 0.85,
				}}
				gap={1}
				padding={2}
				paddingX={props.displayType === EditorDisplayType.ActiveVersion ? 2 : 3}
			>
				<Typography variant="h6">
					{workflowContext.workflow?.workflow.name}
				</Typography>

				<Box display="flex" gap={1} alignItems="center">
					<Circle fontSize="small" color={statusColor} />
					<Typography>{statusText}</Typography>
				</Box>
				{props.displayType === EditorDisplayType.ActiveVersion && (
					<ActiveVersionActions />
				)}
				{props.displayType === EditorDisplayType.Instance &&
					workflowContext.instance !== undefined && (
						<>
							<Button
								variant="contained"
								size="small"
								onClick={action(() => (localStore.showLogs = true))}
							>
								View Log
							</Button>
							<LogDialog
								open={localStore.showLogs}
								logTitle={`Workflow #${workflowContext.instanceId} Logs`}
								logEntries={(
									workflowContext.instance?.workflowLog as LogEntry[]
								).concat(
									workflowContext.activityInstances.flatMap(
										(v) => v.activityLog,
									),
								)}
								onClose={action(() => (localStore.showLogs = false))}
							/>
						</>
					)}
			</Box>
		</Box>
	)
})

const ActiveVersionActions = observer(() => {
	const { workflowContext } = useWorkflowStore()
	const modalService = useModals()

	const localStore = useLocalObservable(() => ({
		saveClicked: false,
	}))

	const lastSaved = useMemo(() => {
		let lastSavedDate = workflowContext.workflow?.lastModifiedDate.toString()
		if (lastSavedDate === undefined) return

		/* fun thing - so when we hit Publish, the last modified date comes back with a "Z"
		  attached, which tells Typescript the value is in UTC, and then the new Date() function
			converts it to EST. So we show the real UTC time when we load the page, but hitting 
			Publish takes us back to EST, which is confusing. So we need to remove the "Z" to 
			make it not convert time zones.
		*/
		if (lastSavedDate.endsWith('Z')) lastSavedDate = lastSavedDate.slice(0, -1)

		return new Date(lastSavedDate).toLocaleString()
	}, [workflowContext.workflow?.lastModifiedDate])

	return (
		<Box display="flex" flexDirection="column" gap={1}>
			{workflowContext.workflow?.lastModifiedDate !== undefined && (
				<Box display="flex" gap={1} alignItems="center">
					<Check fontSize="small" />
					<Typography>Last Saved {lastSaved} UTC</Typography>
				</Box>
			)}
			<Box display="flex" gap={1}>
				<Button
					size="small"
					startIcon={<Settings />}
					variant="outlined"
					onClick={() =>
						modalService
							.showForm((props) => (
								<UpdateWorkflowSettingsDialog
									onConfirm={(settings) =>
										props.close({
											closeResult: 'okay',
											value: settings,
										})
									}
									onCancel={() => props.close({ closeResult: 'cancel' })}
									settings={{
										enabled:
											workflowContext.workflow?.workflow.enabled ?? false,
									}}
								/>
							))
							.then((v) => {
								if (v.closeResult === 'okay') {
									const settings = v.value as WorkflowSettings
									workflowContext
										.updateWorkflow(
											workflowContext.workflow?.workflow.name,
											settings.enabled,
										)
										.then(() => {
											if (workflowContext.workflow !== undefined)
												workflowContext.workflow.workflow.enabled =
													settings.enabled
										})
								}
							})
					}
				>
					Update Settings
				</Button>
				<Button
					size="small"
					startIcon={<Publish />}
					variant="contained"
					disabled={localStore.saveClicked}
					onClick={action(() => {
						localStore.saveClicked = true
						workflowContext.saveAndPublishWorkflow().then(
							action((v) => {
								localStore.saveClicked = false

								// we have to set this = undefined bc publishing a workflow
								// updates the definition ids, so the selected one won't
								// exist anymore
								workflowContext.selectedActivityDefinitionId = undefined
							}),
						)
					})}
				>
					Publish
				</Button>
			</Box>
		</Box>
	)
})
