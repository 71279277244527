import { computed, makeObservable, observable } from 'mobx'
import {
	UserPropertiesClient,
	UserPropertyAssociationsClient
} from '../../api/clients/identity'
import {
	OrganizationPropertiesClient,
	OrganizationPropertyListItem,
	OrganizationPropertyModel,
	OrganizationPropertyType
} from '../../api/clients/identity/OrganizationPropertiesClient'
import UserClient from '../../api/clients/identity/UserClient'
import { BaseUser, SignedInUser } from '../../api/DTO'
import { UserProperty, UserPropertyAssociation } from '../../api/DTOtemp'
import { withScopedDatabaseCaching } from '../../services/offline/indexedDb'
import { SessionService } from '../../services/session'

export enum PackageApiStatus {
	Requesting = 1,
	Complete = 2,
}

export class PackageApi {
	public readonly packageId: number

	public get status(): PackageApiStatus {
		return this._status
	}

	private _userProperties: UserProperty[] | undefined
	private _userPropertyAssociations: UserPropertyAssociation[] | undefined
	private _organizationProperties: OrganizationPropertyModel[] | undefined

	private _currentUser: SignedInUser | undefined

	private _status: PackageApiStatus

	private readonly _sessionService: SessionService

	constructor(packageId: number) {
		this._status = PackageApiStatus.Requesting

		this.packageId = packageId
		this._sessionService = new SessionService()

		const requestPromises: Promise<void>[] = []

		// we only want to get user properties if a user is logged in
		if (this._sessionService.isLoggedIn) {
			const userPropertiesPromise = this.getUserProperties()
			const userPropertyAssociationsPromise = this.getUserPropertyAssociations()
			const currentUserPromise = this.getCurrentUser()

			requestPromises.push(
				userPropertiesPromise,
				userPropertyAssociationsPromise,
				currentUserPromise,
			)
		}

		const organizationPropertiesPromise = this.getOrganizationProperties()

		requestPromises.push(organizationPropertiesPromise)

		// use this instead of Promise.all() b/c if some error it's ok, we log it and return empty arrays
		// we have several requests so if one fails we don't want to stop the working requests from doing
		// their thing
		Promise.allSettled(requestPromises).then(() => {
			this._status = PackageApiStatus.Complete
		})

		makeObservable<
			PackageApi,
			| '_userProperties'
			| '_userPropertyAssociations'
			| '_organizationProperties'
			| '_currentUser'
			| '_status'
		>(this, {
			_userProperties: observable,
			_userPropertyAssociations: observable,
			_organizationProperties: observable,
			_currentUser: observable,
			_status: observable,
			userProperties: computed,
			userPropertyAssociations: computed,
			organizationProperties: computed,
			currentUser: computed,
		})
	}

	public get userProperties(): UserProperty[] {
		return this._userProperties ?? []
	}

	public get userPropertyAssociations(): UserPropertyAssociation[] {
		return this._userPropertyAssociations ?? []
	}

	public get organizationProperties(): OrganizationPropertyModel[] {
		return this._organizationProperties ?? []
	}

	public getCurrentUserPropertyAssociations(): UserPropertyAssociation[] {
		const currentUserId = this._sessionService.authToken.id
		if (this._userPropertyAssociations === undefined) return []

		return this._userPropertyAssociations.filter(
			(v) => v.userId === currentUserId,
		)
	}

	public get currentUser(): SignedInUser | undefined {
		return this._currentUser
	}

	//#region API methods
	private async getUserProperties() {
		const userPropertiesClient = new UserPropertiesClient()
		try {
			let data = await withScopedDatabaseCaching('user-properties', async () => {
				const result = await userPropertiesClient.GetUserProperties();
				return result.data;
			})

			if (data === undefined) {
				data = []
				console.warn('package api failed to fetch user properties')
			}

			this._userProperties = data
		} catch (e) {
			console.log(e)
			this._userProperties = []
		}
	}

	private async getUserPropertyAssociations() {
		const userPropertyAssociationsClient = new UserPropertyAssociationsClient()

		try {
			let data = await withScopedDatabaseCaching('user-property-associations', async () => {
				const result = await userPropertyAssociationsClient.GetUserPropAssociations(true)
				return result.data;
			})

			if (data === undefined) {
				console.warn('failed to fetch user property associations form network and cache')
				data = []
			}

			this._userPropertyAssociations = data
		} catch (e) {
			console.log(e)
			this._userPropertyAssociations = []
		}
	}

	// TODO - get only publicly accessible ones if user isn't logged in
	private async getOrganizationProperties() {
		const organizationPropertiesClient = new OrganizationPropertiesClient()

		try {
			let data = await withScopedDatabaseCaching('organization-properties', async () => {
				const result = await organizationPropertiesClient.GetOrganizationProperties()
				return result.data
			})

			if (data === undefined) {
				console.warn('failed to fetch organization properties from network and cache')
				data = []
			}

			this._organizationProperties = data
		} catch (e) {
			console.log(e)
			this._organizationProperties = []
		}
	}

	private async getCurrentUser() {
		const userClient = new UserClient()

		const data = await withScopedDatabaseCaching('my-user-info', async () => {
			const result = await userClient.MyInfo()
			return result.data
		})

		try {
			this._currentUser = data
		} catch (e) {
			console.log(e)
			this._currentUser = undefined
		}
	}

	//#endregion

	//#region proxies
	public get userPropertiesProxy(): Record<string, string> {
		if (this._userPropertyAssociations === undefined)
			return {}

		return Object.fromEntries(this._userPropertyAssociations.map(i => [`_${i.id}`, i.propertyValue]))
	}

	public get organizationPropertiesProxy(): Record<string, string | OrganizationPropertyListItem[]> {
		if (this._organizationProperties === undefined)
			return {}
		
		return Object.fromEntries(this._organizationProperties.filter(v => v.value.type !== OrganizationPropertyType.List).map(i => [`_${i.id}`, i.value.value]))
	}
	
	public get userInfoProxy(): Record<string, string> {
		if (this._currentUser === undefined)
			return {}
		
		const currentUserObject = Object.fromEntries(Object.keys(this._currentUser).map(i => [`${i}`, this._currentUser![i as keyof BaseUser].toString()]))

		currentUserObject['fullName'] = `${this._currentUser.firstName} ${this._currentUser.lastName}`

		return currentUserObject
	}

	public get queryStringProxy(): URLSearchParams {
		const proxyHandler: ProxyHandler<URLSearchParams> = {
			get(target, property) {
				if (!target.has(property.toString()))
					return ''
				return target.get(property.toString())
			},
		}

		return new Proxy(new URLSearchParams(location.search), proxyHandler)
	}
	//#endregion
}
