import { IconButton, IconButtonProps } from '@mui/material'
import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

type ExtractProps<TComponent> = TComponent extends React.ComponentType<
	infer TProps
>
	? TProps
	: never

type IconButtonLinkProps = {
	to: string
} & IconButtonProps<'a'>

function IconButtonLink(props: IconButtonLinkProps) {
	const { to } = props

	const renderLink = React.useMemo(
		() =>
			// eslint-disable-next-line react/display-name
			forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>((itemProps, ref) => (
				<Link to={to} ref={ref} {...itemProps} />
			)),
		[to],
	)

	return <IconButton {...props} component={renderLink} size="large" />
}

export default IconButtonLink
