import {
	AttachFile,
	ChevronLeft,
	Circle,
	Download,
	Error as ErrorIcon,
	Save,
} from '@mui/icons-material'
import {
	AppBar,
	Badge,
	IconButton,
	Theme,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent, px } from 'csx'
import { isError } from 'lodash'
import { action, computed, keys, runInAction, values } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import moment from 'moment'
import { ReactNode, RefObject, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import {
	CommitIntent,
	DetailForm,
	FormType,
	UpdatedPdfFormModel,
} from '../../api/DTOtemp'
import { FormsClient } from '../../api/clients/identity'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { FormBuilderPackageHostWrapper } from '../../modules/FormBuilderCore/FormBuilderPackageHostWrapper'
import { createFormBuilderValueObject } from '../../modules/FormBuilderCore/cells/rendering/CellManagerUtilities'
import { FormHost } from '../../modules/FormHost/FormHost/FormHost'
import { IUnifiedFormHost } from '../../modules/FormHost/FormHost/IUnifiedFormHost'
import {
	addAttachmentError,
	removeValidationError,
} from '../../modules/FormHost/Utilities/FieldValidationUtilities'
import { HtmlPackageHostWrapper } from '../../modules/FormIntegrations/htmlFormHost/HtmlPackageHostWrapper'
import { instancesToObject as htmlInstancesToObject } from '../../modules/FormIntegrations/htmlFormHost/Utilities/instancesToObject'
import { PdfPackageHostWrapper } from '../../modules/FormIntegrations/pdfFormHost/PdfPackageHostWrapper'
import { PdfCellInstance } from '../../modules/FormIntegrations/pdfFormHost/Types/PdfCellInstance'
import { instancesToObject as pdfInstancesToObject } from '../../modules/FormIntegrations/pdfFormHost/Utilities/instancesToObject'
import { useBlockingOperationBarrier } from '../../services/notifications/BlockingTaskService'
import { useModals } from '../../services/notifications/ModalService'
import { toastService } from '../../services/notifications/ToastService'
import { DetailFormWithData } from '../../services/offline/FormPackageHandling'
import { downloadFile } from '../../utils/DownloadFile'
import { RenderMode, form2pdf } from '../../utils/km-form2pdf'
import {
	AttachFilesDialog,
	ResourceType,
	TrackedResource,
} from './Attachments/AttachFilesDialog'
import { useAttachmentsApi } from './Attachments/AttachmentsApi'
import { ErrorsPopover } from './ErrorsPopover'
import { PackageHostProps } from './PackageHost'
import {
	FormPackageSubmitResult,
	savePackage,
	submitFormPackage,
} from './PackageHostFooter.Submit'
import { usePackageHostPageContext } from './PackageHostPageContext'
import { useOfflinePackageContext } from './PackageListPageContext'
import { PackageDescriptionDialog } from './SavedPackageDescriptionDialog'
import { SubmitPackageButton } from './SubmitPackageButton'
import { UnsavedPackageChangesDialog } from './UnsavedPackageChangesDialog'

// TODO - we should be pulling back attachments when we're loading the package

type PackageHostFooterProps = {
	formHosts: FormHost[]
	forms: DetailFormWithData[]
	resources: TrackedResource[]
	savedInstanceId?: string
	isPackageFromServer: boolean
	getCurrentFormReference: () => {
		ref: RefObject<HTMLDivElement>
		form: DetailForm
		formManager: IUnifiedFormHost | undefined
	}
	onSubmit: () => void
	onSubmitFinished: () => void
	onSubmitError: (message: ReactNode) => void
	onSubmitCanceled: () => void
} & PackageHostProps

export const PackageHostFooter = observer((props: PackageHostFooterProps) => {
	console.log('footer work item props', props.workItem)

	const styles = useStyles()

	const history = useHistory()

	const modalService = useModals()

	const blockingTasks = useBlockingOperationBarrier()
	const context = usePackageHostPageContext()
	const offlineContext = useOfflinePackageContext()
	const attachmentApi = useAttachmentsApi()

	const localStore = useLocalObservable(() => ({
		resources: props.resources as TrackedResource[],
		anchorEl: null as HTMLButtonElement | null,
		isSaving: false,
	}))

	useEffect(() => {
		// Before Unload fires when the user clicks to close the tab or window
		const onBeforeUnload = (e: BeforeUnloadEvent) => {
			if (checkIfDirty()) {
				e.preventDefault()
				e.returnValue =
					'There are unsaved changes. Are you sure you want to leave?'
			}
		}

		window.addEventListener('beforeunload', onBeforeUnload)

		return () => window.removeEventListener('beforeunload', onBeforeUnload)
		// same dependencies as those on savedFormValues otherwise the initial values are incorrect
	}, [props.savedInstanceId, props.formHosts.length])

	const getFormValues = (): Record<string, unknown>[] => {
		const formValues: Record<string, unknown>[] = []

		for (const form of props.formHosts) {
			if (form.formType === 'Html') {
				const htmlForm = form as HtmlPackageHostWrapper
				formValues.push(
					htmlInstancesToObject(
						htmlForm.formHost.cellDefinitions,
						htmlForm.formHost.cellInstances,
					),
				)
			}
			if (form.formType === 'FormBuilder') {
				const formBuilderForm = form as FormBuilderPackageHostWrapper
				formValues.push(createFormBuilderValueObject(formBuilderForm.formHost))
			}
			if (form.formType === 'Pdf') {
				const pdfForm = form as PdfPackageHostWrapper
				formValues.push(
					pdfInstancesToObject(
						pdfForm.formHost.cellDefinitions,
						pdfForm.formHost.cellInstances,
					),
				)
			}
		}

		return formValues
	}

	// initial values (empty OR the ones from the saved instance)
	const savedFormValues = useMemo<Record<string, unknown>[]>(
		() => getFormValues(),

		// dependency on form hosts length b/c when not all form hosts exist
		// when this is first rendered -- meaning we get 0 values
		[props.savedInstanceId, props.formHosts.length],
	)

	// just add resources on
	const appendResources = action((files: TrackedResource[]) => {
		for (const formHost of props.formHosts)
			if (
				formHost.controlApi &&
				formHost.controlApi.attachments.onAttachmentsAdded
			)
				formHost.controlApi.attachments.onAttachmentsAdded(files)

		localStore.resources.push(...files)

		/* if we have any validation errors reliant on the types of files we're uploading
		   run the validation to remove those errors
		*/
		let runValidation = false

		for (const file of files)
			if (context.validationErrors[file.packageAttachmentType] !== undefined)
				runValidation = true

		if (runValidation) validateAttachments()
	})

	// handle adding and removing resources
	const setResources = action((files: TrackedResource[]) => {
		const removedAttachments: TrackedResource[] = []

		for (const attachment of localStore.resources) {
			// if it's not in the files list we know it's been removed
			if (!files.map((v) => v.resourceId).includes(attachment.resourceId))
				removedAttachments.push(attachment)
		}

		for (const formHost of props.formHosts) {
			if (
				formHost.controlApi &&
				formHost.controlApi.attachments.onAttachmentsAdded
			)
				formHost.controlApi.attachments.onAttachmentsAdded(files)

			for (const removedAttachment of removedAttachments.filter(
				(v) => v.relationalId === formHost.formId.toString(),
			))
				if (
					formHost.controlApi &&
					formHost.controlApi.attachments.onAttachmentRemoved
				)
					formHost.controlApi.attachments.onAttachmentRemoved(
						removedAttachment.resourceId,
					)
		}

		// new files we're adding
		localStore.resources = files

		validateAttachments()
	})

	const getUpdatedPdfForm = async (
		formId: number,
		formModel: UpdatedPdfFormModel,
	): Promise<Blob> => {
		const formsClient = new FormsClient(
			props.packageId,
			props.formPackage.versionNumber,
		)

		formModel.existingForm = props.forms.find((v) => v.id === formId)?.stream

		const response = props.anonymous
			? await formsClient.GetUpdatedPdfFormAnonymous(formId, formModel)
			: await formsClient.GetUpdatedPdfForm(formId, formModel)

		return response.data
	}

	/**
	 * download a form at any point, this function isn't called when submitting
	 */
	const handleDownloadForm = async () => {
		const formReference = props.getCurrentFormReference()

		const formHost = formReference.formManager
		if (formHost === undefined)
			throw new Error(
				`form host is required to download form ${formReference.form.id}`,
			)

		if (formHost.formType === 'Pdf') {
			const pdfCellInstances = formHost.cellInstances as PdfCellInstance[]

			const updatedPdfForm = await getUpdatedPdfForm(formReference.form.id, {
				cellInstances: pdfCellInstances,
			})

			const objectUrl = URL.createObjectURL(
				new Blob([updatedPdfForm], { type: 'application/pdf' }),
			)

			downloadFile(document, objectUrl, formReference.form.name, 'pdf')

			URL.revokeObjectURL(objectUrl)

			return
		}

		if (formHost.formContainerElement === null)
			throw new Error(`image element for form ${formHost.formId} is null`)

		const task = blockingTasks.addBlockingTask({
			show: (
				<Typography variant={'subtitle1'}>Downloading Your Package</Typography>
			),
		})
		// HTML & FormBuilder forms use this
		const mode =
			formHost.formType === FormType.Html
				? RenderMode.Html
				: RenderMode.FormBuilder

		const formContainerElement =
			formHost.formType === FormType.Html
				? (formHost as HtmlPackageHostWrapper).iframeContainer
				: formHost.formContainerElement

		const formPdf = await form2pdf(
			formHost.formContainerElement,
			formContainerElement,
			mode,
		)
		task.complete()

		const dataUrl = formPdf.output('dataurlstring')
		downloadFile(document, dataUrl, formReference.form.name, 'pdf')
	}

	const getAttachments = (): TrackedResource[] => {
		return localStore.resources
	}

	const validateAttachments = () => {
		for (const attachmentType of props.formPackage.configuration.attachmentTypes.filter(
			(v) => v.required,
		)) {
			if (
				!localStore.resources.some(
					(v) => v.packageAttachmentType === attachmentType.name,
				)
			) {
				addAttachmentError(
					context.validationErrors,
					attachmentType.name,
					'required',
					`Required`,
				)
			} else {
				removeValidationError(
					context.validationErrors,
					attachmentType.name,
					'required',
				)
			}
		}
	}

	const onSubmit = async (
		intent: CommitIntent.Submit | CommitIntent.Approve | CommitIntent.Reject,
		description?: string,
	) => {
		props.onSubmit()

		validateAttachments()

		// check if the current form has any errors in the validation error record
		// do this first so we don't go thru all the submit stuff for each individual form
		const hasValidationErrorsComputed = computed(
			() =>
				keys(context.validationErrors).filter((v) =>
					props.formPackage.configuration.attachmentTypes
						.filter((v) => v.required)
						.map((v) => v.name)
						.includes(v.toString()),
				).length > 0,
		)

		if (hasValidationErrorsComputed.get()) {
			props.onSubmitError(
				`Form package does not meet all the attachment requirements`,
			)
			return
		}

		try {
			const submitResult = await submitFormPackage({
				intent: intent,
				description: description,
				attachments: getAttachments(),
				context: context,
				formHosts: props.formHosts,
				forms: props.forms,
				onSubmitError: props.onSubmitError,
				formPackage: props.formPackage,
				savedInstanceId: props.savedInstanceId,
				onSaveInstance: props.onSaveInstance,
				workItem: props.workItem,
				isPackageFromServer: props.isPackageFromServer,
			})

			if (
				submitResult === FormPackageSubmitResult.Canceled ||
				submitResult === FormPackageSubmitResult.Errored
			) {
				// if we're cancelling a submission we need to make sure we don't keep the form image, etc. around
				setResources(
					localStore.resources.filter(
						(v) => v.type === ResourceType.Attachment,
					),
				)

				if (submitResult === FormPackageSubmitResult.Canceled)
					props.onSubmitCanceled()
				if (submitResult === FormPackageSubmitResult.Errored)
					props.onSubmitError('Form package submission failed')

				return
			}
		} catch (e) {
			console.error('error submitting form package', e)
			if (isError(e)) props.onSubmitError(e.message)
			return
		}

		if (values(context.validationErrors).length > 0) return

		let toastMessage = 'Form Package Submitted 🚀'
		if (intent === CommitIntent.Approve) toastMessage = 'Form Package Approved'
		if (intent === CommitIntent.Reject) toastMessage = 'Form Package Rejected'

		if (props.savedInstanceId) {
			const savedInstanceIdKey = props.savedInstanceId.toString()

			if (offlineContext.savedPackageList.has(savedInstanceIdKey))
				offlineContext.savedPackageList.delete(savedInstanceIdKey)
			if (offlineContext.receivedPackageList.has(savedInstanceIdKey))
				offlineContext.receivedPackageList.delete(savedInstanceIdKey)
		}

		console.log(toastMessage)

		toastService.displayToast({
			message: <Typography>{toastMessage}</Typography>,
			area: 'global',
			severity: 'success',
		})

		props.onSubmitFinished()
	}

	const onSave = async (): Promise<boolean> =>
		modalService
			.showForm((formProps) => (
				<PackageDescriptionDialog
					intent={CommitIntent.Save}
					initialDescription={
						props.savedInstanceId === undefined
							? `Saved on ${moment().format('MM/DD/YYYY')}`
							: props.formPackage.description
					}
					onConfirm={(v) =>
						formProps.close({
							closeResult: 'okay',
							value: v,
						})
					}
					onCancel={() => formProps.close({ closeResult: 'cancel' })}
				/>
			))
			.then(async (v) => {
				if (v.closeResult === 'okay') {
					runInAction(() => {
						localStore.isSaving = true
					})

					await savePackage({
						attachments: getAttachments(),
						formHosts: props.formHosts,
						formPackage: props.formPackage,
						forms: props.forms,
						intent: CommitIntent.Save,
						savedInstanceId: props.savedInstanceId,
						onSaveInstance: props.onSaveInstance,
						description: v.value as string,
						workItem: props.workItem,
						isPackageFromServer: props.isPackageFromServer,
					})

					runInAction(() => {
						localStore.isSaving = false
					})

					toastService.displayToast({
						message: <Typography>Form Package Saved</Typography>,
						delay: 4,
						area: 'formPackage',
					})

					return true
				}
				return false
			})

	const returnToHome = () => {
		history.push(`/_user/_home`)
	}

	const checkIfDirty = () => {
		// check if original values match the values now
		if (JSON.stringify(savedFormValues) !== JSON.stringify(getFormValues()))
			return true

		// once saved the attachments become saved attachments, so check if there
		// are any unsaved ones
		return localStore.resources.length > 0
	}

	// for returning to home or leaving the web page
	const onLeavePackage = () => {
		const isDirty = checkIfDirty()

		// instance has been saved, or nothing has been updated
		if (!isDirty) {
			returnToHome()
			return
		}

		modalService
			.showForm((formProps) => (
				<UnsavedPackageChangesDialog
					onConfirm={(save) =>
						formProps.close({
							closeResult: 'okay',
							value: save,
						})
					}
					onCancel={() =>
						formProps.close({
							closeResult: 'cancel',
						})
					}
				/>
			))
			.then(async (v) => {
				if (v.closeResult === 'okay') {
					if (v.value as boolean) {
						const saved = await onSave()
						if (saved) returnToHome()
					} else returnToHome()
				}
			})
	}

	if (props.formPackage === undefined || props.forms === undefined)
		return <FullscreenSpinner />

	return (
		<>
			<AppBar color="default" position="relative" className={styles.appBar}>
				<Toolbar>
					<div className={styles.appBarLeft} style={{ position: 'relative' }}>
						{!props.anonymous && (
							<Tooltip title="Return to Home">
								<IconButton
									className={styles.homeButton}
									onClick={() =>
										history.push(`/_form-packages/_allowed-form-packages`)
									}
								>
									<ChevronLeft />
								</IconButton>
							</Tooltip>
						)}
						<Typography variant="h6">{props.formPackage.name}</Typography>
						<div className={styles.errorDiv}>
							{values(context.validationErrors).length > 0 && (
								<>
									<Tooltip title="View Errors">
										<div className={styles.iconDiv}>
											<Circle
												fontSize="small"
												color="error"
												className={clsx(styles.pulsingIcon, styles.errorButton)}
											/>
											<IconButton
												className={clsx(
													styles.errorButton,
													styles.overlayedIconButton,
												)}
												color="error"
												onClick={action(
													(evt: React.MouseEvent<HTMLButtonElement>) =>
														(localStore.anchorEl = evt.currentTarget),
												)}
											>
												<ErrorIcon />
											</IconButton>
										</div>
									</Tooltip>
								</>
							)}
						</div>
					</div>
					{/* if submit is disabled we need to disable save, attachments, etc. */}
					{!props.formPackage.configuration.settings.disableSubmission && (
						<>
							<SubmitPackageButton
								onSubmit={onSubmit}
								approveAndRejectButtons={props.workItem !== undefined}
							/>

							<div className={styles.appBarRight}>
								<Tooltip title="Download Form">
									<IconButton size="large" onClick={handleDownloadForm}>
										<Download />
									</IconButton>
								</Tooltip>
								{!props.anonymous && props.workItem === undefined && (
									<Tooltip title="Save Form" placement="bottom">
										<IconButton
											size="large"
											onClick={onSave}
											disabled={localStore.isSaving}
										>
											<Save />
										</IconButton>
									</Tooltip>
								)}
								{/* only allow attachments if setting is enabled */}
								{props.formPackage.configuration.settings
									.attachmentsEnabled && (
									<Tooltip title="Attach Files" placement="bottom">
										<IconButton
											size="large"
											onClick={action(() => {
												attachmentApi.requestAttachments()
											})}
										>
											<Badge
												anchorOrigin={{
													vertical: 'top',
													horizontal: 'right',
												}}
												badgeContent={
													localStore.resources.filter(
														(v) => v.type == ResourceType.Attachment,
													).length
												}
												color="primary"
											>
												<AttachFile />
											</Badge>
										</IconButton>
									</Tooltip>
								)}
							</div>
						</>
					)}
				</Toolbar>
			</AppBar>
			<ErrorsPopover
				anchorEl={localStore.anchorEl}
				onClose={action(() => (localStore.anchorEl = null))}
			/>
			<AttachFilesDialog
				packageAttachmentTypes={props.formPackage.configuration.attachmentTypes}
				attachments={localStore.resources}
				onAttachFiles={setResources}
				onClose={() => {}}
			/>
		</>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		flex: '0 0 auto',

		display: 'flex',

		width: '100%',
	},

	appBarLeft: {
		flex: '1',

		display: 'flex',
		height: '100%',
		width: '100%',

		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	homeButton: {
		marginRight: theme.spacing(1),
		size: 'large',
	},

	appBarRight: {
		flex: '1',

		display: 'flex',
		height: '100%',
		width: '100%',

		justifyContent: 'flex-end',
		alignItems: 'center',
	},

	iconDiv: {
		width: percent(100),
		height: percent(100),
		display: 'flex',
		justifyContent: 'center',
	},

	pulsingIcon: {
		position: 'relative',
		animation: 'pulse 1250ms infinite',
	},

	overlayedIconButton: {
		position: 'absolute',
	},

	errorButton: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},

	errorPopover: {
		display: 'flex',
		flexDirection: 'column',
		height: 'fit-content',
		padding: theme.spacing(0, 2),

		[theme.breakpoints.up('xs')]: {
			maxWidth: percent(100),
		},

		[theme.breakpoints.up('sm')]: {
			minWidth: px(400),
		},

		[theme.breakpoints.up('md')]: {
			minWidth: px(500),
		},
	},

	errorHeader: {
		padding: theme.spacing(1, 0),
	},

	errorDiv: {
		position: 'absolute',
		right: 1,
	},
}))
