import * as yup from 'yup'

export const getPackageValidationSchema = (
	upperPackageNames: string[],
	originalName?: string,
) => {
	return yup.object({
		name: yup
			.string()
			.required('The form package name must be set')
			.matches(
				/^[a-zA-Z0-9_ ]+$/,
				'The package name must not contain any special characters',
			)
			.test(
				'is-unique',
				'A form package with this name already exists in your organization',
				(value) => {
					return (
						value !== undefined &&
						(value.trim() === originalName?.trim() ||
							!upperPackageNames.includes(value.toUpperCase().trim()))
					)
				},
			),
		description: yup
			.string()
			.matches(
				/^[a-zA-Z0-9_,\-$ ]*$/,
				'The description must not contain any special characters',
			),
	})
}
