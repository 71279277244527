/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { AxiosResponse } from 'axios'
import { AuthenticationMethod, LocalUserAuthentication } from '../../DTO'
import { LoginResultModel } from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class AuthenticationClient extends SecureClient {

	public refresh()
		: Promise<AxiosResponse> {
		return this._api.post('authentication/refresh', null, {
			headers: {
				...this.authorizationHeader
			}
		})
	}

	public secureHeartBeat = (): Promise<AxiosResponse> =>
		this._api.get('authentication/login',
			{
				headers: {
					...this.authorizationHeader
				}
			})

}

export class SignInClient extends SecureClient {
	public getAuthenticationMethod = (username: string):
		Promise<AxiosResponse<AuthenticationMethod>> =>
		this._api.get<AuthenticationMethod>(`authentication/login/${username}/authMethod`, {
			headers: {
				...this.acceptHeader()
			}
		})
	
	public getOrganizationAuthenticationMethods = (): Promise<AxiosResponse<AuthenticationMethod[]>> => 
		this._api.get<AuthenticationMethod[]>(`authentication/OrganizationAuthMethods`, {
			headers: {
				...this.acceptHeader()
			}
		})
	
	public getDefaultOrganizationAuthenticationMethod = (): Promise<AxiosResponse<AuthenticationMethod>> =>
		this._api.get<AuthenticationMethod>(`authentication/OrganizationAuthMethods/Default`, {
			headers: {
				...this.acceptHeader()
			}
		})
	
	public localLogin = (authProps: LocalUserAuthentication)
		: Promise<AxiosResponse<LoginResultModel>> =>
		this._api.post('authentication/login',
			JSON.stringify(authProps), {
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				}
			});

}

