import { createContext, PropsWithChildren, useContext } from 'react'
import { EventBus } from '../../modules/FormBuilderCore/eventBus/EventBus'

const RuntimePackageContext = createContext<
	PackageContextProviderProps | undefined
>(undefined)

type PackageContextProviderProps = {
	eventBus: EventBus // <- the global event bus for the package
}

export const RuntimePackageContextProvider = (
	props: PropsWithChildren<PackageContextProviderProps>,
) => {
	const { children, ...args } = props

	return (
		<RuntimePackageContext.Provider value={args}>
			{children}
		</RuntimePackageContext.Provider>
	)
}

export const useRuntimePackageContext = () => {
	const contextValue = useContext(RuntimePackageContext)
	if (contextValue === undefined)
		throw Error(
			'package context was consumed before adequate props were provided',
		)

	return contextValue
}
