import { ExpandMore } from '@mui/icons-material'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent, px } from 'csx'
import { Program, Syntax } from 'esprima'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useRef } from 'react'
import { DndProvider, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ProgramComponent, {
	Programs,
} from './codegen/components/internal/ProgramComponent'
import { DraggableComponent } from './draggable/DraggableComponent'
import NodeComponentEntry from './draggable/NodeComponentEntry'
import { ComponentCategory, NodeComponents } from './draggable/NodeComponents'
import { TrashDustbin } from './draggable/TrashDustbin'
import { useProgramContext } from './ProgramContext'
import { WithFormsInfo } from './Types'

export const VisualScriptEditor = observer(() => {
	return (
		<DndProvider backend={HTML5Backend}>
			<Box
				display="flex"
				flexDirection="row"
				width={percent(100)}
				gap={2}
				padding={4}
				height={percent(100)}
			>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					gap={2}
				>
					<Box
						display="flex"
						flexDirection="column"
						overflow="scroll"
						paddingRight={2}
					>
						{Object.keys(ComponentCategory).map((category) => (
							<Box key={category} display="flex" flexDirection="column">
								<NodeSourcePanel category={category} />
							</Box>
						))}
					</Box>
				</Box>
				<GridEditor />
			</Box>
		</DndProvider>
	)
})

type NodeSourcePanelProps = {
	category: string
}

// section where we can choose nodes to drag over
const NodeSourcePanel = ({ category }: NodeSourcePanelProps) => {
	return (
		<Accordion defaultExpanded={true} disableGutters>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<Typography variant="h6">{category}</Typography>
			</AccordionSummary>
			<Box
				component={AccordionDetails}
				display="flex"
				flexDirection="column"
				gap={2}
				flex={1}
			>
				{NodeComponents.filter((v) => v.category === category)
					.sort((a, b) => a.displayName.localeCompare(b.displayName))
					.map((v) => (
						<NodeComponentEntry
							key={v.displayName}
							displayName={v.displayName}
							defaultNode={v.defaultNode}
							category={v.category}
						/>
					))}
			</Box>
		</Accordion>
	)
}

const GridEditor = observer(() => {
	const context = useProgramContext()
	const styles = useStyles()

	const containerRef = useRef<HTMLDivElement | null>()

	const [, drop] = useDrop({
		accept: [Syntax.Program, ...Programs],
		drop: (item, monitor) => {
			if (monitor.didDrop()) return

			/*
			 * We want to drop the program on the grid and snap it on the closest dot.
			 * React-dnd is annoying as it gives you the XY coordinates relative to the whole page, not to the drop container
			 * so we have to have a ref to the container that we can use to figure out the offset ☹
			 */
			const container = containerRef.current
			if (!container) throw new Error('container ref not found!')

			const coordinates = monitor.getClientOffset()

			if (coordinates === null)
				throw new Error('no coordinates found for dropped item')

			// find out if we're adding a program or just moving an existing one
			const existingProgram = context.programs.find(
				(v) => v === (item as WithFormsInfo<Program>),
			)

			// snap to the nearest dot in the grid - the subtractions/additions are due to margins/border &
			// we use 24 because the dots are 24 px apart
			const updatedPosition = {
				x:
					Math.round(coordinates.x / 24) * 24 -
					container.getBoundingClientRect().left -
					5,
				y:
					Math.round(coordinates.y / 24) * 24 -
					container.getBoundingClientRect().top -
					3,
			}

			// if we're just moving the program update its position
			if (existingProgram !== undefined) {
				runInAction(() => {
					existingProgram.position = updatedPosition
				})
				return
			}

			const newProgram = item as WithFormsInfo<Program>

			runInAction(() => {
				newProgram.position = updatedPosition
				context.programs.push(newProgram)
			})
		},
	})

	return (
		<Box
			position="relative"
			ref={containerRef}
			width={percent(100)}
			height={percent(100)}
		>
			<Box width={percent(100)} height={percent(100)}>
				<Box
					className={styles.dottedBackground}
					position="absolute"
					top={0}
					left={0}
					width={percent(100)}
					height={percent(100)}
					ref={drop}
				>
					{context.programs.map((v, i) => {
						return (
							<Box
								key={i}
								position="absolute"
								left={v.position?.x}
								top={v.position?.y}
								width="fit-content"
								height={px(20)}
							>
								<DraggableComponent node={v}>
									<ProgramComponent node={v} />
								</DraggableComponent>
							</Box>
						)
					})}
				</Box>
				<Box position="absolute" right={0} bottom={0} padding={2}>
					<TrashDustbin />
				</Box>
			</Box>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	dottedBackground: {
		backgroundImage:
			'radial-gradient(rgb(125, 125, 125), 1px, transparent 0px)',
		backgroundSize: '24px 24px',
		backgroundPosition: '16px 16px',
		borderWidth: '1px',
		border: 'solid',
		overflow: 'scroll',
	},
}))
