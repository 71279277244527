import { AxiosResponse } from "axios"
import { WorkflowInstanceFilter } from "../../../pages/Organization/Administration/Workflows/WorkflowHistory/WorkflowHistoryDashboard"
import SecureClient from "../SecureClient"
import { BaseWorkflowModel, Workflow, WorkflowDefinition, WorkflowDefinitionBase, WorkflowInstance, WorkflowInstanceBase } from "./DTOs"

export default class WorkflowsClient extends SecureClient {
	public getWorkflow = (workflowId: number): Promise<AxiosResponse<Workflow>> => {
		return this._api.get<Workflow>(`Workflows/${workflowId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public getAllWorkflows = (): Promise<AxiosResponse<WorkflowDefinition[]>> => {
		return this._api.get<WorkflowDefinition[]>(`Workflows`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public createWorkflow = (model: BaseWorkflowModel): Promise<AxiosResponse<Workflow>> => {
		return this._api.post<Workflow>(`Workflows`,
			model,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader()
				},
			})
	}

	public updateWorkflow = (workflowId: number, model: BaseWorkflowModel): Promise<AxiosResponse<Workflow>> => {
		return this._api.put<Workflow>(`Workflows/${workflowId}`,
			model,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader()
				}
			}
		)
	}

	public getVersionsOfWorkflow = (workflowId: number):
		Promise<AxiosResponse<WorkflowDefinitionBase[]>> => {
		return this._api.get<WorkflowDefinitionBase[]>(`Workflows/${workflowId}/definitions`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public getPublishedDefinition = (workflowId: number):
		Promise<AxiosResponse<WorkflowDefinition>> => {
		return this._api.get<WorkflowDefinition>(`Workflows/${workflowId}/definitions/published`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public getDefinition = (workflowId: number, definitionId: number):
		Promise<AxiosResponse<WorkflowDefinition>> => {
		return this._api.get<WorkflowDefinition>(`Workflows/${workflowId}/definitions/${definitionId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	/**
	 * get all the instances for a workflow definition
	 * @param workflowId the workflow the definition belongs to
	 * @param definitionId the definition to fetch the instances for
	 * @returns the instances for the workflow definition
	 */
	public getInstanceHistory = (workflowId: number, definitionId: number): Promise<AxiosResponse<WorkflowInstanceBase[]>> => {
		return this._api.get<WorkflowInstanceBase[]>(`Workflows/${workflowId}/definitions/${definitionId}/instances`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	/**
	 * get the instances for a workflow based on the filters, pagination, and sorting
	 * @param workflowId the workflow to fetch the instances for
	 * @param filter definition id, status, start date from, start date to, 
	 * end date, page number, page size, and sort by
	 * @returns the workflow instances based on the filters
	 */
	public getFilteredInstanceHistory = (workflowId: number, filter: WorkflowInstanceFilter):
		Promise<AxiosResponse<WorkflowInstanceBase[]>> => {
		return this._api.get<WorkflowInstanceBase[]>(`Workflows/${workflowId}/instances`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			},
			params: {
				definitionId: filter.definitionId,
				status: filter.status,
				startDateFrom: filter.startDateFrom,
				startDateTo: filter.startDateTo,
				endDate: filter.startDateTo,
				pageNumber: filter.pageNumber,
				pageSize: filter.pageSize,
				sortBy: filter.sortBy
			},
		})
	}

	/**
	 * get the number of workflow instances based on filters, pagination, and sorting
	 * @param workflowId the workflow to fetch the instance count for
	 * @param filter definition id, status, start date from, start date to, 
	 * end date, page number, page size, and sort by
	 * @returns the number of workflow instances based on the filters
	 */
	public getInstanceCount = (workflowId: number, filter: WorkflowInstanceFilter): Promise<AxiosResponse<number>> => {
		return this._api.get<number>(`Workflows/${workflowId}/instances/count`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			},
			paramsSerializer: (v) => {
				const urlSearchParams = new URLSearchParams();
				for (const [key, value] of Object.entries(v)) {
					if (!value)
						continue;

					if (Array.isArray(value)) {
						for (const item of value) {
							if (!item)
								continue;
							
							urlSearchParams.append(key, item.toString())
						}
					} else {
						urlSearchParams.append(key, (value as string ?? '').toString())
					}
				}
				
				return urlSearchParams.toString()
			},
			params: {
				definitionId: filter.definitionId,
				status: filter.status,
				startDateFrom: filter.startDateFrom,
				startDateTo: filter.startDateTo,
				endDate: filter.startDateTo,
				pageNumber: filter.pageNumber,
				pageSize: filter.pageSize,
				sortBy: filter.sortBy
			},
		})
	}

	public getWorkflowInstance = (workflowId: number, definitionId: number, instanceId: number):
		Promise<AxiosResponse<WorkflowInstance>> => {
		return this._api.get<WorkflowInstance>(`Workflows/${workflowId}/definitions/${definitionId}/instances/${instanceId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public publishWorkflow = (workflowId: number, workflow: WorkflowDefinition): Promise<AxiosResponse> => {
		return this._api.post<WorkflowDefinition>(`Workflows/${workflowId}/definitions/publish`,
			workflow, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public publishWorkflowById = (workflowId: number, definitionId: number): Promise<AxiosResponse> => {
		return this._api.post(`Workflows/${workflowId}/definitions/${definitionId}/publish`, null, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public uploadDraft = (workflowId: number, workflow: WorkflowDefinition): Promise<AxiosResponse> => {
		return this._api.post<WorkflowDefinition>(`Workflows/${workflowId}/definitions/draft`, workflow, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public deleteWorkflow = (workflowId: number): Promise<AxiosResponse> => {
		return this._api.delete(`Workflows/${workflowId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public deleteWorkflowDefinition = (workflowId: number, definitionId: number): Promise<AxiosResponse> => {
		return this._api.delete(`Workflows/${workflowId}/definitions/${definitionId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public uploadWorkflowDefinition = (workflowDefinition: WorkflowDefinition, workflowId?: number): Promise<AxiosResponse<WorkflowDefinition>> => {
		return this._api.post(`Workflows/upload/${workflowId ? workflowId : ''}`, workflowDefinition, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}
}