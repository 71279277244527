import { NIL, v4 as uuidv4 } from 'uuid'
import { CellType } from '../../FormHost/Types/CellType'
import FieldType, { isValueType } from '../../FormHost/Types/FieldType'
import {
	ComponentRegistration,
	ConfigurableContainerComponentRegistration,
	ValueComponentRegistration,
} from './ComponentTypes'
import { FormBuilderCellDefinition } from './FormBuilderCellDefinition'

export function createSimpleDefinition<T extends Record<string, unknown>>(
	registration: ValueComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
	value: unknown,
): FormBuilderCellDefinition
export function createSimpleDefinition<T extends Record<string, unknown>>(
	registration: ConfigurableContainerComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
): FormBuilderCellDefinition
export function createSimpleDefinition<T extends Record<string, unknown>>(
	registration: ComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
): FormBuilderCellDefinition
export function createSimpleDefinition<T extends Record<string, unknown>>(
	registration: ComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string = NIL,
	properties: Partial<T>,
	value?: unknown | undefined,
): FormBuilderCellDefinition {
	if (isValueType(registration.fieldType) && value === undefined) {
		const valueRegistration = registration as ValueComponentRegistration<T>
		value = valueRegistration.defaultValue
	}

	const baseDefinition = {
		elementTag: registration.id,
		properties: { ...registration.defaultProperties, ...properties },
		id: uuidv4(),
		name,
		ordinalPosition,
		parentId,
		fieldType: registration.fieldType,
	}

	if (registration.fieldType === FieldType.Object)
		return {
			type: CellType.Layout,
			...baseDefinition,
		}

	if (registration.fieldType === FieldType.ObjectArray)
		return {
			type: CellType.Repeat,
			defaultRepeatCount: 1,
			...baseDefinition,
		}

	if (value === undefined)
		return {
			type: CellType.Concrete,
			...baseDefinition,
		}

	return {
		type: CellType.Value,
		value: value,
		...baseDefinition,
	}
}

export function createComplexDefinition<T extends Record<string, unknown>>(
	registration: ValueComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
	value: unknown,
): FormBuilderCellDefinition
export function createComplexDefinition<T extends Record<string, unknown>>(
	registration: ConfigurableContainerComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
): FormBuilderCellDefinition
export function createComplexDefinition<T extends Record<string, unknown>>(
	registration: ComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string,
	properties: Partial<T>,
): FormBuilderCellDefinition
export function createComplexDefinition<T extends Record<string, unknown>>(
	registration: ComponentRegistration<T>,
	name: string,
	ordinalPosition: number,
	parentId: string = NIL,
	properties: Partial<T>,
	value: unknown | undefined = undefined,
): FormBuilderCellDefinition {
	if (isValueType(registration.fieldType) && value === undefined) {
		const valueRegistration = registration as ValueComponentRegistration<T>
		value = valueRegistration.defaultValue
	}

	const baseDefinition = {
		elementTag: registration.id,
		properties: { ...registration.defaultProperties, ...properties },
		id: uuidv4(),
		name,
		ordinalPosition,
		parentId,
		fieldType: registration.fieldType,
	}

	if (registration.fieldType === FieldType.Object)
		return {
			type: CellType.Layout,
			...baseDefinition,
		}

	if (registration.fieldType === FieldType.ObjectArray)
		return {
			type: CellType.Repeat,
			defaultRepeatCount: 1,
			...baseDefinition,
		}

	if (value === undefined)
		return {
			type: CellType.Concrete,
			...baseDefinition,
		}

	return {
		type: CellType.Value,
		value: value,
		...baseDefinition,
	}
}
