
import { AxiosResponse } from "axios"
import { GroupPackageAssociation, GroupPackageSimpleAssociation } from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class GroupPackageAssociationsClient extends SecureClient {
	/**
	 * Get all associations between packages and groups in the specified organization, with the package and
	 * group id being optional constraints
	 * @param packageId - optional
	 * @param groupId - optional
	 */
	public GetPackageAssociations(packageId?: number, groupId?: number)
		: Promise<AxiosResponse<GroupPackageAssociation[]>> {
		
		return this._api.get<GroupPackageAssociation[]>(
			`GroupPackageAssociations`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				},
				params: {
					packageId,
					groupId
				}
			}
		)
	}


	/**
	 * Add a single association between a package and a group in the organization
	 * @param groupPkg 
	 */
	public AddPackageAssociation(groupPkg: GroupPackageSimpleAssociation)
		: Promise<Response> {
		return this._api.post(
			`GroupPackageAssociations`,
			JSON.stringify(groupPkg),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Get a single association between packages and groups in the specified organization
	 * @param associationId 
	 */
	public GetAssociation(associationId: number)
		: Promise<AxiosResponse<GroupPackageAssociation>> {
		return this._api.get<GroupPackageAssociation>(
			`GroupPackageAssociations/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Remove the association with the specified id from the database
	 * @param associationId 
	 */
	public RemoveAssociation(associationId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`GroupPackageAssociations/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

	/**
	 * Add multiple associations between N Packages and M groups in the organizations
	 * @param associations 
	 */
	public BulkAddPackageAssociations(associations: GroupPackageSimpleAssociation[])
		: Promise<AxiosResponse> {
		return this._api.post(
			`GroupPackageAssociations/BulkAddition`,
			JSON.stringify(associations),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}
	
}