import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { PortalsClient } from '../../../../api/clients/identity/PortalsClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import ButtonLink from '../../../../components/links/ButtonLink'
import { SessionService } from '../../../../services/session'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import {
	getOrganizationPropertyValueByName,
	queryDataProviderById,
	queryDataProviderByName,
} from '../../../../utils/controlApi/ControlApiAccessors'
import { FimWindow } from '../../../../utils/controlApi/FimWindow'
import OrganizationRoute from '../../../../utils/router/OrganizationRoute'

type HTMLPortalViewerProps = {
	anonymous: boolean
}
export const HTMLPortalViewer = (props: HTMLPortalViewerProps) => {
	const params = useParams<{ portalId: string; versionNumber: string }>()
	const portalId = parseInt(params.portalId)
	const versionNumber = parseInt(params.versionNumber)

	if (isNaN(portalId)) throw new Error('invalid portal id')
	if (isNaN(versionNumber)) throw new Error('invalid version number')

	const controlApi = useMemo(() => {
		return {
			onPortalLoaded: () => {
				// defined in the html portal
				return undefined
			},
			dataProviders: {
				queryById: async (
					instanceId: number,
					parameters: Record<string, string>,
				) => await queryDataProviderById(instanceId, parameters),
				queryByName: async (name: string, parameters: Record<string, string>) =>
					await queryDataProviderByName(name, parameters),
			},
			organizationProperties: {
				getPropertyValueByName: async (propertyName: string) =>
					await getOrganizationPropertyValueByName(propertyName),
			},
		}
	}, [])

	const [data, setData] = useState<Blob>()
	const [unauth, setUnauth] = useState<boolean>(false)
	const [iframe, setIFrame] = useState<HTMLIFrameElement | null>(null)

	const styles = useStyles()

	useEffect(() => {
		const api = new PortalsClient()
		;(async () => {
			try {
				const tempData = await api.GetPortalStream(portalId, versionNumber)
				setData(tempData.data)
			} catch {
				setUnauth(true)
			}
		})()
	}, [portalId, versionNumber])

	useEffect(() => {
		if (iframe === null) return

		iframe.onload = async () => {
			const iframeDocument = iframe.contentDocument
			if (iframeDocument === null || iframeDocument === undefined)
				throw Error('iframe document does not exist')

			const fimWindow = iframe.contentWindow as FimWindow

			if (fimWindow.hook && controlApi !== undefined) {
				fimWindow.hook(controlApi)
				controlApi.onPortalLoaded()
			}
		}
	}, [iframe])

	return (
		<>
			{unauth ? (
				// if not authorized
				<PortalViewErrorPage
					portalId={portalId}
					versionNumber={versionNumber}
					errorMessage={
						<Typography>
							This portal either doesn't exist or you do not have access
						</Typography>
					}
				/>
			) : // if data not loaded yet
			data === undefined ? (
				<FullscreenSpinner />
			) : (
				// displays the portal in an iframe
				<iframe
					src={URL.createObjectURL(data)}
					className={styles.root}
					sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin allow-popups allow-modals allow-downloads"
					allowFullScreen
					scrolling="yes"
					id={`portal-${portalId}`}
					ref={(element) => setIFrame(element)}
				/>
			)}
		</>
	)
}

type PortalViewErrorPageProps = {
	portalId: number
	versionNumber: number
	errorMessage: ReactNode
}

export const PortalViewErrorPage = ({
	portalId,
	versionNumber,
	errorMessage,
}: PortalViewErrorPageProps) => {
	const styles = useStyles()
	const history = useHistory()

	const sessionService = new SessionService()

	const loggedInLocation = history.location
	loggedInLocation.pathname = `/_portals/${portalId}/_versions/${versionNumber}`

	return (
		<div className={styles.errorRoot}>
			{errorMessage}
			<div className={styles.spacing}>
				{sessionService.isLoggedIn ? (
					<ButtonLink
						color="primary"
						to={makeChildRoute(`_home`, '')}
						variant="contained"
					>
						Back to Home
					</ButtonLink>
				) : (
					<OrganizationRoute>
						<HTMLPortalViewer anonymous={false} />
					</OrganizationRoute>
				)}
			</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: percent(100),
		height: percent(100),
		frameBorder: 0,
		borderWidth: 0,
		overflow: 'visible',
	},

	errorRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: percent(100),
		width: percent(100),
		alignItems: 'center',
		justifyContent: 'center',
	},

	spacing: {
		padding: theme.spacing(5, 0),
	},
}))
