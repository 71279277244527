import { makeAutoObservable } from "mobx"
import { NIL, v4 as uuidv4 } from "uuid"
import { CellInstance } from "../../FormHost/Types/CellInstance"
import { CellType } from "../../FormHost/Types/CellType"
import FieldType from "../../FormHost/Types/FieldType"
import { EventBusEvent, EventBusEventConsumer, EventBusEventSource } from "./EventTypes"


export class EventBus {

	private consumerArray: {
		id: string
		consumer: EventBusEventConsumer
	}[] = []

    constructor() {
        makeAutoObservable(this)
    }

	public async emit(event: EventBusEvent) {
		/* notice that this function tries to behave like JS - it will traverse
		up the tree for a change event */

		const formHost = event.eventSource.formHost

		console.log(`${event.displayName ?? 'unnamed'} ${event.eventId} event emitted`)

		for (const { consumer } of this.consumerArray) {
			if (consumer.eventId && consumer.eventId !== event.eventId)
				continue

			let currentEventSource: EventBusEventSource = event.eventSource

			do {
				const instance = formHost.cellInstances.find(v => v.id === currentEventSource.instanceId)
				const definition = formHost.cellDefinitions.find(v => v.id === currentEventSource.definitionId)

				// if we ended up here, we are at either a virtual or void component that needs to be skipped
				if (definition !== undefined 
					&& instance !== undefined 
					&& (definition.fieldType === FieldType.Virtual || definition.fieldType === FieldType.Void)) {

					currentEventSource = {
						elementTag: NIL,
						definitionId: definition.parentId,
						instanceId: instance?.parentId,
						formHost
					}

					continue
				}

				// success case, if we make it here then we have a parent definition and a parent instance that we can invoke an event on
				await consumer.shouldActivateAsync(event.eventId, event.props, event.eventSource, currentEventSource).then((response) => {
					if (response === true) {
						consumer.actionAsync(event.eventId, event.props, event.eventSource, currentEventSource)
					}
				})

				// if we ended up here then we were at the root, and we were going to try to go to the root again
				if (currentEventSource.instanceId === NIL)
					break


				/* we are at the root, we need one more iteration with a "NIL target" that represents the false root
				at the bottom of the form builder (definitionId = NIL, elementTag = NIL, etc...) */
				if (definition?.parentId === NIL) {
					currentEventSource = {
						elementTag: NIL,
						definitionId: NIL,
						instanceId: NIL,
						formHost
					}

					continue
				}

				const parentDefinition = formHost.cellDefinitions.find(v => v.id === definition?.parentId)
				let parentInstance: CellInstance | undefined = formHost.cellInstances.find(v => v.id === instance?.parentId)
				if (parentInstance?.type === CellType.Virtual)
					parentInstance = formHost.cellInstances.find(v => v.id === parentInstance?.parentId)
				
				if (parentDefinition === undefined || parentInstance === undefined)
					throw new Error("how did we get here!?")

				currentEventSource = {
					elementTag: parentDefinition?.elementTag ?? NIL,
					definitionId: parentDefinition.id,
					instanceId: parentInstance.id,
					formHost
				}
				/* realistically we may never end up hitting the failure case for this while since 
				our most common exit condition will be us stopping at the root and manually breaking out */
			} while (currentEventSource !== undefined)

		}

	}

	public registerConsumer<TProps = unknown, TSource extends EventBusEventSource = EventBusEventSource>(consumer: EventBusEventConsumer<TProps, TSource>) {
		const id = uuidv4()
		this.consumerArray.push({ id: id, consumer: consumer as EventBusEventConsumer<unknown, EventBusEventSource>})
		return id
	}

	public removeConsumer(id: string) {
		const index = this.consumerArray.findIndex(v => v.id === id)
		if (index !== -1)
			this.consumerArray.splice(index, 1)
	}
}
