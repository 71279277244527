import { observer } from 'mobx-react'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyVoidComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import {
	NumberPropertyConfigurator,
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'

type ImageComponentProps = {
	imageUrl: string
	width: number
	height: number
	alignment: string
}

export const ImageComponent = observer((props: ImageComponentProps) => {
	return (
		<div
			style={{
				flex: 1,
				display: 'flex',
				justifyContent: props.alignment,
			}}
		>
			<img
				alt="img"
				width={props.width}
				height={props.height}
				src={props.imageUrl}
			/>
		</div>
	)
})

export const ImageComponentElementTag = '226c228c-8b39-4de8-a7f7-25b73a8aa942'

export const ImageComponentDefinitionId = '6fdf4642-bf31-42df-aa87-4148be6b4848'

const ImageComponentPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof ImageComponent>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.imageUrl}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, imageUrl: v })
				}
				Editor={TextPropertyConfigurator}
				label="Image URL"
			/>
			<PropertySchematicConfigurator
				value={properties.height}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, height: v })
				}
				Editor={NumberPropertyConfigurator}
				label="Height"
			/>
			<PropertySchematicConfigurator
				value={properties.width}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, width: v })}
				Editor={NumberPropertyConfigurator}
				label="Width"
			/>
			<PropertySchematicConfigurator
				value={properties.alignment}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, alignment: v })
				}
				Editor={(props) => (
					<SelectPropertyConfigurator
						options={[
							{ key: 'flex-start', displayValue: 'Left to Right' },
							{ key: 'center', displayValue: 'centered' },
							{ key: 'flex-end', displayValue: 'Right to Left' },
						]}
						{...props}
					/>
				)}
				label="Image Alignment"
			/>
		</>
	)
}

export const ImageComponentDefinition = specifyVoidComponent(
	ImageComponentDefinitionId,
	ImageComponent,
	ImageComponentElementTag,
	'Image',
	{
		configurator: ImageComponentPropsEditor,
		defaultProperties: {
			imageUrl:
				'https://www.keymarkinc.com/wp-content/uploads/2017/12/keymark-onbase-reseller.svg',
			width: 400,
			height: 200,
			alignment: 'flex-start',
		},
	},
)
