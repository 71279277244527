import {
	Check,
	Close,
	Error,
	Info,
	Notifications,
	Warning,
} from '@mui/icons-material'
import {
	Badge,
	Box,
	Divider,
	IconButton,
	Popover,
	Typography,
} from '@mui/material'
import { isObservable } from 'mobx'
import { observer } from 'mobx-react'
import { Fragment, useState } from 'react'
import { useNotificationCenterCollection } from './NotificationCenterContext'
import { NotificationCenterNotification } from './Types'

const NotificationCenterIcon = observer(() => {
	const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>()

	const notificationCollection = useNotificationCenterCollection()

	const badgeColor = (() => {
		if (notificationCollection.find((v) => v.notification.status === 'error'))
			return 'error'
		if (notificationCollection.find((v) => v.notification.status === 'warning'))
			return 'warning'
		if (notificationCollection.find((v) => v.notification.status === 'info'))
			return 'info'
		if (notificationCollection.find((v) => v.notification.status === 'success'))
			return 'success'
		return 'success'
	})()

	return (
		<div>
			<IconButton
				color="inherit"
				onClick={(event) => setAnchorElement(event.currentTarget)}
			>
				<Badge badgeContent={notificationCollection.length} color={badgeColor}>
					<Notifications color="inherit" />
				</Badge>
			</IconButton>

			<Popover
				open={Boolean(anchorElement)}
				anchorEl={anchorElement}
				onClose={() => setAnchorElement(undefined)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box
					width="400px"
					minHeight="80px"
					display="flex"
					flexDirection="column"
					padding={1}
				>
					{notificationCollection.length === 0 ? (
						<Box
							flex="1"
							justifySelf="center"
							alignSelf="center"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Typography>There are no notifications</Typography>
						</Box>
					) : (
						notificationCollection.map((v, i) => (
							<Fragment key={v.id}>
								<NotificationElement
									notification={v.notification}
									onClose={v.remove.bind(v)}
								/>
								{i !== notificationCollection.length - 1 && (
									<Box component={Divider} marginX={-1} />
								)}
							</Fragment>
						))
					)}
				</Box>
			</Popover>
		</div>
	)
})

type NotificationElementProps = {
	notification: NotificationCenterNotification
	onClose: () => void
}

const NotificationElement = observer((props: NotificationElementProps) => {
	console.log('re-render!!')

	const notification = props.notification

	if (!isObservable(notification))
		console.log('notification lost observability')

	return (
		<Box display="flex" width="100%" flexDirection="column">
			<Box flex="1" display="flex" width="100%" gap={1} alignItems="center">
				{(() => {
					if (notification.status === 'success')
						return <Check color="success" />
					if (notification.status === 'info') return <Info color="info" />
					if (notification.status === 'warning')
						return <Warning color="warning" />
					if (notification.status === 'error') return <Error color="error" />
				})()}

				{typeof notification.title === 'string' ? (
					<Typography flex="1" fontWeight={'625'}>
						{notification.title}
					</Typography>
				) : (
					<Box flex="1">{notification.title}</Box>
				)}

				<Box
					alignSelf="flex-end"
					justifySelf="flex-end"
					display="flex"
					justifyContent="center"
					flex="0"
				>
					<IconButton onClick={props.onClose}>
						<Close />
					</IconButton>
				</Box>
			</Box>
			<Box display="flex" flex="1" fontSize={'small'}>
				{notification.body}
			</Box>
		</Box>
	)
})

export default NotificationCenterIcon
