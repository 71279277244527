import { Box, TextField } from '@mui/material'
import { Identifier, MemberExpression } from 'estree'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { RendererComponent, WithFormsInfo } from '../../../Types'

const QueryStringComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const formsInfo = props.node.formsInfo

		if (formsInfo === undefined || formsInfo.type !== 'QueryStringAccess')
			throw new Error(
				`Query String component requires FormsInfo type of 'QueryStringAccess', got ${formsInfo?.type}`,
			)

		const propertyNode = useMemo(() => {
			return props.node.property as WithFormsInfo<Identifier>
		}, [props.node.property])

		return (
			<Box>
				<TextField
					variant="standard"
					value={propertyNode.name}
					onChange={(evt) => {
						propertyNode.name = evt.currentTarget.value
					}}
				/>
			</Box>
		)
	},
)

export default QueryStringComponent
