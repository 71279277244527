import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import UserClient from '../../../../../api/clients/identity/UserClient'
import { toastService } from '../../../../../services/notifications/ToastService'
import { SessionService } from '../../../../../services/session'
import { FixedHeaderWithHint } from '../../../../../utils/HOC/FixedHeaders'

const validationSchema = yup.object({
	firstName: yup.string().required('first name is required'),
	lastName: yup.string().required('last name is required'),
	emailAddress: yup
		.string()
		.email('enter a valid email address')
		.required('email is required'),
})

type CreateUserDialogProps = {
	open: boolean
	onUserCreated: (id: string) => void
	onExit: () => void
}

const CreateUserDialog = (props: CreateUserDialogProps) => {
	const sessionService = new SessionService()
	const history = useHistory()

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			emailAddress: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const userClient = new UserClient()
			await userClient
				.AdminRegister({
					users: [
						{
							firstName: values.firstName,
							lastName: values.lastName,
							emailAddress: values.emailAddress,
						},
					],
				})
				.then((response) => {
					if (response.data[0].errors.length != 0) {
						toastService.displayToast({
							message: response.data[0].errors[0],
							area: 'global',
						})
					} else {
						const user = response.data[0].baseUser
						history.push(`_users/${user.id}`)
						toastService.displayToast({
							message: 'User created',
							area: 'global',
						})
					}
				})
				.catch((error) => {
					console.log(error.response.data)
					toastService.displayToast({
						message: 'Error creating user',
						area: 'global',
					})
				})
		},
	})

	const styles = useStyles()

	return (
		<Dialog
			disableEscapeKeyDown={formik.isSubmitting}
			open={props.open}
			onClose={props.onExit}
		>
			<DialogTitle>Create a New User</DialogTitle>
			<DialogContent>
				<div className={styles.createUserDialogRoot}>
					<Typography>
						Create a new user within the current organization.
					</Typography>
					<FixedHeaderWithHint
						label="First Name"
						hint={formik.touched.firstName && formik.errors.firstName}
						hintColor="error.main"
						className={styles.createUserDialogItem}
					>
						<TextField
							fullWidth
							id="firstName"
							name="firstName"
							value={formik.values.firstName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								formik.touched.firstName !== undefined &&
								formik.errors.firstName !== undefined
							}
							autoComplete="nope"
						/>
					</FixedHeaderWithHint>
					<FixedHeaderWithHint
						label="Last Name"
						hint={formik.touched.lastName && formik.errors.lastName}
						hintColor="error.main"
						className={styles.createUserDialogItem}
					>
						<TextField
							fullWidth
							id="lastName"
							name="lastName"
							value={formik.values.lastName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={!!formik.touched.lastName && !!formik.errors.lastName}
							autoComplete="nope"
						/>
					</FixedHeaderWithHint>
					<FixedHeaderWithHint
						label="Email Address"
						hint={formik.touched.emailAddress && formik.errors.emailAddress}
						hintColor="error.main"
						className={styles.createUserDialogItem}
					>
						<TextField
							fullWidth
							id="emailAddress"
							name="emailAddress"
							value={formik.values.emailAddress}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								!!formik.touched.emailAddress && !!formik.errors.emailAddress
							}
							autoComplete="nope"
						/>
					</FixedHeaderWithHint>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={formik.isSubmitting}
					className={styles.buttonLeftMargin}
					color="primary"
					onClick={props.onExit}
				>
					Close
				</Button>
				<Button
					disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
					color="primary"
					variant="contained"
					onClick={formik.submitForm}
				>
					Create User
				</Button>
			</DialogActions>
			{formik.isSubmitting && <LinearProgress />}
		</Dialog>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	createUserDialogRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),

		padding: theme.spacing(0, 2),

		[theme.breakpoints.up('sm')]: {
			minWidth: px(300),
		},
	},

	createUserDialogItem: {
		margin: theme.spacing(1, 0),
	},

	buttonLeftMargin: {
		margin: theme.spacing(0, 2, 0, 0),
	},
}))

export default CreateUserDialog
