import { CellDefinition } from "../modules/FormHost/Types/CellDefinition"
import { PdfCellProperties } from "../modules/FormIntegrations/pdfFormHost/Types/PdfCellDefinition"

// returns an array of cell definitions, with the overrides replacing corresponding definitions
export function MergeDefinitionOverrides(definitions: CellDefinition[], cellOverrides: CellDefinition[]) {	
	const mergedDefinitions: CellDefinition[] = []

	definitions.forEach((cell) => {
		const overrideIndex = cellOverrides.findIndex((override) => override.id === cell.id)

		// if an override is found, push that instead of the cell definition
		if (overrideIndex !== -1)
			mergedDefinitions.push(cellOverrides[overrideIndex])
		else
			mergedDefinitions.push(cell)
	})

	return mergedDefinitions
}

// if an override no longer has a cell definition that has a matching id, it is removed
export function RemoveUnusedOverrides(definitions: CellDefinition[], cellOverrides: CellDefinition[]) {
	let indexToRemove = -1
	cellOverrides.forEach((override) => {
		const foundIndex = definitions.findIndex((cell) => { return cell.id === override.id })
		// if no cell is found in definitions that matches an override OR if an override is the same as a definition
		// AND if there is no mask applied to the cell
		const properties = override.properties as PdfCellProperties
		if (foundIndex === -1 ||
			((override.elementTag === definitions[foundIndex].elementTag &&
				override.fieldType === definitions[foundIndex].fieldType) &&
			(properties.mask === undefined || properties.mask === ''))) {
			indexToRemove = cellOverrides.findIndex((cell) => { return cell.id === override.id })
			cellOverrides.splice(indexToRemove, 1)
		}
	})
	return cellOverrides
}