import { ComponentType } from 'react'
import { CellType } from '../../FormHost/Types/CellType'
import FieldType, {
	isValueType,
	ReferenceFieldTypes,
	ValueArrayFieldTypes,
	ValueFieldTypes,
} from '../../FormHost/Types/FieldType'
import {
	ComponentRegistration,
	ConfigurableComponentRegistration,
	ConfigurableContainerComponentRegistration,
	ConfigurableValueComponentRegistration,
	ValueComponentRegistration,
} from './ComponentTypes'

export type PropsEditorComponent<T> = {
	properties: T
	onPropertiesChanged(v: T): void
}

type ComponentPropertiesConfiguration<T> = {
	configurator: ComponentType<PropsEditorComponent<T>>
	defaultProperties: T
}

export type ValuesEditorComponent<T> = {
	value: unknown
	onValueChanged(v: unknown): void
	properties: T
}

type ComponentValuesConfiguration<T> = {
	configurator: ComponentType<ValuesEditorComponent<T>>
	defaultValue: unknown // possible we can do this with a real type
}

export function specifyInternalComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	fieldType: ValueFieldTypes | ValueArrayFieldTypes,
	defaultProperties: T,
	defaultValue: unknown,
): ValueComponentRegistration<T>
export function specifyInternalComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	fieldType: ReferenceFieldTypes,
	defaultProperties: T,
): ComponentRegistration
export function specifyInternalComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	fieldType: FieldType,
	defaultProperties: T,
	defaultValue?: unknown | undefined,
): ComponentRegistration<T> {
	const type = isValueType(fieldType)
		? CellType.Value
		: fieldType === FieldType.Object
		? CellType.Layout
		: fieldType === FieldType.ObjectArray
		? CellType.Repeat
		: CellType.Concrete

	const specification: ComponentRegistration<T> = {
		type,
		id,
		component,
		componentId: elementTag,
		defaultProperties,
		fieldType,
	}

	if (isValueType(fieldType)) {
		;(specification as ValueComponentRegistration<T>).defaultValue = {
			type: 'static',
			value: defaultValue,
		}
	}

	return specification
}

export function specifyValueTypeComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	displayName: string,
	fieldType: ValueFieldTypes | ValueArrayFieldTypes,
	properties: ComponentPropertiesConfiguration<T>,
	value: ComponentValuesConfiguration<T>,
): ConfigurableValueComponentRegistration<T> {
	const specification: ConfigurableValueComponentRegistration<T> = {
		type: CellType.Value,
		id,
		component,
		componentId: elementTag,
		displayName,
		fieldType: fieldType,
		propertiesConfigurator: properties.configurator,
		defaultProperties: properties.defaultProperties,
		valueConfigurator: value.configurator,
		defaultValue: value.defaultValue,
	}

	return specification
}

export function specifyObjectComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	displayName: string,
	properties: ComponentPropertiesConfiguration<T>,
	requiredChildren: (v: Readonly<T>) => number,
) {
	const specification: ConfigurableContainerComponentRegistration<T> = {
		type: CellType.Layout,
		id,
		component,
		componentId: elementTag,
		displayName,
		propertiesConfigurator: properties.configurator,
		defaultProperties: properties.defaultProperties,
		requiredChildren,
		fieldType: FieldType.Object,
	}

	return specification
}

export function specifyRepeatComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	displayName: string,
	properties: ComponentPropertiesConfiguration<T>,
	requiredChildren: (v: Readonly<T>) => number,
): ConfigurableContainerComponentRegistration<T> {
	const specification: ConfigurableContainerComponentRegistration<T> = {
		type: CellType.Repeat,
		id,
		component,
		componentId: elementTag,
		displayName,
		propertiesConfigurator: properties.configurator,
		defaultProperties: properties.defaultProperties,
		requiredChildren,
		fieldType: FieldType.ObjectArray,
	}

	return specification
}

export function specifyVoidComponent<T extends Record<string, unknown>>(
	id: string,
	component: ComponentType<T>,
	elementTag: string,
	displayName: string,
	properties: ComponentPropertiesConfiguration<T>,
) {
	const specification: ConfigurableComponentRegistration<T> = {
		type: CellType.Concrete,
		id,
		component,
		componentId: elementTag,
		displayName,
		propertiesConfigurator: properties.configurator,
		defaultProperties: properties.defaultProperties,
		fieldType: FieldType.Void,
	}

	return specification
}
