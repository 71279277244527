import { Search } from '@mui/icons-material'
import { Box, OutlinedInput } from '@mui/material'

type FilteringSearchBarProps = {
	values: string[]
	onQuery: (matches: string[]) => void
}

// displays a search bar that queries onChange and returns the values
// that match the query
export const FilteringSearchBar = (props: FilteringSearchBarProps) => {
	const handleQuery = (queryValue: string) => {
		if (queryValue === '') {
			props.onQuery(props.values)
			return
		}
		props.onQuery(
			props.values.filter((v) =>
				v.toUpperCase().includes(queryValue.toUpperCase()),
			),
		)
	}

	return (
		<Box width="100%">
			<OutlinedInput
				fullWidth
				size="small"
				placeholder="Search"
				endAdornment={<Search />}
				onChange={(evt) => handleQuery(evt.target.value)}
			/>
		</Box>
	)
}
