/**
 * This is so we can compare state within data providers, auth providers, etc. to know
 * if the values in the existing state equal the values in the updated state. The updated
 * state uses the FormBuilder so there's some extra properties on it for "$props", which
 * the existing state doesn't have. So we only want to compare the properties the states
 * have in common, which are the values
 * @param existingState the state from the db
 * @param newState the state used in the form builder
 * @returns true if the values are all the same, false if there's a value that is not the same
 */

export function compareState(
	existingState: Record<string, unknown>,
	newState: Record<string, unknown>,
): boolean {
	for (const key in existingState) {
		if (newState[key] !== undefined) {
			if (
				typeof existingState[key] === 'object' &&
				typeof newState[key] === 'object'
			) {
				if (
					!compareState(
						existingState[key] as Record<string, unknown>,
						newState[key] as Record<string, unknown>,
					)
				)
					return false
			} else if (existingState[key] !== newState[key]) {
				return false
			}
		}
	}

	return true
}