import { TextField, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Inputmask from 'inputmask'
import { observer } from 'mobx-react'
import React, { createRef, useEffect } from 'react'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type TextInputProps = {
	label?: string
	mask?: string
	hint?: string
	multiline?: boolean
	value: string
	onChange: React.ChangeEventHandler<HTMLInputElement>
	onBlur?: React.FocusEventHandler<HTMLInputElement>
}

export const TextInput = observer((props: TextInputProps) => {
	const styles = useStyles()

	const inputRef = createRef<HTMLDivElement>()

	useEffect(() => {
		if (
			props.mask === undefined ||
			props.mask === '' ||
			inputRef.current === null
		)
			return

		const inputMask = new Inputmask({
			mask: props.mask,
		})

		inputMask.mask(inputRef.current)
	}, [props.mask])

	return (
		<FixedHeaderWithHint
			className={styles.formBuilderTextInputRoot}
			label={props.label}
			hint={props.hint}
		>
			<TextField
				fullWidth
				variant="outlined"
				size="small"
				multiline={props.multiline}
				value={props.value ?? ''}
				onChange={props.onChange}
				onBlur={props.onBlur}
				autoComplete="off"
				inputRef={inputRef}
			/>
		</FixedHeaderWithHint>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	formBuilderTextInputRoot: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
}))
