import { Box, Paper, TablePagination } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
	WorkflowDefinitionBase,
	WorkflowInstanceBase,
	WorkflowStatus,
} from '../../../../../api/clients/workflows/DTOs'
import WorkflowsClient from '../../../../../api/clients/workflows/WorkflowsClient'
import { toastService } from '../../../../../services/notifications/ToastService'
import { WorkflowsHistoryFilters } from './WorkflowHistoryFilters'
import { WorkflowsHistoryTable } from './WorkflowHistoryTable'

type WorkflowHistoryDashboardProps = {
	originalHistory: ReturnType<typeof useHistory>
	workflowId: number
	definitions: WorkflowDefinitionBase[]
}

export enum SortBy {
	Newest = 0,
	Oldest = 1,
}

export type Filters = {
	definitionId: number[] | undefined
	status: WorkflowStatus[] | undefined
	startDateFrom: Date | undefined
	startDateTo: Date | undefined
	sortBy: SortBy
}

export type WorkflowInstanceFilter = {
	definitionId?: number[]
	status?: WorkflowStatus[]
	startDateFrom?: string
	startDateTo?: string
	pageNumber?: number
	pageSize?: number
	sortBy?: SortBy
}

export const WorkflowHistoryDashboard = (
	props: WorkflowHistoryDashboardProps,
) => {
	const [instances, setInstances] = useState<WorkflowInstanceBase[]>([])
	const [filters, setFilters] = useState<Filters>({
		definitionId: undefined,
		status: undefined,
		startDateFrom: undefined,
		startDateTo: undefined,
		sortBy: SortBy.Newest,
	})
	const [pageNumber, setPageNumber] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [instancesCount, setInstancesCount] = useState(0)

	useEffect(() => {
		// if we change filters/page size we need to go back to the first page
		if (pageNumber !== 0) setPageNumber(0)

		getFilteredInstances()
	}, [filters, pageSize])

	useEffect(() => {
		getFilteredInstances()
	}, [pageNumber])

	const workflowsClient = new WorkflowsClient()

	useEffect(() => {
		// get the instance count to use in pagination
		workflowsClient
			.getInstanceCount(props.workflowId, {
				definitionId: filters.definitionId,
				status: filters.status,
				startDateFrom: filters.startDateFrom?.toJSON(),
				startDateTo: filters.startDateTo?.toJSON(),
			})
			.then((response) => setInstancesCount(response.data))
	}, [props.workflowId, filters])

	const getFilteredInstances = useCallback(() => {
		workflowsClient
			.getFilteredInstanceHistory(props.workflowId, {
				definitionId: filters.definitionId,
				status: filters.status,
				startDateFrom: filters.startDateFrom?.toJSON(),
				startDateTo: filters.startDateTo?.toJSON(),
				pageNumber: pageNumber,
				pageSize: pageSize,
				sortBy: filters.sortBy,
			})
			.then((response) => setInstances(response.data))
			.catch((e) => {
				console.error(e)

				toastService.displayToast({
					message: 'Error loading workflow instances',
					area: 'global',
					severity: 'error',
				})
			})
	}, [props.workflowId, filters, pageNumber, pageSize])

	return (
		<Box component={Paper} marginY={2}>
			<WorkflowsHistoryFilters
				definitions={props.definitions}
				filters={filters}
				onChange={setFilters}
			/>
			<WorkflowsHistoryTable
				originalHistory={props.originalHistory}
				workflowId={props.workflowId}
				instances={instances}
				definitions={props.definitions}
			/>
			<TablePagination
				component="div"
				count={instancesCount}
				page={pageNumber}
				onPageChange={(_, page) => setPageNumber(page)}
				rowsPerPage={pageSize}
				onRowsPerPageChange={(evt) => {
					setPageSize(parseInt(evt.target.value, 10))
					setPageNumber(0)
				}}
			/>
		</Box>
	)
}
