import { AxiosResponse } from "axios";
import { action, computed, makeObservable, observable } from "mobx";
import { NIL, v4 as uuid, validate } from "uuid";
import { ActivityInstance, BaseActivityDefinitionModel, BaseConnectionDefinitionModel, WorkflowDefinition, WorkflowInstance, WorkflowLogEntry, WorkflowTransition } from "../../../api/clients/workflows/DTOs";
import WorkflowsClient from "../../../api/clients/workflows/WorkflowsClient";
import { toastService } from "../../../services/notifications/ToastService";

type ConnectionType = {
	sourceActivityId: string
	destinationActivityId: string
	outcome: string
}

export default class WorkflowContext {

	public readonly workflowId: number

	private _definitionId: number | undefined;

	public get definitionId(): number | undefined {
		return this._definitionId
	}

	private _instanceId: number | undefined;

	public get instanceId(): number | undefined {
		return this._instanceId
	}

	public get isReadonly(): boolean {
		return !!this.instanceId
	}

	public workflow: WorkflowDefinition | undefined;

	public instance: WorkflowInstance | undefined;

	public get hasBeenModified(): boolean {
		return this._hasBeenModified
	}

	private _hasBeenModified = false;

	public get hasFinishedLoading(): boolean {
		return this._hasFinishedLoading
	}

	private _hasFinishedLoading = false;

	private readonly client = new WorkflowsClient()



	/**
	 * 
	 * @param workflowId the id of the workflow being fetched
	 * @param definitionId the version we are fetching, if not defined then the published version is fetched
	 * @param instanceId the instance that will be fetched, this will cause the context to become read-only if set
	 */
	constructor(workflowId: number,
		definitionId: number | undefined,
		instanceId: number | undefined) {
		makeObservable<WorkflowContext, "_definitionId" | "_instanceId" | "_hasBeenModified" | "_hasFinishedLoading" | "selectedActivityDefinitionId">(this, {
			_definitionId: observable,
			definitionId: computed,
			_instanceId: observable,
			instanceId: computed,
			isReadonly: computed,
			workflow: observable,
			instance: observable,
			hasBeenModified: computed,
			_hasBeenModified: observable,
			hasFinishedLoading: computed,
			_hasFinishedLoading: observable,
			name: computed,
			activityDefinitions: computed,
			connections: computed,
			createConnection: action,
			removeConnection: action,
			activityInstances: computed,
			workflowLog: computed,
			transitionLog: computed,
			saveAndPublishWorkflow: action,
			saveWorkflowDraft: action,
			selectedActivityDefinitionId: observable
		});

		this.workflowId = workflowId
		this._definitionId = definitionId
		this._instanceId = instanceId

		this.selectedActivityDefinitionId = undefined

		let promise: Promise<unknown>

		if (workflowId && definitionId && instanceId) {
			promise = this.client.getWorkflowInstance(workflowId, definitionId, instanceId)
				.then(v => v.data)
				.then(v => {
					this.workflow = v.workflowDefinition
					this.instance = v
				})
		}
		else if (workflowId && definitionId) {
			promise = this.client.getDefinition(workflowId, definitionId)
				.then(v => v.data)
				.then(v => {
					this.workflow = v
				})
		}
		else {
			promise = this.client.getPublishedDefinition(workflowId)
				.then(v => v.data)
				.then(v => {
					console.log('request finished')
					if (v.id)
						this.workflow = v
				})
				.catch(() => {
					this.workflow = {
						rowVersion: '',
						createdBy: 0,
						createdDate: new Date(),
						lastModifiedBy: 0,
						lastModifiedDate: new Date(),
						activityDefinitions: [],
						connectionDefinitions: [],
						version: 0,
						isDraft: false,
						isPublished: false,
						workflow: {
							name: "",
							uniqueId: NIL,
							id: 0,
							enabled: false
						},
						id: 0,
						uniqueId: NIL,
						workflowId: 0
					}
				})
		}

		promise.finally(() => {
			this._hasFinishedLoading = true
		})
	}

	public get name(): string {
		return this.workflow?.workflow.name ?? ""
	}

	public get enabled(): boolean {
		return this.workflow?.workflow.enabled ?? false
	}

	public updateWorkflow = async (name?: string, enabled?: boolean) => {
		if (this.workflow)
			this.client.updateWorkflow(this.workflowId, { workflowName: name ?? this.name, enabled: enabled ?? this.enabled })
		this._hasBeenModified = true
	}

	//#region Activity Definitions

	public get activityDefinitions(): BaseActivityDefinitionModel[] {
		const definitions = this.workflow?.activityDefinitions ?? []
		return definitions
	}

	public addDefinition = (config: BaseActivityDefinitionModel): boolean => {
		if (!this.workflow || this.isReadonly)
			return false

		console.log('hello?')

		if (!validate(config.uniqueId) && NIL !== config.uniqueId)
			config.uniqueId = uuid()

		console.log('did we get here?')

		if (this.workflow.activityDefinitions === undefined)
			throw "cannot add activity definitions"

		console.log('hello!')

		this.workflow.activityDefinitions.push(config)
		this._hasBeenModified = true
		return true
	}

	public removeDefinition = (id: string): boolean => {
		if (!this.workflow || this.isReadonly)
			return false

		const index = this.activityDefinitions.findIndex(v => v.uniqueId == id)
		this._hasBeenModified = true
		this.activityDefinitions.splice(index, 1)

		this.workflow.connectionDefinitions = this.connections
			.filter(v => v.sourceActivityId !== id && v.destinationActivityId !== id)

		return true
	}

	public getDefinition = (definitionId: string): BaseActivityDefinitionModel | undefined => {
		if (!this.workflow)
			return undefined

		return this.workflow.activityDefinitions.find(v => v.uniqueId == definitionId)
	}

	// selected definition id is in context so we don't cause unnecessary rerenders w/ local store 
	// and passing as props
	public selectedActivityDefinitionId: string | undefined

	//#endregion

	//#region Connections

	public get connections(): BaseConnectionDefinitionModel[] {
		return this.workflow?.connectionDefinitions ?? []
	}

	public createConnection = (props: ConnectionType): boolean => {

		if (!this.workflow || this.isReadonly)
			return false

		this.connections.push({
			destinationActivityId: props.destinationActivityId,
			sourceActivityId: props.sourceActivityId,
			outcome: props.outcome || "Done",
			workflowDefinitionId: this.definitionId ?? 0,
			uniqueId: uuid()
		})
		this._hasBeenModified = true
		return true
	};

	public removeConnection = (props: ConnectionType): boolean => {

		console.log('removing connection')
		if (!this.workflow || this.isReadonly)
			return false

		const index = this.connections.findIndex(v => v.sourceActivityId == props.sourceActivityId
			&& v.destinationActivityId == props.destinationActivityId)

		if (index < 0)
			return false

		const removeIndex = this.workflow.connectionDefinitions.findIndex(v =>
			v.sourceActivityId === props.sourceActivityId
			&& v.destinationActivityId === props.destinationActivityId)

		this.workflow.connectionDefinitions.splice(removeIndex, 1)

		console.log('total connection count: ', this.workflow.connectionDefinitions.length);

		this._hasBeenModified = true
		return true
	};

	//#endregion Connections

	public get activityInstances(): ActivityInstance[] {
		return this.instance?.activityInstances ?? []
	}

	public get workflowLog(): WorkflowLogEntry[] {
		return this.instance?.workflowLog ?? []
	}

	public get transitionLog(): WorkflowTransition[] {
		return this.instance?.transitionLog ?? []
	}

	public saveAndPublishWorkflow = async (): Promise<boolean> => {
		if (this.workflow === undefined)
			return false

		this._hasFinishedLoading = false

		try {
			console.log('publishing workflow: ', JSON.stringify(this.workflow))
			const result = await this.client.publishWorkflow(this.workflowId, this.workflow)

			toastService.displayToast({ message: 'Workflow published', area: 'global', severity: 'success' })
			return this.swapWorkflow(result)
		} catch (e) {
			console.error(e)
			toastService.displayToast({ message: 'Error publishing workflow', area: 'global', severity: 'error' })
			return false
		} finally {
			this._hasFinishedLoading = true
		}
	};

	public saveWorkflowDraft = async (): Promise<boolean> => {
		if (this.workflow === undefined)
			return false

		this._hasFinishedLoading = false

		console.log(JSON.stringify(this.workflow))

		try {
			const result = await this.client.uploadDraft(this.workflowId, this.workflow)

			toastService.displayToast({
				message: 'Workflow draft saved',
				area: 'global',
			})

			return this.swapWorkflow(result)
		}
		catch {
			toastService.displayToast({
				message: 'Error saving workflow draft',
				area: 'global'
			})
			return false
		}
		finally {
			this._hasFinishedLoading = true
		}
	};

	private swapWorkflow = (workflowResponse: AxiosResponse<WorkflowDefinition>): boolean => {
		if (workflowResponse.status >= 200 && workflowResponse.status < 300) {
			this.workflow = workflowResponse.data

			this._hasBeenModified = false
		}
		return true
	}
}