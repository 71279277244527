
import { action, computed, makeObservable, observable } from 'mobx';

export type ActionDataType = {
	header: string
	message: React.ReactNode
	action: () => void
	actionButtonText?: string
}

export type ToastDataType = {
	message: string
	delay?: number
}

export type NotificationDataType = {
	header: string
	message: React.ReactNode
	okButtonText?: string
}

export class ObservableQueue<T> {

	/**
	 *
	 */
	constructor() {
        makeObservable<ObservableQueue<T>, "_queue">(this, {
            _queue: observable,
            head: computed,
            enqueue: action,
            dequeue: action
        });

    }

	private _queue: T[] = [];

	get length(): number {
		return this._queue.length
	}

	get head(): T | null {
		return this._queue.length > 0
			? this._queue[0]
			: null
	}

	public onDequeue: (() => void) | null = null
	public onEnqueue: (() => void) | null = null

	/**
	 * pushes new item to queue and triggers onEnqueue
	 * @param value - ToastDataType = {message: string, delay?: number(in seconds)}
	 */
	public enqueue = (value: T) => {
		this._queue.push(value)
		this.onEnqueue && this.onEnqueue()
	};

	/**
	 * remove and return next item in queue and trigger onDequeue
	 */
	public dequeue = () => {
		const removedItem = this._queue.shift()
		this.onDequeue && this.onDequeue()
		return removedItem
	};

}

class NotificationService {

	private static _instance : NotificationService | null

	public static get Instance(): NotificationService {
		if (!NotificationService._instance)
			NotificationService._instance = new NotificationService()
		return NotificationService._instance
	}

	public ActionQueue: ObservableQueue<ActionDataType> = new ObservableQueue<ActionDataType>()
	public NotificationQueue: ObservableQueue<NotificationDataType> = new ObservableQueue<NotificationDataType>()
	public ToastQueue: ObservableQueue<ToastDataType> = new ObservableQueue<ToastDataType>()

}

export const notificationService = NotificationService.Instance
