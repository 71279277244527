import {
	Add,
	AddLink,
	Delete,
	KeyboardArrowDown,
	KeyboardArrowRight,
	LinkOff,
} from '@mui/icons-material'
import {
	Box,
	Button,
	Divider,
	Fade,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { LinkedField } from '../../../../api/DTOtemp'
import { Field } from '../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/LinkedFieldsSection/FieldMappingsPage'
import { LinkFieldsDialog } from '../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/LinkedFieldsSection/LinkFieldsDialog'
import { toastService } from '../../../../services/notifications/ToastService'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'
import { AddFieldMappingDialog } from './AddFieldMappingDialog'
import {
	FieldMappingSource,
	PropertyFieldMapping,
	SourceType,
} from './FieldMappingAction'

type ConfiguredFieldMappingsProps = {
	sources: FieldMappingSource[]
	fields: Field[]
	fieldMappings: PropertyFieldMapping[]
	onValueChange: (fieldMappings: PropertyFieldMapping[]) => void
}

export const ConfiguredFieldMappingsTable = observer(
	(props: ConfiguredFieldMappingsProps) => {
		const [addDialogOpen, setAddDialogOpen] = useState(false)
		const styles = useStyles()

		const handleAddFieldMapping = (action: PropertyFieldMapping) => {
			const newArray = props.fieldMappings
			newArray.push(action)

			props.onValueChange(newArray)

			toastService.displayToast({
				message: 'Field mapping added',
				area: 'global',
			})
		}

		const handleDeleteFieldMapping = (fieldMappingId: string) => {
			const newArray = props.fieldMappings
			const index = newArray.findIndex((v) => v.id === fieldMappingId)
			if (index < 0) return

			newArray.splice(index, 1)
			props.onValueChange(newArray)

			toastService.displayToast({
				message: 'Field mapping deleted',
				area: 'global',
			})
		}

		const alphabetizedFieldMappings = useMemo(() => {
			return props.fieldMappings
				.slice()
				.sort((a, b) => a.name.localeCompare(b.name))
		}, [props.fieldMappings.length])

		return (
			<>
				<Fade in unmountOnExit>
					<Paper className={styles.insetSpacing}>
						<div className={styles.flexRow}>
							<Typography
								className={styles.topBottomContent}
								variant="h6"
								color="textPrimary"
							>
								Field Mappings
							</Typography>
							<div className={styles.addFieldMappingButton}>
								<Button
									variant="contained"
									color="primary"
									startIcon={<Add />}
									onClick={() => setAddDialogOpen(true)}
								>
									Add Field Mapping
								</Button>
							</div>
						</div>
						<Divider />
						<div className={styles.formSettingsRoot}>
							<Box component={TableContainer} overflow="auto">
								<Table>
									<TableHead>
										<TableRow>
											<TableCell />
											<TableCell>Name</TableCell>
											<TableCell>Source</TableCell>
											<TableCell align="right">Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{alphabetizedFieldMappings.map((mapping) => (
											<FieldMappingRow
												key={mapping.id}
												fieldMapping={mapping}
												sources={props.sources}
												fields={props.fields}
												fieldMappings={props.fieldMappings}
												onDeleteFieldMapping={handleDeleteFieldMapping}
												onValueChange={props.onValueChange}
											/>
										))}
									</TableBody>
								</Table>
							</Box>
						</div>
					</Paper>
				</Fade>
				<AddFieldMappingDialog
					open={addDialogOpen}
					onClose={() => setAddDialogOpen(false)}
					onAddFieldMapping={handleAddFieldMapping}
					sources={props.sources}
				/>
			</>
		)
	},
)

type FieldMappingRowProps = {
	fieldMappings: PropertyFieldMapping[]
	onValueChange: (newValue: PropertyFieldMapping[]) => void
	fieldMapping: PropertyFieldMapping
	sources: FieldMappingSource[]
	fields: Field[]
	onDeleteFieldMapping: (fieldMappingId: string) => void
}

const FieldMappingRow = observer((props: FieldMappingRowProps) => {
	const styles = useStyles()
	const [expanded, setExpanded] = useState(false)
	// store field mapping in state so we can track updates to it and re-render the row when it changes, not the whole table
	const [fieldMapping, setFieldMapping] = useState(props.fieldMapping)

	useEffect(() => {
		/* this will open the expanded row for each field mapping with no linked fields 
			 so that we will have the row expanded when a field mapping is created */
		if (props.fieldMapping.linkedFields.length === 0) setExpanded(true)
	}, [])

	const handleSaveChanges = action(
		(updatedFieldMapping: PropertyFieldMapping) => {
			const newArray = props.fieldMappings

			const indexToUpdate = newArray.findIndex(
				(v) => v.id === props.fieldMapping.id,
			)
			if (indexToUpdate < 0)
				throw new Error(
					`could not find field mapping with id ${props.fieldMapping.id}`,
				)

			newArray[indexToUpdate] = updatedFieldMapping

			setFieldMapping(updatedFieldMapping)

			props.onValueChange(newArray)
			toastService.displayToast({
				message: 'Field mapping updated',
				area: 'global',
			})
		},
	)

	return (
		<>
			<TableRow>
				<TableCell className={clsx(expanded && styles.leftBorder)}>
					<IconButton onClick={() => setExpanded(!expanded)}>
						{expanded ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell>{fieldMapping.name}</TableCell>
				<TableCell>{fieldMapping.source?.displayName ?? ''}</TableCell>
				<TableCell align="right">
					<IconButton
						onClick={() => props.onDeleteFieldMapping(fieldMapping.id)}
					>
						<Delete />
					</IconButton>
				</TableCell>
			</TableRow>
			{!expanded && (
				<ExpandedFieldMappingRow
					fieldMapping={fieldMapping}
					fields={props.fields}
					sources={props.sources}
					onUpdateFieldMapping={handleSaveChanges}
				/>
			)}
		</>
	)
})

type ExpandedFieldMappingRowProps = {
	fieldMapping: PropertyFieldMapping
	fields: Field[]
	sources: FieldMappingSource[]
	onUpdateFieldMapping: (fieldMapping: PropertyFieldMapping) => void
}

const ExpandedFieldMappingRow = observer(
	(props: ExpandedFieldMappingRowProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			sourceType: props.fieldMapping.source.type,
			selectedSourceId: props.fieldMapping.source.id.toString(),
			dialogOpen: false,
			linkedFields: [] as LinkedField[],
		}))

		useEffect(
			action(() => {
				for (const linkedField of props.fieldMapping.linkedFields)
					if (
						localStore.linkedFields.find((v) => v === linkedField) === undefined
					)
						localStore.linkedFields.push(linkedField)
			}),
			[],
		)

		const availableSources = useMemo(() => {
			return props.sources.filter((v) => v.type === localStore.sourceType)
		}, [localStore.sourceType, props.sources])

		const validationSchema = yup.object({
			name: yup.string().required(),
			description: yup.string(),
		})

		const formik = useFormik({
			initialValues: {
				name: props.fieldMapping.name,
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				const newSource =
					localStore.sourceType === SourceType.QueryString
						? {
								displayName: 'Query String',
								id: localStore.selectedSourceId,
								type: localStore.sourceType,
						  }
						: availableSources.find((v) => v.id == localStore.selectedSourceId)
				// above needs to be == instead of === in case we're comparing a number id with a string selectedSourceId

				if (newSource === undefined)
					throw new Error(
						`could not find source of type ${localStore.sourceType} with id ${localStore.selectedSourceId}`,
					)

				const updatedFieldMapping: PropertyFieldMapping = {
					name: values.name,
					id: props.fieldMapping.id,
					linkedFields: localStore.linkedFields,
					source: newSource,
					value: props.fieldMapping.value,
				}

				props.onUpdateFieldMapping(updatedFieldMapping)
			},
		})

		const handleSourceTypeChange = action((evt: SelectChangeEvent) => {
			localStore.sourceType = evt.target.value as SourceType
			localStore.selectedSourceId = ''
		})

		const handleSourceChange = action((evt: SelectChangeEvent) => {
			localStore.selectedSourceId = evt.target.value
		})

		const handleUnlinkField = action((field: LinkedField) => {
			const index = localStore.linkedFields.indexOf(field)
			if (index < 0) return
			localStore.linkedFields.splice(index, 1)
		})

		const handleAddFields = action((fields: LinkedField[]) => {
			localStore.linkedFields.push(...fields)
		})

		// requirements for Save Changes button to be enabled
		const allowSaveChanges = (): boolean => {
			const newFields = localStore.linkedFields
			const originalFields = props.fieldMapping.linkedFields

			// check if the linked fields are the same as the original linked fields
			const fieldsUnchanged =
				newFields.length === originalFields.length &&
				newFields.every((id, i) => id === originalFields[i])

			// first check that a source is selected - it is not valid if a source is not selected!
			if (localStore.selectedSourceId === '') return false

			// check if field mapping has even been changed - either thru config or linked fields
			if (
				!formik.dirty &&
				fieldsUnchanged &&
				localStore.sourceType === props.fieldMapping.source.type &&
				localStore.selectedSourceId === props.fieldMapping.source.id
			)
				return false

			// finally check if formik values are valid
			if (!formik.isValid) return false

			return true
		}

		return (
			<>
				<TableRow>
					<TableCell colSpan={4} className={styles.leftBorder}>
						<div className={styles.expandedSection}>
							<div className={styles.configuration}>
								<div className={styles.header}>
									<Typography variant="h6">Configuration</Typography>
									<Divider className={styles.divider} />
								</div>
								<div className={styles.configurationGrid}>
									<FixedHeaderWithHint
										label="Name"
										hint={formik.touched.name && formik.errors.name}
										hintColor="error.main"
										className={styles.fieldMappingName}
									>
										<TextField
											id="name"
											name="name"
											value={formik.values.name}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={!!formik.errors.name && formik.touched.name}
											fullWidth
											autoComplete="off"
										/>
									</FixedHeaderWithHint>
									<FixedHeader label="Source Type">
										<FormControl fullWidth>
											<Select
												value={localStore.sourceType}
												onChange={handleSourceTypeChange}
											>
												{Object.entries(SourceType).map((type) => (
													<MenuItem key={type[0]} value={type[1]}>
														{type[1]}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</FixedHeader>
									<FixedHeader label="Source">
										<FormControl fullWidth>
											{localStore.sourceType === SourceType.QueryString ? (
												<TextField
													fullWidth
													value={localStore.selectedSourceId}
													onChange={action(
														(evt) =>
															(localStore.selectedSourceId = evt.target.value),
													)}
												/>
											) : (
												<Select
													value={localStore.selectedSourceId}
													onChange={handleSourceChange}
												>
													{availableSources.map((source) => (
														<MenuItem key={source.id} value={source.id}>
															{source.displayName}
														</MenuItem>
													))}
												</Select>
											)}
										</FormControl>
									</FixedHeader>
								</div>
							</div>
							<div className={styles.configuration}>
								<div>
									<div className={styles.header}>
										<Typography variant="h6">Linked Fields</Typography>
										<Divider className={styles.divider} />
									</div>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>Form</TableCell>
													<TableCell>Field</TableCell>
													<TableCell align="right">Unlink</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{localStore.linkedFields.map((linkedField) => (
													<TableRow
														key={`${linkedField.formId}-${linkedField.cellDefinitionId}`}
													>
														<TableCell>
															{
																props.fields.find(
																	(v) =>
																		v.formId === linkedField.formId &&
																		v.cellDefinition.id ===
																			linkedField.cellDefinitionId,
																)?.formName
															}
														</TableCell>
														<TableCell>
															{
																props.fields.find(
																	(v) =>
																		v.formId === linkedField.formId &&
																		v.cellDefinition.id ===
																			linkedField.cellDefinitionId,
																)?.displayName
															}
														</TableCell>
														<TableCell align="right">
															<IconButton
																onClick={() => handleUnlinkField(linkedField)}
															>
																<LinkOff />
															</IconButton>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
									<div className={styles.linkFieldsButton}>
										<Button
											size="small"
											onClick={action(() => (localStore.dialogOpen = true))}
											startIcon={<AddLink />}
										>
											Link Fields
										</Button>
									</div>
								</div>
							</div>
						</div>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell colSpan={5} className={styles.leftBorder}>
						<div className={styles.saveChangesButtonDiv}>
							<Button
								color="primary"
								variant="contained"
								onClick={formik.submitForm}
								disabled={!allowSaveChanges()}
							>
								Save Changes
							</Button>
						</div>
					</TableCell>
				</TableRow>
				<LinkFieldsDialog
					open={localStore.dialogOpen}
					onClose={action(() => (localStore.dialogOpen = false))}
					fields={props.fields.filter(
						(field) =>
							localStore.linkedFields.find(
								(v) =>
									v.formId === field.formId &&
									v.cellDefinitionId === field.cellDefinition.id,
							) === undefined,
					)}
					onAddFields={handleAddFields}
				/>
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		marginTop: theme.spacing(3),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	leftBorder: {
		borderLeft: 'solid',
		borderLeftColor:
			theme.palette.mode === 'dark'
				? theme.palette.primary.dark
				: theme.palette.primary.light,
		borderLeftWidth: theme.spacing(1),
	},

	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	expandedSection: {
		display: 'flex',
		flexDirection: 'row',
	},

	saveChangesButtonDiv: {
		float: 'right',
	},

	configurationGrid: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		columnGap: theme.spacing(3),
		rowGap: theme.spacing(3),
	},

	header: {
		padding: theme.spacing(0, 0, 2, 0),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},

	divider: {
		paddingTop: theme.spacing(2),
	},

	configuration: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		width: percent(100),
	},

	addFieldMappingButton: {
		alignSelf: 'center',
		padding: theme.spacing(0, 1),
	},

	linkFieldsButton: {
		float: 'right',
		padding: theme.spacing(1, 0),
	},

	fieldMappingName: {
		gridColumnEnd: 'span 2',
	},
}))
