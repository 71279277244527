import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/system'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import moment from 'moment'
import React, { useMemo } from 'react'
import { ModalStore, ModalWrapper } from './ModalStore'
import { Signature } from './SignatureContext'
import { SignatureWrapper } from './SignatureModal'

enum SignatureState {
	NotSigned = 0,
	CurrentlySigning = 1,
	Signed = 2,
}

type SignatureHostProps = {
	anonymous: boolean
	selectedSignature: string
	onSelectSignature: (signature: Signature) => void
}

export const SignatureHost: React.FC<SignatureHostProps> = observer(
	({ anonymous, selectedSignature, onSelectSignature }: SignatureHostProps) => {
		const theme = useTheme()

		const modalStore = useMemo(() => new ModalStore(), [])

		const localStore = useLocalObservable(() => ({
			state:
				selectedSignature === ''
					? SignatureState.NotSigned
					: SignatureState.Signed,
			selectedSignature: selectedSignature,
		}))

		const setSignatureState = action((state: SignatureState) => {
			localStore.state = state
		})

		const onEdit = action(() => {
			setSignatureState(SignatureState.NotSigned)
		})

		const onApply = async (signature: Signature): Promise<void> => {
			// add the footer and logo to the signature
			const canvas = document.createElement('canvas')
			const canvasContext = canvas.getContext('2d')

			if (canvasContext === null) throw new Error('canvas context is null')

			const signatureImage = new Image()

			const logo = await new Promise<HTMLImageElement>((resolve) => {
				const innerLogo = new Image()
				innerLogo.onload = () => {
					resolve(innerLogo)
				}
				innerLogo.src = '/assets/fim_icon-bicolor.png'
			})

			signatureImage.onload = () => {
				// text info for use in drawing
				const logoMargin = 25
				const dateHeight = signatureImage.height / 4
				const date = moment()
				const formatDate = date.format('YYYY-MM-DD HH:mmZ')
				canvasContext.font = `${dateHeight}px Roboto`
				console.log('FONT: ' + canvasContext.font)
				let textMetrics = canvasContext.measureText(
					formatDate + '   ' + signature.fullName,
				)
				const doubleRow =
					textMetrics.width > signatureImage.width ? dateHeight : 0

				// sets blank canvas to correct size
				canvas.height = signatureImage.height + dateHeight + doubleRow
				canvas.width =
					signatureImage.width +
					// adds the width of the logo scaled to match the height of canvas + margin
					logo.width * (canvas.height / logo.height) +
					logoMargin
				canvasContext.fillStyle = '#ffffff'
				canvasContext.fillRect(0, 0, canvas.width, canvas.height)

				// draws the signature
				canvasContext.drawImage(
					signatureImage,
					0,
					0,
					signatureImage.width,
					signatureImage.height,
				)

				// draws logo after signature + margin
				canvasContext.drawImage(
					logo,
					signatureImage.width + logoMargin,
					0,
					// sizes the logo to fit the canvas space
					logo.width * (canvas.height / logo.height),
					canvas.height,
				)

				// writes date/time and name below signature
				canvasContext.fillStyle = theme.palette.primary.main
				canvasContext.font = `${dateHeight}px Roboto`
				canvasContext.fillText(formatDate, 0, canvas.height - doubleRow)
				textMetrics = canvasContext.measureText(formatDate + '   ')
				canvasContext.fillText(
					signature.fullName,
					doubleRow === 0 ? textMetrics.width + 5 : 0,
					canvas.height,
				)

				// we have to do this after the image loads otherwise we don't actually display the signature
				const signatureWithLogo = canvas.toDataURL()

				signature.signatureData = signatureWithLogo

				onSelectSignature(signature)
			}

			signatureImage.src = signature.signatureData

			modalStore.closeModal()

			setSignatureState(SignatureState.Signed)
		}

		return (
			<div
				style={{
					width: '100%',
					height: '100%',
				}}
			>
				{localStore.state == SignatureState.NotSigned && (
					<Box
						bgcolor="gray"
						style={{
							width: '100%',
							height: '100%',
							cursor: 'pointer',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						onClick={() =>
							modalStore.showModal(
								<SignatureWrapper
									onCancel={modalStore.closeModal}
									onApply={onApply}
								/>,
							)
						}
					>
						<Typography variant="h5" component="h2">
							Click Here To Sign
						</Typography>
					</Box>
				)}
				{localStore.state == SignatureState.Signed && (
					<SignatureViewer
						image={selectedSignature ?? ''}
						edit={true}
						onEdit={onEdit}
					/>
				)}
				<ModalWrapper store={modalStore} />
			</div>
		)
	},
)

type SignatureViewerProps = {
	image: string
	edit: boolean
	onEdit: () => void
	onClick?: () => void
}

const SignatureViewer: React.FC<SignatureViewerProps> = (
	props: SignatureViewerProps,
) => {
	// can't use makestyles inside iframe if this is HTML form, so have to use inline styles ☹
	return (
		<div
			style={{
				display: 'flex',
				width: percent(100),
				height: percent(100),
				justifyContent: 'center',
				background: '#ffffff',
			}}
		>
			<img
				src={props.image}
				alt={'Signature'}
				onClick={() => (props.onClick ? props.onClick() : props.onEdit())}
				style={{
					height: 'fit-content',
					maxHeight: percent(100),
					maxWidth: percent(100),
					alignSelf: 'center',
					cursor: 'pointer',
				}}
			/>
		</div>
	)
}
