export const createDeferredPromise = <T>(): [Promise<T>, (value: T | PromiseLike<T>) => void, (reason?: unknown) => void] => {
	// eslint-disable-next-line no-var
	var resolve: ((value: T | PromiseLike<T>) => void) | undefined = undefined
	// eslint-disable-next-line no-var
	var reject: ((reason?: unknown) => void) | undefined = undefined

	const promise = new Promise<T>((_resolve, _reject) => {
		resolve = _resolve
		reject = _reject
	})

	if (resolve === undefined || reject === undefined)
		throw new Error("the behavior of javascript has changed and conner didn't know what he was doing")

	return [promise, resolve, reject]

}