import { Upload } from '@mui/icons-material'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import * as yup from 'yup'
import { WorkflowDefinition } from '../../../../api/clients/workflows/DTOs'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type UploadWorkflowDialogProps = {
	open: boolean
	existingWorkflowNames: string[]
	onClose: () => void
	onCreateWorkflow: (wfObj: WorkflowDefinition) => void
}

export const UploadWorkflowDialog: React.FC<UploadWorkflowDialogProps> =
	observer((props: UploadWorkflowDialogProps) => {
		const [workflowData, setWorkflowData] = useState<
			WorkflowDefinition | undefined
		>(undefined)

		const styles = useStyles()

		const upperWorkflowNames = props.existingWorkflowNames.map((v) =>
			v.toUpperCase(),
		)

		const validationSchema = useMemo(
			() =>
				yup.object({
					workflowName: yup
						.string()
						.required('Workflow name is a required field')
						.test(
							'is-unique',
							'A workflow with this name already exists in your organization',
							(value) => {
								console.log('value, ', value)
								console.log('upper workflow names: ', upperWorkflowNames)
								return (
									value !== undefined &&
									!upperWorkflowNames.includes(value.toUpperCase().trim())
								)
							},
						),
				}),
			[upperWorkflowNames],
		)

		const formik = useFormik({
			initialValues: {
				workflowName: '',
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				if (workflowData == undefined) throw new Error('workflow data missing')

				workflowData.workflow.name = values.workflowName
				workflowData.workflow.enabled = false

				props.onCreateWorkflow(workflowData)
			},
		})

		const handleDrop = (files: File[]) => {
			if (files.length === 0) return

			const file = files[0]
			const reader = new FileReader()
			reader.addEventListener('load', (event) => {
				const result = event.target?.result

				if (result === undefined || result === null) {
					console.warn('failed to read result')
					return
				}

				const output: WorkflowDefinition = JSON.parse(result as string)
				setWorkflowData(output)
				console.log(output)

				formik.setTouched({
					workflowName: true,
				})
				formik.setValues({
					workflowName: formik.values.workflowName || output.workflow.name,
				})
			})

			reader.readAsText(file)
		}

		return (
			<Dialog open={props.open} onClose={props.onClose} fullWidth>
				<DialogTitle>Upload Workflow</DialogTitle>
				<DialogContent>
					<Dropzone onDrop={(files) => handleDrop(files)} accept={'.fwf'}>
						{({
							acceptedFiles,
							getRootProps,
							getInputProps,
						}: DropzoneState) => (
							<Box
								component="section"
								flex={'1'}
								className={clsx(
									styles.verticalSpacing,
									styles.enabledDragAndDrop,
								)}
							>
								<Box height={percent(100)} {...getRootProps()}>
									<input {...getInputProps()} />
									<div className={styles.dragAndDropBody}>
										<Box
											display="flex"
											flexDirection="column"
											justifyContent="center"
											alignItems="center"
											height={'100%'}
										>
											<Box
												overflow={'auto'}
												width={percent(100)}
												textAlign={'center'}
											>
												<Box
													flex="1"
													overflow={'hidden'}
													display={'flex'}
													flexDirection={'column'}
													justifyContent={'center'}
													alignItems={'center'}
												>
													<Upload />
													{acceptedFiles.length == 0 ? (
														<Typography>Workflow File</Typography>
													) : (
														acceptedFiles.map((v) => (
															<Typography key={v.name}>{v.name}</Typography>
														))
													)}
												</Box>
											</Box>
										</Box>
									</div>
								</Box>
							</Box>
						)}
					</Dropzone>

					<FixedHeaderWithHint
						label="Name"
						className={styles.textFieldStyle}
						hint={
							formik.errors.workflowName ?? 'The name of the imported workflow'
						}
						hintColor={formik.errors.workflowName ? 'red' : undefined}
					>
						<TextField
							fullWidth
							id="workflowName"
							name="workflowName"
							value={formik.values.workflowName}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
							error={
								!!formik.errors.workflowName && formik.touched.workflowName
							}
						/>
					</FixedHeaderWithHint>

					<DialogActions>
						<Button color="primary" onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							onClick={formik.submitForm}
							disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
							color="primary"
							variant="contained"
						>
							Upload
						</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},

	verticalSpacing: {
		height: '50%',
		flex: 3,
	},
	enabledDragAndDrop: {
		cursor: 'pointer',
	},

	disabledDragAndDrop: {
		opacity: percent(60),
	},

	dragAndDropBody: {
		height: percent(100),
		width: percent(100),
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',

		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: theme.shape.borderRadius,
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: theme.palette.text.secondary,

		overflowWrap: 'break-word',

		padding: theme.spacing(2),

		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(5),
		},
	},
}))
