import { Publish } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { px } from 'csx'
import { ChangeEvent, useCallback, useState } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import { useHistory } from 'react-router'
import { PortalsClient } from '../../../../../api/clients/identity/PortalsClient'
import { NewPortal, PortalVersion } from '../../../../../api/DTOtemp'
import { toastService } from '../../../../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../../../../utils/HOC/FixedHeaders'

export enum UploadType {
	html = 'text/html',
	pdf = 'application/pdf',
}

interface IUploadPortalDialogProps {
	open: boolean

	/**
	 * need to make sure we don't have portals with the same name. this is optional since uploading
	 * a new portal version won't ask for a new name for the portal.
	 */
	existingPortalNames?: string[]

	/**
	 * if this is present then the dialog will be used to upload a new portal version, rather than
	 * creating an entirely new portal.
	 */
	portalId?: number
	portalName?: string
	portalType?: UploadType

	onPortalUploaded?: (newPortal: PortalVersion) => void
	onClose?: () => void
}

const UploadPortalDialog = (props: IUploadPortalDialogProps) => {
	const styles = useStyles()
	const history = useHistory()

	const [portalName, setPortalName] = useState(props.portalName ?? '')
	const [portalDesc, setPortalDesc] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [file, setFile] = useState<File | undefined>()
	const [isPosting, setIsPosting] = useState(false)

	const upperExistingPortalNames =
		props.existingPortalNames?.map((v) => v.toUpperCase()) ?? []

	const handleNameChanged = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			setPortalName(evt.target.value)
			validatePortalName(evt.target.value)
		},
		[upperExistingPortalNames],
	)

	const handleDescChanged = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => setPortalDesc(evt.target.value),
		[],
	)

	const handleDrop = useCallback(
		(acceptedFiles: File[]) => {
			const newFile = acceptedFiles[0]
			if (portalName === '' || portalName === file?.name) {
				setPortalName(newFile.name)
				validatePortalName(newFile.name)
			}
			setFile(newFile)
		},
		[upperExistingPortalNames],
	)

	const validatePortalName = (name: string) => {
		if (upperExistingPortalNames.includes(name.toUpperCase().trim()))
			setErrorMessage('Portal name must be unique')
		else if (name === '') setErrorMessage('Portal name is required')
		else setErrorMessage('')
	}

	const handleUploadClicked = useCallback(() => {
		if (file === undefined) throw Error('no file has been uploaded')

		const api = new PortalsClient()

		const portalInfoModel: NewPortal = {
			name: portalName,
			description: portalDesc,
			authenticationRequired: true,
			portalData: file,
		}

		const promise =
			props.portalId === undefined
				? api.CreatePortal(portalInfoModel)
				: api.UpdatePortalData(props.portalId, portalInfoModel)

		promise
			.then((v) => {
				setIsPosting(false)

				props.onPortalUploaded && props.onPortalUploaded(v.data)
				props.onClose && props.onClose()
				history.push(`_portals/${v.data.portalId}`)

				toastService.displayToast({
					message: 'Portal uploaded',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error uploading portal',
					area: 'global',
				})
			})
	}, [file, portalName])

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Upload Portal</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.portalId === undefined
						? 'Upload a new HTML portal. The name must be unique.'
						: 'Upload a new version of the current portal'}
				</DialogContentText>
				<div className={styles.mainDiv}>
					{props.portalId === undefined && (
						<>
							<FixedHeaderWithHint
								className={styles.verticalSpacing}
								label="Name"
								hint={errorMessage}
								hintColor="error.main"
							>
								<TextField
									autoFocus
									value={portalName}
									onChange={handleNameChanged}
									fullWidth
									autoComplete="off"
									error={errorMessage !== ''}
								/>
							</FixedHeaderWithHint>
							<FixedHeaderWithHint
								className={styles.verticalSpacing}
								label="Description"
							>
								<TextField
									autoFocus
									value={portalDesc}
									onChange={handleDescChanged}
									fullWidth
									autoComplete="off"
								/>
							</FixedHeaderWithHint>
						</>
					)}
					<Dropzone
						maxFiles={1}
						maxSize={5_000_000}
						accept={UploadType.html}
						multiple={false}
						onDrop={handleDrop}
					>
						{({ getRootProps, getInputProps }: DropzoneState) => (
							<section className={styles.verticalSpacing}>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div className={styles.dragAndDropBody}>
										{file !== undefined ? (
											<Typography>{file.name}</Typography>
										) : (
											<>
												<Typography>
													Drag and drop your file here, or click to select a
													file
												</Typography>
												<Publish />
											</>
										)}
									</div>
								</div>
							</section>
						)}
					</Dropzone>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleUploadClicked}
					color="primary"
					variant="contained"
					disabled={errorMessage !== '' || file === undefined}
				>
					Upload
				</Button>
			</DialogActions>
			{isPosting && <LinearProgress />}
		</Dialog>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	hiddenIframe: {
		display: 'none',
	},

	verticalSpacing: {
		margin: theme.spacing(2, 0),
	},

	dragAndDropBody: {
		display: 'flex',
		flexDirection: 'column',

		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: theme.shape.borderRadius,
		borderStyle: 'dashed',
		borderColor: theme.palette.text.secondary,

		cursor: 'pointer',

		minWidth: px(500),
		minHeight: px(200),
	},
}))

export default UploadPortalDialog
