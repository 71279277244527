import { TextField, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ReactNode } from 'react'
import { SignInClient } from '../../api/clients/identity'
import { AuthenticationMethod } from '../../api/DTO'
import EmailValidationRegex from '../../utils/constants/EmailValidationRegex'
import { FixedHeaderWithHint } from '../../utils/HOC/FixedHeaders'
import { AuthenticationWrapper } from './AuthenticationWrapper'

type UsernameEntryMangerProps = {
	headerText?: string
	nextButtonText?: string
	existingUsername?: string // to autopopulate if necessary
	belowUsernameEntryLink?: ReactNode
	onReceivedValidUsername: (
		username: string,
		authMethod: AuthenticationMethod,
	) => void
}

export const UsernameEntryManager = observer(
	(props: UsernameEntryMangerProps) => {
		const store = useLocalObservable(() => ({
			hasLostFocus: false,
			isValid: true,
			isLoading: false,
			username: props.existingUsername ?? ('' as string | undefined),
			customErrorMessage: undefined as string | undefined,
		}))

		const checkValidityState = action((v: string | undefined): boolean => {
			return EmailValidationRegex.test(v ?? '')
		})

		const handleChange = action((v: string | undefined): boolean => {
			store.username = v
			if (!store.hasLostFocus) {
				store.customErrorMessage = undefined
				return true
			}
			store.isValid = checkValidityState(v)
			store.customErrorMessage = store.isValid
				? undefined
				: 'Email Address is Invalid'
			return store.isValid
		})

		const handleBlur = action(() => {
			store.hasLostFocus = true
			handleChange(store.username)
		})

		const handleNextClicked = async (
			v: string | undefined,
		): Promise<boolean> => {
			store.hasLostFocus = true
			const valid = handleChange(store.username)
			if (!valid) return false

			const authenticationClient = new SignInClient()

			runInAction(() => {
				store.isLoading = true
			})

			try {
				const { data: authMethod } =
					await authenticationClient.getAuthenticationMethod(v ?? '')

				props.onReceivedValidUsername(store.username!, authMethod)
				return true
			} catch (error) {
				store.customErrorMessage = 'No User with this email address exists'
				return false
			} finally {
				store.isLoading = false
			}
		}

		return (
			<>
				<AuthenticationWrapper
					nextDisabled={!store.isValid || store.isLoading}
					onNextClicked={() => handleNextClicked(store.username)}
					isLoading={store.isLoading}
					headerText={props.headerText}
					nextButtonText={props.nextButtonText}
				>
					<UsernameEntry
						errorMessage={store.customErrorMessage}
						value={store.username}
						belowUsernameEntryLink={props.belowUsernameEntryLink}
						onChange={handleChange}
						onBlur={handleBlur}
						onEnter={() => handleNextClicked(store.username)}
					/>
				</AuthenticationWrapper>
			</>
		)
	},
)
type UsernameEntryProps = {
	errorMessage?: string
	value: string | undefined
	belowUsernameEntryLink?: ReactNode
	onChange: (value: string | undefined) => void
	onBlur: () => void
	onEnter?: () => void
}

const UsernameEntry = (props: UsernameEntryProps) => {
	const styles = useUsernameEntryStyles()

	return (
		<div className={styles.container}>
			<FixedHeaderWithHint
				className={styles.textField}
				label="Email Address"
				hint={props.errorMessage ?? ' '}
				hintColor="error.main"
			>
				<TextField
					autoFocus
					fullWidth
					type="username"
					name="username"
					error={props.errorMessage !== undefined}
					value={props.value}
					onChange={(v) => props.onChange(v.target.value)}
					onBlur={() => props.onBlur()}
					onKeyPress={(keyEventArgs) => {
						if (keyEventArgs.key === 'Enter' && props.onEnter !== undefined)
							props.onEnter()
					}}
				/>
			</FixedHeaderWithHint>
			{props.belowUsernameEntryLink ?? '\u00a0'}
		</div>
	)
}

const useUsernameEntryStyles = makeStyles((theme: Theme) => ({
	container: {
		height: percent(100),
		width: percent(100),

		display: 'flex',
		flexDirection: 'column',
	},

	textField: {
		width: percent(100),
		padding: theme.spacing(2, 0, 0, 0),
	},

	spacing: {
		margin: theme.spacing(1, 0),
	},
}))
