import { Chip, FormGroup, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { observer } from 'mobx-react'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	ListControlInput,
	ListPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type ListControlProps = {
	label: string
}

const ListInput = observer((props: ListControlProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext<string[]>()

	/* swapping to Static from Javascript gives us an empty string, 
		 which we can't map so we have to assign empty array if we don't already
		 have an array*/
	const realValue =
		context.value !== null && Array.isArray(context.value) ? context.value : []

	return (
		<Box
			component={FormGroup}
			flex={1}
			overflow="hidden"
			onBlur={() => {
				focusEventEmitter(
					cellManager.eventBus,
					{
						focusType: 'FocusLost',
					},
					{
						formHost: cellManager,
						elementTag: ListInputElementTag,
						definitionId: context.cellInstance.definitionId,
						instanceId: context.cellInstance.id,
					},
				)
			}}
		>
			<Typography variant="subtitle1" color="textPrimary">
				{props.label}
			</Typography>
			<ListControlInput
				onAddItem={(v) => {
					const newValue = [...new Set([...realValue, v])]
					context.onChange(newValue)

					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: context.value,
							newValue: newValue,
						},
						{
							formHost: cellManager,
							elementTag: ListInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
			/>
			<div>
				{realValue.map((v) => (
					<Chip
						key={v}
						label={v}
						onDelete={() => {
							console.log('deleting: ', v)
							const oldValue = context.value
							const newValue = realValue.filter((val) => val !== v)
							context.onChange(newValue)
							changeEventEmitter(
								cellManager.eventBus,
								{
									oldValue: oldValue,
									newValue: newValue,
								},
								{
									formHost: cellManager,
									elementTag: ListInputElementTag,
									definitionId: context.cellInstance.definitionId,
									instanceId: context.cellInstance.id,
								},
							)
						}}
					/>
				))}
			</div>
		</Box>
	)
})

export const ListInputElementTag = '8378cda8-3964-4fee-aaa0-81630defd414'

const ListInputId = '39fbdd7a-2138-47b6-8161-1523ce9803bb'

const ListInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof ListInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
		</>
	)
}

const ListInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof ListInput>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={ListPropertyConfigurator}
		label="Default Value"
	/>
)

export const ListInputDefinition = specifyValueTypeComponent(
	ListInputId,
	ListInput,
	ListInputElementTag,
	'List Builder',
	FieldType.StringArray,
	{
		configurator: ListInputPropsEditor,
		defaultProperties: {
			label: 'Label',
		},
	},
	{
		configurator: ListInputValueEditor,
		defaultValue: [],
	},
)
