import { AxiosResponse } from "axios"
import SecureClient from "../SecureClient"
import { ActivityConfiguration } from "./DTOs"

export default class ActivityDefinitionClient extends SecureClient {
	public getDefinitions = (): Promise<AxiosResponse<ActivityConfiguration[]>> => {
		return this._api.get<ActivityConfiguration[]>(`workflowActivities`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

}