import { FormType } from '../../../api/DTOtemp'
import { EventBus } from '../../FormBuilderCore/eventBus/EventBus'
import { IUnifiedFormHost } from '../../FormHost/FormHost/IUnifiedFormHost'
import { CellType } from '../../FormHost/Types/CellType'
import { FormControlApi } from '../../FormHost/Types/FormControlApi'
import { UnifiedCellDefinition } from '../../FormHost/Types/UnifiedCellDefinition'
import { UnifiedCellInstance } from '../../FormHost/Types/UnifiedCellInstance'
import {
	createCellDefinitionProxy,
	createNonValueCellInstanceProxy,
	createValueCellInstanceProxy
} from '../../FormHost/Utilities/CellProxyUtilities'
import { HtmlFormManager, adapters } from './HtmlFormHost'

export interface IHtmlPackageHostWrapper extends IUnifiedFormHost {
	readonly formType: FormType.Html
	readonly formHost: HtmlFormManager
}

export class HtmlPackageHostWrapper implements IHtmlPackageHostWrapper {
	private readonly _formDocument: Document
	private readonly _iframeElement: HTMLIFrameElement

	readonly formHost: HtmlFormManager

	readonly formId: number
	readonly formType: FormType.Html = FormType.Html;

	eventBus: EventBus
	dispose: () => void

	cellDefinitions: UnifiedCellDefinition[]
	cellInstances: UnifiedCellInstance[]

	controlApi?: FormControlApi

	public get formContainerElement() {
		return this._formDocument.documentElement
	}

	public get iframeContainer() {
		return this._iframeElement
	}

	constructor(formHost: HtmlFormManager, iframeElement: HTMLIFrameElement, formDocument: Document) {
		this.formHost = formHost
		this._iframeElement = iframeElement
		this._formDocument = formDocument

		this.formId = formHost.formId
		this.eventBus = formHost.eventBus
		this.dispose = formHost.dispose

		this.cellDefinitions = []
		this.cellInstances = []

		this.controlApi = formHost.controlApi

		// proxy the html cell definitions/instances so they can be used in the package host
		this.proxyCellDefinitions()
		this.proxyCellInstances()
	}

	private proxyCellDefinitions() {
		for (const definition of this.formHost.cellDefinitions) {
			this.cellDefinitions.push(
				createCellDefinitionProxy(definition, propertiesProxy),
			)
		}
	}

	private proxyCellInstances() {
		for (const instance of this.formHost.cellInstances) {
			if (instance.type === CellType.Virtual) {
				this.cellInstances.push(instance)
				continue
			}

			if (instance.type !== CellType.Value) {
				this.cellInstances.push(
					createNonValueCellInstanceProxy(instance, propertiesProxy),
				)
				continue
			}

			const valueGetter = () => instance.value
			
			const valueSetter = (v: unknown) => {
				instance.value = v

				for (const adapter of adapters) {
					if (!adapter.canRead(instance, this._formDocument)) continue

					adapter.write(instance, this._formDocument, v)

					// we don't want to check the other adapters
					break
				}
			}

			const unifiedCellInstance = createValueCellInstanceProxy(
				instance,
				propertiesProxy,
				valueGetter,
				valueSetter,
			)
			this.cellInstances.push(unifiedCellInstance)
		}
	}
}

const propertiesProxy = (properties: Record<string, unknown>) =>
	new Proxy(properties, handler)

const handler: ProxyHandler<Record<string, unknown>> = {
	get: function (properties: Record<string, unknown>, propertyName: string) {
		return properties[propertyName]
	},
	set: function (
		properties: Record<string, unknown>,
		propertyName: string,
		value: unknown,
	) {
		// TODO: later when dealing with attributes, we will want to update the HTML element's attributes thru document.getElementById()
		properties[propertyName] = value
		return true
	},
}
