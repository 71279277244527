import FieldType from "../../FormHost/Types/FieldType"
import { PdfCellInstance } from "./Types/PdfCellInstance"

export interface IPDFAdapter {
	canRead(cellInstance: PdfCellInstance, document: Document): boolean

	read(cellInstance: PdfCellInstance, document: Document): unknown

	write(
		cellInstance: PdfCellInstance,
		document: Document,
		value: unknown,
	): void
}

export class PDFInputAdapter implements IPDFAdapter {
	canRead(cellInstance: PdfCellInstance, document: Document): boolean {
		if (document.getElementById(cellInstance.id) === null)
			return false
		
		return (cellInstance.elementTag === 'Text' && cellInstance.fieldType === FieldType.String)
	}

	read(cellInstance: PdfCellInstance, document: Document): unknown {
		const input = document.getElementById(cellInstance.id) as HTMLInputElement
		return input.value
	}

	write(
		cellInstance: PdfCellInstance,
		document: Document,
		value: string,
	): void {
		const input = document.getElementById(cellInstance.id) as HTMLInputElement
		input.value = value
	}
}

export class PDFBooleanInputAdapter implements IPDFAdapter {
	canRead(cellInstance: PdfCellInstance, document: Document): boolean {
		if (document.getElementById(cellInstance.id) === null)
			return false

		return (
			cellInstance.elementTag === 'Checkbox' &&
			cellInstance.fieldType === FieldType.Boolean
		)
	}

	read(cellInstance: PdfCellInstance, document: Document): unknown {
		const element = document.getElementById(cellInstance.id) as HTMLInputElement
		return element.checked
	}

	write(
		cellInstance: PdfCellInstance,
		document: Document,
		value: boolean,
	): void {
		const element = document.getElementById(cellInstance.id) as HTMLInputElement
		element.checked = value
	}
}

export class PDFVirtualRadioAdapter implements IPDFAdapter {
	canRead(cellInstance: PdfCellInstance): boolean {		
		return (
			cellInstance.elementTag === 'Radio'
		)
	}

	read(cellInstance: PdfCellInstance, document: Document): unknown {
		const elements = document.getElementsByName(
			cellInstance.id,
		) as NodeListOf<HTMLInputElement>

		for (const element of elements) {
			if (element.checked) {
				return element.value
			}
		}

		return ""
	}

	write(cellInstance: PdfCellInstance, document: Document, value: string) {
		const elements = document.getElementsByName(
			cellInstance.id,
		) as NodeListOf<HTMLInputElement>

		for (const element of elements) {
			if (!element.checked && element.value === value) element.checked = true
			if (element.checked && element.value !== value) element.checked = false
		}
	}
}
