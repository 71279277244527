import { TextFields } from '@mui/icons-material'
import { Divider, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { ConfigurableComponentRegistration } from '../../../../FormBuilderCore/cells/ComponentTypes'
import { getGlobalComponentRegistry } from '../../../ComponentRegistry/GlobalComponentRegistry'
import { ComponentEntry } from '../ComponentManager/ComponentCollection'

const ComponentSourcePanel = observer(() => {
	const { componentCollection } = getGlobalComponentRegistry()
	const styles = useStylesTwo()

	const elements: { id: string; name: string }[] = useMemo(() => {
		const innerElements = []

		for (const registrationKey in componentCollection) {
			const registration = componentCollection[registrationKey]

			if (registration === undefined)
				throw Error('fetched null registration from component registry')

			const configurableRegistration =
				registration as ConfigurableComponentRegistration<
					Record<string, unknown>
				>
			if (
				configurableRegistration.displayName === undefined ||
				configurableRegistration.propertiesConfigurator === undefined
			)
				continue // if we end up here, it isn't a configurable component

			innerElements.push({
				id: configurableRegistration.id,
				name: configurableRegistration.displayName,
			})
		}

		return innerElements
	}, [])

	return (
		<div className={styles.formBuilderSidebarRoot}>
			<Typography variant="h6" color="textSecondary">
				Elements
			</Typography>
			<Divider className={styles.sidebarDivider} />
			<div className={styles.sidebarFlex}>
				{elements.map((v) => (
					<div key={v.id} className={styles.sidebarItem}>
						<ComponentEntry
							icon={<TextFields />}
							text={v.name}
							componentRegistrationId={v.id}
						/>
					</div>
				))}
			</div>
		</div>
	)
})

const useStylesTwo = makeStyles((theme: Theme) => ({
	formBuilderSidebarRoot: {
		flex: 1,
		height: '100%',
		overflow: 'auto',
	},

	sidebarDivider: {
		margin: theme.spacing(1, 0),
	},

	sidebarItem: {
		padding: theme.spacing(0.75, 1),
	},

	sidebarFlex: {
		display: 'flex',
		flexDirection: 'column',
	},
}))

export default ComponentSourcePanel
