import {
	ArrowForward,
	FilterAlt,
	KeyboardArrowDown,
	Warning,
} from '@mui/icons-material'
import Create from '@mui/icons-material/Create'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import {
	Box,
	Button,
	Chip,
	Fade,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import DataProviderInstancesClient from '../../../../api/clients/dataProviders/DataProviderInstancesClient'
import DataProvidersClient from '../../../../api/clients/dataProviders/DataProvidersClient'
import {
	BaseDataProviderInstanceModel,
	DataProviderConfiguration,
	DataProviderInstanceMetadata,
} from '../../../../api/clients/dataProviders/DTO'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { Scrollbar } from '../../../../components/scrollbars/Scrollbar'
import { useModals } from '../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { CreateDataProviderInstanceDialog } from './CreateDataProviderInstanceDialog'
import { DeleteDataProviderDialog } from './DeleteDataProviderDialog'

export type InstanceIdAndName = {
	id: string
	name: string
}

type FilterOption = {
	label: string
	value: number
}

export const DataProvidersDashboard: React.FC = () => {
	const history = useHistory()
	const styles = useStyles()
	const modalService = useModals()

	const dataProvidersClient = new DataProvidersClient()
	const instancesClient = new DataProviderInstancesClient()

	const [dataProviders, setDataProviders] = useState<
		DataProviderConfiguration[]
	>([])
	const [dataProviderInstances, setDataProviderInstances] = useState<
		DataProviderInstanceMetadata[]
	>([])

	const [requestsComplete, setRequestsComplete] = useState(false)

	const handleEditClicked = (instanceId: string) => {
		history.push(`/_administration/_data-providers/${instanceId}`)
	}

	const handleDeleteInstance = async (instanceId: string) => {
		instancesClient.deleteInstance(instanceId).then(() => {})

		const originalInstances = dataProviderInstances
		const newInstances = (originalInstances ?? []).filter(
			(v) => v.id !== instanceId,
		)

		setDataProviderInstances(newInstances)
	}

	const handleCreateInstance = async (
		baseModel: BaseDataProviderInstanceModel,
	) => {
		instancesClient
			.configure(baseModel)
			.then((response) => {
				const instanceId = response.data.id
				history.push(`/_administration/_data-providers/${instanceId}`)

				toastService.displayToast({
					message: 'Data provider created',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error creating data provider',
					area: 'global',
				})
			})
	}

	const getDataProviderTypes = async () => {
		const { data } = await dataProvidersClient.getDataProviders()
		setDataProviders(data)
	}

	const getDataProviderInstances = async () => {
		const { data } = await instancesClient.getMetadataForInstances()
		setDataProviderInstances(data)
	}

	useEffect(() => {
		Promise.all([getDataProviderTypes(), getDataProviderInstances()]).then(() =>
			setRequestsComplete(true),
		)
	}, [])

	const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null)
	const [selectedFilter, setSelectedFilter] = useState(0)

	const filterOptions: FilterOption[] = [
		{
			label: 'All',
			value: 0,
		},
		{
			label: 'System',
			value: 1,
		},
		{
			label: 'Configured',
			value: 2,
		},
	]

	const handleFilterClick = action(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			setFilterAnchorEl(event.currentTarget)
		},
	)

	const handleFilterUsers = action((option: FilterOption) => {
		setSelectedFilter(option.value)
		setFilterAnchorEl(null)
	})

	if (!requestsComplete) return <FullscreenSpinner />

	return (
		<AdministrationPageContainer
			title="Data Providers"
			actions={
				<div>
					<Chip
						label={`Filter: ${filterOptions[selectedFilter].label}`}
						className={styles.filterItems}
					/>
					<Button
						className={styles.filterItems}
						startIcon={<FilterAlt />}
						color="primary"
						variant="outlined"
						size="medium"
						onClick={handleFilterClick}
						endIcon={<KeyboardArrowDown />}
					>
						Filter
					</Button>
					<Menu
						anchorEl={filterAnchorEl}
						open={filterAnchorEl !== null}
						onClose={() => setFilterAnchorEl(null)}
					>
						{filterOptions.map((option) => (
							<MenuItem
								onClick={() => handleFilterUsers(option)}
								key={option.value}
							>
								{option.label}
							</MenuItem>
						))}
					</Menu>
					<Button
						variant="contained"
						hidden={dataProviders.length === 0}
						color="primary"
						onClick={() => {
							modalService
								.showForm((props) => {
									return (
										<CreateDataProviderInstanceDialog
											dataProviders={dataProviders}
											instanceNames={
												dataProviderInstances.map((v) => v.displayName) ?? []
											}
											onCancel={() => props.close({ closeResult: 'cancel' })}
											onConfirm={(v) =>
												props.close({ closeResult: 'okay', value: v })
											}
										/>
									)
								})
								.then(async (v) => {
									if (v.closeResult === 'okay')
										handleCreateInstance(
											v.value as BaseDataProviderInstanceModel,
										)
								})
						}}
						startIcon={<Create />}
					>
						Create Data Provider
					</Button>
				</div>
			}
		>
			{dataProviderInstances.length === 0 && dataProviders.length === 0 ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					width={percent(100)}
				>
					<Typography variant="subtitle1">
						No Data Provider Plugins Included
					</Typography>
				</Box>
			) : (
				<DataProvidersDashboardTable
					dataProviderInstances={dataProviderInstances}
					onEditClicked={handleEditClicked}
					onDeleteInstance={handleDeleteInstance}
					filterProviders={selectedFilter}
				/>
			)}
		</AdministrationPageContainer>
	)
}

type DataProvidersDashboardTableProps = {
	dataProviderInstances: DataProviderInstanceMetadata[]
	onEditClicked: (instanceId: string) => void
	onDeleteInstance: (instanceId: string) => void
	filterProviders: number
}

const DataProvidersDashboardTable: React.FC<DataProvidersDashboardTableProps> =
	observer((props: DataProvidersDashboardTableProps) => {
		const styles = useStyles()
		const promptService = usePrompts()

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Scrollbar>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Description</TableCell>
									<TableCell align="right">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.dataProviderInstances.map((instance, i) => {
									if (
										props.filterProviders == 2 // Configured
											? !instance.isInternal
											: props.filterProviders == 1 // System
											? instance.isInternal
											: true // All
									) {
										return (
											<TableRow key={i}>
												<TableCell>
													<Typography
														variant="inherit"
														color={instance.isMissingPlugin ? 'error' : ''}
													>
														{instance.displayName}
													</Typography>
												</TableCell>
												<TableCell>
													{instance.isMissingPlugin ? (
														<Box display="flex" alignItems="center" gap={1}>
															<Warning color="error" fontSize="small" />
															<Typography variant="subtitle2" color="error">
																Plugin Missing
															</Typography>
														</Box>
													) : (
														instance.providerType
													)}
												</TableCell>
												<TableCell>
													{instance.description.length < 100
														? instance.description
														: instance.description.substring(0, 99) + '...'}
												</TableCell>
												<TableCell align="right">
													{instance.isInternal ? (
														<IconButton
															onClick={() => props.onEditClicked(instance.id)}
														>
															<ArrowForward fontSize="small" />
														</IconButton>
													) : (
														<>
															{!instance.isMissingPlugin && (
																<IconButton
																	onClick={() =>
																		props.onEditClicked(instance.id)
																	}
																>
																	<Edit fontSize="small" />
																</IconButton>
															)}
															<IconButton
																onClick={() => {
																	promptService
																		.showDialog((props) => (
																			<DeleteDataProviderDialog
																				instance={{
																					id: instance.id,
																					name: instance.displayName,
																				}}
																				onCancel={() => props.close('cancel')}
																				onConfirm={() => props.close('okay')}
																			/>
																		))
																		.then((v) => {
																			if (v == 'okay')
																				props.onDeleteInstance(instance.id)
																		})
																}}
															>
																<Delete fontSize="small" />
															</IconButton>
														</>
													)}
												</TableCell>
											</TableRow>
										)
									}
								})}
							</TableBody>
						</Table>
					</Scrollbar>
				</TableContainer>
			</Fade>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflowX: 'auto',
	},

	editIcon: {
		marginRight: theme.spacing(1),
	},

	filterItems: {
		marginRight: theme.spacing(2),
	},
}))
