export const blobToBase64 = (blob: Blob) => {
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.readAsDataURL(blob)
		reader.onloadend = function () {
			resolve(reader.result)
		}
	})
}


export const dataUrlToBlob = (dataUrl: string) => {
	// get the first part of data url, includes the mime type
	const splitDataUrl = dataUrl.split(',')

	const mimeTypeMatch = splitDataUrl[0].match(/:(.*);/)

	if (mimeTypeMatch === null)
		throw new Error('could not get mime type for data url')

	const mimeType = mimeTypeMatch[1]

	// have to convert the data url to uint8array

	const byteString = window.atob(splitDataUrl[1])
	let byteStringLength = byteString.length
	const uint8array = new Uint8Array(byteStringLength)

	while (byteStringLength--)
		uint8array[byteStringLength] = byteString.charCodeAt(byteStringLength)

	return new Blob([uint8array], { type: mimeType })
}
