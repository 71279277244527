// i did not come up with these :) https://stackoverflow.com/questions/36637146/encode-string-to-hex -Caroline

export function hexEncodeString(value: string): string {
	return value
		.split('')
		.map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
		.join('')
}

export function decodeHexString(encodedString: string) {
	return encodedString
		.split(/(\w\w)/g)
		.filter((p) => !!p)
		.map((c) => String.fromCharCode(parseInt(c, 16)))
		.join('')
}