import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyObjectComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useLayoutCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/LayoutCellContextProvider'
import {
	NumberPropertyConfigurator,
	PropertySchematicConfigurator,
} from '../../Configuration'

type GridLayoutProps = {
	columns: number
	rows: number
}

export const GridLayout = (props: GridLayoutProps) => {
	console.log('rendering sample layout')
	const styles = useStyles()
	const context = useLayoutCellContext()

	const cellComponents = context.cellComponents

	const columnRange = [...Array(props.columns).keys()]
	const rowRange = [...Array(props.rows).keys()]

	return (
		<div className={styles.root}>
			{rowRange.map((_rowValue, rowIndex) => (
				<div key={rowIndex} className={styles.box}>
					{columnRange.map((_columnValue, columnIndex) => (
						<div key={`${rowIndex}-${columnIndex}`} className={styles.cell}>
							{cellComponents[rowIndex * props.columns + columnIndex]}
						</div>
					))}
				</div>
			))}
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		gap: theme.spacing(1),
		flexDirection: 'column',
	},
	box: {
		display: 'flex',
		gap: theme.spacing(1),
		justifyContent: 'center',
	},
	cell: {
		display: 'flex',
		flex: '1',
		justifyContent: 'flex-start',
		overflow: 'hidden',
	},
}))

export const GridLayoutElementTag = '399e76c4-1586-4f22-b1dc-f8705dc4c32c'

const GridLayoutId = '8c46ccac-1d89-44fb-909e-48ed269468bd'

const GridLayoutPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof GridLayout>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.rows}
				onValueChanged={(v) =>
					onPropertiesChanged({
						...properties,
						rows: v,
					})
				}
				Editor={NumberPropertyConfigurator}
				label="Rows"
			/>
			<PropertySchematicConfigurator
				value={properties.columns}
				onValueChanged={(v) =>
					onPropertiesChanged({
						...properties,
						columns: v,
					})
				}
				Editor={NumberPropertyConfigurator}
				label="Columns"
			/>
		</>
	)
}

export const GridLayoutDefinition = specifyObjectComponent(
	GridLayoutId,
	GridLayout,
	GridLayoutElementTag,
	'Grid Layout',
	{
		configurator: GridLayoutPropsEditor,
		defaultProperties: {
			columns: 2,
			rows: 2,
		},
	},
	(v) => {
		const value = (v.rows ?? 0) * (v.columns ?? 0)
		return value
	},
)
