import { Identifier } from '@babel/types'
import { Box, MenuItem, Typography } from '@mui/material'
import { MemberExpression } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import {
	OrganizationPropertiesClient,
	OrganizationPropertyModel,
	OrganizationPropertyType,
} from '../../../../../api/clients/identity/OrganizationPropertiesClient'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent, WithFormsInfo } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import useColorScheme from '../../internal/useColorScheme'

const OrganizationPropertyComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const [, textColor] = useColorScheme(ComponentCategory.Variables)
		const [organizationProperties, setOrganizationProperties] =
			useState<OrganizationPropertyModel[]>()

		useEffect(() => {
			const organizationPropertiesClient = new OrganizationPropertiesClient()

			organizationPropertiesClient
				.GetOrganizationProperties()
				.then((response) => {
					setOrganizationProperties(
						response.data.filter(
							(v) => v.value?.type !== OrganizationPropertyType.List,
						),
					)
				})
		}, [])

		const formsInfo = props.node.formsInfo

		const propertyNode = useMemo(() => {
			return props.node.property as WithFormsInfo<Identifier>
		}, [props.node.property])

		if (
			formsInfo === undefined ||
			formsInfo.type !== 'OrganizationPropertyAccess'
		)
			throw new Error(
				`Organization Property component requires FormsInfo type of 'OrganizationPropertyAccess', got ${formsInfo?.type}`,
			)

		if (organizationProperties === undefined)
			return <FullscreenSpinner size={20} />

		return (
			<Box>
				<ColoredFormControl formControlColor={textColor}>
					<ColoredSelect
						variant="standard"
						fullWidth
						selectColor={textColor}
						value={formsInfo.organizationPropertyId}
						onChange={action((evt) => {
							formsInfo.organizationPropertyId = evt.target.value as number
							propertyNode.name = `_${evt.target.value}`
						})}
					>
						<MenuItem disabled value={0}>
							<Typography fontStyle="italic">Organization Property</Typography>
						</MenuItem>
						{organizationProperties.map((property) => (
							<MenuItem key={property.id} value={property.id}>
								{property.name}
							</MenuItem>
						))}
					</ColoredSelect>
				</ColoredFormControl>
			</Box>
		)
	},
)

export default OrganizationPropertyComponent
