import { AxiosResponse } from "axios"
import SecureClient from "../SecureClient"
import { BaseWorkItemModel, WorkItemQueueSnapshot, WorkItemState } from "./DTOs"

export default class WorkItemQueueClient extends SecureClient {

	public getAnonymousQueue = (workItemState?: WorkItemState): Promise<AxiosResponse<BaseWorkItemModel[]>> => {
		return this._api.get<BaseWorkItemModel[]>(`WorkItemQueue/AnonymousQueue`, {
			headers: {
				...this.acceptHeader,
				...this.contentTypeHeader
			},
			params: {
				workItemState
			}
		})
	}
	
	/**
		 * Get the next work item in the queue belonging to the id (user or group)
		 * @param id the id of the user or group to get the next work item for
		 * @returns the id of the next work item
		 */
	public getNextInQueue = (id: number): Promise<AxiosResponse<number>> => {
		return this._api.get<number>(`WorkItemQueue/${id}/Next`, {
			headers: {
				...this.acceptHeader,
				...this.contentTypeHeader
			}
		})
	}

	/**
	 * Get a work item queue
	 * @param queueId the id of the user or group the queue belongs to
	 * @param workItemState optional - unlocked, locked, active, done, or all
	 * @returns the work items belonging to the specified user or group
	 */
	public getQueue = (queueId: number, workItemState?: WorkItemState): Promise<AxiosResponse<BaseWorkItemModel[]>> => {
		return this._api.get<BaseWorkItemModel[]>(`WorkItemQueue/${queueId}`, {
			headers: {
				...this.acceptHeader,
				...this.contentTypeHeader
			},
			params: {
				workItemState
			}
		})
	}

	/**
	 * Get the information of queues belonging to a user that the work item queue dashboard needs
	 * @param userId 
	 * @returns the assignment type, assigned to, and item count of queues belonging to the user 
	 * (assigned to them or groups they belong to)
	 */
	public getQueueSnapshots = (userId: number): Promise<AxiosResponse<WorkItemQueueSnapshot[]>> => {
		return this._api.get<WorkItemQueueSnapshot[]>(`WorkItemQueue/${userId}/Snapshots`, {
			headers: {
				...this.acceptHeader,
				...this.contentTypeHeader
			}
		})
	}
}