import { CellType } from "../../../FormHost/Types/CellType"
import FieldType, { isValueType } from "../../../FormHost/Types/FieldType"
import { HtmlCellInstance } from "../Types/HtmlCellInstance"

// this is here for us to set up value adapters around the HTML form viewer

export interface IHTMLAdapter {

	canRead(cellInstance: HtmlCellInstance, document: Document): boolean

	read(cellInstance: HtmlCellInstance, document: Document): unknown

	write(cellInstance: HtmlCellInstance, document: Document, value: unknown): void
}

export class HTMLInputAdapter implements IHTMLAdapter {

	canRead(cellInstance: HtmlCellInstance): boolean {
		if (cellInstance.type === CellType.Virtual)
			return false

		return (cellInstance.elementTag === 'INPUT'
			|| cellInstance.elementTag === 'TEXTAREA')
			&& isValueType(cellInstance.fieldType)
	}
	read(cellInstance: HtmlCellInstance, document: Document): unknown {
		const input = document.getElementById(cellInstance.id) as HTMLInputElement
		return input.value
	}

	write(cellInstance: HtmlCellInstance, document: Document, value: string): void {
		const input = document.getElementById(cellInstance.id) as HTMLInputElement
		input.value = value ?? ''
	}
}

export class HTMLBooleanInputAdapter implements IHTMLAdapter {

	canRead(cellInstance: HtmlCellInstance): boolean {

		return cellInstance.type !== CellType.Virtual
			&& cellInstance.elementTag === 'INPUT'
			&& cellInstance.fieldType === FieldType.Boolean
	}

	read(cellInstance: HtmlCellInstance, document: Document): unknown {
		const element = document.getElementById(cellInstance.id) as HTMLInputElement
		return element.checked
	}

	write(cellInstance: HtmlCellInstance, document: Document, value: boolean): void {
		const element = document.getElementById(cellInstance.id) as HTMLInputElement
		element.checked = value
	}

}

export class HTMLVirtualRadioAdapter implements IHTMLAdapter {

	canRead(cellInstance: HtmlCellInstance): boolean {
		return cellInstance.type !== CellType.Virtual
			&& cellInstance.elementTag === 'VIRTUAL'
			&& cellInstance.properties.subType === 'RADIO'
	}

	read(cellInstance: HtmlCellInstance, document: Document): unknown {
		const elements = document.getElementsByName(cellInstance.id) as NodeListOf<HTMLInputElement>

		for (const element of elements)
			if (element.checked)
				return element.value

		return null
	}

	write(cellInstance: HtmlCellInstance, document: Document, value: string) {
		const elements = document.getElementsByName(cellInstance.id) as NodeListOf<HTMLInputElement>

		for (const element of elements) {
			if (!element.checked && element.value === value)
				element.checked = true
			if (element.checked && element.value !== value)
				element.checked = false
		}
	}
}

export class HTMLSelectAdapter implements IHTMLAdapter {

	canRead(cellInstance: HtmlCellInstance, document: Document): boolean {
		if (cellInstance.type === CellType.Virtual)
			return false

		return cellInstance.elementTag === 'SELECT'
			&& isValueType(cellInstance.fieldType)
			&& (cellInstance.fieldType === FieldType.String
				|| cellInstance.fieldType === FieldType.StringArray)
	}

	read(cellInstance: HtmlCellInstance, document: Document): unknown {
		if (cellInstance.fieldType === FieldType.String) {
			const input = document.getElementById(cellInstance.id) as HTMLInputElement
			return input.value
		}

		// multiple select
		if (cellInstance.fieldType === FieldType.StringArray) {
			const selectedOptions = (document.getElementById(cellInstance.id) as HTMLSelectElement).selectedOptions
			const values = Array.from(selectedOptions).map(({ value }) => value)

			return values
		}

		// if it's neither, idk how we got here
		return null
	}

	write(cellInstance: HtmlCellInstance, document: Document, value: unknown): void {
		if (cellInstance.fieldType === FieldType.String) {
			const input = document.getElementById(cellInstance.id) as HTMLSelectElement
			input.value = value as string ?? ''
		}

		// if it's a multiple select we need to set all the selected options to match the value
		if (cellInstance.fieldType === FieldType.StringArray) {
			const input = document.getElementById(cellInstance.id) as HTMLSelectElement

			// thank you stack overflow (https://stackoverflow.com/questions/16582901/javascript-jquery-set-values-selection-in-a-multiple-select)
			// -Caroline
			for (let i = 0; i < input.options.length; i++) {
				input.options[i].selected = (value as string[]).indexOf(input.options[i].value) >= 0;
			}
		}
	}
}
