import { Publish } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { px } from 'csx'
import { observer } from 'mobx-react'
import { ChangeEvent, createRef, useCallback, useMemo, useState } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import { useHistory } from 'react-router'
import { NIL } from 'uuid'
import { DetailForm, FormConfiguration, FormInfoModel } from '../../api/DTOtemp'
import { FormsClient } from '../../api/clients/identity'
import { CellDefinition } from '../../modules/FormHost/Types/CellDefinition'
import { generateDefinitionArray } from '../../modules/FormIntegrations/htmlFormHost/Internals/CellManagement'
import { toastService } from '../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../utils/HOC/FixedHeaders'
import { RemoveUnusedOverrides } from '../../utils/PdfOverrides'

export enum UploadType {
	html = 'text/html',
	pdf = 'application/pdf',
}

interface IUploadFormDialogProps {
	packageId: number
	packageVersionNumber: number
	open: boolean

	/**
	 * need to make sure we don't have forms with the same name. this is optional since uploading
	 * a new form version won't ask for a new name for the form.
	 */
	existingFormNames?: string[]

	/**
	 * if this is present then the dialog will be used to upload a new form version, rather than
	 * creating an entirely new form.
	 */
	formId?: number
	formName?: string
	formType?: UploadType

	formConfiguration: FormConfiguration

	onFormUploaded?: (newForm: DetailForm) => void
	onClose?: () => void
}

const UploadFormDialog = observer((props: IUploadFormDialogProps) => {
	const styles = useStyles()
	const history = useHistory()

	const [formName, setFormName] = useState(props.formName ?? '')
	const [errorMessage, setErrorMessage] = useState('')
	const [file, setFile] = useState<File | undefined>()
	const [isPosting, setIsPosting] = useState(false)

	const iframeRef = createRef<HTMLIFrameElement>()

	const upperExistingFormNames =
		props.existingFormNames?.map((v) => v.toUpperCase()) ?? []

	const handleNameChanged = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			setFormName(evt.target.value)
			validateFormName(evt.target.value)
		},
		[upperExistingFormNames],
	)

	const handleDrop = useCallback(
		(acceptedFiles: File[]) => {
			console.log('handle drop')
			const newFile = acceptedFiles[0]
			if (formName === '' || formName === file?.name) {
				// we don't want the file extension in the form name
				const fileName =
					newFile.name.substring(0, newFile.name.lastIndexOf('.')) ||
					newFile.name

				setFormName(fileName)
				validateFormName(fileName)
			}
			setFile(newFile)
		},
		[upperExistingFormNames],
	)

	const validateFormName = (name: string) => {
		if (upperExistingFormNames.includes(name.toUpperCase().trim()))
			setErrorMessage('Form name must be unique')
		else if (name === '') setErrorMessage('Form name is required')
		else if (!/^[a-zA-Z0-9 _]+$/.test(name))
			setErrorMessage('The form name cannot have any special characters')
		else setErrorMessage('')
	}

	const handleUploadClicked = useCallback(() => {
		if (file === undefined) throw Error('no file has been uploaded')

		setIsPosting(true)

		const targetIframe = iframeRef.current

		if (targetIframe?.contentDocument !== null)
			console.log('target iframe has a content document')

		if (targetIframe == null) throw new Error()

		toText(file).then((v) => {
			const targetDocument =
				targetIframe.contentDocument ?? targetIframe.contentWindow?.document
			if (targetDocument === null || targetDocument === undefined)
				throw Error('failed to open iframe document')

			/* did I have to steal this from stuart? yeah... I did... Don't ask me how I feel
			about it ☹	*/
			targetDocument.open('text/html', 'replace')
			targetDocument.write(v)
			targetDocument.close()
		})

		const definitionArray: CellDefinition[] = []

		targetIframe.onload = () => {
			const contentDocument =
				targetIframe.contentDocument ?? targetIframe.contentWindow?.document

			if (contentDocument === undefined || contentDocument === null)
				throw Error('no content document exists on loaded iframe')

			if (contentDocument.contentType === UploadType.html)
				definitionArray.push(
					...generateDefinitionArray(contentDocument, [], NIL),
				)

			const api = new FormsClient(props.packageId, props.packageVersionNumber)

			const formInfoModel: FormInfoModel = {
				name: formName,
				formData: file,
				formConfiguration: props.formConfiguration,
				metadata: {
					cellDefinitions: definitionArray,
					cellOverrides: [],
					dynamicProperties: {},
				},
			}

			// gets rid of unecessary overrides
			formInfoModel.metadata.cellOverrides = RemoveUnusedOverrides(
				formInfoModel.metadata.cellDefinitions,
				formInfoModel.metadata.cellOverrides,
			)

			const promise =
				props.formId === undefined
					? api.CreateForm(formInfoModel)
					: api.UpdateForm(props.formId, formInfoModel)

			promise
				.then((v) => {
					props.onFormUploaded && props.onFormUploaded(v.data)
					props.onClose && props.onClose()
					if (props.formId === undefined)
						history.push(
							`${props.packageId}/_forms/${props.formId ?? v.data.id}`,
						)

					toastService.displayToast({
						message: 'Form uploaded',
						area: 'global',
					})

					setFile(undefined)
				})
				.catch(() => {
					toastService.displayToast({
						severity: 'error',
						message: 'Error uploading form',
						area: 'global',
					})
				})
				.finally(() => {
					setIsPosting(false)
				})
		}
	}, [file, formName])

	const acceptedFiles = useMemo(() => {
		// if there's already a form we want to only accept that same content type
		if (props.formType !== undefined) return [props.formType.toString()]

		return ['text/html', 'application/pdf']
	}, [])

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Upload Form</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{props.formId === undefined
						? 'Upload a new HTML or PDF form. The name must be unique for the form package.'
						: 'Upload a new version of the current form'}
				</DialogContentText>
				<div className={styles.mainDiv}>
					{props.formId === undefined && (
						<FixedHeaderWithHint
							className={styles.verticalSpacing}
							label="Form Name"
							hint={errorMessage}
							hintColor="error.main"
						>
							<TextField
								autoFocus
								value={formName}
								onChange={handleNameChanged}
								fullWidth
								autoComplete="off"
								error={errorMessage !== ''}
							/>
						</FixedHeaderWithHint>
					)}
					<Dropzone
						maxFiles={1}
						maxSize={5_000_000}
						accept={acceptedFiles}
						multiple={false}
						onDrop={handleDrop}
					>
						{({ getRootProps, getInputProps }: DropzoneState) => (
							<section className={styles.verticalSpacing}>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<div className={styles.dragAndDropBody}>
										{file !== undefined ? (
											<Typography>{file.name}</Typography>
										) : (
											<>
												<Typography>
													Drag and drop your file here, or click to select a
													file
												</Typography>
												<Publish />
											</>
										)}
									</div>
								</div>
							</section>
						)}
					</Dropzone>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose} color="primary">
					Cancel
				</Button>
				<Button
					onClick={handleUploadClicked}
					color="primary"
					variant="contained"
					disabled={errorMessage !== '' || file === undefined}
				>
					Upload
				</Button>
			</DialogActions>
			{isPosting && <LinearProgress />}
			<iframe
				className={styles.hiddenIframe}
				sandbox="allow-same-origin"
				ref={iframeRef}
			/>
		</Dialog>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	hiddenIframe: {
		display: 'none',
	},

	verticalSpacing: {
		margin: theme.spacing(2, 0),
	},

	dragAndDropBody: {
		display: 'flex',
		flexDirection: 'column',

		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: theme.shape.borderRadius,
		borderStyle: 'dashed',
		borderColor: theme.palette.text.secondary,

		cursor: 'pointer',

		minWidth: px(500),
		minHeight: px(200),
	},
}))

const toText = (file: File) =>
	new Promise<string>((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsText(file)
		reader.onload = () => {
			const result = reader.result
			if (typeof result !== 'string')
				throw Error('could not convert result to a base64 string')
			resolve(result)
		}
		reader.onerror = (error) => reject(error)
	})

export default UploadFormDialog
