import { observable } from "mobx"
import { Breadcrumb, UseAdministrationBreadcrumbsContext } from "./AdministrationBreadcrumbsContext"


/**
 * add a link to the existing breadcrumbs if it isn't there, 
 * but if it is, remove everything after it because that is 
 * the current page we are on
 * @param link the link to the page to add to breadcrumbs
 * @param pageName the name of the page
 */
export const updateBreadcrumbs = (link: string, pageName: string): Breadcrumb => {
	const breadcrumbContext = UseAdministrationBreadcrumbsContext()

	const found = breadcrumbContext.breadcrumbs.findIndex(v => v.link === link)

	if (found >= 0) {
		breadcrumbContext.breadcrumbs.splice(found + 1, breadcrumbContext.breadcrumbs.length)

		return breadcrumbContext.breadcrumbs[found]
	}
	else {
		const newBreadcrumb = observable({ link, pageName })
		breadcrumbContext.breadcrumbs.push(newBreadcrumb)

		return newBreadcrumb
	}
}

/**
 * for when we're swapping sections, like Form Packages -> Administration, so Administration
 * isn't added onto the Form Packages link
 * should be used in the main routers
 * @param link the link to the page to add to breadcrumbs
 * @param pageName the name of the page
 */
export const resetBreadcrumbs = (link: string, pageName: string): Breadcrumb => {
	const breadcrumbContext = UseAdministrationBreadcrumbsContext()

	const newBreadcrumb = observable({ link, pageName })
	breadcrumbContext.breadcrumbs = [newBreadcrumb]

	return newBreadcrumb
}