import { AxiosResponse } from 'axios'
import SecureClient from '../SecureClient'
import { SecretType, VaultRecord } from './DTO'

export default class VaultStorageClient extends SecureClient {
	/**
	 * get all the available vaults (ex - SQL Server or Azure)
	 * @returns all available configurable vaults
	 */
	public getVaults = (): Promise<
		AxiosResponse<VaultRecord[]>
	> => {
		return this._api.get<VaultRecord[]>('VaultStorage', {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}

	/**
	 * get one configurable vault
	 * @param vaultId 
	 * @returns the configurable vault if it exists
	 */
	public getVault = (
		vaultId: string,
	): Promise<AxiosResponse<VaultRecord>> => {
		return this._api.get<VaultRecord>(
			`VaultStorage/${vaultId}`,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * get one secret type
	 * @param vaultId the id of the vault the secret type belongs tp
	 * @param secretTypeId the id of the secret type to fetch
	 * @returns the secret type if it exists
	 */
	public getSecretType = (vaultId: string, secretTypeId: string): Promise<AxiosResponse<SecretType>> => {
		return this._api.get<SecretType>(`VaultStorage/${vaultId}/SecretTypes/${secretTypeId}`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}
}
