import { makeAutoObservable } from 'mobx'
import * as React from 'react'
import { ComponentType, createContext, PropsWithChildren } from 'react'
import { CellType } from '../../../../FormHost/Types/CellType'
import { IFormBuilderFormHost } from '../../../IFormBuilderFormHost'
import {
	IRepeatFormBuilderCellInstance,
	virtualValueKey,
} from '../../FormBuilderCellInstance'
import { RepeatElement, VirtualElement } from '../../FormBuilderCellTree'
import { ComponentRegistryDictionary } from '../../GlobalComponentRegistry'
import { appendRepeatElement } from '../CellManager'
import { renderCells } from '../cellRenderer'
import {
	CellWrapperType,
	ComponentWrapper,
	FormBuilderElementContextProps,
	NullCellNodeType,
	NullContextType,
} from './ContextTypes'

const cellContext = createContext<RepeatCellInstanceManager | undefined>(
	undefined,
)

export const RepeatContextProvider = (
	props: PropsWithChildren<FormBuilderElementContextProps>,
) => {
	const manager = React.useMemo(() => {
		const node = props.node

		if (node.cellType !== CellType.Repeat)
			throw new Error('repeat context provider requires a repeat node')

		return new RepeatCellInstanceManager(
			props.componentRegistry,
			props.formHost,
			node,
			props.CellWrapper,
			props.NullCellNode,
		)
	}, [
		props.componentRegistry,
		props.formHost,
		props.node,
		props.CellWrapper,
		props.NullCellNode,
	])

	return (
		<cellContext.Provider value={manager}>
			{props.children}
		</cellContext.Provider>
	)
}

export function useRepeatCellContext() {
	const context = React.useContext(cellContext)
	if (context === undefined) throw new Error('unable to create repeat context')
	return context
}

class RepeatCellInstanceManager {
	private readonly _componentRegistry: ComponentRegistryDictionary

	private readonly _cellManager: IFormBuilderFormHost

	public get cellInstance(): IRepeatFormBuilderCellInstance {
		return this.node.cell
	}

	public readonly node: RepeatElement

	private readonly CellWrapper: ComponentType<
		PropsWithChildren<ComponentWrapper>
	>

	private readonly NullCellNode?: ComponentType<NullContextType>

	public get cellComponents(): [VirtualElement, JSX.Element[]][] {
		return this.node.children.map((node) => {
			console.log('node child cell count: ', node.children.length)

			return [
				node,
				renderCells(
					{
						componentRegistry: this._componentRegistry,
						formHost: this._cellManager,
						CellWrapper: this.CellWrapper,
						NullCellNode: this.NullCellNode,
					},
					node.children,
				),
			]
		})
	}

	constructor(
		componentRegistry: ComponentRegistryDictionary,
		cellManager: IFormBuilderFormHost,
		node: RepeatElement,
		cellWrapper: CellWrapperType,
		nullCellNode?: NullCellNodeType,
	) {
		makeAutoObservable(this)
		this._componentRegistry = componentRegistry
		this._cellManager = cellManager
		this.node = node
		this.CellWrapper = cellWrapper
		this.NullCellNode = nullCellNode
	}

	public appendNode() {
		if (this._cellManager.isReadonly) return
		appendRepeatElement(this._cellManager, this.node)
	}

	public removeNode(index: number) {
		if (this._cellManager.isReadonly) return

		this.node.children.splice(index, 1)
		this.node.cell[virtualValueKey].splice(index, 1)

		for (let i = 0; i < this.node.children.length; i++)
			this.node.children[i].cell.ordinalPosition = i
	}
}
