import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import {
	BaseAuthenticationProviderInstanceModel,
	ConfigurableAuthenticationProvider,
} from '../../../../api/clients/authenticationProviders/DTO'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'

type AddAuthenticationProviderDialogProps = {
	providers: ConfigurableAuthenticationProvider[]
	upperInstanceNames: string[] // to avoid duplicate names
	onConfirm: (instanceModel: BaseAuthenticationProviderInstanceModel) => void
	onCancel: () => void
}

export const AddAuthenticationProviderDialog = (
	props: AddAuthenticationProviderDialogProps,
) => {
	const [selectedProvider, setSelectedProvider] =
		useState<ConfigurableAuthenticationProvider>(
			props.providers[0] ?? undefined,
		)

	const styles = useStyles()

	const validationSchema = yup.object({
		name: yup
			.string()
			.required('Name is a required field')
			.test(
				'is-unique',
				'An authentication provider with this name already exists in your organization',
				(value) => {
					return (
						value !== undefined &&
						!props.upperInstanceNames.includes(value.toUpperCase().trim())
					)
				},
			),
	})

	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			if (selectedProvider === undefined) return

			props.onConfirm({
				displayName: values.name,
				authenticationProviderId: selectedProvider.authenticationProviderId,
				state: {},
			})
		},
	})
	return (
		<>
			<DialogTitle>Add Authentication Provider</DialogTitle>
			<DialogContent>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.touched.name && formik.errors.name}
					hintColor="error.main"
					className={styles.textField}
				>
					<TextField
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						error={!!formik.errors.name && formik.touched.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeader label="Type" className={styles.textField}>
					<Select
						defaultValue={selectedProvider.authenticationProviderId}
						fullWidth
						value={selectedProvider.authenticationProviderId}
						onChange={(evt) =>
							setSelectedProvider(
								props.providers.find(
									(v) => v.authenticationProviderId === evt.target.value,
								) ?? props.providers[0],
							)
						}
					>
						{props.providers.map((provider) => (
							<MenuItem
								key={provider.authenticationProviderId}
								value={provider.authenticationProviderId}
							>
								<Typography>{provider.displayName}</Typography>
							</MenuItem>
						))}
					</Select>
				</FixedHeader>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={
						!formik.dirty ||
						!formik.isValid ||
						selectedProvider === undefined ||
						formik.isSubmitting
					}
					onClick={formik.submitForm}
				>
					Add
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	textField: {
		margin: theme.spacing(2, 0),
	},
}))
