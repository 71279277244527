import {
	ComponentRegistryDictionary,
	GlobalComponentRegistryType,
} from '../../FormBuilderCore/cells/GlobalComponentRegistry'
import {
	AutoCompleteInputDefinition,
	CheckboxDefinition,
	ChecklistDefinition,
	DataProviderConfiguratorDefinition,
	DatePickerDefinition,
	ExpressionEditorConfiguratorDefinition,
	FormBuilderDesignerInputDefinition,
	GridLayoutDefinition,
	GridRepeatDefinition,
	HeaderComponentDefinition,
	InternalAutoCompleteInputDefinition,
	InternalCheckboxDefinition,
	InternalMonacoFormBuilderDefinition,
	InternalNumberInputDefinition,
	InternalTextInputDefinition,
	ListInputDefinition,
	MultiSelectInputDefinition,
	NumberInputDefinition,
	RadioInputDefinition,
	SelectInputDefinition,
	SignatureInputDefinition,
	TextInputDefinition,
	TimePickerDefinition,
} from '../Components'
import { FileInputDefinition } from '../Components/Inputs/FileInput'
import { PasswordInputDefinition } from '../Components/Inputs/PasswordInput'
import { ImageComponentDefinition } from '../Components/Void/ImageComponent'
import { ParagraphComponentDefinition } from '../Components/Void/ParagraphComponent'

let registryInitialized = false

export function getGlobalComponentRegistry(): GlobalComponentRegistryType {
	if (!registryInitialized) initialize()
	return G_GlobalComponentRegistryObject
}

function initialize() {
	registryInitialized = true

	G_GlobalComponentRegistryObject.componentCollection = {
		[InternalAutoCompleteInputDefinition.id]:
			InternalAutoCompleteInputDefinition,
		[InternalCheckboxDefinition.id]: InternalCheckboxDefinition,
		[InternalNumberInputDefinition.id]: InternalNumberInputDefinition,
		[InternalTextInputDefinition.id]: InternalTextInputDefinition,

		[DataProviderConfiguratorDefinition.id]: DataProviderConfiguratorDefinition,
		[InternalMonacoFormBuilderDefinition.id]:
			InternalMonacoFormBuilderDefinition,
		[ExpressionEditorConfiguratorDefinition.id]:
			ExpressionEditorConfiguratorDefinition,

		[AutoCompleteInputDefinition.id]: AutoCompleteInputDefinition,
		[CheckboxDefinition.id]: CheckboxDefinition,
		[ChecklistDefinition.id]: ChecklistDefinition,
		[DatePickerDefinition.id]: DatePickerDefinition,
		[ListInputDefinition.id]: ListInputDefinition,
		[MultiSelectInputDefinition.id]: MultiSelectInputDefinition,
		[NumberInputDefinition.id]: NumberInputDefinition,
		[PasswordInputDefinition.id]: PasswordInputDefinition,
		[RadioInputDefinition.id]: RadioInputDefinition,
		[SelectInputDefinition.id]: SelectInputDefinition,
		[SignatureInputDefinition.id]: SignatureInputDefinition,
		[TextInputDefinition.id]: TextInputDefinition,
		[TimePickerDefinition.id]: TimePickerDefinition,

		[GridLayoutDefinition.id]: GridLayoutDefinition,

		[GridRepeatDefinition.id]: GridRepeatDefinition,

		[FileInputDefinition.id]: FileInputDefinition,
		[HeaderComponentDefinition.id]: HeaderComponentDefinition,
		[ImageComponentDefinition.id]: ImageComponentDefinition,
		[ParagraphComponentDefinition.id]: ParagraphComponentDefinition,

		// put components that we want to remove below here 😀
		[FormBuilderDesignerInputDefinition.id]: FormBuilderDesignerInputDefinition,
	} as ComponentRegistryDictionary
}

const G_GlobalComponentRegistryObject: GlobalComponentRegistryType = {
	componentCollection: {} as ComponentRegistryDictionary,
}
