import { Save, Upload } from '@mui/icons-material'
import {
	Box,
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { FormPackageInfo } from '../../../../../../../../api/DTOtemp'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { ClickToCopyButton } from '../../../../../../../../components/buttons/ClickToCopyButton'
import DescriptiveSwitch from '../../../../../../../../components/inputs/DescriptiveSwitch'
import { useModals } from '../../../../../../../../services/notifications/ModalService'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { getPackageValidationSchema } from '../../../../FormPackageUtils'
import { UploadFormPackageDialog } from '../../../UploadFormPackageDialog'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'

type SettingsSectionProps = {
	packageId: number
	upperPackageNames: string[] // to make sure we don't get duplicate names
}

export const SettingsAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps & SettingsSectionProps) => {
		const styles = useStyles()
		const context = useFormPackageContext()
		const modalService = useModals()

		const formik = useFormik({
			initialValues: {
				name: context.formPackage.name,
				description: context.formPackage.description,
				anonymous: !context.formPackage.authenticationRequired,
				offline: context.formPackage.configuration.settings.offlineEnabled,
				attachments:
					context.formPackage.configuration.settings.attachmentsEnabled,
				clientActionBar:
					context.formPackage.configuration.settings.showClientActionBar,
				locationServices:
					context.formPackage.configuration.settings.enableLocationServices,
				wizardMode: context.formPackage.configuration.settings.wizardMode,
				digitallySign: context.formPackage.configuration.settings.digitallySign,
				disableSubmission:
					context.formPackage.configuration.settings.disableSubmission,
			},
			validationSchema: getPackageValidationSchema(
				props.upperPackageNames,
				context.formPackage.name,
			),

			onSubmit: (values) => {
				handleUpdatePackage(values)
					.then(
						action((v) => {
							toastService.displayToast({
								message: 'Settings updated',
								area: 'global',
							})
						}),
					)
					.catch((e) => {
						console.log(e)
						toastService.displayToast({
							message: 'Error updating settings',
							area: 'global',
						})
					})
			},
		})

		const handleUpdatePackage = async (values: {
			name: string
			description: string
			anonymous: boolean
			offline: boolean
			attachments: boolean
			clientActionBar: boolean
			locationServices: boolean
			wizardMode: boolean
			digitallySign: boolean
			disableSubmission: boolean
		}) => {
			runInAction(() => {
				context.formPackage.name = values.name
				context.formPackage.description = values.description
				context.formPackage.authenticationRequired = !values.anonymous

				const packageSettings = context.formPackage.configuration.settings

				packageSettings.offlineEnabled = values.offline
				packageSettings.attachmentsEnabled = values.attachments
				packageSettings.showClientActionBar = values.clientActionBar
				packageSettings.enableLocationServices = values.locationServices
				packageSettings.wizardMode = values.wizardMode
				packageSettings.digitallySign = values.digitallySign
				packageSettings.disableSubmission = values.disableSubmission
			})

			const api = new FormPackageVersionClient(props.packageId)
			return await api.UpdateConfiguration(context.formPackage)
		}

		return (
			<AdministrationPageContainer
				title={'Form Package Settings'}
				actions={
					<div>
						<Box
							component={Button}
							color="primary"
							variant="outlined"
							className={styles.noTextTransform}
							marginRight={2}
							onClick={() =>
								modalService
									.showForm((modalProps) => (
										<UploadFormPackageDialog
											existingPackage={context.formPackage}
											existingPackageNames={props.upperPackageNames}
											onCancel={() =>
												modalProps.close({ closeResult: 'cancel' })
											}
											onConfirm={(uploadedPackage) =>
												modalProps.close({
													closeResult: 'okay',
													value: uploadedPackage,
												})
											}
										/>
									))
									.then(
										action((response) => {
											if (response.closeResult === 'okay') {
												const packageInfo = response.value as FormPackageInfo

												const updatedSettings = {
													name: packageInfo.name,
													description: packageInfo.description,
													anonymous: !packageInfo.authenticationRequired,
													attachments:
														packageInfo.configuration.settings
															.attachmentsEnabled,
													clientActionBar:
														packageInfo.configuration.settings
															.showClientActionBar,
													digitallySign:
														packageInfo.configuration.settings.digitallySign,
													locationServices:
														packageInfo.configuration.settings
															.enableLocationServices,
													offline:
														packageInfo.configuration.settings.offlineEnabled,
													wizardMode:
														packageInfo.configuration.settings.wizardMode,
													disableSubmission:
														packageInfo.configuration.settings
															.disableSubmission,
												}

												context.formPackage = packageInfo

												// because the switches rely on formik we need to update those values to
												// otherwise nothing in the settings page will change
												formik.resetForm({ values: updatedSettings })

												toastService.displayToast({
													message: 'Form package updated',
													area: 'global',
												})
											}
										}),
									)
									.catch((e) => {
										console.log(e)
										toastService.displayToast({
											message: 'Error updating form package',
											area: 'global',
										})
									})
							}
							startIcon={<Upload />}
						>
							Upload
						</Box>
						<Button
							color="primary"
							variant="contained"
							className={styles.noTextTransform}
							onClick={formik.submitForm}
							disabled={!formik.dirty || !formik.isValid}
							startIcon={<Save />}
						>
							Save
						</Button>
					</div>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={0}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<Fade in unmountOnExit>
						<Paper className={styles.insetSpacing}>
							<div className={styles.formSettingsRoot}>
								<div className={styles.formElement}>
									<TextField
										name="name"
										id="name"
										value={formik.values.name}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={!!formik.errors.name}
										helperText={formik.errors.name ?? ' '}
										label="Form Package Name"
										fullWidth
										autoComplete="off"
									/>
								</div>

								<div className={styles.formElement}>
									<TextField
										name="description"
										id="description"
										value={formik.values.description}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={!!formik.errors.description}
										helperText={formik.errors.description ?? ' '}
										label="Form Package Description"
										fullWidth
										autoComplete="off"
									/>
								</div>

								<DescriptiveSwitch
									name="anonymous"
									id="anonymous"
									checked={formik.values.anonymous}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Anonymous Access"
									bodyLabel="Allow this form to be accessed by users who are not logged in"
								/>

								<div className={styles.formElement}>
									{formik.values.anonymous && (
										<Fade in unmountOnExit>
											<AnonymousLink packageId={props.packageId} />
										</Fade>
									)}
								</div>
								<DescriptiveSwitch
									name="attachments"
									id="attachments"
									checked={formik.values.attachments}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Attachments"
									bodyLabel="Allow attachments to be uploaded with this form"
								/>
								<DescriptiveSwitch
									name="clientActionBar"
									id="clientActionBar"
									checked={formik.values.clientActionBar}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Client Action Bar"
									bodyLabel="Display the client action bar with the form"
								/>
								<DescriptiveSwitch
									name="locationServices"
									id="locationServices"
									checked={formik.values.locationServices}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Location Services"
									bodyLabel="Submit the form with the user's current location, provided they grant permission"
								/>
								<DescriptiveSwitch
									name="wizardMode"
									id="wizardMode"
									checked={formik.values.wizardMode}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Wizard Mode"
									bodyLabel="Require the user to fix all validation issues before moving to the next form"
								/>
								<DescriptiveSwitch
									name="offline"
									id="offline"
									checked={formik.values.offline}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Offline"
									bodyLabel="Make form package available when the user is not able to access the internet"
								/>
								<DescriptiveSwitch
									name="digitallySign"
									id="digitallySign"
									checked={formik.values.digitallySign}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Digitally Sign Forms"
									bodyLabel="Digitally sign each form on submit"
								/>
								<DescriptiveSwitch
									name="disableSubmission"
									id="disableSubmission"
									checked={formik.values.disableSubmission}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									containerClassName={styles.formElement}
									color="primary"
									headerLabel="Disable Submission"
									bodyLabel="Remove the submit button on the form package"
								/>
							</div>
						</Paper>
					</Fade>
				</div>
			</AdministrationPageContainer>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		paddingTop: theme.spacing(1),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},

	formSettingsRoot: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},
}))

export const SettingsSectionButtons = () => {
	return (
		<>
			<div>
				<Button color="primary"></Button>
			</div>
		</>
	)
}

type AnonymousLinkProps = {
	packageId: number
}

const AnonymousLink = React.forwardRef<HTMLDivElement, AnonymousLinkProps>(
	(props, ref) => {
		const styles = useAnonymousLinkStyles()

		const anonymousLink = `${window.location.origin}/_external/_package-host/${props.packageId}`

		return (
			<div ref={ref} className={styles.anonymousLinkRoot}>
				<div className={styles.anonymousLinkStart}>
					<Typography color="textSecondary" variant="caption">
						Anonymous / Embed Link
					</Typography>
					<div className={styles.anonymousLinkContent}>
						<TextField value={anonymousLink} />
						<ClickToCopyButton value={anonymousLink} />
					</div>
				</div>
			</div>
		)
	},
)

const useAnonymousLinkStyles = makeStyles((theme: Theme) => ({
	anonymousLinkRoot: {
		display: 'flex',

		height: percent(100),
		width: percent(100),

		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},

	anonymousLinkStart: {
		flex: '1',
		overflow: 'hide',
	},

	anonymousLinkContent: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
	},
}))
