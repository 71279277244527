import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAttachmentsApi } from '../../../pages/PackageHostPage/Attachments/AttachmentsApi'
import { usePackageHostPageContext } from '../../../pages/PackageHostPage/PackageHostPageContext'
import { useRuntimePackageContext } from '../../../pages/PackageHostPage/RuntimePackageContext'
import { useModals } from '../../../services/notifications/ModalService'
import { CacheDetailForm } from '../../../services/offline/FormPackageStorage'
import { FimWindow } from '../../../utils/controlApi/FimWindow'
import { FormIntegrationProps } from '../Types'
import { HtmlFormManager } from './HtmlFormHost'
import { HtmlPackageHostWrapper } from './HtmlPackageHostWrapper'
import { HtmlCellDefinition } from './Types/HtmlCellDefinition'
import { HtmlCellInstance } from './Types/HtmlCellInstance'
import HtmlPortalManager from './Utilities/HtmlPortalManager'

type HtmlFormProps = {
	formName: string
} & FormIntegrationProps

export const HtmlForm = (props: HtmlFormProps) => {
	const { eventBus } = useRuntimePackageContext()
	const { validationErrors } = usePackageHostPageContext()

	const [formReady, setFormReady] = useState<boolean>(false)
	const [host, setHost] = useState<HTMLIFrameElement | null>(null)
	const [formManager, setFormManager] = useState<HtmlFormManager | undefined>()

	const attachmentService = useAttachmentsApi()
	const modalsApi = useModals()

	const setFormHostCallback = useCallback(
		(element: HTMLIFrameElement | null) => {
			setHost(element)
		},
		[setHost],
	)

	useEffect(() => {
		console.log('use effect fired in html form host with id ' + props.formId)

		// this way avoids closures
		const intervals = {
			sizeInterval: 0,
		}

		if (host === null) return

		if (host.onload) return

		console.log('defining onload')

		host.onload = async () => {
			console.log('form load event fired')
			const updateInterval = 100

			const iframeDocument = host.contentDocument
			if (iframeDocument === null || iframeDocument === undefined)
				throw Error('iframe document does not exist')

			// TODO past form in props
			const definitions = props.form.metadata
				.cellDefinitions as HtmlCellDefinition[]

			const fimWindow = host.contentWindow as FimWindow

			intervals.sizeInterval = startResizeLoop(host, updateInterval)

			if (formManager) formManager.dispose()

			let cellInstances: HtmlCellInstance[] = []
			const castForm = props.form as CacheDetailForm
			if (castForm.cellInstances !== undefined)
				cellInstances = castForm.cellInstances

			const internalFormManager = new HtmlFormManager(
				definitions,
				cellInstances,
				eventBus,
				iframeDocument,
				validationErrors,
				props.formName,
				attachmentService,
				modalsApi,
			)

			internalFormManager.formId = props.formId

			internalFormManager.setSavedInstanceValues()

			props.onFormLoaded(
				new HtmlPackageHostWrapper(internalFormManager, host, iframeDocument),
			)

			setFormManager(internalFormManager)

			if (fimWindow.hook && internalFormManager.controlApi !== undefined) {
				fimWindow.hook(internalFormManager.controlApi)
				internalFormManager.controlApi.onFormLoaded()
			}
		}

		setFormReady(true)

		return () => {
			window.clearInterval(intervals.sizeInterval)
			formManager?.dispose()
			console.log('unloading html form, cleared intervals ', intervals)
		}
	}, [host])

	// this needs to be memoized otherwise we end up loading the host twice
	// which causes issues when setting values to fields on form load
	const formSource = useMemo(
		() => URL.createObjectURL(props.form.stream),
		[props.form.stream],
	)

	const styles = useStyles()

	console.log(
		`html viewer status\nform ready: ${formReady}\nform manager: ${formManager}\niframe content doc: ${host}`,
	)

	return (
		<>
			{formReady && formManager && host?.contentDocument && (
				<HtmlPortalManager
					cellManager={formManager}
					isAnonymous={props.anonymous}
					document={host.contentDocument}
				/>
			)}
			<iframe
				src={formSource}
				className={styles.root}
				sandbox="allow-forms allow-pointer-lock allow-scripts allow-same-origin allow-popups allow-modals allow-downloads"
				allowFullScreen
				scrolling="no"
				ref={setFormHostCallback}
				id={`form-${props.formId}`}
			/>
		</>
	)
}

const startResizeLoop = (iframe: HTMLIFrameElement, interval: number) => {
	const resizeFn = () => {
		const innerDocument = iframe.contentDocument

		if (innerDocument == null) return
		const iframeBody = innerDocument.body
		const height = iframeBody.scrollHeight

		if (iframe.style.height != px(height))
			iframe.style.height = px(height) as string
	}

	resizeFn()
	const intervalId = window.setInterval(() => {
		resizeFn()
	}, interval)

	return intervalId
}

const useStyles = makeStyles(() => ({
	root: {
		width: percent(100),
		height: percent(100),
		frameBorder: 0,
		borderWidth: 0,
		overflow: 'visible',
	},
}))
