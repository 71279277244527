import { Clear, Done, Send } from '@mui/icons-material'
import { Button, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { computed, isObservable, values } from 'mobx'
import { observer } from 'mobx-react'
import { CommitIntent } from '../../api/DTOtemp'
import { useModals } from '../../services/notifications/ModalService'
import { usePackageHostPageContext } from './PackageHostPageContext'
import { PackageDescriptionDialog } from './SavedPackageDescriptionDialog'

type SubmitPackageButtonProps = {
	onSubmit: (
		intent: CommitIntent.Submit | CommitIntent.Approve | CommitIntent.Reject,
		description?: string,
	) => Promise<void>
	approveAndRejectButtons: boolean // display Approve & Reject instead of Submit
}

export const SubmitPackageButton = observer(
	(props: SubmitPackageButtonProps) => {
		const styles = useStyles()
		const modalService = useModals()

		const context = usePackageHostPageContext()

		if (!isObservable(context.validationErrors))
			throw new Error('why not observable')

		const observableValidationErrors = context.validationErrors

		const observableErrors = computed(() => values(observableValidationErrors))
			.get()
			.flatMap((v) => v)

		const hasErrors = observableErrors.length > 0

		const handleApproveOrReject = async (
			intent: CommitIntent.Approve | CommitIntent.Reject,
		): Promise<unknown> => {
			return modalService
				.showForm((formProps) => (
					<PackageDescriptionDialog
						intent={intent}
						initialDescription={''}
						onConfirm={(v) =>
							formProps.close({
								closeResult: 'okay',
								value: v,
							})
						}
						onCancel={() => formProps.close({ closeResult: 'cancel' })}
					/>
				))
				.then((v) => {
					console.log('close result: ', v.closeResult)
					if (v.closeResult === 'okay') {
						return props.onSubmit(intent, v.value as string)
					}
				})
		}

		return (
			<Tooltip title={hasErrors ? 'Correct errors before submitting' : ''}>
				<div>
					{props.approveAndRejectButtons ? (
						<>
							<Button
								variant="outlined"
								color="error"
								startIcon={<Clear />}
								className={styles.approvalButtons}
								onClick={() => handleApproveOrReject(CommitIntent.Reject)}
								disabled={hasErrors}
							>
								Reject
							</Button>
							<Button
								variant="outlined"
								color="success"
								startIcon={<Done />}
								className={styles.approvalButtons}
								onClick={() => handleApproveOrReject(CommitIntent.Approve)}
								disabled={hasErrors}
							>
								Approve
							</Button>
						</>
					) : (
						<Button
							className={styles.appBarButton}
							color="primary"
							variant="outlined"
							startIcon={<Send />}
							onClick={() => props.onSubmit(CommitIntent.Submit)}
							disabled={hasErrors}
						>
							Submit
						</Button>
					)}
				</div>
			</Tooltip>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	appBarButton: {
		margin: '10px',
	},

	approvalButtons: {
		margin: theme.spacing(0, 1),
	},
}))
