import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

type UnsavedPackageChangesDialogProps = {
	onCancel: () => void
	onConfirm: (save: boolean) => void
}

export const UnsavedPackageChangesDialog = (
	props: UnsavedPackageChangesDialogProps,
) => {
	const styles = useStyles()

	return (
		<>
			<DialogTitle>Unsaved Changes</DialogTitle>
			<DialogContent>
				Your package has unsaved changes. Do you want to save them?
			</DialogContent>
			<DialogActions className={styles.actions}>
				<Button color="primary" variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				<div>
					<Button
						color="error"
						variant="outlined"
						className={styles.discard}
						onClick={() => props.onConfirm(false)}
					>
						Discard
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => props.onConfirm(true)}
					>
						Save
					</Button>
				</div>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	actions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		margin: theme.spacing(0, 0, 0, 2),
	},

	discard: {
		margin: theme.spacing(0, 1),
	},
}))
