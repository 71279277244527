
import { SessionService } from "../../services/session/SessionService"
import ClientBase from './ClientBase'

class SecureClient extends ClientBase {

	protected readonly _sessionService = new SessionService()
	private readonly AuthorizationHeaderName: string = "authorization"
	private readonly OrganizationAccessHeaderName: string = "organization-token"

	constructor() {
		super()

		this._api.interceptors.response.use(config => {
			if (config.headers[this.AuthorizationHeaderName])
				this._sessionService.authToken.rawToken = config.headers[this.AuthorizationHeaderName]
			
			return config
		})

	}

	protected get hasAuthorizationHeader(): boolean {
		return !!this._sessionService.authToken.rawToken
	}

	protected get authorizationHeader(): AuthorizeHeader {
		if (!this._sessionService.authToken.rawToken)
			throw new Error(
				"cannot make secured call without authentication token")
		return {Authorization: this._sessionService.authToken.rawToken}
	}
}

export type AuthorizeHeader = {
	'Authorization': string
}

export type OrganizationHeader = {
	'organization-token': string
}

export default SecureClient
