import { Box, TextField, TextFieldProps } from '@mui/material'
import {
	LocalizationProvider,
	TimePicker as MuiTimePicker,
} from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { observer } from 'mobx-react'
import moment, { Moment } from 'moment'
import { ComponentProps } from 'react'
import { FixedHeader } from '../../../../utils/HOC/FixedHeaders'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	CheckboxPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
	TimePickerPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type TimePickerProps = {
	label: string
	format?: string
	useTwentyFourHourTime?: boolean
	// inputVariant: "standard" // TODO
}

const TimePicker = observer((props: TimePickerProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()

	const format = props.format ?? 'HH:mm'

	return (
		<Box width="100%">
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<FixedHeader label={props.label}>
					<MuiTimePicker
						value={(context.value as Moment) ?? moment()}
						onChange={(time: Moment | null) => {
							const oldValue = context.value

							context.onChange(time ?? undefined)

							changeEventEmitter(
								cellManager.eventBus,
								{
									oldValue: oldValue,
									newValue: context.value,
								},
								{
									formHost: cellManager,
									elementTag: TimePickerElementTag,
									definitionId: context.cellInstance.definitionId,
									instanceId: context.cellInstance.id,
								},
							)
						}}
						onClose={() => {
							focusEventEmitter(
								cellManager.eventBus,
								{
									focusType: 'FocusLost',
								},
								{
									formHost: cellManager,
									elementTag: TimePickerElementTag,
									definitionId: context.cellInstance.definitionId,
									instanceId: context.cellInstance.id,
								},
							)
						}}
						inputFormat={format}
						ampm={!props.useTwentyFourHourTime}
						renderInput={(params: TextFieldProps) => (
							<TextField fullWidth {...params} />
						)}
					/>
				</FixedHeader>
			</LocalizationProvider>
		</Box>
	)
})

export const TimePickerElementTag = '177442f4-ac0a-46b8-a498-7b020eb6ac3a'

export const TimePickerId = '837a2f1b-40f2-439d-97b0-02112a79e45d'

const TimePickerPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof TimePicker>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.format}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, format: v })
				}
				Editor={TextPropertyConfigurator}
				label="Format"
			/>
			<PropertySchematicConfigurator
				value={properties.useTwentyFourHourTime}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, useTwentyFourHourTime: v })
				}
				Editor={CheckboxPropertyConfigurator}
				label="Use Twenty-Four Hour Time"
			/>
		</>
	)
}

const TimePickerValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof TimePicker>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={(v) => props.onValueChanged(v)}
		Editor={TimePickerPropertyConfigurator}
		label="Default Value"
	/>
)

export const TimePickerDefinition = specifyValueTypeComponent(
	TimePickerId,
	TimePicker,
	TimePickerElementTag,
	'Time Picker',
	FieldType.DateTime,
	{
		configurator: TimePickerPropsEditor,
		defaultProperties: {
			label: 'Label',
			format: 'HH:mm',
			useTwentyFourHourTime: false,
		},
	},
	{
		configurator: TimePickerValueEditor,
		defaultValue: {
			type: 'static',
			value: '01-01-1990',
		},
	},
)
