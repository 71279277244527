import { Save } from '@mui/icons-material'
import { Box, Button, Divider, Paper } from '@mui/material'
import { percent } from 'csx'
import * as escodegen from 'escodegen'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { FormsClient } from '../../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import {
	DetailForm,
	FormPackageAdvancedEvent,
} from '../../../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../../../components/feedback/circular'
import { ProgramContextProvider } from '../../../../../../../../modules/VisualScripting/ProgramContext'
import { VisualScriptEditor } from '../../../../../../../../modules/VisualScripting/VisualScriptEditor'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'
import { FormEventsContextProvider } from './FormEventsContext'

export const AdvancedEventsAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps) => {
		const { formPackage } = useFormPackageContext()
		const formPackageClient = new FormPackageVersionClient(props.packageId)
		const formsClient = new FormsClient(
			props.packageId,
			formPackage.versionNumber,
		)

		const localStore = useLocalObservable(() => ({
			advancedEventPrograms: formPackage.configuration.advancedEvents.map(
				(v) => v.eventProgram,
			),
			forms: undefined as DetailForm[] | undefined,
		}))

		useEffect(() => {
			formsClient.GetAllForms().then(
				action((response) => {
					localStore.forms = response.data
				}),
			)
		}, [formPackage.versionNumber])

		const handleSaveEvents = async () => {
			const updatedAdvancedEvents: FormPackageAdvancedEvent[] = []

			for (const program of localStore.advancedEventPrograms) {
				try {
					const code = escodegen.generate(program, {
						format: {
							compact: false,
						},
					})

					updatedAdvancedEvents.push({
						eventProgram: program,
						eventScript: code,
					})
				} catch (e) {
					console.log(e)

					toastService.displayToast({
						message: 'Invalid entries. Make sure all inputs are filled.',
						area: 'global',
					})

					return
				}
			}

			runInAction(() => {
				formPackage.configuration.advancedEvents = updatedAdvancedEvents
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)

				toastService.displayToast({
					message: 'Advanced events updated',
					area: 'global',
				})
			} catch (e) {
				console.log(e)

				toastService.displayToast({
					message: 'Error updating the advanced events',
					area: 'global',
				})
			}
			return
		}

		return (
			<AdministrationPageContainer
				title="Form Package Advanced Events"
				actions={
					<Box>
						<Button
							variant="contained"
							onClick={handleSaveEvents}
							startIcon={<Save />}
						>
							Save
						</Button>
					</Box>
				}
			>
				<Box
					display="flex"
					flexDirection="column"
					width={percent(100)}
					height={percent(100)}
				>
					<AdministrationPageTabs
						currentTab={8}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					{localStore.forms === undefined ? (
						<FullscreenSpinner />
					) : (
						<Box
							display="flex"
							flexDirection="column"
							component={Paper}
							height={percent(100)}
							gap={0.5}
							marginTop={2}
							marginBottom={2}
							overflow="hidden"
						>
							<ProgramContextProvider
								programs={localStore.advancedEventPrograms}
							>
								<FormEventsContextProvider
									type="FormPackage"
									forms={localStore.forms}
								>
									<VisualScriptEditor />
								</FormEventsContextProvider>
							</ProgramContextProvider>
						</Box>
					)}
				</Box>
			</AdministrationPageContainer>
		)
	},
)
