import { LinkOff } from '@mui/icons-material'
import {
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Tooltip,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { LinkedField } from '../../../../../../../../api/DTOtemp'
import { Field } from './FieldMappingsPage'

type LinkedFieldsSectionProps = {
	linkedFields: Field[]
	onRemoveField: (linkedField: LinkedField) => void
}

export const LinkedFieldsSection = (props: LinkedFieldsSectionProps) => {
	const styles = useStyles()

	return (
		<Fade in unmountOnExit>
			<Paper className={styles.insetSpacing}>
				<div className={styles.topBottomContent}>
					<Typography variant="h6" color="textPrimary">
						Linked Fields
					</Typography>
				</div>

				<Divider />
				<TableContainer className={styles.tableRoot}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Form Name</TableCell>
								<TableCell>Field Name</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.linkedFields.length > 0 &&
								props.linkedFields.map((formField, i) => (
									<TableRow key={i}>
										<TableCell>{formField.formName}</TableCell>
										<TableCell>{formField.displayName}</TableCell>
										<TableCell align="right">
											<Tooltip title="Unlink">
												<IconButton
													onClick={() =>
														props.onRemoveField({
															formId: formField.formId,
															cellDefinitionId: formField.cellDefinition.id,
														})
													}
												>
													<LinkOff />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(3, 0),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	tableRoot: {
		width: percent(100),
		overflow: 'auto',
	},
}))
