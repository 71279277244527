import { observer } from 'mobx-react'
import { ComponentType, Fragment, PropsWithChildren } from 'react'
import { CellType } from '../../../FormHost/Types/CellType'
import FieldType from '../../../FormHost/Types/FieldType'
import { IFormBuilderFormHost } from '../../IFormBuilderFormHost'
import {
	IConcreteFormBuilderCellInstance,
	INullFormBuilderCellInstance,
	ValueLikeFormBuilderCellInstance,
} from '../FormBuilderCellInstance'
import { ElementTreeNode } from '../FormBuilderCellTree'
import {
	ComponentRegistryDictionary,
	fetchComponent,
} from '../GlobalComponentRegistry'
import {
	CellWrapperType,
	ComponentWrapper,
	FormBuilderElementContextProps,
	NullCellNodeType,
} from './contexts/ContextTypes'
import { InputCellContextProvider as InputContextProvider } from './contexts/InputContextProvider'
import { LayoutContextProvider } from './contexts/LayoutCellContextProvider'
import { RepeatContextProvider } from './contexts/RepeatCellContextProvider'
import { VoidContextProvider } from './contexts/VoidCellContextProvider'

export type CellRendererContext = {
	componentRegistry: ComponentRegistryDictionary
	formHost: IFormBuilderFormHost
	CellWrapper?: CellWrapperType
	NullCellNode?: NullCellNodeType
}

export function renderCell(
	cellRendererContext: CellRendererContext,
	node: ElementTreeNode,
): JSX.Element {
	const cell = node.cell

	const { componentRegistry, formHost, NullCellNode } = cellRendererContext

	let { CellWrapper } = cellRendererContext

	CellWrapper ?? (CellWrapper = DefaultCellWrapper)

	/**
	 * null cells exist so that when we add components to our element array
	 * the array is able to maintain its order
	 */
	if (cell.type === CellType.Null) {
		return (
			<Fragment key={cell.id}>
				{NullCellNode && (
					<NullCellNode
						formHost={formHost}
						cellInstance={cell as INullFormBuilderCellInstance}
					/>
				)}
			</Fragment>
		)
	}

	if (cell.fieldType === FieldType.Virtual) return <Fragment />

	if (cell.type === CellType.Virtual)
		throw new Error('cannot render a non-concrete cell')

	console.log(`creating concrete cell with component id ${cell.elementTag}`)
	const componentRegistration = fetchComponent(
		componentRegistry,
		cell.elementTag,
	)

	if (componentRegistration === undefined)
		throw new Error(`no component provided for element ${cell.elementTag}`)

	let ContextProvider:
		| ComponentType<PropsWithChildren<FormBuilderElementContextProps>>
		| undefined

	if (cell.fieldType === FieldType.Object) {
		ContextProvider = LayoutContextProvider
	} else if (cell.fieldType === FieldType.ObjectArray) {
		ContextProvider = RepeatContextProvider
	} else if (cell.fieldType === FieldType.Void) {
		ContextProvider = VoidContextProvider
	} else if (cell.type === CellType.Value) {
		ContextProvider = InputContextProvider
	} else {
		throw new Error(
			`could not create context provider for instance type ${cell.fieldType}`,
		)
	}

	return (
		<ContextProvider
			key={cell.definitionId}
			componentRegistry={componentRegistry}
			node={node}
			formHost={formHost}
			CellWrapper={CellWrapper}
			NullCellNode={NullCellNode}
		>
			<CellWrapper cellInstance={cell} formHost={formHost}>
				<ComponentHost
					targetComponent={componentRegistration.component}
					cell={cell}
				/>
			</CellWrapper>
		</ContextProvider>
	)
}

export function renderCells(
	cellRendererContext: CellRendererContext,
	nodes: ElementTreeNode[],
): JSX.Element[] {
	let { CellWrapper } = cellRendererContext

	CellWrapper ?? (CellWrapper = DefaultCellWrapper)

	const components: JSX.Element[] = []

	for (const node of nodes) {
		components.push(renderCell(cellRendererContext, node))
	}

	return components
}

const DefaultCellWrapper = (props: PropsWithChildren<ComponentWrapper>) => {
	return <>{props.children}</>
}

type ComponentHostProps = {
	targetComponent: ComponentType<Record<string, unknown>>
	cell: IConcreteFormBuilderCellInstance | ValueLikeFormBuilderCellInstance
}

const ComponentHost = observer((props: ComponentHostProps) => {
	return <props.targetComponent {...props.cell.properties} />
})
