import { useEffect, useMemo, useState } from 'react'
import { FullscreenSpinner } from '../../../components/feedback/circular'
import { useRuntimePackageContext } from '../../../pages/PackageHostPage/RuntimePackageContext'
import { CacheDetailForm } from '../../../services/offline/FormPackageStorage'
import { FormBuilderPackageHostWrapper } from '../../FormBuilderCore/FormBuilderPackageHostWrapper'
import { FormBuilderSchema } from '../../FormBuilderCore/Types'
import { FormBuilderCellInstance } from '../../FormBuilderCore/cells/FormBuilderCellInstance'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../../FormBuilderInterop/Runtimes/Standalone'
import { FormIntegrationProps } from '../Types'

export const FormBuilder = (props: FormIntegrationProps) => {
	const [formData, setFormData] = useState<FormBuilderSchema | undefined>()

	const { eventBus } = useRuntimePackageContext()

	const formHost = useMemo(() => {
		if (formData === undefined) return undefined

		// ! this could be a bug

		const castForm = props.form as CacheDetailForm
		if (castForm.cellInstances !== undefined)
			formData.instances = castForm.cellInstances as FormBuilderCellInstance[]

		console.log('form data type = ' + typeof formData)
		console.log(JSON.stringify(formData, null, 2))

		const cellManager = new StandaloneCellManager(
			formData,
			undefined,
			false,
			eventBus,
		)
		cellManager.formId = props.formId

		return cellManager
	}, [formData])

	useEffect(() => {
		const formStream = props.form.stream

		formStream
			.text()
			.then((v) => JSON.parse(v) as FormBuilderSchema)
			.then((v) => setFormData(v))
	}, [])

	useEffect(() => {
		if (formHost === undefined) return

		props.onFormLoaded(new FormBuilderPackageHostWrapper(formHost))
	}, [formHost])

	if (formData === undefined || formHost === undefined)
		return <FullscreenSpinner />

	return <FormBuilderViewer cellManager={formHost} />
}
