import { Program } from 'estree'
import { observer } from 'mobx-react'
import { RendererComponent, ValidNodeTypes } from '../../../Types'
import FieldEventComponent from '../events/FieldEventComponent'
import FormEventComponent from '../events/FormEventComponent'

export const Programs: ValidNodeTypes[] = [
	'FieldEvent',
	'FormEvent',
	'PackageEvent',
]

const ProgramComponent = observer((props: RendererComponent<Program>) => {
	if (props.node.formsInfo?.type === 'FormEvent')
		return <FormEventComponent node={props.node} />

	if (props.node.formsInfo?.type === 'FieldEvent')
		return <FieldEventComponent node={props.node} />

	throw new Error(
		`Program with FormsInfo type ${props.node.formsInfo?.type} is not supported`,
	)
})

export default ProgramComponent
