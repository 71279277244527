import { Component, ReactPortal, useState } from 'react'
import { createPortal } from 'react-dom'
import { SignatureStatus, SignatureValue } from '../../../../api/DTOtemp'
import { SignatureHost } from '../../../../controls/specifics/signatures/SignatureHost'
import { isValueType } from '../../../FormHost/Types/FieldType'
import { HtmlFormManager } from '../HtmlFormHost'
import { IValueHtmlCellInstance } from '../Types/HtmlCellInstance'

type HtmlPortalManagerProps = {
	cellManager: HtmlFormManager
	isAnonymous: boolean
	document: typeof document
}

class HtmlPortalManager extends Component<HtmlPortalManagerProps> {
	private _cachedPortalDict: { [key: string]: ReactPortal } = {}

	private get cellManager(): HtmlFormManager {
		return this.props.cellManager
	}

	constructor(props: HtmlPortalManagerProps) {
		super(props)
	}

	shouldComponentUpdate(): boolean {
		console.log('checking shouldComponentUpdate')
		const cachedPortalKeys = Object.keys(this._cachedPortalDict).sort()
		const newPortalKeys = this.getNodesRequiringPortal().sort()

		if (cachedPortalKeys.length !== newPortalKeys.length) {
			this._cachedPortalDict = {}
			return true
		}

		for (let i = 0; i < cachedPortalKeys.length; i++)
			if (cachedPortalKeys[i] !== newPortalKeys[i]) {
				this._cachedPortalDict = {}
				return true
			}

		return false
	}

	componentDidMount() {
		// unused
	}

	render() {
		console.log('rendering portal manager')
		return (
			<>{this.getNodesRequiringPortal().map((v) => this.RenderPortal(v))}</>
		)
	}

	private getNodesRequiringPortal() {
		const instances = this.cellManager.cellInstances
			.filter(
				(v) =>
					isValueType(v.fieldType) &&
					(v as IValueHtmlCellInstance).elementTag === 'Signature',
			)
			.map((v) => {
				return (v as IValueHtmlCellInstance).id
			})

		return instances
	}

	private RenderPortal(instanceId: string) {
		const instance = this.cellManager.cellInstances.find(
			(v) => v.id === instanceId,
		) as IValueHtmlCellInstance
		if (instance === undefined)
			throw new Error(
				`instance with id ${instanceId} could not be found in portal manager`,
			)

		const targetElement = this.props.document.getElementById(instance.id)
		if (targetElement === null)
			throw new Error(
				`element '${instance.id}' could not be found in the HTML dom`,
			)

		/*
		we have to remove any children that might already exist underneath the
		signature div, since there could be leftovers from our last commit
		 */
		for (const child of targetElement.children) targetElement.removeChild(child)

		const portal = createPortal(
			<SignatureField
				instance={instance}
				isAnonymous={this.props.isAnonymous}
			/>,
			targetElement,
			instance.id,
		)

		this._cachedPortalDict[instance.id] = portal
		return portal
	}
}
export default HtmlPortalManager

// export default observer<HtmlPortalManagerProps>((props) => (
// 	<HtmlPortalManager {...props} />
// ))

type SignatureFieldProps = {
	instance: IValueHtmlCellInstance
	isAnonymous: boolean
}

const SignatureField = ({ instance, isAnonymous }: SignatureFieldProps) => {
	const signatureValue = instance.value as SignatureValue

	const [signatureImage, setSignatureImage] = useState(
		signatureValue.image ?? '',
	)

	return (
		<SignatureHost
			anonymous={isAnonymous}
			selectedSignature={signatureImage}
			onSelectSignature={(signature) => {
				setSignatureImage(signature.signatureData)
				instance.value = {
					emailAddress: signature.email,
					fullName: signature.fullName,
					image: signature.signatureData,
					status: SignatureStatus.Untracked,
				} as SignatureValue
			}}
		/>
	)
}
