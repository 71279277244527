import { OfflineBolt } from '@mui/icons-material'
import {
	Box,
	Button,
	List,
	ListItem,
	Popover,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { EventBus } from '../../../FormBuilderCore/eventBus/EventBus'
import { instancesToObject } from '../../../FormBuilderCore/utilities/instancesToObject'
import FieldType from '../../../FormHost/Types/FieldType'
import {
	EventBusChangeEventProps,
	changeEventConsumer,
	changeEventEmitter,
} from '../../EventBus/BuiltInEvents/ChangeEvent'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../../Runtimes/Standalone'

// IF THERE IS EVER A RECURSIVE DEPENDENCY IN THE FORM BUILDER - IT'S PROBABLY THIS COMPONENT

type ExpressionValue = {
	syntax: string
	expression: unknown | undefined
}

type SyntaxConfiguration = {
	syntaxDisplayName: string
	syntaxType: string
	configuration: FormBuilderSchema
}

type WorkflowExpressionConfiguratorProps = {
	label?: string
	configuredSyntaxes: SyntaxConfiguration[]
}

type ExpressionValued = {
	value: unknown | undefined
	onChange: (value: unknown | undefined) => void
}

export const ExpressionConfigurator = observer(
	(props: WorkflowExpressionConfiguratorProps) => {
		const { cellManager } = useFormBuilderContext()
		const inputCellContext = useInputCellContext<ExpressionValue>()

		const [popoverAnchorElement, setPopoverAnchorElement] =
			useState<HTMLButtonElement | null>(null)

		const handleSyntaxClicked = (
			event: React.MouseEvent<HTMLButtonElement>,
		) => {
			setPopoverAnchorElement(event.currentTarget)
		}

		const handleClose = () => {
			setPopoverAnchorElement(null)
		}

		const onSyntaxPicked = (syntaxName: string) => {
			handleClose()

			if (syntaxName === inputCellContext.value?.syntax) return

			const oldValue = inputCellContext.value

			console.log('Old value!', oldValue)

			inputCellContext.onChange({
				syntax: syntaxName,
				expression:
					syntaxName === 'javascript' ? { code: undefined } : { value: '' },
			})
			emitChange({
				oldValue: oldValue,
				newValue: inputCellContext.value,
			})
		}

		const styles = useStyles()

		console.log(
			'workflow expression configurator got props: ',
			JSON.stringify(props, null, 2),
		)

		if (props.configuredSyntaxes.length === 0)
			throw Error('no syntaxes configured for expression')

		console.log(
			'workflow expression configurator got value: ',
			JSON.stringify(inputCellContext.value, null, 2),
		)

		const emitChange = (v: EventBusChangeEventProps) =>
			changeEventEmitter(cellManager.eventBus, v, {
				formHost: cellManager,
				elementTag: ExpressionConfiguratorElementTag,
				definitionId: inputCellContext.cellInstance.definitionId,
				instanceId: inputCellContext.cellInstance.id,
			})

		const id = useMemo(() => uuidv4(), [])

		if (inputCellContext.value === undefined || inputCellContext.value === null)
			return <div>No value for expression configurator</div>

		const syntaxType = inputCellContext.value.syntax
		const expression = inputCellContext.value.expression

		console.log('configured syntaxes: ', props.configuredSyntaxes)

		const configuredSyntax = props.configuredSyntaxes.find(
			(v) => v.syntaxType === inputCellContext.value?.syntax,
		)

		if (configuredSyntax === undefined)
			throw new Error(
				`no configuration could be found for syntax type ${syntaxType}`,
			)

		return (
			<Box flex={1}>
				<div className={styles.header}>
					{props.label && (
						<Typography variant="subtitle1" color="textPrimary">
							{props.label}
						</Typography>
					)}
					<div>
						<Button
							variant="outlined"
							size="small"
							color="primary"
							endIcon={<OfflineBolt />}
							onClick={handleSyntaxClicked}
							aria-describedby={id}
						>
							{inputCellContext.value?.syntax}
						</Button>
						<Popover
							id={id}
							open={!!popoverAnchorElement}
							anchorEl={popoverAnchorElement}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<div>
								<List>
									{props.configuredSyntaxes.map((v, i) => (
										<ListItem
											key={v.syntaxType}
											button
											disableTouchRipple
											onClick={() => onSyntaxPicked(v.syntaxType)}
										>
											{v.syntaxDisplayName}
										</ListItem>
									))}
								</List>
							</div>
						</Popover>
					</div>
				</div>

				<SyntaxComponentPicker
					syntaxType={syntaxType}
					configuration={configuredSyntax.configuration}
					syntaxDisplayName={configuredSyntax.syntaxDisplayName}
					value={expression}
					onChange={(v) =>
						inputCellContext.onChange({
							syntax: syntaxType,
							expression: v,
						})
					}
				/>
			</Box>
		)
	},
)

const SyntaxComponentPicker = observer(
	(props: SyntaxConfiguration & ExpressionValued) => {
		return (
			<StaticConfigurator
				internalForm={props.configuration}
				value={props.value}
				onChange={props.onChange}
			/>
		)
	},
)

type StaticConfiguratorProps = {
	internalForm: FormBuilderSchema
} & ExpressionValued

export const StaticConfigurator = (props: StaticConfiguratorProps) => {
	const context = useFormBuilderContext()

	console.log(
		'receiving definitions: ',
		JSON.stringify(props.internalForm.definitions, null, 2),
	)
	console.log(
		'receiving instances: ',
		JSON.stringify(props.internalForm.instances, null, 2),
	)
	console.log('receiving values: ', JSON.stringify(props.value))

	const cellManager = useMemo(
		() =>
			new StandaloneCellManager(
				props.internalForm,
				props.value as object | undefined,
				context.cellManager.isReadonly,
			),
		[props.internalForm],
	)

	// TODO - initializeStaticFields --

	// TODO this should be the event bus from context
	const eventBus = new EventBus()

	changeEventConsumer(
		eventBus,
		() => Promise.resolve(true),
		() => {
			console.log('captured change event')

			const newPropsObject = instancesToObject(
				cellManager.cellDefinitions,
				cellManager.cellInstances,
			)

			props.onChange(newPropsObject)
			return Promise.resolve()
		},
	)

	return <FormBuilderViewer cellManager={cellManager} />
}

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',

		marginBottom: theme.spacing(1),
	},

	typePickerSection: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
	},
}))

export const ExpressionConfiguratorElementTag =
	'80492104-5b0a-4a60-9f18-647a6c309cfc'

export const ExpressionConfiguratorId = 'c8bbea1d-5555-4e45-bca2-da85dcb34c83'

export const ExpressionEditorConfiguratorDefinition = specifyInternalComponent(
	ExpressionConfiguratorId,
	ExpressionConfigurator,
	ExpressionConfiguratorElementTag,
	FieldType.ReferenceType,
	{
		label: undefined,
		configuredSyntaxes: [],
	},
	{},
)
