import { AxiosResponse } from 'axios'
import SecureClient from '../SecureClient'
import {
	AuthenticationProviderInstance,
	AuthenticationProviderInstanceMetadata,
	AuthenticationProviderLoginModel,
	AuthenticationProviderSettings,
	BaseAuthenticationProviderInstanceModel
} from './DTO'

export default class AuthenticationProviderInstancesClient extends SecureClient {
	/**
	 * get all authentication provider instances for an organization
	 * @returns 
	 */
	public getInstances = (): Promise<AxiosResponse<AuthenticationProviderInstance[]>> => {
		return this._api.get<AuthenticationProviderInstance[]>(
			`AuthenticationProviderInstances`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * get one authentication provider instance
	 * @param instanceId 
	 * @returns 
	 */
	public getInstance = (
		instanceId: string,
	): Promise<AxiosResponse<AuthenticationProviderInstance>> => {
		return this._api.get<AuthenticationProviderInstance>(
			`AuthenticationProviderInstances/${instanceId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * get the metadata for an auth provider instance - display name,
	 * description, auth provider type, email domain, and id
	 * @returns 
	 */
	public getInstanceMetadata = (
	): Promise<AxiosResponse<AuthenticationProviderInstanceMetadata[]>> => {
		return this._api.get<AuthenticationProviderInstanceMetadata[]>(
			`AuthenticationProviderInstances/Metadata`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * create an auth provider instance
	 * @param model 
	 * @returns 
	 */
	public configureInstance = (
		model: BaseAuthenticationProviderInstanceModel,
	): Promise<AxiosResponse<AuthenticationProviderInstance>> => {
		return this._api.post<AuthenticationProviderInstance>(
			`AuthenticationProviderInstances`,
			model,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * update an existing auth provider instance
	 * @param instanceId 
	 * @param model 
	 * @returns 
	 */
	public updateInstance = (
		instanceId: string,
		model: BaseAuthenticationProviderInstanceModel,
	): Promise<AxiosResponse<AuthenticationProviderInstance>> => {
		return this._api.put<AuthenticationProviderInstance>(
			`AuthenticationProviderInstances/${instanceId}`,
			model,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	/**
	 * delete an auth provider instance
	 * @param instanceId 
	 * @returns 
	 */
	public deleteInstance = (
		instanceId: string,
	): Promise<AxiosResponse> => {
		return this._api.delete(
			`AuthenticationProviderInstances/${instanceId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	public getOrganizationAuthProviderSettings = (): Promise<AxiosResponse<AuthenticationProviderSettings>> => {
		return this._api.get('AuthenticationProviderInstances/Settings', {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}

	public updateOrganizationAuthProviderSettings = (settings: AuthenticationProviderSettings): Promise<AxiosResponse> => {
		return this._api.put('AuthenticationProviderInstances/Settings', settings, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	} 

	/**
	 * get the url where we will redirect to have the user login
	 * with the external authentication provider
	 * @param instanceId
	 * @returns the redirect url where we will get the token
	 */
	public getAuthenticationUrl = (
		instanceId: string,
		redirectUri: string,
		originalRoute?: string
	): Promise<AxiosResponse<string>> => {
		return this._api.get<string>(
			`AuthenticationProviderInstances/${instanceId}/AuthenticationUrl`,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
				params: {
					redirectUri,
					originalRoute
				},
			},
		)
	}

	/**
	 * get the access token from the auth provider to log them in with FIM
	 * by giving them our access token
	 * @param instanceId 
	 * @param loginModel the authorization code and redirect uri from the
	 * auth provider, used to get their access token in the backend
	 * @returns 
	 */
	public login = (
		instanceId: string,
		loginModel: AuthenticationProviderLoginModel,
	): Promise<AxiosResponse<string>> => {
		return this._api.post<string>(
			`AuthenticationProviderInstances/${instanceId}/Login`,
			loginModel,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	public getPostLoginRedirectUrl = (instanceId: string, responseUrl: string): Promise<AxiosResponse<string>> => {
		return this._api.get<string>(`AuthenticationProviderInstances/${instanceId}/PostLoginRedirectUrl`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader()
			},
			params: {
				responseUrl
			}
		})
	}
}
