import { Check, PriorityHigh, Publish } from '@mui/icons-material'
import {
	Box,
	Button,
	Card,
	Chip,
	Dialog,
	Divider,
	Fab,
	Theme,
	Tooltip,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { action, autorun, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { FormPackageAttachmentType } from '../../../api/DTOtemp'
import { OverflowTooltip } from '../../../components/tooltips/OverflowTooltip'
import { toastService } from '../../../services/notifications/ToastService'
import pluralizeString from '../../../utils/PluralizeString'
import { useAttachmentsApi } from './AttachmentsApi'

type AttachFilesDialogProps = {
	packageAttachmentTypes: FormPackageAttachmentType[] // configured by user (driver's license, birth certificate)
	attachments?: TrackedResource[]
	onAttachFiles: (attachments: TrackedResource[]) => void
	onClose: () => void
}

export enum ResourceType {
	Form = 'Form',
	Attachment = 'Attachment',
	FormImage = 'FormImage',
	SignatureImage = 'SignatureImage',
}

export enum PersistenceStatus {
	New,
	Updated,
	Persisted,
}

export type TrackedResource = {
	type: ResourceType
	relationalId?: string
	file: File
	resourceId: string
	persistenceStatus: PersistenceStatus
	packageAttachmentType: string
}

export const AttachFilesDialog = observer((props: AttachFilesDialogProps) => {
	const localStore = useLocalObservable(() => {
		return {
			attachments: [...(props.attachments ?? [])],

			selectedAttachmentType: props.packageAttachmentTypes[0],

			accordionExpanded: true,

			getDropzoneDisabled() {
				if (this.selectedAttachmentType === undefined) return false
				return (
					this.selectedAttachmentType.maxAttachmentCount !== undefined &&
					getFilesForAttachmentType(this.selectedAttachmentType.name).length >=
						this.selectedAttachmentType.maxAttachmentCount
				)
			},
		}
	})

	const attachmentsApi = useAttachmentsApi()

	const currentRequest =
		attachmentsApi.attachmentRequests.length > 0
			? attachmentsApi.attachmentRequests[0]
			: undefined

	const targetAttachmentType = currentRequest?.attachmentType
		? props.packageAttachmentTypes.find(
				(v) => v.name === currentRequest.attachmentType,
		  )
		: undefined

	if (
		targetAttachmentType !== undefined &&
		targetAttachmentType.name !== localStore.selectedAttachmentType.name
	) {
		runInAction(() => {
			localStore.selectedAttachmentType = targetAttachmentType
		})
	}

	const styles = useStyles()

	useEffect(() => {
		const disposer = autorun(() => {
			localStore.attachments = [...(props.attachments ?? [])]
		})

		return () => disposer()
	}, [props.attachments])

	// check to make sure we have all the required attachment types, used to disable submit
	const requiredAttachmentsAdded = useMemo(() => {
		for (const attachmentType of props.packageAttachmentTypes
			.filter((v) => v.required)
			.map((v) => v.name)) {
			if (
				!localStore.attachments.some(
					(v) => v.packageAttachmentType === attachmentType,
				)
			)
				return false
		}

		return true
	}, [localStore.attachments.length])

	const handleDrop = useCallback(
		action((attachmentType: string, acceptedFiles: File[]) => {
			// check for duplicates in files already dropped & files already saved to form package instance
			for (const file of acceptedFiles)
				if (
					localStore.attachments
						.map((v) => v.file)
						.filter((v) => v.name === file.name).length !== 0
				) {
					toastService.displayToast({
						message: `cannot add duplicate file '${file.name}'`,
						area: 'formPackage',
					})
					continue
				} else {
					localStore.attachments.push({
						type: ResourceType.Attachment,
						file,
						resourceId: uuidv4(),
						persistenceStatus: PersistenceStatus.New,
						packageAttachmentType: attachmentType,
					})
					localStore.selectedAttachmentType = props.packageAttachmentTypes[0]
				}
		}),
		[],
	)

	const handleDelete = action((file: TrackedResource) => {
		const index = localStore.attachments.indexOf(file)
		if (index < 0) return

		localStore.attachments.splice(index, 1)
	})

	// get added file names for specified attachment type
	const getFilesForAttachmentType = useCallback(
		(attachmentTypeName: string) => {
			return localStore.attachments
				.filter((v) => v.packageAttachmentType === attachmentTypeName)
				.map((v) => v.file.name)
		},
		[localStore.attachments.length],
	)

	const handleAttachFiles = () => {
		// TODO if nothing changes then don't do this stuff
		currentRequest?.resolver(localStore.attachments)
		props.onAttachFiles(localStore.attachments)
		toastService.displayToast({
			message: 'Files attached',
			delay: 3,
			area: 'formPackage',
		})
		props.onClose()
	}

	return (
		<Dialog
			open={
				attachmentsApi.attachmentRequests.length > 0 &&
				attachmentsApi.attachmentRequests[0].active
			}
			maxWidth="md"
			fullWidth
			PaperProps={{
				sx: {
					height: { xs: percent(100), sm: '60vh' },
				},
			}}
			className={styles.dialog}
		>
			<Box
				display="flex"
				flexDirection="column"
				height="100%"
				width="100%"
				overflow="hidden"
			>
				<Typography
					sx={{ typography: { xs: 'h6', sm: 'h5' } }}
					flex="0"
					padding={2}
				>
					Attach Files
				</Typography>
				<Box
					flex="1"
					display="flex"
					paddingX={2}
					gap={2}
					overflow="hidden"
					flexDirection={{ xs: 'column', sm: 'row' }}
				>
					{/* only show the left hand attachment selector if the selected
					attachment type is undefined */}
					{currentRequest !== undefined &&
						currentRequest.attachmentType === undefined && (
							<>
								<Box overflow="auto" flex="2" paddingRight={3}>
									<div className={styles.attachmentTypes}>
										{props.packageAttachmentTypes.map((attachmentType) => (
											<AttachmentTypeCard
												key={attachmentType.name}
												attachmentType={attachmentType}
												selected={
													localStore.selectedAttachmentType === attachmentType
												}
												onUpload={(attachmentTypeName, files) =>
													handleDrop(attachmentTypeName, files)
												}
												onSelected={action(
													() =>
														(localStore.selectedAttachmentType =
															attachmentType),
												)}
												requirementMet={
													!attachmentType.required
														? undefined
														: getFilesForAttachmentType(attachmentType.name)
																.length > 0
												}
											/>
										))}
									</div>
								</Box>
								<Divider orientation="vertical" flexItem />
							</>
						)}

					<div className={styles.fileSection}>
						<AttachmentDropZone
							key={localStore.selectedAttachmentType.name}
							attachmentType={localStore.selectedAttachmentType}
							dropzoneDisabled={localStore.getDropzoneDisabled()}
							handleDrop={(files) =>
								handleDrop(localStore.selectedAttachmentType.name, files)
							}
						/>
						<Box flex="1" overflow="auto">
							<div className={styles.fileChips}>
								{localStore.attachments
									.filter(
										(v) =>
											v.packageAttachmentType ===
											localStore.selectedAttachmentType.name,
									)
									.map((attachment) => (
										<Chip
											key={attachment.file.name}
											label={attachment.file.name}
											onDelete={() => handleDelete(attachment)}
										/>
									))}
							</div>
						</Box>
					</div>
				</Box>
			</Box>
			<Box display="flex" flex="0" justifyContent="flex-end" padding={2}>
				<Button
					onClick={action(() => {
						props.onClose()
						currentRequest?.resolver([])
						localStore.attachments = [...(props.attachments ?? [])]
					})}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleAttachFiles}
					disabled={!requiredAttachmentsAdded}
				>
					Confirm Attachments
				</Button>
			</Box>
		</Dialog>
	)
})

type AttachmentTypeCardProps = {
	attachmentType: FormPackageAttachmentType
	selected: boolean
	requirementMet?: boolean // will be undefined if the attachment type is not required
	onSelected: () => void
	onUpload: (attachmentTypeName: string, files: File[]) => void
}

const AttachmentTypeCard = ({
	attachmentType,
	selected,
	requirementMet,
	onSelected,
	onUpload,
}: AttachmentTypeCardProps) => {
	const styles = useStyles()

	const nameRef = useRef<HTMLSpanElement>(null)

	return (
		<Box
			component={Card}
			key={attachmentType.name}
			className={clsx(
				styles.attachmentTypeCard,
				selected && styles.selectedCard,
			)}
			variant="outlined"
			onClick={onSelected}
		>
			<Box flex="1" overflow="hidden">
				<OverflowTooltip tooltipText={attachmentType.name} childRef={nameRef}>
					<Typography
						flex="1"
						variant="subtitle2"
						className={styles.attachmentTypeName}
						textOverflow="ellipsis"
						noWrap
						ref={nameRef}
					>
						{attachmentType.name}
					</Typography>
				</OverflowTooltip>
			</Box>
			{requirementMet !== undefined &&
				(requirementMet ? (
					<Tooltip title="Requirements met">
						<Check color="success" />
					</Tooltip>
				) : (
					<Tooltip title="Attachments are still required">
						<PriorityHigh color="error" />
					</Tooltip>
				))}
			<Fab
				size="small"
				component="label"
				color="primary"
				className={styles.uploadButton}
			>
				<input
					type="file"
					hidden
					multiple={
						attachmentType.maxAttachmentCount === undefined ||
						attachmentType.maxAttachmentCount > 1
					}
					onChange={(evt) => {
						if (evt.target.files === null) return

						onUpload(attachmentType.name, Array.from(evt.target.files))
					}}
				/>
				<Publish />
			</Fab>
		</Box>
	)
}

type AttachmentDropZoneProps = {
	attachmentType: FormPackageAttachmentType
	dropzoneDisabled: boolean
	handleDrop: (files: File[]) => void
}

const AttachmentDropZone = ({
	attachmentType,
	dropzoneDisabled,
	handleDrop,
}: AttachmentDropZoneProps) => {
	const styles = useStyles()

	return (
		<Dropzone
			key={attachmentType.name}
			multiple={true}
			onDrop={(files) => handleDrop(files)}
			maxFiles={attachmentType.maxAttachmentCount}
			disabled={dropzoneDisabled}
			accept={attachmentType.acceptedFileTypes}
		>
			{({ getRootProps, getInputProps }: DropzoneState) => (
				<Box
					component={'section'}
					flex="1"
					className={clsx(
						styles.verticalSpacing,
						dropzoneDisabled
							? styles.disabledDragAndDrop
							: styles.enabledDragAndDrop,
					)}
				>
					<Box height={percent(100)} {...getRootProps()}>
						<input {...getInputProps()} />
						<div className={styles.dragAndDropBody}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
								height="100%"
							>
								<Box overflow="auto" width={percent(100)} textAlign="center">
									<Box
										flex="1"
										overflow="hidden"
										display="flex"
										flexDirection="column"
									>
										<Typography
											sx={{ typography: { xs: 'subtitle1', sm: 'h6' } }}
										>
											{attachmentType.name}
										</Typography>
										{attachmentType.description !== '' && (
											<Typography
												sx={{
													typography: { xs: 'caption', sm: 'body2' },
												}}
											>
												{attachmentType.description}
											</Typography>
										)}
									</Box>
								</Box>
								<Box flex={1} height={percent(100)} />
								<Box
									display="flex"
									flexDirection="column"
									alignItems="center"
									justifyContent="center"
									textAlign="center"
								>
									<Typography
										sx={{
											typography: { xs: 'caption', sm: 'subtitle2' },
										}}
									>
										{attachmentType.maxAttachmentCount !== undefined &&
										dropzoneDisabled
											? `Maximum of ${pluralizeString(
													attachmentType.maxAttachmentCount,
													'file',
											  )} met, delete a file to add another`
											: 'Drag and drop files here, or click to select a file'}
									</Typography>
									{dropzoneDisabled ? <Check color="success" /> : <Publish />}
								</Box>
							</Box>
						</div>
					</Box>
				</Box>
			)}
		</Dropzone>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	dialog: {
		overflow: 'hidden',
	},

	attachmentTypes: {
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},

	fileSection: {
		justifyContent: 'space-between',
		flex: '5',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
	},

	verticalSpacing: {
		height: '50%',
		flex: 3,
	},

	dragAndDropBody: {
		height: percent(100),
		width: percent(100),
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',

		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: theme.shape.borderRadius,
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: theme.palette.text.secondary,

		overflowWrap: 'break-word',

		padding: theme.spacing(2),

		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(5),
		},
	},

	enabledDragAndDrop: {
		cursor: 'pointer',
	},

	disabledDragAndDrop: {
		opacity: percent(60),
	},

	fileChips: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
	},

	attachmentTypeCard: {
		padding: theme.spacing(0, 0, 0, 3),
		borderRadius: theme.spacing(6),
		cursor: 'pointer',

		alignItems: 'center',

		display: 'flex',
	},

	attachmentTypeName: {
		flex: '1',
	},

	uploadButton: {
		alignSelf: 'flex-end',
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		margin: theme.spacing(0, 0, 0, 0.5),
	},

	selectedCard: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
	},

	accordion: {
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		width: percent(100),
	},

	accordionSummary: {
		flexDirection: 'row-reverse',
		gap: theme.spacing(2),
		backgroundColor: theme.palette.background.default,

		borderStyle: 'solid',
		borderColor: theme.palette.divider,
		borderWidth: '1px',

		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
	},

	accordionDetails: {
		borderTopStyle: 'solid',
		borderTopColor: theme.palette.divider,
		borderTopWidth: '1px',

		overflowY: 'auto',
	},

	uploadedAttachments: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(0, 0, 2, 0),
	},
}))
