import {
	ArrowForward,
	FileDownload,
	Upload,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material'
import {
	Box,
	Button,
	Divider,
	Fade,
	IconButton,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import {
	Route,
	Switch,
	useHistory,
	useParams,
	useRouteMatch,
} from 'react-router'
import { HashRouter } from 'react-router-dom'
import { AssignmentType } from '../../../../api/clients/workItems/DTOs'
import {
	Workflow,
	WorkflowDefinition,
	WorkflowDefinitionBase,
} from '../../../../api/clients/workflows/DTOs'
import WorkflowsClient from '../../../../api/clients/workflows/WorkflowsClient'
import UserChip from '../../../../components/chips/UserChip'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import DescriptiveSwitch from '../../../../components/inputs/DescriptiveSwitch'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { UploadWorkflowDialog } from './UploadWorkflowDialog'
import { WorkflowHistoryDashboard } from './WorkflowHistory/WorkflowHistoryDashboard'

import { History } from 'react-router/node_modules/@types/history/index'
import IconButtonLink from '../../../../components/links/IconButtonLink'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'

export const WorkflowDefinitionsDashboard: React.FC = observer(() => {
	const styles = useStyles()

	const [uploadWorkflowOpen, setUploadWorkflowOpen] = useState(false)

	const [publishedWorkflows, setPublishedWorkflows] =
		useState<WorkflowDefinition[]>()
	const [workflow, setWorkflow] = useState<Workflow>()
	const [workflowDefinitions, setWorkflowDefinitions] = useState<
		WorkflowDefinitionBase[]
	>([])

	const [selectedIndex, setSelectedIndex] = useState(0)

	const params = useParams<{ workflowId: string }>()

	const workflowId = parseInt(params.workflowId)
	if (isNaN(workflowId)) throw new Error('invalid workflow id')

	const client = new WorkflowsClient()
	const history = useHistory()

	const getWorkflow = async () => {
		const { data: workflowData } = await client.getWorkflow(workflowId)
		setWorkflow(workflowData)

		const { data: definitions } = await client.getVersionsOfWorkflow(workflowId)
		setWorkflowDefinitions(definitions)

		const { data: workflows } = await client.getAllWorkflows()
		setPublishedWorkflows(workflows)
	}

	const handleUploadWorkflowClicked = () => {
		setUploadWorkflowOpen(true)
	}

	const handleUploadWorkflowClosed = () => {
		setUploadWorkflowOpen(false)
	}

	const handleUploadWorkflow = (wfData: WorkflowDefinition) => {
		const result = client.uploadWorkflowDefinition(wfData, workflow?.id)

		result.then((v) => {
			history.push(`/_workflows/${v.data.workflowId}`)
		})

		setUploadWorkflowOpen(false)
	}

	const handleViewWorkflowClicked = () => {
		history.push(`/_workflows/${workflow?.id}`)
	}

	useEffect(() => {
		getWorkflow()
	}, [workflowId])

	return (
		<>
			{workflow === undefined ? (
				<FullscreenSpinner />
			) : (
				<AdministrationPageContainer
					title={workflow.name}
					actions={
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							gap={2}
						>
							<Button
								variant="outlined"
								color="primary"
								onClick={handleUploadWorkflowClicked}
								startIcon={<Upload />}
							>
								Upload Workflow
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleViewWorkflowClicked}
								startIcon={<Visibility />}
							>
								View Workflow
							</Button>
						</Box>
					}
				>
					<div className={styles.root}>
						{/* <BrowserRouter basename={}> */}
						<WorkflowDefinitionContent
							originalHistory={history}
							rootPath={history.location.pathname}
							workflow={workflow}
							definitions={workflowDefinitions}
						/>
						{/* </BrowserRouter> */}
					</div>
					<UploadWorkflowDialog
						open={uploadWorkflowOpen}
						onClose={handleUploadWorkflowClosed}
						existingWorkflowNames={
							// remove the name of the current workflow so we can avoid errors
							// why yes, it is hacky
							publishedWorkflows
								?.filter((v) => workflow.name !== v.workflow.name)
								.map((v) => v.workflow.name) ?? []
						}
						onCreateWorkflow={handleUploadWorkflow}
					></UploadWorkflowDialog>
				</AdministrationPageContainer>
			)}
		</>
	)
})

const WorkflowDefinitionContentHost = (props: React.PropsWithChildren) => {
	return <HashRouter>{props.children}</HashRouter>
}

const WorkflowDefinitionContent = ({
	originalHistory,
	rootPath,
	workflow,
	definitions,
}: {
	originalHistory: History<unknown>
	rootPath: string
	workflow: Workflow
	definitions: WorkflowDefinitionBase[]
}) => {
	const history = useHistory() // hash history :)
	const { path } = useRouteMatch()

	console.log('pathname: ', rootPath)
	const [location, setLocation] = useState(() => {
		const arrayPieces = history.location.pathname.split('/')
		const lastItem = arrayPieces[arrayPieces.length - 1]

		console.log('last item: ', lastItem)

		if (!['settings', 'versions', 'history'].find((v) => v === lastItem)) {
			const historyLocation =
				history.location.pathname +
				(history.location.pathname[history.location.pathname.length - 1] === '/'
					? 'settings'
					: '/settings')
			console.log('pushing location', historyLocation)
			history.push(historyLocation)
			return 'settings'
		}

		return lastItem
	})

	console.log('location: ', location)

	const handleTabIndexChanged = useCallback(
		(event: React.SyntheticEvent, newValue: string) => {
			console.log('pushing to page ', newValue)
			history.push(newValue)
			setLocation(newValue)
		},
		[],
	)

	console.log('????', makeChildRoute('settings', history.location.pathname))

	return (
		<>
			<Tabs
				textColor="primary"
				indicatorColor="primary"
				value={location}
				onChange={handleTabIndexChanged}
			>
				<Tab key="Settings" label="Settings" value="settings" />
				<Tab key="Versions" label="Versions" value="versions" />
				<Tab key="History" label="History" value="history" />
			</Tabs>
			<Divider />
			<Switch>
				<Route path={makeChildRoute('settings', path)}>
					<WorkflowSettings history={originalHistory} workflow={workflow} />
				</Route>
				<Route path={makeChildRoute('versions', path)}>
					<WorkflowDefinitionsTable
						originalHistory={originalHistory}
						workflowId={workflow.id}
						definitions={definitions}
					/>
				</Route>
				<Route path={makeChildRoute('history', path)}>
					<WorkflowHistoryDashboard
						originalHistory={originalHistory}
						workflowId={workflow.id}
						definitions={definitions}
					/>
				</Route>
			</Switch>
		</>
	)
}

type WorkflowDefinitionsTableProps = {
	workflowId: number
	definitions: WorkflowDefinitionBase[]
	originalHistory: History<unknown>
}

export const WorkflowDefinitionsTable = observer(
	(props: WorkflowDefinitionsTableProps) => {
		const styles = useStyles()

		const createRoute = (definition: WorkflowDefinitionBase) => {
			return `/_workflows/${props.workflowId}` + definition.isPublished
				? ''
				: `/_versions/${definition.id}`
		}

		const handleDownloadVersion = (definition: WorkflowDefinitionBase) => {
			const api = new WorkflowsClient()

			console.log('downloading version')

			const response = api.getDefinition(definition.workflowId, definition.id)

			response.then((v) => {
				const result = JSON.stringify(v.data)
				const buffer = window.btoa(result)

				const anchor = document.createElement('a', {})
				anchor.href = 'data:application/json;base64,' + buffer
				anchor.target = '_blank'
				anchor.download = `workflow-${definition.workflowId}_${definition.version}.fwf`

				anchor.click()
				anchor.remove()
			})
		}

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Version Number</TableCell>
								<TableCell>Last Updated</TableCell>
								<TableCell>Last Updated By</TableCell>
								<TableCell>Active</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.definitions
								.sort((a, b) => {
									// show active version first
									if (a.isPublished > b.isPublished) return -1
									if (b.isPublished < a.isPublished) return 1

									// order by descending
									return b.version - a.version
								})
								.map((definition) => (
									<TableRow key={definition.id}>
										<TableCell>{definition.version}</TableCell>
										<TableCell>
											{new Date(definition.lastModifiedDate).toLocaleString()}{' '}
											UTC
										</TableCell>
										<TableCell>
											<UserChip
												assignmentType={AssignmentType.User}
												id={definition.lastModifiedBy}
											/>
										</TableCell>
										<TableCell>
											{definition.isPublished ? (
												<Visibility />
											) : (
												<VisibilityOff color="disabled" />
											)}
										</TableCell>
										<TableCell align="right">
											<IconButton
												onClick={() => handleDownloadVersion(definition)}
											>
												<FileDownload />
											</IconButton>
											<IconButtonLink to={createRoute(definition)}>
												<ArrowForward />
											</IconButtonLink>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

type WorkflowSettingsProps = {
	history: History<unknown>
	workflow: Workflow
}

export const WorkflowSettings: React.FC<WorkflowSettingsProps> = observer(
	({ workflow }: WorkflowSettingsProps) => {
		const styles = useStyles()

		const client = new WorkflowsClient()

		const formik = useFormik({
			initialValues: {
				name: workflow.name,
				enabled: workflow.enabled,
			},

			onSubmit: (values) => {
				workflow.name = values.name
				workflow.enabled = values.enabled
				client
					.updateWorkflow(workflow.id, {
						workflowName: values.name,
						enabled: values.enabled,
					})
					.then(() => {
						toastService.displayToast({
							message: 'Workflow updated',
							area: 'global',
						})
					})
					.catch(() => {
						toastService.displayToast({
							message: 'Error updating workflow',
							area: 'global',
						})
					})
			},
		})

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Typography
						className={styles.topBottomContent}
						variant="h6"
						color="textPrimary"
					>
						Settings
					</Typography>
					<Divider />

					<div className={styles.formSettingsRoot}>
						<div className={styles.formElement}>
							<TextField
								name="name"
								id="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								label="Name"
								fullWidth
								autoComplete="off"
							/>
						</div>
					</div>
					<DescriptiveSwitch
						name="enabled"
						id="enabled"
						checked={formik.values.enabled}
						onChange={formik.handleChange}
						containerClassName={styles.formElement}
						color="primary"
						headerLabel="Workflow Enabled"
						bodyLabel="Allow this workflow to run when executed"
					/>
					<div>
						<Divider />
						<div
							className={clsx(styles.topBottomContent, styles.bottomFlexbox)}
						>
							<Button
								color="primary"
								variant="contained"
								className={styles.noTextTransform}
								onClick={formik.submitForm}
								disabled={
									!formik.dirty || !formik.isValid || formik.isSubmitting
								}
							>
								Save Changes
							</Button>
						</div>
					</div>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: percent(100),
	},

	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		overflow: 'auto',
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},
}))
