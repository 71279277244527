import { FormType } from "../../../api/DTOtemp"
import { EventBus } from "../../FormBuilderCore/eventBus/EventBus"
import { IUnifiedFormHost } from "../../FormHost/FormHost/IUnifiedFormHost"
import { UnifiedCellDefinition } from "../../FormHost/Types/UnifiedCellDefinition"
import { UnifiedCellInstance } from "../../FormHost/Types/UnifiedCellInstance"
import { createCellDefinitionProxy, createValueCellInstanceProxy } from "../../FormHost/Utilities/CellProxyUtilities"
import { adapters, PdfFormManager } from "./PdfFormHost"

export interface IPdfPackageHostWrapper extends IUnifiedFormHost {
	readonly formType: FormType.Pdf
	readonly formHost: PdfFormManager
}

export class PdfPackageHostWrapper implements IUnifiedFormHost {
	private readonly _formDocument: Document

	readonly formHost: PdfFormManager

	readonly formId: number
	readonly formType: FormType.Pdf = FormType.Pdf

	eventBus: EventBus
	dispose: () => void

	cellDefinitions: UnifiedCellDefinition[]
	cellInstances: UnifiedCellInstance[]

	get formContainerElement() {
		return this._formDocument.getElementById(`form-${this.formId}`)
	}

	constructor(formHost: PdfFormManager, formDocument: Document) {
		this.formHost = formHost
		this._formDocument = formDocument

		this.formId = formHost.formId
		this.eventBus = formHost.eventBus
		this.dispose = formHost.dispose

		this.cellDefinitions = []
		this.cellInstances = []

		// proxy the html cell definitions/instances so they can be used in the package host
		this.proxyCellDefinitions()
		this.proxyCellInstances()
	}

	private proxyCellDefinitions() {
		for (const definition of this.formHost.cellDefinitions) {
			this.cellDefinitions.push(
				createCellDefinitionProxy(definition, propertiesProxy),
			)
		}
	}

	private proxyCellInstances() {
		for (const instance of this.formHost.cellInstances) {
			const valueGetter = () => {
				return instance.value
			}

			const valueSetter = (v: unknown) => {
				instance.value = v

				for (const adapter of adapters) {
					if (!adapter.canRead(instance, this._formDocument)) continue

					adapter.write(instance, this._formDocument, v)

					// we don't want to check the other adapters
					break
				}
			}

			const unifiedCellInstance = createValueCellInstanceProxy(
				instance,
				propertiesProxy,
				valueGetter,
				valueSetter,
			)
			this.cellInstances.push(unifiedCellInstance)
		}
	}
}

const propertiesProxy = (properties: Record<string, unknown>) =>
	new Proxy(properties, handler)

const handler: ProxyHandler<Record<string, unknown>> = {
	get: function (properties: Record<string, unknown>, propertyName: string) {
		return properties[propertyName]
	},
	set: function (
		properties: Record<string, unknown>,
		propertyName: string,
		value: unknown,
	) {
		properties[propertyName] = value
		return true
	},
}
