import {
	Backdrop,
	Button,
	Card,
	Dialog,
	Divider,
	Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import {
	LicenseResponse,
	LicensingClient,
} from '../../../../api/clients/identity/LicensingClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { TextInput } from '../../../../modules/FormBuilderInterop/Components/Inputs/TextInput'
import { useModals } from '../../../../services/notifications/ModalService'
import { toastService } from '../../../../services/notifications/ToastService'
import licenseInstance from '../../../../services/session/LicensingService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { updateBreadcrumbs } from '../Breadcrumbs'

type StatusType = 'NotLoaded' | 'Loading' | 'Loaded' | 'Failed'

const LicensingPage = observer(() => {
	const modalService = useModals()

	const localStore = useLocalObservable(() => ({
		status: 'NotLoaded' as StatusType,
		license: undefined as LicenseResponse | undefined,
		newLicenseString: '' as string,
	}))

	updateBreadcrumbs('/_administration/_licensing', 'Licensing')

	const licensingClient = useMemo(() => new LicensingClient(), [])

	useEffect(() => {
		;(async () => {
			try {
				runInAction(() => (localStore.status = 'Loading'))
				const license = await licensingClient.GetLicense()
				localStore.license = license.data
				runInAction(() => (localStore.status = 'Loaded'))
			} catch (error) {
				console.error(error)
				runInAction(() => (localStore.status = 'Failed'))

				toastService.displayToast({
					area: 'global',
					message: 'Failed to load license',
				})
			}
		})()
	}, [])

	const updateLicense = async () => {
		if (localStore.newLicenseString === '') return

		const result = await modalService.showForm((props) => (
			<Box
				component={Dialog}
				display="flex"
				flexDirection={'column'}
				open={true}
				onClose={() => props.close({ closeResult: 'cancel' })}
			>
				<Box component={Typography} padding={2} variant="h6">
					Update License
				</Box>
				<Divider />
				<Box component={Typography} padding={2}>
					Are you sure you want to update the license? This action cannot be
					undone
				</Box>
				<Box
					display="flex"
					flexDirection={'row-reverse'}
					gap={1}
					paddingBottom={1}
					paddingRight={1}
				>
					<Button
						variant="contained"
						onClick={() => props.close({ closeResult: 'okay' })}
					>
						Update
					</Button>
					<Button onClick={() => props.close({ closeResult: 'cancel' })}>
						Cancel
					</Button>
				</Box>
			</Box>
		))

		if (result.closeResult === 'cancel') return

		const previousStatus = localStore.status

		runInAction(() => (localStore.status = 'Loading'))

		try {
			const newLicense = await licensingClient.SetLicense(
				localStore.newLicenseString,
			)
			runInAction(() => {
				localStore.status = 'Loaded'
				localStore.license = newLicense.data
				localStore.newLicenseString = ''

				licenseInstance.licenseInfo = {
					status: 'licensed',
					license: newLicense.data,
				}
			})

			toastService.displayToast({
				area: 'global',
				message: 'License updated',
			})
		} catch (error) {
			console.warn(error)
			toastService.displayToast({
				area: 'global',
				message: 'Failed to update license',
			})
			runInAction(() => (localStore.status = previousStatus))
		}
	}

	return (
		<AdministrationPageContainer title="Licensing">
			<Backdrop
				open={
					localStore.status === 'NotLoaded' || localStore.status === 'Loading'
				}
			>
				<FullscreenSpinner />
			</Backdrop>
			<Box
				height={'100%'}
				width={'100%'}
				display="flex"
				flexDirection="column"
				gap={5}
			>
				<Box flex="0">
					<Box
						component={Card}
						width="100%"
						padding={2}
						display="flex"
						flexDirection="column"
					>
						<TextInput
							label="License Key"
							hint="A new license key"
							multiline
							value={localStore.newLicenseString}
							onChange={(v) =>
								runInAction(
									() => (localStore.newLicenseString = v.target.value),
								)
							}
						/>
						<Box display="flex" justifyContent={'flex-end'} width="100%">
							<Button variant="contained" onClick={updateLicense}>
								Update License
							</Button>
						</Box>
					</Box>
				</Box>

				{localStore.status === 'Failed' && (
					<Box
						display="flex"
						alignItems={'center'}
						justifyContent={'center'}
						height="100%"
						width="100%"
					>
						<Typography variant="h5">Software is Not Licensed</Typography>
					</Box>
				)}

				{localStore.status === 'Loaded' && (
					<Box display="flex" flexDirection="column" gap={2}>
						<Box>
							<Typography variant="h6">License Details</Typography>

							<Typography color={'GrayText'} variant="caption">
								{localStore.license?.licenseId}
							</Typography>
						</Box>
						<Box
							width="100%"
							height="100%"
							display="flex"
							flexWrap="wrap"
							gap={2}
						>
							{Object.entries(localStore.license!.modules).map((v) => {
								if (v[1] === undefined) return

								return (
									<Box
										width="300px"
										key={v[0]}
										component={Card}
										display="flex"
										flexDirection={'column'}
									>
										<Box padding={2} variant="h6" component={Typography}>
											{v[0]}
										</Box>
										<Divider />
										<Box
											display={'grid'}
											gridTemplateColumns={'repeat(2, 1fr)'}
											rowGap={2}
											columnGap={2}
											paddingY={2}
										>
											{/* type */}
											<Box
												component={Typography}
												fontWeight={500}
												width="100%"
												height="100%"
												display="flex"
												justifyContent={'flex-end'}
											>
												Type
											</Box>
											<Box component={Typography} width="100%" height="100%">
												{v[1].type}
											</Box>

											{/* start date */}
											<Box
												component={Typography}
												fontWeight={500}
												width="100%"
												height="100%"
												display="flex"
												justifyContent={'flex-end'}
											>
												Start Date
											</Box>

											<Box component={Typography} width="100%" height="100%">
												{new Date(v[1].startDate).toLocaleDateString()}
											</Box>

											{/* end date */}
											<Box
												component={Typography}
												fontWeight={500}
												width="100%"
												height="100%"
												display="flex"
												justifyContent={'flex-end'}
											>
												Expiration Date
											</Box>

											<Box component={Typography} width="100%" height="100%">
												{new Date(v[1].expirationDate).toLocaleDateString()}
											</Box>

											{/* end  */}
											<Box
												component={Typography}
												fontWeight={500}
												width="100%"
												height="100%"
												display="flex"
												justifyContent={'flex-end'}
											>
												Additional Details
											</Box>

											<Box component={Typography} width="100%" height="100%">
												{v[1].additionalDetails === ''
													? '{ }'
													: v[1].additionalDetails}
											</Box>
										</Box>
									</Box>
								)
							})}
						</Box>
					</Box>
				)}
			</Box>
		</AdministrationPageContainer>
	)
})

export default LicensingPage
