import { createEvent } from "../../../FormBuilderCore/eventBus/EventSpecification"
import { EventBusEventSource } from "../../../FormBuilderCore/eventBus/EventTypes"
import { IFormHost } from "../../../FormHost/FormHost/IFormHost"

export type EventBusFocusEventProps = {
	focusType: "FocusLost" | "FocusGained",
	relatedTargetId?: string,
	relatedTargetNodeName?: string
}

export type EventBusFocusEventSource = {
	formHost: IFormHost
	formId: number
	definitionId: string
	instanceId?: string
}

export const focusEventId = "12847ced-89cf-45dd-8c5a-fc947386e4d2"

export const [focusEventEmitter, focusEventConsumer] =
	createEvent<
		EventBusFocusEventProps,
		EventBusEventSource
	>
	(focusEventId, "Field Focused")