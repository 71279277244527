import { Circle, Delete, PersonAdd } from '@mui/icons-material'
import ArrowForward from '@mui/icons-material/ArrowForward'
import {
	Button,
	Chip,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import { green, red, yellow } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { CurrentOrganizationUsersClient } from '../../../../../api/clients/identity/CurrentOrganizationUsersClient'
import { BaseUser } from '../../../../../api/DTO'
import {
	AccessStatus,
	CurrentOrganizationUserAssociation,
} from '../../../../../api/DTOtemp'
import { DeleteDialog } from '../../../../../components/dialogs/DeleteDialog'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import IconButtonLink from '../../../../../components/links/IconButtonLink'
import { SessionService } from '../../../../../services/session'
import { makeChildRoute } from '../../../../../utils/CreateChildRoute'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import CreateUserDialog from './CreateUserDialog'

const UsersPage = observer(() => {
	const store = useLocalObservable(() => ({
		isFetching: false,
		users: undefined as CurrentOrganizationUserAssociation[] | undefined,

		createUserOpen: false,
		userToRemove: undefined as BaseUser | undefined,
	}))

	const api = new CurrentOrganizationUsersClient()

	const handleShowCreateUser = useCallback(
		action(() => {
			store.createUserOpen = true
		}),
		[store],
	)

	const handleHideCreateUser = useCallback(
		action(() => {
			store.createUserOpen = false
		}),
		[store],
	)

	const handleShowDeleteAssociation = useCallback(
		action((user: BaseUser) => {
			store.userToRemove = user
		}),
		[store],
	)

	const handleHideDeleteAssociation = useCallback(
		action(() => {
			store.userToRemove = undefined
		}),
		[store],
	)

	const handleDeleteAssociation = useCallback(
		(userId: number) => {
			api.deleteAssociationForCurrentOrganization(userId)
			runInAction(() => {
				if (store.users !== undefined) {
					const userToRemove = store.users.map((v) => v.userId).indexOf(userId)
					store.users.splice(userToRemove, 1)
				}
			})
		},
		[store],
	)

	useEffect(() => {
		store.isFetching = true
		api.getUsersForCurrentOrganization().then(
			action((v) => {
				store.isFetching = false
				store.users = v.data
			}),
		)
	}, [store])

	return (
		<>
			<AdministrationPageContainer
				title="Users"
				actions={
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<PersonAdd />}
							onClick={handleShowCreateUser}
						>
							Create User
						</Button>
					</div>
				}
			>
				{store.users === undefined ? (
					<FullscreenSpinner />
				) : (
					<UsersTable
						users={store.users}
						onRemoveClicked={handleShowDeleteAssociation}
					/>
				)}
			</AdministrationPageContainer>
			<CreateUserDialog
				open={store.createUserOpen}
				onExit={handleHideCreateUser}
				onUserCreated={handleHideCreateUser}
			/>
			{store.userToRemove !== undefined && (
				<DeleteDialog<number>
					open={store.userToRemove !== undefined}
					itemName={`${store.userToRemove.firstName} ${store.userToRemove.lastName}`}
					itemId={store.userToRemove.id}
					onClose={handleHideDeleteAssociation}
					onDelete={handleDeleteAssociation}
				/>
			)}
		</>
	)
})

export default UsersPage

type UsersTableProps = {
	users: CurrentOrganizationUserAssociation[]
	onRemoveClicked: (user: BaseUser) => void
}

const UsersTable = (props: UsersTableProps) => {
	const styles = useStyles()

	const { url } = useRouteMatch()

	const sessionService = new SessionService()

	const getAccessStatusText = (accessStatus: AccessStatus): string => {
		switch (accessStatus) {
			case 0:
				return 'Awaiting Approval'
			case 1:
				return 'Locked Out'
			case 2:
				return 'Access Permitted'
			default:
				return ''
		}
	}

	return (
		<Fade in>
			<TableContainer className={styles.userProfileRoot} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Email Address</TableCell>
							<TableCell>Access Status</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.users.map((v) => (
							<TableRow key={v.userId}>
								<TableCell>
									<Typography variant="body2">{v.user.firstName}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2">{v.user.lastName}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2">{v.user.emailAddress}</Typography>
								</TableCell>
								<TableCell>
									<Chip
										icon={
											<div>
												<Circle
													sx={{
														color:
															v.accessStatus === AccessStatus.accessPermitted
																? green[500]
																: v.accessStatus === AccessStatus.lockedOut
																? red[500]
																: yellow[500],
														fontSize: 10,
													}}
												/>
											</div>
										}
										label={getAccessStatusText(v.accessStatus)}
										variant="outlined"
									/>
								</TableCell>
								<TableCell align="right">
									{sessionService.authToken.id !== v.userId && ( // we don't want to be able to delete ourselves! :)
										<IconButton onClick={() => props.onRemoveClicked(v.user)}>
											<Delete fontSize="small" />
										</IconButton>
									)}
									<IconButtonLink to={makeChildRoute(v.userId.toString(), url)}>
										<ArrowForward fontSize="small" />
									</IconButtonLink>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	userProfileRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
