import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { ActivityConfiguration } from '../../api/clients/workflows/DTOs'
import { FilteringSearchBar } from '../../components/inputs/FilteringSearchBar'
import { ActivityConfigurationCard } from './ActivityCard'
import { useWorkflowStore } from './stores/WorkflowStore'

/**
 * display all the activity options
 */
export const ActivityOptions = observer(() => {
	const { activityConfigurationStore } = useWorkflowStore()
	const [filteredActivities, setFilteredActivities] = useState(
		activityConfigurationStore.configurations,
	)

	const handleFilterActivities = (matches: string[]) => {
		// matches is the display names/categories of the activities that matched the query
		// so if query = "flow" matches will include "control flow"
		const innerFilteredActivities: ActivityConfiguration[] = []

		for (const value of matches) {
			// we are filtering by category and display name
			const activityMatches = activityConfigurationStore.configurations.filter(
				(v) => v.category === value || v.displayName === value,
			)

			for (const activity of activityMatches)
				if (!innerFilteredActivities.includes(activity))
					innerFilteredActivities.push(activity)
		}

		setFilteredActivities(innerFilteredActivities)
	}

	return (
		<Box
			height="100%"
			width="100%"
			padding={3}
			display="flex"
			flexDirection="column"
			gap={3}
		>
			<Typography variant="h5">Add an Activity</Typography>
			<FilteringSearchBar
				values={activityConfigurationStore.configurations
					.map((v) => v.displayName)
					.concat(
						activityConfigurationStore.configurations.map((v) => v.category),
					)}
				onQuery={(values) => handleFilterActivities(values)}
			/>
			{[...new Set(filteredActivities.map((v) => v.category))].map(
				(category) => (
					<Box display="flex" flexDirection="column" gap={0.5} key={category}>
						<Typography variant="h6">{category}</Typography>
						<Box display="flex" flexDirection="column" gap={1.5}>
							{filteredActivities
								.filter((v) => v.category === category)
								.map((activity) => (
									<Box
										key={activity.definitionId}
										component={ActivityConfigurationCard}
										border={1}
										borderColor={(theme) => theme.palette.grey[500]}
										activityTitle={activity.displayName}
										description={activity.description}
										icon={activity.icon}
										boxShadow={0}
										uniqueId={activity.definitionId}
									/>
								))}
						</Box>
					</Box>
				),
			)}
		</Box>
	)
})
