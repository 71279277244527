import { useTheme } from "@mui/system"
import { CategoryColorMap } from "../../draggable/NodeComponents"

const useColorScheme = (category: keyof typeof CategoryColorMap): [string, string] => {
  const theme = useTheme()

  const color = CategoryColorMap[category]
  const textColor = theme.palette.getContrastText(color)

  return [color, textColor]
}

export default useColorScheme