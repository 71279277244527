import { createContext, PropsWithChildren, useContext } from 'react'

export type RegistrationError = {
	registrationId: string
	errorText: string

	errorFieldName: string
	errorBucket: string
}

const PackageHostPageContext = createContext<
	PackageHostPageContextProviderProps | undefined
>(undefined)

export type PackageHostPageContextProviderProps = {
	validationErrors: Record<string, RegistrationError[]>
	ipAddress?: string
	location?: GeolocationPosition
}

export const PackageHostPageContextProvider = (
	props: PropsWithChildren<PackageHostPageContextProviderProps>,
) => {
	const { children, ...args } = props

	return (
		<PackageHostPageContext.Provider value={args}>
			{children}
		</PackageHostPageContext.Provider>
	)
}

export const usePackageHostPageContext = () => {
	const contextValue = useContext(PackageHostPageContext)
	if (contextValue === undefined)
		throw Error(
			'package host context was consumed before adequate props were provided',
		)

	return contextValue
}
