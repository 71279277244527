import { NIL } from 'uuid'
import { CellType } from '../../../FormHost/Types/CellType'
import FieldType, { isValueType } from '../../../FormHost/Types/FieldType'
import { PdfCellDefinition } from '../Types/PdfCellDefinition'
import { PdfCellInstance } from '../Types/PdfCellInstance'

export function instancesToObject(
	cellDefinitions: PdfCellDefinition[],
	cellInstances: PdfCellInstance[],
	parentInstanceId: string = NIL,
): Record<string, unknown> {

	const childCells = cellInstances.filter(v => v.parentId === parentInstanceId)

	const obj = {} as Record<string, unknown>

	for (const cell of childCells) {
		const definition = cellDefinitions.find(v => v.id === cell.definitionId)

		if (definition === undefined)
			throw new Error(`no definition with id '${cell.definitionId}' exists`)
		
		if (isValueType(cell.fieldType) && cell.type === CellType.Value)
			obj[definition.name] = cell.value

		// possibility of sections here, but hard to tell w/o acrobat
		if (cell.fieldType === FieldType.Object)
			obj[definition.name] = instancesToObject(cellDefinitions, cellInstances, cell.id)
		
		// no repeatable sections in PDFs, makes this super easy
	}

	return obj
}
