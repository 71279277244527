import { Box, Paper } from '@mui/material'
import { percent } from 'csx'
import { Syntax } from 'esprima'
import { BlockStatement, Directive, Node, Program } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { InsertComponent } from '../../draggable/InsertComponent'
import { ASTNodeComponents, RendererComponent } from '../../Types'
import Renderer, { DropMode } from './Renderer'

type EditorProps = {
	node: Program | BlockStatement
}

// any nodes that can stand alone within an editor
const StandaloneNodeTypes = [
	Syntax.IfStatement,
	Syntax.AssignmentExpression,
	Syntax.ExpressionStatement,
]

const Editor = observer((props: EditorProps) => {
	if (props.node.body === undefined) throw new Error('Node requires a body')

	return (
		<Box
			display="flex"
			flexDirection="column"
			component={Paper}
			height={percent(100)}
			bgcolor="transparent"
			elevation={0}
		>
			{props.node.body.length === 0 && (
				<InsertComponent
					insertionIndex={0}
					node={props.node}
					validNodes={StandaloneNodeTypes}
				/>
			)}

			{props.node.body.map((v, i) => {
				if (v.type === undefined)
					throw new Error('all except type are able to be undefined')
				const Component = ASTNodeComponents[v.type] as React.ComponentType<
					RendererComponent<Node>
				>

				if (Component === undefined) return 'undefined'
				return (
					<Fragment key={i}>
						<Renderer
							key={i}
							dropMode={DropMode.Insert}
							nodes={props.node.body}
							insertionIndex={i}
							validNodes={StandaloneNodeTypes}
							getter={() => props.node.body[i]}
							setter={action(
								(node) => (props.node.body[i] = node as Directive),
							)}
						/>
					</Fragment>
				)
			})}
		</Box>
	)
})

export default Editor
