import { FormBuilderSchema } from "../../../modules/FormBuilderCore/Types"

export type BaseWorkItemModel = {
	id: number
	declaredActivityInstanceId: number
	name: string
	workItemTypeId: string
	assignmentType: AssignmentType
	assignmentTargetId: number
	workItemStatus: WorkItemStatus
	lockedUntil: Date
	createdDate: Date
	createdBy: number
}

export type BaseWorkItemState = {
	'$t': string
	workItemTypeId: string
}

export type WorkItemResponse<TState extends BaseWorkItemState> = {
	id: number
	declaredActivityInstanceId: number
	name: string
	workItemState: TState
	assignmentType: AssignmentType
	assignmentPointer: number
	formBuilderSchema: FormBuilderSchema // ! Remove Me!!!
}

export type NewWorkItemModel = {
	declaredActivityInstanceId: number
	name: string
	workItemTypeId: string
	assignmentType: AssignmentType
	assignmentTargetId: number
}

export type ReadWorkItemModel<TState extends BaseWorkItemState> = WorkItemResponse<TState>

export type WriteWorkItemModel<TState extends BaseWorkItemState> = {
	lockPassword: string
} & WorkItemResponse<TState>

export type WorkItemResult = {
	outcome: string
	results: unknown
	lockPassword: string
	userId?: number
	logMessage?: string
}

export type WorkItemQueueSnapshot = {
	assignmentType: AssignmentType
	assignedTo: number
	itemCount: number
}

export type ForwardedFormPackageWorkItemState = {
	packageInstanceId: number
	packageId: number
	packageVersionId: number
	packageName: string
	description?: string
	instanceUserId: number
	lifetimeId: string
	uniqueInstanceId: string
} & BaseWorkItemState

export type AssignedFormPackagesWithChangeToken = {
	rowVersion: string,
	workItem: WorkItemResponse<ForwardedFormPackageWorkItemState>
}

export enum AssignmentType {
	AnonymousLink = 0,
	Group = 1,
	User = 2
}

export enum WorkItemPermissions {
	Read = 0,
	Write = 1
}

export enum WorkItemState {
	All = 0,
	Unlocked = 1,
	Locked = 2,
	Active = 3,
	Done = 4
}

export enum WorkItemStatus {
	Active = 0,
	Done = 1
}