import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { WorkflowDefinitionsDashboard } from './WorkflowDefinitionsDashboard'
import { WorkflowsDashboardPage } from './WorkflowsDashboardPage'

export const WorkflowsRouter: React.FC = () => {
	const { path } = useRouteMatch()

	updateBreadcrumbs(`/_administration/_workflows`, 'Workflows')

	return (
		<Switch>
			<Route path={makeChildRoute(':workflowId', path)}>
				<WorkflowDefinitionsDashboard />
			</Route>

			<Route path={makeChildRoute('', path)}>
				<WorkflowsDashboardPage />
			</Route>
		</Switch>
	)
}
