import { TextField } from '@mui/material'
import { TypedEditor } from './PropertySchematicConfigurator'

const NumberPropertyConfigurator = (props: TypedEditor<number | undefined>) => {
	return (
		<TextField
			fullWidth
			type="number"
			value={props.value ?? 0}
			onChange={(v) => {
				const newValue = parseInt(v.currentTarget.value)
				if (isNaN(newValue)) props.onChange(0)
				else props.onChange(newValue)
			}}
		/>
	)
}

export default NumberPropertyConfigurator
