import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { DetailForm } from '../../../../../../../../api/DTOtemp'
import { CellDefinition } from '../../../../../../../../modules/FormHost/Types/CellDefinition'
import { ValidationEventRegistration } from '../../../../../../../../modules/FormHost/Validation/Types'
import { ValidationEvents } from '../../../../../../../../modules/FormHost/Validation/ValidationEvents'
import { useModals } from '../../../../../../../../services/notifications/ModalService'

type FormPackageValidationSectionProps = {
	forms?: DetailForm[] // if forms are defined then this is displayed in the package section
	cellDefinitions: CellDefinition[]
	validationRegistrations: ValidationEventRegistration[]
	onDeleteRegistration: (registrationId: string) => void
	onUpdateRegistration: (registration: ValidationEventRegistration) => void
}

export const FormPackageValidationSection = observer(
	(props: FormPackageValidationSectionProps) => {
		const styles = useStyles()
		const modalService = useModals()

		const getValidationEvent = useCallback(
			(eventId: string) => {
				const event = ValidationEvents.find((v) => v.id === eventId)

				if (event === undefined)
					throw new Error(`could not find event with id ${eventId}`)

				return event
			},
			[ValidationEvents],
		)

		const getRuntimeDescription = useCallback(
			(definitionId: string, eventId: string) => {
				const eventDescription = ValidationEvents.find(
					(v) => v.id === eventId,
				)?.description
				if (eventDescription === undefined)
					throw new Error(`could not find event with id ${eventId}`)

				const fieldName = props.cellDefinitions.find(
					(v) => v.id === definitionId,
				)?.name
				if (fieldName === undefined)
					throw new Error(
						`could not find cell definition with id ${definitionId}`,
					)

				return `${fieldName} ${eventDescription}`
			},
			[ValidationEvents],
		)

		return (
			<Fade in unmountOnExit>
				<TableContainer className={styles.tableContainer} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{props.forms !== undefined && <TableCell>Form</TableCell>}
								<TableCell>Runtime Description</TableCell>
								<TableCell>Validation Type</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.validationRegistrations.map((registration) => {
								const event = getValidationEvent(registration.eventTypeId)

								return (
									<TableRow key={registration.registrationId}>
										{props.forms !== undefined && (
											<TableCell>
												{
													props.forms.find((v) => v.id === registration.formId)
														?.name
												}
											</TableCell>
										)}
										<TableCell>
											{getRuntimeDescription(
												registration.definitionId,
												registration.eventTypeId,
											)}
										</TableCell>
										<TableCell>{event.displayName}</TableCell>
										<TableCell align="right">
											{event.parameterConfigurator && ( // only want to be able to edit if there are parameters to edit
												<IconButton
													onClick={() => {
														modalService
															.showForm((modalProps) => (
																<EditValidationRegistrationDialog
																	validationRegistration={registration}
																	onCancel={() =>
																		modalProps.close({
																			closeResult: 'cancel',
																		})
																	}
																	onConfirm={(v) =>
																		modalProps.close({
																			closeResult: 'okay',
																			value: v,
																		})
																	}
																/>
															))
															.then((v) => {
																if (v.closeResult === 'cancel') return
																props.onUpdateRegistration({
																	...registration,
																	parameters: v.value as {
																		[key: string]: unknown
																	},
																})
															})
													}}
												>
													<Edit />
												</IconButton>
											)}
											<IconButton
												onClick={() =>
													props.onDeleteRegistration(
														registration.registrationId,
													)
												}
											>
												<Delete />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

type EditValidationRegistrationDialogProps = {
	validationRegistration: ValidationEventRegistration
	onConfirm: (parameters: { [key: string]: unknown }) => void
	onCancel: () => void
}

const EditValidationRegistrationDialog = (
	props: EditValidationRegistrationDialogProps,
) => {
	const event = ValidationEvents.find(
		(v) => v.id === props.validationRegistration.eventTypeId,
	)

	const [updatedParameters, setUpdatedParameters] = useState(
		props.validationRegistration.parameters,
	)

	return (
		<>
			<DialogTitle>Edit Field Validation</DialogTitle>
			<DialogContent>
				{event &&
					event.parameterConfigurator &&
					event.parameterConfigurator(updatedParameters, setUpdatedParameters)}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => props.onConfirm(updatedParameters)}
					disabled={
						updatedParameters === props.validationRegistration.parameters
					}
				>
					Save Changes
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'auto',
	},
}))
