import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Literal } from 'estree'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import useColorScheme from '../../internal/useColorScheme'
import BooleanLiteralComponent from './BooleanLiteralComponent'
import NumberLiteralComponent from './NumberLiteralComponent'
import StringLiteralComponent from './StringLiteralComponent'

const LiteralComponent = observer((props: RendererComponent<Literal>) => {
	const [color, textColor] = useColorScheme(ComponentCategory.Variables)

	const renderLiteralType = (valueType: string) => {
		if (valueType === 'string')
			return (
				<>
					<Typography fontSize="small" color={textColor}>
						String Literal
					</Typography>
					<StringLiteralComponent node={props.node} />
				</>
			)

		if (valueType === 'number')
			return (
				<>
					<Typography fontSize="small" color={textColor}>
						Number Literal
					</Typography>
					<NumberLiteralComponent node={props.node} />
				</>
			)

		if (valueType === 'boolean')
			return (
				<>
					<Typography fontSize="small" color={textColor}>
						Boolean Literal
					</Typography>
					<BooleanLiteralComponent node={props.node} />
				</>
			)

		throw new Error(`literal type '${valueType}' is not supported`)
	}

	return (
		<Box
			bgcolor={color}
			padding={1}
			width="fit-content"
			display="flex"
			flexDirection="column"
		>
			{renderLiteralType(typeof props.node.value)}
		</Box>
	)
})

export default LiteralComponent
