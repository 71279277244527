import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { FieldMapping } from '../../../../../../../../api/DTOtemp'
import IconButtonLink from '../../../../../../../../components/links/IconButtonLink'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'
import { FixedHeaderWithHint } from '../../../../../../../../utils/HOC/FixedHeaders'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'

type LinkedGroupsSectionProps = {
	packageId: number
}

export const LinkedGroupsAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps) => {
		const styles = useStyles()

		return (
			<AdministrationPageContainer
				title="Form Package Linked Groups"
				actions={<LinkedGroupsSectionButtons packageId={props.packageId} />}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={3}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<LinkedGroupsSection packageId={props.packageId} />
				</div>
			</AdministrationPageContainer>
		)
	},
)

const LinkedGroupsSection = observer((props: LinkedGroupsSectionProps) => {
	const styles = useStyles()
	const { url } = useRouteMatch()

	const { formPackage } = useFormPackageContext()

	const store = useLocalObservable(() => ({
		fieldMappings: formPackage.configuration.fieldMappings,
	}))

	const handleRemoveFieldMapping = (id: string, name: string) => {
		const api = new FormPackageVersionClient(props.packageId)
		const indexToRemove = formPackage.configuration.fieldMappings.findIndex(
			(v) => v.id === id,
		)
		if (indexToRemove < 0) return

		formPackage.configuration.fieldMappings.splice(indexToRemove, 1)

		api
			.UpdateConfiguration(formPackage)
			.then(() => {
				toastService.displayToast({
					message: 'Linked group deleted',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error deleting linked group',
					area: 'global',
				})
			})
	}

	return (
		<Fade in unmountOnExit>
			<TableContainer className={styles.tableContainer} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{store.fieldMappings !== undefined &&
							store.fieldMappings.map((fieldMapping, i) => (
								<TableRow key={fieldMapping.name}>
									<TableCell>{fieldMapping.name}</TableCell>
									<TableCell>{fieldMapping.description ?? ''}</TableCell>
									<TableCell align="right">
										<IconButtonLink
											to={makeChildRoute(
												`_linked-groups/${fieldMapping.id}`,
												url,
											)}
										>
											<Edit />
										</IconButtonLink>
										<IconButton
											onClick={() =>
												handleRemoveFieldMapping(
													fieldMapping.id,
													fieldMapping.name,
												)
											}
										>
											<Delete />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
})

const LinkedGroupsSectionButtons = (props: LinkedGroupsSectionProps) => {
	const [dialogOpen, setDialogOpen] = useState(false)
	const { formPackage } = useFormPackageContext()
	const history = useHistory()

	const handleOpenDialog = useCallback(() => {
		setDialogOpen(true)
	}, [])

	const handleCloseDialog = useCallback(() => {
		setDialogOpen(false)
	}, [])

	const handleAddFieldMapping = (fieldMapping: FieldMapping) => {
		const api = new FormPackageVersionClient(props.packageId)

		if (formPackage.configuration.fieldMappings !== undefined)
			formPackage.configuration.fieldMappings.push(fieldMapping)

		api
			.UpdateConfiguration(formPackage)
			.then(() => {
				toastService.displayToast({
					message: 'Linked group created',
					area: 'global',
				})
				handleCloseDialog()
				history.push(
					`/_administration/_form-packages/${props.packageId}/_linked-groups/${fieldMapping.id}`,
				)
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error creating linked group',
					area: 'global',
				})
			})
	}

	return (
		<>
			<div>
				<Button
					onClick={handleOpenDialog}
					color="primary"
					variant="contained"
					startIcon={<Edit />}
				>
					Create Linked Group
				</Button>
			</div>
			<AddLinkedFieldsDialog
				packageId={props.packageId}
				open={dialogOpen}
				onClose={handleCloseDialog}
				onAddFieldMapping={handleAddFieldMapping}
			/>
		</>
	)
}

type AddLinkedFieldsDialogProps = {
	packageId: number
	open: boolean
	onClose: () => void
	onAddFieldMapping: (link: FieldMapping) => void
}

export const AddLinkedFieldsDialog = (props: AddLinkedFieldsDialogProps) => {
	const styles = useStyles()

	const validationSchema = yup.object({
		name: yup.string().required(),
		description: yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			props.onAddFieldMapping({
				id: uuidv4(),
				name: values.name,
				description: values.description,
				value: '',
				linkedFields: [],
			})
		},
	})

	return (
		<Dialog open={props.open} onClose={props.onClose} fullWidth>
			<DialogTitle>Create Linked Group</DialogTitle>
			<DialogContent className={styles.dialogContent}>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.touched.name && formik.errors.name}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						autoFocus
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.errors.name !== undefined}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>

				<FixedHeaderWithHint
					label="Description"
					hint={formik.touched.description && formik.errors.description}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						fullWidth
						id="description"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.errors.description !== undefined}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={!formik.isValid || formik.isSubmitting}
					onClick={formik.submitForm}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'auto',
	},

	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},
}))
