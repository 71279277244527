import { BaseWorkItemState, WorkItemPermissions, WorkItemResponse, WriteWorkItemModel } from "../../api/clients/workItems/DTOs";
import WorkItemsClient from "../../api/clients/workItems/WorkItemsClient";
import { retrieveUserDatabase } from "./indexedDb";
	
export function getWorkItem<TState extends BaseWorkItemState>(
	workItemId: number,
): Promise<WorkItemResponse<TState> | undefined>;
export function getWorkItem<TState extends BaseWorkItemState>(
	workItemId: number,
	permission: WorkItemPermissions.Read
): Promise<WorkItemResponse<TState> | undefined>;
export function getWorkItem<TState extends BaseWorkItemState>(
	workItemId: number,
	permission: WorkItemPermissions.Write
): Promise<WriteWorkItemModel<TState> | undefined>;
export async function getWorkItem<TState extends BaseWorkItemState>(
	workItemId: number,
	permission?: WorkItemPermissions,
): Promise<WorkItemResponse<TState> | undefined> {

	// TODO - make this thing target the stored cache

	const database = await retrieveUserDatabase();

	if (navigator.onLine) {
		const workItemClient = new WorkItemsClient();

		const {data: response} = await workItemClient.getWorkItem(workItemId, permission)

		const transaction = database.transaction('workItemCache', 'readwrite')
		const workItemCache = transaction.objectStore('workItemCache')
		
		workItemCache.put(response, response.id.toString())
		transaction.commit()
		await transaction.done

		return response as WorkItemResponse<TState>
	} else {
		const transaction = database.transaction('workItemCache', 'readonly')
		const workItemCache = transaction.objectStore('workItemCache')

		const result = await workItemCache.get(workItemId.toString())
		return result as WorkItemResponse<TState>
	}	
}

export async function setWorkItem<TState extends BaseWorkItemState>(workItem: WorkItemResponse<TState>) {
	const database = await retrieveUserDatabase();

	const transaction = database.transaction('workItemCache', 'readwrite')
	const workItemCache = transaction.objectStore('workItemCache')

	workItemCache.put(workItem, workItem.id.toString())
	// transaction.commit()
	await transaction.done

}

export const removeWorkItem = async (workItemId: string) => {
	const database = await retrieveUserDatabase();

	const transaction = database.transaction('workItemCache', 'readwrite')
	const workItemCache = transaction.objectStore('workItemCache')
	
	await workItemCache.delete(workItemId)
	transaction.commit()

	await transaction.done
}