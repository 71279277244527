import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Route, Switch as RouterSwitch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../utils/CreateChildRoute'
import { EditorDisplayType } from './Types'
import { WorkflowEditorHost } from './Workflows'

export const WorkflowEditorHostRouter = () => {
	const { path } = useRouteMatch()

	return (
		<DndProvider backend={HTML5Backend}>
			<RouterSwitch>
				<Route
					path={makeChildRoute(
						'_versions/:definitionId/_instances/:instanceId',
						path,
					)}
				>
					<WorkflowEditorHost displayType={EditorDisplayType.Instance} />
				</Route>
				<Route path={makeChildRoute('_versions/:definitionId', path)}>
					<WorkflowEditorHost displayType={EditorDisplayType.ReadonlyVersion} />
				</Route>
				<Route>
					<WorkflowEditorHost displayType={EditorDisplayType.ActiveVersion} />
				</Route>
			</RouterSwitch>
		</DndProvider>
	)
}
