import { useLocalObservable } from 'mobx-react'
import React, { createContext, PropsWithChildren } from 'react'
import { SessionService } from '../../../services/session'

export type SignatureText = {
	fullName: string
	initials: string
	email: string
}

export type SignatureInput = {
	signatureData: {
		viewSignatureValue?: string | undefined
		drawSignatureValue?: string | undefined
	}
} & SignatureText

function createSignatureStore(signatureInput?: SignatureInput): SignatureInput {
	let fullName = ''
	let initials = ''
	let email = ''

	const sessionService = new SessionService()

	if (sessionService.isLoggedIn) {
		if (
			sessionService.authToken.firstName &&
			sessionService.authToken.lastName
		) {
			fullName =
				sessionService.authToken.firstName +
				' ' +
				sessionService.authToken.lastName
			initials =
				sessionService.authToken.firstName.charAt(0) +
				sessionService.authToken.lastName.charAt(0)
		}
		if (sessionService.authToken.email) email = sessionService.authToken.email
	}

	return (
		signatureInput ?? {
			fullName: fullName,
			initials: initials,
			email: email,
			signatureData: {
				viewSignatureValue: undefined,
				drawSignatureValue: undefined,
			},
		}
	)
}

type SignatureStoreType = ReturnType<typeof createSignatureStore>
const signatureStoreContext = createContext<SignatureStoreType | undefined>(
	undefined,
)

type SignatureStoreProviderProps = PropsWithChildren<{
	signatureInput?: SignatureInput
}>

export const SignatureStoreProvider: React.FC<SignatureStoreProviderProps> = ({
	children,
	signatureInput,
}: SignatureStoreProviderProps) => {
	const store = useLocalObservable(() => createSignatureStore(signatureInput))

	return (
		<signatureStoreContext.Provider value={store}>
			{' '}
			{children}{' '}
		</signatureStoreContext.Provider>
	)
}

export const useSignatureStore = () => {
	const context = React.useContext(signatureStoreContext)
	if (!context) throw 'store could not be found'
	return context
}
