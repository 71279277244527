import { Paper, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactNode } from 'react'
import { useDrag } from 'react-dnd'
import { DraggableItemType } from '../../Types/DraggableItemType'

type ComponentEntryProps = {
	icon: ReactNode
	text: string // <<- TEMPORARY
	componentRegistrationId: string
}

export const ComponentEntry = (props: ComponentEntryProps) => {
	const [, drag] = useDrag(() => ({
		type: DraggableItemType.FormBuilderComponent,
		item: {
			componentRegistrationId: props.componentRegistrationId,
		},
	}))

	const styles = useStyles()

	return (
		<Paper ref={drag} elevation={1} className={styles.componentEntryRoot}>
			<div className={styles.iconDiv}>{props.icon}</div>
			<div className={styles.textDiv}>{props.text}</div>
		</Paper>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	componentEntryRoot: {
		flex: '1',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		padding: theme.spacing(1, 1),

		cursor: 'move',
	},

	iconDiv: {
		flex: '0 0 auto',

		display: 'flex',
	},

	textDiv: {
		flex: '1 1 auto',
		alignSelf: 'center',

		margin: theme.spacing(0, 1),
	},
}))
