import { Box, Link, TextField, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import axios from 'axios'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useMemo, useRef } from 'react'
import { SignInClient } from '../../api/clients/identity'
import { AccessStatus, LockedOutLoginResultModel } from '../../api/DTOtemp'
import { SessionService } from '../../services/session/SessionService'
import { FixedHeaderWithHint } from '../../utils/HOC/FixedHeaders'
import { AuthenticationWrapper } from './AuthenticationWrapper'

type PasswordEntryManagerProps = {
	username: string
	onAuthenticated: () => void
	onBackToUsernameEntry: () => void
}

export const PasswordEntryManager = observer(
	(props: PasswordEntryManagerProps) => {
		const store = useLocalObservable(() => ({
			isLoading: false,
			errorMessage: undefined as string | undefined,
			password: '' as undefined | string,
		}))

		const sessionService = new SessionService()

		async function handleNextClicked() {
			const authClient = new SignInClient()
			try {
				store.isLoading = true
				const { data: loginResult } = await authClient.localLogin({
					username: props.username,
					password: store.password!,
				})
				console.log('got auth token: ', sessionService.authToken)

				if (loginResult.accessStatus === AccessStatus.awaitingApproval) {
					store.errorMessage =
						'Your account is still awaiting approval. Please contract your administrator.'
					return
				}

				props.onAuthenticated()
			} catch (e) {
				if (axios.isAxiosError(e)) {
					// if user is unauthorized
					if (e.response?.status === 401) {
						console.log(
							'Unauthorized - Username / Password Combination was invalid',
						)
						store.errorMessage = 'Username / Password Combination was invalid'

						return
					}

					// go ahead and exit if it's not "forbidden"
					if (e.response?.status !== 403) {
						console.log(e)
						return
					}

					const lockedOutLoginResult = e.response
						?.data as LockedOutLoginResultModel

					// if user was manually locked out by an administrator we won't have a LockoutEnd
					if (!lockedOutLoginResult.lockoutEnd) {
						console.log(`Unauthorized - User locked out`)
						store.errorMessage = `You have been locked out of your account. Please contact your administrator.`

						return
					}

					const lockoutEnd = new Date(
						lockedOutLoginResult.lockoutEnd,
					).toLocaleString()

					console.log(`Unauthorized - User locked out until ${lockoutEnd}`)

					store.errorMessage = `Too many attempts. You have been locked out of your account until ${lockoutEnd}.`
				}
			} finally {
				store.isLoading = false
			}
		}

		return (
			<AuthenticationWrapper
				nextButtonText="Login"
				onNextClicked={() => handleNextClicked()}
				onBackClicked={props.onBackToUsernameEntry}
				isLoading={store.isLoading}
			>
				<PasswordEntry
					username={props.username}
					value={store.password}
					onChange={(v) => {
						store.password = v
					}}
					onEnter={() => handleNextClicked()}
					errorMessage={store.errorMessage}
					setIsLoading={action((value) => (store.isLoading = value))}
				/>
			</AuthenticationWrapper>
		)
	},
)

type PasswordEntryProps = {
	username: string
	errorMessage?: string
	value: string | undefined
	onChange: (value: string | undefined) => void
	onEnter?: () => void
	setIsLoading: (value: boolean) => void
}

export const PasswordEntry = (props: PasswordEntryProps) => {
	const styles = usePasswordStyles()
	const textFieldRef = useRef<HTMLDivElement>(null)

	// so the hint doesn't make the whole card & text field wider
	const hintWidth = useMemo(() => {
		if (textFieldRef.current === null) return '100%'

		return textFieldRef.current.clientWidth
	}, [textFieldRef, textFieldRef.current, textFieldRef.current?.clientWidth])

	return (
		<div className={styles.textField} ref={textFieldRef}>
			<TextField hidden={true} name="username" value={'username'} />
			<FixedHeaderWithHint
				className={styles.textField}
				label="Password"
				hint={<Box width={hintWidth}>{props.errorMessage}</Box>}
				hintColor="error.main"
			>
				<TextField
					autoFocus
					fullWidth
					type="password"
					name="password"
					error={props.errorMessage !== undefined}
					value={props.value}
					onChange={(v) => props.onChange(v.target.value)}
					onKeyPress={(keyEventArgs) => {
						if (keyEventArgs.key === 'Enter' && props.onEnter !== undefined)
							props.onEnter()
					}}
				/>
			</FixedHeaderWithHint>

			<Link
				href={`/_forgot-password?email=${props.username}`}
				onClick={() => props.setIsLoading(true)}
				className={styles.spacing}
			>
				Forgot Password?
			</Link>
		</div>
	)
}

const usePasswordStyles = makeStyles((theme: Theme) => ({
	container: {
		height: percent(100),
		width: percent(100),

		display: 'flex',
		flexDirection: 'column',
	},

	textField: {
		width: percent(100),
		padding: theme.spacing(1, 0, 0, 0),
	},

	spacing: {
		margin: theme.spacing(1, 0),
	},
}))
