export enum FieldType {
	Inherited = -1000,
	Virtual = -2,

	/* 
		* the None field type has 2 uses:
		* 1. root components that parent the other components (the cell will be of type 'layout') 
		* 2. placeholders (null cells) in form host and in grids/repeats to maintain component order (the cell will be of type 'null')
	*/
	None = -1,

	Void = 0, // for buttons & display components
	Number = 1,
	String = 2,
	Boolean = 3,
	DateTime = 4,
	ReferenceType = 5, // for non-layout components that export objects

	Object = 1000,

	NumberArray = 2001,
	StringArray = 2002,
	BooleanArray = 2003,
	DateTimeArray = 2004,

	ObjectArray = 2100,
}

export type ValueFieldTypes = FieldType.Number | FieldType.String | FieldType.Boolean | FieldType.DateTime | FieldType.ReferenceType
export type ValueArrayFieldTypes = FieldType.NumberArray | FieldType.StringArray | FieldType.BooleanArray | FieldType.DateTimeArray

export function isValueType(type: FieldType) {
	return (type > FieldType.Void && type < FieldType.Object)
		|| (type >= FieldType.NumberArray && type <= FieldType.DateTimeArray)
}

export type ReferenceFieldTypes = FieldType.Object | FieldType.ObjectArray

export function isReferenceType(type: FieldType) {
	return (type === FieldType.Object || type === FieldType.ObjectArray)
}

export default FieldType