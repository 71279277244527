import { Typography } from '@mui/material'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { FullscreenSpinner } from '../../components/feedback/circular'
import {
	downloadFormPackageInstance,
	getFormPackageInstance,
} from '../../services/offline/FormPackageHandling'
import {
	InstanceCacheFormPackage,
	RetrievalStorageType,
} from '../../services/offline/FormPackageStorage'
import { handleSubmitPackage } from './FormPackageHostPage'
import { PackageApi, PackageApiStatus } from './PackageApi'
import { PackageHost } from './PackageHost'
import { PackageHostErrorPage } from './PackageHostErrorPage'
import { PackageHostPageContextProvider } from './PackageHostPageContext'
import { useOfflinePackageContext } from './PackageListPageContext'

export const SavedFormPackageHostPage = observer(() => {
	const params = useParams<{
		packageId: string
		formPackageInstanceId: string
	}>()

	const offlinePackageContext = useOfflinePackageContext()

	const packageId = parseInt(params.packageId)

	const localStore = useLocalObservable(() => ({
		storedPackage: undefined as InstanceCacheFormPackage | undefined,
		forbidden: false,
	}))

	if (isNaN(packageId)) throw new Error('invalid package id')

	const packageApi = useMemo(() => {
		return new PackageApi(packageId)
	}, [packageId])

	useEffect(() => {
		const abortController = new AbortController()

		async function fetchFormsData() {
			try {
				const savedItemIndex = offlinePackageContext.savedPackageList.get(
					params.formPackageInstanceId,
				)

				console.log(
					'fetching form package instance ' + params.formPackageInstanceId,
				)

				let packageInstance: InstanceCacheFormPackage | undefined =
					await getFormPackageInstance(params.formPackageInstanceId)

				if (navigator.onLine) {
					try {
						packageInstance = await downloadFormPackageInstance(
							RetrievalStorageType.Saved,
							params.formPackageInstanceId,
							savedItemIndex,
							abortController.signal,
							undefined,
						)
					} catch (error) {
						console.error(error)
					}
				} else if (packageInstance === undefined && !navigator.onLine) {
					console.error('form package is not downloaded in cache')
					runInAction(() => {
						localStore.forbidden = true
					})
					return
				}

				runInAction(() => {
					if (abortController.signal.aborted) return

					localStore.storedPackage = packageInstance
					localStore.forbidden = false
				})
			} catch (e) {
				console.error(e)

				// we don't want to do anything about cancelled because
				// it could be the component re-mounting

				if (abortController.signal.aborted) {
					console.log(
						'ignoring error as cancelled status indicates method wil re-run',
					)
					return
				}

				runInAction(() => {
					localStore.forbidden = true
				})
			}
		}

		fetchFormsData()

		return () => {
			abortController.abort('component unmounted')
		}
	}, [packageId])

	if (localStore.forbidden)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<>
						<Typography variant="h6">
							You don't have access to this package
						</Typography>{' '}
						<Typography variant="h6">or this package doesn't exist.</Typography>
					</>
				}
			/>
		)

	if (
		localStore.storedPackage === undefined ||
		packageApi.status !== PackageApiStatus.Complete
	)
		return <FullscreenSpinner />

	const packageInstance = localStore.storedPackage

	console.log('saved instance instance id: ', packageInstance.instanceId)

	return (
		<PackageHostPageContextProvider validationErrors={observable({})}>
			<PackageHost
				packageId={packageId}
				packageApi={packageApi}
				formPackage={packageInstance.packageVersion}
				resources={packageInstance.resources}
				forms={packageInstance.forms}
				workItem={packageInstance.workItem}
				savedInstanceId={packageInstance.uniqueInstanceId}
				onSaveInstance={handleSubmitPackage}
				anonymous={false}
				isPackageFromServer={packageInstance.isPackageFromServer}
			/>
		</PackageHostPageContextProvider>
	)
})
