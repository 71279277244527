
import { NestedCSSProperties } from 'typestyle/lib/types'
import { color, rgb, rgba } from 'csx'


export const light = '#fff'
export const dark = '#121212'

export const lightForeground = dark
export const lightBackground = light

export const darkForeground = light
export const darkBackground = dark

export const red = rgb(255, 0, 0)
export const green = rgb(0, 255, 0)
export const blue = rgb(0, 0, 255)
export const ltBlue = rgb(105, 175, 229)
export const blueIsh = color('#0ae')
export const darkGreen = color('#26944d')
export const sidebarDark = rgba(59, 58, 57, 1)

export const lightGrey = rgba(242, 242, 242, 1)
export const defaultPrimaryColor = '#649940'//'659940'
export const defaultPrimaryLight = '#b8f081'
export const defaultPrimaryDark = '#558c23'
export const defaultPrimaryComplement = '#8952bd'
export const defaultPrimaryAnalogousOne = '#bbbd52'
export const defaultPrimaryAnalogousTwo = '#52bd54'
export const defaultPrimaryTriadicOne = '#52bd89'
export const defaultPrimaryTriadicTwo = '#5286bd'

export const darkPrimaryColor = rgb(193, 221, 167)//'#c1dda7'//'659940'
export const darkPrimaryLight = '#f4ffd9'
export const darkPrimaryDark = '#90ab78'
export const darkPrimaryComplement = '#d4c3e7'
export const darkPrimaryAnalogousOne = '#e6ee8d'
export const darkPrimaryAnalogousTwo = '#c4e7c3'
export const darkPrimaryTriadicOne = '#b8e2cb'
export const darkPrimaryTriadicTwo = '#c1dcef'

export const lightColors: NestedCSSProperties = {
	color: lightForeground,
	backgroundColor: lightBackground
}

export const darkColors: NestedCSSProperties = {
	color: darkForeground,
	backgroundColor: darkBackground
}

export const ListRowKnownClasses = {
	onHoverShow: 'CardList__ListRowContainer--showOnHover'
}

export const palette = {
	knownClasses: ListRowKnownClasses,

	light: light,
	dark: dark,

	lightColors: lightColors,
	darkColors: darkColors,

	dark95: color(dark).lighten(.05).toString(),
	dark90: color(dark).lighten(.1).toString(),
	dark85: color(dark).lighten(.15).toString(),
	dark80: color(dark).lighten(.2).toString(),
	dark75: color(dark).lighten(.25).toString(),
	dark70: color(dark).lighten(.3).toString(),
	dark65: color(dark).lighten(.35).toString(),
	dark60: color(dark).lighten(.4).toString(),
	dark55: color(dark).lighten(.45).toString(),
	dark50: color(dark).lighten(.5).toString(),
	dark45: color(dark).lighten(.55).toString(),
	dark40: color(dark).lighten(.6).toString(),
	dark35: color(dark).lighten(.65).toString(),
	dark30: color(dark).lighten(.7).toString(),
	dark25: color(dark).lighten(.75).toString(),
	dark20: color(dark).lighten(.8).toString(),
	dark15: color(dark).lighten(.85).toString(),
	dark10: color(dark).lighten(.9).toString(),
	dark05: color(dark).lighten(.95).toString(),

	red: red.toString(),
	red95: red.lighten(.05).toString(),
	red90: red.lighten(.1).toString(),
	red85: red.lighten(.15).toString(),
	red80: red.lighten(.2).toString(),
	red75: red.lighten(.25).toString(),
	red70: red.lighten(.3).toString(),
	red65: red.lighten(.35).toString(),
	red60: red.lighten(.4).toString(),
	red55: red.lighten(.45).toString(),
	red50: red.lighten(.5).toString(),
	red45: red.lighten(.55).toString(),
	red40: red.lighten(.6).toString(),
	red35: red.lighten(.65).toString(),
	red30: red.lighten(.7).toString(),

	green: green.toString(),
	green95: green.lighten(.05).toString(),
	green90: green.lighten(.1).toString(),
	green85: green.lighten(.15).toString(),
	green80: green.lighten(.2).toString(),
	green75: green.lighten(.25).toString(),
	green70: green.lighten(.3).toString(),
	green65: green.lighten(.35).toString(),
	green60: green.lighten(.4).toString(),
	green55: green.lighten(.45).toString(),
	green50: green.lighten(.5).toString(),
	green45: green.lighten(.55).toString(),
	green40: green.lighten(.6).toString(),
	green35: green.lighten(.65).toString(),
	green30: green.lighten(.7).toString(),

	blue: blue.toString(),
	blue95: blue.lighten(.05).toString(),
	blue90: blue.lighten(.1).toString(),
	blue85: blue.lighten(.15).toString(),
	blue80: blue.lighten(.2).toString(),
	blue75: blue.lighten(.25).toString(),
	blue70: blue.lighten(.3).toString(),
	blue65: blue.lighten(.35).toString(),
	blue60: blue.lighten(.4).toString(),
	blue55: blue.lighten(.45).toString(),
	blue50: blue.lighten(.5).toString(),
	blue45: blue.lighten(.55).toString(),
	blue40: blue.lighten(.6).toString(),
	blue35: blue.lighten(.65).toString(),
	blue30: blue.lighten(.7).toString(),

	ltBlue: ltBlue.toString(),
	ltBlue95: ltBlue.darken(.45).toString(),
	ltBlue90: ltBlue.darken(.40).toString(),
	ltBlue85: ltBlue.darken(.35).toString(),
	ltBlue80: ltBlue.darken(.30).toString(),
	ltBlue75: ltBlue.darken(.25).toString(),
	ltBlue70: ltBlue.darken(.20).toString(),
	ltBlue65: ltBlue.darken(.15).toString(),
	ltBlue60: ltBlue.darken(.10).toString(),
	ltBlue55: ltBlue.darken(.05).toString(),
	ltBlue50: ltBlue.toString(),
	ltBlue45: ltBlue.lighten(.05).toString(),
	ltBlue40: ltBlue.lighten(.10).toString(),
	ltBlue35: ltBlue.lighten(.15).toString(),
	ltBlue30: ltBlue.lighten(.20).toString(),

	blueIsh: blueIsh.toString(),
	blueIsh70: blueIsh.darken(.20).toString(),
	blueIsh90: blueIsh.darken(.40).toString(),

	darkGreen: darkGreen.toString(),

	sidebarDark: sidebarDark.toString(),
	sidebarDark40: sidebarDark.lighten(.10).toString(),
	sidebarDark30: sidebarDark.lighten(.20).toString(),
	sidebarDark20: sidebarDark.lighten(.30).toString(),

	lightGrey: lightGrey.toString(),
	defaultPrimaryColor: defaultPrimaryColor.toString(),
	defaultPrimaryLight: defaultPrimaryLight.toString(),
	defaultPrimaryDark: defaultPrimaryDark.toString(),
	defaultPrimaryComplement: defaultPrimaryComplement.toString(),
	defaultPrimaryAnalogousOne: defaultPrimaryAnalogousOne.toString(),
	defaultPrimaryAnalogousTwo: defaultPrimaryAnalogousTwo.toString(),
	defaultPrimaryTriadicOne: defaultPrimaryTriadicOne.toString(),
	defaultPrimaryTriadicTwo: defaultPrimaryTriadicTwo.toString(),

	darkPrimaryColor: darkPrimaryColor.toString(),
	darkPrimaryColor60: darkPrimaryColor.darken(.10).toString(),
	darkPrimaryColor30: darkPrimaryColor.lighten(.20).toString(),
	darkPrimaryColor40: darkPrimaryColor.lighten(.10).toString(),
	darkPrimaryLight: darkPrimaryLight.toString(),
	darkPrimaryDark: darkPrimaryDark.toString(),
	darkPrimaryComplement: darkPrimaryComplement.toString(),
	darkPrimaryAnalogousOne: darkPrimaryAnalogousOne.toString(),
	darkPrimaryAnalogousTwo: darkPrimaryAnalogousTwo.toString(),
	darkPrimaryTriadicOne: darkPrimaryTriadicOne.toString(),
	darkPrimaryTriadicTwo: darkPrimaryTriadicTwo.toString(),
}
