import { Code, OpenInNew } from '@mui/icons-material'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Tooltip,
	Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { toastService } from '../../../../services/notifications/ToastService'
import { MonacoEditor } from '../../../CodeEditor/MonacoEditor'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'

type MonacoFormBuilderProps = {
	label?: string
	language: string

	height?: string
}

export const MonacoFormBuilderElement = observer(
	(props: MonacoFormBuilderProps) => {
		const { cellManager } = useFormBuilderContext()
		const context = useInputCellContext()

		const [dialogOpen, setDialogOpen] = useState(false)

		const changeHandler = useCallback((value: string | undefined) => {
			const oldValue = context.value
			context.onChange(value ?? '')

			changeEventEmitter(
				cellManager.eventBus,
				{
					oldValue: oldValue,
					newValue: value,
				},
				{
					formHost: cellManager,
					elementTag: InternalMonacoFormBuilderId,
					definitionId: context.cellInstance.definitionId,
					instanceId: context.cellInstance.id,
				},
			)
		}, [])

		return (
			<Box display="flex" flexDirection="column" width="100%">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="subtitle1">
						{/* if this is an expression editor it will have the name code, and we are
						already displaying the label for it */}
						{context.cellInstance.name !== 'code' && props.label}
					</Typography>
					<Tooltip title="Expand Editor">
						<IconButton size="small" onClick={() => setDialogOpen(true)}>
							<OpenInNew />
						</IconButton>
					</Tooltip>
				</Box>
				<MonacoEditorDialog
					open={dialogOpen}
					title={props.label ?? 'Code Editor'}
					language={props.language}
					height={props.height}
					readonly={cellManager.isReadonly}
					value={context.value as string}
					onConfirm={(v) => {
						changeHandler(v)
						toastService.displayToast({
							message: 'Value updated',
							area: 'global',
							severity: 'success',
						})
						setDialogOpen(false)
					}}
					onCancel={() => setDialogOpen(false)}
				/>
				<Box width="100%">
					<MonacoEditor
						language={props.language}
						value={context.value as string}
						onChange={changeHandler}
						height={props.height}
						width="100%"
						readonly={cellManager.isReadonly}
					/>
				</Box>
			</Box>
		)
	},
)

type MonacoEditorDialogProps = {
	open: boolean
	title: string
	language: string
	height?: string
	readonly?: boolean
	value: string
	onConfirm: (v: string) => void
	onCancel: () => void
}

const MonacoEditorDialog = (props: MonacoEditorDialogProps) => {
	const [value, setValue] = useState(props.value)

	return (
		<Dialog open={props.open} fullWidth maxWidth="lg">
			<Box
				component={DialogTitle}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box display="flex" alignItems="center" gap={1}>
					<Code />
					{props.title}
				</Box>
				<Typography variant="body1">{props.language}</Typography>
			</Box>
			<Box component={DialogContent} overflow="hidden">
				<MonacoEditor
					language={props.language}
					value={value}
					onChange={(v) => setValue(v ?? '')}
					height="50vh"
					readonly={props.readonly}
				/>
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				{!props.readonly && (
					<Button
						variant="contained"
						onClick={() => props.onConfirm(value)}
						disabled={value === props.value}
					>
						Save
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)
}

const InternalMonacoFormBuilderId = 'fd5f32fb-b972-4dbf-b47f-2556900a9c04'

export const InternalMonacoFormBuilderDefinition = specifyInternalComponent(
	InternalMonacoFormBuilderId,
	MonacoFormBuilderElement,
	InternalMonacoFormBuilderId,
	FieldType.String,
	{
		language: 'javascript',
		height: '19vh',
	},
	'',
)
