import { Button, ButtonProps } from '@mui/material'
import React, { forwardRef } from 'react'
import { Link, LinkProps } from 'react-router-dom'

type ButtonLinkProps = {
	to: string
} & ButtonProps<'a'>

function ButtonLink(props: ButtonLinkProps) {
	const { to } = props

	const renderLink = React.useMemo(
		() =>
			// eslint-disable-next-line react/display-name
			forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>((itemProps, ref) => (
				<Link to={to} ref={ref} {...itemProps} />
			)),
		[to],
	)

	return <Button {...props} component={renderLink} />
}

export default ButtonLink
