import { Node, Program } from 'estree'
import { runInAction } from 'mobx'
import { WithFormsInfo } from '../../Types'

export type NodeGetter = () => WithFormsInfo<Node> | undefined
export type NodeSetter = (node: WithFormsInfo<Node> | undefined) => void

export function placeNode(
	programs: WithFormsInfo<Program>[],
	newNode: Node,
	targetGetter: NodeGetter,
	targetSetter: NodeSetter,
) {
	const existingElement = targetGetter()

	// does the node that we're given already exist in one of the programs?
	// if so, place the node that was already in this position where it was
	// depth first search
	for (const program of programs) {
		const newNodeGetterSetter = obtainNodeGetterSetter(program, newNode)
		if (newNodeGetterSetter !== undefined) {
			const [_, newNodeSetter] = newNodeGetterSetter
			runInAction(() => {
				newNodeSetter(existingElement)
			})
			break
		}
	}

	runInAction(() => {
		targetSetter(newNode)
	})
}

export function removeNode(
	programs: WithFormsInfo<Program>[],
	node: Node,
): Node | undefined {
	// if we're removing a program, remove it from the programs array and exit
	if (node.type === 'Program') {
		const index = programs.indexOf(node as WithFormsInfo<Program>)
		if (index >= 0) {
			runInAction(() => {
				programs.splice(index, 1)
			})
			return node
		}
	}

	// depth first search to find node to remove, and remove it if found
	for (const program of programs) {
		const getterSetter = obtainNodeGetterSetter(program, node)
		if (getterSetter === undefined) continue

		const [getter, setter] = getterSetter
		const value = getter()

		runInAction(() => {
			setter(undefined)
		})
		return value
	}
}

export function isChildOfTree(tree: Node, child: Node | undefined): boolean {
	if (child === undefined) return false
	return obtainNodeGetterSetter(tree, child) !== undefined
}

/*
UNSAFE!!!!
Getters and setters must always be computed dynamically and at the time that they are consumed.
No Caching!
That means you, Conner!
*/
function obtainNodeGetterSetter(
	tree: Node,
	node: Node,
): [NodeGetter, NodeSetter] | undefined {
	for (const key in tree) {
		const element = Reflect.get(tree, key)
		// if the type is not object, we know we can move on
		// if the object does not have the key 'type', it probably isn't a node
		if (typeof element !== 'object') continue

		const nodeElement = element as Node

		// we're dealing with something like 'body' on a block statement
		if (Array.isArray(nodeElement)) {
			const result = obtainNodeArrayGetterSetter(nodeElement, node)
			if (result !== undefined) return result
		}

		// node was able to be found as a dictionary element
		if (nodeElement === node) {
			return [
				() => Reflect.get(tree, key),
				(innerNode) => Reflect.set(tree, key, innerNode),
			]
		}
	}

	for (const key in tree) {
		const element = Reflect.get(tree, key)
		if (
			!!element &&
			typeof element === 'object' &&
			Reflect.has(element, 'type')
		) {
			const result = obtainNodeGetterSetter(element, node)
			if (result !== undefined) return result
		}
	}
}

function obtainNodeArrayGetterSetter(
	nodeArray: Node[],
	node: Node,
): [NodeGetter, NodeSetter] | undefined {
	const index = nodeArray.indexOf(node)
	if (index >= 0) {
		// we found the node index in an array, now we have to
		// create a getter-setter around it
		return [
			() => nodeArray[index],
			function (node) {
				// there could be a bug here. If multiple items are removed from the array
				// then we could have issues with adding items back b/c closures

				if (node === undefined) {
					nodeArray.splice(index, 1)
				} else {
					nodeArray[index] = node
				}
			},
		]
	}

	for (const arrayElement of nodeArray) {
		if (typeof arrayElement === 'object' && Reflect.has(arrayElement, 'type')) {
			const result = obtainNodeGetterSetter(arrayElement, node)
			if (result !== undefined) return result
		}
	}
}
