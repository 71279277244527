import {
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactNode } from 'react'

type Property = {
	label: string
	value: string | ReactNode
}

type PropertiesListProps = {
	properties: Property[]
}

// Not to be confused with UserProperties - this is just a list for details
export const PropertiesList = ({ properties }: PropertiesListProps) => {
	const styles = useStyles()

	return (
		<List>
			{properties.map((property) => (
				<div key={property.label}>
					<ListItem>
						<ListItemText
							className={styles.listItemText}
							primary={
								<Typography
									color="textPrimary"
									variant="subtitle2"
									className={styles.primary}
								>
									{property.label}
								</Typography>
							}
							secondary={
								<Typography
									component="span"
									color="textSecondary"
									variant="body2"
									className={styles.secondary}
								>
									{property.value}
								</Typography>
							}
						/>
					</ListItem>
					<Divider />
				</div>
			))}
		</List>
	)
}

const useStyles = makeStyles(() => ({
	listItemText: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	primary: {
		flex: 1,
	},
	secondary: {
		flex: 5,
	},
}))
