import { Button, IconButton, LinkProps, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import React, { forwardRef, ReactElement, ReactNode } from 'react'
import { Link, matchPath } from 'react-router-dom'

type ListItemLinkProps = {
	icon?: ReactElement
	primary: ReactNode
	to: string
}

function ListItemLink(props: ListItemLinkProps) {
	const { icon, primary, to } = props

	const styles = useListItemLinkStyles()

	const matchResult = matchPath(window.location.pathname, {
		path: to,
		exact: false,
		strict: false,
	})
	const shouldBeSelected = matchResult !== null

	const renderLink = React.useMemo(
		() =>
			// eslint-disable-next-line react/display-name
			forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to' | 'color'>>(
				(itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />,
			),
		[to],
	)

	if (primary === undefined)
		return (
			<li className={styles.listItem}>
				<IconButton
					component={renderLink}
					className={clsx({ [styles.selected]: shouldBeSelected })}
				>
					{icon}
				</IconButton>
			</li>
		)

	return (
		<li>
			<Button
				className={clsx(styles.button, { [styles.selected]: shouldBeSelected })}
				startIcon={icon}
				component={renderLink}
			>
				{primary && primary}
			</Button>
		</li>
	)
}

export default ListItemLink

const useListItemLinkStyles = makeStyles((theme: Theme) => ({
	button: {
		justifyContent: 'flex-start',

		// 1.25 is enough, not too much, not too little
		padding: theme.spacing(1.25, 2),

		width: percent(100),
		height: percent(100),

		color: theme.palette.text.secondary,
		textTransform: 'none',
		whiteSpace: 'nowrap',
	},

	selected: {
		color: theme.palette.primary.main,
	},

	listItem: {
		display: 'flex',
		justifyContent: 'center',
	},
}))
