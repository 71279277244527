import { observer } from 'mobx-react'
import { PropsWithChildren, useEffect } from 'react'
import { useNotificationCenterApi } from '../components/notificationCenter'
import { useOfflinePackageContext } from '../pages/PackageHostPage/PackageListPageContext'
import FormPackageBackgroundSyncService from './offline/FormPackageBackgroundSyncService'

const BackgroundServices = observer((props: PropsWithChildren<unknown>) => {
	const notificationContextApi = useNotificationCenterApi()
	const offlineContext = useOfflinePackageContext()

	useEffect(() => {
		const packageSync = new FormPackageBackgroundSyncService(
			notificationContextApi,
			offlineContext,
		)

		const startPromise = packageSync.start()

		return () => {
			console.log('calling disposer')
			startPromise.then((v) => v())
		}
	}, [notificationContextApi])

	return <>{props.children} </>
})

export default BackgroundServices
