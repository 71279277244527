import { normalize, setupPage } from 'csstips'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './components/App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

normalize()
setupPage('#root')

const root = createRoot(document.getElementById('root')!)
root.render(
	<React.StrictMode>
		<link
			rel="stylesheet"
			href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
		/>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
)

reportWebVitals(console.log)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
