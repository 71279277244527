import { Autocomplete, TextField, Typography } from '@mui/material'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useState } from 'react'
import DataProviderInstancesClient from '../../../../api/clients/dataProviders/DataProviderInstancesClient'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type AutoCompleteInputProps = {
	label: string
	dataProviderInstanceId: number
	indexer: string
}

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = observer(
	(props: AutoCompleteInputProps) => {
		const { cellManager } = useFormBuilderContext()
		const context = useInputCellContext()

		const [options, setOptions] = useState<
			{
				key: string
				value: string
			}[]
		>()

		const dataProviderInstancesClient = new DataProviderInstancesClient()

		return (
			<Autocomplete
				options={options ?? []}
				getOptionLabel={(option) => option.value}
				onChange={action((e, v: { key: string; value: string } | null) => {
					const oldValue = context.value
					const newValue = v?.value
					context.onChange(newValue)
					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: oldValue,
							newValue: newValue,
						},
						{
							formHost: cellManager,
							elementTag: AutoCompleteElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				})}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: AutoCompleteElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
				renderInput={(params) => (
					<>
						<Typography variant="subtitle1" color="textPrimary">
							{props.label}
						</Typography>
						<TextField
							{...params}
							autoComplete="off"
							onChange={(e) => {
								dataProviderInstancesClient
									.execute(props.dataProviderInstanceId, {
										'@name': e.currentTarget.value ?? '',
									})
									.then((response) => {
										const options2: {
											key: string
											value: string
										}[] = []

										console.log(response.data)

										for (const data of response.data ?? []) {
											console.log(response.data)
											options2.push({
												key: data[props.indexer] as string,
												value: data[props.indexer] as string,
											})
										}

										setOptions(options2)
									})
							}}
						/>
					</>
				)}
			/>
		)
	},
)

export const AutoCompleteElementTag = '05e57f7f-6900-48fa-a17d-04afe077935d'

const InternalAutoCompleteInputId = '166bf2fd-cd16-451e-bf11-6e921bf5dfbd'

export const InternalAutoCompleteInputDefinition = specifyInternalComponent(
	InternalAutoCompleteInputId,
	AutoCompleteInput,
	AutoCompleteElementTag,
	FieldType.String,
	{
		label: '',
		dataProviderInstanceId: 0,
		indexer: '',
	},
	'',
)
