import { FileCopy } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { toastService } from '../../services/notifications/ToastService'

type ClickToCopyButtonProps = {
	value: string
}

export const ClickToCopyButton = ({ value }: ClickToCopyButtonProps) => {
	const styles = useStyles()

	// ! navigator.clipboard can only be accessed by HTTPS or localhost
	const isHttps = window.location.protocol === 'https:'

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(value).then(() =>
			toastService.displayToast({
				message: 'Text copied to clipboard',
				area: 'global',
			}),
		)
	}

	if (!isHttps) {
		return <></>
	}

	return (
		<Tooltip title="Click to copy">
			<div>
				<IconButton
					size="large"
					onClick={handleCopyToClipboard}
					disabled={!isHttps}
					className={styles.copyButton}
				>
					<FileCopy />
				</IconButton>
			</div>
		</Tooltip>
	)
}

const useStyles = makeStyles(() => ({
	copyButton: {
		width: percent(100),
		height: percent(100),
	},
}))
