import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { ReactNode } from 'react'
import { useHistory } from 'react-router'
import ButtonLink from '../../components/links/ButtonLink'
import { SessionService } from '../../services/session'
import { makeChildRoute } from '../../utils/CreateChildRoute'

type PackageHostErrorPageProps = {
	packageId: number
	errorMessage: ReactNode
}

export const PackageHostErrorPage = ({
	packageId,
	errorMessage,
}: PackageHostErrorPageProps) => {
	const styles = useStyles()
	const history = useHistory()

	const sessionService = new SessionService()

	const loggedInLocation = history.location
	loggedInLocation.pathname = `/_package-host/${packageId}`

	return (
		<div className={styles.root}>
			{errorMessage}
			<div className={styles.spacing}>
				{sessionService.isLoggedIn ? (
					<ButtonLink
						color="primary"
						to={makeChildRoute(`_user/_home`, '')}
						variant="contained"
					>
						Back to Form Packages
					</ButtonLink>
				) : (
					<Button
						color="primary"
						onClick={() => history.push('/_login', { from: loggedInLocation })}
						variant="contained"
					>
						Sign In
					</Button>
				)}
			</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: percent(100),
		width: percent(100),
		alignItems: 'center',
		justifyContent: 'center',
	},

	spacing: {
		padding: theme.spacing(5, 0),
	},
}))
