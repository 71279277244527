import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { PortalsPage } from './PortalsPage/PortalsPage'
import { PortalSettingsPage } from './PortalsPage/PortalSettingsPage'
import { PortalAdminPage } from './PortalsPage/PortalAdminPage'

const PortalsPageRouter = () => {
	const { path } = useRouteMatch()
	updateBreadcrumbs(`/_administration/_portals`, 'Portals')

	return (
		<Switch>
			<Route path={makeChildRoute(':portalId', path)}>
				<PortalAdminPage />
			</Route>
			<Route path={makeChildRoute('', path)}>
				<PortalsPage />
			</Route>
		</Switch>
	)
}

export default PortalsPageRouter
