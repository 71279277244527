import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material'

type DeleteDialogProps<T> = {
	open: boolean
	itemName: string
	itemId: T
	onClose: () => void
	onDelete: (id: T) => void
}

export const DeleteDialog = <T,>(props: DeleteDialogProps<T>) => {
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogContent>
				<Typography>
					Are you sure you want to remove <b>{props.itemName}</b> from your
					organization?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => {
						props.onDelete(props.itemId)
						props.onClose()
					}}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	)
}
