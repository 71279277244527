import { Delete, DeleteForever } from '@mui/icons-material'
import { Box } from '@mui/material'
import { percent } from 'csx'
import { Node } from 'estree'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useDrop } from 'react-dnd'
import { removeNode } from '../codegen/internal/NodeApi'
import { useProgramContext } from '../ProgramContext'
import { allNodes, PartiallyConstructedNode } from '../Types'

export const TrashDustbin = observer(() => {
	const context = useProgramContext()

	const [{ isOverCurrent }, drop] = useDrop(() => ({
		accept: [...allNodes],
		drop(item: PartiallyConstructedNode<Node>) {
			runInAction(() => {
				removeNode(context.programs, item as Node)
			})
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			isOverCurrent: monitor.isOver({ shallow: true }),
		}),
	}))

	return (
		<Box
			ref={drop}
			width={percent(100)}
			alignItems="center"
			justifyContent="center"
		>
			{isOverCurrent ? (
				<DeleteForever fontSize="large" color="primary" />
			) : (
				<Delete fontSize="large" color="primary" />
			)}
		</Box>
	)
})
