import { Identifier } from '@babel/types'
import { Box, MenuItem, Typography } from '@mui/material'
import { MemberExpression } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { UserPropertiesClient } from '../../../../../api/clients/identity'
import { UserProperty } from '../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent, WithFormsInfo } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import useColorScheme from '../../internal/useColorScheme'

const UserPropertyComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const [, textColor] = useColorScheme(ComponentCategory.Variables)
		const [userProperties, setUserProperties] = useState<UserProperty[]>()

		useEffect(() => {
			const userPropertiesClient = new UserPropertiesClient()

			userPropertiesClient.GetUserProperties(true).then((response) => {
				setUserProperties(response.data)
			})
		}, [])

		const propertyNode = useMemo(() => {
			return props.node.property as WithFormsInfo<Identifier>
		}, [props.node.property])

		const formsInfo = props.node.formsInfo

		if (formsInfo === undefined || formsInfo.type !== 'UserPropertyAccess')
			throw new Error(
				`User Property component requires FormsInfo type of 'UserPropertyAccess', got ${formsInfo?.type}`,
			)

		if (userProperties === undefined) return <FullscreenSpinner size={20} />

		return (
			<Box>
				<ColoredFormControl formControlColor={textColor}>
					<ColoredSelect
						variant="standard"
						fullWidth
						selectColor={textColor}
						value={formsInfo.userPropertyId}
						onChange={action((evt) => {
							formsInfo.userPropertyId = evt.target.value as number
							propertyNode.name = `_${evt.target.value}`
						})}
					>
						<MenuItem disabled value={0}>
							<Typography fontStyle="italic">User Property</Typography>
						</MenuItem>
						{userProperties.map((property) => (
							<MenuItem key={property.id} value={property.id}>
								{property.name}
							</MenuItem>
						))}
					</ColoredSelect>
				</ColoredFormControl>
			</Box>
		)
	},
)

export default UserPropertyComponent
