import { NIL, v4 as uuidv4 } from 'uuid'
import { EventBus } from '../../FormBuilderCore/eventBus/EventBus'
import { changeEventEmitter } from '../../FormBuilderInterop/EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../FormBuilderInterop/EventBus/BuiltInEvents/FocusEvent'
import { IFormHost } from '../../FormHost/FormHost/IFormHost'
import { CellType } from '../../FormHost/Types/CellType'
import { isValueType } from '../../FormHost/Types/FieldType'
import { IValueHtmlCellInstance } from '../htmlFormHost/Types/HtmlCellInstance'
import {
	IPDFAdapter,
	PDFBooleanInputAdapter,
	PDFInputAdapter, PDFVirtualRadioAdapter
} from './PdfAdapters'
import { PdfCellDefinition } from './Types/PdfCellDefinition'
import { PdfCellInstance } from './Types/PdfCellInstance'

export class PdfFormManager implements IFormHost {
	public formId = 0

	public cellDefinitions: PdfCellDefinition[] = []
	public cellInstances: PdfCellInstance[] = []

	public eventBus: EventBus

	private _document: Document

	constructor(
		definitions: PdfCellDefinition[],
		instances: PdfCellInstance[],
		eventBus: EventBus,
		document: Document,
	) {
		this.cellDefinitions = definitions
		this.cellInstances = instances
		this.eventBus = eventBus

		this._document = document

		const htmlElement = this._document.getElementsByTagName('html')[0]
		this.addEventListeners(htmlElement)

		this.cellInstances = generateInstances(definitions, instances)
	}

	dispose() {
		return undefined
	 }
	
	private addEventListeners(htmlElement: HTMLElement) {
		htmlElement.addEventListener('input', (evt) => {
			const target = evt.target as HTMLElement
			const instance = this.cellInstances
				.filter((v) => isValueType(v.fieldType))
				.find((v) => v.id === target.id)
			if (instance === undefined) return

			const valueInstance = instance as IValueHtmlCellInstance

			for (const adapter of adapters) {
				if (!adapter.canRead(instance, this._document)) continue

				const value = adapter.read(instance, this._document)

				if (value !== valueInstance.value) {
					changeEventEmitter(
						this.eventBus,
						{
							oldValue: valueInstance.value,
							newValue: value,
						},
						{
							formHost: this,
							elementTag: NIL,
							definitionId: valueInstance.definitionId,
							instanceId: valueInstance.id,
						},
					)

					valueInstance.value = value
				}

				// we don't want to check the other adapters
				break
			}
		})

		htmlElement.addEventListener('focusout', (evt) => {
			const target = evt.target as HTMLElement
			const instance = this.cellInstances
				.find((v) => v.id === target.id)
			if (instance === undefined) return

			focusEventEmitter(
				this.eventBus,
				{
					focusType: 'FocusLost',
				},
				{
					formHost: this,
					elementTag: NIL,
					definitionId: instance.definitionId,
					instanceId: instance.id,
				},
			)
		})

	}

	/**
	 * set the cell instance values to the cell instance values we get from the saved form package (if it exists)
	 * @param document 
	 * @param instances 
	 */
	public setSavedInstanceValues() {

		for (const instance of this.cellInstances) {
			if (instance.type !== CellType.Value) continue
			if (instance.value === '') continue

			for (const adapter of adapters) {
				if (!adapter.canRead(instance, this._document)) continue

				adapter.write(instance, this._document, instance.value)
			}
		}
	}
}

/**
 * generate instances based on definitions
 * PDF instances and definitions are 1:1 since there are no repeatable sections
 * @param definitions 
 * @returns 
 */
const generateInstances = (
	definitions: PdfCellDefinition[],
	instances?: PdfCellInstance[]
): PdfCellInstance[] => {
	const cellInstances: PdfCellInstance[] = []
	
	for (const definition of definitions) {
		const existingInstance = instances?.find(v => v.definitionId === definition.id)

		// if we have an instance for this definition there is no need to create a whole new one
		if (existingInstance !== undefined) {
			cellInstances.push(existingInstance)
			continue
		}

		cellInstances.push({
			id: uuidv4(),
			definitionId: definition.id,
			elementTag: definition.elementTag,
			fieldType: definition.fieldType,
			name: definition.name,
			parentId: definition.parentId,
			properties: definition.properties,
			value: '',
			type: CellType.Value,
		})
	}

	return cellInstances
}

export const adapters: Readonly<IPDFAdapter[]> = [
	new PDFVirtualRadioAdapter(),
	new PDFBooleanInputAdapter(),
	new PDFInputAdapter(),
]
