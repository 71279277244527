import { createContext, PropsWithChildren, useContext } from 'react'
import { Portal, PortalVersion } from '../../../../../api/DTOtemp'

const portalPageContext = createContext<PortalPageContext | undefined>(
	undefined,
)

export type PortalPageContextProps = {
	data: PortalPageContext
}

export type PortalPageContext = {
	version: PortalVersion
	portal: Portal
}

export const PortalPageContextProvider = (
	props: PropsWithChildren<PortalPageContextProps>,
) => {
	return (
		<portalPageContext.Provider value={props.data}>
			{props.children}
		</portalPageContext.Provider>
	)
}

export function usePortalContext() {
	const contextData = useContext(portalPageContext)
	if (contextData === undefined)
		throw Error('Portal page context has not been initialized')
	return contextData
}
