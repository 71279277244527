import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Node } from 'estree'
import { observer } from 'mobx-react'
import { PropsWithChildren } from 'react'
import { useDrag } from 'react-dnd'
import { PartiallyConstructedNode } from '../Types'

type DraggableComponentProps = {
	node: PartiallyConstructedNode<Node>
}

export const DraggableComponent = observer(
	(props: PropsWithChildren<DraggableComponentProps>) => {
		const styles = useStyles()

		const [, drag] = useDrag({
			type: props.node.formsInfo?.type ?? props.node.type,
			collect: (monitor) => ({
				isDragging: !!monitor.isDragging(),
			}),

			item: props.node,
		})

		return (
			<Box className={styles.draggableComponent} ref={drag}>
				{props.children}
			</Box>
		)
	},
)

const useStyles = makeStyles(() => ({
	draggableComponent: {
		cursor: 'move',
	},
}))
