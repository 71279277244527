import { Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ComponentType, ReactNode } from 'react'

export type TypedEditor<T> = {
	value: T | undefined
	hint?: ReactNode
	hintColor?: string
	onChange(value: T): void
}

type PropertySchematicConfiguratorProps<
	TValue,
	TProps extends TypedEditor<TValue>,
> = {
	value: TValue
	onValueChanged(value: TValue): void
	Editor: ComponentType<TProps>
	label?: string
	hint?: ReactNode
	hintColor?: string
}

export const PropertySchematicConfigurator = <
	TValue,
	TProps extends TypedEditor<TValue>,
>({
	value,
	onValueChanged,
	Editor,
	label,
	hint,
	hintColor,
}: PropertySchematicConfiguratorProps<TValue, TProps>) => {
	const styles = useStyles()

	return (
		<div>
			<div className={styles.header}>
				{label && (
					<Typography variant="subtitle1" color="textPrimary">
						{label}
					</Typography>
				)}
			</div>

			{/*@ts-expect-error we have all the props we need */}
			<Editor
				value={value}
				onChange={(v) => onValueChanged(v)}
				hint={hint}
				hintColor={hintColor}
			/>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
	},

	typePickerSection: {
		marginLeft: 'auto',
		display: 'flex',
		alignItems: 'center',
	},

	nameSpacing: {
		marginRight: theme.spacing(1),
	},
}))
