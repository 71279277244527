import { Program } from 'estree'
import { observable } from 'mobx'
import { createContext, PropsWithChildren, useContext } from 'react'
import { WithFormsInfo } from './Types'

type ProgramContextType = {
	programs: WithFormsInfo<Program>[]
}

const ProgramContext = createContext<ProgramContextType | undefined>(undefined)

export const ProgramContextProvider = (
	props: PropsWithChildren<ProgramContextType>,
) => {
	return (
		<ProgramContext.Provider
			value={{
				programs: observable(props.programs),
			}}
		>
			{props.children}
		</ProgramContext.Provider>
	)
}

export function useProgramContext() {
	const context = useContext(ProgramContext)
	if (context === undefined) throw new Error('Program context was undefined')

	return context
}
