import makeStyles from '@mui/styles/makeStyles';
import { percent } from "csx"
import React, { useMemo } from "react"

type OverlayProps = {
	color: string
}

export const DragAndDropOverlay = (props: OverlayProps) => {
	const styles = useStyles()

	const targetStyle = useMemo(() => ({ background: props.color }), [props.color])

	return (
		<div
			className={styles.overlayStyle}
			style={targetStyle}
		/>
	)
}

const useStyles = makeStyles({
	overlayStyle: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: percent(100),
		width: percent(100),
		zIndex: 1,
		opacity: 0.5
	}
})