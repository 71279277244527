import { Edit } from '@mui/icons-material'
import {
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useState } from 'react'
import {
	UserPropertiesClient,
	UserPropertyAssociationsClient,
} from '../../../../../../api/clients/identity'
import { BaseUser } from '../../../../../../api/DTO'
import {
	UserProperty,
	UserPropertyAssociation,
} from '../../../../../../api/DTOtemp'
import { EditValuePopover } from '../../../UserProperties/EditValuePopover'

type UserPropertiesSectionProps = {
	user: BaseUser
}

export const UserPropertiesSection = observer(
	(props: UserPropertiesSectionProps) => {
		const styles = useStyles()

		const propertiesClient = new UserPropertiesClient()
		const associationsClient = new UserPropertyAssociationsClient()

		const store = useLocalObservable(() => ({
			properties: [] as UserProperty[],
			associations: [] as UserPropertyAssociation[],
		}))

		const handleUpdateValue = (associationId: number, newValue: string) => {
			associationsClient
				.UpdateUserPropAssociation(associationId, newValue)
				.then((response) => {
					const association = store.associations.find(
						(v) => v.id === response.data.id,
					)

					if (association !== undefined) association.propertyValue = newValue
				})
		}

		const handleAddAssociation = (propertyId: number, newValue: string) => {
			associationsClient
				.AddUserPropAssociation({
					userId: props.user.id,
					propertyId: propertyId,
					value: newValue,
				})
				.then(
					action((response) => {
						const updatedAssociations: UserPropertyAssociation[] = []

						for (const association of store.associations)
							updatedAssociations.push(association)

						updatedAssociations.push(response.data)

						store.associations = updatedAssociations
					}),
				)
		}

		const handleDeleteAssociation = (associationId: number) => {
			associationsClient.DeleteUserPropAssociation(associationId).then(
				action(() => {
					const associationIndex = store.associations.findIndex(
						(v) => v.id === associationId,
					)

					if (associationIndex < 0) return

					const updatedAssociations: UserPropertyAssociation[] = []

					for (const association of store.associations)
						updatedAssociations.push(association)

					updatedAssociations.splice(associationIndex, 1)
					store.associations = updatedAssociations
				}),
			)
		}

		useEffect(() => {
			propertiesClient.GetUserProperties(false).then(
				action((response) => {
					store.properties = response.data
				}),
			)

			associationsClient.GetUserPropAssociations(false, props.user.id).then(
				action((response) => {
					store.associations = response.data
				}),
			)
		}, [])

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Typography
						className={styles.topBottomContent}
						variant="h6"
						color="textPrimary"
					>
						User Properties
					</Typography>
					<Divider />
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Property</TableCell>
									<TableCell>Value</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{store.properties.map((property) => {
									const association = store.associations.find(
										(v) => v.propertyId === property.id,
									)

									return (
										<PropertyTableRow
											key={property.id}
											association={association}
											property={property}
											onUpdateValue={handleUpdateValue}
											onAddAssociation={handleAddAssociation}
											onDeleteAssociation={handleDeleteAssociation}
										/>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Fade>
		)
	},
)

type PropertyTableRowProps = {
	association?: UserPropertyAssociation
	property: UserProperty
	onUpdateValue: (associationId: number, newValue: string) => void
	onAddAssociation: (propertyId: number, value: string) => void
	onDeleteAssociation: (associationId: number) => void
}

const PropertyTableRow = observer((props: PropertyTableRowProps) => {
	const styles = useStyles()
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [showEdit, setShowEdit] = useState(false)

	const handleMouseEnterValue = () => {
		setShowEdit(true)
	}

	const handleMouseLeaveValue = () => {
		setShowEdit(false)
	}

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleSavePopoverValue = (newValue: string) => {
		if (props.association !== undefined) {
			if (newValue === '') props.onDeleteAssociation(props.association.id)
			else if (props.association.propertyValue !== newValue)
				props.onUpdateValue(props.association.id, newValue) // avoid unnecessary api call if we're not really updating anything
		} else {
			props.onAddAssociation(props.property.id, newValue)
		}
	}

	return (
		<TableRow>
			<TableCell>{props.property.name}</TableCell>
			<TableCell>
				<div
					onMouseEnter={handleMouseEnterValue}
					onMouseLeave={handleMouseLeaveValue}
					className={styles.valueCell}
				>
					{props.association?.propertyValue ?? '-'}
					<IconButton
						size="small"
						onClick={handlePopoverOpen}
						className={clsx(showEdit === false && styles.hideEditIcon)}
					>
						<Edit fontSize="inherit" />
					</IconButton>
				</div>
			</TableCell>
			<EditValuePopover
				anchorEl={anchorEl}
				originalValue={props.association?.propertyValue ?? ''}
				onPopoverClose={handlePopoverClose}
				onSaveValue={handleSavePopoverValue}
			/>
		</TableRow>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		overflow: 'hidden',
		width: percent(100),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	valueCell: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},

	hideEditIcon: {
		visibility: 'hidden',
	},
}))
