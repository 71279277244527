import { CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useCallback, useEffect, useState } from 'react'
import { DetailForm } from '../../../../../../api/DTOtemp'
import { FormsClient } from '../../../../../../api/clients/identity'
import { FormPage } from './FormPage'

type FormsPageProps = {
	packageId: number
	packageVersionNumber: number
	formId: number
}

const FormsPage = (props: FormsPageProps) => {
	const styles = useStyles()

	const [formInfo, setFormInfo] = useState<DetailForm | undefined>(undefined)

	useEffect(() => {
		const formsApi = new FormsClient(
			props.packageId,
			props.packageVersionNumber,
		)

		const abortController = new AbortController()

		formsApi.GetForm(props.formId, abortController.signal).then((v) => {
			const formInfo = v.data
			setFormInfo(formInfo)
		})

		return () => {
			abortController.abort()
		}
	}, [props.packageId, props.formId])

	console.log('get forms page content type: ', formInfo?.contentType)

	const handleUpdateFormInfo = (newForm: DetailForm) => {
		setFormInfo(newForm)
	}

	const getFormType = useCallback((formInfo: DetailForm) => {
		switch (formInfo?.contentType) {
			case 'text/html': {
				return 'HTML'
			}
			case 'application/pdf': {
				return 'PDF'
			}
			case 'application/json': {
				return 'FB'
			}
			default:
				throw new Error(
					`content type ${formInfo?.contentType} is not supported`,
				)
		}
	}, [])

	const GetRequiredPage = () => {
		if (formInfo === undefined) return <> </>

		return (
			<FormPage
				packageVersionNumber={props.packageVersionNumber}
				packageId={props.packageId}
				form={formInfo}
				formType={getFormType(formInfo)}
				onUpdateForm={handleUpdateFormInfo}
			/>
		)
	}

	return (
		<>
			{formInfo === undefined ? (
				<div className={styles.loadingRoot}>
					<CircularProgress />
				</div>
			) : (
				GetRequiredPage()
			)}
		</>
	)
}

const useStyles = makeStyles((theme) => ({
	loadingRoot: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		height: percent(100),
		width: percent(100),
	},
}))

export default FormsPage
