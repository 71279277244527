import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { UserPropertiesPage } from './UserPropertiesPage'
import { UserPropertyPage } from './UserPropertyPage'

export const UserPropertiesPageRouter = () => {
	const { path } = useRouteMatch()

	updateBreadcrumbs(`/_administration/_user-properties`, 'User Properties')

	return (
		<Switch>
			<Route path={makeChildRoute(':propertyId', path)}>
				<UserPropertyPage />
			</Route>
			<Route path={makeChildRoute('', path)}>
				<UserPropertiesPage />
			</Route>
		</Switch>
	)
}
