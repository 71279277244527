import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import {
	AssignmentExpression,
	AssignmentOperator,
	Expression,
	Pattern,
} from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent, ValidNodeTypes } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'
import { MemberExpressions } from './MemberExpressionComponent'

const leftAssignmentOptions: ValidNodeTypes[] = [
	Syntax.Identifier,
	Syntax.ObjectPattern,
	Syntax.ArrayPattern,
	Syntax.RestElement,
	Syntax.AssignmentPattern,
	'FormFieldAccess',
]

const rightAssignmentOptions: ValidNodeTypes[] = [
	...leftAssignmentOptions,
	Syntax.Literal,
	...MemberExpressions,
	Syntax.MemberExpression,
	Syntax.BinaryExpression,
	Syntax.LogicalExpression,
]

const AssignmentExpressionComponent = observer(
	(props: RendererComponent<AssignmentExpression>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Variables)

		return (
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				bgcolor={color}
				gap={2}
				padding={1}
				width="max-content"
			>
				<Box component={Typography} color={textColor} paddingLeft={1}>
					Set
				</Box>
				<Box>
					<Renderer
						dropMode={DropMode.Replace}
						validNodes={leftAssignmentOptions}
						getter={() => props.node.left}
						setter={action((node) => (props.node.left = node as Pattern))}
					/>
				</Box>
				<Box>
					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							selectColor={textColor}
							variant="standard"
							size="small"
							value={props.node.operator}
							onChange={action((event: SelectChangeEvent<unknown>) => {
								props.node.operator = event.target.value as AssignmentOperator
							})}
						>
							<MenuItem value="=">
								<Typography fontStyle="italic">equal to</Typography>
							</MenuItem>
							<MenuItem value="+=">
								<Typography fontStyle="italic">
									equal to the first value plus
								</Typography>
							</MenuItem>
							<MenuItem value="-=">
								<Typography fontStyle="italic">
									equal to the first value minus
								</Typography>
							</MenuItem>
							<MenuItem value="*=">
								<Typography fontStyle="italic">
									equal to the first value multiplied by
								</Typography>
							</MenuItem>
							<MenuItem value="/=">
								<Typography fontStyle="italic">
									equal to the first value divided by
								</Typography>
							</MenuItem>
							<MenuItem value="%=">
								<Typography fontStyle="italic">
									equal to the modulus of the first value
								</Typography>
							</MenuItem>
						</ColoredSelect>
					</ColoredFormControl>
				</Box>
				<Renderer
					dropMode={DropMode.Replace}
					getter={() => props.node.right}
					setter={action((node) => (props.node.right = node as Expression))}
					validNodes={rightAssignmentOptions}
				/>
			</Box>
		)
	},
)

export default AssignmentExpressionComponent
