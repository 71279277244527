import { Box, Button, Fade, Paper, TextField } from '@mui/material'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import {
	BaseSecretModel,
	Secret,
	SecretType,
} from '../../../../api/clients/vaultStorage/DTO'
import SecretsClient from '../../../../api/clients/vaultStorage/SecretsClient'
import VaultStorageClient from '../../../../api/clients/vaultStorage/VaultStorageClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../../../../modules/FormBuilderInterop/Runtimes/Standalone'
import { toastService } from '../../../../services/notifications/ToastService'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'
import { compareState } from '../../../../utils/compareState'
import AdministrationPageContainer from '../AdministrationPageContainer'

export const SecretPage = observer(() => {
	const params = useParams<{ secretId: string }>()
	const secretId = parseInt(params.secretId)

	if (isNaN(secretId)) throw new Error('invalid secret id')

	const localStore = useLocalObservable(() => ({
		secret: undefined as Secret | undefined,
		secretType: undefined as SecretType | undefined,
		secretState: {} as Record<string, unknown>,
		secretName: '',
		secretDescription: '',
		nameIsDuplicate: false,
		existingSecretNames: [] as string[],
	}))

	const vaultStorageClient = new VaultStorageClient()
	const secretsClient = new SecretsClient()

	const fetchSecret = async () => {
		const { data: secret } = await secretsClient.getSecret(secretId)
		const { data: secretType } = await vaultStorageClient.getSecretType(
			secret.vaultId,
			secret.secretTypeId,
		)

		runInAction(() => {
			localStore.secret = secret
			localStore.secretType = secretType
			localStore.secretState = secret.state as Record<string, unknown>
			localStore.secretName = secret.name
			localStore.secretDescription = secret.description
		})
	}

	const fetchExistingSecretNames = async () => {
		const { data: secrets } = await secretsClient.getSecretMetadata()

		runInAction(() => {
			localStore.existingSecretNames = secrets
				.filter((v) => v.id !== secretId)
				.map((v) => v.name)
		})
	}

	useEffect(() => {
		fetchSecret()
		fetchExistingSecretNames()
	}, [])

	const cellManager = useMemo(() => {
		if (localStore.secret === undefined || localStore.secretType === undefined)
			return undefined

		return new StandaloneCellManager(
			localStore.secretType.formBuilderSchema,
			localStore.secretState,
		)
	}, [localStore.secret, localStore.secretType, localStore.secretState])

	const handleUpdateSecret = async () => {
		try {
			const { data: updatedSecret } = await secretsClient.updateSecret(
				secretId,
				{
					name: localStore.secretName,
					description: localStore.secretDescription,
					state: localStore.secretState,
					vaultId: localStore.secret?.vaultId,
					secretTypeId: localStore.secret?.secretTypeId,
				} as BaseSecretModel,
			)

			toastService.displayToast({
				message: 'Vault updated',
				area: 'global',
				severity: 'success',
			})

			runInAction(() => {
				localStore.secret = updatedSecret
			})
		} catch (e) {
			console.error(e)

			toastService.displayToast({
				message: 'Error updating vault',
				area: 'global',
				severity: 'error',
			})
		}
	}

	const isSaveDisabled = useCallback((): boolean => {
		if (
			localStore.secret === undefined ||
			localStore.secret.state === undefined ||
			localStore.nameIsDuplicate
		)
			return true

		if (!compareState(localStore.secret.state, localStore.secretState))
			return false

		return (
			localStore.secretName === localStore.secret.name &&
			localStore.secretDescription === localStore.secretDescription
		)
	}, [
		localStore.secret,
		localStore.nameIsDuplicate,
		localStore.secretState,
		localStore.secretName,
		localStore.secretDescription,
	])

	const checkNameAlreadyExists = useCallback(
		action(() => {
			const isDuplicate = localStore.existingSecretNames
				.map((v) => v.toUpperCase().trim())
				.includes(localStore.secretName.toUpperCase().trim())

			localStore.nameIsDuplicate = isDuplicate
		}),
		[localStore.existingSecretNames, localStore.secretName],
	)

	if (localStore.secret === undefined) return <FullscreenSpinner />

	return (
		<AdministrationPageContainer title={localStore.secret.name}>
			<Fade in>
				<Box
					component={Paper}
					width="100%"
					marginX={-2}
					marginY={2}
					paddingX={2}
				>
					<Box display="flex" flexDirection="column" gap={1}>
						<FixedHeaderWithHint
							label="Name"
							hint={
								localStore.nameIsDuplicate &&
								'A secret with this name already exists'
							}
							hintColor={localStore.nameIsDuplicate ? 'error.main' : ''}
						>
							<TextField
								fullWidth
								size="small"
								value={localStore.secretName}
								onChange={action(
									(evt) => (localStore.secretName = evt.target.value),
								)}
								onBlur={checkNameAlreadyExists}
							/>
						</FixedHeaderWithHint>
						<FixedHeader label="Description">
							<TextField
								fullWidth
								size="small"
								value={localStore.secretDescription}
								onChange={action(
									(evt) => (localStore.secretDescription = evt.target.value),
								)}
							/>
						</FixedHeader>
						{cellManager !== undefined && (
							<FormBuilderViewer cellManager={cellManager} />
						)}
					</Box>
					<Box display="flex" justifyContent="flex-end" paddingY={2}>
						<Button
							variant="contained"
							disabled={isSaveDisabled()}
							onClick={handleUpdateSecret}
						>
							Save Changes
						</Button>
					</Box>
				</Box>
			</Fade>
		</AdministrationPageContainer>
	)
})
