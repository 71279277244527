import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import { FieldMapping } from '../../../../../../../../api/DTOtemp'

type FieldMappingOptionSectionProps = {
	fieldMapping: FieldMapping
	onUpdateFieldMappingInfo: (name: string, description: string) => void
}

export const FieldMappingOptionSection = observer(
	(props: FieldMappingOptionSectionProps) => {
		const styles = useStyles()

		const validationSchema = yup.object({
			name: yup.string().required(),
			description: yup.string(),
		})

		const formik = useFormik({
			initialValues: {
				name: props.fieldMapping.name,
				description: props.fieldMapping.description,
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				props.onUpdateFieldMappingInfo(values.name, values.description)
			},
		})

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Typography
						className={styles.topBottomContent}
						variant="h6"
						color="textPrimary"
					>
						Settings
					</Typography>
					<Divider />
					<div className={styles.formSettingsRoot}>
						<div className={styles.formElement}>
							<TextField
								id="name"
								name="name"
								label="Name"
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={!!formik.errors.name && formik.touched.name}
								helperText={formik.touched.name && formik.errors.name}
								fullWidth
								autoComplete="off"
							/>
						</div>
						<div className={styles.formElement}>
							<TextField
								id="description"
								name="description"
								label="Description"
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									!!formik.errors.description && formik.touched.description
								}
								helperText={
									formik.touched.description && formik.errors.description
								}
								fullWidth
								autoComplete="off"
							/>
						</div>
					</div>
					<div>
						<Divider />
						<div
							className={clsx(styles.topBottomContent, styles.bottomFlexbox)}
						>
							<Button
								color="primary"
								variant="contained"
								className={styles.noTextTransform}
								disabled={!formik.isValid}
								onClick={formik.submitForm}
							>
								Save Changes
							</Button>
						</div>
					</div>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},
}))
