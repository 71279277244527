
import { AxiosResponse } from "axios"
import { AdminRegisteredUsersModel, BaseOrganization, BaseUser, CreateUsers, SignedInUser } from "../../DTO"
import { ChangePasswordData } from "../../DTOtemp"
import SecureClient from '../SecureClient'

class UserClient extends SecureClient {

	private _userEmail: string

	constructor() {
		super()
		
		this._userEmail = this._sessionService.authToken.email ?? ''
	}

	/**
	 * Register one or many new users and associate them with this organization
	 * @param createUser 
	 */
	public AdminRegister = ( createUser: CreateUsers):
		Promise<AxiosResponse<AdminRegisteredUsersModel[]>> => {
		return this._api.post(`/Users/AdminRegister`,
			JSON.stringify(createUser),
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
					...this.authorizationHeader
				}
			}
		)
	}

	/**
	 * Changes user password
	 * @param changePassword 
	 * @param userEmail 
	 */
	public ChangePassword(changePassword: ChangePasswordData)
		: Promise<AxiosResponse> {
		return this._api.post(
			`Users/${this._userEmail}/ChangePassword`,
			JSON.stringify(changePassword),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Get detailed information about the signed in user
	 */
	public MyInfo():
		Promise<AxiosResponse<SignedInUser>> {
		return this._api.get<SignedInUser>('/Users/Me', {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			}
		})
	}

	/**
	 * Update the currently signed in user
	 * @param user 
	 */
	public UpdateUser(user: BaseUser)
		: Promise<AxiosResponse> {
		return this._api.put(
			`Users/Me`,
			JSON.stringify(user),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Delete the currently signed in user
	 */
	public Delete():
		Promise<AxiosResponse> {
		return this._api.delete('/users/me', {
			headers: {
				...this.authorizationHeader
			}
		})
	}	

	/**
	 * Fetch all organizations the currently signed in user belongs to
	 */
	public getOrganizations():
		Promise<AxiosResponse<BaseOrganization[]>> {
		return this._api.get<BaseOrganization[]>(`/users/${this._sessionService.authToken.id}/organizations`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})
	}

	/**
	 * TODO update with img return type
	 * returns the profile picture of the specified user
	 */
	public GetProfilePicture(userId: number)
		: Promise<AxiosResponse<Blob>> {
		return this._api.get(
			`Users/${userId}/ProfilePicture`,
			{
				responseType: 'blob',
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader('image/*')
				},
				validateStatus: () => true
			}
		)
	}

	/**
	 * TODO update with img obj
	 * Updates the profile picture of the specified user to the specified image
	 * @param userId 
	 */
	public UpdateProfilePicture(userId: number, image: FormData)
		: Promise<AxiosResponse> {
				
		return this._api.put(
			`Users/${userId}/ProfilePicture`,
			image,
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader('multipart/form-data'),
				}
			}
		)
	}

	/**
	 * Removes the profile picture of the specified user
	 * @param userId 
	 */
	public DeleteProfilePicture(userId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`Users/${userId}/ProfilePicture`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}
	
}

export default UserClient