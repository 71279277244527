import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { isObservable, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { ReactElement, useEffect, useMemo } from 'react'
import { IFormBuilderFormHost } from '../../../FormBuilderCore/IFormBuilderFormHost'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { renderCells } from '../../../FormBuilderCore/cells/rendering/cellRenderer'
import {
	FormBuilderContextProvider,
	useFormBuilderContext,
} from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { EventBus } from '../../../FormBuilderCore/eventBus/EventBus'
import { getGlobalComponentRegistry } from '../../ComponentRegistry/GlobalComponentRegistry'
import { StandaloneCellManager } from './StandaloneCellManager'

type SimpleViewerProps = {
	schema: FormBuilderSchema
	valueObject?: object
}

type StandaloneViewerProps = {
	cellManager: IFormBuilderFormHost
}

export const SimpleFormBuilderViewer = ({
	schema,
	valueObject,
}: SimpleViewerProps) => {
	const cellManager = useMemo(
		() => new StandaloneCellManager(schema, valueObject, false, new EventBus()),
		[],
	)

	return (
		<FormBuilderContextProvider cellManager={cellManager}>
			<FormBuilderViewerHost />
		</FormBuilderContextProvider>
	)
}

export const FormBuilderViewer = (props: StandaloneViewerProps) => {
	console.log('standalone viewer re-rendering')
	return (
		<FormBuilderContextProvider cellManager={props.cellManager}>
			<FormBuilderViewerHost />
		</FormBuilderContextProvider>
	)
}

const FormBuilderViewerHost = () => {
	const { componentCollection } = getGlobalComponentRegistry()
	const formBuilderContext = useFormBuilderContext()
	if (!isObservable(formBuilderContext))
		throw new Error('provided form builder context was not observable')

	// TODO: add this to the cell manager!
	const regenerateCellInstances = () => {
		console.log('recomputing cell instances')

		const internalElements = renderCells(
			{
				formHost: formBuilderContext.cellManager,
				componentRegistry: componentCollection,
			},
			formBuilderContext.cellManager.rootNode.children,
		)

		return internalElements
	}

	const styles = useStyles()

	/*
	we only rebuild this if the underlying context has changed, since that means that
	there's a super high chance that we've changed the underlying form builder

	maybe we don't need such a complicated structure for storing our elements, but tbh
	I really don't feel like investing the effort to push this any further.
	It's not fragile, it'll always work, it's just... annoying...
	*/
	const elementRef = useMemo<ElementCollectionRef>(
		() =>
			observable({
				elements: regenerateCellInstances(),
			}),
		[formBuilderContext],
	)

	/**
	 * the useEffect will only regenerate a disposer if the elementRef has been rebuilt
	 * since otherwise elementRef would be stuck in a closure and that's just no fun...
	 *
	 * We don't need to worry about firing immediately since the elementRef gets initially
	 * populated when it's built
	 */
	useEffect(() => {
		const disposer = reaction(
			() => formBuilderContext.cellManager.cellInstances,
			() => {
				elementRef.elements = regenerateCellInstances()
			},
			{
				fireImmediately: false,
			},
		)
		return disposer
	}, [elementRef])

	return (
		<div
			className={styles.layoutStyle}
			id={`form-${formBuilderContext.cellManager.formId}`}
		>
			<HiddenInnerElementList eRef={elementRef} />
		</div>
	)
}

type ElementCollectionRef = {
	elements: ReactElement[]
}

/**
 * this thing sticks down here and traps any changes to our elementRef,
 * since we don't want our parent component to completely regenerate 100%
 * of the time this should suffice
 */
const HiddenInnerElementList = observer(
	(props: { eRef: ElementCollectionRef }) => {
		const styles = useStyles()

		return (
			<>
				{props.eRef.elements.map((v) => (
					<div className={styles.innerDivElement} key={v.key}>
						{' '}
						{v}{' '}
					</div>
				))}
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	layoutStyle: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),

		height: 'fit-content',
		width: percent(100),

		overflowY: 'auto',

		// to give our form image the right background color
		backgroundColor: theme.palette.background.paper,
	},
	innerDivElement: {
		display: 'flex',
	},
}))
