import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { CommitIntent } from '../../api/DTOtemp'

type PackageDescriptionDialogProps = {
	intent: CommitIntent.Save | CommitIntent.Approve | CommitIntent.Reject
	initialDescription: string
	onConfirm: (newDescription: string) => void
	onCancel: () => void
}

export const PackageDescriptionDialog = (
	props: PackageDescriptionDialogProps,
) => {
	const [description, setDescription] = useState(props.initialDescription)
	const [error, setError] = useState(false)

	const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setDescription(evt.target.value)

		// description is required for saving a package
		setError(props.intent === CommitIntent.Save && evt.target.value === '')
	}

	const actionText = useMemo(() => {
		switch (props.intent) {
			case CommitIntent.Save:
				return 'Save'
			case CommitIntent.Approve:
				return 'Approve'
			case CommitIntent.Reject:
				return 'Reject'
			default:
				throw new Error(
					`Description for commit intent ${props.intent} not supported`,
				)
		}
	}, [props.intent])

	return (
		<>
			<DialogTitle>{actionText} Package</DialogTitle>
			<DialogContent>
				<Typography>Enter a description for this package.</Typography>
				<TextField
					label="Description"
					value={description}
					onChange={handleChange}
					fullWidth
					sx={{
						margin: (theme) => theme.spacing(2, 0),
					}}
					error={error}
					helperText={error ? 'Description is required' : ' '}
					autoComplete="off"
				/>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => props.onConfirm(description)}
					disabled={error}
				>
					{actionText}
				</Button>
			</DialogActions>
		</>
	)
}
