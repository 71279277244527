import { Tab, Tabs } from '@mui/material'

type AdministrationPageTabsProps = {
	currentTab: number
	tabLabels: string[]
	onTabChanged: (event: React.SyntheticEvent, newValue: number) => void
}

export const AdministrationPageTabs = ({
	currentTab,
	tabLabels,
	onTabChanged,
}: AdministrationPageTabsProps) => {
	return (
		<Tabs
			variant="scrollable"
			scrollButtons="auto"
			textColor="primary"
			indicatorColor="primary"
			value={currentTab}
			onChange={onTabChanged}
		>
			{tabLabels.map((label) => (
				<Tab label={label} key={label} />
			))}
		</Tabs>
	)
}
