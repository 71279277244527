import { Delete } from '@mui/icons-material'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Typography,
} from '@mui/material'
import { runInAction } from 'mobx'
import { observer, useLocalStore } from 'mobx-react'
import { useEffect } from 'react'
import { FormPackageInstancesClient } from '../../api/clients/identity'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { usePrompts } from '../../services/notifications/PromptService'
import { toastService } from '../../services/notifications/ToastService'
import {
	getRetrievalFormPackages,
	RetrievalPackage,
} from '../../services/offline/FormPackageHandling'
import { RetrievalStorageType } from '../../services/offline/FormPackageStorage'
import AdministrationPageContainer from '../Organization/Administration/AdministrationPageContainer'
import { updateBreadcrumbs } from '../Organization/Administration/Breadcrumbs'
import { useOfflinePackageContext } from '../PackageHostPage/PackageListPageContext'
import { PackageCard } from './PackageCard'
import { OfflineStatusChip } from './UserPackageListPage'

export const SavedPackageListPage = observer(() => {
	const offlinePackageContext = useOfflinePackageContext()

	const localStore = useLocalStore(() => ({
		packages: undefined as undefined | RetrievalPackage[],
	}))

	const promptService = usePrompts()

	updateBreadcrumbs(
		'/_form-packages/_saved-form-packages',
		'Saved Form Packages',
	)

	useEffect(() => {
		const abortController = new AbortController()

		getRetrievalFormPackages(
			RetrievalStorageType.Saved,
			offlinePackageContext.savedPackageList,
			abortController.signal,
		).then((v) => {
			runInAction(() => (localStore.packages = v))
		})

		return () => {
			abortController.abort()
		}
	}, [])

	const deleteSavedPackage = async (id: number) => {
		if (localStore.packages === undefined) return

		const instanceToDelete = localStore.packages.find(
			(v) => v.instanceId === id,
		)
		if (instanceToDelete === undefined) return

		const index = localStore.packages.indexOf(instanceToDelete)
		if (index < 0) return

		const instancesClient = new FormPackageInstancesClient()

		await instancesClient.deleteFormPackageInstance(id)

		runInAction(() => {
			localStore.packages!.splice(index, 1)
		})
		toastService.displayToast({
			message: 'Saved form package deleted',
			area: 'global',
		})
	}

	return (
		<AdministrationPageContainer title="Saved Form Packages">
			{localStore.packages === undefined ? (
				<FullscreenSpinner />
			) : (
				<Box
					display="flex"
					flexDirection="column"
					width="100%"
					height="100%"
					gap={1}
				>
					<Divider flexItem />

					<Box height="100%" width="100%" flex="1">
						{localStore.packages.map((v) => (
							<PackageCard
								key={v.instanceId}
								description={v.description}
								name={v.name}
								route={`/_package-host/${v.packageId}/_${v.storageType}/${v.uniqueId}`}
								chips={
									<>
										<OfflineStatusChip
											id={v.uniqueId}
											packageMap={offlinePackageContext.savedPackageList}
										/>
									</>
								}
								actions={
									<IconButton
										size="large"
										onClick={(e) => {
											e.stopPropagation()

											promptService
												.showDialog((props) => (
													<ConfirmDeleteSavedPackagePrompt
														packageName={v.name}
														onCancel={() => props.close('cancel')}
														onConfirm={() => props.close('okay')}
													/>
												))
												.then((response) => {
													if (response === 'okay')
														deleteSavedPackage(v.instanceId)
												})
										}}
									>
										<Delete />
									</IconButton>
								}
							/>
						))}
					</Box>
				</Box>
			)}
		</AdministrationPageContainer>
	)
})

type ConfirmDeleteSavedPackagePromptProps = {
	packageName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeleteSavedPackagePrompt = (
	props: ConfirmDeleteSavedPackagePromptProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete Saved Package</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete this saved version of{' '}
					<b>{props.packageName}</b>?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}
