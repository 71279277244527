import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropsFor } from '@mui/system'
import { PropsWithChildren, ReactNode } from 'react'

type FixedHeaderProps = {
	label?: ReactNode
	labelColor?: PropsFor<typeof Typography>['color']
	className?: string
}

export const FixedHeader = ({
	label,
	labelColor,
	className,
	children,
}: PropsWithChildren<FixedHeaderProps>) => {
	return (
		<div className={className}>
			<Typography variant="subtitle1" color={labelColor ?? 'textPrimary'}>
				{label ?? ''}
			</Typography>
			{children}
		</div>
	)
}

type FixedHeaderWithHintProps = {
	hint?: ReactNode
	hintColor?: PropsFor<typeof Typography>['color']
} & FixedHeaderProps

export const FixedHeaderWithHint = ({
	label,
	labelColor,
	hint,
	hintColor,
	className,
	children,
}: PropsWithChildren<FixedHeaderWithHintProps>) => {
	const styles = useStyles()

	return (
		<div className={className}>
			<Typography variant="subtitle1" color={labelColor ?? 'textPrimary'}>
				{label ?? ' '}
			</Typography>
			{children}
			<Typography
				className={styles.subtitle}
				variant="subtitle2"
				color={hintColor ?? 'textSecondary'}
				whiteSpace="pre-wrap"
			>
				{hint ?? ' '}
			</Typography>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	subtitle: {
		marginTop: theme.spacing(0.5),
	},
}))
