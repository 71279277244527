import { OfflineBolt, OpenInNew } from '@mui/icons-material'
import {
	Box,
	Chip,
	CircularProgress,
	Divider,
	IconButton,
	Typography,
} from '@mui/material'

import { runInAction } from 'mobx'
import { observer, useLocalStore } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FormPackageInfo } from '../../api/DTOtemp'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { getFormPackages } from '../../services/offline/FormPackageHandling'
import AdministrationPageContainer from '../Organization/Administration/AdministrationPageContainer'
import { updateBreadcrumbs } from '../Organization/Administration/Breadcrumbs'
import {
	OfflineStatus,
	PackageMetaInfo,
	useOfflinePackageContext,
} from '../PackageHostPage/PackageListPageContext'
import { PackageCard, PackageDescription } from './PackageCard'

export const UserPackageListPage = observer(() => {
	const offlinePackageContext = useOfflinePackageContext()

	updateBreadcrumbs(
		'/_form-packages/_allowed-form-packages',
		'My Form Packages',
	)

	const localStore = useLocalStore(() => ({
		packages: undefined as undefined | FormPackageInfo[],
	}))

	useEffect(() => {
		const abortController = new AbortController()

		getFormPackages(offlinePackageContext, abortController.signal).then((v) =>
			runInAction(() => (localStore.packages = v)),
		)

		return () => {
			abortController.abort()
		}
	}, [])

	return (
		<AdministrationPageContainer title="My Form Packages">
			{localStore.packages === undefined ? (
				<FullscreenSpinner />
			) : (
				<>
					<Box
						display="flex"
						flexDirection="column"
						width="100%"
						height="100%"
						gap={1}
					>
						<Divider flexItem />

						<Box height="100%" width="100%" flex="1">
							{localStore.packages.map((v) => (
								<PackageCard
									key={v.id}
									name={v.name}
									description={
										<PackageDescription
											description={
												v.description === '' ? 'No Description' : v.description
											}
											italic={v.description === ''}
										/>
									}
									route={`/_package-host/${v.id}`}
									chips={
										<>
											<OfflineStatusChip
												id={v.versionId.toString()}
												packageMap={offlinePackageContext.packageList}
											/>
										</>
									}
									actions={
										<>
											<IconButton
												size="large"
												LinkComponent="a"
												href={`/_package-host/${v.id}`}
												target="_blank"
												onClick={(e) => e.stopPropagation()} // prevent bubbling!
											>
												<OpenInNew />
											</IconButton>
										</>
									}
								/>
							))}
						</Box>
					</Box>
				</>
			)}
			{/* <UserPackageList packages={localStore.packages} /> */}
		</AdministrationPageContainer>
	)
})

type OfflineChipProps = {
	id: string
	packageMap: Map<string, PackageMetaInfo>
}

export const OfflineStatusChip = observer((props: OfflineChipProps) => {
	const trackingPackage = props.packageMap.get(props.id)

	if (trackingPackage === undefined) return <></>

	const status = trackingPackage.offlineStatus

	return (
		<Chip
			icon={
				status === OfflineStatus.Downloaded ||
				status === OfflineStatus.DownloadFailed ? (
					<OfflineBolt />
				) : (
					<Box>
						<CircularProgress />
					</Box>
				)
			}
			label={<Typography>Offline Status: {status}</Typography>}
		></Chip>
	)
})
