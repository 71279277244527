import { Box, MenuItem, Select, Typography } from '@mui/material'
import {
	WorkflowDefinitionBase,
	WorkflowStatus,
} from '../../../../../api/clients/workflows/DTOs'
import { MultiSelect } from '../../../../../components/selects/MultiSelect'
import { workflowStatuses } from '../../../../../modules/Workflows/WorkflowInstanceStatus'
import { WorkflowDateFilter } from './WorkflowDateFilter'
import { Filters, SortBy } from './WorkflowHistoryDashboard'

type WorkflowsHistoryFiltersProps = {
	filters: Filters
	onChange: (filters: Filters) => void
	definitions: WorkflowDefinitionBase[]
}

const getDefinitionOptions = (definitions: WorkflowDefinitionBase[]) => {
	return definitions.map((v) => ({
		label: v.version.toString(),
		value: v.id,
	}))
}

export const WorkflowsHistoryFilters = (
	props: WorkflowsHistoryFiltersProps,
) => {
	return (
		<Box display="flex" flexDirection="column">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				padding={1}
			>
				<Box display="flex" gap={2}>
					<MultiSelect
						label="Version Number"
						options={getDefinitionOptions(props.definitions).sort(
							(a, b) => b.value - a.value,
						)}
						value={props.filters.definitionId ?? []}
						onChange={(values) => {
							props.onChange({
								...props.filters,
								definitionId: values.map((v) => parseInt(v as string)) ?? [],
							})
						}}
					/>
					<MultiSelect
						label="Status"
						options={Object.keys(workflowStatuses).map((v) => ({
							label: workflowStatuses[v as unknown as WorkflowStatus]!.label,
							value: parseInt(v),
						}))}
						value={props.filters.status ?? []}
						onChange={(values) => {
							props.onChange({
								...props.filters,
								status: values.map((v) => parseInt(v as string)) ?? [],
							})
						}}
					/>
					<WorkflowDateFilter
						startDateFrom={props.filters.startDateFrom}
						startDateTo={props.filters.startDateTo}
						onChange={(startDateFrom, startDateTo) => {
							props.onChange({
								...props.filters,
								startDateFrom: startDateFrom,
								startDateTo: startDateTo,
							})
						}}
					/>
				</Box>
				<Box height="50%">
					<Select
						size="small"
						value={props.filters.sortBy}
						onChange={(evt) => {
							props.onChange({
								...props.filters,
								sortBy: evt.target.value as SortBy,
							})
						}}
						renderValue={(value) => (
							<Typography variant="body2">
								{value === SortBy.Newest ? 'Newest' : 'Oldest'}
							</Typography>
						)}
					>
						<MenuItem value={SortBy.Newest}>
							<Typography variant="body2">Newest</Typography>
						</MenuItem>
						<MenuItem value={SortBy.Oldest}>
							<Typography variant="body2">Oldest</Typography>
						</MenuItem>
					</Select>
				</Box>
			</Box>
		</Box>
	)
}
