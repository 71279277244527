import { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import { FormPackageInfo } from '../../../../../../api/DTOtemp'
import { FormPackageClient } from '../../../../../../api/clients/identity'
import { FullscreenSpinner } from '../../../../../../components/feedback/circular'
import { makeChildRoute } from '../../../../../../utils/CreateChildRoute'
import {
	FormPackagePageContextProps,
	FormPackagePageContextProvider,
} from '../FormPackagePage/FormPackagePageContext'
import FormPageFormBuilderEditor from './FormPageFormBuilderEditor'
import FormsPage from './FormsPage'

type FormsPageRouterProps = {
	packageId: number
}

const FormsPageRouter = (props: FormsPageRouterProps) => {
	const params = useParams<{ formId: string }>()

	const formId = parseInt(params.formId)

	if (isNaN(formId)) throw new Error('invalid form id')

	const [packageInfo, setPackageInfo] = useState<FormPackageInfo>()

	const { path } = useRouteMatch()

	useEffect(() => {
		/* so - i added this here and we're getting the package version here now instead of thru props.
			this is because whenever a new form is created, we want to route the user here to see form info, but
			the package version is updated and different from the one we get in the router. so this api
			call makes sure we get the most recent version -Caroline
		*/
		const packageApi = new FormPackageClient()
		packageApi.GetFormPackage(props.packageId).then((response) => {
			setPackageInfo(response.data)
		})
	}, [props.packageId, formId])

	const packageMemo: FormPackagePageContextProps | undefined = useMemo(() => {
		if (packageInfo == undefined) return undefined
		const x: FormPackagePageContextProps = {
			data: {
				formPackage: packageInfo,
			},
		}
		return x
	}, [packageInfo])

	if (packageInfo === undefined) return <FullscreenSpinner />

	return (
		// note that this is only here because the form builder
		// editor needs to know some information for a certain set
		// of components. All we need is formPackageInfo, not the
		// full context
		<FormPackagePageContextProvider {...packageMemo!}>
			<Switch>
				<Route path={makeChildRoute('_editor', path)}>
					<FormPageFormBuilderEditor
						packageId={props.packageId}
						packageVersionNumber={packageInfo.versionNumber}
						formId={formId}
					/>
				</Route>
				<Route>
					<FormsPage
						packageId={props.packageId}
						packageVersionNumber={packageInfo.versionNumber}
						formId={formId}
					/>
				</Route>
			</Switch>
		</FormPackagePageContextProvider>
	)
}

export default FormsPageRouter
