import { NIL } from "uuid"
import { CellType } from "../../../FormHost/Types/CellType"
import { isReferenceType } from "../../../FormHost/Types/FieldType"
import { createNullDefinition, FormBuilderCellDefinition } from "../FormBuilderCellDefinition"
import { ComponentRegistryDictionary, fetchContainerComponent } from "../GlobalComponentRegistry"

/**
 * normalize a definition array with null and ensure unnecessary ones are removed
 * . This ensures that the layouts are able to render
 * themselves correctly
 * @param components the components that will be used to pack the cells
 * @param scopedObject the object that will be used to provide state, should any be necessary
 * @param definitions the cell definitions that will be evaluated and packed
 * @param parentId the parent that this function is targeting, defaults to root
 */
export function normalizeDefinitionArray(components: ComponentRegistryDictionary,
    definitions: FormBuilderCellDefinition[],
    parentId: string = NIL) {

    // we only prune once
    definitions = prune(definitions)

    const normalizedDefinitions = normalizeDefinitionArrayInternal(components, definitions, parentId)
    return normalizedDefinitions
}

function normalizeDefinitionArrayInternal(components: ComponentRegistryDictionary,
    definitions: FormBuilderCellDefinition[],
    parentId: string = NIL) {

    const orderedCells = definitions.filter(v => v.parentId === parentId)
        .sort((a, b) => a.ordinalPosition - b.ordinalPosition)

    const targetIndex = getExpectedIndex(components,
        definitions,
        orderedCells,
        parentId)
    const largestIndex = getLargestIndex(orderedCells)

    // first, we make sure everything in the list has enough items
    for (let i = 0; i < targetIndex; i++) {
        const foundDefinition = orderedCells.find(v => v.ordinalPosition === i)
        if (foundDefinition === undefined) {
            definitions.push(createNullDefinition(parentId, i))
            continue
        }

        if (isReferenceType(foundDefinition.fieldType)) {

            if (foundDefinition.type !== CellType.Layout && foundDefinition.type !== CellType.Repeat)
                throw new Error('cell definition must be of layout or repeat type')

            normalizeDefinitionArrayInternal(components, definitions, foundDefinition.id)
        }
    }

    for (let i = targetIndex; i <= largestIndex; i++) {
        const foundDefinition = orderedCells.find(v => v.ordinalPosition === i)
        if (foundDefinition === undefined) {
            continue
        }
        const definitionIndex = definitions.indexOf(foundDefinition)
        definitions.splice(definitionIndex, 1)
    }

    return definitions
}

function getExpectedIndex(components: ComponentRegistryDictionary,
    definitions: FormBuilderCellDefinition[],
    orderedCells: FormBuilderCellDefinition[],
    parentId: string) {

    const currentParent = definitions.find(v => v.id === parentId)

    console.log(`got current parent type '${currentParent?.fieldType}'`)

    if (currentParent !== undefined && isReferenceType(currentParent.fieldType)) {
        const component = fetchContainerComponent(components, currentParent.elementTag)
        const evalObject = currentParent.properties;
        return component.requiredChildren(evalObject)
    }

    if (orderedCells.length === 0)
        return 0

    // add one because otherwise we won't add the last one in the for loop
    return orderedCells[orderedCells.length - 1].ordinalPosition + 1
}

function getLargestIndex(orderedCells: FormBuilderCellDefinition[]) {
    if (orderedCells.length === 0)
        return 0
    return orderedCells[orderedCells.length - 1].ordinalPosition
}

function prune(definitions: FormBuilderCellDefinition[]) {

    const localDefinitionCopy = definitions
    for (let i = 0; i < definitions.length; i++) {
        // item is at root, continue
        if (definitions[i].parentId === NIL)
            continue

        const parent = definitions.find(v => v.id === definitions[i].parentId)
        if (parent === undefined && localDefinitionCopy.includes(definitions[i])) {
            removeBranch(localDefinitionCopy, definitions[i].id)
        }
    }
    return localDefinitionCopy
}

function removeBranch(definitions: FormBuilderCellDefinition[], definitionId: string) {
    const targetIndex = definitions.findIndex(v => v.id === definitionId)
    if (targetIndex === -1)
        throw new Error(`could not find element with id ${definitionId} to prune`)

    definitions.splice(targetIndex, 1)

    for (const childDefinition of definitions.filter(v => v.parentId === definitionId))
        removeBranch(definitions, childDefinition.id)
}