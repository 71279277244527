import { Box, MenuItem, SelectChangeEvent } from '@mui/material'
import { Literal } from 'estree'
import { action } from 'mobx'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import useColorScheme from '../../internal/useColorScheme'

const BooleanLiteralComponent = (props: RendererComponent<Literal>) => {
	const [color, textColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<Box bgcolor={textColor} padding={1}>
			<ColoredFormControl formControlColor={textColor}>
				<ColoredSelect
					selectColor={color}
					value={props.node.value?.toString() ?? 'true'}
					// so annoying thing with this onChange, Selects can only have string/number values and if
					// we do value as boolean, it still outputs a string "true" or "false". so we need to
					// set the value this way
					onChange={action(
						(evt: SelectChangeEvent<unknown>) =>
							(props.node.value = evt.target.value === 'true'),
					)}
					variant="standard"
				>
					<MenuItem value={'true'}>true</MenuItem>
					<MenuItem value={'false'}>false</MenuItem>
				</ColoredSelect>
			</ColoredFormControl>
		</Box>
	)
}

export default BooleanLiteralComponent
