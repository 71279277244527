import {
	ArrowBack,
	ArrowForward,
	Assignment,
	BlurCircular,
	DataUsageOutlined,
	EnhancedEncryption,
	Group,
	Key,
	ListAlt,
	Lock,
	Person,
	Work,
	WorkOutline,
} from '@mui/icons-material'
import InboxIcon from '@mui/icons-material/Inbox'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import SaveIcon from '@mui/icons-material/Save'
import {
	Badge,
	Box,
	Divider,
	Drawer,
	IconButton,
	List,
	Theme,
	Typography,
	useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { calc, percent, px } from 'csx'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useRouteMatch } from 'react-router'
import ListItemLink from '../components/links/ListItemLink'
import { Scrollbar } from '../components/scrollbars/Scrollbar'
import { SessionService } from '../services/session'
import { makeChildRoute } from '../utils/CreateChildRoute'
import { useOfflinePackageContext } from './PackageHostPage/PackageListPageContext'

const SidebarContext = createContext<SidebarContextOptions | undefined>(
	undefined,
)

type SidebarContextOptions = {
	sidebarVisible: boolean
}

type SidebarContextProviderProps = {
	options: SidebarContextOptions
}

export const SidebarContextProvider = (
	props: PropsWithChildren<SidebarContextProviderProps>,
) => {
	return (
		<SidebarContext.Provider value={props.options}>
			{props.children}
		</SidebarContext.Provider>
	)
}

const AdministrationRouterWrapper = (props: PropsWithChildren<unknown>) => {
	const contextProviderOptions = useMemo(
		() =>
			observable({
				sidebarVisible: true,
			}),
		[],
	)

	return (
		<SidebarContextProvider options={contextProviderOptions}>
			<GlobalSidebar children={props.children} />
		</SidebarContextProvider>
	)
}

export const useSidebarContext = () => {
	const context = useContext(SidebarContext)
	if (context === undefined)
		throw Error('a value for the administration context was not provided')
	return context
}

const GlobalSidebar = observer((props: PropsWithChildren<unknown>) => {
	const [open, setOpen] = useState(true)

	const sessionService = new SessionService()
	const userRoles = sessionService.authToken.roles ?? []
	// Booleans for if the user has permissions to access areas of administration
	const globalAdmin = userRoles.includes('GlobalAdministration')
	const user = {
		packageAdmin: userRoles.includes('PackageManagement') || globalAdmin,
		licenseAdmin: userRoles.includes('LicenseManagement') || globalAdmin,
		orgAdmin: userRoles.includes('OrganizationManagement') || globalAdmin,
		workAdmin: userRoles.includes('WorkflowManagement') || globalAdmin,
		dataAdmin: userRoles.includes('DataProviderManagement') || globalAdmin,
		vaultAdmin: userRoles.includes('VaultManagement') || globalAdmin,
	}

	const toggleSidebar = () => {
		open ? setOpen(false) : setOpen(true)
	}

	const styles = useStyles()

	const sidebarContext = useSidebarContext()
	const packageContext = useOfflinePackageContext()

	const { url } = useRouteMatch()

	const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	// minimize sidebar if screen is small enough, open it when screen gets bigger
	useEffect(() => {
		if (matches && open) setOpen(false)

		if (!matches && !open) setOpen(true)
	}, [matches])

	const contentClassName = useMemo(() => {
		return clsx(styles.adminRoot, {
			[open ? styles.adminRootWithSidebar : styles.adminRootWithoutSidebar]:
				sidebarContext.sidebarVisible,
		})
	}, [sidebarContext.sidebarVisible, open, styles])

	return (
		<>
			{sidebarContext.sidebarVisible && (
				<Drawer
					classes={{
						paper: open ? styles.drawerOpen : styles.drawerClose,
					}}
					variant="permanent"
					anchor="left"
				>
					<div className={styles.toolbarSpacer} />

					<Box
						sx={{
							justifyContent: 'space-between',
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							alignItems: 'center',
						}}
					>
						<List component="nav">
							{/* <ListItemLink
								to={makeChildRoute('_user/_home', url)}
								icon={<Home fontSize={'medium'} />}
								primary={open ? 'Home' : undefined}
							/> */}

							{open ? (
								<li>
									<Typography
										className={styles.listItemText}
										color="textSecondary"
									>
										Form Packages
									</Typography>
								</li>
							) : (
								<Divider className={styles.listItemText} />
							)}

							<ListItemLink
								to={makeChildRoute(
									'_form-packages/_allowed-form-packages',
									url,
								)}
								icon={<InsertDriveFileOutlinedIcon />}
								primary={open ? 'Form Packages' : undefined}
							/>

							<ListItemLink
								to={makeChildRoute('_form-packages/_saved-form-packages', url)}
								icon={
									<Badge
										badgeContent={
											packageContext.savedPackageList.size !== 0
												? packageContext.savedPackageList.size
												: undefined
										}
										color="primary"
									>
										<SaveIcon fontSize={'medium'} />
									</Badge>
								}
								primary={open ? 'Saved Form Packages' : undefined}
							/>

							<ListItemLink
								to={makeChildRoute(
									'_form-packages/_received-form-packages',
									url,
								)}
								icon={
									<Badge
										badgeContent={
											packageContext.receivedPackageList.size !== 0
												? packageContext.receivedPackageList.size
												: undefined
										}
										color="primary"
									>
										<InboxIcon fontSize="medium" />
									</Badge>
								}
								primary={open ? 'Received Form Packages' : undefined}
							/>
							{user.packageAdmin && (
								<>
									<ListItemLink
										to={makeChildRoute('_administration/_form-packages', url)}
										icon={<WorkOutline fontSize={'medium'} />}
										primary={open ? 'Form Package Configuration' : undefined}
									/>
								</>
							)}
							{user.orgAdmin && (
								<>
									{open ? (
										<li>
											<Typography
												className={styles.listItemText}
												color="textSecondary"
											>
												Identity
											</Typography>
										</li>
									) : (
										<Divider className={styles.listItemText} />
									)}
									<ListItemLink
										to={makeChildRoute('_administration/_users', url)}
										icon={<Person fontSize={'medium'} />}
										primary={open ? 'Users' : undefined}
									/>
									<ListItemLink
										to={makeChildRoute('_administration/_groups', url)}
										icon={<Group fontSize={'medium'} />}
										primary={open ? 'Groups' : undefined}
									/>
									<ListItemLink
										to={makeChildRoute('_administration/_user-properties', url)}
										icon={<ListAlt fontSize={'medium'} />}
										primary={open ? 'User Properties' : undefined}
									/>
									<ListItemLink
										to={makeChildRoute(
											'_administration/_organization-properties',
											url,
										)}
										icon={<ListAlt fontSize={'medium'} />}
										primary={open ? 'Organization Properties' : undefined}
									/>
								</>
							)}
							{(user.orgAdmin || user.workAdmin || user.dataAdmin) && open ? (
								<li>
									<Typography
										className={styles.listItemText}
										color="textSecondary"
									>
										Tools
									</Typography>
								</li>
							) : (
								<Divider className={styles.listItemText} />
							)}

							{user.orgAdmin && (
								<ListItemLink
									to={makeChildRoute('_administration/_portals', url)}
									icon={<BlurCircular />}
									primary={open ? 'Portals' : undefined}
								/>
							)}
							{user.dataAdmin && (
								<ListItemLink
									to={makeChildRoute('_administration/_data-providers', url)}
									icon={<DataUsageOutlined fontSize={'medium'} />}
									primary={open ? 'Data Providers' : undefined}
								/>
							)}
							{user.orgAdmin && (
								<ListItemLink
									to={makeChildRoute(
										'_administration/_authentication-providers',
										url,
									)}
									icon={<Lock fontSize={'medium'} />}
									primary={open ? 'Authentication Providers' : undefined}
								/>
							)}

							{/* todo - add back when we add connector :) <ListItemLink
								to={makeChildRoute('_administration/_Connector', url)}
								icon={<CompareArrows />}
								primary={open ? 'Connector' : undefined}
							/> */}
							{user.workAdmin && (
								<>
									<ListItemLink
										to={makeChildRoute('_administration/_workflows', url)}
										icon={<Work fontSize={'medium'} />}
										primary={open ? 'Workflows' : undefined}
									/>
									<ListItemLink
										to={makeChildRoute('_administration/_work-items', url)}
										icon={<Assignment fontSize={'medium'} />}
										primary={open ? 'Work Items' : undefined}
									/>
								</>
							)}
							{user.vaultAdmin && (
								<ListItemLink
									to={makeChildRoute('_administration/_vault-storage', url)}
									icon={<EnhancedEncryption fontSize="medium" />}
									primary={open ? 'Vault Storage' : undefined}
								/>
							)}
							{user.orgAdmin && (
								<ListItemLink
									to={makeChildRoute('_administration/_licensing', url)}
									icon={<Key fontSize="medium" />}
									primary={open ? 'Licensing' : undefined}
								/>
							)}
						</List>
						<Box className={clsx(open && styles.collapseButton)}>
							<IconButton
								onClick={toggleSidebar}
								sx={{ margin: '8px', width: 'fit-content' }}
							>
								{open ? <ArrowBack /> : <ArrowForward />}
							</IconButton>
						</Box>
					</Box>
				</Drawer>
			)}
			<Scrollbar contentClassName={styles.height100}>
				<div className={contentClassName}>
					<div className={styles.adminRootVertical}>{props.children}</div>
				</div>
			</Scrollbar>
		</>
	)
})

const drawerWidth = 256
const drawerWidthClosed = 60
const transitionDuration = 300

const useStyles = makeStyles((theme: Theme) => ({
	height100: {
		height: '100%',
		overflow: 'hidden',
	},

	adminRoot: {
		position: 'relative',

		display: 'flex',

		height: percent(100),
		width: percent(100),

		padding: theme.spacing(0, 3),

		overflow: 'auto',
	},

	adminRootWithSidebar: {
		left: px(drawerWidth),
		height: percent(100),
		width: calc(`${percent(100)} - ${px(drawerWidth)}`),
		transition: theme.transitions.create(['width', 'left'], {
			duration: transitionDuration,
		}),
	},

	adminRootWithoutSidebar: {
		left: px(drawerWidthClosed),
		height: percent(100),
		width: calc(`${percent(100)} - ${px(drawerWidthClosed)}`),
		transition: theme.transitions.create(['width', 'left'], {
			duration: transitionDuration,
		}),
	},

	adminRootVertical: {
		display: 'flex',
		flexDirection: 'column',

		alignItems: 'center',

		width: percent(100),
		height: percent(100),
	},

	drawerOpen: {
		width: drawerWidth,
		height: percent(100),
		padding: theme.spacing(0, 1.5),

		overflow: 'hidden',

		'&:hover': {
			overflowY: 'auto',
		},

		'&::-webkit-scrollbar': {
			width: theme.spacing(0.5),
		},
		'&::-webkit-scrollbar-thumb': {
			background:
				theme.palette.mode === 'dark'
					? theme.palette.grey[700]
					: theme.palette.primary.light,
			borderRadius: theme.spacing(4),
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.grey[500],
		},
		transition: theme.transitions.create('width', {
			duration: transitionDuration,
		}),
	},

	drawerClose: {
		width: drawerWidthClosed,
		height: percent(100),
		padding: theme.spacing(0, 1.5),

		overflow: 'hidden',

		'&:hover': {
			overflowY: 'auto',
		},

		'&::-webkit-scrollbar': {
			width: theme.spacing(0.5),
		},
		'&::-webkit-scrollbar-thumb': {
			background:
				theme.palette.mode === 'dark'
					? theme.palette.grey[700]
					: theme.palette.primary.light,
			borderRadius: theme.spacing(4),
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.grey[500],
		},
		transition: theme.transitions.create('width', {
			duration: transitionDuration,
		}),
	},

	toolbarSpacer: {
		...theme.mixins.toolbar,
	},

	// move to another component
	listItemText: {
		fontWeight: 'bold',
		margin: theme.spacing(1, 0),
	},

	collapseButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
}))

export default AdministrationRouterWrapper
