import { Delete } from '@mui/icons-material'
import { Box, Button, Icon, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import {
	ActivityConfiguration,
	BaseActivityDefinitionModel,
} from '../../api/clients/workflows/DTOs'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../FormBuilderInterop/Runtimes/Standalone'

type EditActivityProps = {
	activity: BaseActivityDefinitionModel
	configuration: ActivityConfiguration
	readonly: boolean
	onRemoveActivity: (id: string) => void
}

export const EditActivity = observer(
	({
		activity,
		configuration,
		readonly,
		onRemoveActivity,
	}: EditActivityProps) => {
		if (configuration.formBuilderSchema.definitions === undefined)
			throw Error(`form builder definitions are undefined`)

		const cellManager = useMemo(() => {
			return new StandaloneCellManager(
				configuration.formBuilderSchema,
				activity.state,
				readonly,
			)
		}, [configuration, activity])

		return (
			<Box
				height="100%"
				width="100%"
				padding={3}
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<Box display="flex" flexDirection="column" gap={3}>
					<link
						rel="stylesheet"
						href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
					/>

					<Box
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						gap={2}
					>
						<Icon baseClassName="material-symbols-outlined">
							{configuration.icon}
						</Icon>
						<Typography variant="h6">{configuration.displayName}</Typography>
					</Box>

					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						gap={3}
					>
						<FormBuilderViewer cellManager={cellManager} />
					</Box>
				</Box>
				{!readonly && (
					<Box width="100%" paddingTop={3} paddingBottom={1}>
						<Box
							component={Button}
							width="100%"
							variant="outlined"
							onClick={() => onRemoveActivity(activity.uniqueId)}
							startIcon={<Delete />}
						>
							Delete Activity
						</Box>
					</Box>
				)}
			</Box>
		)
	},
)
