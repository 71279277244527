import {
	Button,
	CircularProgress,
	Fade,
	Paper,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { calc, percent } from 'csx'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import DataProviderInstancesClient from '../../../../api/clients/dataProviders/DataProviderInstancesClient'
import { MonacoEditor } from '../../../../modules/CodeEditor/MonacoEditor'

export const TestDataProvider: React.FC = () => {
	const styles = useStyles()

	const params = useParams<{ instanceId: string }>()
	const instanceId = parseInt(params.instanceId)
	if (isNaN(instanceId)) throw new Error('invalid instance id')

	const [parameters, setParameters] = useState<Record<string, string>>({})
	const [testResponse, setTestResponse] = useState<Record<string, unknown>[]>()
	const [isExecuting, setIsExecuting] = useState(false)

	const client = new DataProviderInstancesClient()

	const handleExecute = async () => {
		setIsExecuting(true)

		await client
			.execute(instanceId, parameters)
			.then((response) => {
				setTestResponse(response.data)
			})
			.catch((error) => {
				setTestResponse(error.response ? error.response.data : error)
			})
			.finally(() => {
				setIsExecuting(false)
			})
	}

	const handleMonacoChange = (v: string | undefined) => {
		if (v === undefined) return

		const parsed = JSON.parse(v)

		setParameters(parsed)
	}

	return (
		<Fade in unmountOnExit>
			<Paper className={styles.insetSpacing}>
				<div className={styles.div}>
					<div className={styles.parametersDiv}>
						<Typography className={styles.label}>Parameters</Typography>
						<div className={styles.editor}>
							<MonacoEditor
								language="json"
								value={JSON.stringify(parameters)}
								onChange={(v: string | undefined) => {
									handleMonacoChange(v)
								}}
								height="50vh"
							/>
						</div>
						<div className={styles.execute}>
							{isExecuting && (
								<CircularProgress
									size={32}
									className={styles.circularProgress}
								/>
							)}
							<Button
								variant="contained"
								color="primary"
								onClick={handleExecute}
								disabled={isExecuting}
							>
								Execute
							</Button>
						</div>
					</div>
					<div className={styles.resultsDiv}>
						<Typography className={styles.label}>Results</Typography>
						<MonacoEditor
							language="json"
							value={
								testResponse !== undefined
									? JSON.stringify(testResponse, undefined, 2)
									: ''
							}
							onChange={() => undefined}
							height="60vh"
						/>
					</div>
				</div>
			</Paper>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(2, -2),
		padding: theme.spacing(0, 2),
		width: percent(100),
	},

	div: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: percent(100),
	},

	parametersDiv: {
		flex: 2,
		overflow: 'hidden',
	},

	resultsDiv: {
		flex: 3,
		overflow: 'hidden',
	},

	editor: {
		height: calc('100% - 100px'),
	},

	label: {
		marginBottom: theme.spacing(1.5),
	},

	circularProgress: {
		marginRight: theme.spacing(1.5),
	},

	execute: {
		float: 'right',
	},
}))
