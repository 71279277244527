import { NIL } from 'uuid'
import { IVirtualCellInstance } from '../../../FormHost/Types/CellInstance'
import { CellType } from '../../../FormHost/Types/CellType'
import { FieldType } from '../../../FormHost/Types/FieldType'
import { HtmlCellDefinition } from '../Types/HtmlCellDefinition'
import { HtmlCellInstance } from '../Types/HtmlCellInstance'
import { HTMLVirtualRadioAdapter } from './HtmlAdapters'

export function generateDefinitionArray(
	root: Node,
	definitions: HtmlCellDefinition[],
	parentId: string,
) {
	for (const node of root.childNodes) {
		if (!isHtmlElementNode(node)) continue

		// if we have a forms attribute then it's probably a repeat anchor and we need to skip it
		if (
			!hasFormsAttribute(node) ||
			(getFormsAttribute(node) === '' &&
				tryGetRepeatAnchorAttribute(node) != undefined)
		) {
			// we restart, walking from here so we can guarantee that we have _all_ the nodes and
			// we use the last id
			generateDefinitionArray(node, definitions, parentId)
			continue
		}

		// so it has a forms attribute, now we know we're looking at some entity that needs tracked
		const elementName = getFormsAttribute(node)

		const parentType = getParentType(definitions, parentId)

		if (parentType === CellType.Layout && elementName === '')
			throw new Error('direct children of layouts must have a name')

		/*
		 * radios are treated a bit differently. we need to make sure only one definition is created for a radio,
		 * instead of definitions for each option in the radio, since they all have the HTML tag "radio".
		 * the radio definition we create will have the radio's name as the id & name
		 */
		if (isRadioElement(node)) {
			/* all the radio options have the same name, so if we hit a radio option with the same name
			  we don't want to create a new definition for it, since there should only be one definition
				per radio. otherwise we'd have a definition for the whole radio and then definitions for
			  each option. */
			if (!definitions.find((v) => v.name === node.name))
				definitions.push({
					id: node.name,
					parentId: parentId,
					name: node.name,
					elementTag: 'VIRTUAL',
					fieldType: FieldType.String,
					properties: { subType: 'RADIO' },
					type: CellType.Value,
				})

			return definitions
		}

		const nodeTypeData = computeNodeFieldType(node)

		/*
		 * we need to look for any anchors of the repeat, but they may not be direct descendents of
		 * the repeat, so we have to recurse a little bit.
		 * if no anchor is defined we throw an error because we have to have at least one anchor
		 * in a repeat, since we have to have those element names to do field mapping.
		 * more on what findDefinitionAnchorElements() does is described before the function definition.
		 */
		if (nodeTypeData.type === CellType.Repeat) {
			findDefinitionAnchorElements(node, definitions, node.id)
		}

		if (nodeTypeData.type === CellType.Layout) {
			generateDefinitionArray(node, definitions, node.id)
		}

		const definition: HtmlCellDefinition = {
			id: node.id,
			parentId: parentId,
			name: elementName,
			...nodeTypeData,
		}

		/* typically, we'd expect to replace this ID with some sort of GUID so we can better track it,
		unfortunately, that means that we would have to somehow restore the structure whenever we uploaded
		a new version, and that is very difficult. The easier thing is to track via ID and rely on the
		implementer to establish all the correct relations. */
		definitions.push(definition)
	}

	return definitions
}

type ElementTreeStandaloneElement = {
	node: HtmlCellDefinition

	hasChildren: false
}

type ElementTreeParentElement = {
	node: HtmlCellDefinition

	hasChildren: true
	children: ElementTree
}

type ElementTreeElement =
	| ElementTreeStandaloneElement
	| ElementTreeParentElement

type ElementTree = {
	elements: {
		[element: string]: ElementTreeElement | undefined
	}
	parent?: ElementTree
}

export function generateElementTree(
	definitions: HtmlCellDefinition[],
	parentId: string = NIL,
	parent?: ElementTree,
): ElementTree {
	const children = definitions.filter((v) => v.parentId === parentId)

	const elementTree: ElementTree = {
		elements: {},
		parent: parent,
	}

	for (const child of children) {
		const childId = child.id

		const tree = generateElementTree(definitions, childId)

		const hasChildren = Object.keys(tree).length > 0

		elementTree.elements[child.name] = {
			node: child,

			hasChildren: hasChildren,
			children: tree,
		}
	}

	return elementTree
}

export function refreshInstanceCollection(
	root: Node,
	definitionTree: ElementTree,
	definitions: HtmlCellDefinition[],
	parentInstanceId: string,
	instances: HtmlCellInstance[],
) {
	for (const node of root.childNodes) {
		if (!isHtmlElementNode(node)) continue

		if (!hasFormsAttribute(node)) {
			refreshInstanceCollection(
				node,
				definitionTree,
				definitions,
				parentInstanceId,
				instances,
			)
			continue
		}

		// if we have a forms node without a name, and an anchor attribute, what
		// we're looking at is a virtual cell, verify it's parented by a repeat and
		// we're good
		if (getFormsAttribute(node) === '' && hasRepeatAnchorAttribute(node)) {
			const parentInstance = instances.find((v) => v.id == parentInstanceId)
			if (parentInstance === undefined)
				throw new Error(`could not find parent for instance ${node.id}`)
			if (parentInstance.type != CellType.Repeat)
				throw new Error(
					`repeat anchor instance ${node.id} is not parented by repeat`,
				)

			const ordinalPosition = parseInt(getRepeatAnchorAttribute(node))

			const virtualInstanceId = parentInstanceId + `-${ordinalPosition}`

			if (instances.find((v) => v.id === virtualInstanceId) === undefined) {
				/*
				 * add the virtual cell instance that's parented by the repeat cell instance,
				 * and all the contents of the repeatable section will be parented by this instance
				 */
				const virtualInstance: IVirtualCellInstance = {
					type: CellType.Virtual,
					id: virtualInstanceId,
					ordinalPosition: ordinalPosition,
					parentId: parentInstance.id,
					fieldType: FieldType.Virtual,
				}

				instances.push(virtualInstance)
			}

			refreshInstanceCollection(
				node,
				definitionTree,
				definitions,
				virtualInstanceId,
				instances,
			)

			// nothing left to do here
			continue
		}

		const elementName = getFormsAttribute(node)

		if (isRadioElement(node)) {
			addRadioInstance(node, parentInstanceId, definitions, instances)
			continue
		}

		const treeNode = definitionTree.elements[elementName]

		const definition =
			treeNode?.node ?? definitions.find((v) => v.id === node.id)
		if (definition === undefined) {
			throw new Error(
				`could not find tree node with name '${elementName}' or id ${node.id}`,
			)
		}

		/* if this is true then we're already tracking this element, we can try to
		see if it has any children*/
		if (instances.find((v) => v.id === node.id) !== undefined) {
			refreshInstanceCollection(
				node,
				treeNode?.hasChildren ? treeNode.children : { elements: {} },
				definitions,
				getFormsAttribute(node),
				instances,
			)
			continue
		}

		const parentType = getParentType(definitions, definition.parentId)

		// Direct children of layouts must have a name to have a correct object structure
		if (parentType === CellType.Layout && elementName === '')
			throw new Error('direct children of layouts must have a name')

		const basicInstance = {
			definitionId: definition.id,
			id: node.id,
			name: definition.name,
			fieldType: definition.fieldType,
			properties: definition.properties,
			parentId: parentInstanceId,
			elementTag: definition.elementTag,
		}

		if (
			definition.type === CellType.Layout ||
			definition.type === CellType.Repeat
		) {
			instances.push({
				type: definition.type,
				...basicInstance,
			})

			refreshInstanceCollection(
				node,
				treeNode?.hasChildren ? treeNode.children : { elements: {} },
				definitions,
				definition.id,
				instances,
			)
		} else if (
			/*
			 * if the cell is not parented by a layout or repeat section, we don't have to do anything special
			 * or check for attributes required by children of layouts/repeats
			 */
			parentType !== CellType.Repeat &&
			parentType !== CellType.Layout &&
			definition.type !== CellType.Value
		) {
			instances.push({
				type: definition.type,
				...basicInstance,
			})
		} else if (definition.type === CellType.Value) {
			let value: string | object | boolean | null = node.getAttribute('value')

			if (definition.fieldType === FieldType.ReferenceType) value = {}

			// value = checked, but checked=false still is true since the attribute exists
			// so we need to check if "checked" attribute exists
			if (definition.fieldType === FieldType.Boolean)
				value = node.getAttribute('checked') !== null

			instances.push({
				type: CellType.Value,
				value: value,
				...basicInstance,
			})
		}
	}
}

/*
 * checks to see if there are any anchor elements under the repeatable section because a repeat
 * is required to have at least one anchor.
 * also checks that the anchor doesn't have a name because that will mess with the repeat object structure.
 * if anchor element is found, generate definitions/instances like normal for that element and then
 * continue the search for more anchor elements.
 * if no anchor element found within the repeat, return false
 */
function findDefinitionAnchorElements(
	node: HTMLElement,
	definitions: HtmlCellDefinition[],
	parentId: string,
): boolean {
	let found = false

	for (const child of node.childNodes) {
		if (!isHtmlElementNode(child)) continue

		if (hasRepeatAnchorAttribute(child)) {
			if (getFormsAttribute(child) !== '')
				throw new Error('repeat anchors cannot have a name')

			generateDefinitionArray(node, definitions, parentId)
			found = true
		} else {
			found = findDefinitionAnchorElements(child, definitions, parentId)
		}
	}

	if (!found)
		throw new Error('there must be at least one anchor within a repeat')

	return found
}

function isHtmlElementNode(node: Node): node is HTMLElement {
	return node.nodeType === Node.ELEMENT_NODE
}

function isHtmlInputNode(node: HTMLElement): node is HTMLInputElement {
	return node.nodeName === 'INPUT'
}

function isRadioElement(node: HTMLElement): node is HTMLInputElement {
	return isHtmlInputNode(node) && node.type.toLowerCase() === 'radio'
}

type HtmlFieldTypeExtended = {
	type: Exclude<CellType, CellType.Virtual | CellType.Null>
	fieldType: FieldType
	properties: Record<string, unknown>
	elementTag: string
	value?: unknown
}

function computeNodeFieldType(node: HTMLElement): HtmlFieldTypeExtended {
	// we put this here so we don't have to worry about recreating it for every single node
	const valueObject: HtmlFieldTypeExtended = {
		type: CellType.Value,
		fieldType: FieldType.String,
		properties: {},
		elementTag: node.tagName,
	}

	if (node.tagName === 'SELECT') {
		valueObject.fieldType = (node as HTMLSelectElement).multiple
			? FieldType.StringArray
			: FieldType.String
		return valueObject
	}

	if (node.tagName === 'TEXTAREA') return valueObject

	if (isHtmlInputNode(node)) {
		const nodeType = node.type.toLowerCase()

		switch (nodeType) {
			case 'button':
			case 'file':
			case 'image':
			case 'reset':
			case 'submit':
				throw Error(`Input type = ${node.type} is not a supported input type`)
			case 'checkbox':
				valueObject.properties = {
					...valueObject.properties,
					inputType: nodeType,
					name: node.name,
				}
				valueObject.fieldType = FieldType.Boolean
				return valueObject
			case 'color':
			case 'date':
			case 'datetime-local':
			case 'email':
			case 'hidden':
			case 'month':
			case 'number':
			case 'password':
			case 'range':
			case 'tel':
			case 'text':
			case 'time':
			case 'url':
			case 'week':
				valueObject.properties = {
					...valueObject.properties,
					inputType: nodeType,
				}
				return valueObject
		}
	}

	if (node.tagName === 'DIV' && node.hasAttribute('data-f-signature')) {
		valueObject.elementTag = 'Signature'
		valueObject.fieldType = FieldType.ReferenceType
		valueObject.type = CellType.Value
		return valueObject
	}

	/* if we have eliminated all of the possible input types we have to assume that what
	we are looking at has something to do with layouts. If we have the repeat attribute 
	then we know that it's an _expandable_ layout too. */
	if (node.hasAttribute('data-f-repeat')) {
		valueObject.fieldType = FieldType.ObjectArray
		valueObject.type = CellType.Repeat
	} else {
		valueObject.fieldType = FieldType.Object
		valueObject.type = CellType.Layout
	}
	return valueObject
}

function addRadioInstance(
	node: HTMLInputElement,
	parentInstanceId: string,
	definitions: HtmlCellDefinition[],
	instances: HtmlCellInstance[],
) {
	const definition = definitions.find((v) => v.id === node.name)
	if (definition === undefined)
		throw new Error(
			`definition could not be found for radio element: ${node.name}`,
		)

	/* since all options in a radio will have the same node name, we need to make sure
		there's not already an instance that exists for this definition. 
		without this check we will end up with an instance for each radio option.
	*/
	if (instances.find((v) => v.id === definition.id)) return

	const cellInstance: HtmlCellInstance = {
		definitionId: definition.id,
		id: definition.id,
		name: definition.name,
		fieldType: definition.fieldType,
		properties: definition.properties,
		parentId: parentInstanceId,
		elementTag: definition.elementTag,
		type: CellType.Value,
		value: null,
	}
	const radioAdapter = new HTMLVirtualRadioAdapter()

	if (radioAdapter.canRead(cellInstance))
		cellInstance.value = radioAdapter.read(cellInstance, document)

	instances.push(cellInstance)
}

function hasFormsAttribute(element: HTMLElement) {
	return element.hasAttribute('data-f-element')
}

function getFormsAttribute(element: HTMLElement) {
	const attributeValue = element.getAttribute('data-f-element')
	if (attributeValue === null)
		throw Error('no data-f-element attribute on element')
	return attributeValue
}

function hasRepeatAnchorAttribute(element: HTMLElement) {
	return element.hasAttribute('data-f-repeat-anchor')
}

function getRepeatAnchorAttribute(element: HTMLElement) {
	// get the ordinal position for virtual cells!
	const attributeValue = element.getAttribute('data-f-repeat-anchor')
	if (attributeValue === null)
		throw new Error('no data-f-repeat-anchor attribute on element')
	return attributeValue
}

function tryGetRepeatAnchorAttribute(element: HTMLElement) {
	try {
		return getRepeatAnchorAttribute(element)
	} catch (e) {
		return undefined
	}
}

function getParentType<T extends HtmlCellDefinition | HtmlCellInstance>(
	collection: T[],
	parentId: string,
): CellType | undefined {
	const parent = collection.find((v) => v.id === parentId)

	if (parent === undefined) return undefined

	return parent.type
}
