import { TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { TypedEditor } from './PropertySchematicConfigurator'

const DatePickerPropertyConfigurator = (
	props: TypedEditor<Date | undefined>,
) => {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DesktopDatePicker
				value={props.value ?? moment()}
				onChange={(date: Moment | null) => {
					props.onChange(date?.toDate() ?? undefined)
				}}
				inputFormat="MM/DD/YYYY"
				renderInput={(params: TextFieldProps) => (
					<TextField fullWidth {...params} />
				)}
			/>
		</LocalizationProvider>
	)
}

export default DatePickerPropertyConfigurator
