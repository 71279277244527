import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import {
	FormPackageClient,
	FormsClient,
	UserPropertiesClient,
} from '../../../../api/clients/identity'
import {
	OrganizationPropertiesClient,
	OrganizationPropertyType,
} from '../../../../api/clients/identity/OrganizationPropertiesClient'
import { Field } from '../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/LinkedFieldsSection/FieldMappingsPage'
import { getCellDisplayName } from '../../../../utils/getCellDisplayName'
import { IEventActionProps } from '../IEventAction'
import { ConfiguredFieldMappingsTable } from './ConfiguredFieldMappingsTable'
import {
	FieldMappingSource,
	PropertyFieldMapping,
	SourceType,
} from './FieldMappingAction'

type FieldMappingConfiguratorProps = IEventActionProps<PropertyFieldMapping[]>

export const FieldMappingConfigurator = observer(
	(props: FieldMappingConfiguratorProps) => {
		const params = useParams<{ packageId: string }>()
		const packageId = parseInt(params.packageId)

		if (isNaN(packageId)) throw new Error('invalid package id')

		const userPropertiesClient = new UserPropertiesClient()
		const orgPropertiesClient = new OrganizationPropertiesClient()

		const localStore = useLocalObservable(() => ({
			sources: [] as FieldMappingSource[],
			fields: [] as Field[],
		}))

		const formPackageClient = new FormPackageClient()

		useEffect(() => {
			;(async () => {
				const intermediateSources: FieldMappingSource[] = []

				const userPropsPromise = userPropertiesClient
					.GetUserProperties(true)
					.then(
						action((response) => {
							const properties = response.data.map(
								({ id, name }) =>
									({
										id,
										displayName: name,
										type: SourceType.UserProperty,
									} as FieldMappingSource),
							)

							intermediateSources.push(...properties)
						}),
					)

				const orgPropsPromise = orgPropertiesClient
					.GetOrganizationProperties()
					.then(
						action((response) => {
							const properties = response.data
								.filter((v) => v.value?.type !== OrganizationPropertyType.List)
								.map(
									({ id, name }) =>
										({
											id,
											displayName: name,
											type: SourceType.OrganizationProperty,
										} as FieldMappingSource),
								)

							intermediateSources.push(...properties)
						}),
					)

				const userInfoSources: FieldMappingSource[] = [
					{
						id: 'firstName',
						displayName: 'First Name',
						type: SourceType.CurrentUserInfo,
					},
					{
						id: 'lastName',
						displayName: 'Last Name',
						type: SourceType.CurrentUserInfo,
					},
					{
						id: 'fullName',
						displayName: 'Full Name',
						type: SourceType.CurrentUserInfo,
					},
					{
						id: 'emailAddress',
						displayName: 'Email Address',
						type: SourceType.CurrentUserInfo,
					},
				]

				intermediateSources.push(...userInfoSources)

				await Promise.all([userPropsPromise, orgPropsPromise])
				runInAction(() => (localStore.sources = intermediateSources))
			})()

			// because only one async call we don't really care
			formPackageClient.GetFormPackage(packageId).then((response) => {
				const formsClient = new FormsClient(
					packageId,
					response.data.versionNumber,
				)

				formsClient.GetAllForms().then(
					action((response) => {
						const innerFields: Field[] = []

						for (const form of response.data) {
							for (const cellDefinition of form.metadata.cellDefinitions.filter(
								(v) => v.elementTag !== 'Signature',
							))
								innerFields.push({
									formId: form.id,
									formName: form.name,
									cellDefinition: cellDefinition,
									displayName: getCellDisplayName(
										form.metadata.cellDefinitions,
										cellDefinition,
									),
									linked: false,
								})
						}
						localStore.fields = innerFields
					}),
				)
			})
		}, [])

		return (
			<ConfiguredFieldMappingsTable
				sources={localStore.sources}
				fields={localStore.fields}
				fieldMappings={props.value}
				onValueChange={props.onValueChanged}
			/>
		)
	},
)
