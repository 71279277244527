import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { UserPropertiesClient } from '../../../../api/clients/identity'
import { UserProperty, UserPropertySimple } from '../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { Scrollbar } from '../../../../components/scrollbars/Scrollbar'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { UserPropertyOptionsSection } from './UserPropertyOptionsSection'
import { UserPropertyUsersSection } from './UserPropertyUsersSection/UserPropertyUsersSection'

export const UserPropertyPage = observer(() => {
	const params = useParams<{ propertyId: string }>()

	const propertyId = parseInt(params.propertyId)
	if (isNaN(propertyId)) throw new Error('invalid property id')

	const styles = useStyles()

	const userPropertiesClient = new UserPropertiesClient()

	const store = useLocalObservable(() => ({
		userProperty: undefined as UserProperty | undefined,
		upperPropertyNames: [] as string[], // to make sure names are unique when changed
	}))

	const handleUpdateUserProperty = action(
		(propertyId: number, updatedProperty: UserPropertySimple) => {
			if (store.userProperty === undefined)
				throw new Error('user property is undefined')

			const index = store.upperPropertyNames.indexOf(
				store.userProperty.name.toUpperCase(),
			)

			if (index === -1)
				throw new Error('could not find property name to update')

			// don't update property if the name can't be found in the list - this could cause errors later
			// if a user tries to update another property with this property's name
			userPropertiesClient.UpdateUserProperty(propertyId, {
				name: updatedProperty.name,
				userEditable: updatedProperty.userEditable,
			})

			store.upperPropertyNames[index] = updatedProperty.name.toUpperCase()

			store.userProperty.name = updatedProperty.name
		},
	)

	useEffect(() => {
		userPropertiesClient.GetUserProperty(propertyId).then(
			action((response) => {
				store.userProperty = response.data
			}),
		)

		userPropertiesClient.GetUserProperties(true).then(
			action((response) => {
				store.upperPropertyNames = response.data.map((v) =>
					v.name.toUpperCase(),
				)
			}),
		)
	}, [store])

	return (
		<>
			{store.userProperty === undefined ? (
				<FullscreenSpinner />
			) : (
				<AdministrationPageContainer title={store.userProperty.name}>
					<div className={styles.userPropertyContentRoot}>
						<Scrollbar contentClassName={styles.scrollContent}>
							<UserPropertyOptionsSection
								userProperty={store.userProperty}
								upperPropertyNames={store.upperPropertyNames}
								onUpdateUserProperty={handleUpdateUserProperty}
							/>
							<UserPropertyUsersSection userProperty={store.userProperty} />
						</Scrollbar>
					</div>
				</AdministrationPageContainer>
			)}
		</>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	scrollContent: {
		marginRight: theme.spacing(1),
	},

	userPropertyContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	scrollbar: {
		'&::-webkit-scrollbar': {
			width: px(10),
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.primary.dark,
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.primary.light,
		},
	},
}))
