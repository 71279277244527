import { FormControl, MenuItem, Select, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { px } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import React, { useEffect } from 'react'
import WebFont, { Config as WebFontConfig } from 'webfontloader'
import { useSignatureStore } from './SignatureModalStore'

type FontRecord = {
	fontName: string
	fontProvider: 'google' | 'typekit'
	arguments: {
		[key: string]: unknown
	}
	loaded?: boolean
}

export const SignatureView: React.FC = observer(() => {
	const styles = useStyles()

	const store = useSignatureStore()

	const localStore = useLocalObservable(() => ({
		validState: false,
		font: fonts[0],
		defaultLoaded: false,
	}))

	const generatorCanvasReference = React.createRef<HTMLCanvasElement>()

	localStore.validState = store.fullName !== ''

	useEffect(
		action(() => {
			// need to make sure the font is loaded before doing anything!
			if (!localStore.defaultLoaded) {
				loadFont(
					localStore.font,
					action(() => (localStore.defaultLoaded = true)),
				)
			}

			if (!generatorCanvasReference) throw 'cannot find canvas'

			const generatorCanvas = generatorCanvasReference.current
			if (!generatorCanvas) throw 'the canvas was null'

			const generatorCanvasContext = generatorCanvas.getContext('2d')
			if (!generatorCanvasContext) throw 'failed to fetch canvas context'

			// get a 3:1 canvas aspect ratio so the signature will fit better on the form
			generatorCanvas.height = 100
			generatorCanvas.width = generatorCanvas.height * 5

			// we only want to fill the canvas if the full name is not empty
			if (localStore.validState) {
				generatorCanvasContext.fillStyle = '#ffffff'
				generatorCanvasContext.fillRect(
					0,
					0,
					generatorCanvas.width,
					generatorCanvas.height,
				)
				generatorCanvasContext.fillStyle = '#000'

				let initialFontSize = 128
				let fontMeasurements: TextMetrics | undefined
				let textHeight: number | undefined

				// shrinks font until it fits on canvas
				do {
					generatorCanvasContext.font =
						initialFontSize + 'px ' + localStore.font.fontName
					fontMeasurements = generatorCanvasContext.measureText(store.fullName)
					textHeight =
						fontMeasurements.actualBoundingBoxAscent +
						fontMeasurements.actualBoundingBoxDescent
					initialFontSize--
				} while (
					fontMeasurements.width > generatorCanvas.width ||
					textHeight > generatorCanvas.height
				)

				// ensures font is always centered on the canvas (based on full height of text not baseline)
				const x = generatorCanvas.width / 2 - fontMeasurements.width / 2
				const y =
					(generatorCanvas.height - textHeight) / 2 +
					fontMeasurements.actualBoundingBoxAscent

				generatorCanvasContext.fillText(store.fullName, x, y)

				store.signatureData.viewSignatureValue = generatorCanvas.toDataURL()
			} else {
				generatorCanvasContext.clearRect(
					0,
					0,
					generatorCanvas.width,
					generatorCanvas.height,
				)
				store.signatureData.viewSignatureValue = undefined
			}
		}),
		[localStore.defaultLoaded, localStore.font, store.fullName],
	)

	const changeFont = action((font: FontRecord) => {
		if (font.loaded) {
			localStore.font = font
			return
		}

		loadFont(
			font,
			action(() => (localStore.font = font)),
		)
	})

	return (
		<div className={styles.signatureSection}>
			<canvas
				ref={generatorCanvasReference}
				className={styles.canvas}
				title={store.fullName || store.initials}
			></canvas>
			<div className={styles.canvasActions}>
				<FormControl>
					<Select value={localStore.font.fontName} size="small">
						{fonts.map((v, i) => (
							<MenuItem
								key={i}
								onClick={() => changeFont(v)}
								value={v.fontName}
							>
								{v.fontName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		</div>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	signatureSection: {
		margin: theme.spacing(2),
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridTemplateRows: 'repeat(4, 1fr)',
		gridColumnGap: px(0),
		gridRowGap: px(0),
	},

	canvas: {
		border: 'solid',
		gridArea: '1 / 2 / 4 / 5',
		placeSelf: 'center',
	},

	canvasActions: {
		gridArea: ' 4 / 4 / 5 / 5',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}))

export const fonts: FontRecord[] = [
	{
		fontName: 'Shadows Into Light',
		fontProvider: 'google',
		arguments: { families: ['Shadows Into Light'] },
	},
	{
		fontName: 'Courier New',
		fontProvider: 'google',
		arguments: { families: ['Courier New'] },
	},
	{
		fontName: 'Dekko',
		fontProvider: 'google',
		arguments: { families: ['Dekko'] },
	},
	{
		fontName: 'La Belle Aurore',
		fontProvider: 'google',
		arguments: { families: ['La Belle Aurore'] },
	},
	{
		fontName: 'Gloria Hallelujah',
		fontProvider: 'google',
		arguments: { families: ['Gloria Hallelujah'] },
	},
	{
		fontName: 'Rock Salt',
		fontProvider: 'google',
		arguments: { families: ['Rock Salt'] },
	},
	{
		fontName: 'Homemade Apple',
		fontProvider: 'google',
		arguments: { families: ['Homemade Apple'] },
	},
	{
		fontName: 'Sacramento',
		fontProvider: 'google',
		arguments: { families: ['Sacramento'] },
	},
	{
		fontName: 'Dawning of a New Day',
		fontProvider: 'google',
		arguments: { families: ['Dawning of a New Day'] },
	},
	{
		fontName: 'Mr De Haviland',
		fontProvider: 'google',
		arguments: { families: ['Mr De Haviland'] },
	},
	{
		fontName: 'Devonshire',
		fontProvider: 'google',
		arguments: { families: ['Devonshire'] },
	},
]

export const loadFont = (font: FontRecord, action?: () => void) => {
	const requestConfig = {} as {
		[key: string]: string | number | Record<string, unknown>
	}

	requestConfig[font.fontProvider] = font.arguments
	const otherConfig = {
		...requestConfig,
		active() {
			action && action()
		},
	} as WebFontConfig

	if (!font.loaded) WebFont.load(otherConfig)

	font.loaded = true
}
