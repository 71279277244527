import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PropsWithChildren } from 'react'
import { useDrag } from 'react-dnd'
import { ComponentWrapper } from '../../../../FormBuilderCore/cells/rendering/contexts/ContextTypes'
import { DraggableItemType } from '../Types/DraggableItemType'

export const DraggableComponent = (
	props: PropsWithChildren<ComponentWrapper>,
) => {
	const styles = useStyles()

	const [{ isDragging }, drag] = useDrag(() => ({
		type: DraggableItemType.FormBuilderComponent,
		item: {
			definitionId: props.cellInstance.definitionId,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}))

	return (
		<div ref={drag} className={styles.draggableStyle}>
			{props.children}
			<div className={styles.dragOverlay} />
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	draggableStyle: {
		position: 'relative',

		flex: '1',

		padding: theme.spacing(0.5),
	},

	dragOverlay: {
		position: 'absolute',

		top: 0,
		bottom: 0,
		left: 0,
		right: 0,

		zIndex: 1,

		cursor: 'move',
	},
}))
