import { NIL } from "uuid"
import { DraggableFormHost } from "../../DraggableFormHost"
import { DraggableDefinitionData } from "../../Types"

export function isRecursiveCycle(item: DraggableDefinitionData, definitionId: string, formHost: DraggableFormHost) {

	const cellDefinitions = formHost.cellDefinitions

	while (definitionId !== NIL) {
		const currentItem = cellDefinitions.find(v => v.id === definitionId)
		if (currentItem === undefined)
			throw Error(`could not find an item with id ${currentItem}`)

		if (currentItem.parentId === item.definitionId) {
			console.log('detected possible recursive cycle!')
			return true
		}
		definitionId = currentItem.parentId
	}
	
	return false
}