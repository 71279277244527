import { ArrowBack } from '@mui/icons-material'
import { Box, IconButton, Theme, Tooltip, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { cloneDeep } from 'lodash'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { useHistory, useParams } from 'react-router'
import { v4 as uuid } from 'uuid'
import {
	ActivityConfiguration,
	BaseActivityDefinitionModel,
	Lifetime,
} from '../../api/clients/workflows/DTOs'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { ActivityDrawer } from './ActivityDrawer'
import { DraggableWorkflowType } from './DraggableWorkflowType'
import { EditorDisplayType } from './Types'
import { WorkflowEditor } from './WorkflowEditor'
import { WorkflowStatusBox } from './WorkflowStatusBox'
import { WorkflowStoreProvider, useWorkflowStore } from './stores/WorkflowStore'

type WorkflowEditorHostProps = {
	displayType: EditorDisplayType
}

export const WorkflowEditorHost = (props: WorkflowEditorHostProps) => {
	const routeParams = useParams<{
		workflowId: string
		definitionId?: string
		instanceId?: string
	}>()

	const workflowId = parseInt(routeParams.workflowId)
	const definitionId = parseInt(routeParams.definitionId ?? '0')
	const instanceId = parseInt(routeParams.instanceId ?? '0')

	if (isNaN(workflowId)) throw new Error('invalid workflow id')
	if (isNaN(definitionId)) throw new Error('invalid workflow definition id')
	if (isNaN(instanceId)) throw new Error('invalid workflow instance id')

	return (
		<WorkflowStoreProvider
			workflowId={workflowId}
			definitionId={definitionId}
			instanceId={instanceId}
		>
			<WorkflowEditorHostComponent
				displayType={props.displayType}
			></WorkflowEditorHostComponent>
		</WorkflowStoreProvider>
	)
}

export const WorkflowEditorHostComponent = observer(
	({ displayType }: WorkflowEditorHostProps) => {
		console.log('🚨 Workflow Editor Host Rendering 🚨')

		const styles = useStyles()
		const history = useHistory()
		const theme = useTheme()

		const canvasRef = useRef<HTMLDivElement>(null)

		const { workflowContext, activityConfigurationStore } = useWorkflowStore()

		const [_, drop] = useDrop({
			accept: DraggableWorkflowType.ActivityConfiguration,
			collect: (monitor) => ({
				isOver: !!monitor.isOver({ shallow: true }),
				canDrop: !!monitor.canDrop(),
			}),
			drop: (item, monitor) => {
				if (monitor.didDrop()) return

				const container = canvasRef.current
				if (!container) throw new Error('container ref not found!')

				const coordinates = monitor.getSourceClientOffset()

				if (coordinates === null)
					throw new Error('no coordinates found for dropped item')

				handleAddActivity(
					(item as ActivityConfiguration).definitionId,
					Math.round(coordinates.x - container.getBoundingClientRect().left),
					Math.round(coordinates.y - container.getBoundingClientRect().top),
				)
			},
		})

		const handleAddActivity = (id: string, left: number, top: number) => {
			const configuration = activityConfigurationStore.getConfiguration(id)
			if (!configuration) throw `❌ no definition with id ${id} available`

			// TODO move lifetimes to server-side only
			const newDefinition: BaseActivityDefinitionModel = {
				activityTypeId: configuration.definitionId,
				description: configuration.description,
				displayName: configuration.displayName,
				left: left,
				top: top,
				lifetime: Lifetime.Transient,
				state: cloneDeep(configuration.initialState),
				uniqueId: uuid(),
				workflowDefinitionId: workflowContext.definitionId ?? 0,
			}

			workflowContext.addDefinition(newDefinition)
		}

		return (
			<>
				{!workflowContext.hasFinishedLoading ||
				!activityConfigurationStore.hasFinishedLoading ? (
					<FullscreenSpinner />
				) : (
					<div className={styles.canvasScroller}>
						<ActivityDrawer
							displayType={displayType}
							onCloseDrawer={action(
								() =>
									(workflowContext.selectedActivityDefinitionId = undefined),
							)}
							onRemoveActivity={action((id) => {
								workflowContext.removeDefinition(id)

								workflowContext.selectedActivityDefinitionId = undefined
							})}
						/>
						<Box className={styles.canvas} ref={drop}>
							<WorkflowStatusBox displayType={displayType} />
							<WorkflowEditor
								displayType={displayType}
								canvasRef={canvasRef}
								onSelectActivity={action((id) => {
									workflowContext.selectedActivityDefinitionId = id
								})}
							/>
							<Tooltip title="Back">
								<Box
									component={IconButton}
									position="absolute"
									left={'0.25%'}
									top={'0.25%'}
									zIndex={theme.zIndex.speedDial}
									onClick={() => history.goBack()}
									size="large"
									color="text.primary"
								>
									<ArrowBack />
								</Box>
							</Tooltip>
						</Box>
					</div>
				)}
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	canvasScroller: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},
	canvas: {
		height: percent(100),
		backgroundColor:
			theme.palette.mode === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[800],
		flexGrow: 1,
	},
}))
