import { Theme } from "@mui/material"
import { ConnectorSpec, EndpointOptions, jsPlumb, jsPlumbInstance, OverlaySpec } from "jsplumb"

export const createInstance = (container: string, readonly: boolean): jsPlumbInstance =>
	jsPlumb.getInstance({
		ConnectionsDetachable: !readonly,
		DragOptions: { cursor: 'pointer', zIndex: 1000 },
		Container: container,
		ConnectionOverlays: [
			['Arrow', {
				location: 1,
				visible: true,
				width: 11,
				length: 11
			}],
			['Label', {
				location: 0.5,
				id: 'label',
				cssClass: '' //TODO make a css class for this
			}]
		],
	})

export const createEndpointUuid = (activityId: string, outcome: string) => `activity-${activityId}-${outcome}`

export const getSourceEndpointOptions = (theme: Theme, activityId: string, outcome: string, executed: boolean) => {
	const stroke = theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[300]
	const connectorFill = executed ? theme.palette.success.main : '#999999'

	const x: EndpointOptions = {
		anchor: "Continuous",
		endpoint: 'Dot',
		paintStyle: {
			stroke: stroke,
			fill: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light,
			strokeWidth: 1,
		},
		isSource: true,
		connector: ['Flowchart', { stub: [40, 60], gap: 10, cornerRadius: 5, alwaysRespectStubs: true }] as ConnectorSpec,
		connectorStyle: {
			strokeWidth: 2,
			stroke: connectorFill
		},
		connectorHoverStyle: {
			strokeWidth: 2,
			stroke: connectorFill
		},
		connectorOverlays: [['Label', { location: [3, -1.5], cssClass: 'endpointSourceLabel' }]] as OverlaySpec[],
		hoverPaintStyle: {
			fill: stroke,
			stroke: stroke
		},
		dragOptions: {},
		uuid: createEndpointUuid(activityId, outcome),
		parameters: {
			outcome
		},
		scope: undefined,
		reattachConnections: true,
		maxConnections: 1,
		
	}

	return x;
}
