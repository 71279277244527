import { IConcreteCellInstance, ILayoutCellInstance, INullCellInstance, IRepeatCellInstance, IValueCellInstance, IVirtualCellInstance } from "../../FormHost/Types/CellInstance"
import { CellType } from "../../FormHost/Types/CellType"

export type IVirtualFormBuilderCellInstance = IVirtualCellInstance

type INonVirtualFormBuilderCellInstance = {
	properties: Record<string, unknown>
	ordinalPosition: number
}

export const virtualValueKey = `sym:$virtualValue`

export const isVirtualValueKey = (key: string): boolean => {
	return key === virtualValueKey
}

export type IConcreteFormBuilderCellInstance =
	INonVirtualFormBuilderCellInstance & IConcreteCellInstance

export type INullFormBuilderCellInstance =
	INonVirtualFormBuilderCellInstance & INullCellInstance

export type ILayoutFormBuilderCellInstance = {
	[virtualValueKey]: unknown
} & INonVirtualFormBuilderCellInstance &
	ILayoutCellInstance

export type IRepeatFormBuilderCellInstance = {
	[virtualValueKey]: unknown[]
} & INonVirtualFormBuilderCellInstance &
	IRepeatCellInstance

export type IValueFormBuilderCellInstance = {
	value: unknown
} & INonVirtualFormBuilderCellInstance &
	IValueCellInstance

export type ValueLikeFormBuilderCellInstance =
	| IValueFormBuilderCellInstance
	| ILayoutFormBuilderCellInstance
	| IRepeatFormBuilderCellInstance

export type FormBuilderCellInstance =
	| IConcreteFormBuilderCellInstance
	| IVirtualFormBuilderCellInstance
	| ValueLikeFormBuilderCellInstance
	| INullFormBuilderCellInstance

export function isValueLikeInstance(
	cell: FormBuilderCellInstance,
): cell is ValueLikeFormBuilderCellInstance {
	return (
		cell.type === CellType.Value ||
		cell.type === CellType.Layout ||
		cell.type === CellType.Repeat
	)
}
