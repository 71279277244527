import { ArrowBack } from '@mui/icons-material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Avatar, Box, CircularProgress, IconButton, Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import React, { ReactNode } from 'react'

type AuthenticationWrapperProps = {
	headerText?: string
	nextButtonText?: string
	nextDisabled?: boolean
	onNextClicked: () => void
	onBackClicked?: () => void

	isLoading?: boolean
	avatar?: ReactNode
}

export const AuthenticationWrapper = (
	props: React.PropsWithChildren<AuthenticationWrapperProps>,
) => {
	const styles = useWrapperStyles()

	return (
		<Paper className={styles.root}>
			<Avatar className={styles.avatar} color="primary">
				{props.avatar ?? <LockOutlinedIcon />}
			</Avatar>

			<Box alignSelf="flex-start">
				<Typography component="h1" variant="h5">
					<Box fontWeight="fontWeightMedium">
						{props.headerText ?? 'Sign In'}
					</Box>
				</Typography>
			</Box>

			<div className={styles.contentSection}>{props.children}</div>

			<div className={styles.actionSection}>
				{props.onBackClicked ? (
					<IconButton onClick={props.onBackClicked}>
						<ArrowBack />
					</IconButton>
				) : (
					<div></div>
				)}
				<div className={styles.loginButtonSection}>
					{props.isLoading !== undefined && props.isLoading ? (
						<CircularProgress />
					) : (
						<> </>
					)}

					<Button
						disabled={props.nextDisabled}
						className={styles.loginButton}
						variant="contained"
						onClick={props.onNextClicked}
					>
						{props.nextButtonText ?? 'Next'}
					</Button>
				</div>
			</div>
		</Paper>
	)
}

const useWrapperStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',

		height: 'fit-content',
		padding: theme.spacing(2),
		margin: theme.spacing(1),

		[theme.breakpoints.up('sm')]: {
			minWidth: px(450),
		},
	},

	avatar: {
		alignSelf: 'center',

		color: theme.palette.primary.contrastText.toString(),
		backgroundColor: theme.palette.primary.main,
	},

	contentSection: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),

		width: percent(100),

		alignSelf: 'flex-start',
	},

	loginButtonSection: {
		display: 'flex',
		alignSelf: 'flex-end',

		margin: theme.spacing(1),

		justifyContent: 'center',
		alignItems: 'center',
	},

	loginButton: {
		margin: theme.spacing(1),
		alignSelf: 'flex-end',

		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},

	actionSection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}))
