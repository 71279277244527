import { Box, Typography } from '@mui/material'
import { values } from 'mobx'
import { ReactNode } from 'react'
import { NIL, v4 as uuidv4 } from 'uuid'
import {
	BaseFormPackageInstanceModel,
	CommitIntent,
	FormPackageInfo,
	SignatureStatus,
	SignatureValue,
} from '../../api/DTOtemp'
import {
	ForwardedFormPackageWorkItemState,
	ReadWorkItemModel,
} from '../../api/clients/workItems/DTOs'
import { beforeSubmitFormEventEmitter } from '../../modules/FormBuilderInterop/EventBus/BuiltInEvents/BeforeSubmitFormEvent'
import { FormHost } from '../../modules/FormHost/FormHost/FormHost'
import { IUnifiedFormHost } from '../../modules/FormHost/FormHost/IUnifiedFormHost'
import { CellType } from '../../modules/FormHost/Types/CellType'
import {
	addPackageToCache,
	sendCachedPackageToServer,
} from '../../services/offline/FormPackageBackgroundSync'
import { PackageSubmissionMutex } from '../../services/offline/FormPackageBackgroundSyncService'
import { DetailFormWithData } from '../../services/offline/FormPackageHandling'
import { dataUrlToBlob } from '../../utils/BlobUtils'
import {
	PersistenceStatus,
	ResourceType,
	TrackedResource,
} from './Attachments/AttachFilesDialog'
import { PackageHostPageContextProviderProps } from './PackageHostPageContext'

export enum FormPackageSubmitResult {
	Submitted = 0,
	Errored = 1,
	Canceled = 2,
}

export const submitFormPackage = (
	params: SavePackageParams & {
		intent: CommitIntent.Submit | CommitIntent.Approve | CommitIntent.Reject

		context: PackageHostPageContextProviderProps

		onSubmitError: (reason: ReactNode) => void
	},
): Promise<FormPackageSubmitResult> => {
	return submitFormPackageInternal(params)
}

const __makeSubmitError: (message: string) => ReactNode = (message: string) => {
	return (
		<Box display={'flex'} gap={1}>
			<Typography>{message}</Typography>
		</Box>
	)
}

const submitFormPackageInternal = async (
	params: SavePackageParams & {
		intent: CommitIntent.Submit | CommitIntent.Approve | CommitIntent.Reject

		context: PackageHostPageContextProviderProps

		onSubmitError: (reason: ReactNode) => void
	},
): Promise<FormPackageSubmitResult> => {
	/***
	 * add the signature images and set the signature values
	 * used in normal submitting and offline
	 */
	const addSignatures = async (formHost: IUnifiedFormHost) => {
		// so all signatures added for this form instance have the same sign date
		// rather than when we create the specific signature instance
		const signatureDate = new Date()

		// attach images of the signatures to the form
		for (const cellInstance of formHost.cellInstances.filter(
			(v) => v.type === CellType.Value,
		)) {
			if (
				cellInstance.type !== CellType.Value ||
				!isSignature(cellInstance.value)
			)
				continue

			const signature = cellInstance.value

			if (signature === undefined || signature.image === undefined) continue

			params.attachments.push({
				type: ResourceType.SignatureImage,
				file: new File(
					[dataUrlToBlob(signature.image)],
					`signature-${cellInstance.id}.png`,
					{ type: 'image/png' },
				),
				resourceId: uuidv4(),
				persistenceStatus: PersistenceStatus.New,
				packageAttachmentType: 'SignatureImages',
			})

			// don't rewrite the signature's values if it's already been signed
			if (
				(cellInstance.value as SignatureValue).status ===
				SignatureStatus.Untracked
			) {
				if (signature.ipAddress === undefined)
					signature.ipAddress = params.context.ipAddress ?? 'Offline'
				// TODO getLocation()

				cellInstance.value = {
					emailAddress: signature.emailAddress,
					fullName: signature.fullName,
					image: signature.image,
					ipAddress: signature.ipAddress,
					timestamp: signatureDate,
					status: signature.status,
				} as SignatureValue
			}
		}
	}

	for (const formHost of params.formHosts) {
		const form = params.forms.find((v) => v.id === formHost.formId)

		if (form === undefined) {
			params.onSubmitError(`Could not find form with id ${formHost.formId}`)
			throw new Error(`could not find form ${formHost.formId}`)
		}
		// TODO add Approve/Reject property to event when we figure those out
		const formHasErrors = await beforeSubmitFormEventEmitter(
			formHost.eventBus,
			{},
			{
				formHost: formHost,
				elementTag: NIL,
				definitionId: NIL,
				instanceId: NIL,
			},
		).then(() => {
			return values(params.context.validationErrors).length > 0
		})

		if (formHasErrors) {
			params.onSubmitError(
				` Form ${form.name} does not meet all the requirements`,
			)
			return FormPackageSubmitResult.Errored
		}

		// use the form's onBeforeSubmit hook to check if we can submit or not
		if (formHost.controlApi && formHost.controlApi.onBeforeSubmit) {
			const onBeforeSubmitResult = formHost.controlApi.onBeforeSubmit()

			if (!onBeforeSubmitResult.canSubmit) {
				params.onSubmitError(
					onBeforeSubmitResult.errorMessage ??
						`Form ${form.name} does not meet all the requirements`,
				)

				console.log(
					`Form ${form.id} did not meet requirements set in onBeforeSubmit: ${onBeforeSubmitResult.errorMessage}`,
				)

				// don't submit if the form says we can't
				return FormPackageSubmitResult.Errored
			}
		}

		// add the signature images and set the untracked signature values

		addSignatures(formHost)
	}

	try {
		return await savePackage(params)
	} catch (e) {
		console.error(e)

		params.onSubmitError(
			__makeSubmitError('An error occurred while submitting the package'),
		)

		return FormPackageSubmitResult.Errored
	}

	return FormPackageSubmitResult.Submitted
}

/**
 * check if a value is a signature
 * @param value the value that we're checking to see if it's a signature
 * @returns true if the value is a signature, false otherwise
 */
const isSignature = (value: unknown): value is SignatureValue => {
	return !!value && (value as SignatureValue).image !== undefined
}

type SavePackageParams = {
	intent: CommitIntent
	attachments: TrackedResource[]
	formPackage: FormPackageInfo
	formHosts: FormHost[]
	forms: DetailFormWithData[]
	savedInstanceId?: string
	description?: string
	workItem?: ReadWorkItemModel<ForwardedFormPackageWorkItemState>
	isPackageFromServer: boolean

	onSaveInstance: (
		baseFormPackageInstance: BaseFormPackageInstanceModel,
		formInstances: DetailFormWithData[],
		isPackageFromServer: boolean,
		existingInstanceId?: number,
	) => Promise<number>
}

/**
 *
 * @param intent save, submit, approve, or reject
 * @param description - only required for saving forms
 * @param attachedForms - may exist for submitting/approving/rejecting forms only
 */
export const savePackage = async (
	params: SavePackageParams,
): Promise<FormPackageSubmitResult> => {
	const release = await PackageSubmissionMutex.acquire()

	try {
		const [key, cachedPackage] = await addPackageToCache({
			intent: params.intent,
			formPackage: params.formPackage,
			formHosts: params.formHosts,
			forms: params.forms,
			resources: params.attachments,
			savedInstanceId: params.savedInstanceId,
			description: params.description,
			workItem: params.workItem,
			isPackageFromServer: params.isPackageFromServer,
		})

		// we just need to act like we've submitted here, so the animation for
		// the submit will end, since we can't do anything behind the scenes
		if (!navigator.onLine) return FormPackageSubmitResult.Submitted

		return await sendCachedPackageToServer(
			cachedPackage,
			key,
			params.onSaveInstance,
		)
	} finally {
		release()
	}
}
