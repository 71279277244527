import { Redirect, Route, RouteProps } from 'react-router-dom'
import { SessionService } from '../../services/session/SessionService'

export default function AnonymousRoute({ children, ...rest }: RouteProps) {
	const sessionService = new SessionService()

	return (
		<Route
			{...rest}
			render={({ location }) =>
				!sessionService.isLoggedIn ? (
					<>{children}</>
				) : (
					<Redirect
						to={{
							pathname: '/_user/_home',
							state: { from: location },
						}}
					></Redirect>
				)
			}
		/>
	)
}
