import {
	Box,
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Typography,
} from '@mui/material'
import { useState } from 'react'
import { WorkflowSettings } from './Types'

type UpdateWorkflowSettingsDialogProps = {
	onCancel: () => void
	onConfirm: (settings: WorkflowSettings) => void
	settings: WorkflowSettings
}

export const UpdateWorkflowSettingsDialog = (
	props: UpdateWorkflowSettingsDialogProps,
) => {
	const [settings, setSettings] = useState(props.settings)

	return (
		<>
			<DialogTitle>Settings</DialogTitle>
			<DialogContent>
				<Box display="flex" flexDirection="column">
					<Box display="flex" gap={1} justifyContent="flex-start">
						<Box
							component={FormControlLabel}
							alignItems="flex-start"
							control={
								<Checkbox
									checked={settings.enabled}
									onChange={(evt) => {
										setSettings({
											...settings,
											enabled: evt.currentTarget.checked,
										})
									}}
									value={settings.enabled}
								/>
							}
							label={
								<Box
									display="flex"
									flexDirection="column"
									justifyContent="flex-start"
									paddingTop={0.5}
								>
									<Typography variant="subtitle1">Enabled</Typography>
									<Typography variant="body2">
										Enabling a workflow allows for the workflow to start when
										triggered. Disabling a workflow will prevent the workflow
										from running.
									</Typography>
								</Box>
							}
						/>
					</Box>
				</Box>
				<DialogActions>
					<Button variant="outlined" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button variant="contained" onClick={() => props.onConfirm(settings)}>
						Save
					</Button>
				</DialogActions>
			</DialogContent>
		</>
	)
}
