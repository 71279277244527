import { Add, Delete } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import {
	GroupPackageAssociationsClient,
	GroupsClient,
} from '../../../../../../../../api/clients/identity'
import { BaseGroup } from '../../../../../../../../api/DTO'
import { GroupPackageSimpleAssociation } from '../../../../../../../../api/DTOtemp'
import { usePrompts } from '../../../../../../../../services/notifications/PromptService'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { AddValuesDialog } from '../../../../../Groups/AddValuesDialog'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'

export const SecurityAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			organizationGroups: [] as BaseGroup[], // all groups in organization
			packageGroups: [] as BaseGroup[], // groups associated with this package

			// the groups that don't belong to the package to display when adding new groups
			get unassociatedGroups() {
				return this.organizationGroups.filter(
					(orgGroup) =>
						!this.packageGroups
							.map((packageGroup) => packageGroup.id)
							.includes(orgGroup.id),
				)
			},
		}))

		const groupPackagesClient = new GroupPackageAssociationsClient()

		const getPackageGroups = useCallback(() => {
			return groupPackagesClient
				.GetPackageAssociations(props.packageId)
				.then((response) => response.data.map((v) => v.group))
		}, [])

		const getOrganizationGroups = useCallback(() => {
			const groupsClient = new GroupsClient()

			return groupsClient.GetAllGroups().then((response) => response.data)
		}, [])

		useEffect(() => {
			;(async () => {
				const packageGroupsPromise = getPackageGroups()
				const organizationGroupsPromise = getOrganizationGroups()

				const packageGroups = await packageGroupsPromise
				const organizationGroups = await organizationGroupsPromise

				runInAction(() => {
					localStore.packageGroups = packageGroups
					localStore.organizationGroups = organizationGroups
				})

				getPackageGroups()
			})()
		}, [])

		const handleAddGroups = async (groups: BaseGroup[]) => {
			const associations = groups.map(
				(group) =>
					({
						packageId: props.packageId,
						groupId: group.id,
					} as GroupPackageSimpleAssociation),
			)

			try {
				await groupPackagesClient.BulkAddPackageAssociations(associations)

				const packageGroups = await getPackageGroups()

				runInAction(() => {
					localStore.packageGroups = packageGroups
				})

				toastService.displayToast({ message: 'Groups added', area: 'global' })
			} catch {
				toastService.displayToast({
					message: 'Error adding groups',
					area: 'global',
				})
			}
		}

		const handleRemoveGroup = async (groupId: number) => {
			try {
				const { data } = await groupPackagesClient.GetPackageAssociations(
					props.packageId,
					groupId,
				)

				if (data.length > 1)
					throw new Error(
						`found multiple associations for group id ${groupId} and package ${props.packageId}`,
					)
				if (data.length < 1) return

				const associationToRemove = data[0]

				await groupPackagesClient.RemoveAssociation(associationToRemove.id)

				const packageGroups = await getPackageGroups()

				runInAction(() => {
					localStore.packageGroups = packageGroups
				})

				toastService.displayToast({ message: 'Group removed', area: 'global' })
			} catch {
				toastService.displayToast({
					message: 'Error removing group',
					area: 'global',
				})
			}
		}

		return (
			<AdministrationPageContainer
				title="Form Package Security"
				actions={
					<SecuritySectionButtons
						unassociatedGroups={localStore.unassociatedGroups}
						onAddGroups={handleAddGroups}
					/>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={4}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<SecuritySection
						groups={localStore.packageGroups}
						onRemoveGroup={handleRemoveGroup}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type SecuritySectionProps = {
	groups: BaseGroup[]
	onRemoveGroup: (groupId: number) => void
}

const SecuritySection = observer((props: SecuritySectionProps) => {
	const styles = useStyles()
	const promptService = usePrompts()

	return (
		<Fade in unmountOnExit>
			<TableContainer className={styles.tableContainer} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Group Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.groups.map((group) => (
							<TableRow key={group.id}>
								<TableCell>{group.name}</TableCell>
								<TableCell>{group.description}</TableCell>
								<TableCell align="right">
									<IconButton
										onClick={() => {
											promptService
												.showDialog((props) => (
													<ConfirmRemoveGroupPrompt
														groupName={group.name}
														onCancel={() => props.close('cancel')}
														onConfirm={() => props.close('okay')}
													/>
												))
												.then((v) => {
													if (v == 'okay') props.onRemoveGroup(group.id)
												})
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
})

type SecuritySectionButtonsProps = {
	unassociatedGroups: BaseGroup[] // groups that don't already belong to package
	onAddGroups: (groups: BaseGroup[]) => void
}

export const SecuritySectionButtons = observer(
	(props: SecuritySectionButtonsProps) => {
		const [dialogOpen, setDialogOpen] = useState(false)

		const handleCloseDialog = useCallback(() => {
			setDialogOpen(false)
		}, [])

		const handleAddGroups = useCallback((groups: BaseGroup[]) => {
			props.onAddGroups(groups)
			setDialogOpen(false)
		}, [])

		return (
			<>
				<div>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Add />}
						onClick={() => setDialogOpen(true)}
					>
						Add Group
					</Button>
				</div>
				<AddValuesDialog<BaseGroup>
					open={dialogOpen}
					onClose={handleCloseDialog}
					onCommit={handleAddGroups}
					getLabel={(group: BaseGroup) => `${group.name}`}
					renderOption={(group) => {
						return (
							<>
								<Typography color="textPrimary" variant="subtitle2">
									{group.name}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									{group.description}
								</Typography>
							</>
						)
					}}
					options={props.unassociatedGroups}
					placeholder="Search groups"
					addText="Add Groups"
					header={`Add Groups to Package`}
				/>
			</>
		)
	},
)

type ConfirmRemoveGroupPromptProps = {
	groupName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmRemoveGroupPrompt = (props: ConfirmRemoveGroupPromptProps) => {
	return (
		<>
			<DialogTitle>Confirm Remove Association</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to remove the <b>{props.groupName}</b>{' '}
					group from the form package?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'auto',
	},
}))
