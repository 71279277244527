import { SystemUpdateAlt } from '@mui/icons-material'
import { Theme } from '@mui/material'
import { blue } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { useDrop } from 'react-dnd'
import { NullContextType } from '../../../../../FormBuilderCore/cells/rendering/contexts/ContextTypes'
import { DraggableFormHost } from '../../DraggableFormHost'
import {
	DraggableItemType,
	isDraggableComponent,
	isDraggableDefinition,
} from '../../Types'
import { useGeneralStyles } from './General'
import { isRecursiveCycle } from './Utils'

export const NullDustbinComponent = (props: NullContextType) => {
	const styles = useStyles()
	const standardStyles = useGeneralStyles()

	const [{ isOver, canDrop }, drop] = useDrop({
		accept: DraggableItemType.FormBuilderComponent,
		collect: (monitor) => ({
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: !!monitor.canDrop(),
		}),
		canDrop: (item) => {
			if (isDraggableDefinition(item)) {
				if (
					isRecursiveCycle(
						item,
						props.cellInstance.definitionId,
						props.formHost as DraggableFormHost,
					)
				)
					return false

				if (item.definitionId === props.cellInstance.definitionId) return false
			}

			return true
		},
		drop: (item, monitor) => {
			if (monitor.didDrop()) return

			const formHost = props.formHost as DraggableFormHost

			if (isDraggableDefinition(item)) {
				formHost.swapCells(item.definitionId, props.cellInstance.definitionId)
			}

			if (isDraggableComponent(item)) {
				formHost.createDefinition(
					item.componentRegistrationId,
					props.cellInstance.id,
				)
			}
		},
	})

	// don't capture the click here, if we click on a null dustbin we probably want its parent
	return (
		<div className={standardStyles.dropContainerStyle} ref={drop}>
			<div
				className={clsx(styles.overlay, {
					[styles.overlayColor]: isOver && canDrop,
				})}
			>
				<div className={styles.textTheme}>
					<SystemUpdateAlt color="primary" className={styles.iconStyle} />
				</div>
			</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	textTheme: {
		display: 'flex',
		flex: '1',

		textAlign: 'center',
		justifyContent: 'center',

		color:
			theme.palette.mode === 'light'
				? theme.palette.grey[400]
				: theme.palette.grey[600],
	},

	overlay: {
		display: 'flex',
		flex: '1',
	},

	overlayColor: {
		display: 'flex',
		flex: '1',

		opacity: '1',
		backgroundColor: blue[200],
	},

	iconStyle: {
		margin: theme.spacing(1),
	},
}))
