import { AxiosResponse } from "axios";
import { GroupReduced } from '../../../api/DTOtemp';
import { BaseGroup, GroupNameId as GroupNameModel } from "../../DTO";
import SecureClient from '../SecureClient';

export class GroupsClient extends SecureClient {
	/**
	 * Create a new group for the selected organization
	 * @param model 
	 */
	public CreateGroup(model: GroupReduced)
		: Promise<AxiosResponse<BaseGroup>> {
		return this._api.post<BaseGroup>(`groups`,
			JSON.stringify(model),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader('application/problem+json'),
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Get a list of all groups in an organization
	 */
	public GetAllGroups()
		: Promise<AxiosResponse<BaseGroup[]>> {
		return this._api.get<BaseGroup[]>(
			`groups`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			})
	}

	/**
	 * Get the specified group
	 * @param groupId 
	 */
	public GetGroup(groupId: number)
		: Promise<AxiosResponse<BaseGroup>> {
		return this._api.get<BaseGroup>(
			`groups/${groupId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	public GetGroupName(groupId: number): Promise<AxiosResponse<GroupNameModel>> {
		return this._api.get<GroupNameModel>(`groups/${groupId}/Name`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
			}
		})
	}
	
	/**
	 * Update basic group information
	 * @param group 
	 * @param groupId 
	 */
	public UpdateGroup(group: GroupReduced, groupId: number)
		: Promise<AxiosResponse<BaseGroup>> {
		return this._api.put<BaseGroup>(
			`groups/${groupId}`,
			JSON.stringify(group),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader(),
					...this.acceptHeader('application/problem+json')
				}
			}
		)
	}

	/**
	 * Delete the group
	 * @param groupId 
	 */
	public DeleteGroup(groupId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`groups/${groupId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			})
	}

}