import { makeAutoObservable } from 'mobx'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { FormPackageInfo } from '../../../api/DTOtemp'
import { TrackedResource } from './AttachFilesDialog'

export class AttachmentApi {
	private _formPackageInfo: FormPackageInfo

	private _attachments: TrackedResource[] = []

	readonly attachmentRequests: AttachmentRequest[] = []

	get attachments(): ReadonlyArray<TrackedResource> {
		return this._attachments
	}

	constructor(
		formPackageInfo: FormPackageInfo,
		attachmentCollection: TrackedResource[],
	) {
		this._formPackageInfo = formPackageInfo
		this._attachments = attachmentCollection

		makeAutoObservable(this)
	}

	addAttachments(attachments: TrackedResource[]) {
		this._attachments.push(...attachments)
	}

	requestAttachments(attachmentType?: string): Promise<TrackedResource[]> {
		console.log('requesting attachments!')
		const promise = new Promise<TrackedResource[]>((resolve) => {
			const attachmentRequest = new AttachmentRequest(
				attachmentType,
				(attachments) => {
					// only one is processed at a time so we can assume that
					// if this is being called it was itself that was removed
					resolve(
						new Promise<TrackedResource[]>((resolve) => {
							this.attachmentRequests[0].active = false
							setTimeout(() => {
								this.attachmentRequests.shift()
								resolve(attachments)
								// this timeout is here for the transition duration in
								// the modal
							}, 250)
						}),
					)
				},
			)

			console.log('pushing attachment request')
			this.attachmentRequests.push(attachmentRequest)
		})

		return promise
	}

	removeAttachment(fileName: string) {
		const indexToRemove = this._attachments.findIndex(
			(v) => v.file.name === fileName,
		)
		if (indexToRemove < 0) this._attachments.splice(indexToRemove, 1)
	}

	setRequiredAttachmentType = (attachmentTypeName: string, required = true) => {
		const attachmentType =
			this._formPackageInfo.configuration.attachmentTypes.find(
				(v) => v.name === attachmentTypeName,
			)

		if (attachmentType === undefined)
			throw new Error(
				`could not find attachment type with name '${attachmentTypeName}'`,
			)
		attachmentType.required = required
	}
}

export class AttachmentRequest {
	readonly attachmentType?: string

	active = true

	constructor(
		attachmentTypeName: string | undefined,
		resolver: (value: TrackedResource[]) => void,
	) {
		this.attachmentType = attachmentTypeName
		this.resolver = resolver

		makeAutoObservable(this)
	}

	public resolver: (value: TrackedResource[]) => void
}

const attachmentsApiContext = createContext<AttachmentApi | undefined>(
	undefined,
)

type AttachmentsApiContextProps = {
	package: FormPackageInfo
	existingAttachments: TrackedResource[]
}

export const AttachmentsApiContext = (
	props: PropsWithChildren<AttachmentsApiContextProps>,
) => {
	const api = useMemo(() => {
		return new AttachmentApi(props.package, props.existingAttachments)
	}, [props.package, ...props.existingAttachments])

	return (
		<attachmentsApiContext.Provider value={api}>
			{props.children}
		</attachmentsApiContext.Provider>
	)
}

export function useAttachmentsApi(): AttachmentApi {
	const api = useContext(attachmentsApiContext)
	if (api === undefined) throw new Error('attachments api was not defined')
	return api
}
