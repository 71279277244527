import { Modal } from '@mui/material'
import { action, makeAutoObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'

type Modal = {
	show: boolean
	body?: React.ReactNode
}

export class ModalStore {
	@observable
	modal: Modal = {
		show: false,
	}

	constructor() {
		makeAutoObservable(this)
	}

	@action
	showModal = (body: React.ReactNode) => {
		this.modal.show = true
		this.modal.body = body
	}

	@action
	closeModal = () => {
		this.modal.show = false
		this.modal.body = undefined
	}
}

type ModalWrapperProps = {
	store: ModalStore
}

export const ModalWrapper: React.FC<ModalWrapperProps> = observer(
	({ store }: ModalWrapperProps) => {
		return (
			<Modal open={store.modal.show}>
				<div>{store.modal.body ?? <></>}</div>
			</Modal>
		)
	},
)
