import React, { PropsWithChildren, useEffect } from 'react'
import MapInteraction, {
	MapInteractionControllerProps,
	TranslationScale,
} from './MapInteraction'
import { MapInteractionStore } from './MapInteractionStore'
import { Point } from './geometry'

export type HostedMapInteractionProps = {
	mapInteractionStore: MapInteractionStore
}

/*
  This component provides a map like interaction to any content that you place in it. It will let
  the user zoom and pan the children by scaling and translating props.children using css.
*/
const HostedMapInteraction: React.FC<MapInteractionControllerProps> = (
	props: MapInteractionControllerProps,
) => {
	const Translator: React.ComponentType<
		PropsWithChildren<HostedMapInteractionProps>
	> = ({
		children,
		mapInteractionStore,
	}: PropsWithChildren<HostedMapInteractionProps>) => {
		console.log('rerender')

		const transform = (innerTranslation: Point, innerScale: number): string => {
			return `translate(${innerTranslation.x}px, ${innerTranslation.y}px) scale(${innerScale})`
		}

		const initialDotDistance = 20

		const ref = React.createRef<HTMLDivElement>()

		const patternRef = React.createRef<SVGPatternElement>()
		const circleRef = React.createRef<SVGCircleElement>()

		useEffect((): (() => void) => {
			// todo find a better name
			const scaleCallback = (translationScale: TranslationScale) => {
				if (ref.current == null)
					throw new Error('cannot change the translation or scale ☹')
				ref.current.style.transform = transform(
					translationScale.translation,
					translationScale.scale,
				)

				if (patternRef.current == null)
					throw new Error('cannot change translation scale of pattern')

				const dotDistance = translationScale.scale * initialDotDistance
				const dotThickness = translationScale.scale

				const pattern = patternRef.current
				pattern.setAttribute(
					'x',
					(translationScale.translation.x % dotDistance).toString(),
				)
				pattern.setAttribute(
					'y',
					(translationScale.translation.y % dotDistance).toString(),
				)
				pattern.setAttribute('width', dotDistance.toString())
				pattern.setAttribute('height', dotDistance.toString())

				const circle = circleRef.current!

				circle.setAttribute('cx', dotThickness.toString())
				circle.setAttribute('cy', dotThickness.toString())
				circle.setAttribute('r', dotThickness.toString())
			}

			mapInteractionStore.registerTranslationScaleCallback(scaleCallback)

			return () => {
				mapInteractionStore.removeTranslationScaleCallback(scaleCallback)
			}
		})

		return (
			<>
				<div
					style={{
						height: '100%',
						width: '100%',
						position: 'relative', // for absolutely positioned children
						overflow: 'hidden',
						touchAction: 'none', // Not supported in Safari :(
						msTouchAction: 'none',
						cursor: 'all-scroll',
						WebkitUserSelect: 'none',
						MozUserSelect: 'none',
						msUserSelect: 'none',
						zIndex: 1,
					}}
				>
					<div
						style={{
							display: 'inline-block', // size to content
							transform: transform(
								mapInteractionStore.translation,
								mapInteractionStore.scale,
							),
							transformOrigin: '0 0',
						}}
						ref={ref}
					>
						{children}
					</div>
				</div>

				<svg
					style={{
						width: '100%',
						height: '100%',
						left: '0',
						position: 'absolute',
						top: '0',
					}}
				>
					<pattern
						ref={patternRef}
						id="pattern-0"
						x={0}
						y={0}
						width={initialDotDistance}
						height={initialDotDistance}
						patternUnits="userSpaceOnUse"
					>
						<circle ref={circleRef} cx="1" cy="1" r="1" fill="#81818a"></circle>
					</pattern>
					<rect
						x="0"
						y="0"
						width="100%"
						height="100%"
						fill="url(#pattern-0)"
					></rect>
				</svg>
			</>
		)
	}

	return (
		<MapInteraction {...props} Translator={Translator}>
			{props.children}
		</MapInteraction>
	)
}

export default HostedMapInteraction
