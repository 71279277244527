import { Typography } from '@mui/material'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { BaseFormPackageInstanceModel } from '../../api/DTOtemp'
import { FormPackageClient } from '../../api/clients/identity'
import { AnonymousFormPackageInstancesClient } from '../../api/clients/identity/AnonymousFormPackageInstancesClient'
import { FullscreenSpinner } from '../../components/feedback/circular'
import {
	DetailFormWithData,
	StoredFormPackageDefinition,
	downloadFormPackage,
	getFullFormPackageVersion,
} from '../../services/offline/FormPackageHandling'
import { PackageApi, PackageApiStatus } from './PackageApi'
import { PackageHost } from './PackageHost'
import { PackageHostErrorPage } from './PackageHostErrorPage'
import { PackageHostPageContextProvider } from './PackageHostPageContext'

const AnonymousFormPackageHostPage = observer(() => {
	const params = useParams<{ packageId: string }>()

	const packageId = parseInt(params.packageId)

	if (isNaN(packageId)) throw new Error('invalid package id')

	const localStore = useLocalObservable(() => ({
		forbidden: false,
		storedPackage: undefined as StoredFormPackageDefinition | undefined,
	}))

	const packageApi = useMemo(() => {
		return new PackageApi(packageId)
	}, [packageId])

	useEffect(() => {
		const abortController = new AbortController()

		async function fetchFormsData() {
			const formPackageClient = new FormPackageClient()

			const signal = abortController.signal

			try {
				/*
				Because this API call is cached, we don't have to worry about checking
				whether or not we're online
				*/
				const { data: formPackageData } =
					await formPackageClient.GetFormPackageAnonymous(packageId)

				let fullFormPackageVersion = await getFullFormPackageVersion(
					formPackageData.versionId,
				)

				if (fullFormPackageVersion === undefined && navigator.onLine) {
					fullFormPackageVersion = await downloadFormPackage(
						formPackageData,
						abortController.signal,
					)
				} else if (fullFormPackageVersion === undefined && !navigator.onLine) {
					console.error('form package is not downloaded in cache')
					runInAction(() => {
						localStore.forbidden = true
					})
					return
				}

				runInAction(() => {
					console.log('signal status: ', signal.aborted)
					if (signal.aborted) return

					localStore.storedPackage = fullFormPackageVersion
				})
			} catch (e) {
				console.log('ERROR!', e)
				if (signal.aborted) return

				runInAction(() => {
					localStore.forbidden = true
				})
			}
		}

		fetchFormsData()

		return () => {
			abortController.abort()
		}
	}, [packageId])

	if (localStore.forbidden)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<>
						<Typography variant="h6">
							You don't have access to this package
						</Typography>{' '}
						<Typography variant="h6">or this package doesn't exist.</Typography>
					</>
				}
			/>
		)

	if (
		packageApi.status !== PackageApiStatus.Complete ||
		localStore.storedPackage === undefined
	)
		return <FullscreenSpinner />

	if (localStore.storedPackage.forms.length === 0)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<Typography variant="h6">
						There are no forms to fill out in this package.
					</Typography>
				}
			/>
		)

	return (
		<PackageHostPageContextProvider validationErrors={observable({})}>
			<PackageHost
				resources={[]}
				packageId={packageId}
				packageApi={packageApi}
				formPackage={localStore.storedPackage.packageInfo}
				forms={localStore.storedPackage.forms}
				anonymous={true}
				isPackageFromServer={false}
				onSaveInstance={handleSubmitAnonymousPackage}
			/>
		</PackageHostPageContextProvider>
	)
})

export const handleSubmitAnonymousPackage = async (
	baseFormPackageInstance: BaseFormPackageInstanceModel,
	forms: DetailFormWithData[],
): Promise<number> => {
	const instancesClient = new AnonymousFormPackageInstancesClient()

	// if an instance exists, overwrite it, else create a new one
	const { data: newInstance } = await instancesClient.CreateFormPackageInstance(
		baseFormPackageInstance,
		forms,
	)

	return newInstance.id
}

export default AnonymousFormPackageHostPage
