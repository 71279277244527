import { Box, MenuItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { px } from 'csx'
import { Program } from 'estree'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { useFormEventsContext } from '../../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/AdvancedEventsSection/FormEventsContext'
import { getCellDisplayName } from '../../../../../utils/getCellDisplayName'
import { CellDefinition } from '../../../../FormHost/Types/CellDefinition'
import { CellType } from '../../../../FormHost/Types/CellType'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { FieldEventType, RendererComponent } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import Editor from '../../internal/Editor'
import NodeBodyWrapper from '../../internal/NodeBodyWrapper'
import useColorScheme from '../../internal/useColorScheme'

const FieldEventComponent = observer((props: RendererComponent<Program>) => {
	const styles = useStyles()
	const [color, textColor] = useColorScheme(ComponentCategory.Events)

	const formEventsContext = useFormEventsContext()

	const formsInfo = props.node.formsInfo

	// this should not happen because we have a check in the parent that leads us here
	if (formsInfo === undefined || formsInfo.type !== 'FieldEvent')
		throw new Error(
			`FieldEvent component requires FormsInfo type to be FieldEvent, is actually ${formsInfo?.type}`,
		)

	// fields that are available to the currently selected form
	const availableFields = useMemo<CellDefinition[]>(() => {
		const form =
			formEventsContext.type === 'Form'
				? formEventsContext.form
				: formEventsContext.forms.find((v) => v.id === formsInfo.formId)

		if (form === undefined) return []

		return form.metadata.cellDefinitions.filter((v) => v.name !== '')
	}, [formsInfo.formId])

	const eventType = formsInfo.event

	// if we are in the form page, we know we're only working with the current form
	if (formEventsContext.type === 'Form') {
		runInAction(() => {
			formsInfo.formId = formEventsContext.form.id
		})
	}

	return (
		<NodeBodyWrapper
			color={color}
			topSection={
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={2}
					width="fit-content"
					margin={1}
				>
					<Typography color={textColor}>On</Typography>

					{formEventsContext.type === 'Form' ? (
						<Typography color={textColor} fontStyle="italic">
							{formEventsContext.form.name}
						</Typography>
					) : (
						<ColoredFormControl formControlColor={textColor}>
							<ColoredSelect
								variant="standard"
								selectColor={textColor}
								fullWidth
								className={styles.select}
								value={formsInfo.formId}
								size="small"
								onChange={action(
									(event) =>
										(formsInfo.formId = parseInt(event.target.value as string)),
								)}
							>
								<MenuItem disabled value={0}>
									<Typography fontStyle="italic">Form</Typography>
								</MenuItem>
								{formEventsContext.forms.map((form) => (
									<MenuItem key={form.id} value={form.id}>
										{form.name}
									</MenuItem>
								))}
							</ColoredSelect>
						</ColoredFormControl>
					)}

					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							variant="standard"
							fullWidth
							selectColor={textColor}
							className={styles.select}
							value={formsInfo.definitionId}
							size="small"
							displayEmpty
							onChange={action(
								(event) =>
									(formsInfo.definitionId = event.target.value as string),
							)}
						>
							<MenuItem disabled value="">
								<em>Field</em>
							</MenuItem>
							{availableFields
								.filter((v) => v.type === CellType.Value)
								.map((field) => (
									<MenuItem key={field.id} value={field.id}>
										{getCellDisplayName(availableFields, field)}
									</MenuItem>
								))}
						</ColoredSelect>
					</ColoredFormControl>

					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							variant="standard"
							fullWidth
							selectColor={textColor}
							className={styles.select}
							value={eventType}
							label="Form"
							size="small"
							onChange={action(
								(event) =>
									(formsInfo.event = event.target.value as FieldEventType),
							)}
						>
							{Object.values(FieldEventType).map((eventType) => (
								<MenuItem key={eventType} value={eventType}>
									{eventType}
								</MenuItem>
							))}
						</ColoredSelect>
					</ColoredFormControl>
				</Box>
			}
		>
			<Editor node={props.node as Program} />
		</NodeBodyWrapper>
	)
})

const useStyles = makeStyles(() => ({
	select: {
		minWidth: px(100), // i do not like min width but without it the select is so teeny
	},
}))

export default FieldEventComponent
