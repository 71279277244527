
export default class Cookie {
	static get<T extends string>(key: string, def: T): T {
		const result = document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`)
		return result ? (result[2] as T) : def
	}

	static set(key: string, value: string, days?: number) {
		let cookie = `${key}=${value};path=/`
		if (days && days > 0) {
			const expires = new Date()
			expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * days)
			cookie += `;expires=${expires.toUTCString()}`
		}
		document.cookie = cookie
	}

	static del(key: string) {
		Cookie.set(key, '', -1)
	}
}
