import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { UserPropertiesClient } from '../../../../api/clients/identity'
import { UserProperty, UserPropertySimple } from '../../../../api/DTOtemp'
import { DeleteDialog } from '../../../../components/dialogs/DeleteDialog'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import IconButtonLink from '../../../../components/links/IconButtonLink'
import { toastService } from '../../../../services/notifications/ToastService'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { CreateUserPropertyDialog } from './CreateUserPropertyDialog'

export const UserPropertiesPage = observer(() => {
	const userPropertiesClient = new UserPropertiesClient()
	const history = useHistory()

	const store = useLocalObservable(() => ({
		properties: undefined as UserProperty[] | undefined,
		createPropertyOpen: false,
		propertyToDelete: undefined as UserProperty | undefined,
	}))

	useEffect(() => {
		userPropertiesClient.GetUserProperties(true).then(
			action((v) => {
				store.properties = v.data
			}),
		)
	}, [])

	const handleShowCreateProperty = action(() => {
		store.createPropertyOpen = true
	})

	const handleCloseCreateProperty = action(() => {
		store.createPropertyOpen = false
	})

	const handleCreateProperty = async (property: UserPropertySimple) => {
		store.createPropertyOpen = false

		userPropertiesClient
			.CreateUserProperty(property)
			.then((response) => {
				const propertyId = response.data.id

				toastService.displayToast({
					message: 'User property created',
					area: 'global',
				})

				history.push(`/_administration/_user-properties/${propertyId}`)
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error creating user property',
					area: 'global',
				})
			})
	}

	const handleDeleteClicked = action((property: UserProperty) => {
		store.propertyToDelete = property
	})

	const handleDeleteDialogClosed = action(() => {
		store.propertyToDelete = undefined
	})

	const handleDeleteProperty = (propertyId: number) => {
		userPropertiesClient
			.DeleteUserProperty(propertyId)
			.then(() => {
				toastService.displayToast({
					message: 'User property deleted',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error deleting user property',
					area: 'global',
				})
			})

		runInAction(() => {
			if (store.properties !== undefined) {
				const propertyToDelete = store.properties
					.map((v) => v.id)
					.indexOf(propertyId)
				if (propertyToDelete !== -1)
					store.properties.splice(propertyToDelete, 1)
			}
		})
	}

	return (
		<>
			<AdministrationPageContainer
				title="User Properties"
				actions={
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Edit />}
							onClick={handleShowCreateProperty}
						>
							Create Property
						</Button>
					</div>
				}
			>
				{store.properties === undefined ? (
					<FullscreenSpinner />
				) : (
					<UserPropertiesTable
						userProperties={store.properties}
						onDeleteProperty={handleDeleteClicked}
					/>
				)}
			</AdministrationPageContainer>
			<CreateUserPropertyDialog
				open={store.createPropertyOpen}
				userPropertyNames={store.properties?.map((v) => v.name) ?? []}
				createUserProperty={handleCreateProperty}
				onClose={handleCloseCreateProperty}
			/>
			{store.propertyToDelete !== undefined && (
				<DeleteDialog<number>
					open={store.propertyToDelete !== undefined}
					itemName={store.propertyToDelete?.name}
					itemId={store.propertyToDelete?.id}
					onClose={handleDeleteDialogClosed}
					onDelete={handleDeleteProperty}
				/>
			)}
		</>
	)
})

type UserPropertiesTableProps = {
	userProperties: UserProperty[]
	onDeleteProperty: (property: UserProperty) => void
}

const UserPropertiesTable = (props: UserPropertiesTableProps) => {
	const styles = useStyles()
	const { url } = useRouteMatch()

	return (
		<Fade in>
			<TableContainer className={styles.tableRoot} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>User Editable</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.userProperties.map((property) => (
							<TableRow key={property.id}>
								<TableCell>{property.name}</TableCell>
								<TableCell>
									{property.userEditable ? 'true' : 'false'}
								</TableCell>
								<TableCell align="right">
									<IconButtonLink
										to={makeChildRoute(property.id.toString(), url)}
									>
										<Edit fontSize="small" />
									</IconButtonLink>
									<IconButton
										onClick={() => props.onDeleteProperty(property)}
										size="large"
									>
										<Delete fontSize="small" />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
