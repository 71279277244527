import { TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type NumberInputProps = {
	label: string
	allowDecimals: boolean
	hint?: string
}

export const NumberInput = observer((props: NumberInputProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()
	const styles = useStyles()

	return (
		<FixedHeaderWithHint
			className={styles.root}
			label={props.label}
			hint={props.hint}
		>
			<TextField
				className={styles.root}
				value={context.value ?? 0}
				onChange={action((v) => {
					const oldValue = context.value
					const newValue =
						v.currentTarget.value === ''
							? 0
							: props.allowDecimals
							? parseFloat(v.currentTarget.value)
							: parseInt(v.currentTarget.value)
					if (newValue === undefined || isNaN(newValue)) return

					context.onChange(newValue)
					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: oldValue,
							newValue: newValue,
						},
						{
							formHost: cellManager,
							elementTag: NumberInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				})}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: NumberInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
				type="number"
			/>
		</FixedHeaderWithHint>
	)
})

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
})

export const NumberInputElementTag = '06850138-d1ff-4ac5-96b0-cbe26e3b45f1'

const InternalNumberInputId = '27a4e7ff-48d0-48ea-8069-00a2e7d9104b'

export const InternalNumberInputDefinition = specifyInternalComponent(
	InternalNumberInputId,
	NumberInput,
	NumberInputElementTag,
	FieldType.Number,
	{
		label: 'Label',
		allowDecimals: true,
		hint: '',
	},
	0,
)
