import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentProps, createRef, useCallback, useState } from 'react'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import {
	CheckboxPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'

type PasswordInputProps = {
	label?: string
	hint?: string
	disableVisibility?: boolean
}

export const PasswordInput = observer((props: PasswordInputProps) => {
	const styles = useStyles()

	const inputRef = createRef<HTMLDivElement>()
	const context = useInputCellContext()
	const { cellManager } = useFormBuilderContext()

	const [passwordVisible, setPasswordVisible] = useState(false)

	const changeHandler = useCallback(
		action((v: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = v.currentTarget.value
			context.onChange(newValue)
			changeEventEmitter(
				cellManager.eventBus,
				{
					oldValue: context.value,
					newValue: newValue,
				},
				{
					formHost: cellManager,
					elementTag: PasswordInputElementTag,
					definitionId: context.cellInstance.definitionId,
					instanceId: context.cellInstance.id,
				},
			)
		}),
		[],
	)

	return (
		<FixedHeaderWithHint
			className={styles.formBuilderTextInputRoot}
			label={props.label}
			hint={props.hint}
		>
			<TextField
				fullWidth
				variant="outlined"
				size="small"
				value={context.value}
				onChange={changeHandler}
				autoComplete="off"
				inputRef={inputRef}
				type={passwordVisible ? 'text' : 'password'}
				InputProps={{
					endAdornment: !props.disableVisibility && (
						<InputAdornment position="end">
							<IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
								{passwordVisible ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
		</FixedHeaderWithHint>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	formBuilderTextInputRoot: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
}))

export const PasswordInputElementTag = 'ba7c40b0-6e97-4238-a237-a0f37d016e30'
export const PasswordInputId = 'b88c8184-c52f-40d9-971b-3a909093e350'

const PasswordInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof PasswordInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.disableVisibility}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, disableVisibility: v })
				}
				Editor={CheckboxPropertyConfigurator}
				label="Disable Visibility"
			/>
		</>
	)
}

const PasswordInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof PasswordInput>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={TextPropertyConfigurator}
		label="Default Value"
	/>
)

export const PasswordInputDefinition = specifyValueTypeComponent(
	PasswordInputId,
	PasswordInput,
	PasswordInputElementTag,
	'Password',
	FieldType.String,
	{
		configurator: PasswordInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			hint: undefined,
			disableVisibility: false,
		},
	},
	{
		configurator: PasswordInputValueEditor,
		defaultValue: '',
	},
)
