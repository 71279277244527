import Editor from '@monaco-editor/react'
import {
	ExpandCircleDown,
	KeyboardArrowDown,
	KeyboardArrowUp,
} from '@mui/icons-material'
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Collapse,
	IconButton,
	Typography,
	useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import {
	ActivityInstance,
	ActivityInstanceStatus,
	BaseActivityDefinitionModel,
} from '../../api/clients/workflows/DTOs'
import { ActivityStatusChip, statuses } from './ActivityInstanceStatus'
import { LogDialog } from './LogDialog'

type ViewActivityInstanceProps = {
	definition: BaseActivityDefinitionModel
	instances: ActivityInstance[]
}

export const ViewActivityInstance = (props: ViewActivityInstanceProps) => {
	const [selectedInstance, setSelectedInstance] = useState<
		ActivityInstance | undefined
	>(props.instances[props.instances.length - 1])

	useEffect(() => {
		setSelectedInstance(props.instances[props.instances.length - 1])
	}, [props.definition])

	return (
		<Box
			display="flex"
			flexDirection="column"
			padding={3}
			gap={2}
			height="100%"
			width="100%"
		>
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Typography variant="h6">{props.definition.displayName}</Typography>
			</Box>

			{props.instances.length === 0 && (
				<Box>
					<ActivityStatusChip
						status={ActivityInstanceStatus.NotRun}
						size="small"
					/>
				</Box>
			)}

			{props.instances.map((instance, i) => (
				<div key={i}>
					<Box
						key={i}
						component={'div'}
						display={'flex'}
						flexDirection={'column'}
					>
						<Box
							padding={2}
							flex={1}
							border={1}
							borderRadius={1}
							borderColor={grey[500]}
							component={ButtonBase}
							disableRipple={false}
							alignItems="flex-start"
							onClick={() => {
								if (selectedInstance?.id !== instance.id)
									setSelectedInstance(instance)
								else setSelectedInstance(undefined)
							}}
							display="flex"
							flexDirection="column"
							gap={0.5}
						>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="space-between"
								width="100%"
								alignItems="center"
							>
								<Box
									display="flex"
									flexDirection="row"
									justifyContent="flex-start"
									alignItems="center"
									gap={1}
								>
									<Box
										component={Avatar}
										bgcolor={`${statuses[instance.status].color}.main`}
										height="36px"
										width="36px"
									>
										{instance.activitySequenceNumber}
									</Box>
									<Box
										display="flex"
										flexDirection="column"
										justifyContent="flex-start"
									>
										<Typography textAlign="left" variant="h6" fontWeight="400">
											Activity #{instance.id}
										</Typography>
										<Typography textAlign="left" variant="body2">
											{new Date(instance.startedAt).toLocaleString()} UTC
										</Typography>
									</Box>
								</Box>
								{selectedInstance?.id === instance.id ? (
									<KeyboardArrowUp fontSize="large" />
								) : (
									<KeyboardArrowDown fontSize="large" />
								)}
							</Box>
						</Box>

						<Collapse in={selectedInstance?.id === instance.id}>
							<InstancePropertiesSection
								instance={instance}
								onCollapse={() => setSelectedInstance(undefined)}
							/>
						</Collapse>
					</Box>
				</div>
			))}
		</Box>
	)
}

type InstancePropertiesSectionProps = {
	instance: ActivityInstance
	onCollapse: () => void
}

const InstancePropertiesSection = ({
	instance,
	onCollapse,
}: InstancePropertiesSectionProps) => {
	const theme = useTheme()
	const [showLogs, setShowLogs] = useState(false)

	const getEndTime = (instance: ActivityInstance) => {
		if (
			instance.status !== ActivityInstanceStatus.Completed &&
			instance.status !== ActivityInstanceStatus.Cancelled
		)
			return '-'

		return `${new Date(instance.finishedAt).toLocaleString()} UTC`
	}

	return (
		<Box display="flex" flexDirection="column" gap={1} paddingY={1}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-start"
				gap={1}
				paddingLeft={1}
			>
				<Box display="flex" justifyContent="space-between">
					<Box>
						<Typography variant="subtitle2">Status</Typography>
						<Box>
							<ActivityStatusChip status={instance.status} size="small" />
						</Box>
					</Box>
					<Box display="flex" alignItems="center">
						<Button
							variant="outlined"
							size="small"
							onClick={() => setShowLogs(true)}
						>
							View Log
						</Button>
					</Box>
				</Box>

				<Box>
					<Typography variant="subtitle2">Start Time</Typography>
					<Typography variant="body2">
						{new Date(instance.startedAt).toLocaleString()} UTC
					</Typography>
				</Box>
				<Box>
					<Typography variant="subtitle2">End Time</Typography>
					<Typography variant="body2">{getEndTime(instance)}</Typography>
				</Box>
			</Box>
			<Editor
				language="json"
				height="30vh"
				width="100%"
				value={JSON.stringify(instance.state, null, 2)}
				options={{
					automaticLayout: true,
					minimap: {
						enabled: false,
					},
					fontSize: 16,
					domReadOnly: true,
					readOnly: true,
					lineNumbers: false,
					scrollBeyondLastLine: false,
				}}
			/>
			<LogDialog
				open={showLogs}
				logTitle={`Activity #${instance.id} Logs`}
				logEntries={instance.activityLog}
				onClose={() => setShowLogs(false)}
			/>
			<Box
				component={IconButton}
				color={theme.palette.primary.main}
				alignSelf={'center'}
				sx={{ transform: 'rotate(180deg)' }}
				onClick={onCollapse}
			>
				<ExpandCircleDown />
			</Box>
		</Box>
	)
}
