import { CancelOutlined, DescriptionOutlined } from '@mui/icons-material'
import {
	Button,
	Dialog,
	IconButton,
	Paper,
	PaperProps,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { useMemo, useState } from 'react'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import Designer from '../../Runtimes/Designer'

type FormBuilderDesignerInputProps = {
	buttonText: string
}

export const FormBuilderDesignerInput: React.FC<FormBuilderDesignerInputProps> =
	observer((props: FormBuilderDesignerInputProps) => {
		const styles = useWrapperStyles()

		const [dialogOpen, setDialogOpen] = useState(false)

		const context = useInputCellContext<FormBuilderSchema>()

		const handleOpenDialog = () => {
			setDialogOpen(true)
		}

		const handleCloseDialog = () => {
			setDialogOpen(false)
		}

		return (
			<div className={styles.wrapperParentDiv}>
				<Button
					color="primary"
					variant="outlined"
					startIcon={<DescriptionOutlined />}
					onClick={handleOpenDialog}
				>
					{props.buttonText}
				</Button>
				<FormBuilderDesignerDialog
					open={dialogOpen}
					onClose={handleCloseDialog}
					formBuilderSchema={context.value ?? ({} as FormBuilderSchema)}
				/>
			</div>
		)
	})

type FormBuilderDesignerDialogProps = {
	open: boolean
	onClose: () => void
	formBuilderSchema: FormBuilderSchema
}

const useWrapperStyles = makeStyles((theme: Theme) => ({
	wrapperParentDiv: {
		padding: theme.spacing(1),
		flex: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))

const FormBuilderDesignerDialog: React.FC<FormBuilderDesignerDialogProps> =
	observer((props: FormBuilderDesignerDialogProps) => {
		const styles = useStyles()

		const handleSchemaModified = (schema: FormBuilderSchema) => {
			// no op
		}

		const paperProps = useMemo<PaperProps>(
			() => ({
				className: styles.bsPaperStyle,
			}),
			[],
		)

		const observableFormBuilderSchema = useMemo(
			() => observable(props.formBuilderSchema),
			[props.formBuilderSchema],
		)

		return (
			<>
				{/* <div style={{ overflowY: 'hidden' }}> */}
				<Dialog
					PaperComponent={Paper}
					PaperProps={paperProps}
					open={props.open}
					onClose={props.onClose}
					maxWidth="lg"
					fullWidth
				>
					<div className={styles.bsScrollbarBarrierStyle}>
						<div className={styles.container}>
							<div className={styles.header}>
								<Typography variant="h5">Form Builder Designer</Typography>
								<IconButton
									onClick={props.onClose}
									className={styles.headerCloseButton}
									size="large"
								>
									<CancelOutlined color="primary" />
								</IconButton>
							</div>
							<Designer
								schemaReference={observableFormBuilderSchema}
								onSchemaModified={handleSchemaModified}
							/>
						</div>
					</div>
				</Dialog>
			</>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	bsPaperStyle: {
		height: '100%',
	},
	bsScrollbarBarrierStyle: {
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
	},

	container: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		padding: theme.spacing(2),
	},

	header: {
		width: percent(100),
		display: 'flex',
	},
	headerCloseButton: {
		marginLeft: 'auto',
	},

	containerHeader: {},
}))

export const FormBuilderDesignerInputElementTag =
	'998dc833-f57c-4084-82dc-162a12546dc9'

const FormBuilderDesignerInputId = 'ece443f8-db7a-410e-97e0-b4f672b10801'

export const FormBuilderDesignerInputDefinition = specifyInternalComponent(
	FormBuilderDesignerInputId,
	FormBuilderDesignerInput,
	FormBuilderDesignerInputElementTag,
	FieldType.ReferenceType,
	{
		buttonText: 'Configure Form Builder',
	},
	{
		definitions: [],
		handlers: {
			actions: [],
			dependencies: {},
		},
	},
)
