import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import { action } from 'mobx'
import * as yup from 'yup'
import { OrganizationPropertyListItem } from '../../../../api/clients/identity/OrganizationPropertiesClient'
import { useModals } from '../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type OrganizationPropertyTableSectionProps = {
	propertyName: string
	listItems: OrganizationPropertyListItem[]
	onUpdateListItem: (
		oldListItem: OrganizationPropertyListItem,
		updatedListItem: OrganizationPropertyListItem,
	) => void
	onDeleteListItem: (listItem: OrganizationPropertyListItem) => void
}

export const OrganizationPropertyTableSection = (
	props: OrganizationPropertyTableSectionProps,
) => {
	const styles = useStyles()
	const modalService = useModals()
	const promptService = usePrompts()

	return (
		<Fade in>
			<Paper className={styles.insetSpacing}>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Label</TableCell>
								<TableCell>Value</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* TODO - when labels are unique make the keys the labels instead of i*/}
							{props.listItems.map((listItem, i) => (
								<TableRow key={i}>
									<TableCell>{listItem.label}</TableCell>
									<TableCell>{listItem.value}</TableCell>
									<TableCell align="right">
										<IconButton
											onClick={() => {
												modalService
													.showForm((modalProps) => (
														<EditOrganizationPropertyListDialog
															initialLabel={listItem.label}
															initialValue={listItem.value}
															otherListItems={props.listItems.filter(
																(v) => v !== listItem,
															)}
															onConfirm={(v) =>
																modalProps.close({
																	closeResult: 'okay',
																	value: v,
																})
															}
															onCancel={() =>
																modalProps.close({
																	closeResult: 'cancel',
																})
															}
														/>
													))
													.then(
														action((v) => {
															if (v.closeResult === 'okay')
																props.onUpdateListItem(
																	listItem,
																	v.value as OrganizationPropertyListItem,
																)
														}),
													)
											}}
										>
											<Edit />
										</IconButton>
										<IconButton
											onClick={() => {
												promptService
													.showDialog((props) => (
														<ConfirmDeleteListItemPrompt
															listItem={listItem}
															onCancel={() => props.close('cancel')}
															onConfirm={() => props.close('okay')}
														/>
													))
													.then((v) => {
														if (v === 'okay') props.onDeleteListItem(listItem)
													})
											}}
										>
											<Delete />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Fade>
	)
}

type EditOrganizationPropertyListDialogProps = {
	initialLabel: string
	initialValue: string
	otherListItems: OrganizationPropertyListItem[] // to make sure items are unique
	onConfirm: (value: OrganizationPropertyListItem) => void
	onCancel: () => void
}

export const EditOrganizationPropertyListDialog = (
	props: EditOrganizationPropertyListDialogProps,
) => {
	// TODO - later on make it so that the label is unique
	const validationSchema = yup.object({
		label: yup.string().required('Label is required'),
		value: yup.string().required('Value is required'),
	})

	const formik = useFormik({
		initialValues: {
			label: props.initialLabel,
			value: props.initialValue,
		},
		validateOnChange: true,
		validationSchema: validationSchema,
		onSubmit: (values, { resetForm }) => {
			props.onConfirm({ label: values.label, value: values.value })

			resetForm()
		},
	})

	const styles = useStyles()

	return (
		<>
			<DialogTitle>Edit Property Value</DialogTitle>
			<DialogContent className={styles.editItemDialog}>
				<div className={styles.itemTextFields}>
					<FixedHeaderWithHint
						label="Label"
						hint={formik.touched.label && formik.errors.label}
						hintColor="error.main"
						className={styles.textField}
					>
						<TextField
							id="label"
							name="label"
							value={formik.values.label}
							error={!!formik.errors.label && formik.touched.label}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>
					<FixedHeaderWithHint
						label="Value"
						hint={formik.touched.value && formik.errors.value}
						hintColor="error.main"
						className={styles.textField}
					>
						<TextField
							id="value"
							name="value"
							value={formik.values.value}
							error={!!formik.errors.value && formik.touched.value}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>
				</div>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={
						!formik.isValid || formik.isSubmitting || !formik.touched.label
					}
					onClick={formik.submitForm}
				>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

type ConfirmDeleteListItemPromptProps = {
	listItem: OrganizationPropertyListItem
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeleteListItemPrompt = (
	props: ConfirmDeleteListItemPromptProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete Property</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete this list item with label{' '}
					<b>{props.listItem.label}</b> and value <b>{props.listItem.value}</b>?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'auto',
	},

	textField: {
		padding: theme.spacing(1, 2),
	},

	editItemDialog: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	itemTextFields: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}))
