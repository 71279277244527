import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import { FormEvents } from '../../../../../../../../utils/constants/FormEvents'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../../../../../Breadcrumbs'
import { ConfigureActionPage } from './ConfigureActionPage'
import { EventPage } from './EventPage'

type EventsRouterProps = {
	packageId: number
}

export const EventsRouter = (props: EventsRouterProps) => {
	const { path } = useRouteMatch()

	const { eventId } = useParams<{ eventId: string }>()

	const eventName = FormEvents[eventId].name

	updateBreadcrumbs(
		`/_administration/_form-packages/${props.packageId}/_events/${eventId}`,
		`${eventName}`,
	)

	return (
		<Switch>
			<Route path={makeChildRoute('_actions/:actionId', path)}>
				<ConfigureActionPage packageId={props.packageId} />
			</Route>
			<Route path={path}>
				<EventPage packageId={props.packageId} />
			</Route>
		</Switch>
	)
}
