import { Delete, Edit, GroupAdd } from '@mui/icons-material'
import {
	Button,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { GroupsClient } from '../../../../api/clients/identity'
import { BaseGroup } from '../../../../api/DTO'
import { GroupReduced } from '../../../../api/DTOtemp'
import { DeleteDialog } from '../../../../components/dialogs/DeleteDialog'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import IconButtonLink from '../../../../components/links/IconButtonLink'
import { toastService } from '../../../../services/notifications/ToastService'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { CreateGroupDialog } from './CreateGroupDialog'

export const GroupsPage = observer(() => {
	const groupsClient = new GroupsClient()
	const history = useHistory()

	const store = useLocalObservable(() => ({
		groups: undefined as BaseGroup[] | undefined,
		createGroupOpen: false,
		groupToDelete: undefined as BaseGroup | undefined,
	}))

	useEffect(() => {
		groupsClient.GetAllGroups().then(
			action((v) => {
				store.groups = v.data
			}),
		)
	}, [store])

	const handleShowCreateGroup = () => {
		store.createGroupOpen = true
	}

	const handleCloseCreateGroup = () => {
		store.createGroupOpen = false
	}

	const handleCreateGroup = async (group: GroupReduced) => {
		store.createGroupOpen = false

		groupsClient
			.CreateGroup(group)
			.then((response) => {
				const groupId = response.data.id
				history.push(`/_administration/_groups/${groupId}`)

				toastService.displayToast({ message: 'Group created', area: 'global' })
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error creating group',
					area: 'global',
				})
			})
	}

	const handleDeleteClicked = action((group: BaseGroup) => {
		store.groupToDelete = group
	})

	const handleDeleteDialogClosed = action(() => {
		store.groupToDelete = undefined
	})

	const handleDeleteGroup = async (groupId: number) => {
		groupsClient.DeleteGroup(groupId)

		runInAction(() => {
			if (store.groups !== undefined) {
				const groupToDelete = store.groups.map((v) => v.id).indexOf(groupId)

				store.groups.splice(groupToDelete, 1)
			}
		})
	}

	return (
		<>
			<AdministrationPageContainer
				title="Groups"
				actions={
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<GroupAdd />}
							onClick={handleShowCreateGroup}
						>
							Create Group
						</Button>
					</div>
				}
			>
				{store.groups === undefined ? (
					<FullscreenSpinner />
				) : (
					<GroupsTable
						groups={store.groups}
						onDeleteGroup={handleDeleteClicked}
					/>
				)}
			</AdministrationPageContainer>
			<CreateGroupDialog
				open={store.createGroupOpen}
				onClose={handleCloseCreateGroup}
				createGroup={handleCreateGroup}
				groupNames={store.groups?.map((v) => v.name) ?? []}
			/>
			{store.groupToDelete !== undefined && (
				<DeleteDialog<number>
					open={store.groupToDelete !== undefined}
					onClose={handleDeleteDialogClosed}
					itemId={store.groupToDelete.id}
					itemName={store.groupToDelete.name}
					onDelete={handleDeleteGroup}
				/>
			)}
		</>
	)
})

type GroupsTableProps = {
	groups: BaseGroup[]
	onDeleteGroup: (group: BaseGroup) => void
}

const GroupsTable = (props: GroupsTableProps) => {
	const styles = useStyles()
	const { url } = useRouteMatch()

	return (
		<Fade in>
			<TableContainer className={styles.tableRoot} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.groups.map((group) => (
							<TableRow key={group.id}>
								<TableCell>{group.name}</TableCell>
								<TableCell>{group.description}</TableCell>
								<TableCell align="right">
									<IconButtonLink to={makeChildRoute(`${group.id}`, url)}>
										<Edit fontSize="small" />
									</IconButtonLink>
									<IconButton
										onClick={() => props.onDeleteGroup(group)}
										size="large"
									>
										<Delete fontSize="small" />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
