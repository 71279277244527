import { Box, Divider, Typography } from '@mui/material'
import { runInAction } from 'mobx'
import { observer, useLocalStore } from 'mobx-react'
import { useEffect } from 'react'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { usePrompts } from '../../services/notifications/PromptService'
import {
	getRetrievalFormPackages,
	RetrievalPackage,
} from '../../services/offline/FormPackageHandling'
import { RetrievalStorageType } from '../../services/offline/FormPackageStorage'
import AdministrationPageContainer from '../Organization/Administration/AdministrationPageContainer'
import { updateBreadcrumbs } from '../Organization/Administration/Breadcrumbs'
import { useOfflinePackageContext } from '../PackageHostPage/PackageListPageContext'
import { PackageCard } from './PackageCard'
import { OfflineStatusChip } from './UserPackageListPage'

export {}

const ReceivedPackageListPage = observer(() => {
	const offlinePackageContext = useOfflinePackageContext()

	updateBreadcrumbs(
		'/_form-packages/_received-form-packages',
		'Received Form Packages',
	)

	const localStore = useLocalStore(() => ({
		packages: undefined as undefined | RetrievalPackage[],
	}))

	const promptService = usePrompts()

	useEffect(() => {
		const abortController = new AbortController()

		getRetrievalFormPackages(
			RetrievalStorageType.Received,
			offlinePackageContext.receivedPackageList,
			abortController.signal,
		).then((v) => runInAction(() => (localStore.packages = v)))

		return () => {
			abortController.abort()
		}
	}, [])

	const InnerComponent = observer(() => {
		if (localStore.packages === undefined) return <FullscreenSpinner />
		if (localStore.packages.length === 0)
			return (
				<>
					<Box
						display="flex"
						width="100%"
						height="100%"
						justifyContent="center"
						alignItems="center"
						flexDirection="column"
					>
						<Box
							component={Divider}
							flexItem
							alignSelf="flex-start"
							justifySelf="flex-start"
							width="100%"
						/>
						{/* <Divider flexItem></Divider> */}

						<Box
							display="flex"
							flex="1"
							justifyContent="center"
							alignItems="center"
						>
							<Typography variant="h5" align="center">
								You have not received any form packages
							</Typography>
						</Box>
					</Box>
				</>
			)

		return (
			<Box
				display="flex"
				flexDirection="column"
				width="100%"
				height="100%"
				gap={1}
			>
				<Divider flexItem />

				<Box height="100%" width="100%" flex="1">
					{localStore.packages.map((v) => (
						<PackageCard
							key={v.instanceId}
							description={v.description}
							name={v.name}
							route={`/_package-host/${v.packageId}/_${v.storageType}/${v?.workItem?.id}`}
							chips={
								<>
									<OfflineStatusChip
										id={v.uniqueId}
										packageMap={offlinePackageContext.receivedPackageList}
									/>
								</>
							}
							actions={<></>}
						/>
					))}
				</Box>
			</Box>
		)
	})

	return (
		<AdministrationPageContainer title="Received Packages">
			<InnerComponent />
		</AdministrationPageContainer>
	)
})

export default ReceivedPackageListPage
