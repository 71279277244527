import { PropsWithChildren } from 'react'
import { ComponentWrapper } from '../../../../FormBuilderCore/cells/rendering/contexts/ContextTypes'
import { DraggableComponent } from './DraggableComponent'
import { DustbinComponent } from './Dustbins/DustbinComponent'

export const DragAndDropCellWrapper = (
	props: PropsWithChildren<ComponentWrapper>,
) => {
	return (
		<DustbinComponent
			cellInstance={props.cellInstance}
			formHost={props.formHost}
		>
			<DraggableComponent
				cellInstance={props.cellInstance}
				formHost={props.formHost}
			>
				{props.children}
			</DraggableComponent>
		</DustbinComponent>
	)
}
