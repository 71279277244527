import { Upload } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { ComponentProps, useCallback } from 'react'
import { useFormPackageContext } from '../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/FormPackagePageContext'
import {
	AttachmentApi,
	useAttachmentsApi,
} from '../../../../pages/PackageHostPage/Attachments/AttachmentsApi'
import {
	PropsEditorComponent,
	specifyVoidComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import {
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'

type FileInputProps = {
	buttonText: string
	attachmentType: string
}

export const FormBuilderFileInput = observer((props: FileInputProps) => {
	const formPackageContext = useFormPackageContext()

	let attachmentsApi: AttachmentApi | undefined = undefined
	try {
		attachmentsApi = useAttachmentsApi()
	} catch (e) {
		console.warn(
			'error, attachments api not defined. This is expected in form builder designer',
		)
	}

	const onClick = useCallback(() => {
		attachmentsApi?.requestAttachments(props.attachmentType)
	}, [attachmentsApi])

	if (
		formPackageContext.formPackage.configuration.attachmentTypes.find(
			(v) => v.name === props.attachmentType,
		) === undefined
	) {
		return <Typography variant="h6">Attachment Type Not Found</Typography>
	}

	return (
		<Box width="100%" height="100%" display="flex" justifyContent={'center'}>
			<Button startIcon={<Upload />} variant="contained" onClick={onClick}>
				{props.buttonText}
			</Button>
		</Box>
	)
})

export const FormBuilderFileInputPropsEditor = (
	props: PropsEditorComponent<ComponentProps<typeof FormBuilderFileInput>>,
) => {
	const currentFormPackage = useFormPackageContext()

	const properties = props.properties
	const onPropertiesChanged = props.onPropertiesChanged

	const attachmentTypes =
		currentFormPackage.formPackage.configuration.attachmentTypes
	console.log('attachment types: ', attachmentTypes)

	return (
		<>
			<PropertySchematicConfigurator
				value={properties.buttonText}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, buttonText: v })
				}
				Editor={TextPropertyConfigurator}
			/>

			<PropertySchematicConfigurator
				value={properties.attachmentType}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, attachmentType: v })
				}
				Editor={(props) => {
					const options = attachmentTypes.map((v) => ({
						key: v.name,
						displayValue: v.name,
					}))
					console.log('props value', props.value)
					return <SelectPropertyConfigurator options={options} {...props} />
				}}
			/>
		</>
	)
}

export const FileInputElementTag = '4baa6619-05e3-42c3-a76b-c5298c6d8a2c'
export const FileInputElementId = 'ca82a2ce-a965-439d-af7e-714464877e6b'
export const FileInputDefinition = specifyVoidComponent(
	FileInputElementId,
	FormBuilderFileInput,
	FileInputElementTag,
	'File Upload',
	{
		configurator: FormBuilderFileInputPropsEditor,
		defaultProperties: {
			buttonText: 'Upload File',
			attachmentType: 'General Attachments',
		},
	},
)
