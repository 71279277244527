import { ObservableMap } from 'mobx'
import React, { createContext, useContext, useMemo } from 'react'

export type PackageMetaInfo = {
	id: string
	offlineStatus: OfflineStatus
	rowVersion?: string
}

export enum OfflineStatus {
	NotOffline = 'NotOffline',
	NotDownloaded = 'NotDownloaded',
	Downloading = 'Downloading',
	DownloadFailed = 'DownloadFailed',
	Downloaded = 'Downloaded',
	Synced = 'Synced',
}

export type OfflineContextType = {
	packageList: ObservableMap<string, PackageMetaInfo>
	savedPackageList: ObservableMap<string, PackageMetaInfo>
	receivedPackageList: ObservableMap<string, PackageMetaInfo>
}

const context = createContext<OfflineContextType>({
	packageList: new ObservableMap(),
	savedPackageList: new ObservableMap(),
	receivedPackageList: new ObservableMap(),
})

export const OfflineTrackingContextProvider = (
	props: React.PropsWithChildren<unknown>,
) => {
	const contextValue: OfflineContextType = useMemo<OfflineContextType>(
		() => ({
			packageList: new ObservableMap(),
			savedPackageList: new ObservableMap(),
			receivedPackageList: new ObservableMap(),
		}),
		[],
	)

	return (
		<context.Provider value={contextValue}>{props.children}</context.Provider>
	)
}

export const useOfflinePackageContext = () => {
	return useContext(context)
}
