import {
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Typography,
} from '@mui/material'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ComponentProps, useEffect } from 'react'
import {
	DataProviderInfo,
	executeDataProvider,
} from '../../../../utils/FormBuilderDataProviderUtils'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	ColumnType,
	DataProviderPropertyConfigurator,
	MultiSelectPropertyConfigurator,
	OptionsType,
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	StaticDataProviderPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type ChecklistProps = {
	label: string
	options: OptionsType[]
	dataProviderType: 'static' | 'dataProvider'
	dataProviderInfo?: DataProviderInfo
}

const Checklist = observer((props: ChecklistProps) => {
	console.log(
		'checklist re-rendered with props: ',
		JSON.stringify(props, null, 2),
	)

	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()

	const localStore = useLocalObservable(() => ({
		isExecuting: false,
		dataProviderResults: [] as OptionsType[],

		get options(): OptionsType[] {
			return props.dataProviderType === 'static'
				? props.options
				: this.dataProviderResults
		},
	}))

	useEffect(() => {
		if (
			props.dataProviderInfo == null ||
			props.dataProviderInfo.dataProviderId === 0
		)
			return

		runInAction(() => {
			localStore.isExecuting = true
		})

		executeDataProvider(props.dataProviderInfo)
			.then(action((results) => (localStore.dataProviderResults = results)))
			.finally(
				action(() => {
					localStore.isExecuting = false
				}),
			)
	}, [])

	return (
		<FormControl>
			<Typography variant="subtitle1" color="textPrimary">
				{props.label}
			</Typography>
			{localStore.isExecuting && <CircularProgress />}
			<FormGroup
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: ChecklistElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
			>
				{localStore.options.map((v) => (
					<FormControlLabel
						key={v.key}
						label={v.displayValue}
						control={
							<Checkbox
								checked={
									((context.value ?? []) as string[]).find(
										(val) => val === v.key,
									) !== undefined
								}
								onChange={(change) => {
									const changedTarget = change.currentTarget.checked as boolean
									const oldValue = context.value
									const newValue = changedTarget
										? [...((context.value ?? []) as string[]), v.key]
										: ((context.value ?? []) as string[]).filter(
												(val) => val !== v.key,
										  )

									context.onChange(newValue)
									changeEventEmitter(
										cellManager.eventBus,
										{
											oldValue: oldValue,
											newValue: newValue,
										},
										{
											formHost: cellManager,
											elementTag: ChecklistElementTag,
											definitionId: context.cellInstance.definitionId,
											instanceId: context.cellInstance.id,
										},
									)
								}}
							/>
						}
					/>
				))}
			</FormGroup>
		</FormControl>
	)
})

export const ChecklistElementTag = 'd7a29b80-ef7d-4972-a7d6-55bdab4d229c'

export const ChecklistInputId = '330f2a2c-5ccd-484b-b733-765af46ef615'

const ChecklistInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof Checklist>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.dataProviderType}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, dataProviderType: v })
				}
				Editor={(props) => {
					const options = [
						{ key: 'static', displayValue: 'Static' },
						{ key: 'dataProvider', displayValue: 'Data Provider' },
					]
					return <SelectPropertyConfigurator options={options} {...props} />
				}}
				label="Data Provider Type"
			/>
			{properties.dataProviderType === 'static' && (
				<PropertySchematicConfigurator
					value={properties.options}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, options: v })
					}
					Editor={(props) => (
						<StaticDataProviderPropertyConfigurator
							values={{
								key: {
									columnName: 'Key',
									columnType: ColumnType.String,
								},
								displayValue: {
									columnName: 'Display Value',
									columnType: ColumnType.String,
								},
							}}
							{...props}
						/>
					)}
					label="Options"
				/>
			)}
			{properties.dataProviderType === 'dataProvider' && (
				<PropertySchematicConfigurator
					value={properties.dataProviderInfo}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, dataProviderInfo: v })
					}
					Editor={DataProviderPropertyConfigurator}
					label="Options"
				/>
			)}
		</>
	)
}

const ChecklistInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof Checklist>>,
) => {
	const options = props.properties.options

	// we can't have a default value if it's a data provider
	// bc values may be different when form is loaded
	if (props.properties.dataProviderType === 'dataProvider') return <></>

	return (
		<PropertySchematicConfigurator
			value={props.value}
			onValueChanged={props.onValueChanged}
			Editor={(props) => (
				<MultiSelectPropertyConfigurator
					options={options}
					{...props}
					value={props.value as string[] | undefined}
				/>
			)}
			label="Default Value"
		/>
	)
}

export const ChecklistDefinition = specifyValueTypeComponent(
	ChecklistInputId,
	Checklist,
	ChecklistElementTag,
	'Checklist',
	FieldType.StringArray,
	{
		configurator: ChecklistInputPropsEditor,
		defaultProperties: {
			label: '',
			options: [
				{
					key: 'option',
					displayValue: 'option one',
				},
			],
			dataProviderType: 'static',
			dataProviderInfo: undefined,
		},
	},
	{
		configurator: ChecklistInputValueEditor,
		defaultValue: [],
	},
)
