/* eslint-disable @typescript-eslint/no-explicit-any */

import { action, computed, observable } from "mobx";
import Cookie from "./Cookie";

enum LogLevel {
	Trace = 0,
	Debug = 1,
	Information = 2,
	Warning = 3,
	Error = 4,
	Critical = 5,
	None = 6
}

export function getLogLevel() {
	const level = Cookie.get<string>('LogLevel', LogLevel.Warning.toString())
	return parseInt(level) as LogLevel;
}

export function setLoggingEnabled(level: LogLevel) {
	Cookie.set('LogLevel', level.toString())
	Settings.logLevel = level;
}


const Settings = observable({
	logLevel: getLogLevel()
})

const createSettingsGuard = <T extends Array<never>>(fn: (...args: T) => void,
	predicate: () => boolean) => {

	return (...args: T) => {
		if (predicate())
			fn(...args)
	}
}

const applyPrefix = (fn: (...args: any) => void, prefix: string) => {

	return (...args: any) => fn(prefix, ...args);

}

export const logTrace = createSettingsGuard(applyPrefix(console.log, "TRACE "), () => Settings.logLevel >= LogLevel.Trace)
export const logDebug = createSettingsGuard(applyPrefix(console.log, "DEBUG "), () => Settings.logLevel >= LogLevel.Debug)
export const logInformation = createSettingsGuard(applyPrefix(console.log, "INFO "), () => Settings.logLevel >= LogLevel.Information)
export const logWarning = createSettingsGuard(applyPrefix(console.warn, "WARN "), () => Settings.logLevel >= LogLevel.Warning)
export const logError = createSettingsGuard(applyPrefix(console.error, "ERROR "), () => Settings.logLevel >= LogLevel.Error)
export const LogCritical = createSettingsGuard(applyPrefix(console.error, "CRITICAL "), () => Settings.logLevel >= LogLevel.Critical)


const logFor = {
	service: '--',
	component: '##',
	utility: '**'
}

export type LogType = keyof typeof logFor

const logTypeValue = (type: LogType): number =>
	type == 'service' ? 0 : type == 'component' ? 1 : 2

export type LogEntry = {
	type: LogType
	name: string
	description: string
	log: DebugLog
}

const compare = (a: LogEntry, b: LogEntry): number => {
	const aVal = logTypeValue(a.type)
	const bVal = logTypeValue(b.type)
	if (aVal == bVal)
		return a.name < b.name ? -1 : b.name < a.name ? 1 : 0
	return aVal < bVal ? -1 : 1
}

export class DebugLog {
	@observable public static loggingEnabled: boolean =
		Cookie.get<string>('Debug', 'false') == 'true'

	constructor(
		public prefix: string,
		public enabled: boolean = DebugLog.loggingEnabled) { }

	@action static setLoggingEnabled = (value: boolean): void => {
		Cookie.set('Debug', value.toString())
		DebugLog.loggingEnabled = value
	}

	write = (...args: any[]): void => {
		if (DebugLog.loggingEnabled && this.enabled)
			console.log(this.prefix, ':\t', ...args)
	}

	@observable private static _logs: LogEntry[] = []
	@action static register = (
		type: LogType,
		name: string,
		description: string,
		enabled: boolean = DebugLog.loggingEnabled): DebugLog => {
		const result = new DebugLog(`${logFor[type]} ${name}:`, enabled)
		DebugLog._logs.push({
			type: type,
			name: name,
			description: description,
			log: result
		})
		return result
	}

	@computed static get logs() { 
		return DebugLog._logs.sort(compare)
	}

	@computed static get logTypes() {
		return DebugLog.logs.map(l => l.type)
			.filter((value, index, self) => 
				self.indexOf(value) == index)
	}

	static logsForType = (type: LogType): LogEntry[] =>
		DebugLog.logs.filter(l => l.type == type)

	static enableLogsByType = (type: LogType, enable = true): void =>
		DebugLog.logsForType(type)
			.forEach(l => l.log.enabled = enable)

	static disableLogsByType = (type: LogType): void =>
		DebugLog.enableLogsByType(type, false)

	static enableLog = (name: string, enable = true): void =>
		DebugLog.logs
			.filter(l => l.name == name)
			.forEach(l => l.log.enabled = enable)

	static disableLog = (name: string): void =>
		DebugLog.enableLog(name, false)
}
