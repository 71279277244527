import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import { BaseGroup } from '../../../../api/DTO'
import { GroupReduced } from '../../../../api/DTOtemp'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { AdministrationPageTabs } from '../AdministrationPageTabs'
import { GroupAdministrationPageProps } from './GroupPage'

type GroupOptionsAdministrationPageProps = {
	groupNames: string[]
	onUpdateGroup: (groupId: number, updatedGroup: GroupReduced) => void
} & GroupAdministrationPageProps

export const GroupOptionsAdministrationPage = observer(
	({
		group,
		tabLabels,
		onTabChanged,
		groupNames,
		onUpdateGroup,
	}: GroupOptionsAdministrationPageProps) => {
		const styles = useStyles()

		return (
			<AdministrationPageContainer title={group.name}>
				<div className={styles.groupContentRoot}>
					<AdministrationPageTabs
						currentTab={0}
						onTabChanged={onTabChanged}
						tabLabels={tabLabels}
					/>
					<Divider />
					<GroupOptionsSection
						group={group}
						onUpdateGroup={onUpdateGroup}
						upperGroupNames={groupNames}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type GroupOptionsSectionProps = {
	group: BaseGroup
	onUpdateGroup: (groupId: number, updatedGroup: GroupReduced) => void
	upperGroupNames: string[]
}

const GroupOptionsSection = observer(
	({
		group,
		onUpdateGroup,
		upperGroupNames: groupNames,
	}: GroupOptionsSectionProps) => {
		const styles = useStyles()

		const validationSchema = yup.object({
			groupName: yup
				.string()
				.required('Name is a required field')
				.test(
					'is-unique',
					'A group with this name already exists in your organization',
					(value) => {
						return (
							value !== undefined &&
							(value === group.name ||
								!groupNames.includes(value.toUpperCase().trim()))
						)
					},
				),
			description: yup.string(),
		})

		const formik = useFormik({
			initialValues: {
				groupName: group.name,
				description: group.description,
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				try {
					onUpdateGroup(group.id, {
						name: values.groupName,
						description: values.description,
					})
					toastService.displayToast({
						message: 'Group updated',
						area: 'global',
					})
				} catch {
					toastService.displayToast({
						message: 'Error updating group',
						area: 'global',
					})
				}
			},
		})

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Typography
						className={styles.topBottomContent}
						variant="h6"
						color="textPrimary"
					>
						Settings
					</Typography>
					<Divider />
					<div className={styles.formSettingsRoot}>
						<div className={styles.formElement}>
							<TextField
								name="groupName"
								id="groupName"
								value={formik.values.groupName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								helperText={formik.touched.groupName && formik.errors.groupName}
								error={!!formik.errors.groupName && formik.touched.groupName}
								label="Name"
								fullWidth
								autoComplete="off"
							/>
						</div>
						<div className={styles.formElement}>
							<TextField
								name="description"
								id="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								helperText={
									formik.touched.description && formik.errors.description
								}
								error={
									!!formik.errors.description && formik.touched.description
								}
								label="Description"
								fullWidth
								autoComplete="off"
							/>
						</div>
					</div>
					<div>
						<Divider />
						<div
							className={clsx(styles.topBottomContent, styles.bottomFlexbox)}
						>
							<Button
								color="primary"
								variant="contained"
								className={styles.noTextTransform}
								disabled={!formik.isValid || !formik.dirty}
								onClick={formik.submitForm}
							>
								Save Changes
							</Button>
						</div>
					</div>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	groupContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	root: {
		width: percent(100),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},
}))
