import { TextField, Typography } from '@mui/material'
import { TypedEditor } from './PropertySchematicConfigurator'

const TextPropertyConfigurator = (props: TypedEditor<string | undefined>) => {
	return (
		<TextField
			fullWidth
			value={props.value ?? ''}
			onChange={(v) => {
				props.onChange(v.currentTarget.value)
			}}
			autoComplete="off"
			helperText={
				<Typography
					variant="caption"
					color={props.hintColor ?? 'textSecondary'}
					whiteSpace="pre-wrap"
					marginTop={1}
				>
					{props.hint ?? ' '}
				</Typography>
			}
		/>
	)
}

export default TextPropertyConfigurator
