import { Box, MenuItem, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import { BinaryExpression, BinaryOperator, Expression } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent, ValidNodeTypes } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'
import { MemberExpressions } from '../variables/MemberExpressionComponent'

const validNodes: ValidNodeTypes[] = [
	Syntax.BinaryExpression,
	Syntax.LogicalExpression,
	Syntax.Literal,
	Syntax.CallExpression,
	...MemberExpressions,
]

const BinaryExpressionComponent = observer(
	(props: RendererComponent<BinaryExpression>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Logic)

		return (
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
				gap={2}
				bgcolor={color}
				padding={1}
			>
				<Box borderRadius={3}>
					<Renderer
						dropMode={DropMode.Replace}
						validNodes={validNodes}
						getter={() => props.node.left}
						setter={action((node) => (props.node.left = node as Expression))}
					/>
				</Box>

				<Box>
					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							selectColor={textColor}
							variant="standard"
							size="small"
							value={props.node.operator}
							onChange={(v) =>
								(props.node.operator = v.target.value as BinaryOperator)
							}
						>
							<MenuItem value={'=='}>
								<Typography fontStyle="italic">is equal to</Typography>
							</MenuItem>
							<MenuItem value={'==='}>
								<Typography fontStyle="italic">is exactly equal to</Typography>
							</MenuItem>
							<MenuItem value={'!='}>
								<Typography fontStyle="italic">is not equal to</Typography>
							</MenuItem>
							<MenuItem value={'!=='}>
								<Typography fontStyle="italic">
									is not exactly equal to
								</Typography>
							</MenuItem>
							<MenuItem value={'<'}>
								<Typography fontStyle="italic">is less than</Typography>
							</MenuItem>
							<MenuItem value={'<='}>
								<Typography fontStyle="italic">
									is less than or equal to
								</Typography>
							</MenuItem>
							<MenuItem value={'>'}>
								<Typography fontStyle="italic">is greater than</Typography>
							</MenuItem>
							<MenuItem value={'>='}>
								<Typography fontStyle="italic">
									is greater than or equal to
								</Typography>
							</MenuItem>
							<MenuItem value={'+'}>
								<Typography fontStyle="italic">plus</Typography>
							</MenuItem>
							<MenuItem value={'-'}>
								<Typography fontStyle="italic">minus</Typography>
							</MenuItem>
							<MenuItem value={'*'}>
								<Typography fontStyle="italic">multiplied by</Typography>
							</MenuItem>
							<MenuItem value={'/'}>
								<Typography fontStyle="italic">divided by</Typography>
							</MenuItem>
							<MenuItem value={'%'}>
								<Typography fontStyle="italic">modulus of</Typography>
							</MenuItem>
						</ColoredSelect>
					</ColoredFormControl>
				</Box>

				<Box borderRadius={3}>
					<Renderer
						dropMode={DropMode.Replace}
						validNodes={validNodes}
						getter={() => props.node.right}
						setter={action((node) => (props.node.right = node as Expression))}
					/>
				</Box>
			</Box>
		)
	},
)

export default BinaryExpressionComponent
