import { computed, makeObservable, observable } from "mobx";
import ActivityDefinitionClient from "../../../api/clients/workflows/ActivityDefinitionClient";
import { ActivityConfiguration } from "../../../api/clients/workflows/DTOs";

export default class ActivityConfigurationStore {

	private client: ActivityDefinitionClient = new ActivityDefinitionClient()

	public get hasFinishedLoading(): boolean {
		return this._hasFinishedLoading
	}
	
	private _hasFinishedLoading = false;


	/**
	 *
	 */
	constructor() {
        makeObservable<ActivityConfigurationStore, "_hasFinishedLoading" | "_configurations">(this, {
            hasFinishedLoading: computed,
            _hasFinishedLoading: observable,
            configurations: computed,
            _configurations: observable
        });

        this.loadConfigurations()
    }

	private loadConfigurations() {
		this.client.getDefinitions()
			.then(v => this._configurations = v.data)
			.then(() => this._hasFinishedLoading = true)
	}

	public get configurations(): ActivityConfiguration[] {
		if (!this._configurations) {
			this.loadConfigurations()
			return []
		}
		return this._configurations
	}

	private _configurations: ActivityConfiguration[] | undefined;

	private getConfigurationsAsync = async (): Promise<ActivityConfiguration[]> => {
		if (this._configurations)
			return this._configurations
		const axiosResponse = await this.client.getDefinitions()
		this._configurations = axiosResponse.data
		return axiosResponse.data
	}

	public getConfiguration = (definitionId: string): ActivityConfiguration | undefined => {
		return this.configurations.find(v => v.definitionId === definitionId)
	}

	public getConfigurationAsync = async (definitionId: string): Promise<ActivityConfiguration | undefined> => {
		const definitionList = this._configurations || (await this.getConfigurationsAsync())

		const definition = definitionList.find(v => v.definitionId === definitionId)
		return definition
	}
}