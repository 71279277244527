import { CircularProgress, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { percent } from 'csx'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import AuthenticationProviderInstancesClient from '../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import { ErrorPage } from '../Error/ErrorPage'

export const AuthenticationProviderLoginPage = () => {
	const { instanceId } = useParams<{ instanceId: string }>()
	const history = useHistory()
	const [signInError, setSignInError] = useState<string>()
	const [lockedOut, setLockedOut] = useState(false)

	const styles = useStyles()

	const authProviderInstancesClient =
		new AuthenticationProviderInstancesClient()

	const redirectAfterLogin = useCallback(() => {
		authProviderInstancesClient
			.getPostLoginRedirectUrl(instanceId, window.location.toString())
			.then((response) => {
				console.log('response!!', response.data)
				if (!response.data) history.push('/_user/_home')
				else history.replace(response.data)
			})
			.catch((e) => {
				console.error(e)
				history.push('/_user/_home')
			})
	}, [window.location])

	useEffect(() => {
		authProviderInstancesClient
			.login(instanceId, {
				oidcRequestData: window.location.toString(),
				redirectUrl: window.location.origin + window.location.pathname,
			})
			.then(() => {
				redirectAfterLogin()
			})
			.catch((e) => {
				console.error(e)

				if (axios.isAxiosError(e)) {
					if (e.response?.status !== 403) {
						setSignInError(e.response?.data as string)
						return
					}

					console.log(`Unauthorized - User locked out`)
					setLockedOut(true)
				}
			})
	}, [])

	if (signInError)
		return (
			<ErrorPage
				errorMessage={signInError}
				buttonText={'Authentication Methods'}
				route={'/_login/_auth-methods'}
			/>
		)

	if (lockedOut)
		return (
			<ErrorPage
				errorMessage="You have been locked out of your account. Please contact your administrator."
				route={'/_login'}
				buttonText="Back to Login"
			/>
		)

	return (
		<div className={styles.loadingPage}>
			<>
				<CircularProgress />
				<Typography>Signing you in</Typography>
			</>
			{/* (
				<ErrorPage
					errorMessage="An error occurred while logging in with your external provider."
					route="/_login"
					buttonText="Back to Login"
				/>
			) */}
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	loadingPage: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: percent(100),
	},

	spacing: {
		margin: theme.spacing(2, 0),
	},
}))
