import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import { percent } from 'csx'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { WorkflowDefinition } from '../../../../../../../../api/clients/workflows/DTOs'
import TagsClient from '../../../../../../../../api/clients/workflows/TagsClient'
import { FullscreenSpinner } from '../../../../../../../../components/feedback/circular'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { WorkflowsDashboardTable } from '../../../../../Workflows/WorkflowsDashboardPage'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'

type WorkflowsSectionProps = {
	packageId: number
} & FormPackageAdministrationPageProps

export const WorkflowsSection = ({
	packageId,
	...props
}: WorkflowsSectionProps) => {
	const [workflows, setWorkflows] = useState<WorkflowDefinition[]>()

	useEffect(() => {
		const executeRequest = async () => {
			const client = new TagsClient()

			const response = await client.queryWorkflows([
				{
					class: 'Core.Database.Models.Forms.FormPackage.Id',
					value: packageId.toString(),
				},
			])

			setWorkflows(response.data)
		}

		executeRequest()
	}, [packageId])

	const history = useHistory()

	const handleViewWorkflowClicked = (workflowId: number) => {
		history.push(`/_administration/_workflows/${workflowId}`)
	}

	if (workflows === undefined) return <FullscreenSpinner />

	return (
		<AdministrationPageContainer title="Form Package Workflows">
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: percent(100),
					height: percent(100),
				}}
			>
				<AdministrationPageTabs
					currentTab={5}
					onTabChanged={props.onTabChanged}
					tabLabels={props.tabLabels}
				/>
				<Divider />
				<Box
					sx={{
						paddingTop: 2,
					}}
				>
					<WorkflowsDashboardTable
						workflowDefinitions={workflows}
						onViewWorkflowClicked={handleViewWorkflowClicked}
					/>
				</Box>
			</Box>
		</AdministrationPageContainer>
	)
}
