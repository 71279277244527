
import { AxiosResponse } from "axios";
import { GroupUserAssociation, GroupUserSimpleAssociation } from '../../DTOtemp';
import SecureClient from '../SecureClient';

export class GroupUserAssociationsClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `GroupUserAssociations`
	}

	/**
	 * Get all user-group associations in organization based on optional user id and group id entries
	 * @param userId 
	 * @param groupId 
	 */
	public GetGroupUserAssociations(userId?: number, groupId?: number)
		:Promise<AxiosResponse<GroupUserAssociation[]>> {
		
		return this._api.get<GroupUserAssociation[]>(
			`${this._urlBase}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				},
				params: {
					userId,
					groupId
				}
			}
		)
	}

	/**
	 * Add an association to an organization
	 * @param groupUser 
	 */
	public AddGroupUserAssociation(groupUser: GroupUserSimpleAssociation)
		: Promise<AxiosResponse> {
		return this._api.post(
			`${this._urlBase}`,
			JSON.stringify(groupUser),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Get one association
	 * @param associationId 
	 */
	public GetGroupUserAssociation(associationId: number)
		: Promise<AxiosResponse<GroupUserAssociation>> {
		return this._api.get<GroupUserAssociation>(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Remove an association from an organization
	 * @param associationId 
	 */
	public DeleteGroupUserAssociation(associationId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

	/**
	 * Remove more than one association from an organization
	 * @param ids the ids of the associations to be deleted
	 */
	public BulkDeleteGroupUserAssociations(ids: number[])
		: Promise<AxiosResponse<number>> {
		return this._api.post(
			`${this._urlBase}/BulkDelete`,
			ids,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

	/**
	 * Adds more than one association for an organization
	 * @param groupUsers 
	 */
	public BulkAddGroupUserAssociation(groupUsers: GroupUserSimpleAssociation[])
		: Promise<AxiosResponse<number>> {
		return this._api.post(
			`${this._urlBase}/BulkAddition`,
			JSON.stringify(groupUsers),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

}