import { KeyboardArrowDown } from '@mui/icons-material'
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Popover,
	TextField,
	TextFieldProps,
	Typography,
} from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useEffect, useState } from 'react'

type WorkflowDateFilterProps = {
	startDateFrom?: Date
	startDateTo?: Date
	onChange: (startDateFrom?: Date, startDateTo?: Date) => void
}

export const WorkflowDateFilter = (props: WorkflowDateFilterProps) => {
	const [dateAnchorEl, setDateAnchorEl] = useState<HTMLButtonElement | null>(
		null,
	)
	const [filterByStartDate, setFilterByStartDate] = useState(false)

	// start from the very first minute of today
	const [startDateFrom, setStartDateFrom] = useState<Date | undefined>(
		props.startDateFrom ?? moment({ hour: 0 }).toDate(),
	)

	// end at the very last minute of today
	const [startDateTo, setStartDateTo] = useState<Date | undefined>(
		props.startDateTo ??
			moment({ hour: 23, minute: 59, second: 59, ms: 999 }).toDate(),
	)

	useEffect(() => {
		props.onChange(startDateFrom, startDateTo)
	}, [startDateFrom, startDateTo])

	useEffect(() => {
		if (filterByStartDate) props.onChange(startDateFrom, startDateTo)
		else props.onChange(undefined, undefined)
	}, [filterByStartDate])

	return (
		<>
			<Button
				color="inherit"
				endIcon={<KeyboardArrowDown fontSize="small" />}
				onClick={(evt) => setDateAnchorEl(evt.currentTarget)}
			>
				Start Date
			</Button>
			<Popover
				open={dateAnchorEl !== null}
				anchorEl={dateAnchorEl}
				onClose={() => setDateAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box display="flex" flexDirection="column" gap={1} padding={4}>
					<FormControlLabel
						control={
							<Checkbox
								checked={filterByStartDate}
								onChange={() => setFilterByStartDate(!filterByStartDate)}
								value={filterByStartDate}
							/>
						}
						label="Filter by Start Date"
					/>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							disableFuture
							disabled={!filterByStartDate}
							value={props.startDateFrom ?? moment()}
							onChange={(date: Date | null) => {
								const newDate = date !== null ? new Date(date) : undefined
								// this is so we include the entire day
								newDate?.setHours(0, 0, 0, 0)
								setStartDateFrom(newDate ?? undefined)

								// need to handle the to date if the from date is past the to
								if (
									newDate !== undefined &&
									startDateTo !== undefined &&
									newDate > startDateTo
								) {
									const newToDate = new Date(newDate)
									newToDate.setHours(23, 59, 59, 999)
									setStartDateTo(newToDate ?? undefined)
								}
							}}
							inputFormat="MM/DD/YYYY"
							renderInput={(params: TextFieldProps) => (
								<Box display="flex" flexDirection="column">
									<Typography variant="subtitle2">From</Typography>
									<TextField {...params} size="small" />
								</Box>
							)}
						/>
					</LocalizationProvider>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							disableFuture
							disabled={!filterByStartDate}
							value={props.startDateTo ?? moment()}
							onChange={(date: Date | null) => {
								const newDate = date !== null ? new Date(date) : undefined
								// this is so we include the entire day
								newDate?.setHours(23, 59, 59, 999)
								setStartDateTo(newDate ?? undefined)

								// need to handle the from date if the to date is before the from
								if (
									newDate !== undefined &&
									startDateFrom !== undefined &&
									newDate < startDateFrom
								) {
									const newFromDate = new Date(newDate)
									newFromDate.setHours(0, 0, 0, 0)
									setStartDateFrom(newFromDate ?? undefined)
								}
							}}
							inputFormat="MM/DD/YYYY"
							renderInput={(params: TextFieldProps) => (
								<Box display="flex" flexDirection="column">
									<Typography variant="subtitle2">To</Typography>
									<TextField {...params} size="small" />
								</Box>
							)}
						/>
					</LocalizationProvider>
				</Box>
			</Popover>
		</>
	)
}
