import { Delete, Edit } from '@mui/icons-material'
import { Avatar, Button, Paper, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import UserClient from '../../../../../../api/clients/identity/UserClient'
import { BaseUser } from '../../../../../../api/DTO'
import { toastService } from '../../../../../../services/notifications/ToastService'

type UserPageCardProps = {
	user: BaseUser
	isCurrentUser: boolean
}

export const UserPageCard = observer((props: UserPageCardProps) => {
	const styles = useStyles()

	const client = new UserClient()

	const [profilePictureUrl, setProfilePictureUrl] = useState<string>()

	const getProfilePicture = async () => {
		const { data } = await client.GetProfilePicture(props.user.id)

		const url = window.URL.createObjectURL(data)

		setProfilePictureUrl(url)
	}

	const handleUpdateProfilePictureUrl = (newUrl: string | undefined) => {
		setProfilePictureUrl(newUrl)
	}

	useEffect(() => {
		getProfilePicture()
	}, [])

	return (
		<Paper className={styles.userPageCardRoot}>
			<div className={styles.headerContainer}>
				<div className={styles.avatarWrapper}>
					<Avatar src={profilePictureUrl} className={styles.avatarLarge} />
				</div>
				<Typography
					className={styles.nameplate}
					variant="h6"
					color="textPrimary"
				>
					{props.user.firstName} {props.user.lastName}
				</Typography>
				{props.isCurrentUser && (
					<CurrentUserSection
						profilePictureUrl={profilePictureUrl}
						user={props.user}
						onUpdateProfilePictureUrl={handleUpdateProfilePictureUrl}
					/>
				)}
			</div>
		</Paper>
	)
})

type CurrentUserSectionProps = {
	profilePictureUrl?: string
	user: BaseUser
	onUpdateProfilePictureUrl: (newUrl: string | undefined) => void
}

const CurrentUserSection = observer((props: CurrentUserSectionProps) => {
	const styles = useStyles()

	const client = new UserClient()

	let inputRef: HTMLInputElement | null = null

	const changeProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		if (!files) return
		const file = files[0]

		const formData = new FormData()
		formData.append('image', file)

		client
			.UpdateProfilePicture(props.user.id, formData)
			.then(() => {
				props.onUpdateProfilePictureUrl(URL.createObjectURL(file))

				toastService.displayToast({
					message: 'Profile picture updated',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error updating profile picture',
					area: 'global',
				})
			})
	}

	const removeProfilePicture = () => {
		client
			.DeleteProfilePicture(props.user.id)
			.then(() => {
				props.onUpdateProfilePictureUrl(undefined)
				toastService.displayToast({
					message: 'Profile picture removed',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error removing profile picture',
					area: 'global',
				})
			})
	}

	return (
		<>
			<input
				hidden
				type="file"
				accept="image/png, image/jpeg"
				onChange={changeProfilePicture}
				ref={(fileInput) => (inputRef = fileInput)}
			/>
			<Button
				onClick={() => {
					if (inputRef) inputRef.click()
				}}
				className={styles.actionButton}
				size="small"
				color="primary"
				startIcon={<Edit />}
			>
				Change Picture
			</Button>
			<Button
				className={styles.actionButton}
				size="small"
				color="primary"
				startIcon={<Delete />}
				onClick={removeProfilePicture}
			>
				Remove Picture
			</Button>
		</>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	userPageCardRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		height: 'fit-content',
		width: percent(100),

		padding: theme.spacing(1),
	},

	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		padding: theme.spacing(2),
	},

	avatarWrapper: {
		padding: theme.spacing(1),

		borderWidth: px(1),
		borderStyle: 'dashed',
		borderColor: theme.palette.grey[400],
		borderRadius: percent(100),
	},

	avatarLarge: {
		width: theme.spacing(9),
		height: theme.spacing(9),
	},

	nameplate: {
		marginTop: theme.spacing(2),
	},

	actionButton: {
		padding: theme.spacing(1),

		textTransform: 'none',
		width: percent(100),
	},
}))
