import { ArrowForward } from '@mui/icons-material'
import {
	Box,
	Chip,
	ChipProps,
	Fade,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import {
	WorkflowDefinitionBase,
	WorkflowInstanceBase,
	WorkflowStatus,
} from '../../../../../api/clients/workflows/DTOs'
import IconButtonLink from '../../../../../components/links/IconButtonLink'
import { workflowStatuses } from '../../../../../modules/Workflows/WorkflowInstanceStatus'

type WorkflowsHistoryTableProps = {
	originalHistory: ReturnType<typeof useHistory>
	workflowId: number
	instances: WorkflowInstanceBase[]
	definitions: WorkflowDefinitionBase[]
}

export const WorkflowsHistoryTable = (props: WorkflowsHistoryTableProps) => {
	const styles = useStyles()

	const getReadableStatus = useCallback((status: WorkflowStatus) => {
		return workflowStatuses[status]
	}, [])

	const getEndTime = useCallback((instance: WorkflowInstanceBase) => {
		if (instance.status !== WorkflowStatus.Finished) return '-'

		return `${new Date(instance.finishedAt).toLocaleString()} UTC`
	}, [])

	return (
		<Fade in>
			<TableContainer className={styles.tableRoot}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Instance ID</TableCell>
							<TableCell>Workflow Version Number</TableCell>
							<TableCell>Start Time</TableCell>
							<TableCell>End Time</TableCell>
							<TableCell>Status</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.instances.map((instance) => (
							<TableRow key={instance.id}>
								<TableCell>{instance.id}</TableCell>
								<TableCell>
									{
										props.definitions.find(
											(v) => v.id === instance.workflowDefinitionId,
										)?.version
									}
								</TableCell>
								<TableCell>
									{new Date(instance.startedAt).toLocaleString()} UTC
								</TableCell>
								<TableCell>{getEndTime(instance)}</TableCell>
								<TableCell>
									<Box display="flex" alignItems="center" gap={0.5}>
										<Chip
											variant="outlined"
											color={
												getReadableStatus(instance.status)
													?.color as ChipProps['color']
											}
											label={getReadableStatus(instance.status)?.label}
										/>
									</Box>
								</TableCell>
								<TableCell align="right">
									<IconButtonLink
										size="small"
										to={`/_workflows/${props.workflowId}/_versions/${instance.workflowDefinitionId}/_instances/${instance.id}`}
									>
										<ArrowForward />
									</IconButtonLink>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		overflow: 'auto',
	},
}))
