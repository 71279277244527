import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { GroupPage } from './GroupPage'
import { GroupsPage } from './GroupsPage'

export const GroupsPageRouter = () => {
	const { path } = useRouteMatch()
	updateBreadcrumbs(`/_administration/_groups`, 'Groups')

	return (
		<Switch>
			<Route path={makeChildRoute(':groupId', path)}>
				<GroupPage />
			</Route>
			<Route path={makeChildRoute('', path)}>
				<GroupsPage />
			</Route>
		</Switch>
	)
}
