import DataProviderInstancesClient from "../../api/clients/dataProviders/DataProviderInstancesClient"
import { OrganizationPropertiesClient } from "../../api/clients/identity/OrganizationPropertiesClient"

export const queryDataProviderById = async (instanceId: number, parameters: Record<string, string>) => {
	const dataProviderApi = new DataProviderInstancesClient()

	return (await dataProviderApi.execute(instanceId, parameters)).data
}

export const queryDataProviderByName = async (
	name: string,
	parameters: Record<string, string>,
) => {
	const dataProviderApi = new DataProviderInstancesClient()

	return (await dataProviderApi.executeByName(name, parameters)).data
}

export const getOrganizationPropertyValueByName = async (propertyName: string) => {
	const organizationPropertiesApi = new OrganizationPropertiesClient()

	const { data: property } =
		await organizationPropertiesApi.GetPropertyByName(propertyName)

	return property.value?.value
}