import { observable, reaction } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { NIL } from 'uuid'
import { FormBuilderSchema } from '../../FormBuilderCore/Types'
import {
	createComplexDefinition,
	createSimpleDefinition,
} from '../../FormBuilderCore/cells/CreateCellDefinition'
import { normalizeDefinitionArray } from '../../FormBuilderCore/cells/rendering/normalizeDefinitions'
import { EventBus } from '../../FormBuilderCore/eventBus/EventBus'
import { getGlobalComponentRegistry } from '../ComponentRegistry/GlobalComponentRegistry'
import {
	FormBuilderDesignerInputDefinition,
	GridRepeatDefinition,
	TextInputDefinition,
} from '../Components/'
import Designer from '../Runtimes/Designer'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../Runtimes/Standalone'

type FormBuilderTest = {
	test: 0
}

export const FormBuilderTest = () => {
	const eventBus = useMemo(() => {
		return new EventBus()
	}, [])

	const componentRegistry = useMemo(() => getGlobalComponentRegistry(), [])

	const referenceObject = useMemo(() => {
		return observable({
			label: 'hello!',
			alpha: 'another hello!',
			beta: '',
			fbDesigner: undefined as FormBuilderSchema | undefined,
			fbRepeat: [],
		})
	}, [])

	const initialFormBuilderSchema = useMemo(() => {
		const d_alpha = createComplexDefinition(
			TextInputDefinition,
			'beta',
			0,
			NIL,
			{
				label: 'the default label',
			},
			'some random test string',
		)

		const d_beta = createComplexDefinition(
			TextInputDefinition,
			'anotherOne',
			1,
			NIL,
			{
				label: 'this is the label text, it cannot change',
			},
			"what's up?",
		)

		const d_c = createSimpleDefinition(
			TextInputDefinition,
			'test',
			2,
			NIL,
			{
				label: 'text',
			},
			'default value',
		)

		const d_formBuilder = createSimpleDefinition(
			FormBuilderDesignerInputDefinition,
			'fbDesigner',
			3,
			NIL,
			{
				buttonText: 'Configure Form Builder',
			},
			{
				definitions: [],
				handlers: {
					actions: [],
					dependencies: {},
				},
			},
		)

		const d_repeat = createSimpleDefinition(
			GridRepeatDefinition,
			'fbRepeat',
			4,
			NIL,
			{
				columns: 2,
				rows: 2,
			},
		)

		const d_repeat_a = createSimpleDefinition(
			TextInputDefinition,
			'a',
			0,
			d_repeat.id,
			{
				label: 'A',
			},
			'',
		)

		const d_repeat_b = createSimpleDefinition(
			TextInputDefinition,
			'b',
			1,
			d_repeat.id,
			{
				label: 'B',
			},
			'',
		)

		const d_repeat_c = createSimpleDefinition(
			TextInputDefinition,
			'c',
			2,
			d_repeat.id,
			{
				label: 'C',
			},
			'',
		)

		const d_repeat_d = createSimpleDefinition(
			TextInputDefinition,
			'd',
			3,
			d_repeat.id,
			{
				label: 'D',
			},
			'',
		)

		const schema: FormBuilderSchema = {
			definitions: normalizeDefinitionArray(
				componentRegistry.componentCollection,
				[
					d_alpha,
					d_beta,
					d_c,
					d_formBuilder,
					d_repeat,
					d_repeat_a,
					d_repeat_b,
					d_repeat_c,
					d_repeat_d,
				],
			),
			handlers: {
				actions: [
					{
						id: 'evt-0',
						name: 'Test Event',
						description: 'a test event for testing',
						shouldActivate: {
							type: 'pureJs',
							text: 'return true',
						},
						action: {
							type: 'pureJs',
							text: `// <code>`,
						},
					},
				],
			},
		}
		return schema
	}, [])

	const [formBuilderSchema, setFormBuilderSchema] = useState(
		initialFormBuilderSchema,
	)

	const cellManager = useMemo(() => {
		return new StandaloneCellManager(formBuilderSchema, eventBus)
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			console.log(JSON.stringify(cellManager.createSnapshot(), null, 2))
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	})

	// useEffect(() => {
	// 	const interval = setInterval(
	// 		action(() => {
	// 			referenceObject.label = new Date().getSeconds().toString()
	// 		}),
	// 		1000,
	// 	)
	// 	return () => {
	// 		clearInterval(interval)
	// 	}
	// }, [])

	useEffect(() => {
		reaction(
			() => referenceObject.fbDesigner,
			() => {
				console.log('change detected!')
				console.log(
					'reference object value: ',
					JSON.stringify(referenceObject, null, 2),
				)
			},
		)
	}, [])

	return (
		<>
			{false && (
				<Designer
					schemaReference={formBuilderSchema}
					onSchemaModified={(f: FormBuilderSchema) => {
						console.log('setting form builder schema')
						setFormBuilderSchema(f)
					}}
				/>
			)}

			{true && <FormBuilderViewer cellManager={cellManager} />}
		</>
	)
}
