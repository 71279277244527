import { Identifier } from '@babel/types'
import { Box, MenuItem, Typography } from '@mui/material'
import { MemberExpression } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent, WithFormsInfo } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import useColorScheme from '../../internal/useColorScheme'

type UserInfoOption = {
	value: string
	displayName: string
}

const UserInfoComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const [, textColor] = useColorScheme(ComponentCategory.Variables)

		const userInfoOptions = useMemo<UserInfoOption[]>(() => {
			return [
				{
					value: 'firstName',
					displayName: 'First Name',
				},
				{
					value: 'lastName',
					displayName: 'Last Name',
				},
				{
					value: 'fullName',
					displayName: 'Full Name',
				},
				{
					value: 'emailAddress',
					displayName: 'Email Address',
				},
			]
		}, [])

		const propertyNode = useMemo(() => {
			return props.node.property as WithFormsInfo<Identifier>
		}, [props.node.property])

		const formsInfo = props.node.formsInfo

		if (formsInfo === undefined || formsInfo.type !== 'UserInfoAccess')
			throw new Error(
				`User Info component requires FormsInfo type of 'UserInfoAccess', got ${formsInfo?.type}`,
			)

		return (
			<Box>
				<ColoredFormControl formControlColor={textColor}>
					<ColoredSelect
						variant="standard"
						fullWidth
						selectColor={textColor}
						value={formsInfo.property}
						onChange={action((evt) => {
							formsInfo.property = evt.target.value as string
							propertyNode.name = evt.target.value as string
						})}
					>
						<MenuItem disabled value="">
							<Typography fontStyle="italic">User Info</Typography>
						</MenuItem>
						{userInfoOptions.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.displayName}
							</MenuItem>
						))}
					</ColoredSelect>
				</ColoredFormControl>
			</Box>
		)
	},
)

export default UserInfoComponent
