import {
	Card,
	CardHeader,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { AssignmentType } from '../../../../../../api/clients/workItems/DTOs'
import { DetailForm } from '../../../../../../api/DTOtemp'
import UserChip from '../../../../../../components/chips/UserChip'

type FormInfoCardProps = {
	form: DetailForm
}

const FormInfoCard = ({ form }: FormInfoCardProps) => {
	const styles = useStyles()

	return (
		<Card className={clsx(styles.formPageTopItem, styles.formPageLeftSpacing)}>
			<CardHeader title={<Typography variant="h6">About</Typography>} />
			<Divider />
			<Table>
				<TableBody>
					<TableRow>
						<TableCell variant="head">Active Version</TableCell>
						<TableCell variant="footer">{form.activeVersion}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant="head">Created By</TableCell>
						<TableCell variant="footer">
							<UserChip
								assignmentType={AssignmentType.User}
								id={form.createdBy}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant="head">Created On</TableCell>
						<TableCell variant="footer">
							{new Date(form.createdDate).toLocaleString()}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant="head">Last Updated By</TableCell>
						<TableCell variant="footer">
							<UserChip
								assignmentType={AssignmentType.User}
								id={form.lastModifiedBy}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant="head">Last Updated On</TableCell>
						<TableCell variant="footer">
							{new Date(form.lastModifiedDate).toLocaleString()}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Card>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formPageTopItem: {
		flex: '1',
	},

	formPageLeftSpacing: {
		marginRight: theme.spacing(1),
	},
}))

export default FormInfoCard
