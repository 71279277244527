import { Add } from '@mui/icons-material'
import { Button, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import {
	GroupRoleAssociationsClient,
	RolesClient,
} from '../../../../../api/clients/identity'
import { BaseRole } from '../../../../../api/DTO'
import { GroupRoleSimpleAssociation } from '../../../../../api/DTOtemp'
import { toastService } from '../../../../../services/notifications/ToastService'
import pluralizeString from '../../../../../utils/PluralizeString'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../AdministrationPageTabs'
import { AddValuesDialog } from '../AddValuesDialog'
import { GroupAdministrationPageProps } from '../GroupPage'
import { GroupRolesTable } from './GroupRolesTable'

export const GroupRolesAdministrationPage = observer(
	({ group, tabLabels, onTabChanged }: GroupAdministrationPageProps) => {
		const styles = useStyles()
		const groupRoleClient = new GroupRoleAssociationsClient()

		const localStore = useLocalObservable(() => ({
			groupRoles: [] as BaseRole[],
			organizationRoles: [] as BaseRole[],
			// get the roles in the organization that don't belong in the group to display when adding new roles
			get filteredOrganizationRoles() {
				return this.organizationRoles.filter(
					(orgRole) =>
						!this.groupRoles
							.map((groupRole) => groupRole.id)
							.includes(orgRole.id),
				)
			},
			isRequesting: false,
		}))

		const getGroupRoles = useCallback(() => {
			return groupRoleClient
				.GetGroupRoles(group.id)
				.then((response) => response.data.map((v) => v.role))
		}, [])

		const getOrganizationRoles = useCallback(() => {
			const rolesClient = new RolesClient()

			return rolesClient.GetAllRoles().then((response) => response.data)
		}, [])

		useEffect(() => {
			;(async () => {
				const groupRolesPromise = getGroupRoles()
				const organizationRolesPromise = getOrganizationRoles()

				runInAction(() => {
					localStore.isRequesting = true
				})

				const groupRoles = await groupRolesPromise
				const organizationRoles = await organizationRolesPromise

				runInAction(() => {
					localStore.groupRoles = groupRoles
					localStore.organizationRoles = organizationRoles
					localStore.isRequesting = false
				})
			})()

			getGroupRoles()
		}, [group, localStore])

		const handleAddRoles = async (roles: BaseRole[]) => {
			const associations: GroupRoleSimpleAssociation[] = []
			for (const role of roles)
				associations.push({ roleId: role.id, groupId: group.id })

			try {
				runInAction(() => {
					localStore.isRequesting = true
				})

				const addedCount = (
					await groupRoleClient.BulkAddGroupRoleAssociation(associations)
				).data

				const groupRoles = await getGroupRoles()

				runInAction(() => {
					localStore.groupRoles = groupRoles
				})

				toastService.displayToast({
					message: `${pluralizeString(addedCount, 'role')} added`,
					area: 'global',
				})
			} catch {
				toastService.displayToast({
					message: 'Error adding roles',
					area: 'global',
				})
			} finally {
				runInAction(() => {
					localStore.isRequesting = false
				})
			}
		}

		const handleRemoveRoles = async (roleIds: number[]) => {
			try {
				runInAction(() => {
					localStore.isRequesting = true
				})

				// we have to get the association ids of roles to remove
				const { data } = await groupRoleClient.GetGroupRoles(group.id)
				const associationsToRemove = data
					.filter((v) => roleIds.includes(v.roleId))
					.map((v) => v.id)

				const removedCount = (
					await groupRoleClient.BulkDeleteGroupRoleAssociations(
						associationsToRemove,
					)
				).data

				const groupRoles = await getGroupRoles()

				runInAction(() => {
					localStore.groupRoles = groupRoles
				})

				toastService.displayToast({
					message: `${pluralizeString(removedCount, 'role')} removed`,
					area: 'global',
				})
			} catch {
				toastService.displayToast({
					message: 'Error removing roles',
					area: 'global',
				})
			} finally {
				runInAction(() => {
					localStore.isRequesting = false
				})
			}
		}

		return (
			<AdministrationPageContainer
				title={group.name}
				actions={
					<GroupRolesSectionButtons
						groupName={group.name}
						onAddRoles={handleAddRoles}
						isRequesting={localStore.isRequesting}
						filteredOrganizationRoles={localStore.filteredOrganizationRoles}
					/>
				}
			>
				<div className={styles.groupContentRoot}>
					<AdministrationPageTabs
						currentTab={2}
						onTabChanged={onTabChanged}
						tabLabels={tabLabels}
					/>
					{/* <GroupPageTabs currentTab={2} onTabChanged={onTabChanged} /> */}
					<Divider />
					<GroupRolesTable
						roles={localStore.groupRoles}
						onRemoveRoles={handleRemoveRoles}
						isRequesting={localStore.isRequesting}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type GroupRolesSectionButtonsProps = {
	groupName: string
	filteredOrganizationRoles: BaseRole[]
	onAddRoles: (roles: BaseRole[]) => void
	isRequesting: boolean
}

const GroupRolesSectionButtons = observer(
	(props: GroupRolesSectionButtonsProps) => {
		const [addRoleOpen, setAddRoleOpen] = useState(false)

		const handleOpenAddRole = useCallback(() => {
			setAddRoleOpen(true)
		}, [])

		const handleCloseAddRole = useCallback(() => {
			setAddRoleOpen(false)
		}, [])

		const handleAddRoles = useCallback((roles: BaseRole[]) => {
			props.onAddRoles(roles)
			setAddRoleOpen(false)
		}, [])

		return (
			<div>
				<Button
					color="primary"
					variant="contained"
					startIcon={<Add />}
					onClick={handleOpenAddRole}
					disabled={props.isRequesting}
				>
					Add Role
				</Button>
				<AddValuesDialog<BaseRole>
					open={addRoleOpen}
					onClose={handleCloseAddRole}
					onCommit={handleAddRoles}
					getLabel={(role: BaseRole) => `${role.friendlyName}`}
					renderOption={(role) => {
						return (
							<>
								<Typography color="textPrimary" variant="subtitle2">
									{role.friendlyName}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									{(role.description?.length ?? 0) > 0
										? role.description
										: 'no description'}
								</Typography>
							</>
						)
					}}
					options={props.filteredOrganizationRoles}
					placeholder="Search roles"
					addText="Add Roles"
					header={`Add Roles to ${props.groupName}`}
				/>
			</div>
		)
	},
)

const useStyles = makeStyles((theme) => ({
	groupContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),

		overflow: 'hidden',
	},
}))
