import { Box, Typography } from '@mui/material'
import { Identifier } from 'estree'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import useColorScheme from '../../internal/useColorScheme'

const IdentifierComponent = observer((props: RendererComponent<Identifier>) => {
	const [color, textColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<Box
			component={Typography}
			color={textColor}
			bgcolor={color}
			border={1}
			padding={1}
			borderColor={textColor}
		>
			{props.node.name}
		</Box>
	)
})

export default IdentifierComponent
