import { Email } from '@mui/icons-material'
import { Link, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { percent } from 'csx'
import { useState } from 'react'
import { useHistory } from 'react-router'
import AnonymousUserClient from '../../api/clients/identity/AnonymousUserClient'
import { AuthenticationWrapper } from './AuthenticationWrapper'
import { UsernameEntryManager } from './UsernameEntry'

export const ForgotPasswordPage = () => {
	const styles = useStyles()
	const history = useHistory()

	const [emailSent, setEmailSent] = useState(false)
	const queryParams = new URLSearchParams(history.location.search)
	// get existing email for autofilling the username field
	const existingEmail = queryParams.get('email')

	const handleReceivedValidUsername = async (username: string) => {
		const userClient = new AnonymousUserClient()

		try {
			userClient.IssueResetToken(username)
			setEmailSent(true)
		} catch (error) {
			// even if the user doesn't exist still say we sent an email
			if (axios.isAxiosError(error) && error.response?.status === 404)
				setEmailSent(true)

			console.log(error)
		}
	}

	return (
		<div className={styles.wholePageLayout}>
			{!emailSent ? (
				<UsernameEntryManager
					headerText="Forgot Password"
					nextButtonText="Next"
					onReceivedValidUsername={handleReceivedValidUsername}
					existingUsername={existingEmail ?? undefined}
					belowUsernameEntryLink={<Link href="/_login">Back to Login</Link>}
				/>
			) : (
				<AuthenticationWrapper
					headerText="Check your email"
					onNextClicked={() => history.push('/_login')}
					nextButtonText="Back to Login"
					avatar={<Email />}
				>
					<Typography>
						We have sent you an email with instructions to reset your password.
					</Typography>
				</AuthenticationWrapper>
			)}
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	wholePageLayout: {
		display: 'flex',

		height: percent(100),
		width: percent(100),

		justifyContent: 'center',
		alignItems: 'center',
	},
}))
