import { ArrowForward } from '@mui/icons-material'
import { Box, MenuItem, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import { Identifier, MemberExpression, Node } from 'estree'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Fragment, useCallback, useMemo } from 'react'
import { NIL } from 'uuid'
import { useFormEventsContext } from '../../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/AdvancedEventsSection/FormEventsContext'
import { hexEncodeString } from '../../../../../utils/HexEncoding'
import { CellDefinition } from '../../../../FormHost/Types/CellDefinition'
import { CellType } from '../../../../FormHost/Types/CellType'
import { UnifiedCellDefinition } from '../../../../FormHost/Types/UnifiedCellDefinition'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import {
	PartiallyConstructedNode,
	RendererComponent,
	WithFormsInfo,
} from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import useColorScheme from '../../internal/useColorScheme'
import { resetNodes, treeGenerator } from './MemberExpressionComponent.assets'

type FieldAccessOption = {
	id: string
	displayName: string
	type: 'field' | 'value' | 'property'
}

/*
 * This is the most complex Visual Script Editor component. It's for choosing a field
 * within a form and either its value or a property that belongs to the field. We have
 * to make sure we are accessing the field correctly, which means starting with the
 * advancedEventApi, then the formProxy, then the form (which we select in this component),
 * then the field (and any children of the fields), and then finally "value" OR the properties
 * (which gets a little more complicated -- we have to add in a property "properties" before the
 * actual property (ex. label), so we access a field's property like form.field.properties.label).
 * This component handles all the complexity of creating a member expression that allows us to
 * create correct property access. -Caroline
 */

const FormFieldComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Variables)

		const formEventsContext = useFormEventsContext()

		const formsInfo = useMemo(() => {
			if (props.node.formsInfo?.type !== 'FormFieldAccess')
				throw new Error(
					`FormField component required FormsInfo type of 'FormFieldAccess', got ${props.node.formsInfo?.type}`,
				)

			return props.node.formsInfo
		}, [props.node.formsInfo])

		const selectedForm = useMemo(() => {
			return formEventsContext.forms.find((v) => v.id === formsInfo.formId)
		}, [formsInfo.formId, formEventsContext.forms])

		const path = observable(treeGenerator(props.node))

		const addNode = action(
			(
				fieldAccessOption: FieldAccessOption,
			): PartiallyConstructedNode<Node> => {
				const newNode = {
					type: Syntax.Identifier,
					formsInfo: {
						type: 'FieldAccess',
						accessor: fieldAccessOption.id,
					},
					name: fieldAccessOption.id,
				} as WithFormsInfo<Identifier>

				if (fieldAccessOption.type === 'field')
					newNode.name = `_${hexEncodeString(fieldAccessOption.id)}`

				path.push(newNode)

				resetNodes(path, props.node, false)

				return newNode
			},
		)

		const getOptionsListForField = (cellDefinition: CellDefinition) => {
			if (selectedForm === undefined) return []

			const options: FieldAccessOption[] = []

			// get all the descendents for the current cell definition
			options.push(
				...selectedForm.metadata.cellDefinitions
					.filter((v) => v.parentId === cellDefinition.id && v.name !== '')
					.map(
						(v) =>
							({
								id: v.id,
								displayName: v.name,
								type: 'field',
							} as FieldAccessOption),
					),
			)

			// make 'value' an option
			if (cellDefinition.type === CellType.Value)
				options.push({
					id: 'value',
					displayName: 'value',
					type: 'value',
				})

			// now here is the fun part - make all the editable properties options
			const properties = (cellDefinition as UnifiedCellDefinition).properties
			for (const property of Object.keys(properties)) {
				// only allow edits to properties that aren't restricted (ex - we can't change # of columns or rows in grid)
				// also we're only allowing edits to properties in form builder forms right now
				if (
					!(properties[property] as { runtimeRestricted?: boolean })
						?.runtimeRestricted &&
					selectedForm.contentType === 'application/json'
				) {
					options.push({
						id: property,
						displayName: property,
						type: 'property',
					})
				}
			}

			return options
		}

		// get all the options for a node - child cells, "value", or properties
		const getFieldAccessOptions = useCallback(
			(node: PartiallyConstructedNode<Node>): FieldAccessOption[] => {
				if (node.type !== Syntax.Identifier || selectedForm === undefined)
					return []

				let identifierNode = node as PartiallyConstructedNode<Identifier>

				// the options for a form will always only be cell definitions at the root
				if (identifierNode.formsInfo?.type === 'FormAccess')
					return selectedForm?.metadata.cellDefinitions
						.filter((v) => v.parentId === NIL && v.name !== '')
						.map((v) => ({
							id: v.id,
							displayName: v.name,
							type: 'field',
						}))

				/* if the node we're getting the options for (the last node in path) is 
				   'properties' (which we added so we can have correct property access 
						through the cell definition), we need to look at what the actual cell
						definition is, so we can know what options are available to it.
						Essentially we are ignoring the 'properties' node we added ourselves.
				*/
				if (
					(identifierNode as WithFormsInfo<Identifier>).name === 'properties'
				) {
					const propertiesIndex = path.indexOf(identifierNode)
					const previousNode = path[propertiesIndex - 1]
					if (
						previousNode !== undefined &&
						previousNode.type === Syntax.Identifier
					)
						identifierNode = previousNode as WithFormsInfo<Identifier>
				}

				// we check this here in case our original node we were getting options
				// for is 'properties' - and now identifierNode is the node before 'properties'
				if (
					identifierNode.formsInfo?.type !== 'FormFieldAccess' &&
					identifierNode.formsInfo?.type !== 'FieldAccess'
				)
					return []

				const cellDefinitionId =
					identifierNode.formsInfo.type === 'FieldAccess'
						? identifierNode.formsInfo.accessor
						: identifierNode.formsInfo.definitionId

				const cellDefinition = selectedForm.metadata.cellDefinitions.find(
					(v) => v.id === cellDefinitionId,
				)
				if (cellDefinition === undefined) return []

				return getOptionsListForField(cellDefinition)
			},
			[path, selectedForm],
		)

		/*
		 * to update a node (using a select):
		 * 1. update the current node to be the selected option
		 * 2. add 'properties' node before the current node if the selected option is
		 *    a cell's property. this is so we can handle property access when running
		 *    the event script.
		 * 3. if the current node is a property, but we're updating it to be a non-property
		 *    (i.e., value or a different field), we need to remove the 'properties' node that
		 *    comes before it.
		 */
		const updateNode = action(
			(
				node: PartiallyConstructedNode<Node>,
				fieldAccessOption: FieldAccessOption,
			) => {
				// we're only dealing with field accesses
				if (
					node.formsInfo?.type !== 'FieldAccess' ||
					node.type !== Syntax.Identifier
				)
					return

				let lastNode = path[path.length - 1]

				// get rid of the options following if this is not the last node bc those no longer apply!
				if (
					lastNode?.formsInfo?.type === 'FieldAccess' &&
					node.formsInfo.accessor !== lastNode.formsInfo.accessor
				) {
					const currentNodeIndex = path.indexOf(node)
					path.splice(currentNodeIndex + 1, path.length - currentNodeIndex)

					resetNodes(path, props.node, false)

					lastNode = path[path.length - 1]
				}

				const currentNodeIndex = path.indexOf(node)

				// handle adding "properties" node along with actual property
				if (fieldAccessOption.type === 'property') {
					if (
						path[currentNodeIndex - 1] !== undefined &&
						(path[currentNodeIndex - 1] as WithFormsInfo<Identifier>).name !==
							'properties'
					) {
						path.splice(currentNodeIndex, 0, {
							type: Syntax.Identifier,
							name: 'properties',
						} as Identifier)

						resetNodes(path, props.node, false)
					}
				}

				// get rid of properties if it's there & we've chosen a non-property
				if (
					fieldAccessOption.type !== 'property' &&
					(path[currentNodeIndex - 1] !== undefined &&
						(path[currentNodeIndex - 1] as WithFormsInfo<Identifier>).name) ===
						'properties'
				) {
					path.splice(currentNodeIndex - 1, 1)
					resetNodes(path, props.node, false)
				}

				node.formsInfo.accessor = fieldAccessOption.id

				// we need to hex encode definition ids since they may start with a number (bad for property access)
				// or they may have characters not allowed in property access
				if (fieldAccessOption.type === 'field')
					(node as Identifier).name = `_${hexEncodeString(
						fieldAccessOption.id,
					)}`
				else (node as Identifier).name = fieldAccessOption.id

				// if this node has options (aka it's a cell and we need to choose a child, property, or value)
				// add a new node by calling this function again (add new node to path, select option is first option for that node)
				const options = getFieldAccessOptions(node)

				// if we have possible options for this new last node, display them so user needs to pick value/property
				if (
					options.length > 0 &&
					lastNode?.formsInfo?.type === 'FieldAccess' &&
					lastNode.formsInfo.accessor === node.formsInfo.accessor
				) {
					const newNode = addNode(options[0]) // todo prob don't need to pass in the select option here!
					if (getFieldAccessOptions(newNode).length > 0)
						updateNode(newNode, options[0])
				}

				// finally, reset nodes to fix the member expression tree
				resetNodes(path, props.node, false)
			},
		)

		// 'advancedEventApi', 'formProxy', and 'properties' are here only for property access
		// the user should not see/edit them.
		const isUserEditable = useCallback((node: Identifier) => {
			return (
				node.name !== 'formProxy' &&
				node.name !== 'properties' &&
				node.name !== 'advancedEventApi'
			)
		}, [])

		return (
			<Box display="flex" alignItems="center" gap={2} bgcolor={color}>
				<Box display="flex" gap={1} alignItems="center">
					{path.map((node, i) => {
						if (!isUserEditable(node as Identifier))
							return <Fragment key={i}></Fragment>

						if (node?.formsInfo?.type === 'FormAccess') {
							return (
								<Box
									key={i}
									display="flex"
									flexDirection="row"
									gap={1}
									alignItems="center"
								>
									<ColoredFormControl formControlColor={textColor}>
										<ColoredSelect
											selectColor={textColor}
											variant="standard"
											value={formsInfo.formId}
											onChange={action((evt) => {
												formsInfo.formId = evt.target.value as number
												if (node.formsInfo?.type === 'FormAccess')
													node.formsInfo.formId = evt.target.value as number

												if (node.type === Syntax.Identifier) {
													;(node as Identifier).name = `_${evt.target.value}`
												}
											})}
										>
											<MenuItem disabled value={0} key={0}>
												<Typography fontStyle="italic">Form</Typography>
											</MenuItem>
											{formEventsContext.forms.map((form) => (
												<MenuItem key={form.id} value={form.id}>
													{form.name}
												</MenuItem>
											))}
										</ColoredSelect>
									</ColoredFormControl>

									<ArrowForward sx={{ color: textColor }} />
								</Box>
							)
						}

						if (node?.formsInfo?.type !== 'FieldAccess')
							return <Box key={i}></Box>

						if (i === path.length - 1) {
							return (
								<FieldAccessSelect
									key={i}
									selectedId={node.formsInfo.accessor}
									options={getFieldAccessOptions(
										path[i - 1] as WithFormsInfo<Identifier>,
									)}
									onChange={action((selectOption) => {
										updateNode(node, selectOption)
									})}
								/>
							)
						} else
							return (
								<Box display="flex" alignItems="center" gap={1} key={i}>
									<FieldAccessSelect
										key={i}
										selectedId={node.formsInfo.accessor}
										options={getFieldAccessOptions(
											path[i - 1] as WithFormsInfo<Identifier>,
										)}
										onChange={action((selectOption) => {
											updateNode(node, selectOption)
										})}
									/>
									<ArrowForward sx={{ color: textColor }} />
								</Box>
							)
					})}
				</Box>
			</Box>
		)
	},
)

type FieldAccessSelectProps = {
	selectedId: string
	options: FieldAccessOption[]
	onChange: (selectedOption: FieldAccessOption) => void
}

// for selecting a field, 'value', or a field's property
const FieldAccessSelect = observer((props: FieldAccessSelectProps) => {
	const [, textColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<ColoredFormControl formControlColor={textColor}>
			<ColoredSelect
				selectColor={textColor}
				variant="standard"
				value={props.selectedId}
				displayEmpty
				onChange={action((evt) => {
					const selectedField = props.options.find(
						(v) => v.id === evt.target.value,
					)
					if (selectedField === undefined)
						throw new Error(
							`selected field with id ${evt.target.value} could not be found in options list`,
						)
					props.onChange(selectedField)
				})}
			>
				<MenuItem disabled value="" key="">
					<Typography fontStyle="italic">Field Access</Typography>
				</MenuItem>
				{props.options.map((option) => (
					<MenuItem key={option.id} value={option.id}>
						{option.displayName}
					</MenuItem>
				))}
			</ColoredSelect>
		</ColoredFormControl>
	)
})

export default FormFieldComponent
