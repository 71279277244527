import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type CheckboxProps = {
	label: string
}

export const Checkbox = observer((props: CheckboxProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()

	return (
		<FormControlLabel
			control={
				<MaterialCheckbox
					checked={(context.value as boolean) ?? false}
					indeterminate={context.value === undefined}
					onChange={action((v) => {
						const oldValue = context.value
						const newValue = v.currentTarget.checked
						context.onChange(newValue)
						changeEventEmitter(
							cellManager.eventBus,
							{
								oldValue: oldValue,
								newValue: newValue,
							},
							{
								formHost: cellManager,
								elementTag: CheckboxElementTag,
								definitionId: context.cellInstance.definitionId,
								instanceId: context.cellInstance.id,
							},
						)
					})}
					onBlur={() => {
						focusEventEmitter(
							cellManager.eventBus,
							{
								focusType: 'FocusLost',
							},
							{
								formHost: cellManager,
								elementTag: CheckboxElementTag,
								definitionId: context.cellInstance.definitionId,
								instanceId: context.cellInstance.id,
							},
						)
					}}
				/>
			}
			label={props.label}
		/>
	)
})

export const CheckboxElementTag = 'cacb0ceb-1ff9-455b-8a48-95c34ea5a9cf'

export const InternalCheckboxId = '2e89e5d7-17f8-49e1-b4e6-594c274c6860'

export const InternalCheckboxDefinition = specifyInternalComponent(
	InternalCheckboxId,
	Checkbox,
	CheckboxElementTag,
	FieldType.Boolean,
	{
		label: 'Checkbox',
	},
	[],
)
