import { AxiosResponse } from "axios";
import { ChunkedUploadRequest, ChunkedUploadResponse } from "../../DTOtemp";
import SecureClient from "../SecureClient";

export class BlobUploadClient extends SecureClient {
	private _urlBase: string
	
	constructor() {
		super()
		this._urlBase = 'BlobUpload'
	}

	public uploadBlob(file: File): Promise<AxiosResponse<number>> {
		const formData = new FormData()
		formData.append('file', file)

		return this._api.post<number>(this._urlBase, formData, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()

		}})
	}

	public createBlobForChunkedUpload(file: File, fileHash: string): Promise<AxiosResponse<ChunkedUploadResponse>> {
		const uploadRequest: ChunkedUploadRequest = {
			fileName: file.name,
			fileBytes: file.size,
			contentType: "application/json",
			fileHash: fileHash
		}
		
		return this._api.post<ChunkedUploadResponse>(`${this._urlBase}/chunk`, uploadRequest, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public uploadBlobChunk(chunkIndex: number, blobId: number, chunk: Blob): Promise<AxiosResponse> {
		const formData = new FormData()
		formData.append('chunk', chunk)
		formData.append('blobId', blobId.toString())
		
		return this._api.post(`${this._urlBase}/chunk/${chunkIndex}`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public verifyChunkedUpload(blobId: number): Promise<AxiosResponse> {
		return this._api.get(`${this._urlBase}/verify/${blobId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})
	}

	public commitUpload(blobId: number): Promise<AxiosResponse> {
		return this._api.put(`${this._urlBase}/commit/${blobId}`, {}, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})
	}

	public cancelUpload(blobId: number): Promise<AxiosResponse> {
		return this._api.put(`${this._urlBase}/cancel/${blobId}`, {}, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})
	}
}
