import { observable } from 'mobx'
import React, {
	createContext,
	PropsWithChildren,
	useContext,
	useMemo,
} from 'react'
import { IFormBuilderFormHost } from '../../../IFormBuilderFormHost'

export type FormBuilderContextType = {
	cellManager: IFormBuilderFormHost
}

const formBuilderContext = createContext<FormBuilderContextType | undefined>(
	undefined,
)

export const FormBuilderContextProvider = (
	props: PropsWithChildren<FormBuilderContextType>,
) => {
	const value = useMemo(
		() =>
			observable({
				cellManager: props.cellManager,
			}),
		[props.cellManager],
	)

	return (
		<formBuilderContext.Provider value={value}>
			{props.children}
		</formBuilderContext.Provider>
	)
}

export function useFormBuilderContext() {
	const context = useContext(formBuilderContext)
	if (context === undefined) throw Error('form builder context was undefined')
	return context
}
