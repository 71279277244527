import { Box, MenuItem, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import { Expression, LogicalExpression, LogicalOperator } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'
import { MemberExpressions } from '../variables/MemberExpressionComponent'

const validNodes = [
	Syntax.BinaryExpression,
	Syntax.LogicalExpression,
	Syntax.Literal,
	Syntax.CallExpression,
	...MemberExpressions,
]

const LogicalExpressionComponent = observer(
	(props: RendererComponent<LogicalExpression>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Logic)

		return (
			<Box
				display="flex"
				flexDirection="row"
				gap={2}
				alignItems="center"
				justifyContent="space-between"
				bgcolor={color}
				padding={1}
			>
				<Box borderRadius={3}>
					<Renderer
						dropMode={DropMode.Replace}
						validNodes={validNodes}
						getter={() => props.node.left}
						setter={action((node) => (props.node.left = node as Expression))}
					/>
				</Box>

				<Box>
					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							selectColor={textColor}
							variant="standard"
							fullWidth
							size="small"
							value={props.node.operator}
							onChange={(v) => {
								props.node.operator = v.target.value as LogicalOperator
							}}
						>
							<MenuItem value={'&&'}>
								<Typography fontStyle={'italic'}>and</Typography>
							</MenuItem>
							<MenuItem value={'||'}>
								<Typography fontStyle={'italic'}>or</Typography>
							</MenuItem>
						</ColoredSelect>
					</ColoredFormControl>
				</Box>

				<Box borderRadius={3}>
					<Renderer
						dropMode={DropMode.Replace}
						validNodes={validNodes}
						getter={() => props.node.right}
						setter={action((node) => (props.node.right = node as Expression))}
					/>
				</Box>
			</Box>
		)
	},
)

export default LogicalExpressionComponent
