import { Divider, Fade, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import { FormSectionProps } from '../FormPage'
import FormVersionHistoryTable from '../FormVersionHistoryTable'

export const FormVersionsSection = ({
	form,
	packageId,
	packageVersionNumber,
	tabLabels,
	onTabChanged,
}: FormSectionProps) => {
	const styles = useStyles()

	return (
		<AdministrationPageContainer title={form.name} actions={<div> </div>}>
			<div className={styles.formPageRoot}>
				<AdministrationPageTabs
					currentTab={1}
					tabLabels={tabLabels}
					onTabChanged={onTabChanged}
				/>
				<Divider />
				<Fade in>
					<div className={styles.contentRoot}>
						<FormVersionHistoryTable
							formInfo={form}
							packageId={packageId}
							packageVersionNumber={packageVersionNumber}
						/>
					</div>
				</Fade>
			</div>
		</AdministrationPageContainer>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formPageRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),

		overflow: 'hidden',
	},

	contentRoot: {
		paddingTop: theme.spacing(2),

		width: percent(100),
	},
}))
