import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { LicenseResponse } from '../../../../api/clients/identity/LicensingClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import ButtonLink from '../../../../components/links/ButtonLink'
import licenseInstance from '../../../../services/session/LicensingService'

type LicenseCheckProps = {
	demandCheck?: (license: LicenseResponse) => boolean
}

const LicensingCheck = observer(
	(props: React.PropsWithChildren<LicenseCheckProps>) => {
		const licenseInfo = licenseInstance.licenseInfo

		console.log('license info status: ', licenseInfo.status)

		if (licenseInfo.status == 'loading') return <FullscreenSpinner />

		if (licenseInfo.status == 'licensed')
			if (props.demandCheck === undefined) return <>{props.children}</>
			else if (props.demandCheck(licenseInfo.license))
				return <>{props.children}</>

		return (
			<Box
				height="100%"
				width="100%"
				display="flex"
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Typography fontWeight={600} variant="h6">
					Organization Not Licensed
				</Typography>
				<ButtonLink to={'/_administration/_licensing'}>
					Go to Licensing
				</ButtonLink>
			</Box>
		)
	},
)

export default LicensingCheck
