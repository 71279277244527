import { AlertProps } from '@mui/material'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { ReactNode } from 'react'
import { ObservableQueue } from '../notifications'

export type ToastData = {
	area: string, // from the start: 'global' | 'formPackage'
	message: ReactNode
	/* the number of seconds that the toast should be visible for */
	delay?: number
	severity?: AlertProps['severity']
	icon?: ReactNode // false to hide it :)
}

// TODO update this in the future to match PromptService

export class ToastService {
	constructor() {
		this._toastQueue = new ObservableQueue<ToastData>()

		makeAutoObservable(this)
	}

	private _toastQueue: ObservableQueue<ToastData>

	private _bigPromise = Promise.resolve()

	public get toast(): ToastData | undefined {
		if (this._toastQueue.head === null) return undefined

		return this._toastQueue.head
	}

	private _fadeOut = true

	public get fadeOut(): boolean {
		const fadeOut = this._fadeOut;
		const toast = this._toastQueue.head
		return fadeOut && toast !== null
	}

	public displayToast(toastData: ToastData) {
		this._toastQueue.enqueue(toastData)

		console.log(toastData.message)
		console.log('currently there are ' + this._toastQueue.length + ' elements in the queue')

		this._bigPromise = this._bigPromise
			.then(
				(v) =>
					new Promise<void>((resolve) => {
						runInAction(() => { this._fadeOut = false; } )
						setTimeout(action(() => {
							resolve()
						}), (toastData.delay && toastData.delay * 1000) ?? 6000)
					}),
			)
			.then(() => {
				runInAction(() => { this._fadeOut = true; })
				setTimeout(action(() => {
					this._toastQueue.dequeue()
				}), 500)
			})
	}
}

export const toastService = new ToastService()
