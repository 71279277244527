import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
	AppBar,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Popover,
	Theme,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import React, { useMemo, useState } from 'react'
import { toastService } from '../../services/notifications/ToastService'
import { DetailFormWithData } from '../../services/offline/FormPackageHandling'

type PackageFormSwapperProps = {
	forms: DetailFormWithData[]
	onSelectForm: (index: number) => void
	isCurrentFormValid: (index: number) => boolean
	isWizardMode: boolean
}

/**
 * This component is overlayed on top of the form viewer - it includes the app bar that displays the
 * form name and lets the user go to previous/next form using the arrow buttons or select a form from
 * the popover, as well as arrow icons that are on the left/right sides of the form that let the user
 * go to the previous/next form. I created this component to overlay on top of the form viewer in the
 * Package Host b/c we can't store the selectedIndex in state there, b/c it'll force all the forms to
 * re-render every time we swap to a different form, which is bad! We do have a ref in the Package Host
 * where we keep track of what form we're on, but changing that doesn't re-render the child components,
 * which we need to update the form name/disabled arrow icons.
 * So, all this to say, we needed a component where the selected form index would be shared between the
 * app bar and the side arrows, without updating PackageHost's state. The solution is a component that
 * is on top of the form viewer! Added some styling to make it look like part of the form viewer. -Caroline
 */
export const PackageFormSwapper = React.forwardRef(
	(props: PackageFormSwapperProps, ref: React.ForwardedRef<HTMLDivElement>) => {
		const styles = useStyles()
		const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
		const [selectedIndex, setSelectedIndex] = useState(0)

		const isFirstForm = useMemo(() => {
			return selectedIndex === 0
		}, [selectedIndex])

		const isLastForm = useMemo(() => {
			return selectedIndex === props.forms.length - 1
		}, [selectedIndex])

		const handleSelectForm = (index: number) => {
			if (selectedIndex === index) return

			// won't change tabs if wizard mode is active and form isn't valid
			if (!props.isCurrentFormValid(selectedIndex) && props.isWizardMode) {
				toastService.displayToast({
					message:
						'Current form must be valid before moving to a different form',
					area: 'formPackage',
				})
				return
			}

			props.onSelectForm(index)
			setSelectedIndex(index)
		}

		// there's no forms!?
		console.log(props.forms)

		return (
			<div className={styles.formSwapper}>
				<AppBar
					color="default"
					position="absolute"
					className={styles.appBar}
					ref={ref}
				>
					<Tooltip
						title={isFirstForm ? '' : 'Previous Form'}
						className={styles.appBarLeft}
					>
						<IconButton
							onClick={() => handleSelectForm(selectedIndex - 1)}
							disabled={isFirstForm}
							color="primary"
							size="large"
						>
							<ChevronLeft />
						</IconButton>
					</Tooltip>

					<Button
						onClick={(evt: React.MouseEvent<HTMLButtonElement>) =>
							setAnchorEl(evt.currentTarget)
						}
						className={styles.appBarTitle}
					>
						{props.forms[selectedIndex].name}
					</Button>
					<Tooltip
						title={isLastForm ? '' : 'Next Form'}
						className={styles.appBarRight}
					>
						<IconButton
							onClick={() => handleSelectForm(selectedIndex + 1)}
							disabled={isLastForm}
							color="primary"
							size="large"
						>
							<ChevronRight />
						</IconButton>
					</Tooltip>
					<Popover
						open={anchorEl !== null}
						onClose={() => setAnchorEl(null)}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<List>
							{props.forms.map((form) => (
								<ListItem
									key={form.id}
									divider={form.ordinalPosition !== props.forms.length - 1}
								>
									<ListItemButton
										onClick={() => {
											handleSelectForm(form.ordinalPosition)
											setAnchorEl(null)
										}}
										selected={selectedIndex === form.ordinalPosition}
									>
										<ListItemText>{form.name}</ListItemText>
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</Popover>
				</AppBar>
				<div className={styles.formViewerArrowContainer}>
					<div className={styles.leftArrowContainer}>
						{!isFirstForm && (
							<Tooltip title="Previous Form">
								<div>
									<IconButton
										onClick={() => handleSelectForm(selectedIndex - 1)}
										color="primary"
										size="large"
										className={styles.leftArrowButton}
									>
										<ChevronLeft fontSize="large" />
									</IconButton>
								</div>
							</Tooltip>
						)}
					</div>
					<div className={styles.rightArrowContainer}>
						{!isLastForm && (
							<Tooltip title="Next Form">
								<div>
									<IconButton
										className={styles.rightArrowButton}
										onClick={() => handleSelectForm(selectedIndex + 1)}
										color="primary"
										size="large"
									>
										<ChevronRight fontSize="large" />
									</IconButton>
								</div>
							</Tooltip>
						)}
					</div>
				</div>
			</div>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		padding: theme.spacing(2, 0),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',

		// stylings for a grid header are commented out

		// display: 'grid',
		// gridTemplateColumns: '3fr 1fr 3fr 1fr 3fr',
		// gridTemplateRows: '1fr',
		// gridRowGap: px(12),
	},

	appBarLeft: {
		// gridArea: '1 / 2 / 2 / 3',
		// zIndex: theme.zIndex.speedDial,
	},

	appBarTitle: {
		// gridArea: '1 / 3 / 2 / 4',
		// zIndex: theme.zIndex.speedDial,
	},

	appBarRight: {
		// gridArea: '1 / 4 / 2 / 5',
		// zIndex: theme.zIndex.speedDial,
	},

	formSwapper: {
		position: 'absolute',
		width: percent(100),
		height: percent(100),
		overflow: 'hidden',
	},

	formViewerArrowContainer: {
		position: 'absolute',
		width: percent(100),
		height: percent(100),
	},

	leftArrowContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		height: percent(100),
		left: 0,
		margin: theme.spacing(0, 2),
	},

	leftArrowButton: {
		top: 0,
		left: 0,
		bottom: 0,
		zIndex: theme.zIndex.speedDial,
	},

	rightArrowContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		height: percent(100),
		right: 0,
		margin: theme.spacing(0, 2),
	},

	rightArrowButton: {
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: theme.zIndex.speedDial,
	},
}))
