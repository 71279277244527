import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { PropsWithChildren } from 'react'

type ScrollbarProps = {
	name?: string
	contentClassName?: string
}

// Wrap this around content to add a scrollbar that is consistent with the theme
export const Scrollbar = (props: PropsWithChildren<ScrollbarProps>) => {
	const styles = useStyles()

	return (
		<div className={styles.scrollbar}>
			<div className={props.contentClassName}>{props.children}</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	scrollbar: {
		overflow: 'auto',
		width: percent(100),
		height: percent(100),

		'&::-webkit-scrollbar': {
			width: theme.spacing(1),
		},
		'&::-webkit-scrollbar-thumb': {
			background:
				theme.palette.mode === 'dark'
					? theme.palette.primary.dark
					: theme.palette.primary.light,
			borderRadius: theme.spacing(4),
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.primary.main,
		},
	},
}))
