
import { AxiosResponse } from "axios"
import { UserProperty, UserPropertySimple } from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class UserPropertiesClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `UserProperties`
	}

	/**
	 * Get all user properties for the given organization
	 */
	public GetUserProperties(includeNonEditable?: boolean)
		: Promise<AxiosResponse<UserProperty[]>> {
		return this._api.get<UserProperty[]>(
			`${this._urlBase}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				},
				params: {
					includeNonEditable
				}
			}
		)
	}

	/**
	 * Create a user property with the given values
	 * @param prop 
	 */
	public CreateUserProperty(prop: UserPropertySimple)
		: Promise<AxiosResponse> {
		return this._api.post(
			`${this._urlBase}`,
			JSON.stringify(prop),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Get the user property at the specified id
	 * @param propId 
	 */
	public GetUserProperty(propId: number)
		: Promise<AxiosResponse<UserProperty>> {
		return this._api.get<UserProperty>(
			`${this._urlBase}/${propId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Update the user property at the specified route
	 * @param propId 
	 * @param prop 
	 */
	public UpdateUserProperty(propId: number, prop: UserPropertySimple)
		: Promise<AxiosResponse> {
		return this._api.put(
			`${this._urlBase}/${propId}`,
			JSON.stringify(prop),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader(),
				}
			}
		)
	}

	/**
	 * Delete the user property at the specified route
	 * @param propId 
	 */
	public DeleteUserProperty(propId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`${this._urlBase}/${propId}`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

}