import { Divider, Tab, Tabs, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import React, { useCallback } from 'react'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { FormBuilderDesigner } from './FormBuilderDesigner'
import { FormBuilderEventEditor } from './FormBuilderEventEditor'

type DesignerProps = {
	schemaReference: FormBuilderSchema
	onSchemaModified: (v: FormBuilderSchema) => void
}

export const Designer = observer(
	({ schemaReference, onSchemaModified }: DesignerProps) => {
		const styles = useStyles()

		const state = useLocalObservable(() => ({
			selectedTabIndex: 0,
		}))

		const handleTabChange = useCallback(
			action((_v: React.SyntheticEvent, newValue: number) => {
				state.selectedTabIndex = newValue
			}),
			[state],
		)

		return (
			<div className={styles.formBuilderTabEditorRoot}>
				<div className={styles.formBuilderTabs}>
					<Tabs
						indicatorColor="primary"
						value={state.selectedTabIndex}
						onChange={handleTabChange}
					>
						<Tab label="Schema Editor"></Tab>
						<Tab label="Events"></Tab>
						<Tab label="Settings"></Tab>
					</Tabs>
				</div>
				<Divider />
				{state.selectedTabIndex === 0 && (
					<FormBuilderDesigner
						schemaReference={schemaReference}
						onSchemaModified={onSchemaModified}
					/>
				)}
				{state.selectedTabIndex === 1 && (
					<FormBuilderEventEditor schemaReference={schemaReference} />
				)}
			</div>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	formBuilderTabEditorRoot: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},
	formBuilderTabs: {
		backgroundColor: theme.palette.background.paper,
	},
}))
