import { Box } from '@mui/material'
import { BlockStatement } from 'estree'
import { observer } from 'mobx-react'
import { RendererComponent } from '../../../Types'
import Editor from '../../internal/Editor'

const BlockStatementComponent = observer(
	(props: RendererComponent<BlockStatement>) => {
		return (
			<Box>
				<Editor node={props.node as BlockStatement} />
			</Box>
		)
	},
)

export default BlockStatementComponent
