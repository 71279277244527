import { Dialog } from '@mui/material'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import React, { PropsWithChildren, createContext, useContext } from 'react'

type CloseResult = 'okay' | 'cancel' | 'error'

export type DialogProps = {
	close(result: CloseResult): void
}

type DialogTracker = {
	Viewer(props: DialogProps): React.ReactElement
	resolvePromise(value: CloseResult | PromiseLike<CloseResult>): void
}

class PromptService {
	public dialogTrackers: DialogTracker[] = []

	constructor() {
		makeAutoObservable(this)
	}

	public showDialog(
		display: (props: DialogProps) => React.ReactElement,
	): Promise<CloseResult> {
		console.log('attempting to show dialog')

		// @ts-expect-error - we have to have this assignment here, JS spec says ctor of promise is sync so we can export
		let promiseResolve: (
			value: CloseResult | PromiseLike<CloseResult>,
		) => void = undefined

		const promise = new Promise<CloseResult>((resolve, reject) => {
			promiseResolve = resolve
		})

		console.log('promise resolve value: ', promiseResolve)

		this.dialogTrackers.push({
			Viewer: display,
			resolvePromise: promiseResolve,
		})

		promise.then((v) => {
			this.dialogTrackers.shift()
		})

		return promise
	}
}

const promptService = new PromptService()

const context = createContext(promptService)

export const PromptContextProvider = (props: PropsWithChildren<unknown>) => {
	return (
		<context.Provider value={promptService}>
			<PromptDisplayComponent />
			{props.children}
		</context.Provider>
	)
}

type PromptServiceType = {
	showDialog(
		display: (props: DialogProps) => React.ReactElement,
	): Promise<CloseResult>
}

export const usePrompts = (): PromptServiceType => {
	const contextInstance = useContext(context)
	return contextInstance
}

const PromptDisplayComponent = observer(() => {
	const length = promptService.dialogTrackers.length

	if (length === 0) return <></>

	const firstTracker = promptService.dialogTrackers[0]

	return (
		<>
			{length > 0 && (
				<Dialog open={true}>
					<firstTracker.Viewer
						close={(v) => {
							firstTracker.resolvePromise(v)
						}}
					/>
				</Dialog>
			)}
		</>
	)
})
