import { Theme, useTheme } from '@mui/material'
import { GlobalStyles } from '@mui/styled-engine'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useMemo } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { LicenseResponse } from '../../api/clients/identity/LicensingClient'
import { FormBuilderTest } from '../../modules/FormBuilderInterop/FormBuilderTest/TestFile'
import { WorkflowEditorHostRouter } from '../../modules/Workflows/WorkflowEditorHostRouter'
import { HomePage } from '../../pages/HomePage'
import LoginPage from '../../pages/LoginPage'
import { AuthenticationProviderLoginPage } from '../../pages/LoginPage/AuthenticationProviderLoginPage'
import { ForgotPasswordPage } from '../../pages/LoginPage/ForgotPasswordPage'
import { LoginAuthMethodsPage } from '../../pages/LoginPage/LoginAuthMethodsPage'
import { LogoutPage } from '../../pages/LoginPage/LogoutPage'
import { ResetPasswordPage } from '../../pages/LoginPage/ResetPasswordPage'
import LicenseCheck from '../../pages/Organization/Administration/Licensing/LicenseCheck'
import { HTMLPortalViewer } from '../../pages/Organization/Administration/Portals/HTMLPortalViewer'
import { Eula } from '../../pages/Organization/Eula'
import OrganizationRouter from '../../pages/Organization/OrganizationRouter'
import PackageHostPage from '../../pages/PackageHostPage'
import AnonymousFormPackageHostPage from '../../pages/PackageHostPage/AnonymousFormPackageHostPage'
import { ReceivedFormPackageHostPage } from '../../pages/PackageHostPage/ReceivedFormPackageHostPage'
import { SavedFormPackageHostPage } from '../../pages/PackageHostPage/SavedFormPackageHostPage'
import { WorkItemReviewPage } from '../../pages/WorkItems/WorkItemReviewPage'
import { SessionService } from '../../services/session'
import { makeChildRoute } from '../../utils/CreateChildRoute'
import AnonymousRoute from '../../utils/router/AnonymousRoute'
import OrganizationRoute from '../../utils/router/OrganizationRoute'
import { ToastWrapper } from '../toast/ToastWrapper'

export function RootRouter() {
	const sessionService = useMemo(() => new SessionService(), [])

	const { path } = useRouteMatch()

	const styles = useStyles()

	const theme = useTheme()

	const anonymousDemandCheck = (v: LicenseResponse) => {
		const module = v.modules['FormsInMotion-Anonymous']
		if (module === undefined) return false

		const startDate = new Date(module.startDate)
		const endDate = new Date(module.expirationDate)
		const now = new Date()

		return startDate < now && endDate > now
	}

	return (
		<>
			<GlobalStyles
				styles={{
					['::-webkit-scrollbar']: {
						width: theme.spacing(1),
						height: theme.spacing(1),
					},

					['::-webkit-scrollbar-thumb']: {
						background:
							theme.palette.mode === 'dark'
								? theme.palette.primary.dark
								: theme.palette.primary.light,
						borderRadius: theme.spacing(4),
					},

					['::-webkit-scrollbar-thumb:hover']: {
						background: theme.palette.primary.main,
					},

					['::-webkit-scrollbar-corner']: {
						opacity: 0,
					},
				}}
			/>
			<div className={styles.appRoot}>
				<Switch>
					{/* <Route path={makeChildRoute('_ocr/:workItemId?', path)}>
						<OcrDocumentReviewDemo />
					</Route> */}

					<Route path={makeChildRoute('/documentation', path)} />
					<Route
						path={makeChildRoute('fbtest', path)}
						component={FormBuilderTest}
					/>
					<Route
						path={makeChildRoute('_work-items/:workItemId?', path)}
						component={WorkItemReviewPage}
					/>
					<OrganizationRoute
						path={makeChildRoute('_workflows/:workflowId', path)}
						component={WorkflowEditorHostRouter}
					/>
					<Route
						path={makeChildRoute('_logout', path)}
						component={LogoutPage}
					/>
					<AnonymousRoute
						path={makeChildRoute('signin-oidc/:instanceId', path)}
					>
						<AuthenticationProviderLoginPage />
					</AnonymousRoute>
					<AnonymousRoute path={makeChildRoute('_login/_auth-methods', path)}>
						<LoginAuthMethodsPage />
					</AnonymousRoute>
					<AnonymousRoute
						path={makeChildRoute('_login/:providerInstanceId?', path)}
					>
						<LoginPage />
					</AnonymousRoute>
					<AnonymousRoute path={makeChildRoute('_forgot-password', path)}>
						<ForgotPasswordPage />
					</AnonymousRoute>
					<AnonymousRoute path={makeChildRoute('ResetPassword', path)}>
						<ResetPasswordPage />
					</AnonymousRoute>
					<OrganizationRoute
						path={makeChildRoute(
							'_package-host/:packageId/_saved/:formPackageInstanceId',
							path,
						)}
					>
						<SavedFormPackageHostPage />
					</OrganizationRoute>
					<Route
						path={makeChildRoute(
							'_package-host/:packageId/_received/:workItemId',
							path,
						)}
					>
						<ReceivedFormPackageHostPage />
					</Route>
					<OrganizationRoute path={makeChildRoute('_eula', path)}>
						<Eula />
					</OrganizationRoute>
					<Route path={makeChildRoute('_validate-db', path)}>
						<HomePage />
					</Route>
					{/* signed in user route */}
					<OrganizationRoute
						path={makeChildRoute('_package-host/:packageId', path)}
					>
						<PackageHostPage />
					</OrganizationRoute>
					{/* anonymous route */}

					<Route
						path={makeChildRoute('_external/_package-host/:packageId', path)}
						render={({ match }) => {
							/* if the user is logged in and accessing the _external route (for anonymous forms),
							we want to redirect to use the non-anonymous component - so now we have the back button 
							to go back to form packages and a toast won't display telling the user they're viewing 
							anonymously with a sign in button
							*/
							return sessionService.isLoggedIn ? (
								<Redirect to={`/_package-host/${match.params.packageId}`} />
							) : (
								<LicenseCheck demandCheck={anonymousDemandCheck}>
									<AnonymousFormPackageHostPage />
								</LicenseCheck>
							)
						}}
					/>
					<OrganizationRoute
						path={makeChildRoute(
							'_portals/:portalId/_versions/:versionNumber',
							path,
						)}
					>
						<HTMLPortalViewer anonymous={false} />
					</OrganizationRoute>
					<Route
						path={makeChildRoute(
							'_external/_portals/:portalId/_versions/:versionNumber',
							path,
						)}
						render={({ match }) => {
							return sessionService.isLoggedIn ? (
								<Redirect
									to={`/_portals/${match.params.portalId}/_versions/${match.params.versionNumber}`}
								/>
							) : (
								<HTMLPortalViewer anonymous={true} />
							)
						}}
					/>
					<Route path={makeChildRoute('/', path)}>
						{sessionService.isLoggedIn ? (
							<Redirect to={`/_user/_home`} />
						) : (
							<Redirect to={`/_login`} />
						)}
					</Route>
					{/* catches all from path "/" -- handles default "/" and takes user to /_user/_home */}
					<OrganizationRoute path={path}>
						<OrganizationRouter></OrganizationRouter>
					</OrganizationRoute>
				</Switch>

				<ToastWrapper subscriptionArea="global" />
			</div>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	appRoot: {
		display: 'flex',

		width: percent(100),
		height: percent(100),

		background: theme.palette.background.default,
	},
}))
