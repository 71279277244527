import {
	ArticleOutlined,
	ErrorOutline,
	InputOutlined,
} from '@mui/icons-material'
import { Box, Divider, Popover, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent, px } from 'csx'
import { values } from 'mobx'
import { usePackageHostPageContext } from './PackageHostPageContext'

type ErrorsPopoverProps = {
	anchorEl: HTMLButtonElement | null
	onClose: () => void
}

export const ErrorsPopover = (props: ErrorsPopoverProps) => {
	const styles = useStyles()
	const context = usePackageHostPageContext()

	return (
		<Popover
			open={props.anchorEl !== null}
			anchorEl={props.anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			onClose={props.onClose}
			PaperProps={{
				className: styles.errorPopover,
			}}
		>
			<Typography variant="h6" component="h4" className={styles.errorHeader}>
				Errors
			</Typography>
			{values(context.validationErrors).map((value) =>
				value.map((v) => {
					return (
						<div key={v.registrationId}>
							<Divider sx={{ marginX: -2 }} />
							<Box display="flex" padding={2} marginLeft={-2}>
								<Box flex="1" flexDirection={'column'} gap={1}>
									<Box display="flex" flexDirection="row-reverse">
										<Box flex="1" display="flex" gap={1}>
											<ArticleOutlined fontSize="small" />
											<Typography fontWeight="bold">{v.errorBucket}</Typography>
										</Box>
										<Box flex="1" display="flex" gap={1}>
											<InputOutlined fontSize="small" />
											<Typography>{v.errorFieldName}</Typography>
										</Box>
									</Box>
									<Box display="flex" gap={1}>
										<ErrorOutline fontSize="small" color="error" />
										<Typography color="error">{v.errorText}</Typography>
									</Box>
								</Box>
							</Box>
						</div>
					)
				}),
			)}
		</Popover>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	errorPopover: {
		display: 'flex',
		flexDirection: 'column',
		height: 'fit-content',
		padding: theme.spacing(0, 2),

		[theme.breakpoints.up('xs')]: {
			maxWidth: percent(100),
		},

		[theme.breakpoints.up('sm')]: {
			minWidth: px(400),
		},

		[theme.breakpoints.up('md')]: {
			minWidth: px(500),
		},
	},

	errorHeader: {
		padding: theme.spacing(1, 0),
	},
}))
