import Typography from '@mui/material/Typography/Typography'
import { observer } from 'mobx-react'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyVoidComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import {
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'

type HeaderComponentProps = {
	headerLevel: string
	text: string
}

export const HeaderComponent = observer((props: HeaderComponentProps) => {
	const level = ('h' + props.headerLevel) as
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'

	return <Typography variant={level}>{props.text}</Typography>
})

export const HeaderComponentElementTag = 'b05d1d7e-d606-4724-aed7-0fad6efa55be'

export const HeaderComponentDefinitionId =
	'bf15a0cc-486c-4456-9f9f-58fbfdf62b73'

const HeaderComponentPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof HeaderComponent>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.headerLevel}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, headerLevel: v })
				}
				Editor={(props) => (
					<SelectPropertyConfigurator
						options={[
							{ key: '1', displayValue: 'Level 1' },
							{ key: '2', displayValue: 'Level 2' },
							{ key: '3', displayValue: 'Level 3' },
							{ key: '4', displayValue: 'Level 4' },
							{ key: '5', displayValue: 'Level 5' },
							{ key: '6', displayValue: 'Level 6' },
						]}
						{...props}
					/>
				)}
				label="Heading Level"
			/>
			<PropertySchematicConfigurator
				value={properties.text}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, text: v })}
				Editor={TextPropertyConfigurator}
				label="Heading Text"
			/>
		</>
	)
}

export const HeaderComponentDefinition = specifyVoidComponent(
	HeaderComponentDefinitionId,
	HeaderComponent,
	HeaderComponentElementTag,
	'Text Header',
	{
		configurator: HeaderComponentPropsEditor,
		defaultProperties: {
			headerLevel: '1',
			text: 'Heading',
		},
	},
)
