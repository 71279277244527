import { Close } from '@mui/icons-material'
import { Box, Button, IconButton, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { observer } from 'mobx-react'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyRepeatComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useRepeatCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/RepeatCellContextProvider'
import {
	NumberPropertyConfigurator,
	PropertySchematicConfigurator,
} from '../../Configuration'

type GridRepeatProps = {
	columns: number
	rows: number
}

export const GridRepeat = observer((props: GridRepeatProps) => {
	const styles = useStyles()

	const context = useRepeatCellContext()
	const formBuilderContext = useFormBuilderContext()

	if (context === undefined) return <> </>

	const columnRange = [...Array(props.columns).keys()]
	const rowRange = [...Array(props.rows).keys()]

	return (
		<div className={styles.root}>
			{context.cellComponents.map((v, i) => (
				// TODO - this needs to be a new component if we keep it - for performance
				<div key={v[0].cell.id} className={styles.rootWithBorder}>
					{rowRange.map((_rowValue, rowIndex) => (
						<div key={rowIndex} className={styles.box}>
							{columnRange.map((_columnRange, columnIndex) => (
								<div key={columnIndex} className={styles.cell}>
									{v[1][rowIndex * props.columns + columnIndex]}
								</div>
							))}
						</div>
					))}
					<Box
						component={IconButton}
						position="absolute"
						top={0}
						right={0}
						onClick={() => context.removeNode(i)}
					>
						<Close color={'error'} fontSize="small" />
					</Box>
				</div>
			))}

			<Box
				component={Button}
				variant="contained"
				alignSelf={'center'}
				onClick={() => context.appendNode()}
			>
				Add Record
			</Box>
		</div>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	rootWithBorder: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
		borderRadius: theme.spacing(1),
		border: `solid 3px ${theme.palette.divider}`,
		position: 'relative',
	},
	root: {
		flex: 1,
		display: 'flex',
		gap: theme.spacing(1),
		flexDirection: 'column',
	},
	box: {
		display: 'flex',
		gap: theme.spacing(1),
		justifyContent: 'center',
	},
	cell: {
		display: 'flex',
		flex: '1',
		justifyContent: 'center',
	},
}))

export const GridRepeatElementTag = 'f849a8a5-2ba8-4150-bdc8-f66af79941e5'

export const GridRepeatDefinitionId = '55945120-d582-461d-815a-7bb0c9a58020'

const GridRepeatPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof GridRepeat>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.rows}
				onValueChanged={(v) =>
					onPropertiesChanged({
						...properties,
						rows: v,
					})
				}
				Editor={NumberPropertyConfigurator}
				label="Rows"
			/>
			<PropertySchematicConfigurator
				value={properties.columns}
				onValueChanged={(v) =>
					onPropertiesChanged({
						...properties,
						columns: v,
					})
				}
				Editor={NumberPropertyConfigurator}
				label="Columns"
			/>
		</>
	)
}

export const GridRepeatDefinition = specifyRepeatComponent(
	GridRepeatDefinitionId,
	GridRepeat,
	GridRepeatElementTag,
	'Repeat',
	{
		configurator: GridRepeatPropsEditor,
		defaultProperties: {
			columns: 2,
			rows: 2,
		},
	},
	(v) => v.rows * v.columns,
)
