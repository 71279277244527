import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isObservable, observable } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'

export enum ColumnDataType {
	Number = 0,
	String = 1,
	Boolean = 2,
}

type ColumnConfiguration = {
	type: ColumnDataType
	name: string
	displayName?: string
}

type DataProviderConfiguratorProps = {
	requiredColumns: ColumnConfiguration[]
}

type DataProviderConfiguratorValue = {
	dataProviderType: 'static' | 'dataProvider'
}

const DataProviderConfigurator = observer(
	(props: DataProviderConfiguratorProps) => {
		const { cellManager } = useFormBuilderContext()
		const inputContext = useInputCellContext()

		console.log(
			'detected re-render with context ',
			JSON.stringify(inputContext.value, null, 2),
		)
		console.log(`is value observable?: ${isObservable(inputContext.value)}`)

		const localStore = useLocalObservable(() => ({
			dialogOpen: false,
		}))

		const styles = useStyles()

		return (
			<div className={styles.root}>
				<Button
					style={{ margin: '5px' }}
					variant="contained"
					color="primary"
					onClick={(_v) => (localStore.dialogOpen = true)}
				>
					Configure Options
				</Button>
				<Dialog open={localStore.dialogOpen}>
					<DialogTitle>Configure Data Provider</DialogTitle>
					<DialogContent>
						<StaticDataProviderConfigurator
							columns={props.requiredColumns}
							value={
								(inputContext.value as StaticDataProviderResultObject[])
									.length === 0
									? [createRow(props.requiredColumns)]
									: (inputContext.value as StaticDataProviderResultObject[])
							}
							onChange={(newValue) => {
								const oldValue = inputContext.value
								inputContext.onChange(newValue)
								changeEventEmitter(
									cellManager.eventBus,
									{
										oldValue: oldValue,
										newValue: newValue,
									},
									{
										formHost: cellManager,
										elementTag: DataProviderConfiguratorElementTag,
										definitionId: inputContext.cellInstance.definitionId,
										instanceId: inputContext.cellInstance.id,
									},
								)
							}}
						/>
						<Button
							onClick={(v) => {
								const newValue = [
									...(inputContext.value as StaticDataProviderResultObject[]),
									createRow(props.requiredColumns),
								]
								const oldValue = inputContext.value
								inputContext.onChange(newValue)
								changeEventEmitter(
									cellManager.eventBus,
									{
										newValue: newValue,
										oldValue: oldValue,
									},
									{
										formHost: cellManager,
										elementTag: DataProviderConfiguratorElementTag,
										definitionId: inputContext.cellInstance.id,
										instanceId: inputContext.cellInstance.id,
									},
								)
							}}
						>
							Add Row
						</Button>
						<Button onClick={(v) => (localStore.dialogOpen = false)}>
							Close
						</Button>
					</DialogContent>
				</Dialog>
			</div>
		)
	},
)

type StaticDataProviderResultObject = {
	[key: string]: boolean | string | number
}

type StaticDataProviderConfiguratorProps = {
	columns: ColumnConfiguration[]
	value: StaticDataProviderResultObject[]
	onChange: (value: StaticDataProviderResultObject[]) => void
}

const StaticDataProviderConfigurator = observer(
	(props: StaticDataProviderConfiguratorProps) => {
		return (
			<Table>
				<TableHead>
					<TableRow>
						{props.columns.map((v, i) => (
							<TableCell key={i}>{v.displayName ?? v.name}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.value.map((v, i) => (
						<StaticDataProviderRow
							key={i}
							columns={props.columns}
							value={v}
							onChange={(newValue) => {
								props.value[i] = newValue
								props.onChange(props.value)
							}}
						/>
					))}
				</TableBody>
			</Table>
		)
	},
)

type StaticDataProviderRowProps = {
	columns: ColumnConfiguration[]
	value: StaticDataProviderResultObject
	onChange: (newValue: StaticDataProviderResultObject) => void
}

const StaticDataProviderRow = observer((props: StaticDataProviderRowProps) => {
	return (
		<TableRow>
			{props.columns.map((v, i) => {
				return (
					<TableCell key={v.name}>
						<SingleDataProviderCell
							column={v}
							value={props.value}
							onChange={(newValue) => {
								props.value[v.name] = newValue
								props.onChange(props.value)
							}}
						/>
					</TableCell>
				)
			})}
		</TableRow>
	)
})

type SingleDataProviderCellProps = {
	column: ColumnConfiguration
	value: StaticDataProviderResultObject
	onChange: (newValue: string | boolean | number) => void
}

const SingleDataProviderCell = observer(
	({ column, value, onChange }: SingleDataProviderCellProps) => {
		if (column.type === ColumnDataType.String)
			return (
				<TextField
					value={value[column.name]}
					onChange={(evt) => {
						onChange(evt.currentTarget.value)
					}}
				/>
			)
		if (column.type === ColumnDataType.Number)
			return (
				<TextField
					type="number"
					value={value[column.name]}
					onChange={(evt) => {
						onChange(evt.currentTarget.value)
					}}
				/>
			)
		if (column.type === ColumnDataType.Boolean) {
			return (
				<Checkbox
					value={value[column.name]}
					onChange={(evt) => {
						onChange(evt.currentTarget.value)
					}}
				/>
			)
		}

		throw Error(`could not map type ${column.type} to component`)
	},
)

function createRow(columns: ColumnConfiguration[]) {
	const obj: StaticDataProviderResultObject = {}
	for (const v of columns) {
		if (v.type === ColumnDataType.Boolean) obj[v.name] = false
		if (v.type === ColumnDataType.Number) obj[v.name] = 0
		if (v.type === ColumnDataType.String) obj[v.name] = ''
	}

	return observable(obj)
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flex: 'auto',
	},
}))

export const DataProviderConfiguratorElementTag =
	'1e4efe9d-fdf6-4c4c-88d9-f744081fdfb2'

const DataProviderConfiguratorId = '5ebfde1b-d3ff-49d9-abdd-2fb98bfb1c73'

export const DataProviderConfiguratorDefinition = specifyInternalComponent(
	DataProviderConfiguratorId,
	DataProviderConfigurator,
	DataProviderConfiguratorElementTag,
	FieldType.ReferenceType,
	{
		requiredColumns: [],
	},
	{},
)
