import { DBSchema, IDBPDatabase, openDB } from 'idb'
import { BaseWorkItemState, WorkItemResponse, WriteWorkItemModel } from '../../api/clients/workItems/DTOs'
import { DetailForm, FormPackageInfo } from '../../api/DTOtemp'
import { SessionService } from '../session'
import { IndexDbFormPackage, IntendedUsage } from './FormPackageStorage'

interface FormsInMotionDatabase extends DBSchema {

	// misc...
	blobStorage: {
		key: string,
		value: { 
			relationalKey: string,
			data: Blob | File
		},
		indexes: {
			'relational-key': string
		}
	},
	apiResponseCache: {
		key: string,
		value: CacheEntry<unknown>
	},

	// the form package cache
	storedPackageCache: {
		key: string,
		value: IndexDbFormPackage,
		indexes: {
			'intended-usage': IntendedUsage
		}
	},
	workItemCache: {
		key: string,
		value: WorkItemResponse<BaseWorkItemState> | WriteWorkItemModel<BaseWorkItemState>
	}

	// Form models
	formPackageVersions: {
		key: number,
		value: FormPackageInfo
	},
	formPackageFormsAssociations: {
		key: string,
		value: {
			packageVersionId: number,
			formId: number
		},
		indexes: {
			'by-package-version-id': number
		}
	},
	formPackageForms: {
		key: number
		value: DetailForm
	}
}

const initializeDatabase = async (databaseName: string) => {
	const db = await openDB<FormsInMotionDatabase>(databaseName, 1, {
		upgrade(database, oldVersion, newVersion, transaction) {

			console.log('old version: ', oldVersion)

			if (oldVersion < 1) {

				database.createObjectStore('apiResponseCache')

				const packageCache = database.createObjectStore('storedPackageCache')
				packageCache.createIndex('intended-usage', 'intendedUsage', {
					unique: false,
				})

				const blobStorage = database.createObjectStore('blobStorage')
				blobStorage.createIndex('relational-key', 'relationalKey', {
					unique: false
				})

				// form package versions
				database.createObjectStore('formPackageVersions')

				// form package form associations
				const formPackageFormsAssociations = database.createObjectStore('formPackageFormsAssociations')
				formPackageFormsAssociations.createIndex('by-package-version-id', 'packageVersionId', {
					unique: false,
				})

				// form package forms
				database.createObjectStore('formPackageForms')
			}
			if (oldVersion < 2) {
				database.createObjectStore('workItemCache');
			}
		},
	})

	return db
}

export const initializeGlobalDatabase = () => {
	return initializeDatabase('fim')
}

export const initializeUserDatabase = (userId: number) => {
	return initializeDatabase(`fim-${userId}`)
}

type CacheEntry<T = unknown> = {
	value: T
	storeDate: Date
}

export const withScopedDatabaseCaching = async<T>(
	key: string,
	executor: () => Promise<T>
): Promise<T | undefined> => {
	let db: IDBPDatabase<FormsInMotionDatabase> | null = null
	try {
		db = await retrieveScopedDatabase();
	} catch (e) {
		console.warn('scoped database caching failed, running executor\n' + e)
		return await executor()
	}
	return await withDatabaseDisconnectedApiCaching(db, key, executor)
}

export const withGlobalDatabaseCaching = async <T>(
	key: string,
	executor: () => Promise<T>,
): Promise<T | undefined> => {
	let db: IDBPDatabase<FormsInMotionDatabase> | null = null
	try {
		db = await retrieveGlobalDatabase();
	} catch (e) {
		console.warn('global database caching failed, running executor\n' + e)
		return await executor()
	}
	return await withDatabaseDisconnectedApiCaching(db, key, executor)
}

export const withUserDatabaseCaching = async <T>(
	userId: string,
	key: string,
	executor: () => Promise<T>,
): Promise<T | undefined> => {
	const db = await openDB<FormsInMotionDatabase>(`fim-${userId}`)
	return await withDatabaseDisconnectedApiCaching(db, key, executor)
}

export const withDatabaseDisconnectedApiCaching = async <T>(
	database: IDBPDatabase<FormsInMotionDatabase>,
	key: string,
	executor: () => Promise<T>,
): Promise<T | undefined> => {
	console.log('online status: ' + navigator.onLine)
	if (navigator.onLine) {
		const result = await executor()

		const transaction = database.transaction('apiResponseCache', 'readwrite')
		const store = transaction.objectStore('apiResponseCache')

		const putResult = await store.put(
			{ value: result, storeDate: new Date() },
			key,
		)

		transaction.commit()
		await transaction.done

		return result
	} else {
		const store = database
			.transaction('apiResponseCache', 'readonly')
			.objectStore('apiResponseCache')

		const result: CacheEntry<T> | undefined = await store.get(key) as CacheEntry<T> | undefined
		return result?.value

	}
}

export const retrieveGlobalDatabase = async () => {
	return await initializeGlobalDatabase()
}

export const retrieveUserDatabase = async () => {
	const sessionService = new SessionService()
	if (sessionService.authToken.id === undefined)
		throw new Error(
			'session service indicated logged in but unable to get auth token id',
		)
	return await initializeUserDatabase(sessionService.authToken.id)
}

export const retrieveScopedDatabase = async () => {
	const sessionService = new SessionService()

	if (!sessionService.isLoggedIn) return await initializeGlobalDatabase()
	if (sessionService.authToken.id === undefined)
		throw new Error(
			'session service indicated logged in but unable to get auth token id',
		)
	return await initializeUserDatabase(sessionService.authToken.id)
}