import { Redirect, Route, RouteProps } from 'react-router-dom'
import { SessionService } from '../../services/session/SessionService'

export default function OrganizationRoute({ children, ...rest }: RouteProps) {
	return (
		<Route
			{...rest}
			render={({ location }) => (
				<RouteRenderComponent {...rest} location={location}>
					{children}
				</RouteRenderComponent>
			)}
		/>
	)
}

type RouteRenderComponent = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	location: any
} & RouteProps

const RouteRenderComponent = ({
	children,
	location,
	...rest
}: RouteRenderComponent) => {
	const subdomain = window.location.host.match(/^[a-z0-9-]+/)

	const sessionService = new SessionService()

	if (subdomain === null)
		throw new Error(`route ${rest.path} does not have an organization`)

	console.log('got organization subdomain: ', subdomain[0])

	if (!sessionService.isLoggedIn) {
		return (
			<Redirect
				to={{
					pathname: '/_login',
					state: { from: location },
				}}
			/>
		)
	}

	return <>{children}</>
}
