import ArrowForward from '@mui/icons-material/ArrowForward'
import People from '@mui/icons-material/People'
import Person from '@mui/icons-material/Person'
import {
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
	AssignmentType,
	WorkItemPermissions,
	WorkItemQueueSnapshot,
} from '../../../../api/clients/workItems/DTOs'
import WorkItemQueueClient from '../../../../api/clients/workItems/WorkItemQueueClient'
import WorkItemsClient from '../../../../api/clients/workItems/WorkItemsClient'
import UserChip from '../../../../components/chips/UserChip'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { SessionService } from '../../../../services/session/SessionService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { updateBreadcrumbs } from '../Breadcrumbs'

type WorkItemQueuesTableProps = {
	queues: WorkItemQueueSnapshot[]
	onGoToQueue: (workItemId: number) => void
}

export const WorkItemsPage: React.FC = () => {
	const history = useHistory()
	const sessionService = new SessionService()

	updateBreadcrumbs(`/_administration/_work-items`, 'Work Items')

	const workItemsClient = new WorkItemsClient()
	const workItemQueueClient = new WorkItemQueueClient()

	const [queueSnapshots, setQueueSnapshots] =
		useState<WorkItemQueueSnapshot[]>()

	const handleGoToQueue = async (id: number): Promise<void> => {
		const nextId = (await workItemQueueClient.getNextInQueue(id)).data
		await workItemsClient.getWorkItem(nextId, WorkItemPermissions.Write) // set up the work item with lock password
		history.push(`/_work-items/${nextId}`)
	}

	const addQueues = async () => {
		const userId = sessionService.authToken.id ?? 0

		const snapshotsResponse = await workItemQueueClient.getQueueSnapshots(
			userId,
		)

		const snapshots = snapshotsResponse.data
		setQueueSnapshots(snapshots)
	}

	useEffect(() => {
		addQueues()
	}, [])

	return (
		<AdministrationPageContainer title="Work Items">
			{queueSnapshots === undefined || queueSnapshots?.length < 1 ? (
				<FullscreenSpinner />
			) : (
				<WorkItemQueuesTable
					queues={queueSnapshots}
					onGoToQueue={handleGoToQueue}
				/>
			)}
		</AdministrationPageContainer>
	)
}

export const WorkItemQueuesTable: React.FC<WorkItemQueuesTableProps> = observer(
	(props: WorkItemQueuesTableProps) => {
		const styles = useStyles()

		return (
			<>
				{true && (
					<Fade in>
						<TableContainer className={styles.tableRoot} component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell></TableCell>
										<TableCell>Assigned To</TableCell>
										<TableCell>Number of Items</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{props.queues.map((queue, i) => {
										return (
											<TableRow key={i}>
												<TableCell>
													{queue.assignmentType === AssignmentType.Group && (
														<People />
													)}
													{queue.assignmentType === AssignmentType.User && (
														<Person />
													)}
												</TableCell>
												<TableCell>
													<UserChip
														assignmentType={queue.assignmentType}
														id={queue.assignedTo}
													/>
												</TableCell>
												<TableCell>{queue.itemCount}</TableCell>
												<TableCell align="right">
													<IconButton
														onClick={() => props.onGoToQueue(queue.assignedTo)}
														disabled={queue.itemCount < 1}
													>
														<ArrowForward fontSize="small" />
													</IconButton>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Fade>
				)}
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
