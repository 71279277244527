import { Clear } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useMemo } from 'react'
import { LinkedField } from '../../../../../../../../api/DTOtemp'
import { CellDefinition } from '../../../../../../../../modules/FormHost/Types/CellDefinition'
import { Field } from './FieldMappingsPage'

type LinkFieldsDialogProps = {
	open: boolean
	fields: Field[]
	onClose: () => void
	onAddFields: (fields: LinkedField[]) => void
}
export const LinkFieldsDialog = observer((props: LinkFieldsDialogProps) => {
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		selectedFormName: '',
		selectedField: undefined as CellDefinition | undefined,
		cellDefinitionIds: [] as string[],
		fieldsToAdd: [] as Field[],
	}))

	/* we only want to show unique form names in this list (not a bunch of the 
			same if there are multiple forms from the same field) */
	const formNames = useMemo(() => {
		return props.fields
			.filter(
				(v, i) =>
					props.fields.findIndex((x) => x.formName === v.formName) === i,
			)
			.map((v) => v.formName)
	}, [props.fields])

	const handleFormChange = action((event: SelectChangeEvent) => {
		localStore.selectedFormName = event.target.value
	})

	const handleFieldChange = action((event: SelectChangeEvent) => {
		localStore.selectedField = props.fields.find(
			(v) =>
				v.formName === localStore.selectedFormName &&
				v.cellDefinition.id === event.target.value,
		)?.cellDefinition
		if (localStore.selectedFormName === '')
			throw new Error('cannot add a field without a selected form')

		if (localStore.selectedField === undefined)
			throw new Error('selected field is undefined')

		const field = props.fields.find(
			(v) =>
				v.formName === localStore.selectedFormName &&
				v.cellDefinition === localStore.selectedField,
		)
		if (field === undefined)
			throw new Error('cannot add a field that does not exist in field list')

		localStore.fieldsToAdd.push(field)

		localStore.selectedField = undefined
	})

	const handleRemoveField = action((field: Field) => {
		const indexToRemove = localStore.fieldsToAdd.indexOf(field)
		if (indexToRemove < 0) return

		localStore.fieldsToAdd.splice(indexToRemove, 1)
	})

	const handleLinkFields = action((fields: LinkedField[]) => {
		props.onAddFields(fields)
		localStore.fieldsToAdd = []
		localStore.selectedFormName = ''
	})

	// only display value fields that haven't been linked or added to the list of fields to link
	const availableFields = useMemo(() => {
		return props.fields.filter(
			(v) =>
				v.formName === localStore.selectedFormName &&
				!localStore.fieldsToAdd
					.filter((v) => v.formName === localStore.selectedFormName)
					.map((v) => v.cellDefinition.id)
					.includes(v.cellDefinition.id) &&
				v.cellDefinition.type === 'value' &&
				!v.linked,
		)
	}, [props.fields, localStore.selectedFormName, localStore.fieldsToAdd.length])

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			fullWidth
			maxWidth="xs"
			classes={{ paper: styles.rightSideDialog }}
		>
			<DialogTitle>Link Fields</DialogTitle>
			<DialogContent className={styles.addFieldsDialogContent}>
				<div
					className={clsx(styles.selectedField, styles.addFieldsDialogContent)}
				>
					<div>
						<FormControl fullWidth className={styles.select}>
							<InputLabel>Form</InputLabel>
							<Select
								value={localStore.selectedFormName}
								label="Form"
								onChange={handleFormChange}
							>
								{formNames.map((name, i) => (
									<MenuItem key={i} value={name}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div>
						<FormControl fullWidth className={styles.select}>
							<InputLabel>Field</InputLabel>
							<Select
								value={localStore.selectedField?.id ?? ''}
								label="Field"
								onChange={handleFieldChange}
								readOnly={localStore.selectedFormName === ''}
							>
								{availableFields.length > 0 ? (
									availableFields.map((field) => (
										<MenuItem
											key={field.cellDefinition.id}
											value={field.cellDefinition.id}
										>
											{field.displayName}
										</MenuItem>
									))
								) : (
									<MenuItem disabled>No fields available</MenuItem>
								)}
							</Select>
						</FormControl>
					</div>
				</div>
				{localStore.fieldsToAdd.length > 0 &&
					localStore.fieldsToAdd.map((field, i) => (
						<div>
							<div
								key={i}
								className={clsx(styles.selectedField, styles.fieldInfo)}
							>
								<div className={styles.addFieldsDialogContent}>
									<div>{field.formName}</div>
									<div>{field.displayName}</div>
								</div>
								<IconButton
									onClick={() => handleRemoveField(field)}
									className={styles.iconButton}
								>
									<Clear fontSize="small" />
								</IconButton>
							</div>
							<Divider />
						</div>
					))}
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={() => {
						handleLinkFields(
							localStore.fieldsToAdd.map(
								(v) =>
									({
										formId: v.formId,
										cellDefinitionId: v.cellDefinition.id,
									} as LinkedField),
							),
						)
						props.onClose()
					}}
					disabled={localStore.fieldsToAdd.length === 0}
				>
					Link Fields
				</Button>
			</DialogActions>
		</Dialog>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	select: {
		margin: theme.spacing(1, 0),
	},

	selectedField: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	fieldInfo: {
		margin: theme.spacing(1, 0),
		fontSize: 'small',
	},

	addFieldsDialogContent: {
		display: 'flex',
		flexDirection: 'column',
	},

	iconButton: {
		placeSelf: 'center',
		size: 'small',
	},

	rightSideDialog: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
	},
}))
