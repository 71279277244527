import { EventBus } from "./EventBus"
import { EventBusEvent, EventBusEventConsumer, EventBusEventSource } from "./EventTypes"

type EventInvocation<TEvent extends EventBusEvent> = (eventBus: EventBus,
	properties: TEvent['props'],
	source: TEvent['eventSource']) => Promise<void>

type EventConsumption<TEventProps, TEventSource extends EventBusEventSource> = (eventBus: EventBus,
	shouldActivate: EventBusEventConsumer<TEventProps, TEventSource>['shouldActivateAsync'],
	action: EventBusEventConsumer<TEventProps, TEventSource>['actionAsync']
) => string

export function createEvent<TEventProps = unknown, TEventSource extends EventBusEventSource = EventBusEventSource>(
	eventId: string,
	displayName: string | undefined = undefined):
	[
		EventInvocation<EventBusEvent<TEventProps, TEventSource>>,
		EventConsumption<TEventProps, TEventSource>
	]
{
	const eventEmitter = function createEmitter(eventBus: EventBus,
		properties: TEventProps,
		source: TEventSource) {
		
		const event: EventBusEvent<TEventProps, TEventSource> = {
			eventId: eventId,
			displayName: displayName,
			props: properties,
			eventSource: source
		}

		return eventBus.emit(event)
	}

	const eventConsumer = function createConsumer(eventBus: EventBus,
		shouldActivate: EventBusEventConsumer<TEventProps, TEventSource>['shouldActivateAsync'],
		action: EventBusEventConsumer<TEventProps, TEventSource>['actionAsync']
	)
	{
		const consumerId = eventBus.registerConsumer<TEventProps, TEventSource>({
			eventId: eventId,
			shouldActivateAsync: (localEventId, props, originalSource, currentEventSource) => {
				return shouldActivate(localEventId, props, originalSource, currentEventSource)
			},
			actionAsync: (eventId, props, originalSource, currentEventSource) => {
				return action(eventId, props, originalSource, currentEventSource)
			}
		})

		return consumerId
	}

	return [eventEmitter, eventConsumer]
}