import { Chip, ChipProps } from '@mui/material'
import { ActivityInstanceStatus } from '../../api/clients/workflows/DTOs'

export const statuses: Record<
	ActivityInstanceStatus,
	{ color: string; label: string }
> = {
	[ActivityInstanceStatus.Completed]: {
		color: 'success',
		label: 'Complete',
	},
	[ActivityInstanceStatus.Halted]: {
		color: 'primary',
		label: 'Halted',
	},
	[ActivityInstanceStatus.Cancelled]: {
		color: 'info',
		label: 'Cancelled',
	},
	[ActivityInstanceStatus.NotRun]: {
		color: 'default',
		label: 'Not Run',
	},
	[ActivityInstanceStatus.Faulted]: {
		color: 'error',
		label: 'Faulted',
	},
}

type ActivityStatusChipProps = {
	status: ActivityInstanceStatus
} & Omit<ChipProps, 'color' | 'label'>

export const ActivityStatusChip = ({
	status,
	...rest
}: ActivityStatusChipProps) => {
	const color = statuses[status].color as ChipProps['color']

	return (
		<Chip
			variant="outlined"
			color={color}
			label={statuses[status].label}
			{...rest}
		/>
	)
}
