import { createContext, PropsWithChildren, useContext } from 'react'

export type Signature = {
	fullName: string
	initials: string
	email: string
	signatureData: string
	id: number
}

export type SignatureContextProps = {
	signatures: Signature[]
}

const SignatureContext = createContext<SignatureContextProps | undefined>(
	undefined,
)

export const SignatureContextProvider = (
	props: PropsWithChildren<SignatureContextProps>,
) => {
	return (
		<SignatureContext.Provider
			value={{
				signatures: props.signatures,
			}}
		>
			{props.children}
		</SignatureContext.Provider>
	)
}

export const useSignatureContext = () => {
	const context = useContext(SignatureContext)
	if (context === undefined)
		throw new Error('a value for the signature context was not provided')

	return context
}
