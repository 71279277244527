import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import {
	BaseWorkItemState,
	WriteWorkItemModel,
} from '../../api/clients/workItems/DTOs'
import WorkItemQueueClient from '../../api/clients/workItems/WorkItemQueueClient'
import WorkItemsClient from '../../api/clients/workItems/WorkItemsClient'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { SessionService } from '../../services/session/SessionService'

export type WorkItemResultValues = {
	originalValue: unknown
	newValue: unknown
}

export const WorkItemReviewPage: React.FC = () => {
	const routeParams = useParams<{
		workItemId: string
	}>()

	const workItemId = parseInt(routeParams.workItemId)
	if (isNaN(workItemId))
		throw new Error(`could not parse work item id ${routeParams.workItemId}`)

	const sessionService = new SessionService()

	const [workItem, setWorkItem] =
		useState<WriteWorkItemModel<BaseWorkItemState>>()
	const [documentReviewed, setDocumentReviewed] = useState<boolean>(false)
	const [nextWorkItemId, setNextWorkItemId] = useState<number>()
	const [queueComplete, setQueueComplete] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)

	const workItemClient = new WorkItemsClient()
	const workItemQueueClient = new WorkItemQueueClient()

	const getWorkItem = async () => {
		const { data } = await workItemClient.getWorkItem(workItemId)

		setWorkItem(data as WriteWorkItemModel<BaseWorkItemState>)
	}

	const onDocumentReviewed = async (
		comparedValues: Record<string, WorkItemResultValues>,
		obj: unknown,
	) => {
		if (workItem === undefined) return
		const logMessage = JSON.stringify(comparedValues)

		setIsSubmitting(true)

		await workItemClient
			.completeWorkItem(workItem.id, {
				lockPassword: workItem.lockPassword,
				outcome: 'Completed',
				results: obj,
				userId: sessionService.authToken.id,
				logMessage: logMessage,
			})
			.then(async () => {
				await workItemQueueClient
					.getNextInQueue(workItem.assignmentPointer)
					.then((response) => {
						setNextWorkItemId(response.data)
					})
					.catch(() => {
						setQueueComplete(true)
						setNextWorkItemId(undefined)
					})
				setDocumentReviewed(true)
			})
			.finally(() => setIsSubmitting(false))
	}

	useEffect(() => {
		getWorkItem()
		setDocumentReviewed(false)
	}, [routeParams.workItemId])

	return (
		<>
			{documentReviewed ? (
				queueComplete ? (
					<Redirect to={`/_administration/_work-items`} />
				) : (
					<Redirect to={`/_work-items/${nextWorkItemId}`} />
				)
			) : (
				<FullscreenSpinner />
			)}
		</>
	)
}
