import { createEvent } from "../../../FormBuilderCore/eventBus/EventSpecification"
import { EventBusEventSource } from "../../../FormBuilderCore/eventBus/EventTypes"

export type EventBusChangeEventProps = {
	oldValue: unknown
	newValue: unknown
}

export type EventBusChangeEventSource = EventBusEventSource

export const changeEventId = "ec1a2ffa-97af-4330-a2fe-6e9e73fae26b"

export const [changeEventEmitter, changeEventConsumer] =
	createEvent<
		EventBusChangeEventProps,
		EventBusChangeEventSource
	>
	(changeEventId, "Value Change")