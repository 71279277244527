import { green, indigo, red } from "@mui/material/colors"
import { Syntax } from "esprima"
import { AssignmentExpression, BinaryExpression, BlockStatement, ExpressionStatement, Identifier, IfStatement, Literal, LogicalExpression, MemberExpression, Node, Program } from "estree"
import { NIL } from "uuid"
import { PartiallyConstructedNode, WithFormsInfo } from "../Types"

export enum ComponentCategory {
    Events = "Events",
    Logic = "Logic",
    Variables = "Variables",
}

export const CategoryColorMap: { [key in ComponentCategory]: string } = {
    Logic: red[400],
    Variables: indigo[400],
    Events: green[600]
}

// all the nodes we can drag over to add to the event editor
export const NodeComponents: {
    defaultNode: PartiallyConstructedNode<Node>
    displayName: string
    category: ComponentCategory
}[] = [
        {
            displayName: 'Assignment Expression',
            // from what I have found assignment expressions will ALWAYS be wrapped in a statement,
            // we can't have an expression standing alone
            defaultNode: {
                type: Syntax.ExpressionStatement,
                expression: {
                    type: Syntax.AssignmentExpression,
                    operator: '=',
                } as AssignmentExpression
            } as ExpressionStatement,
            category: ComponentCategory.Variables,
        },
        {
            displayName: 'Binary Expression',
            defaultNode: {
                type: Syntax.BinaryExpression,
                operator: '=='
            } as BinaryExpression,
            category: ComponentCategory.Logic,
        },
        {
            displayName: 'If Statement',
            defaultNode: {
                type: Syntax.IfStatement,
                consequent: {
                    type: Syntax.BlockStatement,
                    body: [],
                } as BlockStatement,
            } as IfStatement,
            category: ComponentCategory.Logic
        },
        {
            displayName: 'String Literal',
            defaultNode: {
                type: Syntax.Literal,
                value: ''
            } as Literal,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'Number Literal',
            defaultNode: {
                type: Syntax.Literal,
                value: 0
            } as Literal,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'Boolean Literal',
            defaultNode: {
                type: Syntax.Literal,
                value: true
            } as Literal,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'Logical Expression',
            defaultNode: {
                type: Syntax.LogicalExpression,
                operator: '&&'
            } as LogicalExpression,
            category: ComponentCategory.Logic
        },
        {
            displayName: 'Member Expression',
            defaultNode: {
                type: Syntax.MemberExpression,
                computed: true,
                optional: false
            } as MemberExpression,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'String Length',
            defaultNode: {
                type: Syntax.MemberExpression,
                formsInfo: {
                    type: 'String-Length',
                },
                computed: false,
                optional: false,
                property: {
                    type: "Identifier",
                    name: "length"
                },
            } as WithFormsInfo<MemberExpression>,
            category: ComponentCategory.Variables,
        },
        {
            displayName: 'Form Event',
            defaultNode: {
                type: Syntax.Program,
                formsInfo: {
                    type: 'FormEvent',
                    formId: 0,
                    event: "Load"
                },
                sourceType: 'script',
                body: [],
            } as WithFormsInfo<Program>,
            category: ComponentCategory.Events,
        },
        {
            displayName: 'Field Event',
            defaultNode: {
                type: Syntax.Program,
                formsInfo: {
                    type: 'FieldEvent',
                    formId: 0,
                    definitionId: '',
                    event: "Change"
                },
                sourceType: 'script',
                body: [],
            } as WithFormsInfo<Program>,
            category: ComponentCategory.Events
        },
        {
            displayName: 'Form Field',
            defaultNode: {
                type: "MemberExpression",
                formsInfo: {
                    type: 'FormFieldAccess',
                    formId: 0,
                    definitionId: NIL,
                },
                computed: false,
                optional: false,
                object: {
                    type: "MemberExpression",
                    computed: false,
                    optional: false,
                    object: {
                        type: "MemberExpression",
                        computed: false,
                        optional: false,
                        object: {
                            type: "Identifier",
                            name: "advancedEventApi",
                        },
                        property: {
                            type: "Identifier",
                            name: "formProxy",
                        },
                    },
                    property: {
                        type: "Identifier",
                        name: "",
                        formsInfo: {
                            type: 'FormAccess',
                            formId: 0,
                        }
                    } as WithFormsInfo<Identifier>
                },
                property: {
                    type: "Identifier",
                    name: "",
                    formsInfo: {
                        type: 'FieldAccess',
                        accessor: '',
                    }
                } as WithFormsInfo<Identifier>,
            } as WithFormsInfo<MemberExpression>,

            category: ComponentCategory.Variables
        },
        {
            displayName: 'User Property',
            defaultNode: {
                type: Syntax.MemberExpression,
                formsInfo: {
                    type: 'UserPropertyAccess',
                    userPropertyId: 0
                },
                computed: false,
                optional: false,
                object: {
                    type: Syntax.MemberExpression,
                    computed: false,
                    optional: false,
                    object: {
                        type: Syntax.Identifier,
                        name: "advancedEventApi"
                    },
                    property: {
                        type: Syntax.Identifier,
                        name: "userPropertiesProxy"
                    }
                },
                property: {
                    type: Syntax.Identifier,
                    name: "",
                },
            } as WithFormsInfo<MemberExpression>,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'Organization Property',
            defaultNode: {
                type: Syntax.MemberExpression,
                formsInfo: {
                    type: 'OrganizationPropertyAccess',
                    organizationPropertyId: 0
                },
                computed: false,
                optional: false,
                object: {
                    type: Syntax.MemberExpression,
                    computed: false,
                    optional: false,
                    object: {
                        type: Syntax.Identifier,
                        name: "advancedEventApi"
                    },
                    property: {
                        type: Syntax.Identifier,
                        name: "organizationPropertiesProxy"
                    }
                },
                property: {
                    type: Syntax.Identifier,
                    name: "",
                },
            } as WithFormsInfo<MemberExpression>,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'User Info',
            defaultNode: {
                type: Syntax.MemberExpression,
                formsInfo: {
                    type: 'UserInfoAccess',
                    property: ''
                },
                computed: false,
                optional: false,
                object: {
                    type: Syntax.MemberExpression,
                    computed: false,
                    optional: false,
                    object: {
                        type: Syntax.Identifier,
                        name: "advancedEventApi"
                    },
                    property: {
                        type: Syntax.Identifier,
                        name: "userInfoProxy"
                    }
                },
                property: {
                    type: Syntax.Identifier,
                    name: "",
                },
            } as WithFormsInfo<MemberExpression>,
            category: ComponentCategory.Variables
        },
        {
            displayName: 'Query String',
            defaultNode: {
                type: Syntax.MemberExpression,
                formsInfo: {
                    type: 'QueryStringAccess',
                },
                computed: false,
                optional: false,
                object: {
                    type: Syntax.MemberExpression,
                    computed: false,
                    optional: false,
                    object: {
                        type: Syntax.Identifier,
                        name: "advancedEventApi"
                    },
                    property: {
                        type: Syntax.Identifier,
                        name: "queryStringProxy"
                    }
                },
                property: {
                    type: Syntax.Identifier,
                    name: "",
                },
            } as WithFormsInfo<MemberExpression>,
            category: ComponentCategory.Variables
        }
    ] 