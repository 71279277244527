import { NavigateNext } from '@mui/icons-material'
import { Breadcrumbs, Link, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { createContext, PropsWithChildren, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { UseAdministrationBreadcrumbsContext } from './AdministrationBreadcrumbsContext'

type AdministrationPageContextType = {
	actions?: ReactNode
}

const administrationPageContext = createContext<
	AdministrationPageContextType | undefined
>({})

export const AdministrationPageContextProvider = (
	props: PropsWithChildren<Record<string, unknown>>,
) => {
	return (
		<administrationPageContext.Provider value={{}}>
			{props.children}
		</administrationPageContext.Provider>
	)
}

type AdministrationPageContainerProps = {
	title: ReactNode
	actions?: ReactNode
} & PropsWithChildren<Record<string, unknown>>

type AdministrationPageProps = PropsWithChildren<Record<string, unknown>>

export const AdministrationPage = (props: AdministrationPageProps) => {
	const styles = useStyles()

	return <div className={styles.root}>{props.children}</div>
}

type AdministrationPageHeaderProps = PropsWithChildren<Record<string, unknown>>

export const AdministrationPageHeader = (
	props: AdministrationPageHeaderProps,
) => {
	const styles = useStyles()

	return <div className={styles.headerSection}> {props.children} </div>
}

type AdministrationPageHeaderTitleProps = PropsWithChildren<
	Record<string, unknown>
>

export const AdministrationPageTitle = (
	props: AdministrationPageHeaderTitleProps,
) => {
	const styles = useStyles()

	return (
		<div className={styles.headerLeft}>
			<Typography
				className={styles.title}
				variant="h5"
				component="h4"
				color="inherit"
			>
				{props.children}
			</Typography>
		</div>
	)
}

export const AdministrationPageBreadcrumbs = () => {
	const breadcrumbContext = UseAdministrationBreadcrumbsContext()
	const breadcrumbs = breadcrumbContext.breadcrumbs

	return (
		<Breadcrumbs separator={<NavigateNext />}>
			{breadcrumbs.map((breadcrumb, i) => {
				return (
					<Link
						variant="subtitle2"
						color="textSecondary"
						to={breadcrumb.link}
						key={i}
						component={RouterLink}
					>
						{breadcrumb.pageName}
					</Link>
				)
			})}
		</Breadcrumbs>
	)
}

export type AdministrationPageHeaderActionsProps = PropsWithChildren<
	Record<string, unknown>
>

export const AdministrationPageActions = (
	props: AdministrationPageHeaderActionsProps,
) => {
	const styles = useStyles()

	return <div className={styles.headerRight}>{props.children}</div>
}

type AdministrationPageContentProps = PropsWithChildren<Record<string, unknown>>

export const AdministrationPageContent = (
	props: AdministrationPageContentProps,
) => {
	const styles = useStyles()
	return <div className={styles.contentSection}>{props.children}</div>
}

const AdministrationPageContainer = (
	props: AdministrationPageContainerProps,
) => {
	return (
		<AdministrationPage>
			<AdministrationPageHeader>
				<AdministrationPageTitle>
					{props.title}
					<AdministrationPageBreadcrumbs />
				</AdministrationPageTitle>
				<AdministrationPageActions>{props.actions}</AdministrationPageActions>
			</AdministrationPageHeader>
			<AdministrationPageContent>{props.children}</AdministrationPageContent>
		</AdministrationPage>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		paddingTop: theme.spacing(3),

		width: percent(100),
		height: percent(100),
	},

	limitedWidthRoot: {
		[theme.breakpoints.up('lg')]: {
			maxWidth: px(1280),
		},
	},

	headerSection: {
		display: 'flex',

		width: percent(100),

		marginBottom: theme.spacing(2),
	},

	headerLeft: {
		display: 'flex',
		flexDirection: 'column',
	},

	headerRight: {
		display: 'flex',

		marginLeft: 'auto',
	},

	title: {
		fontWeight: 500,
	},

	contentSection: {
		display: 'flex',
		flex: '1',

		width: percent(100),
		height: percent(100),
	},
}))

export default AdministrationPageContainer
