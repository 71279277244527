import { observable } from 'mobx'
import React, { useCallback, useEffect, useState } from 'react'
import { FormPackageClient } from '../../../../../../api/clients/identity'
import { FullscreenSpinner } from '../../../../../../components/feedback/circular'
import {
	FormPackagePageContext,
	FormPackagePageContextProvider,
} from './FormPackagePageContext'
import { AdvancedEventsAdministrationPage } from './Sections/AdvancedEventsSection/AdvancedEventsAdministrationPage'
import { AttachmentTypesAdministrationPage } from './Sections/AttachmentTypesSection/AttachmentTypesAdministrationPage'
import { EventsAdministrationPage } from './Sections/EventsSection/EventsAdministrationPage'
import { FormsAdministrationPage } from './Sections/FormsSection/FormsAdministrationPage'
import { LinkedGroupsAdministrationPage } from './Sections/LinkedFieldsSection/FieldMappingSection'
import WorkflowsSection from './Sections/LinkedWorkflowsSection'
import { PackageVersionsAdministrationPage } from './Sections/PackageVersionsAdministrationPage'
import { SecurityAdministrationPage } from './Sections/SecuritySection/SecurityAdministrationPage'
import { SettingsAdministrationPage } from './Sections/SettingsSection/SettingsAdministrationPage'
import { FormPackageValidationPage } from './Sections/ValidationSection/FormPackageValidationPage'

type FormPackagePage = {
	packageId: number
}

export type FormPackageAdministrationPageProps = {
	tabLabels: string[]
	onTabChanged: (event: React.SyntheticEvent, value: number) => void
} & FormPackagePage

const FormPackagePage = (props: FormPackagePage) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const [packageData, setPackageData] = useState<FormPackagePageContext>()
	const [upperPackageNames, setUpperPackageNames] = useState<string[]>([])

	useEffect(() => {
		const formPackageApi = new FormPackageClient()
		const apiRequest = formPackageApi.GetFormPackage(props.packageId)

		apiRequest.then((v) => {
			const internalPackageData: FormPackagePageContext = packageData ?? {
				formPackage: v.data,
			}

			setPackageData(observable(internalPackageData))
		})

		formPackageApi.GetFormPackages().then((v) => {
			setUpperPackageNames(v.data.map((v) => v.name.toUpperCase()))
		})
	}, [props.packageId])

	const tabLabels = [
		'Settings',
		'Forms',
		'Events',
		'Linked Groups',
		'Security',
		'Workflows',
		'Validation',
		'Attachment Types',
		'Advanced Events',
		'Versions',
	]

	const tabSelector = (packageData: FormPackagePageContext) => {
		switch (selectedIndex) {
			case 0:
				return (
					<SettingsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
						upperPackageNames={upperPackageNames}
					/>
				)
			case 1:
				return (
					<FormsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
						packageVersionNumber={packageData.formPackage.versionNumber ?? 0}
					/>
				)
			case 2:
				return (
					<EventsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 3:
				return (
					<LinkedGroupsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 4:
				return (
					<SecurityAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 5:
				return (
					<WorkflowsSection
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 6:
				return (
					<FormPackageValidationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
						versionNumber={packageData.formPackage.versionNumber ?? 0}
					/>
				)
			case 7:
				return (
					<AttachmentTypesAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 8:
				return (
					<AdvancedEventsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			case 9:
				return (
					<PackageVersionsAdministrationPage
						onTabChanged={handleTabIndexChanged}
						tabLabels={tabLabels}
						packageId={props.packageId}
					/>
				)
			default:
				throw new Error(`selected index ${selectedIndex} is not valid`)
		}
	}

	const handleTabIndexChanged = useCallback(
		(evt: React.SyntheticEvent, value: number) => {
			setSelectedIndex(value)
		},
		[],
	)

	if (packageData === undefined) return <FullscreenSpinner />

	return (
		<FormPackagePageContextProvider data={packageData}>
			{tabSelector(packageData)}
		</FormPackagePageContextProvider>
	)
}

export default FormPackagePage
