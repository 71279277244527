import { beforeSubmitFormEventId } from "../../modules/FormBuilderInterop/EventBus/BuiltInEvents/BeforeSubmitFormEvent";
import { formLoadEventId } from "../../modules/FormBuilderInterop/EventBus/BuiltInEvents/FormLoadEvent";

export const FormEvents: Record<string, { name: string; description: string }> =
{
		[formLoadEventId]: {
			name: 'On Form Load',
			description: 'Occurs when a user loads a form',
		},
		[beforeSubmitFormEventId]: {
			name: 'On Before Form Submit',
			description: 'Occurs when a user clicks Submit to submit a form',
		},
	}
