import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import * as yup from 'yup'
import {
	BaseSecretModel,
	SecretType,
	VaultRecord,
} from '../../../../api/clients/vaultStorage/DTO'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'

type CreateSecretDialogProps = {
	existingSecretNames: string[]
	vaults: VaultRecord[]
	onConfirm: (v: BaseSecretModel) => void
	onCancel: () => void
}

export const CreateSecretDialog = (props: CreateSecretDialogProps) => {
	const [selectedVault, setSelectedVault] = useState<VaultRecord>(
		props.vaults[0] ?? undefined,
	)

	const [secretType, setSecretType] = useState<SecretType>(
		selectedVault.secretTypes[0] ?? undefined,
	)

	const secretTypes = useMemo(() => {
		return selectedVault.secretTypes
	}, [selectedVault])

	const handleUpdateVault = useCallback((vault: VaultRecord) => {
		setSelectedVault(vault)

		setSecretType(vault.secretTypes[0])
	}, [])

	const validationSchema = yup.object({
		name: yup
			.string()
			.required('Name is a required field')
			.test(
				'is-unique',
				'A secret with this name already exists in your organization',
				(value) => {
					return (
						value !== undefined &&
						!props.existingSecretNames
							.map((v) => v.toUpperCase().trim())
							.includes(value.toUpperCase().trim())
					)
				},
			),
		description: yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		validationSchema,
		onSubmit: (values) => {
			if (selectedVault === undefined) return

			props.onConfirm({
				name: values.name,
				description: values.description,
				state: {},
				vaultId: selectedVault.vaultId,
				secretTypeId: secretType.secretTypeId,
			})
		},
	})

	return (
		<>
			<DialogTitle>Create Secret</DialogTitle>
			<DialogContent>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.touched.name && formik.errors.name}
					hintColor="error.main"
				>
					<TextField
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						error={!!formik.errors.name && formik.touched.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint
					label="Description"
					hint={formik.touched.description && formik.errors.description}
					hintColor="error.main"
				>
					<TextField
						fullWidth
						id="description"
						name="description"
						value={formik.values.description}
						error={!!formik.errors.description && formik.touched.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeader label="Vault">
					<Select
						fullWidth
						defaultValue={selectedVault.vaultId}
						value={selectedVault.vaultId}
						onChange={(evt) => {
							const vault =
								props.vaults.find((v) => v.vaultId === evt.target.value) ??
								props.vaults[0]

							handleUpdateVault(vault)
						}}
					>
						{props.vaults.map((vault) => (
							<MenuItem key={vault.vaultId} value={vault.vaultId}>
								<Box>
									<Typography fontWeight="medium">
										{vault.displayName}
									</Typography>
									<Typography fontStyle="italic" fontSize="small">
										{vault.description}
									</Typography>
								</Box>
							</MenuItem>
						))}
					</Select>
				</FixedHeader>
				{/* display secret types only if they exist or if there are more secret types
				  than just the current vault*/}
				{(secretTypes.length > 1 ||
					(secretTypes.length === 1 &&
						secretTypes[0].secretTypeId !== selectedVault.vaultId)) && (
					<FixedHeader label="Secret Type">
						<Select
							fullWidth
							defaultValue={secretType?.secretTypeId}
							value={secretType?.secretTypeId}
							onChange={(evt) => {
								setSecretType(
									secretTypes.find(
										(v) => v.secretTypeId === evt.target.value,
									) ?? secretTypes[0],
								)
							}}
						>
							{secretTypes.map((secretType) => (
								<MenuItem
									key={secretType.secretTypeId}
									value={secretType.secretTypeId}
								>
									<Box>
										<Typography fontWeight="medium">
											{secretType.displayName}
										</Typography>
										<Typography fontStyle="italic" fontSize="small">
											{secretType.description}
										</Typography>
									</Box>
								</MenuItem>
							))}
						</Select>
					</FixedHeader>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					variant="contained"
					onClick={formik.submitForm}
					disabled={
						!formik.dirty ||
						!formik.isValid ||
						selectedVault === undefined ||
						formik.isSubmitting
					}
				>
					Create
				</Button>
			</DialogActions>
		</>
	)
}
