import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useHistory } from 'react-router'
import { AssignmentType } from '../../../../../api/clients/workItems/DTOs'
import { PortalVersion } from '../../../../../api/DTOtemp'
import UserChip from '../../../../../components/chips/UserChip'
import { usePrompts } from '../../../../../services/notifications/PromptService'

type PortalsTableProps = {
	portals: PortalVersion[]
	onEditClicked: (portalId: number) => void
	onDeletePortal: (portalId: number) => void
}

export const PortalsTable = (props: PortalsTableProps) => {
	const styles = useStyles()
	const prompts = usePrompts()
	const history = useHistory()

	return (
		<Fade in>
			<TableContainer className={styles.tableRoot} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Portal Name</TableCell>
							<TableCell>Portal Description</TableCell>
							<TableCell>Last Edited</TableCell>
							<TableCell>Edited By</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.portals.map((v) => (
							<TableRow key={v.id}>
								<TableCell>
									<Typography variant="body2">{v.name}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2">{v.description}</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body2">
										{new Date(v.lastModifiedDate).toLocaleString()}
									</Typography>
								</TableCell>
								<TableCell>
									<UserChip
										assignmentType={AssignmentType.User}
										id={v.lastModifiedBy}
									/>
								</TableCell>
								<TableCell align="right">
									<IconButton
										onClick={() => {
											props.onEditClicked(v.portalId)
										}}
									>
										<Edit fontSize="small" />
									</IconButton>
									<IconButton
										onClick={() => {
											prompts
												.showDialog((props) => (
													<ConfirmDeletePortalPrompt
														portalName={v.name}
														onClose={() => props.close('cancel')}
														onConfirm={() => props.close('okay')}
													/>
												))
												.then((r) => {
													if (r === 'okay') props.onDeletePortal(v.portalId)
												})
										}}
									>
										<Delete fontSize="small" />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

type ConfirmDeletePortalPromptProps = {
	portalName: string
	onConfirm: () => void
	onClose: () => void
}

const ConfirmDeletePortalPrompt = (props: ConfirmDeletePortalPromptProps) => {
	return (
		<>
			<DialogTitle>Confirm Delete Portal</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete <b>{props.portalName}</b>?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		height: 'fit-content',
		overflow: 'hidden',
	},
}))
