import { AxiosResponse } from 'axios'
import { CellDefinition } from '../../../modules/FormHost/Types/CellDefinition'
import {
	DetailForm,
	FormInfoModel,
	FormVersion,
	UpdatedPdfFormModel
} from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class FormsClient extends SecureClient {
	private _packageId: number

	private _urlBase: string

	constructor(
		packageId: number,
		packageVersionNumber: number,
	) {
		super()
		this._packageId = packageId
		this._urlBase = `FormPackages/${this._packageId}/versions/${packageVersionNumber}/Forms`
	}

	/**
	 * Get all the forms in a specified package
	 */
	public GetAllForms(): Promise<AxiosResponse<DetailForm[]>> {
		return this._api.get<DetailForm[]>(`${this._urlBase}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Get all the forms in a specified package as an anonymous user
	 */
	public GetAllFormsAnonymous(): Promise<AxiosResponse<DetailForm[]>> {
		return this._api.get<DetailForm[]>(`${this._urlBase}/Anonymous`, {
			headers: {
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Create a new document at the specified package
	 * @param data
	 */
	public CreateForm(
		infoModel: FormInfoModel,
	): Promise<AxiosResponse<DetailForm>> {
		const formData = new FormData()

		formData.set('name', infoModel.name)
		formData.set('formData', infoModel.formData)
		formData.set('metadata', JSON.stringify(infoModel.metadata))
		formData.set('formConfiguration', JSON.stringify(infoModel.formConfiguration))

		return this._api.post<DetailForm>(`${this._urlBase}`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader('application/problem+json; charset=utf-8'),
				...this.acceptHeader('application/problem+json'),
			},
		})
	}
	
	/**
	 * Obtain the specified document
	 * @param formId
	 */
	public GetForm(formId: number, abortSignal: AbortSignal): Promise<AxiosResponse<DetailForm>> {
		return this._api.get<DetailForm>(`${this._urlBase}/${formId}`, {
			signal: abortSignal,
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Obtain the specified document as an anonymous user
	 * @param formId
	 * @returns
	 */
	public GetFormAnonymous(formId: number, abortSignal: AbortSignal): Promise<AxiosResponse<DetailForm>> {
		return this._api.get<DetailForm>(`${this._urlBase}/${formId}/Anonymous`, {
			signal: abortSignal,
			headers: {
				...this.acceptHeader(),
			},
		})
	}

	public getFormVersions(
		formId: number,
	): Promise<AxiosResponse<FormVersion[]>> {
		return this._api.get<FormVersion[]>(`${this._urlBase}/${formId}/versions`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Get the PDF form with the updated values in the form's fields
	 * @param formId
	 * @param cellInstances the PDF cell instances from the form
	 * @returns the updated PDF form (with field filled with instance values) as an ArrayBuffer
	 */
	public async GetUpdatedPdfForm(
		formId: number,
		formModel: UpdatedPdfFormModel,
	): Promise<AxiosResponse<Blob>> {
		const url = `${this._urlBase}/${formId}/pdf`

		const formData = new FormData();
		formData.append('cellInstances', JSON.stringify(formModel.cellInstances))
		if (formModel.existingForm)
			formData.append('existingForm', formModel.existingForm)

		return this._api.post(url, formData, {
			responseType: 'blob',
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader('application/pdf'),
				...this.contentTypeHeader(),
			},
		})
	}

	/**
	 * Get the PDF form with the updated values in the form's fields as an anonymous user
	 * @param formId
	 * @param cellInstances the PDF cell instances from the form
	 * @returns the updated PDF form (with field filled with instance values) as an ArrayBuffer
	 */
	public async GetUpdatedPdfFormAnonymous(
		formId: number,
		formModel: UpdatedPdfFormModel,
	): Promise<AxiosResponse<Blob>> {
		const url = `${this._urlBase}/${formId}/pdf/anonymous`

		return this._api.post(url, formModel, {
			responseType: 'blob',
			headers: {
				...this.acceptHeader('application/pdf'),
				...this.contentTypeHeader(),
			},
		})
	}

	/**
	 * Delete the specified document
	 * @param formId
	 */
	public DeleteForm(formId: number): Promise<AxiosResponse<DetailForm>> {
		return this._api.delete<DetailForm>(`${this._urlBase}/${formId}`, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}

	/**
	 * fetch the form stream for a specific form
	 * @param formId
	 * @param versionNumber
	 */
	public GetFormStream(formId: number, abortSignal: AbortSignal): Promise<AxiosResponse<Blob>> {
		return this._api.get<Blob>(`${this._urlBase}/${formId}/stream`, {
			signal: abortSignal,
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
			responseType: 'blob'
		})
	}
	public GetFormVersionStream(
        formId: number,
        versionNumber: number,
    ): Promise<AxiosResponse<Blob>> {
        return this._api.get<Blob>(
            `${this._urlBase}/${formId}/stream/${versionNumber}`,
            {
                headers: {
                    ...this.authorizationHeader,
                    ...this.acceptHeader(),
                },
                responseType: 'blob'
            },
        )
    }


	/**
	 * fetch the form stream for a specific form as an anonymous user
	 * @param formId
	 * @param versionNumber
	 */
	public GetFormStreamAnonymous(
		formId: number,
		abortSignal: AbortSignal
	): Promise<AxiosResponse<Blob>> {
		return this._api.get<Blob>(
			`${this._urlBase}/${formId}/stream/Anonymous`,
			{
				signal: abortSignal,
				headers: {
					...this.acceptHeader(),
				},
				responseType: 'blob'
			},
		)
	}

	/**
	 * Update the specified form
	 * @param formId
	 * @param data
	 */
	public UpdateForm(
		formId: number,
		infoModel: FormInfoModel,
	): Promise<AxiosResponse<DetailForm>> {
		const formData = new FormData()
				
		formData.set('name', infoModel.name)
		formData.set('formData', infoModel.formData)
		formData.set('metadata', JSON.stringify(infoModel.metadata))
		formData.set('formConfiguration', JSON.stringify(infoModel.formConfiguration))

		return this._api.put(`${this._urlBase}/${formId}/stream`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader('multipart/form-data'),
			},
		})
	}

	public UpdateFormSettings(
		formId: number,
		settings: Record<string, unknown>,
	): Promise<AxiosResponse> {
		return this._api.post(`${this._urlBase}/${formId}/settings`, settings, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}

	public UpdateCellOverrides(
		formId: number,
		overrides: CellDefinition[],
	): Promise<AxiosResponse> {
		return this._api.post(`${this._urlBase}/${formId}/override`, overrides, {
			headers: {
				...this.authorizationHeader,
			}
		})
	}

	public setFormPositions(
		forms: { formId: number; ordinalPosition: number }[],
	): Promise<AxiosResponse> {
		return this._api.post(`${this._urlBase}/positions`, forms, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}

	//TODO add get all versions of a form, once return type is updated

	public DeleteFormVersion(
		formId: number,
		versionId: number,
	): Promise<AxiosResponse> {
		return this._api.delete(
			`${this._urlBase}/${formId}/versions/${versionId}`,
			{
				headers: {
					...this.authorizationHeader,
				},
			},
		)
	}
}
