import { AxiosResponse } from "axios";
import { SessionService } from "../../../services/session";
import { NewPortal, Portal, PortalData, PortalSettings, PortalVersion } from "../../DTOtemp";
import SecureClient from "../SecureClient";

export class PortalsClient extends SecureClient{
	private _urlBase: string
	protected readonly _sessionService = new SessionService()

	constructor() {
		super()
		this._urlBase = `portals`
	}

	/**
	 * Gets all the portals in an organization
	 */
	public GetPortals(): Promise<AxiosResponse<Portal[]>> {
		return this._api.get<Portal[]>(`${this._urlBase}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Gets a specific portal object
	 * @param portalId the guid id of the portal
	 */
	public GetPortal(portalId: string): Promise<AxiosResponse<Portal>> {
		return this._api.get<Portal>(`${this._urlBase}/${portalId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * returns the latest version of each portal
	 */
	public GetLatestPortalVersions(): Promise<AxiosResponse<PortalVersion[]>> {
		return this._api.get<PortalVersion[]>(`${this._urlBase}/latest-versions`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * returns the latest version of a given portal
	 * @param portalId the portal to get the version from
	 */
	public GetLatestPortalVersion(portalId: number): Promise<AxiosResponse<PortalVersion>> {
		return this._api.get<PortalVersion>(`${this._urlBase}/${portalId}/latest-version`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			}
		})
	}

	/**
	 * gets a specific version for a portal
	 * @param portalId the portal the version belongs to
	 * @param versionNumber 
	 */
	public GetPortalVersion(portalId: number, versionNumber: number): Promise<AxiosResponse<PortalVersion>> {
		return this._api.get<PortalVersion>(`${this._urlBase}/${portalId}/version/${versionNumber}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			}
		})
	}

	/**
	 * Gets the actual portal data
	 * @param portalId the portal being queried
	 * @param versionNumber the version to get the stream from
	 */
	public GetPortalStream(portalId: number, versionNumber: number): Promise<AxiosResponse<Blob>> {
		// if user isn't logged in, don't send the org or auth headers
			return this._api.get<Blob>(`${this._urlBase}/${portalId}/version/${versionNumber}/stream`, !this._sessionService.isLoggedIn ? {
				headers: {
					...this.acceptHeader(),
				},
				responseType: 'blob'
			} : {
				// if user isn't anonymous, send auth and org data along with api request
				headers: {
					...this.authorizationHeader,
						...this.acceptHeader(),
				},
				responseType: 'blob'
			})
	}

	/**
	 * Creates a new portal
	 * @param portalData the data describing how to create a new portal
	 * @returns the portal version representing the newly created portal
	 */
	public CreatePortal(portalData: NewPortal): Promise<AxiosResponse<PortalVersion>> {
		const formData = new FormData()

		formData.set('name', portalData.name)
		formData.set('description', portalData.description)
		formData.set('authenticationRequired', JSON.stringify(portalData.authenticationRequired))
		formData.set('portalData', portalData.portalData)
		
		return this._api.post<PortalVersion>(`${this._urlBase}`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader('application/problem+json; charset=utf-8'),
				...this.acceptHeader('application/problem+json'),
			},
		})
	}

	/**
	 * Gets the portal settings
	 * @param portalId the portal being queried
	 * @param versionNumber the version to get settings for
	 * @returns the settings for the given version, or the newest version in number is invalid
	 */
	public GetPortalSettings(portalId: number, versionNumber: number): Promise<AxiosResponse<PortalSettings>> {
		return this._api.get<PortalSettings>(`${this._urlBase}/${portalId}/version/${versionNumber}/settings`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Updates the portal data
	 * @param portalId the portal to be updated
	 * @param portalData the portal data to create the new version with
	 * @returns the newly created portal version with the updated data
	 */
	public UpdatePortalData(portalId: number, portalData: PortalData): Promise<AxiosResponse<PortalVersion>> {
		const formData = new FormData()

		formData.set('name', portalData.name)
		formData.set('portalData', portalData.portalData)

		return this._api.put<PortalVersion>(`${this._urlBase}/${portalId}`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader('application/problem+json; charset=utf-8'),
				...this.acceptHeader('application/problem+json'),
			},
		})
	}

	/**
	 * Updates a portal setting without updating the data
	 * @param portalId the portal to update
	 * @param settings the new values for the portal settings
	 * @returns the newly created portal version
	 */
	public UpdatePortalSettings(portalId: number, settings: PortalSettings): Promise<AxiosResponse<PortalVersion >> {
		return this._api.put<PortalVersion>(`${this._urlBase}/${portalId}/settings`, settings, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}

	/**
	 * deletes the specified portal
	 */
	public DeletePortal(portalId: number): Promise<AxiosResponse> {
		return this._api.delete(`${this._urlBase}/${portalId}`)
	}

	/**
	 * delete a specific portal version
	 * @param portalId the portal containing the version
	 * @param versionNumber the version to be deleted
	 */
	public DeletePortalVersion(portalId: number, versionNumber: number): Promise<AxiosResponse>{
		return this._api.delete(`${this._urlBase}/${portalId}/version/${versionNumber}`)
	}
}