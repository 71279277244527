import { Box, MenuItem, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { px } from 'csx'
import { Program } from 'estree'
import { action, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useFormEventsContext } from '../../../../../pages/Organization/Administration/Forms/FormPackagesPage/FormPackagePage/Sections/AdvancedEventsSection/FormEventsContext'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { FormEventType, RendererComponent } from '../../../Types'
import ColoredFormControl from '../../internal/ColoredFormControl'
import ColoredSelect from '../../internal/ColoredSelect'
import Editor from '../../internal/Editor'
import NodeBodyWrapper from '../../internal/NodeBodyWrapper'
import useColorScheme from '../../internal/useColorScheme'

const FormEventComponent = observer((props: RendererComponent<Program>) => {
	const styles = useStyles()
	const [color, textColor] = useColorScheme(ComponentCategory.Events)

	const formEventsContext = useFormEventsContext()

	const formsInfo = props.node.formsInfo

	// this shouldn't happen because we have a check in the parent component that leads us here
	if (formsInfo === undefined || formsInfo.type !== 'FormEvent')
		throw new Error(
			`FormEvent component requires FormsInfo to be of type FormEvent, is type ${formsInfo?.type}`,
		)

	const eventType = formsInfo.event

	// if we are in the form page, we know we're only working with the current form
	if (formEventsContext.type === 'Form') {
		runInAction(() => {
			formsInfo.formId = formEventsContext.form.id
		})
	}

	return (
		<NodeBodyWrapper
			color={color}
			topSection={
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={2}
					width="fit-content"
					margin={1}
				>
					<Typography color={textColor}>On</Typography>

					{formEventsContext.type === 'Form' ? (
						<Typography fontStyle="italic" color={textColor}>
							{formEventsContext.form.name}
						</Typography>
					) : (
						<ColoredFormControl formControlColor={textColor}>
							<ColoredSelect
								variant="standard"
								className={styles.select}
								fullWidth
								selectColor={textColor}
								value={formsInfo.formId ?? ''}
								size="small"
								onChange={action(
									(event) =>
										(formsInfo.formId = parseInt(event.target.value as string)),
								)}
							>
								<MenuItem disabled value={0}>
									<Typography fontStyle="italic">Form</Typography>
								</MenuItem>
								{formEventsContext.forms.map((form) => (
									<MenuItem key={form.id} value={form.id}>
										{form.name}
									</MenuItem>
								))}
							</ColoredSelect>
						</ColoredFormControl>
					)}

					<ColoredFormControl formControlColor={textColor}>
						<ColoredSelect
							variant="standard"
							selectColor={textColor}
							value={eventType}
							fullWidth
							className={styles.select}
							size="small"
							onChange={action(
								(event) =>
									(formsInfo.event = event.target.value as FormEventType),
							)}
						>
							{Object.values(FormEventType).map((eventType) => (
								<MenuItem key={eventType} value={eventType}>
									{eventType}
								</MenuItem>
							))}
						</ColoredSelect>
					</ColoredFormControl>
				</Box>
			}
		>
			<Editor node={props.node as Program}></Editor>
		</NodeBodyWrapper>
	)
})

const useStyles = makeStyles(() => ({
	select: {
		minWidth: px(100), // i do not like min width but without it the select is so teeny
	},
}))

export default FormEventComponent
