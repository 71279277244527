import { AxiosResponse } from "axios"
import SecureClient from "../SecureClient"
import { BaseWorkItemModel, BaseWorkItemState, NewWorkItemModel, WorkItemPermissions, WorkItemResponse, WorkItemResult, WorkItemState, WriteWorkItemModel } from "./DTOs"

export default class WorkItemsClient extends SecureClient {

	/**
	 * Get one work item with optional parameter to read or write - write acquires a lock.
	 * Read is default
	 */
	
	public getWorkItem<TState extends BaseWorkItemState>(
		workItemId: number,
	): Promise<AxiosResponse<WorkItemResponse<TState>>>;
	public getWorkItem<TState extends BaseWorkItemState>(
		workItemId: number,
		permission?: WorkItemPermissions
	): Promise<AxiosResponse<WorkItemResponse<TState>>>;
	public getWorkItem<TState extends BaseWorkItemState>(
		workItemId: number,
		permission: WorkItemPermissions.Read
	): Promise<AxiosResponse<WorkItemResponse<TState>>>;
	public getWorkItem<TState extends BaseWorkItemState>(
		workItemId: number,
		permission: WorkItemPermissions.Write
	): Promise<AxiosResponse<WriteWorkItemModel<TState>>>;
	public getWorkItem<TState extends BaseWorkItemState> (
		workItemId: number,
		permission?: WorkItemPermissions,
	): Promise<AxiosResponse<WorkItemResponse<TState>>> {
		return this._api.get<WorkItemResponse<TState>>(
			`WorkItems/${workItemId}`,
			{
				headers: {
					...this.acceptHeader,
					...this.contentTypeHeader,
				},
				params: {
					permission,
				},
			},
		)
	}

	/**
	 * Get all work items for an organization
	 * @param workItemState - optional - get all, locked, unlocked, active, or done work items
	 */
	public getWorkItemsForOrganization = (
		workItemState?: WorkItemState,
	): Promise<AxiosResponse<BaseWorkItemModel[]>> => {
		return this._api.get<BaseWorkItemModel[]>(
			`WorkItems`,
			{
				headers: {
					...this.acceptHeader,
					...this.contentTypeHeader,
				},
				params: {
					workItemState,
				},
			},
		)
	}

	public addWorkItem = (
		model: NewWorkItemModel,
	): Promise<AxiosResponse<BaseWorkItemModel>> => {
		return this._api.post(`WorkItems`, model, {
			headers: {
				...this.acceptHeader,
				...this.contentTypeHeader,
			},
		})
	}

	/**
	 * renew a work item's lock
	 * @param workItemId
	 * @returns the lock password for the specified work item
	 */
	public renewLock = (workItemId: number): Promise<AxiosResponse<string>> => {
		return this._api.put(
			`WorkItems/${workItemId}/RenewLock`,
			{
				headers: {
					...this.acceptHeader,
					...this.contentTypeHeader,
				},
			},
		)
	}

	public completeWorkItem = (
		workItemId: number,
		result: WorkItemResult,
	): Promise<AxiosResponse> => {
		return this._api.post(
			`WorkItems/${workItemId}/Complete`,
			result,
			{
				headers: {
					...this.acceptHeader,
					...this.contentTypeHeader,
				},
			},
		)
	}
}