import axios, { AxiosResponse } from "axios"
import { action, computed, makeObservable, observable } from "mobx"
import { OrganizationUserAssociation, UserOrganizationFilter } from "../../api/DTOtemp"
import { DebugLog } from "../../utils/DebugLog"

// 1000 ms -> 60s -> 10m
const pollingPeriod = 1000 * 60 * 10

// if we're loading the service then use a quick 1s refresh
// if we don't do this then the history service instantiates
// and requests a value from us before we're done
const quickRefreshDelay = 1000

const separator = "."


type StorageEntry<T> = {

	sourceId: number
	value: T

}

const windowId = window.performance?.now() ?? 0
export { windowId as WindowId }

class SessionData {

	private _authToken: FormsJwtAccessToken

	// current time in nanoseconds, used to register our id for other tabs
	// https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
	

	get authToken() {
		if (!this._authToken)
			this._authToken = new FormsJwtAccessToken()

		return this._authToken
	}

	set authToken(value: FormsJwtAccessToken) {
		this._authToken = value
	}

	public refreshAuthToken() {
		if (!navigator.onLine)
			return;

		axios.post(`${window.location.origin}/api/v1/authentication/refresh`, null, {
			headers: {
				Authorization: this._authToken.rawToken
			}
		})
	}

	public async getOrganizationAssociations(): Promise<AxiosResponse<OrganizationUserAssociation[]>> {
		const user: UserOrganizationFilter = {
			userId: this._authToken.id
		}

		const userId = user.userId

		return axios.get(`${window.location.origin}/api/v1/CurrentOrganizationUsers`, {
			headers: {
				Authorization: this._authToken.rawToken,
				'Content-Type': 'application/json'
			},
			params: {
				userId
			}
		})
	}

	/**
	 *
	 */
	constructor() {
		makeObservable<SessionData, "_authToken">(this, {
      _authToken: observable
		});

		this._authToken = new FormsJwtAccessToken()
	}
}

export abstract class TokenService<T extends JwtBody> {
    protected internalPollingPeriod = pollingPeriod
    private _refreshTaskId = 0

    protected _rawToken = "";

    constructor() {
        makeObservable<TokenService<T>, "_rawToken">(this, {
            _rawToken: observable,
            notBefore: computed,
            expirationDate: computed,
            issuedAt: computed,
            issuer: computed,
            audience: computed,
            expired: computed,
            setToken: action
        });
    }

    public get notBefore(): Date {
		return this.convertDate(this.body?.nbf ?? 0)
	}

    public get expirationDate(): Date {
		return this.convertDate(this.body?.exp ?? 0)
	}

    public get issuedAt(): Date {
		return this.convertDate(this.body?.iat ?? 0)
	}

    public get issuer(): string {
		return this.body?.iss ?? ""
	}

    public get audience(): string {
		return this.body?.iss ?? ""
	}

    public get expired(): boolean {
		return this.isExpired(this.body?.exp ?? 0)
	}

    public setToken(tokenValue: string): void {
		this._rawToken = tokenValue

		this.restartRefreshTask(() => this.triggerTokenUpdate())
	}

    protected get refreshTaskId(): number {
		console.log('refresh id fetched', this._refreshTaskId)
		return this._refreshTaskId
	}

    protected set refreshTaskId(id: number) {
		console.log('refresh id set', this._refreshTaskId)
		this._refreshTaskId = id
	}

    public setPollingPeriod(pollingPeriod: number): void {
		this.internalPollingPeriod = pollingPeriod
	}


    public restartRefreshTask(event: () => unknown, pollingPeriod?: number): void {
		window.clearTimeout(this.refreshTaskId)
		console.log('refresh timeout cleared')
		this._refreshTaskId = 0

		if (!this.rawToken) {
			this._refreshTaskId = 0
			return
		}

		this._refreshTaskId = window.setTimeout(() => event(), pollingPeriod ?? this.internalPollingPeriod)
	}

    public abstract get rawToken(): string
    public abstract set rawToken(token: string)

    public abstract get body(): T | null

    public abstract logout(): void

    protected getToken<T>(tokenName: string):
		StorageEntry<T> | null {
		const entry = localStorage.getItem(tokenName)
		return entry
			? JSON.parse(entry) as StorageEntry<T>
			: null
	}

    protected isExpired(expirationDate: number): boolean {
		// JWT tokens are in seconds, * 1000 to get JS Date
		return this.convertDate(expirationDate) <= new Date()
	}

    protected convertDate(seconds: number): Date {
		return new Date(seconds * 1000)
	}

    protected abstract triggerTokenUpdate(): void
}

export class FormsJwtAccessToken extends TokenService<FormsJwtBody> {

	static tokenName = "FJWT"

	private readonly log: DebugLog = new DebugLog(FormsJwtAccessToken.tokenName)
	private readonly tokenIndicator = "Bearer "

	public get rawToken(): string {
		return this._rawToken
	}

	// mobx auto-generates the @action for this
	public set rawToken(value: string) {
		this._rawToken = value

		console.log('refresh token set', value)

		this.restartRefreshTask(() => this.triggerTokenUpdate())

		// there will have been an error if we have a missing value
		if (!value) {
			localStorage.removeItem(FormsJwtAccessToken.tokenName)
			return
		}

		// persist our session in local storage
		const sharedToken: StorageEntry<string> = {
			'sourceId': windowId,
			'value': value
		}
		localStorage.setItem(FormsJwtAccessToken.tokenName, JSON.stringify(sharedToken))
	}


	public get body(): FormsJwtBody | null {

		if (this.rawToken === null || this.rawToken === undefined)
			return null

		const tokenParts = this.isolatedToken.split(separator)

		if (tokenParts[1] === undefined)
			return null

		return JSON.parse(window.atob(tokenParts[1])) as FormsJwtBody
	}

	private get isolatedToken(): string {
		if (!this._rawToken)
			return ""
		return this.rawToken.substring(this.tokenIndicator.length)
	}


	public get id(): number | undefined {
		return this.body?.sub ?? undefined
	}

	public get firstName(): string | null {
		return this.body?.given_name ?? null
	}

	public get lastName(): string | null {
		return this.body?.family_name ?? null
	}

	public get email(): string | null {
		return this.body?.email ?? null
	}

	public get version(): string | null {
		return this.body?.version ?? null
	}

	public get groups(): string[] | null {
		const result = this.body?.group ?? []
		return Array.isArray(result) ? result : [result]
	}

	public get roles(): string[] | null {
		const result = this.body?.role ?? []
		return Array.isArray(result) ? result : [result]
	}

	public get orgName(): string | null {
		return this.body?.orgname ?? null
	}

	public get subDomain(): string | null {
		return this.body?.subdomain ?? null
	}

	constructor() {
        super()

        makeObservable<FormsJwtAccessToken, "isolatedToken">(this, {
            rawToken: computed,
            body: computed,
            isolatedToken: computed,
            id: computed,
            firstName: computed,
            lastName: computed,
            email: computed
        });

        this.fetchStoredToken()
    }

	private fetchStoredToken(): void {
		// attempt to get the token from storage
		const storedToken = this.getToken<string>(FormsJwtAccessToken.tokenName)
		if (!storedToken)
			return

		// if we have a token then we can trigger a token update
		this.rawToken = storedToken.value

		window.setTimeout(() => this.triggerTokenUpdate(), quickRefreshDelay)

		this.restartRefreshTask(() => this.triggerTokenUpdate())
	}

	public logout(): void {
		localStorage.removeItem(FormsJwtAccessToken.tokenName)
		this.rawToken = ""
	}

	protected async triggerTokenUpdate(): Promise<void> {
		console.log('fetching new authorization token')
	//	const authTokenClient = new AuthenticationClient()
		// axios picks this value up for us :)
		// authTokenClient.refresh()
		// 	.then(() => console.log('authentication token fetched'))

		sessionDataInstance.refreshAuthToken()
	}
}

type JwtBody = {
	nbf: number
	exp: number
	iat: number
	iss: string
	aud: string
	sub: number
}

export type FormsJwtBody = JwtBody & {
	given_name: string
	family_name: string
	email: string
	// - new
	version: string
	group: string | string[]
	role: string | string[]
	orgname: string
	subdomain: string
}

const sessionDataInstance = new SessionData()
console.log('session data initialized')
export default sessionDataInstance

