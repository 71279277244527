
import { AxiosResponse } from "axios"
import { BasePackage, FormPackageInfo, FormPackageInstance, SimplePackage, UploadFormPackageModel } from '../../DTOtemp'
import SecureClient from '../SecureClient'
import { AssignedFormPackagesWithChangeToken, ForwardedFormPackageWorkItemState, WorkItemResponse } from "../workItems/DTOs"

export class FormPackageClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `FormPackages`
	}

	/**
	 * Get a list of forms that the current user has access to
	 */
	public GetFormPackages(): Promise<AxiosResponse<FormPackageInfo[]>> {
		return this._api.get<FormPackageInfo[]>(`${this._urlBase}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	public GetAnonymousFormPackages(): Promise<AxiosResponse<FormPackageInfo[]>> {
		return this._api.get<FormPackageInfo[]>(`${this._urlBase}/Anonymous`, {
			headers: {
				...this.acceptHeader()
			}
		})
	}

	/**
	 * Get a list of a user's saved form packages
	 */
	public GetSavedFormPackages(): Promise<AxiosResponse<FormPackageInstance[]>> {
		return this._api.get<FormPackageInstance[]>(`${this._urlBase}/Saved`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	/**
	 * Get a list of a user's assigned form packages
	 */
	public GetAssignedFormPackages(): Promise<AxiosResponse<WorkItemResponse<ForwardedFormPackageWorkItemState>[]>> {
		return this._api.get<WorkItemResponse<ForwardedFormPackageWorkItemState>[]>(
			`${this._urlBase}/Assigned`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
				},
			},
		)
	}

	public GetAssignedFormPackagesWithChangeTokens(): Promise<AxiosResponse<AssignedFormPackagesWithChangeToken[]>> {
		return this._api.get<AssignedFormPackagesWithChangeToken[]>(
			`${this._urlBase}/AssignedWithChangeTokens`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
				}
			}
		)
	}

	/**
	 * Create a new form package
	 * @param packageData
	 */
	public CreateFormPackage(
		packageData: SimplePackage,
	): Promise<AxiosResponse<BasePackage>> {
		return this._api.post<BasePackage>(
			`${this._urlBase}`,
			JSON.stringify(packageData),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader(),
					...this.acceptHeader(),
				},
			},
		)
	}

	public UploadFormPackage(uploadModel: UploadFormPackageModel, packageId?: number): Promise<AxiosResponse<FormPackageInfo>> {
		return this._api.post<FormPackageInfo>(
			`${this._urlBase}/upload/${packageId ? packageId : ''}`,
			uploadModel,
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader(),
					...this.acceptHeader(),
				},
			},
	)}

	/**
	 * Get data about the specified form
	 * @param packageId
	 */
	public GetFormPackage(
		packageId: number,
		abortController?: AbortController
	): Promise<AxiosResponse<FormPackageInfo>> {
		return this._api.get<FormPackageInfo>(`${this._urlBase}/${packageId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
			signal: abortController?.signal
		})
	}

	public GetFormPackageAnonymous(
		packageId: number,
	): Promise<AxiosResponse<FormPackageInfo>> {
		return this._api.get<FormPackageInfo>(
			`${this._urlBase}/${packageId}/Anonymous`,
			{
				headers: {
					...this.acceptHeader(),
				},
			},
		)
	}

	/**
	 * ? I have no idea how this endpoint is supposed to work
	 * Submit a form package to the server
	 * @param formPackageId
	 */
	public SubmitFormPackage(formPackageId: number): Promise<AxiosResponse> {
		return this._api.post(`${this._urlBase}/${formPackageId}/Submit`, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}

	/**
	 * Delete a form package
	 * @param formPackageId
	 */
	public DeleteFormPackage(formPackageId: number): Promise<AxiosResponse> {
		return this._api.delete(`${this._urlBase}/${formPackageId}`, {
			headers: {
				...this.authorizationHeader,
			},
		})
	}
}