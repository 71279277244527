import { Box, Theme } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent, px } from 'csx'
import { BlockStatement, Node, Program, Statement } from 'estree'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { isChildOfTree, placeNode } from '../codegen/internal/NodeApi'
import { useProgramContext } from '../ProgramContext'
import { ValidNodeTypes } from '../Types'

type InsertComponentProps = {
	insertionIndex: number
	node: Program | BlockStatement
	validNodes: ValidNodeTypes[]
}

export const InsertComponent = observer((props: InsertComponentProps) => {
	const styles = useStyles()
	const context = useProgramContext()

	if (props.node.body === undefined) throw new Error('Node requires a body')

	const [{ anythingDragging, isOver, canDrop }, drop] = useDrop({
		accept: props.validNodes,
		collect: (monitor) => ({
			// we don't want to display the dustbin components if the item we're dragging cannot be placed there
			anythingDragging: props.validNodes
				.map((v) => v.toString())
				.includes(monitor.getItemType()?.toString() ?? ''),
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: !!monitor.canDrop(),
		}),
		canDrop(item) {
			return !isChildOfTree(item as Node, props.node)
		},
		drop: (item, monitor) => {
			if (monitor.didDrop()) return

			placeNode(
				context.programs,
				item as Node,
				() => undefined,
				(node) => {
					if (props.node.body)
						props.node.body.splice(props.insertionIndex, 0, node as Statement)
				},
			)
		},
	})

	const classNames = useMemo(
		() =>
			clsx(
				styles.dropContainerStyle,
				styles.hiddenDustbin,
				{ [styles.visibleDropZone]: anythingDragging },
				{ [styles.hideBorder]: !anythingDragging },
			),
		[anythingDragging],
	)

	return (
		<Box
			className={classNames}
			ref={drop}
			width={percent(100)}
			height={5}
			padding={0.5}
		>
			<div className={isOver && canDrop ? styles.hoverOverlay : styles.noHover}>
				<Box
					display="flex"
					flex="1"
					textAlign="center"
					justifyContent="center"
					color={grey[400]}
				/>
			</div>
		</Box>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	dropContainerStyle: {
		display: 'flex',

		position: 'relative',
		justifyContent: 'center',

		border: px(2),
		borderStyle: 'solid',
		borderRadius: px(2),
		borderColor: grey[200],

		zIndex: 5,

		cursor: 'move',
	},

	hideBorder: {
		borderStyle: 'none',
		margin: px(4), // just the right amount to not make the other components move!
	},

	visibleDropZone: {
		backgroundColor: blue[50],
	},

	hiddenDustbin: {
		minHeight: theme.spacing(5),
		minWidth: theme.spacing(20),
		cursor: 'auto',
	},

	textTheme: {
		display: 'flex',
		flex: '1',

		textAlign: 'center',
		justifyContent: 'center',

		color: grey[400],
	},

	noHover: {
		visibility: 'hidden',
	},

	hoverOverlay: {
		flex: 1,

		opacity: '1',
		backgroundColor: blue[400],
	},
}))
