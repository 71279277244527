import { observable } from 'mobx'
import {
	LicenseResponse,
	LicensingClient,
} from '../../api/clients/identity/LicensingClient'
import { registerNotificationCenterNotification } from '../../components/notificationCenter/NotificationCenterContext'
import { toastService } from '../notifications/ToastService'

type LicenseInfo =
	| {
			readonly status: 'loading'
			promise: Promise<LicenseResponse>
	  }
	| {
			readonly status: 'licensed'
			license: LicenseResponse
	  }
	| {
			readonly status: 'notLicensed'
	  }

const client = new LicensingClient()

const promise = client.GetLicense().then((v) => v.data)

const licenseInstance: { licenseInfo: LicenseInfo } = observable({
	licenseInfo: {
		status: 'loading',
		promise: promise,
	},
})

export default licenseInstance

promise
	.then((v) => {
		licenseInstance.licenseInfo = {
			status: 'licensed',
			license: v,
		}
	})
	.catch(() => {
		licenseInstance.licenseInfo = {
			status: 'notLicensed',
		}
		toastService.displayToast({
			area: 'global',
			message: 'Software not licensed',
		})
		registerNotificationCenterNotification({
			title: 'Software Not Licensed',
			body: 'Forms inMotion is not licensed, some functionality will be disabled',
			status: 'error',
		})
	})
