import { makeAutoObservable } from 'mobx'
import * as React from 'react'
import { createContext, PropsWithChildren, useMemo } from 'react'
import { CellType } from '../../../../FormHost/Types/CellType'
import { IValueFormBuilderCellInstance } from '../../FormBuilderCellInstance'
import { ValueElement } from '../../FormBuilderCellTree'
import { FormBuilderElementContextProps } from './ContextTypes'

type InputContextType<TValue = unknown> = {
	readonly cellInstance: IValueFormBuilderCellInstance

	readonly value: TValue | null
	onChange: (v: TValue | null) => void
}

const cellContext = createContext<InputContextType | undefined>(undefined)

export const InputCellContextProvider = (
	props: PropsWithChildren<FormBuilderElementContextProps>,
): React.ReactElement => {
	const manager = useMemo(() => {
		const node = props.node

		if (node.cellType !== CellType.Value)
			throw new Error('value context provider requires a value node')

		return new StandardInputContextManager(node, props.formHost.isReadonly)
	}, [props.node])

	return (
		<cellContext.Provider value={manager}>
			{props.children}
		</cellContext.Provider>
	)
}

export function useInputCellContext<T>(): InputContextType<T> {
	const context = React.useContext(cellContext)
	if (context === undefined) throw new Error('input context was undefined')
	return context as InputContextType<T>
}

class StandardInputContextManager implements InputContextType {
	private readonly isReadonly: boolean
	public readonly node: ValueElement

	public get cellInstance() {
		return this.node.cell
	}

	get value(): unknown {
		return this.cellInstance.value
	}

	onChange(v: unknown) {
		if (this.isReadonly) return

		this.cellInstance.value = v
	}

	constructor(node: ValueElement, isReadonly: boolean) {
		this.node = node
		this.isReadonly = isReadonly

		makeAutoObservable(this)
	}
}
