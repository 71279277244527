import DataProviderInstancesClient from '../api/clients/dataProviders/DataProviderInstancesClient'

export type DataProviderInfo = {
	dataProviderId: number
	parameters: Record<string, string>
	key: string
	displayValue: string
}

export const executeDataProvider = async (
	dataProvider: DataProviderInfo
): Promise<{ key: string; displayValue: string }[]> => {
	const dataProviderInstancesClient = new DataProviderInstancesClient()

	const values: { key: string; displayValue: string }[] = []

	const { data: results } = await dataProviderInstancesClient.execute(
		dataProvider.dataProviderId,
		dataProvider.parameters,
	)

	for (const result of results)
		values.push({
			key: result[dataProvider.key] as string,
			displayValue: result[dataProvider.displayValue] as string,
		})
	
	return values
}
