import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { AuthenticationMethod, AuthenticationType } from '../../api/DTO'
import AuthenticationProviderInstancesClient from '../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import { SignInClient } from '../../api/clients/identity'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { LoginSection } from './LoginSection'

export const LoginPage = observer(() => {
	const history = useHistory()
	const location = useLocation()

	const { from } = (location.state as
		| undefined
		| { from: { pathname: string | undefined } }) || {
		from: { pathname: undefined },
	}

	const { providerInstanceId } = useParams<{ providerInstanceId?: string }>()

	const store = useLocalObservable(() => ({
		authMethod: undefined as AuthenticationMethod | undefined,
		organizationAuthMethods: undefined as AuthenticationMethod[] | undefined,
	}))

	const styles = useStyles()

	const authenticationClient = new SignInClient()

	useEffect(() => {
		fetchOrganizationAuthMethods()
	}, [])

	useEffect(() => {
		runInAction(() => {
			if (store.organizationAuthMethods === undefined) return

			// if there's a provider in the link use that one
			store.authMethod = store.organizationAuthMethods.find(
				(v) =>
					v.authenticationType === AuthenticationType.ExternalProvider &&
					v.authenticationProviderInstanceId === providerInstanceId,
			)

			// if the organization has a default provider & none in the link use the default!
			if (store.authMethod === undefined) {
				authenticationClient
					.getDefaultOrganizationAuthenticationMethod()
					.then((response) => {
						store.authMethod = response.data
					})
			}

			// this could be done in the other useEffect but this is more immediate and doesn't show our login screen at all
			if (store.authMethod !== undefined) handleOidcRedirect()
		})
	}, [store.organizationAuthMethods, providerInstanceId])

	useEffect(() => {
		if (store.authMethod === undefined) return

		handleOidcRedirect()
	}, [store.authMethod])

	// redirect to the external auth provider for user to input username/password
	const handleOidcRedirect = async () => {
		if (
			store.authMethod?.authenticationType !==
			AuthenticationType.ExternalProvider
		)
			return

		const authProviderInstancesClient =
			new AuthenticationProviderInstancesClient()

		const { data: authenticationUrl } =
			await authProviderInstancesClient.getAuthenticationUrl(
				store.authMethod.authenticationProviderInstanceId,
				`${window.location.origin}/signin-oidc/${store.authMethod.authenticationProviderInstanceId}`,
				from.pathname,
			)

		window.location.href = authenticationUrl
	}

	const fetchOrganizationAuthMethods = async () => {
		const { data: authMethods } =
			await authenticationClient.getOrganizationAuthenticationMethods()

		runInAction(() => {
			store.organizationAuthMethods = authMethods
		})
	}

	if (
		store.authMethod?.authenticationType === AuthenticationType.ExternalProvider
	)
		return <FullscreenSpinner />

	return (
		<div className={styles.wholePageLayout}>
			<div className={styles.entryManager}>
				<LoginSection
					onFetchedUserAuthMethod={action(
						(authMethod) => (store.authMethod = authMethod),
					)}
				/>
			</div>
		</div>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	entryManager: {
		display: 'flex',

		height: '100%',
		width: '100%',

		justifyContent: 'center',
		alignItems: 'center',
	},

	wholePageLayout: {
		display: 'flex',
		flexDirection: 'column',
		width: percent(100),
		height: percent(100),
	},
}))
