import { cloneDeep } from 'lodash'
import {
	AnnotationsMap,
	computed,
	isObservable,
	makeAutoObservable,
	observable,
} from 'mobx'
import { NIL, v4 as uuidv4 } from 'uuid'
import { IRepeatCellDefinition } from '../../../FormHost/Types/CellDefinition'
import { CellType } from '../../../FormHost/Types/CellType'
import FieldType, { isReferenceType } from '../../../FormHost/Types/FieldType'
import { FormBuilderCellDefinition } from '../FormBuilderCellDefinition'
import {
	FormBuilderCellInstance,
	IConcreteFormBuilderCellInstance,
	ILayoutFormBuilderCellInstance,
	INullFormBuilderCellInstance,
	IRepeatFormBuilderCellInstance,
	IValueFormBuilderCellInstance,
	IVirtualFormBuilderCellInstance,
	virtualValueKey,
} from '../FormBuilderCellInstance'
import {
	ConcreteElement,
	ElementTreeNode,
	LayoutElement,
	NullElement,
	RepeatElement,
	ValueElement,
	VirtualElement,
} from '../FormBuilderCellTree'

export type FormBuilderType = {
	cellDefinitions: FormBuilderCellDefinition[]
	readonly cellInstances: FormBuilderCellInstance[]
	rootNode: LayoutElement
	valueObject: object
}

export function initializeFormBuilder(
	definitions: FormBuilderCellDefinition[],
	valueObject?: object,
): FormBuilderType
export function initializeFormBuilder(
	definitions: FormBuilderCellDefinition[],
	valueObject: undefined,
	instanceArray: FormBuilderCellInstance[],
): FormBuilderType
export function initializeFormBuilder(
	definitions: FormBuilderCellDefinition[],
	valueObject: object | undefined,
	instanceArray: FormBuilderCellInstance[] | undefined,
): FormBuilderType
export function initializeFormBuilder(
	definitions: FormBuilderCellDefinition[],
	valueObject: object | undefined,
	instanceArray: FormBuilderCellInstance[] | undefined = undefined,
): FormBuilderType {
	if (valueObject && !isObservable(valueObject))
		throw new Error('value passed to create cell instances must be observable')

	/*
	we start with this object, we work through it and make sure that the structure mirrors the definitions correctly
	*/

	// TODO if instances are defined create the nodes first

	let instanceTreeRoot: LayoutElement | undefined

	if (instanceArray === undefined) {
		valueObject = createValueObjectFromDefinitions(
			definitions,
			NIL,
			valueObject,
		)

		instanceTreeRoot = __generateNode(
			_createRootInstance(valueObject),
			_rootDefinition,
		) as LayoutElement
		_createCellInstanceTree(definitions, valueObject, instanceTreeRoot, NIL)
	} else {
		valueObject ??= _createValueObject()
		instanceTreeRoot = __generateNode(
			_createRootInstance(valueObject),
			_rootDefinition,
		) as LayoutElement
		_createInstanceTreeFromInstanceArray(
			definitions,
			instanceArray,
			instanceTreeRoot,
		)
		_initializeValueObjectFromInstanceTree(instanceTreeRoot, valueObject, true)
	}

	return {
		cellDefinitions: definitions,
		valueObject,
		rootNode: instanceTreeRoot,
		get cellInstances() {
			return _generateInstancesArray(this.rootNode)
		},
	}
}

export function appendRepeatElement(
	formBuilder: FormBuilderType,
	node: RepeatElement,
) {
	const cell = node.cell

	const virtualValue = cell[virtualValueKey]

	if (!Array.isArray(virtualValue))
		throw new Error('virtual value for repeat cell was not an array')

	const definition = formBuilder.cellDefinitions.find(
		(v) => v.id === cell.definitionId,
	)
	if (definition === undefined)
		throw new Error('cell definition could not be found for append')
	if (definition.type !== CellType.Repeat)
		throw new Error(
			'cell definition type must be a repeat to append a repeat element',
		)

	_appendValueObjectArrayElement(
		definition,
		virtualValue,
		formBuilder.cellDefinitions,
	)

	_updateRepeatableSectionInstanceTree(
		formBuilder.cellDefinitions,
		virtualValue,
		definition,
		node,
	)

	// work through all the new instances and add them
	//  for(const addedNode of addedNodes)
	//   _initializeValueObjectFromInstanceTree(addedNode, virtualValue, true)
}

/**
 * create a value object from cell definitions
 * @param definitions the definitions that will be used to create a brand new value object
 */
function createValueObjectFromDefinitions(
	definitions: FormBuilderCellDefinition[],
): object
/**
 * This function is largely called internally and recursively, it can start
 * from a certain cell and start assigning from that position, all depending on
 * the parent definition id
 * @param definitions the definitions that will be used to create a value object
 * @param parentDefinitionId the parent definition where recursion will start from
 * @param valueObject the value object that has possibly been previously created
 */
function createValueObjectFromDefinitions(
	definitions: FormBuilderCellDefinition[],
	parentDefinitionId: string,
	valueObject: object | undefined,
): object
function createValueObjectFromDefinitions(
	definitions: FormBuilderCellDefinition[],
	parentDefinitionId: string = NIL,
	valueObject: object | undefined = undefined,
): object {
	// ! declare item
	function declareItem(
		definitions: FormBuilderCellDefinition[],
		valueObject: object,
		item: FormBuilderCellDefinition,
	) {
		const propsKey = _createPropsKey(item.name)

		/*
		step 1. Check to see if the value exists with the props key,
		we add that if not present
		 */
		if (!Reflect.has(valueObject, propsKey)) {
			const props = cloneDeep(item.properties)
			Reflect.set(
				valueObject,
				_createPropsKey(item.name),
				_createValueObject(props),
			)
		}

		/*
		step 2. Check to see if the property already exists, if it does check to 
		see if there will be any children. If there will be call this function 
		recursively
		 */
		if (Reflect.has(valueObject, item.name)) {
			if (item.type === CellType.Value) return
			else if (item.type === CellType.Layout) {
				const innerValueObject = Reflect.get(valueObject, item.name)

				if (innerValueObject !== undefined) {
					createValueObjectFromDefinitions(
						definitions,
						item.id,
						innerValueObject,
					)
				}
			} else if (item.type === CellType.Repeat) {
				const currentValue = Reflect.get(valueObject, item.name)

				if (currentValue !== undefined) {
					if (!Array.isArray(currentValue))
						throw new Error(
							'provided object indicated that an array should be present but value was not of array type. Received type: ' +
							typeof currentValue,
						)
					
					for (let i = 0; i < currentValue.length; i++) {
						if (!Reflect.has(currentValue, i))
							currentValue[i] = _createValueObject()
						createValueObjectFromDefinitions(
							definitions,
							item.id,
							currentValue[i],
						)
					}
				}
			}
		} else {
			/*
			step 3. No value type exists, obviously. So we have to generate the thing
			ourselves. Props object has already been set so we can focus on values only.
			*/
			if (item.type === CellType.Value) {
				Reflect.set(valueObject, item.name, item.value)
			}
			// value type is easy, layouts and repeats tho, recursion
			else if (item.type === CellType.Layout) {
				const newValueObject = _createValueObject()
				Reflect.set(valueObject, item.name, newValueObject)
				createValueObjectFromDefinitions(definitions, item.id, newValueObject)
			} else if (item.type === CellType.Repeat) {
				// this gets sorta complex with arrays
				const newArrayValueObject = _createValueObject([])
				Reflect.set(valueObject, item.name, newArrayValueObject)

				if (!Array.isArray(newArrayValueObject))
					throw new Error("I can't even.")

				for (let i = 0; i < item.defaultRepeatCount; i++) {
					_appendValueObjectArrayElement(item, newArrayValueObject, definitions)
				}
			}
		}
	}

	if (valueObject === undefined) valueObject = _createValueObject()

	if (!observable(valueObject))
		throw new Error('received non-observable element')

	const itemsToDeclare = definitions.filter(
		(v) => v.parentId === parentDefinitionId,
	)

	for (const item of itemsToDeclare) {
		declareItem(definitions, valueObject, item)
	}

	return valueObject
}

function _appendValueObjectArrayElement(
	repeatDefinition: IRepeatCellDefinition,
	valueObject: unknown[],
	definitions: FormBuilderCellDefinition[],
) {
	const newObject = _createValueObject()
	valueObject.push(newObject)

	createValueObjectFromDefinitions(definitions, repeatDefinition.id, newObject)
}

/*
root instance must be created prior to this function executing
*/
function _createInstanceTreeFromInstanceArray(
	definitions: FormBuilderCellDefinition[],
	cellInstances: FormBuilderCellInstance[],
	parentNode: LayoutElement | RepeatElement | VirtualElement,
) {
	for (const instance of cellInstances.filter(
		(v) => v.parentId === parentNode.cell.id,
	)) {
		/*
		No matter what after this line we're using the same instance of an object.
		If we don't have this line we end up with type issues
		*/
		const observableInstance = isObservable(instance)
			? instance
			: makeAutoObservable(instance)

		if (parentNode.cellType === CellType.Repeat) {
			if (observableInstance.type !== CellType.Virtual)
				throw new Error('non-virtual node appeared as child of repeat')

			const node = __generateNode(observableInstance)

			if (node.cellType !== CellType.Virtual)
				throw new Error('children of repeat must be a virtual node')

			parentNode.children.push(node)
		} else if (
			parentNode.cellType === CellType.Layout ||
			parentNode.cellType === CellType.Virtual
		) {
			if (observableInstance.type === CellType.Virtual)
				throw new Error(
					'children of layouts or virtual cells cannot be virtual cells',
				)

			const definition = definitions.find(
				(v) => v.id === observableInstance.definitionId,
			)
			if (definition === undefined)
				throw new Error('instance was not parented by definition')

			const node = __generateNode(observableInstance, definition)
			parentNode.children.push(node)
		}

		const lastAdded = parentNode.children[parentNode.children.length - 1]

		if (
			lastAdded.cellType === CellType.Layout ||
			lastAdded.cellType === CellType.Repeat ||
			lastAdded.cellType === CellType.Virtual
		)
			_createInstanceTreeFromInstanceArray(
				definitions,
				cellInstances,
				lastAdded,
			)
	}
}

function _initializeValueObjectFromInstanceTree(
	node: ElementTreeNode,
	valueObject: object,
	shouldSetFromInstances = true,
) {
	// null cells should not have properties or values so we can go ahead and exit
	if (node.cellType === CellType.Null)
		return

	if (node.cellType === CellType.Virtual) {
		if (!Array.isArray(valueObject))
			throw new Error('expected element to be array at this position')

		// TODO create array element
		let newValueObject = Reflect.get(valueObject, node.cell.ordinalPosition)
		if (newValueObject === undefined) {
			newValueObject = _createValueObject()
			valueObject.push(newValueObject)
		}

		for (const child of node.children) {
			_initializeValueObjectFromInstanceTree(child, newValueObject)
		}

		return
	}

	/*
	Assign instance to track props key if necessary
	*/
	const propsKey = _createPropsKey(node.cell.name)
	if (
		Reflect.get(valueObject, propsKey) === undefined &&
		!shouldSetFromInstances
	)
		throw new Error(
			'no property was present for instance to attach to and cannot be set by instances',
		)
	if (!Reflect.has(valueObject, propsKey)) {
		if (node.cell.id !== NIL)
			// <- avoid setting props for the root instance
			Reflect.set(valueObject, propsKey, node.cell.properties)
	}

	__assignInstancePropertyHandler(node.cell, valueObject)

	if (node.cellType === CellType.Concrete) {
		return
	} else if (node.cellType === CellType.Layout) {
		// we have the root instance, that's different
		if (node.cell.id === NIL && node.cell.parentId === NIL) {
			for (const child of node.children)
				_initializeValueObjectFromInstanceTree(child, valueObject)
			return
		}

		let innerValueObject: object | undefined = {}
		if (Reflect.has(valueObject, node.cell.name)) {
			innerValueObject = Reflect.get(valueObject, node.cell.name)
			if (innerValueObject === undefined)
				throw new Error(
					'value property indicated present but was undefined or null',
				)
		} else if (shouldSetFromInstances) {
			innerValueObject = _createValueObject()
			Reflect.set(valueObject, node.cell.name, innerValueObject)
		} else {
			throw new Error(
				'object for layout to attach to was not previously present and cannot be set by instances',
			)
		}

		for (const child of node.children) {
			_initializeValueObjectFromInstanceTree(child, innerValueObject)
		}
	} else if (node.cellType === CellType.Repeat) {
		let innerValueObject: object | undefined = {}
		if (Reflect.has(valueObject, node.cell.name)) {
			innerValueObject = Reflect.get(valueObject, node.cell.name)
			if (innerValueObject === undefined)
				throw new Error(
					'value property indicated present but was undefined or null',
				)
			if (!Array.isArray(innerValueObject))
				throw new Error('value object property should have been array')
		} else if (shouldSetFromInstances) {
			// note the [] here
			innerValueObject = _createValueObject([])
			Reflect.set(valueObject, node.cell.name, innerValueObject)
		} else {
			throw new Error(
				'array for repeat to attach to was not previously present and cannot be set by instances',
			)
		}

		for (const child of node.children) {
			_initializeValueObjectFromInstanceTree(child, innerValueObject)
		}
	} else if (node.cellType === CellType.Value) {
		if (!shouldSetFromInstances && Reflect.get(valueObject, node.cell.name))
			throw new Error(
				'value for value cell to attach to was not previously present and cannot be set by instances',
			)

		Reflect.set(valueObject, node.cell.name, node.cell.value)
	}

	// lastly assign the value handler
	__assignInstanceValueHandler(node.cell, valueObject)
}

function __assignInstancePropertyHandler(
	instance: FormBuilderCellInstance,
	valueObject: object,
) {
	if (instance.type === CellType.Virtual || instance.type === CellType.Null)
		throw new Error(`cannot create getter/setter for virtual or null instance, got type: '${instance.type}'`)

	const propsKey = _createPropsKey(instance.name)

	Object.defineProperty(instance, 'properties', {
		get: () => Reflect.get(valueObject, propsKey),
		set: (v) => Reflect.set(valueObject, propsKey, v),
		enumerable: true,
		configurable: true,
	})
}

function __assignInstanceValueHandler(
	instance: FormBuilderCellInstance,
	valueObject: object,
) {
	if (instance.type === CellType.Virtual || instance.type === CellType.Null)
		throw new Error(`cannot create getter/setter for virtual or null instance, got type: '${instance.type}'`)

	const valueKey = instance.type === CellType.Value ? 'value' : virtualValueKey

	Object.defineProperty(instance, valueKey, {
		get: () => Reflect.get(valueObject, instance.name),
		set: (v) => Reflect.set(valueObject, instance.name, v),
		enumerable: true,
		configurable: true,
	})
}

const _rootDefinition: FormBuilderCellDefinition = {
	elementTag: NIL,
	properties: {},
	id: NIL,
	name: '',
	ordinalPosition: 0,
	parentId: NIL,
	fieldType: FieldType.None,
	type: CellType.Layout,
}

function _createRootInstance(valueObject: object) {
	const rootInstance: FormBuilderCellInstance = {
		id: NIL,
		ordinalPosition: 0,
		parentId: NIL,
		fieldType: FieldType.None,
		type: CellType.Layout,
		properties: {},
		elementTag: NIL,
		name: '',
		definitionId: NIL,
		[virtualValueKey]: valueObject,
	}

	return rootInstance
}

function _createCellInstanceTree(
	definitions: FormBuilderCellDefinition[],
	valueObject: object,
	elementTreeNode: ElementTreeNode,
	parentDefinitionId: string = NIL,
) {
	const parentDefinition =
		parentDefinitionId !== NIL
			? definitions.find((v) => v.id === parentDefinitionId)
			: _rootDefinition

	if (parentDefinition === undefined)
		throw new Error(
			`parent definition could not be resolved with id ${parentDefinitionId}`,
		)

	if (parentDefinitionId === NIL) {
		if (elementTreeNode.cellType !== CellType.Layout)
			throw new Error('invalid structure')
		_updateLayoutSectionContents(
			definitions,
			valueObject,
			parentDefinition,
			elementTreeNode,
		)
	} else if (parentDefinition.fieldType === FieldType.Object) {
		if (parentDefinition.type !== CellType.Layout)
			throw new Error('invalid cell type for layout')
		if (elementTreeNode.cellType !== CellType.Layout)
			throw new Error('invalid structure')

		_updateLayoutSectionContents(
			definitions,
			valueObject,
			parentDefinition,
			elementTreeNode,
		)
	} else if (parentDefinition.fieldType === FieldType.ObjectArray) {
		if (parentDefinition.type !== CellType.Repeat)
			throw new Error('invalid cell type for repeatable section')
		if (elementTreeNode.cellType !== CellType.Repeat)
			throw new Error('invalid structure')

		_updateRepeatableSectionInstanceTree(
			definitions,
			valueObject,
			parentDefinition,
			elementTreeNode,
		)
	} else {
		// how'd we end up here?
	}
}

function _updateLayoutSectionContents(
	definitions: FormBuilderCellDefinition[],
	valueObject: object,
	parentDefinition: FormBuilderCellDefinition,
	parentNode: LayoutElement,
) {
	const childDefinitions = definitions
		.filter((v) => v.parentId === parentDefinition.id)
		.sort((v1, v2) => v1.ordinalPosition - v2.ordinalPosition)

	for (const currentDefinition of childDefinitions) {
		const newInstance = _createCellInstance(
			currentDefinition,
			valueObject,
			parentNode.cell.id,
		)

		// Null cells don't have names and cannot be in our value object.
		// They will never have properties or values anyways, so no need
		// to set these. Setting these breaks things in the FB structure.
		if (newInstance.type !== CellType.Null) {
			__assignInstancePropertyHandler(newInstance, valueObject)
			__assignInstanceValueHandler(newInstance, valueObject)
		}
		const newNode = __generateNode(newInstance, currentDefinition)

		parentNode.children.push(newNode)

		if (isReferenceType(newInstance.fieldType)) {
			// scope in
			const tempValueObject = Reflect.get(valueObject, newInstance.name)

			_createCellInstanceTree(
				definitions,
				tempValueObject,
				newNode,
				currentDefinition.id,
			)
		}
	}
}

function _updateRepeatableSectionInstanceTree(
	definitions: FormBuilderCellDefinition[],
	valueObject: object,
	repeatDefinition: FormBuilderCellDefinition,
	repeatNode: RepeatElement,
): VirtualElement[] {
	if (!Array.isArray(valueObject))
		throw new Error('value object should be array at this position')

	const added: VirtualElement[] = []

	function __injectRepeatableSection(position: number): VirtualElement {
		const arrayIndexValueElement = Reflect.get(valueObject, position)

		const virtualCell: FormBuilderCellInstance = {
			type: CellType.Virtual,
			id: uuidv4(),
			parentId: repeatNode.cell.id,
			fieldType: FieldType.Virtual,
			ordinalPosition: position,
		}

		// !warn
		const virtualNode = __generateNode(virtualCell) as VirtualElement

		repeatNode.children.push(virtualNode)

		const targetDefinitions = definitions
			.filter((v) => v.parentId === repeatDefinition.id)
			.sort((a, b) => a.ordinalPosition - b.ordinalPosition)

		for (const currentDefinition of targetDefinitions) {
			// this is the child of the virtual cell, so in something like person,
			// this is first name
			const repeatChildInstance = _createCellInstance(
				currentDefinition,
				arrayIndexValueElement,
				virtualCell.id,
			)

			// null cells don't have names and cannot be added to the value object!
			// the form builder will break if we set these for null cells
			if (repeatChildInstance.type !== CellType.Null) {
				__assignInstancePropertyHandler(
					repeatChildInstance,
					arrayIndexValueElement,
				)
				__assignInstanceValueHandler(repeatChildInstance, arrayIndexValueElement)
			}

			// yes the name sucks
			const childOfVirtualNodeNode = __generateNode(
				repeatChildInstance,
				currentDefinition,
			)

			virtualNode.children.push(childOfVirtualNodeNode)

			if (isReferenceType(repeatChildInstance.fieldType)) {
				if (
					repeatChildInstance.type !== CellType.Repeat &&
					repeatChildInstance.type !== CellType.Layout
				)
					throw new Error('reference types must be repeats or layouts')
				const virtualValue = repeatChildInstance[virtualValueKey] as
					| object
					| object[]

				_createCellInstanceTree(
					definitions,
					virtualValue,
					childOfVirtualNodeNode,
					currentDefinition.id,
				)
			}
		}
		return virtualNode
	}

	if (repeatDefinition.type !== CellType.Repeat)
		throw new Error('invalid cell type for repeatable section')

	const originalOrdinalPosition = repeatNode.children.length

	if (!Array.isArray(repeatNode.cell[virtualValueKey]))
		throw new Error('repeat type was not assigned an array')

	const parentInstanceArray = repeatNode.cell[virtualValueKey]

	for (let i = originalOrdinalPosition; i < parentInstanceArray.length; i++) {
		const newNode = __injectRepeatableSection(i)
		added.push(newNode)
	}

	return added
}

function __generateNode(
	instance: IVirtualFormBuilderCellInstance,
): VirtualElement
function __generateNode(
	instance: Exclude<FormBuilderCellInstance, IVirtualFormBuilderCellInstance>,
	definition: FormBuilderCellDefinition,
): Exclude<ElementTreeNode, VirtualElement>
function __generateNode(
	instance: FormBuilderCellInstance,
	definition: FormBuilderCellDefinition | undefined = undefined,
): ElementTreeNode {
	if (instance.type === CellType.Concrete) {
		if (!definition || definition.type !== CellType.Concrete)
			throw new Error('cell type mismatch')
		const v: ConcreteElement = {
			cellType: instance.type,
			cell: instance,
			definition,
		}
		return makeAutoObservable(v)
	} else if (instance.type === CellType.Layout) {
		if (!definition || definition.type !== CellType.Layout)
			throw new Error('cell type mismatch')
		const v: LayoutElement = {
			cellType: instance.type,
			cell: instance,
			definition,
			children: [],
		}
		return makeAutoObservable(v)
	} else if (instance.type === CellType.Repeat) {
		if (!definition || definition.type !== CellType.Repeat)
			throw new Error('cell type mismatch')
		const v: RepeatElement = {
			cellType: instance.type,
			cell: instance,
			definition,
			children: [],
		}
		return makeAutoObservable(v)
	} else if (instance.type === CellType.Value) {
		if (!definition || definition.type !== CellType.Value)
			throw new Error('cell type mismatch')
		const v: ValueElement = {
			cellType: instance.type,
			cell: instance,
			definition,
		}
		return makeAutoObservable(v)
	}
	else if (instance.type === CellType.Null) {
		if (!definition || definition.type !== CellType.Null) {
			throw new Error('cell type mismatch')
		}
		const v: NullElement = {
			cellType: instance.type,
			cell: instance,
			definition
		}
		return makeAutoObservable(v)
	}
	else {
		const v: VirtualElement = {
			cellType: instance.type,
			cell: instance,
			children: [],
		}
		return makeAutoObservable(v)
	}
}

function _createCellInstance(
	cell: FormBuilderCellDefinition,
	valueObject: object,
	parentInstanceId: string,
):
	| ILayoutFormBuilderCellInstance
	| IRepeatFormBuilderCellInstance
	| IValueFormBuilderCellInstance
	| IConcreteFormBuilderCellInstance
	| INullFormBuilderCellInstance {
	function __createCellInstanceObject(
		cellType:
			| CellType.Concrete
			| CellType.Layout
			| CellType.Repeat
			| CellType.Value
			| CellType.Null,
	) {
		const newCellInstance = {
			type: cellType,
			id: uuidv4(),
			ordinalPosition: cell.ordinalPosition,
			parentId: parentInstanceId,
			fieldType: cell.fieldType,
			definitionId: cell.id,
			elementTag: cell.elementTag,
			name: cell.name,
		}

		// null cells can't be added to the value object
		// so go ahead and return the cell instance
		if (cellType === CellType.Null)
			return makeAutoObservable(newCellInstance)

		Object.defineProperty(newCellInstance, 'properties', {
			get: () =>
				Reflect.get(valueObject, _createPropsKey(newCellInstance.name)),
			set: (v) =>
				Reflect.set(valueObject, _createPropsKey(newCellInstance.name), v),
			enumerable: true,
			configurable: true,
		})

		let annotationsMap: AnnotationsMap<typeof newCellInstance, never> = {}

		if (cellType === CellType.Value) {
			Object.defineProperty(newCellInstance, 'value', {
				get() {
					return Reflect.get(valueObject, cell.name)
				},
				set(v) {
					Reflect.set(valueObject, cell.name, v)
				},
				enumerable: true,
				configurable: true,
			})

			const intermediateAnnotationsMap: AnnotationsMap<
				typeof newCellInstance,
				'value'
			> = {
				value: computed,
			}
			annotationsMap = intermediateAnnotationsMap
		}
		if (cellType === CellType.Layout || cellType === CellType.Repeat) {
			Object.defineProperty(newCellInstance, 'virtualValue', {
				get() {
					return Reflect.get(valueObject, cell.name)
				},
				set(v) {
					Reflect.set(valueObject, cell.name, v)
				},
				enumerable: true,
				configurable: true,
			})

			const intermediateAnnotationsMap: AnnotationsMap<
				typeof newCellInstance,
				'virtualValue'
			> = {
				virtualValue: computed,
			}
			annotationsMap = intermediateAnnotationsMap
		}

		try {
			return makeAutoObservable(newCellInstance, annotationsMap)
		} catch (e) {
			console.error(e)
		}
	}

	if (cell.fieldType === FieldType.None) {
		return __createCellInstanceObject(CellType.Null) as INullFormBuilderCellInstance
	} else if (cell.fieldType === FieldType.Object) {
		if (cell.type !== CellType.Layout)
			throw new Error('invalid cell type for layout')

		return __createCellInstanceObject(
			CellType.Layout,
		) as ILayoutFormBuilderCellInstance
	} else if (cell.fieldType === FieldType.ObjectArray) {
		if (cell.type !== CellType.Repeat)
			throw new Error('invalid cell type for repeatable section')

		return __createCellInstanceObject(
			CellType.Repeat,
		) as IRepeatFormBuilderCellInstance
	} else if (cell.fieldType === FieldType.Void) {
		return __createCellInstanceObject(
			CellType.Concrete,
		) as IConcreteFormBuilderCellInstance
	} else if (cell.type !== CellType.Value) {
		throw new Error(
			`provided cell type ${cell.fieldType} does not correspond with cell structure`,
		)
	}

	return __createCellInstanceObject(
		CellType.Value,
	) as IValueFormBuilderCellInstance
}

function _generateInstancesArray(
	node: ElementTreeNode,
	instances: FormBuilderCellInstance[] = [],
) {
	if (node.cell.id !== NIL) instances.push(node.cell)

	if (
		node.cellType === CellType.Layout ||
		node.cellType === CellType.Repeat ||
		node.cellType === CellType.Virtual
	) {
		for (const child of node.children) {
			_generateInstancesArray(child, instances)
		}
	}

	return instances
}

function _createValueObject(obj = {}) {
	return observable(obj, undefined, {
		deep: true,
	})
}

function _createPropsKey(name: string) {
	return `${name}:$props`
}
