import { NIL } from 'uuid'
import {
	AlertDialog,
	DialogChoice,
} from '../../../components/dialogs/AlertDialog'
import { TrackedResource } from '../../../pages/PackageHostPage/Attachments/AttachFilesDialog'
import { AttachmentApi } from '../../../pages/PackageHostPage/Attachments/AttachmentsApi'
import { RegistrationError } from '../../../pages/PackageHostPage/PackageHostPageContext'
import { ModalServiceType } from '../../../services/notifications/ModalService'
import {
	getOrganizationPropertyValueByName,
	queryDataProviderById,
	queryDataProviderByName,
} from '../../../utils/controlApi/ControlApiAccessors'
import { EventBus } from '../../FormBuilderCore/eventBus/EventBus'
import { changeEventEmitter } from '../../FormBuilderInterop/EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../FormBuilderInterop/EventBus/BuiltInEvents/FocusEvent'
import { IFormHost } from '../../FormHost/FormHost/IFormHost'
import { CellType } from '../../FormHost/Types/CellType'
import { isValueType } from '../../FormHost/Types/FieldType'
import { FormControlApi } from '../../FormHost/Types/FormControlApi'
import {
	addValidationError,
	removeValidationError,
} from '../../FormHost/Utilities/FieldValidationUtilities'
import {
	generateElementTree,
	refreshInstanceCollection,
} from './Internals/CellManagement'
import {
	HTMLBooleanInputAdapter,
	HTMLInputAdapter,
	HTMLSelectAdapter,
	HTMLVirtualRadioAdapter,
	IHTMLAdapter,
} from './Internals/HtmlAdapters'
import { HtmlCellDefinition } from './Types/HtmlCellDefinition'
import {
	HtmlCellInstance,
	IValueHtmlCellInstance,
} from './Types/HtmlCellInstance'

export class HtmlFormManager implements IFormHost {
	public formId = 0

	public cellDefinitions: HtmlCellDefinition[] = []
	public cellInstances: HtmlCellInstance[] = []

	public eventBus: EventBus

	private _document: Document

	private _mutationObserver: MutationObserver

	/* the controlApi in IFormHost has onFormLoaded, onBeforeSubmit, and 
	   onValidationStateChange as optional properties but we require them 
		 here so HTML forms can hook into them */
	public controlApi: Required<FormControlApi>

	constructor(
		definitions: HtmlCellDefinition[],
		instances: HtmlCellInstance[],
		eventBus: EventBus,
		document: Document,
		instanceErrors: Record<string, RegistrationError[]>,
		formName: string,
		attachmentsApi: AttachmentApi,
		modalsApi: ModalServiceType,
	) {
		this.cellDefinitions = definitions
		this.cellInstances = instances
		this.eventBus = eventBus
		this._document = document

		const elementTree = generateElementTree(this.cellDefinitions)

		// populate the root of the HTML form
		refreshInstanceCollection(
			this._document.body,
			elementTree,
			this.cellDefinitions,
			NIL,
			this.cellInstances,
		)

		// refresh instance collection if nodes are added to/removed from DOM
		this._mutationObserver = new MutationObserver(function () {
			refreshInstanceCollection(
				document.body,
				elementTree,
				definitions,
				NIL,
				instances,
			)
		})

		const htmlElement = this._document.getElementsByTagName('html')[0]
		this.addEventListeners(htmlElement)

		this._mutationObserver.observe(htmlElement, {
			childList: true,
			subtree: true,
		})

		this.controlApi = {
			onBeforeSubmit: () => {
				// defined in the html form
				return { canSubmit: true }
			},
			onFormLoaded: () => {
				// defined in the html form
				return undefined
			},
			dialogs: {
				createAlertDialog: async (
					header: string,
					description: string,
					choices: DialogChoice[],
				): Promise<string> => {
					const result = await modalsApi.showForm((props) => (
						<AlertDialog
							header={header}
							description={description}
							choices={choices}
							onSelectValue={(v) =>
								props.close({ closeResult: 'okay', value: v })
							}
						/>
					))

					return result.value as string
				},
			},
			dataProviders: {
				queryById: async (
					instanceId: number,
					parameters: Record<string, string>,
				) => await queryDataProviderById(instanceId, parameters),
				queryByName: async (name: string, parameters: Record<string, string>) =>
					await queryDataProviderByName(name, parameters),
			},
			organizationProperties: {
				getPropertyValueByName: async (propertyName: string) =>
					await getOrganizationPropertyValueByName(propertyName),
			},
			setCustomError: (
				instanceId: string,
				registrationId: string,
				errorText: string,
			) => {
				if (errorText === '') return

				addValidationError(
					instanceErrors,
					formName,
					instanceId, // b/c the name is the same as the instance id in html :)
					instanceId,
					registrationId,
					errorText,
				)
			},
			clearCustomError: (instanceId: string, registrationId: string) => {
				removeValidationError(instanceErrors, instanceId, registrationId)
			},
			onValidationStateChange: (instanceId: string, errorText: string) => {
				// defined in the html form
			},
			attachments: {
				onAttachmentsAdded: (attachments: TrackedResource[]) => {
					// defined in html form
					// called when user adds attachments using our attachment button
				},
				onAttachmentRemoved: (resourceId: string) => {
					// also defined in html form
					// called when user removed an attachment using our dialog
				},
				setRequiredAttachmentType: (attachmentTypeName: string) => {
					attachmentsApi.setRequiredAttachmentType(attachmentTypeName)
				},
				requestAttachments: (
					attachmentTypeName?: string,
				): Promise<TrackedResource[]> => {
					return attachmentsApi.requestAttachments(attachmentTypeName)
				},
				removeAttachment: (fileName: string): Promise<void> => {
					attachmentsApi.removeAttachment(fileName)
					// also defined in PackageHostFooter
					return Promise.resolve()
				},
			},
		}
	}

	/**
	 * set the cell instance values to the saved form package's cell instance values
	 */
	public setSavedInstanceValues = () => {
		for (const instance of this.cellInstances) {
			if (instance.type !== CellType.Value || instance.value === null) continue

			for (const adapter of adapters) {
				if (!adapter.canRead(instance, this._document)) continue

				adapter.write(instance, this._document, instance.value)
			}
		}
	}

	dispose(): void {
		this._mutationObserver.disconnect()
	}

	private addEventListeners(htmlElement: HTMLElement) {
		htmlElement.addEventListener('input', (evt) => {
			const target: HTMLElement = evt.target as HTMLElement

			let identifier = target.id

			// radios are different - there is one instance for each radio, with id being the radio's name
			// each option has a different id, which doesn't match the radio as a whole so we need the name
			if (target.getAttribute('type')?.toLowerCase() === 'radio') {
				const radioName = target.getAttribute('name')
				if (radioName === null) return

				identifier = radioName
			}

			const instance = this.cellInstances
				.filter((v) => isValueType(v.fieldType))
				.find((v) => v.id === identifier)

			if (instance === undefined) return

			const valueInstance = instance as IValueHtmlCellInstance

			for (const adapter of adapters) {
				if (!adapter.canRead(instance, this._document)) continue

				const value = adapter.read(instance, this._document)

				if (value !== valueInstance.value) {
					changeEventEmitter(
						this.eventBus,
						{
							oldValue: valueInstance.value,
							newValue: value,
						},
						{
							formHost: this,
							elementTag: NIL,
							definitionId: valueInstance.definitionId,
							instanceId: valueInstance.id,
						},
					)

					valueInstance.value = value
				}

				// we don't want to check the other adapters
				break
			}
		})

		htmlElement.addEventListener('focusout', (evt) => {
			const target = evt.target as HTMLElement

			const instance = this.cellInstances
				.filter((v) => isValueType(v.fieldType))
				.find((v) => v.id === target.id)
			if (instance === undefined) return

			const valueInstance = instance as IValueHtmlCellInstance

			focusEventEmitter(
				this.eventBus,
				{
					focusType: 'FocusLost',
				},
				{
					formHost: this,
					elementTag: NIL,
					definitionId: valueInstance.definitionId,
					instanceId: valueInstance.id,
				},
			)
		})
	}
}

/* 
this is all of the adapters that have been configured and are able to scan 
the HTML DOM, the order matters, as the first one that is able to read a 
particular type of element will be the one that extracts the value, no
matter what. */
export const adapters: Readonly<IHTMLAdapter[]> = [
	new HTMLVirtualRadioAdapter(),
	new HTMLBooleanInputAdapter(),
	new HTMLInputAdapter(),
	new HTMLSelectAdapter(),
]
