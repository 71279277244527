import { ExpressionStatement, Node } from 'estree'
import { observer } from 'mobx-react'
import { ASTNodeComponents, RendererComponent } from '../../../Types'

const ExpressionStatementComponent = observer(
	(props: RendererComponent<ExpressionStatement>) => {
		if (props.node.expression === undefined) return <></>

		const ExpressionComponent = ASTNodeComponents[
			props.node.expression?.type
		] as React.ComponentType<RendererComponent<Node>>

		if (ExpressionComponent === undefined)
			throw new Error(
				'could not resolve expression component for type ' +
					props.node.expression?.type,
			)

		return <ExpressionComponent node={props.node.expression} />
	},
)

export default ExpressionStatementComponent
