import React, { createContext, useContext } from 'react'
import {
	ComponentRegistration,
	ConfigurableComponentRegistration,
	ConfigurableContainerComponentRegistration,
} from './ComponentTypes'

const globalComponentRegistryContext =
	createContext<GlobalComponentRegistryType>({
		componentCollection: {},
	})

export type ComponentRegistryDictionary = Record<string, ComponentRegistration>

export type GlobalComponentRegistryType = {
	componentCollection: ComponentRegistryDictionary
}

export const GlobalComponentRegistryProvider = (
	props: React.PropsWithChildren<GlobalComponentRegistryType>,
) => {
	return (
		<globalComponentRegistryContext.Provider value={{ ...props }}>
			{props.children}
		</globalComponentRegistryContext.Provider>
	)
}

export const useGlobalComponentRegistry = () => {
	const context = useContext(globalComponentRegistryContext)
	if (context === undefined)
		throw new Error(
			'no context provider has been registered for the global component registry',
		)
	return context
}

export function fetchComponents(
	collection: ComponentRegistryDictionary,
): ComponentRegistration[] {
	return Object.keys(collection).map((key) => collection[key])
}

export function fetchComponent(
	collection: ComponentRegistryDictionary,
	id: string,
) {
	const component = collection[id]
	if (component === undefined)
		throw Error(`no component with id ${id} registered`)

	return component
}

export function fetchConfigurableComponent(
	collection: ComponentRegistryDictionary,
	id: string,
) {
	const component = fetchComponent(collection, id)
	const configurableComponent = component as ConfigurableComponentRegistration<
		Record<string, unknown>
	>
	if (
		configurableComponent.propertiesConfigurator === undefined ||
		configurableComponent.displayName === undefined
	)
		throw Error(`component ${id} is not of type configurable component`)
	return configurableComponent
}

export function fetchContainerComponent(
	collection: ComponentRegistryDictionary,
	id: string,
) {
	const component = fetchConfigurableComponent(collection, id)
	const configurableComponent =
		component as ConfigurableContainerComponentRegistration
	if (configurableComponent.requiredChildren === undefined)
		throw Error(`component ${id} is not of type container component`)
	return configurableComponent
}
