import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyValueTypeComponent,
	ValuesEditorComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	CheckboxPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { Checkbox, CheckboxElementTag } from './InternalCheckboxDefinition'

const checkboxId = '0a57c519-8903-4c76-b5df-437f020613f0'

const CheckboxPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof Checkbox>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
		</>
	)
}

const CheckboxValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof Checkbox>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={CheckboxPropertyConfigurator}
		label="Default Value"
	/>
)

export const CheckboxDefinition = specifyValueTypeComponent(
	checkboxId,
	Checkbox,
	CheckboxElementTag,
	'Checkbox',
	FieldType.Boolean,
	{
		configurator: CheckboxPropsEditor,
		defaultProperties: {
			label: 'Label',
		},
	},
	{
		configurator: CheckboxValueEditor,
		defaultValue: false,
	},
)
