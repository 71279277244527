import { cloneDeep } from 'lodash'
import { makeAutoObservable, observable } from 'mobx'
import { NIL, v4 as uuidv4 } from 'uuid'
import { IFormBuilderFormHost } from '../../../FormBuilderCore/IFormBuilderFormHost'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { ConfigurableValueComponentRegistration } from '../../../FormBuilderCore/cells/ComponentTypes'
import { FormBuilderCellDefinition } from '../../../FormBuilderCore/cells/FormBuilderCellDefinition'
import {
	FormBuilderCellInstance
} from '../../../FormBuilderCore/cells/FormBuilderCellInstance'
import { LayoutElement } from '../../../FormBuilderCore/cells/FormBuilderCellTree'
import {
	ComponentRegistryDictionary,
	fetchConfigurableComponent,
} from '../../../FormBuilderCore/cells/GlobalComponentRegistry'
import {
	FormBuilderType,
	initializeFormBuilder,
} from '../../../FormBuilderCore/cells/rendering/CellManager'
import { EventBus } from '../../../FormBuilderCore/eventBus/EventBus'
import { INonVirtualCellInstance } from '../../../FormHost/Types/CellInstance'
import { CellType } from '../../../FormHost/Types/CellType'
import FieldType, { isValueType } from '../../../FormHost/Types/FieldType'

export class DraggableFormHost implements IFormBuilderFormHost {
	readonly formId: number = 0

	private _componentRegistry: ComponentRegistryDictionary

	private _schema: FormBuilderSchema

	public get cellDefinitions(): FormBuilderCellDefinition[] {
		return this._schema.definitions
	}

	public get formBuilderObject(): FormBuilderType {
		return initializeFormBuilder(this.cellDefinitions)
	}

	public get cellInstances(): FormBuilderCellInstance[] {
		return this.formBuilderObject.cellInstances
	}

	public valueObject: object

	public get rootNode(): LayoutElement {
		return this.formBuilderObject.rootNode
	}

	readonly eventBus: EventBus
	readonly isReadonly = false

	private onChange: (v: FormBuilderSchema) => void

	constructor(
		componentRegistry: ComponentRegistryDictionary,
		schema: FormBuilderSchema,
		onChange: (v: FormBuilderSchema) => void,
	) {
		this._componentRegistry = componentRegistry
		this._schema = schema
		this.onChange = onChange

		this.valueObject = observable({})

		this.eventBus = new EventBus()

		makeAutoObservable(this)
	}

	dispose(): void {
		// unused
	}

	public swapCells(fromDefinitionId: string, toDefinitionId: string): void {
		console.log('swapCells')

		const fromDefinition = this.cellDefinitions.find(
			(v) => v.id === fromDefinitionId,
		) as FormBuilderCellDefinition | undefined
		const toDefinition = this.cellDefinitions.find(
			(v) => v.id === toDefinitionId,
		) as FormBuilderCellDefinition | undefined

		if (fromDefinition === undefined)
			throw `could not find source definition ${fromDefinitionId}`

		if (toDefinition === undefined)
			throw `could not find destination definition ${toDefinitionId}`

		const tempOrdinalPosition = toDefinition.ordinalPosition
		const tempParentId = toDefinition.parentId

		toDefinition.ordinalPosition = fromDefinition.ordinalPosition
		toDefinition.parentId = fromDefinition.parentId

		fromDefinition.ordinalPosition = tempOrdinalPosition
		fromDefinition.parentId = tempParentId

		if (fromDefinition.fieldType === FieldType.None)
			this.deleteDefinition(fromDefinition.id)

		this.onChange(this._schema)
	}

	/* this is used when the definition should remain but the component should be swapped */
	public swapComponent(
		targetDefinitionId: string,
		newElementTag: string,
	): void {
		console.log('swapComponent')

		const targetDefinition = this.cellDefinitions.find(
			(v) => v.id === targetDefinitionId,
		)
		const component = fetchConfigurableComponent(
			this._componentRegistry,
			newElementTag,
		)

		if (targetDefinition === undefined)
			throw `could not find target definition ${targetDefinitionId}`
		if (component === undefined)
			throw `could not find component with id ${newElementTag}`

		targetDefinition.elementTag = newElementTag
		targetDefinition.properties = cloneDeep(component.defaultProperties)
		targetDefinition.fieldType = component.fieldType
		targetDefinition.name = component.displayName

		targetDefinition.type = component.type

		if (targetDefinition.type === CellType.Value) {
			const configurableValueComponent =
				component as ConfigurableValueComponentRegistration<
					Record<string, unknown>
				>
			targetDefinition.value = configurableValueComponent.defaultValue
		}

		// this needs to be set, otherwise we just get the "AddRecord button"
		if (targetDefinition.type === CellType.Repeat && targetDefinition.defaultRepeatCount === undefined) {
			targetDefinition.defaultRepeatCount = 1
		}

		const newDefinitionArray = clearDescendants(
			targetDefinitionId,
			this.cellDefinitions,
		)

		console.log(JSON.stringify(targetDefinition, null, 2))
		console.log(JSON.stringify(this.cellDefinitions, null, 2))

		this._schema.definitions = newDefinitionArray
		this.onChange(this._schema)
	}

	public createDefinition(elementTag: string, currentCellId: string): void {
		console.log('createDefinition')

		const component = fetchConfigurableComponent(
			this._componentRegistry,
			elementTag,
		)

		const currentCell = this.cellInstances.find(
			(v) => v.id === currentCellId,
		) as INonVirtualCellInstance
		if (currentCell === undefined)
			throw `could not find a cell instance with ${currentCellId}`

		const definition = this.cellDefinitions.find(
			(v) => v.id === currentCell.definitionId,
		)
		if (definition === undefined)
			throw `could not find definition for cell ${currentCell.id}, target ${currentCell.definitionId}`

		definition.fieldType = component.fieldType
		definition.elementTag = elementTag
		definition.properties = cloneDeep(component.defaultProperties)
		definition.name = component.displayName
		definition.type = component.type

		if (definition.type === CellType.Value) {
			const configurableValueComponent =
				component as ConfigurableValueComponentRegistration<
					Record<string, unknown>
				>
			definition.value = configurableValueComponent.defaultValue
		}

		// this needs to be set, otherwise we only get the "Add Record" button
		if (definition.type === CellType.Repeat && definition.defaultRepeatCount === undefined) {
			definition.defaultRepeatCount = 1
		}

		this.onChange(this._schema)
	}

	public insertDefinition(
		parentDefinitionId: string,
		definitionId: string,
		targetPosition: number,
	): void {
		console.log(`insertDefinition ${definitionId}`)

		const currentDefinition = this.cellDefinitions.find(
			(v) => v.id === definitionId,
		)
		if (currentDefinition === undefined)
			throw `could not find a definition with id ${definitionId}`

		currentDefinition.parentId = parentDefinitionId
		currentDefinition.ordinalPosition = targetPosition

		this.insert(parentDefinitionId, currentDefinition)
	}

	public insertComponent(
		parentDefinitionId: string,
		elementTag: string,
		targetPosition: number,
	): void {
		console.log('insertComponent')

		const targetComponent = fetchConfigurableComponent(
			this._componentRegistry,
			elementTag,
		)
		if (targetComponent === undefined)
			throw `no component registered with id ${elementTag}`

		/* Get the number of existing components of this type so we can name it.
			 Name the component the "Input-1", etc. when added so they have unique names.
			 This is based on the number of existing components, bc if we do ordinal position
			 and put a new component in at the same ordinal position we'll end up with 2
			 components with the same name. */
		const existingComponentCount =
			this._schema.definitions.filter(v => v.elementTag === elementTag).length

		const baseDefinition = {
			elementTag: elementTag,
			properties: observable(targetComponent.defaultProperties),
			id: uuidv4(),
			name: `${targetComponent.displayName.replace(' ', '-')}-${existingComponentCount + 1}`,
			ordinalPosition: targetPosition,
			parentId: parentDefinitionId,
			fieldType: targetComponent.fieldType,
		}

		let newDefinition: FormBuilderCellDefinition = {
			type: CellType.Concrete,
			...baseDefinition,
		}

		const configurableValueComponent =
			targetComponent as ConfigurableValueComponentRegistration<
				Record<string, unknown>
			>

		if (targetComponent.fieldType === FieldType.Object) {
			newDefinition = {
				type: CellType.Layout,
				...baseDefinition,
			}
		}

		if (targetComponent.fieldType === FieldType.ObjectArray) {
			newDefinition = {
				type: CellType.Repeat,
				...baseDefinition,
				defaultRepeatCount: 1, // ! TODO SET TO DEFAULT 1. Needs configuration.
			}
		}

		if (isValueType(targetComponent.fieldType)) {
			newDefinition = {
				type: CellType.Value,
				...baseDefinition,
				value: configurableValueComponent.defaultValue,
			}
		}

		this.cellDefinitions.push(newDefinition)
		this.insert(parentDefinitionId, newDefinition)
	}

	private insert(
		parentDefinitionId: string,
		definition: FormBuilderCellDefinition,
	) {
		console.log('insert')

		const currentDefinitions = this.cellDefinitions.filter(
			(v) => v.parentId === parentDefinitionId,
		)

		for (const siblingDefinitions of currentDefinitions)
			if (
				siblingDefinitions.ordinalPosition >= definition.ordinalPosition &&
				siblingDefinitions.id !== definition.id
			)
				siblingDefinitions.ordinalPosition =
					siblingDefinitions.ordinalPosition + 1

		this.cleanOrdinalPositions(parentDefinitionId)
		this.onChange(this._schema)
	}

	public deleteDefinition(definitionId: string): void {
		console.log('deleting definition ', definitionId)

		const targetDefinitionIndex = this.cellDefinitions.findIndex(
			(v) => v.id === definitionId,
		)
		if (targetDefinitionIndex < 0)
			throw Error('no definition exists with id' + definitionId)

		const targetDefinition = this.cellDefinitions[targetDefinitionIndex]

		// get the parent for the definition we're deleting
		const parentDefinition = this.cellDefinitions.find(v => v.id === targetDefinition.parentId)

		// if this is true then we must be at the root index, we have to remove and re-order
		if (parentDefinition === undefined) {
			this.cellDefinitions.splice(targetDefinitionIndex, 1)
			this.cleanOrdinalPositions(targetDefinition.parentId)
			this.onChange(this._schema)
		} else {
			targetDefinition.elementTag = NIL
			targetDefinition.properties = {}
			targetDefinition.fieldType = FieldType.None
			targetDefinition.type = CellType.Null
		}
	}

	/**
	 * clean ordinal positions so they are better ordered, e.g. [1, 2, 4] becomes [0, 1, 2]
	 * @param parentDefinitionId the parent definition id, can handle the NIL guid
	 */
	private cleanOrdinalPositions(parentDefinitionId: string) {
		const orderedExistingIndexes = this.cellDefinitions
			.filter((v) => v.parentId === parentDefinitionId)
			.sort((a, b) => a.ordinalPosition - b.ordinalPosition)

		for (let i = 0; i < orderedExistingIndexes.length; i++)
			orderedExistingIndexes[i].ordinalPosition = i
	}
}

function clearDescendants(
	definitionId: string,
	cellDefinitions: FormBuilderCellDefinition[],
): FormBuilderCellDefinition[] {
	const children = cellDefinitions.filter((v) => v.parentId === definitionId)

	for (const child of children)
		cellDefinitions = clearDescendants(child.id, cellDefinitions)

	return cellDefinitions.filter((v) => v.parentId !== definitionId)
}
