import { FilterAlt, KeyboardArrowDown } from '@mui/icons-material'
import {
	Button,
	Chip,
	Divider,
	Fade,
	Menu,
	MenuItem,
	Paper,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import { UserPropertyAssociationsClient } from '../../../../../api/clients/identity'
import { CurrentOrganizationUsersClient } from '../../../../../api/clients/identity/CurrentOrganizationUsersClient'
import { BaseUser } from '../../../../../api/DTO'
import {
	UserProperty,
	UserPropertyAssociation,
} from '../../../../../api/DTOtemp'
import { toastService } from '../../../../../services/notifications/ToastService'
import { UserPropertyUsersTable } from './UserPropertyUsersTable'

type UserPropertyUsersSectionProps = {
	userProperty: UserProperty
}

type FilterOption = {
	label: string
	value: number
	filteredUsers: BaseUser[]
}

export const UserPropertyUsersSection = observer(
	({ userProperty }: UserPropertyUsersSectionProps) => {
		const styles = useStyles()

		const client = new UserPropertyAssociationsClient()

		const store = useLocalObservable(() => ({
			associations: [] as UserPropertyAssociation[],
			users: [] as BaseUser[],
			filterAnchorEl: null as HTMLElement | null,
			filteredUsers: [] as BaseUser[],
			selectedFilter: 0,
		}))

		const getUserPropertyAssociations = useCallback(() => {
			return client
				.GetUserPropAssociations(true, undefined, userProperty.id)
				.then((response) => response.data)
		}, [])

		const getUsers = useCallback(() => {
			const organizationUsersClient = new CurrentOrganizationUsersClient()

			return organizationUsersClient
				.getUsersForCurrentOrganization()
				.then((response) => {
					return response.data.map((v) => v.user)
				})
		}, [])

		useEffect(() => {
			;(async () => {
				const associations = await getUserPropertyAssociations()
				const users = await getUsers()

				runInAction(() => {
					store.associations = associations
					store.users = users
					store.filteredUsers = users // we want to display all users first, not filtered
				})
			})()
		}, [])

		const handleUpdateUserValue = (associationId: number, newValue: string) => {
			client
				.UpdateUserPropAssociation(associationId, newValue)
				.then((response) => {
					const association = store.associations.find(
						(v) => v.id === response.data.id,
					)
					if (association !== undefined) association.propertyValue = newValue
					toastService.displayToast({
						message: `Updated user property value`,
						area: 'global',
					})
				})
				.catch(() => {
					toastService.displayToast({
						message: 'Error updating user property value',
						area: 'global',
					})
				})
		}

		const handleAddAssociation = (userId: number, value: string) => {
			client
				.AddUserPropAssociation({
					userId: userId,
					propertyId: userProperty.id,
					value: value,
				})
				.then(
					action((response) => {
						const updatedAssociations: UserPropertyAssociation[] = []

						for (const association of store.associations)
							updatedAssociations.push(association)

						updatedAssociations.push(response.data)

						store.associations = updatedAssociations
					}),
				)
		}

		const handleDeleteAssociation = (associationId: number) => {
			client.DeleteUserPropAssociation(associationId).then(
				action(() => {
					const associationIndex = store.associations.findIndex(
						(v) => v.id === associationId,
					)

					if (associationIndex < 0) return

					const updatedAssociations: UserPropertyAssociation[] = []

					for (const association of store.associations)
						updatedAssociations.push(association)

					updatedAssociations.splice(associationIndex, 1)
					store.associations = updatedAssociations
				}),
			)
		}

		const associatedUsers = useMemo(
			action(() => {
				const associated = store.users.filter((v) =>
					store.associations.map((v) => v.userId).includes(v.id),
				)

				if (store.selectedFilter === 1) store.filteredUsers = associated
				return associated
			}),
			[store.associations, store.users],
		)

		const unassociatedUsers = useMemo(
			action(() => {
				const unassociated = store.users.filter(
					(v) => !store.associations.map((v) => v.userId).includes(v.id),
				)

				if (store.selectedFilter === 2) store.filteredUsers = unassociated
				return unassociated
			}),
			[store.associations, store.users],
		)

		const filterOptions: FilterOption[] = [
			{
				label: 'All',
				value: 0,
				filteredUsers: store.users,
			},
			{
				label: 'Value',
				value: 1,
				filteredUsers: associatedUsers,
			},
			{
				label: 'No Value',
				value: 2,
				filteredUsers: unassociatedUsers,
			},
		]

		const handleFilterClick = action(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				store.filterAnchorEl = event.currentTarget
			},
		)

		const handleFilterUsers = action((option: FilterOption) => {
			store.selectedFilter = option.value
			store.filteredUsers = option.filteredUsers
			store.filterAnchorEl = null
		})

		/* This is here because we have the list of organization users in this component.
			We pass in the filtered users to UserPropertyUsersSection, but we need to get the
			username of the user who last edited an association, and that user might not be
			in that filtered list.
		*/
		const handleGetUserName = useCallback(
			(userId: number) => {
				const user = store.users.find((v) => v.id === userId)
				if (user === undefined) return undefined

				return `${user.firstName} ${user.lastName}`
			},
			[store.users],
		)

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<div className={styles.topBottomContent}>
						<Typography variant="h6" color="textPrimary">
							Users
						</Typography>
						<div>
							<Chip
								label={`Filter: ${filterOptions[store.selectedFilter].label}`}
								className={styles.filterChip}
							/>
							<Button
								startIcon={<FilterAlt />}
								color="primary"
								variant="contained"
								size="small"
								onClick={handleFilterClick}
								endIcon={<KeyboardArrowDown />}
							>
								Filter
							</Button>
							<Menu
								anchorEl={store.filterAnchorEl}
								open={store.filterAnchorEl !== null}
								onClose={action(() => (store.filterAnchorEl = null))}
							>
								{filterOptions.map((option) => (
									<MenuItem
										onClick={() => handleFilterUsers(option)}
										key={option.value}
									>
										{option.label}
									</MenuItem>
								))}
							</Menu>
						</div>
					</div>
					<Divider />
					<UserPropertyUsersTable
						associations={store.associations}
						users={store.filteredUsers}
						userProperty={userProperty}
						onAddAssociation={handleAddAssociation}
						onUpdateUserValue={handleUpdateUserValue}
						onDeleteAssociation={handleDeleteAssociation}
						onGetUserName={handleGetUserName}
					/>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		paddingBottom: theme.spacing(2),
		margin: theme.spacing(3, 0),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	cardRoot: {
		margin: theme.spacing(3, 0),
	},

	filterChip: {
		marginRight: theme.spacing(2),
	},

	displayNone: {
		visibility: 'hidden',
	},

	tableRoot: {
		width: percent(100),
	},

	popover: {
		display: 'flex',
		flexDirection: 'column',
	},

	popoverRoot: {
		margin: theme.spacing(2),
	},

	popoverContent: {
		margin: theme.spacing(1),
	},

	popoverActions: {
		textAlign: 'right',
	},
}))
