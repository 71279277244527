import { Create } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { PortalsClient } from '../../../../../api/clients/identity/PortalsClient'
import { PortalVersion } from '../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import { toastService } from '../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import UploadPortalDialog from './CreatePortalDialog'
import { PortalsTable } from './PortalsTable'

export const PortalsPage = () => {
	const [dialogOpen, setDialogOpen] = useState(false)
	const [portals, setPortals] = useState<PortalVersion[]>()
	const portalApi = new PortalsClient()
	const history = useHistory()

	const deletePortal = (portalId: number) => {
		portalApi
			.DeletePortal(portalId)
			.then((v) => {
				// removes the deleted portal from the table
				setPortals((portals ?? []).filter((v) => v.portalId !== portalId))
				toastService.displayToast({
					message: 'Portal deleted',
					area: 'global',
				})
			})
			.catch((v) => {
				console.error(v)
				toastService.displayToast({
					message: 'Error deleting portal',
					area: 'global',
				})
			})
	}

	// gets the latest version of each portal to display in the table
	useEffect(() => {
		const versionsPromise = portalApi.GetLatestPortalVersions()
		versionsPromise
			.then((v) => setPortals(v.data))
			.catch((v) => console.error(v))
	}, [])

	return (
		<AdministrationPageContainer
			title={'Portals'}
			actions={
				<div>
					<Button
						variant="contained"
						color="primary"
						startIcon={<Create />}
						onClick={() => {
							setDialogOpen(true)
						}}
					>
						Create Portal
					</Button>
				</div>
			}
		>
			{portals === undefined ? (
				<FullscreenSpinner />
			) : (
				<PortalsTable
					portals={portals}
					onEditClicked={(portalId) => {
						history.push(`/_administration/_portals/${portalId}`)
					}}
					onDeletePortal={deletePortal}
				/>
			)}
			<UploadPortalDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
			/>
		</AdministrationPageContainer>
	)
}
