import html2canvas, { Options } from 'html2canvas'

const PRINT_GROUP_ATTRIBUTE = 'data-print-group'
const WIDTH_IN_MM = 211
const TOP_MARGIN_IN_INCHES = 0.25
const HEIGHT_IN_INCHES = 13.75
const MAGIC_PRINT_WIDTH = 1108
const ENABLE_LOGGING = false
const ENABLE_DETAILED_LOGGING = false

export enum RenderMode {
	Html = 0,
	FormBuilder = 1
}

export async function form2pdf(element: HTMLElement, containerElement: HTMLElement, renderMode: RenderMode) {
	function getDPI() {
		let dpiDiv = document.createElement('div')

		dpiDiv.id = 'dpiDiv'
		dpiDiv.style.width = '1in'
		dpiDiv.style.height = '1in'
		dpiDiv.style.display = 'hidden'

		document.body.appendChild(dpiDiv)

		dpiDiv = document.getElementById('dpiDiv') as HTMLDivElement

		const result = dpiDiv.clientWidth

		document.body.removeChild(dpiDiv)

		return result
	}

	const DPI = getDPI()
	const MM_TO_INCHES = 25.4
	const WIDTH_IN_PIXELS = Math.floor((WIDTH_IN_MM * DPI) / MM_TO_INCHES)
	const HEIGHT_IN_PIXELS = Math.floor(HEIGHT_IN_INCHES * DPI)
	const TOP_MARGIN_IN_PIXELS = Math.floor(TOP_MARGIN_IN_INCHES * DPI)

	if (ENABLE_LOGGING) {
		console.log(`-- DPI = ${DPI}`)
		console.log(`-- WIDTH_IN_PIXELS = ${WIDTH_IN_PIXELS}`)
		console.log(`-- HEIGHT_IN_PIXELS = ${HEIGHT_IN_PIXELS}`)
		console.log(`-- TOP_MARGIN_IN_PIXELS = ${TOP_MARGIN_IN_PIXELS}`)
	}

	async function printForm(root: HTMLElement) {
		const images = []
		const formPages = new FormPages(HEIGHT_IN_PIXELS, TOP_MARGIN_IN_PIXELS)

		const initialWidth = containerElement.style.width;

		try {

			containerElement.style.width = `${MAGIC_PRINT_WIDTH}px`

			while (formPages.hasMoreToPrint()) {
				formPages.printNextPage(element);
				/*
				Because of the `printNextPage` function, this is going to be stuck to be
				within the height of a PDF Document at a minimum
				*/
				const height = root.ownerDocument.body.scrollHeight;

				// ! HTML
				const htmlRendererOptions: Partial<Options> = {
					useCORS: true,
					allowTaint: true,
					width: MAGIC_PRINT_WIDTH,
					windowWidth: MAGIC_PRINT_WIDTH,
					height: height,
					windowHeight: height,
					foreignObjectRendering: true,
					// foreign object rendering doesn't show values
				}

				// ! FORM BUILDER
				const formBuilderRendererOptions: Partial<Options> = {
					useCORS: true,
					allowTaint: true,
					width: MAGIC_PRINT_WIDTH,
					// don't set height, no iframe so gets entire doc height and we don't want that
					foreignObjectRendering: false
				}

				// renderMode === RenderMode.Html ? htmlRendererOptions : formBuilderRendererOptions
				const canvas = await html2canvas(root, formBuilderRendererOptions)

				formPages.resetViewState(element)

				images.push(canvas)
			}
			return images
		} catch (e) {
			console.error(e);
			throw e;
		}
		finally {
			containerElement.style.width = initialWidth
		}
	}

	async function createPDF(...images: HTMLCanvasElement[]) {
		const { jsPDF } = await import('jspdf')

		const pdf = new jsPDF('p', 'px', 'a4')
		let first = true
		for (const image of images) {
			if (first) first = false
			else pdf.addPage()

			const width = pdf.internal.pageSize.getWidth()

			pdf.addImage(image, 'JPEG', 0, TOP_MARGIN_IN_PIXELS, width, 0)
		}
		return pdf
	}

	const images = await printForm(element)
	return await createPDF(...images)
}

function verify(expr: boolean, message: string) {
	console.assert(expr, message)
	if (!expr) throw new Error(message)
}

export enum PrintState {
	NotPrinted = 0,
	Printed = 1,
	Printing = 2,
	Hidden = 3,
}

function getElementDetailsGlobal(elem: HTMLElement) {
	let result = `${elem.nodeName || elem.tagName}`
	if (elem.id) result = `${result}#${elem.id}`
	else if (elem.className) result = `${result}.${elem.className}`

	const rect = elem.getBoundingClientRect()
	return `${result} (${rect.top}, ${rect.bottom}, ${rect.bottom - rect.top})`
}

/*
While originally this "cloned element" object was a tracker that kept
height and width, it has had to be expanded to cache some data as well.

We have to make measurements of the DOM to spit out images that correctly
fit inside of a PDF - however the way HTML2Canvas clones items, it gives
absolute heights to items that we're going to hide. With that in mind
we have to use the _real_ html to show and hide elements so the elements
that we're taking snapshots of are going to appear correctly. The cached
data is to restore the element back to its correct state after we're done.
*/
class ClonedElement {
	name: string
	id: string
	className: string
	state: PrintState
	top: number
	bottom: number
	isGroup: boolean
	parent: ClonedElement | null
	children: ClonedElement[]

	/* cache section */
	cachedDisplayProperty: string

	constructor(elem: HTMLElement) {
		this.name = elem.nodeName || elem.tagName
		this.id = elem.id
		this.className = elem.classList[0]
		this.state =
			elem.style.display == 'none' ? PrintState.Hidden : PrintState.NotPrinted
		const rect = elem.getBoundingClientRect()
		this.top = rect.top
		this.bottom = rect.bottom
		if (this.bottom - this.top == 0) this.state = PrintState.Hidden
		this.isGroup = elem.hasAttribute(PRINT_GROUP_ATTRIBUTE)
		this.parent = null
		this.children = []

		/* cache section */
		this.cachedDisplayProperty = elem.style.display

		for (const child of elem.children) {
			const clone = new ClonedElement(child as HTMLElement)
			clone.parent = this
			this.children.push(clone)
		}
	}

	stateIs(state: PrintState) {
		return this.state == state
	}

	isNotPrinted() {
		return this.state == PrintState.NotPrinted
	}

	isPrinted() {
		return this.state == PrintState.Printed
	}

	isPrintedOrHidden() {
		return this.state == PrintState.Printed || this.state == PrintState.Hidden
	}

	isPrinting() {
		return this.state == PrintState.Printing
	}

	visit(callback: (element: ClonedElement) => void) {
		callback(this)
		this.children.forEach((c) => c.visit(callback))
	}

	find(predicate: (element: ClonedElement) => boolean): ClonedElement | null {
		if (predicate(this)) return this
		return this.findInChildren(predicate)
	}

	findInChildren(
		predicate: (element: ClonedElement) => boolean,
	): ClonedElement | null {
		let result = null
		for (const child of this.children) {
			result = child.find(predicate)
			if (result) break
		}
		return result
	}

	hide() {
		this.state = PrintState.Hidden
		this.children.forEach((c) => c.hide())
	}

	markAsPrinted() {
		if (!this.isPrinting()) return
		this.children.forEach((c) => c.markAsPrinted())
		if (this.children.every((c) => c.isPrintedOrHidden()))
			this.state = PrintState.Printed
	}

	markAsPrinting() {
		verify(
			!this.isPrintedOrHidden(),
			'Cannot mark printed element for printing',
		)

		function markAncestors(e: ClonedElement) {
			if (!e.parent) return
			e.parent.state = PrintState.Printing
			markAncestors(e.parent)
		}

		function markChildren(e: ClonedElement) {
			e.children.forEach((c) => {
				c.state = PrintState.Printing
				markChildren(c)
			})
		}

		this.state = PrintState.Printing
		markAncestors(this)
		markChildren(this)
	}

	syncElement(elem: HTMLElement) {
		if (this.state != PrintState.Printing) {
			if (ENABLE_LOGGING && ENABLE_DETAILED_LOGGING)
				console.log(
					`hiding element ${this.getElementDetails()} ` +
						`- ${getElementDetailsGlobal(elem)}`,
				)
			elem.style.display = 'none'
			return
		}
		let index = 0
		for (const child of elem.children)
			this.children[index++].syncElement(child as HTMLElement)
	}

	resetElement(elem: HTMLElement) {
		if (ENABLE_LOGGING && ENABLE_DETAILED_LOGGING)
			console.log(`resetting element ${this.getElementDetails()} - ${getElementDetailsGlobal(elem)}`)

		elem.style.display = this.cachedDisplayProperty; 

		for (let index = 0; index < elem.children.length; index++)
			this.children[index].resetElement(elem.children[index] as HTMLElement)
	}

	getElementDetails() {
		let result = `${this.name}`
		if (this.id) result = `${result}#${this.id}`
		else if (this.className) result = `${result}.${this.className}`
		return `${result} (${this.top}, ${this.bottom}, ${this.bottom - this.top})`
	}

	logState(depth = 0) {
		if (ENABLE_LOGGING && ENABLE_DETAILED_LOGGING) {
			console.log(this.tellState(depth))
			this.children.forEach((c) => c.logState(depth + 1))
		}
	}

	tellState(depth = 0) {
		function stateToString(state: PrintState) {
			return state.toString()
			//	return Object.keys(PrintState).find((name) => name == state)
		}
		return (
			' '.repeat(depth * 2) +
			` ${this.getElementDetails()}` +
			` ${stateToString(this.state)}`
		)
	}
}

class FormPages {
	pageHeight: number
	topMargin: number
	pageCount: number
	root: ClonedElement | null
	next: ClonedElement | null
	top: number
	height: number

	cachedTopMargin: string | null

	constructor(pageHeight: number, topMargin: number) {
		this.pageHeight = pageHeight
		this.topMargin = topMargin
		this.pageCount = 0
		this.root = null
		this.next = null
		this.top = 0
		this.height = 0

		this.cachedTopMargin = null
	}

	hasMoreToPrint() {
		return !this.root || !this.root.isPrintedOrHidden()
	}

	printNextPage(element: HTMLElement) {
		/* 	This `element` that is being passed in is considered the
		root. There's a recursive tree structure going on that keeps
		everything in sync between our data model and the DOM	*/

		verify(
			element !== null || this.root !== null,
			"Argument 'doc' should not be null.",
		)
		this._initialize(element)
		verify(this.hasMoreToPrint(), 'The form is fully printed.')

		if (this.root === null) throw new Error('root cannot be null')

		if (ENABLE_LOGGING) console.log(`-- Printing page ${++this.pageCount}...`)
		try {
			if (!this.hasMoreToPrint()) return
			this._computeTopAndNextToPrint()
			this._skipWhatFitsOnPage()
			this.cachedTopMargin = element.style.marginTop;
			element.style.marginTop = `${this.topMargin}px`
			this.root.syncElement(element)
			this._hideAlreadyPrintedElements()
		} catch (x) {
			console.error(`!!! ${x}`)
			throw x
		}
	}

	resetViewState(element: HTMLElement) {
		if (this.root === null)
			throw new Error('root cannot be null')

		element.style.marginTop = this.cachedTopMargin ?? element.style.marginTop;

		this.root.resetElement(element);
	}

	_initialize(element: HTMLElement) {
		if (this.root) return
		if (ENABLE_LOGGING) console.log('---- _initialize(doc)')
		this.root = new ClonedElement(element)
		this.top = this.root.top + this.topMargin
	}

	_computeTopAndNextToPrint() {
		if (this.root === null) throw new Error('root cannot be null')

		this.next = null
		this.root.visit((e: ClonedElement) => {
			if (e.isPrinted()) {
				if (e.bottom + this.topMargin > this.top)
					this.top = e.bottom + this.topMargin
			} else if (this.next == null && e.isNotPrinted()) this.next = e
		})
	}

	_hideAlreadyPrintedElements() {
		if (this.root === null) throw new Error('root cannot be null')

		if (ENABLE_LOGGING) console.log('---- _hideAlreadyPrintedElements()')
		this.root.markAsPrinted()
	}

	_skipWhatFitsOnPage() {
		if (this.root === null) throw new Error('root cannot be null')

		if (ENABLE_LOGGING) console.log('---- _skipWhatFitsOnPage()')
		this.height = 0
		if (ENABLE_LOGGING) console.log(`** this.top = ${this.top}`)
		while (this.next) {
			const bottom = this.next.bottom + this.topMargin
			const height = bottom - this.top
			if (height < this.pageHeight) {
				if (height > this.height) this.height = height
				if (ENABLE_LOGGING)
					console.log(
						`** Including ${this.next.getElementDetails()}` +
							`(${this.height})`,
					)
				this.next.markAsPrinting()
				this.next = this.root.find((c: ClonedElement) => c.isNotPrinted())
			} else {
				if (ENABLE_LOGGING)
					console.log(
						`** Drilling into ${this.next.getElementDetails()}` +
							`(${this.height})`,
					)
				const next = this.next
				this.next = null
				if (!next.isGroup)
					this.next = next.findInChildren((c: ClonedElement) =>
						c.isNotPrinted(),
					)
				verify(
					this.height > 0 || this.next !== null,
					"Can't fit any elements or child elements " + 'on a single page.',
				)
			}
		}
		this.root.logState()
	}
}
