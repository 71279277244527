import { createContext, PropsWithChildren, useContext } from 'react'

export const AdministrationBreadcrumbsContext =
	createContext<AdministrationBreadcrumbsContextProps>({
		breadcrumbs: [],
	})

export type Breadcrumb = {
	link: string
	pageName: string
}

type AdministrationBreadcrumbsContextProps = {
	breadcrumbs: Breadcrumb[]
}

export const AdministrationContextProvider = (
	props: PropsWithChildren<AdministrationBreadcrumbsContextProps>,
) => {
	return (
		<AdministrationBreadcrumbsContext.Provider value={props}>
			{props.children}
		</AdministrationBreadcrumbsContext.Provider>
	)
}

export const UseAdministrationBreadcrumbsContext = () => {
	const context = useContext(AdministrationBreadcrumbsContext)
	if (context === undefined)
		throw Error(
			'a value for the administration breadcrumbs context was not provided',
		)
	return context
}
