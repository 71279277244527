import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyValueTypeComponent,
	ValuesEditorComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	NumberPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import {
	AutoCompleteElementTag,
	AutoCompleteInput,
} from './InternalAutoCompleteInputDefinition'

const AutoCompleteInputId = '942e1ae5-d77b-497d-88d8-f5d4b1c4f1cb'

const AutoCompleteInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof AutoCompleteInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.dataProviderInstanceId}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, dataProviderInstanceId: v })
				}
				Editor={NumberPropertyConfigurator}
				label="Data Provider Instance Id"
			/>
			<PropertySchematicConfigurator
				value={properties.indexer}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, indexer: v })
				}
				Editor={TextPropertyConfigurator}
				label="Indexer"
			/>
		</>
	)
}

const AutoCompleteInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof AutoCompleteInput>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={TextPropertyConfigurator}
		label="Default Value"
	/>
)

export const AutoCompleteInputDefinition = specifyValueTypeComponent(
	AutoCompleteInputId,
	AutoCompleteInput,
	AutoCompleteElementTag,
	'AutoComplete',
	FieldType.String,
	{
		configurator: AutoCompleteInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			dataProviderInstanceId: 0,
			indexer: '',
		},
	},
	{
		configurator: AutoCompleteInputValueEditor,
		defaultValue: '',
	},
)
