import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useHistory, useLocation } from 'react-router'
import { AuthenticationMethod } from '../../api/DTO'
import { PasswordEntryManager } from './PasswordEntry'
import { UsernameEntryManager } from './UsernameEntry'

type LoginSectionProps = {
	onFetchedUserAuthMethod?: (authMethod: AuthenticationMethod) => void
}

export const LoginSection = observer((props: LoginSectionProps) => {
	const history = useHistory()
	const location = useLocation()

	const { from } = (location.state as
		| undefined
		| { from: { pathname: string | undefined } }) || {
		from: { pathname: undefined },
	}

	const store = useLocalObservable(() => ({
		username: undefined as string | undefined,
	}))

	const onReceivedValidUsername = action(
		(username: string, authMethod?: AuthenticationMethod): void => {
			store.username = username
			if (
				authMethod !== undefined &&
				props.onFetchedUserAuthMethod !== undefined
			)
				props.onFetchedUserAuthMethod(authMethod)
		},
	)

	const handleBackToUsernameEntry = action(() => {
		store.username = undefined
	})

	// display username entry
	if (store.username === undefined) {
		return (
			<UsernameEntryManager onReceivedValidUsername={onReceivedValidUsername} />
		)
	}

	return (
		<PasswordEntryManager
			username={store.username}
			onAuthenticated={() => {
				if (from.pathname !== undefined) {
					history.replace(from)
				} else {
					// nothing in from, so it has to be a new login
					history.push('/_user/_home')
				}
			}}
			onBackToUsernameEntry={handleBackToUsernameEntry}
		/>
	)
})
