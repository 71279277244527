import { Add } from '@mui/icons-material'
import {
	Chip,
	FormControl,
	FormGroup,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
} from '@mui/material'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { TypedEditor } from './PropertySchematicConfigurator'

const ListPropertyConfigurator = (props: TypedEditor<string[] | undefined>) => {
	return (
		<FormGroup>
			<ListControlInput
				onAddItem={(v: string) => {
					const newValue = [...new Set([...(props.value ?? []), v])]
					props.onChange(newValue)
				}}
			/>
			<div>
				{props.value !== undefined &&
					props.value.map((v) => (
						<Chip
							key={v}
							label={v}
							onDelete={() => {
								const newValue = props.value?.filter((val) => val !== v)
								props.onChange(newValue)
							}}
						/>
					))}
			</div>
		</FormGroup>
	)
}

type ListControlInputProps = {
	label?: string
	onAddItem: (v: string) => void
}

export const ListControlInput = observer((props: ListControlInputProps) => {
	const localStore = useLocalObservable(() => ({
		value: '',
	}))

	const addItem = action(() => {
		props.onAddItem(localStore.value)
		localStore.value = ''
	})

	return (
		<FormControl>
			<InputLabel>{props.label}</InputLabel>
			<Input
				value={localStore.value}
				onChange={action((v) => (localStore.value = v.currentTarget.value))}
				onKeyPress={(v) => {
					if (v.key === 'Enter') addItem()
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={addItem} size="large">
							<Add />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	)
})

export default ListPropertyConfigurator
