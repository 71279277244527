import { DynamicProgram } from "../VisualScripting"
import { FormBuilderCellDefinition } from "./cells/FormBuilderCellDefinition"
import { FormBuilderCellInstance } from "./cells/FormBuilderCellInstance"

export type FormBuilderStructure = {
    definitions: FormBuilderCellDefinition[]
    instances?: FormBuilderCellInstance[]
}

export type FormBuilderSchema = {
    handlers: FormBuilderEventHandler
} & FormBuilderStructure

export function isFormBuilderSchema(v: FormBuilderStructure): v is FormBuilderSchema {
    return (v as FormBuilderSchema).handlers !== undefined
}

export type FormBuilderEventHandler = {
    actions: ConditionalFormAction[]
}

export type ConditionalFormAction = {
    shouldActivate: DynamicProgram
} & FormAction

export type FormAction = {
    id: string
    name: string
    description?: string
    action: DynamicProgram
}