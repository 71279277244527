import { Download, Visibility, VisibilityOff } from '@mui/icons-material'
import {
	Box,
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import {
	DetailForm,
	FormPackageVersion,
} from '../../../../../../../api/DTOtemp'
import {
	FormPackageVersionClient,
	FormsClient,
} from '../../../../../../../api/clients/identity'
import { AssignmentType } from '../../../../../../../api/clients/workItems/DTOs'
import UserChip from '../../../../../../../components/chips/UserChip'
import { FullscreenSpinner } from '../../../../../../../components/feedback/circular'
import { blobToBase64 } from '../../../../../../../utils/BlobUtils'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../FormPackagePage'

type PackageVersionsAdministrationPageProps = {
	packageId: number
} & FormPackageAdministrationPageProps

export const PackageVersionsAdministrationPage = observer(
	(props: PackageVersionsAdministrationPageProps) => {
		const localStore = useLocalObservable(() => ({
			versions: undefined as FormPackageVersion[] | undefined,
		}))

		useEffect(() => {
			const versionClient = new FormPackageVersionClient(props.packageId)
			versionClient.Versions().then((response) => {
				localStore.versions = response.data
			})
		}, [])

		return (
			<AdministrationPageContainer title="Form Package Versions">
				<Box
					display="flex"
					flexDirection="column"
					width={percent(100)}
					height={percent(100)}
				>
					<AdministrationPageTabs
						currentTab={9}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					{localStore.versions === undefined ? (
						<FullscreenSpinner />
					) : (
						<PackageVersionsTable
							versions={localStore.versions}
							packageId={props.packageId}
						/>
					)}
				</Box>
			</AdministrationPageContainer>
		)
	},
)

type PackageVersionsTableProps = {
	packageId: number
	versions: FormPackageVersion[]
}

export const PackageVersionsTable = (props: PackageVersionsTableProps) => {
	const styles = useStyles()

	const getVersion = async (versionId: number) => {
		const packageVersionClient = new FormPackageVersionClient(props.packageId)

		const { data: version } = await packageVersionClient.GetVersion(versionId)

		const versionObject = {
			packageInfo: version,
			forms: [] as ({ stream: unknown } & DetailForm)[],
		}

		const formsClient = new FormsClient(props.packageId, version.versionNumber)
		const { data: forms } = await formsClient.GetAllForms()

		const cancellationToken = new AbortController().signal

		for (const form of forms) {
			const { data: formStream } = await formsClient.GetFormStream(
				form.id,
				cancellationToken,
			)

			const b64 = (await blobToBase64(formStream)) as string

			// get the base 64 string without the beginning "data:application/json;base64,"
			const b64stream = b64.split(',')[1]

			versionObject.forms.push({ ...form, stream: b64stream })
		}

		return versionObject
	}

	const handleDownloadVersion = async (versionNumber: number) => {
		const version = await getVersion(versionNumber)

		const jsonData = JSON.stringify(version)
		const base64 = window.btoa(jsonData)

		const anchor = document.createElement('a', {})
		anchor.href = 'data:application/json;base64,' + base64
		anchor.target = '_blank'
		anchor.download = `form-package-${version.packageInfo.id}_${version.packageInfo.versionNumber}.ffp`

		anchor.click()
		anchor.remove()
	}

	return (
		<Fade in>
			<TableContainer component={Paper} className={styles.tableRoot}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Version Number</TableCell>
							<TableCell>Last Updated</TableCell>
							<TableCell>Last Updated By</TableCell>
							<TableCell>Active</TableCell>
							<TableCell>Purged</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.versions
							.sort((a, b) => b.versionNumber - a.versionNumber)
							.map((version) => (
								<TableRow>
									<TableCell>{version.versionNumber}</TableCell>
									<TableCell>
										{new Date(version.lastModifiedDate).toLocaleString()}
									</TableCell>
									<TableCell>
										<UserChip
											assignmentType={AssignmentType.User}
											id={version.lastModifiedBy}
										/>
									</TableCell>
									<TableCell>
										{version.isActive ? (
											<Visibility />
										) : (
											<VisibilityOff color="disabled" />
										)}
									</TableCell>
									<TableCell>{version.isPurged}</TableCell>
									<TableCell align="right">
										<IconButton
											onClick={() =>
												handleDownloadVersion(version.versionNumber)
											}
										>
											<Download />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		margin: theme.spacing(2, 0, 0, 0),
	},
}))
