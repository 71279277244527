import CloudDownload from '@mui/icons-material/CloudDownload'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { useEffect, useState } from 'react'
import { FormsClient } from '../../../../../../api/clients/identity'
import { AssignmentType } from '../../../../../../api/clients/workItems/DTOs'
import { DetailForm, FormVersion } from '../../../../../../api/DTOtemp'
import UserChip from '../../../../../../components/chips/UserChip'
import { downloadFile } from '../../../../../../utils/DownloadFile'

type FormInfoTableProps = {
	packageId: number
	packageVersionNumber: number
	formInfo: DetailForm
}
const FormVersionHistoryTable = ({
	packageId,
	packageVersionNumber,
	formInfo,
}: FormInfoTableProps) => {
	const [formList, setFormList] = useState<FormVersion[] | undefined>(undefined)
	const api = new FormsClient(packageId, packageVersionNumber)

	useEffect(() => {
		api.getFormVersions(formInfo.id).then((v) => {
			setFormList(v.data)
		})
	}, [formInfo])

	async function downloadForm(versionNumber: number, name: string) {
		const response = await api.GetFormVersionStream(formInfo.id, versionNumber)

		let url = ''

		let extension = ""
		if (response.data.type === 'text/html') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
			extension = "html"
		}
		if (response.data.type === 'application/pdf') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
			extension = "pdf"
		}
		if (response.data.type === 'application/json') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'application/json' }))
			extension = "json"
		}

		downloadFile(document, url, name, extension)
	}

	async function viewForm(versionNumber: number) {
		const response = await api.GetFormVersionStream(formInfo.id, versionNumber)

		let url = ''

		if (response.data.type === 'text/html') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'text/html' }))
		}
		if (response.data.type === 'application/pdf') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
		}
		if (response.data.type === 'application/json') {
			url = URL.createObjectURL(new Blob([response.data], { type: 'application/json' }))
		}

		window.open(url);
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Version Number</TableCell>
						<TableCell>Last Updated</TableCell>
						<TableCell>Last Updated By</TableCell>
						<TableCell>Active</TableCell>
						<TableCell>Purged</TableCell>
						<TableCell align="right">Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{formList &&
						formList
							.sort((a, b) => b.versionNumber - a.versionNumber)
							.map((v) => (
								<TableRow key={v.id}>
									<TableCell> {v.versionNumber} </TableCell>
									<TableCell>
										{' '}
										{new Date(v.lastModifiedDate).toLocaleString()}
									</TableCell>
									<TableCell>
										<UserChip
											assignmentType={AssignmentType.User}
											id={v.lastModifiedBy}
										/>
									</TableCell>
									<TableCell>
										<IconButton size="large" onClick={() => viewForm(v.versionNumber)}>
											{' '}
											{v.versionNumber === formInfo.activeVersion ? (
												<Visibility />
											) : (
												<VisibilityOff color="disabled" />
											)}{' '}
										</IconButton>
									</TableCell>
									<TableCell> {v.purged} </TableCell>
									<TableCell align="right">
										<IconButton size="large" onClick={() => downloadForm(v.versionNumber, formInfo.name)}>
											<CloudDownload />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default FormVersionHistoryTable
