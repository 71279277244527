import { Theme } from '@mui/material';
import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';
import { percent } from "csx";

export const useGeneralStyles = makeStyles((theme: Theme) => ({
	dropContainerStyle: {
		display: 'flex',
		
		position: 'relative',
		width: percent(100),

		justifyContent: 'center',

		margin: theme.spacing(.5, 0),

		border: '2px',
		borderStyle: 'solid',
		borderRadius: theme.shape.borderRadius,
		borderColor: theme.palette.mode === 'light'
			? theme.palette.grey[200]
			: theme.palette.grey[800],
		
		zIndex: 5,

		cursor: 'move',
	},

	selectedDropContainerStyle: {
		borderColor: green[500],
	},

	unnamedDropContainerStyle: {
		borderColor: theme.palette.mode === 'light'
			? theme.palette.error.light
			: theme.palette.error.dark
	}
}))
