import { Launch, Save, Upload } from '@mui/icons-material'
import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { PortalsClient } from '../../../../../api/clients/identity/PortalsClient'
import { PortalSettings } from '../../../../../api/DTOtemp'
import { ClickToCopyButton } from '../../../../../components/buttons/ClickToCopyButton'
import DescriptiveSwitch from '../../../../../components/inputs/DescriptiveSwitch'
import ButtonLink from '../../../../../components/links/ButtonLink'
import { toastService } from '../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../AdministrationPageTabs'
import CreatePortalDialog, { UploadType } from './CreatePortalDialog'
import { usePortalContext } from './PortalPageContext'

type PortalSettingsPageProps = {
	portalNames: string[]
}

export const PortalSettingsPage = (props: PortalSettingsPageProps) => {
	const styles = useStyles()
	const [dialogOpen, setDialogOpen] = useState(false)
	const { version, portal } = usePortalContext()
	// this is here to update the portal link properly upon changes being saved
	const [versionNum, setVersionNum] = useState(version.versionNumber)
	const [authenticationRequired, setAuthenticationRequired] = useState(
		portal.authenticationRequired,
	)

	const formik = useFormik({
		initialValues: {
			name: version.name,
			description: version.description,
			anonymous: !portal.authenticationRequired,
		},
		validationSchema: yup.object({
			name: yup
				.string()
				.required('The portal name must be set')
				.matches(
					/^[a-zA-Z0-9_ ]+$/,
					'The package name must not contain any special characters',
				)
				.test(
					'is-unique',
					'A portal with this name already exists in your organization',
					(value) => {
						return (
							value !== undefined &&
							(value.trim() === version.name?.trim() ||
								!props.portalNames.includes(value.toUpperCase().trim()))
						)
					},
				),
			description: yup
				.string()
				.matches(
					/^[a-zA-Z0-9_,\-$ ]*$/,
					'The description must not contain any special characters',
				),
		}),
		onSubmit: (values) => {
			const api = new PortalsClient()
			const settings: PortalSettings = {
				name: values.name,
				description: values.description,
				authenticationRequired: !values.anonymous,
			}
			api
				.UpdatePortalSettings(version.portalId, settings)
				.then(() => {
					toastService.displayToast({
						message: 'Settings updated',
						area: 'global',
					})
					setVersionNum(versionNum + 1)
					setAuthenticationRequired(!formik.values.anonymous)
				})
				.catch(() => {
					toastService.displayToast({
						message: 'Error updating settings',
						area: 'global',
					})
				})
		},
	})

	return (
		<AdministrationPageContainer
			title={'Portal Settings'}
			actions={
				<div>
					<ButtonLink
						color="primary"
						variant="outlined"
						className={styles.actionButtons}
						to={`/${!authenticationRequired ? '_external/' : ''}_portals/${
							portal.id
						}/_versions/${versionNum}`}
						startIcon={<Launch />}
					>
						Open Portal
					</ButtonLink>
					<Button
						color="primary"
						variant="outlined"
						className={styles.actionButtons}
						onClick={() => setDialogOpen(true)}
						startIcon={<Upload />}
					>
						Upload
					</Button>
					<Button
						color="primary"
						variant="contained"
						className={styles.actionButtons}
						onClick={formik.submitForm}
						disabled={!formik.dirty || !formik.isValid}
						startIcon={<Save />}
					>
						Save
					</Button>
				</div>
			}
		>
			<div className={styles.formContentRoot}>
				<AdministrationPageTabs
					currentTab={0}
					tabLabels={['Settings']}
					onTabChanged={function (
						event: React.SyntheticEvent<Element, Event>,
						newValue: number,
					): void {
						throw new Error('Function not implemented.')
					}}
				/>
				<Divider />
				<Fade in unmountOnExit>
					<Paper className={styles.insetSpacing}>
						<div className={styles.formSettingsRoot}>
							<div className={styles.formElement}>
								<TextField
									name="name"
									id="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={!!formik.errors.name}
									helperText={formik.errors.name ?? ' '}
									label="Portal Name"
									fullWidth
									autoComplete="off"
								/>
							</div>

							<div className={styles.formElement}>
								<TextField
									name="description"
									id="description"
									value={formik.values.description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={!!formik.errors.description}
									helperText={formik.errors.description ?? ' '}
									label="Portal Description"
									fullWidth
									autoComplete="off"
								/>
							</div>

							<DescriptiveSwitch
								name="anonymous"
								id="anonymous"
								checked={formik.values.anonymous}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								containerClassName={styles.formElement}
								color="primary"
								headerLabel="Anonymous Access"
								bodyLabel="Allow this form to be accessed by users who are not logged in"
							/>
						</div>

						<div className={styles.formElement}>
							<PortalLink
								portalId={portal.id}
								versionNumber={versionNum}
								anonymous={!authenticationRequired}
							/>
						</div>
					</Paper>
				</Fade>
				<CreatePortalDialog
					open={dialogOpen}
					onClose={() => setDialogOpen(false)}
					portalId={portal.id}
					portalName={version.name}
					portalType={UploadType.html}
				/>
			</div>
		</AdministrationPageContainer>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		paddingTop: theme.spacing(1),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	actionButtons: {
		textTransform: 'none',
		marginLeft: theme.spacing(2),
	},

	formSettingsRoot: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},
}))

type PortalLinkProps = {
	portalId: number
	versionNumber: number
	anonymous: boolean
}

const PortalLink = React.forwardRef<HTMLDivElement, PortalLinkProps>(
	(props, ref) => {
		const styles = usePortalLinkStyles()

		const portalLink = `${window.location.origin}/${
			props.anonymous ? '_external/' : ''
		}_portals/${props.portalId}/_versions/${props.versionNumber}`

		return (
			<div ref={ref} className={styles.portalLinkRoot}>
				<div className={styles.portalLinkStart}>
					<Typography color="textSecondary" variant="caption">
						{props.anonymous ? 'Portal / Embed Link' : 'Portal Link'}
					</Typography>
					<div className={styles.portalLinkContent}>
						<TextField value={portalLink} />
						<ClickToCopyButton value={portalLink} />
					</div>
				</div>
			</div>
		)
	},
)

const usePortalLinkStyles = makeStyles((theme: Theme) => ({
	portalLinkRoot: {
		display: 'flex',

		height: percent(100),
		width: percent(100),

		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},

	portalLinkStart: {
		flex: '1',
		overflow: 'hide',
	},

	portalLinkContent: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
	},
}))
