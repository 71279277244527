import { ArrowBack, Delete, Edit, Settings } from '@mui/icons-material'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useCallback, useMemo } from 'react'
import { NIL } from 'uuid'
import * as yup from 'yup'
import { useModals } from '../../../../../services/notifications/ModalService'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../../utils/HOC/FixedHeaders'
import { FormBuilderSchema } from '../../../../FormBuilderCore/Types'
import {
	ConfigurableComponentRegistration,
	ConfigurableValueComponentRegistration,
} from '../../../../FormBuilderCore/cells/ComponentTypes'
import { FormBuilderCellDefinition } from '../../../../FormBuilderCore/cells/FormBuilderCellDefinition'
import { fetchConfigurableComponent } from '../../../../FormBuilderCore/cells/GlobalComponentRegistry'
import { useFormBuilderContext } from '../../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { CellType } from '../../../../FormHost/Types/CellType'
import { getGlobalComponentRegistry } from '../../../ComponentRegistry/GlobalComponentRegistry'
import { useFormBuilderDragAndDropContext } from '../DragAndDrop/DragAndDropContext'
import { DraggableFormHost } from '../DraggableFormHost'

export type SidebarProps = {
	schema: FormBuilderSchema
	onSchemaModified: (schema: FormBuilderSchema) => void
}

export const PropertyEditor = observer((props: SidebarProps) => {
	const { componentCollection } = getGlobalComponentRegistry()
	const dragAndDropContext = useFormBuilderDragAndDropContext()

	if (
		dragAndDropContext.currentlySelectedDefinition === undefined ||
		dragAndDropContext.currentlySelectedDefinition === NIL
	)
		return <> No Currently Selected Element </>

	const definition = props.schema.definitions.find(
		(v) => v.id === dragAndDropContext.currentlySelectedDefinition,
	)
	if (definition === undefined)
		throw Error(
			`could not find definition ${dragAndDropContext.currentlySelectedDefinition}`,
		)

	const targetComponent = fetchConfigurableComponent(
		componentCollection,
		definition.elementTag,
	)
	if (targetComponent === undefined)
		throw Error(`no component registered with id ${definition.elementTag}`)

	return (
		<PropertyEditorInternal
			schema={props.schema}
			onSchemaModified={props.onSchemaModified}
			definition={definition}
			targetComponent={targetComponent}
		/>
	)
})

type PropertyEditorInternalProps = {
	definition: FormBuilderCellDefinition
	targetComponent: ConfigurableComponentRegistration
}

const PropertyEditorInternal = observer(
	(props: PropertyEditorInternalProps & SidebarProps) => {
		const styles = useStyles()
		const dndContext = useFormBuilderDragAndDropContext()
		const formBuilderContext = useFormBuilderContext()

		const definition = props.definition
		const targetComponent = props.targetComponent

		const PropertiesConfigurator = targetComponent.propertiesConfigurator

		const handleDeleteField = useCallback(
			action(() => {
				const castContext = formBuilderContext.cellManager as DraggableFormHost

				dndContext.currentlySelectedDefinition = NIL
				castContext.deleteDefinition(definition.id)
			}),
			[formBuilderContext, definition],
		)

		return (
			<div className={styles.formBuilderComponentEditor}>
				<ComponentEditorPanelHeader
					definition={definition}
					targetComponent={targetComponent}
				/>
				<Paper className={styles.formBuilderContainer}>
					<div className={styles.componentEditorHeader}>
						<Settings />
						<Typography className={styles.leftPadding} variant="h6">
							Settings
						</Typography>
					</div>
					<div>
						<PropertiesConfigurator
							properties={definition.properties}
							onPropertiesChanged={action(
								(v) => (definition.properties = v as Record<string, unknown>),
							)}
						/>
					</div>
				</Paper>
				<Button
					className={styles.topMargin}
					color="primary"
					variant="outlined"
					onClick={handleDeleteField}
					startIcon={<Delete />}
				>
					Delete Field
				</Button>
			</div>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	componentEditorHeader: {
		display: 'flex',

		alignItems: 'center',

		margin: theme.spacing(1),
	},

	formBuilderComponentEditor: {
		display: 'flex',
		flexDirection: 'column',

		height: percent(100),
		width: percent(100),

		padding: theme.spacing(0, 1),

		overflow: 'auto',
	},

	formBuilderContainer: {
		width: percent(100),
		height: 'fit-content',

		padding: theme.spacing(1),
	},

	topMargin: {
		marginTop: theme.spacing(2),
	},

	leftPadding: {
		marginLeft: theme.spacing(2),
	},
}))

const ComponentEditorPanelHeader = observer(
	({ definition, targetComponent }: PropertyEditorInternalProps) => {
		const styles = useHeaderStyles()
		const context = useFormBuilderDragAndDropContext()
		const modalService = useModals()

		const handleIconButtonClick = useCallback(() => {
			context.currentlySelectedDefinition = NIL
		}, [])

		const valueComponent =
			targetComponent as ConfigurableValueComponentRegistration<
				Record<string, unknown>
			>

		const ValueConfigurator = valueComponent.valueConfigurator

		return (
			<Paper className={styles.componentEditorTitleHeader}>
				<div className={styles.componentEditorTitleTop}>
					<IconButton onClick={handleIconButtonClick} size="large">
						<ArrowBack />
					</IconButton>
					<Typography variant="h6">{targetComponent.displayName}</Typography>
				</div>
				<div className={styles.componentEditorTitleBottom}>
					<FixedHeader label="Component Name">
						<Box
							display="flex"
							alignItems="center"
							border={(theme) => `1px solid ${theme.palette.text.secondary}`}
							borderRadius={0.5}
							width="100%"
							overflow="hidden"
						>
							<Box
								height="100%"
								width="100%"
								bgcolor={(theme) => theme.palette.background.default}
								borderRadius={(theme) => theme.spacing(0.5, 0, 0, 0.5)}
							>
								<Box
									component={Typography}
									variant="subtitle2"
									paddingX={2}
									paddingY={1}
									align="left"
									overflow="hidden"
								>
									{definition.name}
								</Box>
							</Box>
							<Box
								paddingX={1}
								paddingY={0.5}
								height="100%"
								borderLeft={(theme) =>
									`1px solid ${theme.palette.text.secondary}`
								}
							>
								<IconButton
									size="small"
									onClick={() =>
										modalService
											.showForm((props) => {
												return (
													<EditComponentNameDialog
														value={definition.name}
														upperDefinitionNames={context.schema.definitions
															.filter(
																(v) =>
																	v.parentId === definition.parentId &&
																	v.id !== definition.id,
															)
															.map((v) => v.name.toUpperCase().trim())}
														onConfirm={(v) =>
															props.close({
																closeResult: 'okay',
																value: v,
															})
														}
														onCancel={() =>
															props.close({ closeResult: 'cancel' })
														}
													/>
												)
											})
											.then((v) => {
												if (v.closeResult === 'okay')
													definition.name = v.value as string
											})
									}
								>
									<Edit fontSize="small" />
								</IconButton>
							</Box>
						</Box>
					</FixedHeader>
				</div>
				<Divider />
				<div className={styles.componentEditorTitleBottom}>
					{valueComponent.valueConfigurator !== undefined &&
						definition.type === CellType.Value && (
							<ValueConfigurator
								value={definition.value}
								onValueChanged={action((v) => {
									definition.value = v
								})}
								properties={definition.properties}
							/>
						)}
				</div>
			</Paper>
		)
	},
)

type EditComponentNameDialogProps = {
	value: string
	upperDefinitionNames: string[]
	onConfirm: (newName: string) => void
	onCancel: () => void
}

const EditComponentNameDialog = (props: EditComponentNameDialogProps) => {
	const validationSchema = useMemo(
		() =>
			yup.object({
				name: yup
					.string()
					.required('Component Name is a required field')
					.test(
						'is-unique',
						'A component with this name already exists',
						(value) => {
							return (
								value !== undefined &&
								!props.upperDefinitionNames.includes(value.toUpperCase().trim())
							)
						},
					),
			}),
		[],
	)

	const formik = useFormik({
		initialValues: {
			name: props.value,
		},
		validationSchema,
		onSubmit: (values) => {
			props.onConfirm(values.name)
		},
	})

	return (
		<>
			<DialogTitle>Edit Component Name</DialogTitle>
			<DialogContent>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.errors.name}
					hintColor={formik.errors.name ? 'error' : undefined}
				>
					<TextField
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
						error={!!formik.errors.name && formik.touched.name}
					/>
				</FixedHeaderWithHint>
				<DialogActions>
					<Button variant="outlined" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={formik.submitForm}
						disabled={!formik.isValid || !formik.dirty}
					>
						Save
					</Button>
				</DialogActions>
			</DialogContent>
		</>
	)
}

const useHeaderStyles = makeStyles((theme: Theme) => ({
	componentEditorTitleHeader: {
		display: 'flex',
		flexDirection: 'column',

		marginBottom: theme.spacing(1),
	},

	componentEditorTitleTop: {
		display: 'flex',

		alignItems: 'center',
	},

	componentEditorTitleBottom: {
		padding: theme.spacing(1),
	},
}))
