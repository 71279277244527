import { Box, LinearProgress } from '@mui/material'
import { Mutex } from 'async-mutex'
import { makeObservable, observable, runInAction } from 'mobx'
import { CommitIntent } from '../../api/DTOtemp'
import {
	NotificationCenterContextApi,
	NotificationCenterNotification,
	NotificationRegistration,
} from '../../components/notificationCenter'
import { handleSubmitAnonymousPackage } from '../../pages/PackageHostPage/AnonymousFormPackageHostPage'
import { handleSubmitPackage } from '../../pages/PackageHostPage/FormPackageHostPage'
import { OfflineContextType } from '../../pages/PackageHostPage/PackageListPageContext'
import { handleCompleteApproval } from '../../pages/PackageHostPage/ReceivedFormPackageHostPage'
import { toastService } from '../notifications/ToastService'
import { SessionService } from '../session'
import { sendCachedPackageToServer } from './FormPackageBackgroundSync'
import {
	getFormPackages,
	getRetrievalFormPackages,
} from './FormPackageHandling'
import {
	IndexDbFormPackage,
	IntendedUsage,
	RetrievalStorageType,
} from './FormPackageStorage'
import { retrieveScopedDatabase } from './indexedDb'

class FormPackageBackgroundSyncService {
	private _notificationCenterApi: NotificationCenterContextApi
	private _offlinePackageContext: OfflineContextType

	private _packageDownloadLoopHandle: number
	private _submitOfflinePackageHandle: number

	public _anonymous: boolean

	constructor(
		notificationCenterApi: NotificationCenterContextApi,
		offlinePackageContext: OfflineContextType,
	) {
		this._notificationCenterApi = notificationCenterApi
		this._offlinePackageContext = offlinePackageContext
		this._packageDownloadLoopHandle = 0
		this._submitOfflinePackageHandle = 0

		const sessionService = new SessionService()
		this._anonymous = !sessionService.isLoggedIn

		makeObservable<FormPackageBackgroundSyncService>(this, {
			_anonymous: observable,
		})
	}

	// should run every minute
	public async start() {
		console.log('starting form package background service')
		const registerPackageDownloadDisposer = this.registerPackageDownload()
		const registerOfflineSubmitDisposer = await this.registerOfflineSubmit()

		return () => {
			registerPackageDownloadDisposer()
			registerOfflineSubmitDisposer()
		}
	}

	private async registerOfflineSubmit() {
		if (this._submitOfflinePackageHandle > 0)
			window.clearInterval(this._submitOfflinePackageHandle)

		const database = await retrieveScopedDatabase()

		let notificationHandle: NotificationRegistration | undefined = undefined

		const SubmittingNotificationBody = () => (
			<Box
				display="flex"
				flex="1"
				flexDirection="column"
				gap={1}
				paddingBottom={1}
			>
				<Box flex={'1'}>Submitting offline form packages...</Box>
				<LinearProgress />
			</Box>
		)

		const loopFn = async () => {
			if (!navigator.onLine) return

			if (PackageSubmissionMutex.isLocked()) {
				console.log(
					'package submit mutex locked, skipping iteration to prevent duplicated submit',
				)
				return
			}

			const release = await PackageSubmissionMutex.acquire()

			try {
				// eslint-disable-next-line no-debugger
				// debugger

				const transaction = database.transaction(
					'storedPackageCache',
					'readonly',
				)
				const packageCache = transaction.objectStore('storedPackageCache')

				const packageCacheCursor = await packageCache.openCursor()

				if (packageCacheCursor === null) return // no results in cursor
				let hasNotifiedSubmitting = false

				const promiseCollection = []
				let currentElement: IndexDbFormPackage | undefined =
					packageCacheCursor.value as IndexDbFormPackage | undefined

				if (
					currentElement !== undefined &&
					currentElement.intendedUsage & IntendedUsage.Submission &&
					!hasNotifiedSubmitting
				) {
					if (notificationHandle === undefined) {
						console.log('❌ - creating new registration')
						notificationHandle =
							this._notificationCenterApi.registerNotification(
								observable({
									title: 'Submitting Offline Packages',
									status: 'info',
									body: <SubmittingNotificationBody />,
								}),
							)
					} else {
						notificationHandle.notification.title =
							'Submitting Offline Packages'
						notificationHandle.notification.status = 'info'
						notificationHandle.notification.body = (
							<SubmittingNotificationBody />
						)
					}
					hasNotifiedSubmitting = true
				}

				let shouldNotify = false

				while (currentElement) {
					if (!(currentElement.intendedUsage & IntendedUsage.Submission)) {
						currentElement = (await packageCacheCursor.continue())?.value as
							| IndexDbFormPackage
							| undefined
						continue
					}

					shouldNotify = true

					const saveFn = (() => {
						if (
							currentElement.action === CommitIntent.Approve ||
							currentElement.action === CommitIntent.Reject
						)
							return handleCompleteApproval

						if (currentElement.intendedUsage & IntendedUsage.Submission) {
							return !this._anonymous
								? handleSubmitPackage
								: handleSubmitAnonymousPackage
						} else
							throw new Error(
								'trying to save a package that does not indicate it should be saved',
							)
					})()

					console.log(
						'sending cached package to server, package id: ' +
							currentElement.instanceId,
					)

					const promise = sendCachedPackageToServer(
						currentElement,
						packageCacheCursor.primaryKey,
						saveFn,
					)
					promiseCollection.push(promise)
					currentElement = (await packageCacheCursor.continue())?.value as
						| IndexDbFormPackage
						| undefined
				}

				transaction.commit()
				await transaction.done

				await Promise.all(promiseCollection)

				if (!shouldNotify) return

				if (promiseCollection.length !== 0) {
					runInAction(() => {
						// notification handle cannot be null b/c we check to see if there's an element
						// as the first thing we do
						notificationHandle!.notification.title =
							'Offline Packages Submitted'
						notificationHandle!.notification.body =
							'All offline packages were successfully submitted'
						notificationHandle!.notification.status = 'success'
					})
				}

				toastService.displayToast({
					message: 'Packages Submitted Offline Synced',
					area: 'global',
				})
			} catch (e) {
				console.error(
					'error occurred in loop function for background sync service',
					e,
				)
				// TODO update
				notificationHandle!.notification.status = 'error'
				notificationHandle!.notification.title =
					'Failed to submit offline packages'
				notificationHandle!.notification.body =
					'Some offline packages failed to submit'
			} finally {
				release()
			}
		}

		loopFn()

		this._submitOfflinePackageHandle = window.setInterval(loopFn, 60 * 1000)

		return () => {
			window.clearInterval(this._submitOfflinePackageHandle)
		}
	}

	private registerPackageDownload() {
		if (this._packageDownloadLoopHandle != 0) {
			window.clearInterval(this._packageDownloadLoopHandle)
		}

		const DownloadingNotificationBody = () => (
			<Box
				display="flex"
				flex="1"
				flexDirection="column"
				gap={1}
				paddingBottom={1}
			>
				<Box flex={'1'}>Downloading offline form packages...</Box>
				<LinearProgress />
			</Box>
		)

		const DownloadedNotificationBody = () => (
			<>Downloaded offline form packages</>
		)

		const createNewNotification = (): NotificationCenterNotification => {
			return observable({
				title: 'Downloading Offline Packages',
				body: <DownloadingNotificationBody />,
				status: 'info',
			})
		}

		let notificationHandle: NotificationRegistration | undefined = undefined

		const intervalFn = () => {
			console.log(notificationHandle?.id)

			if (notificationHandle === undefined || notificationHandle.isRemoved) {
				notificationHandle = this._notificationCenterApi.registerNotification(
					createNewNotification(),
				)
			} else {
				runInAction(() => {
					notificationHandle!.notification.title =
						'Downloading Offline Packages'
					notificationHandle!.notification.body = (
						<DownloadingNotificationBody />
					)
				})
			}

			const abortController = new AbortController()

			const savedPromise = getRetrievalFormPackages(
				RetrievalStorageType.Saved,
				this._offlinePackageContext.savedPackageList,
				abortController.signal,
			)

			const receivedPromise = getRetrievalFormPackages(
				RetrievalStorageType.Received,
				this._offlinePackageContext.receivedPackageList,
				abortController.signal,
			)

			const formPackagesPromise = getFormPackages(
				this._offlinePackageContext,
				abortController.signal,
			)

			Promise.all([savedPromise, receivedPromise, formPackagesPromise]).then(
				() => {
					runInAction(() => {
						if (notificationHandle !== undefined) {
							notificationHandle.notification.status = 'success'
							notificationHandle.notification.title =
								'Offline Packages Downloaded'
							notificationHandle.notification.body = (
								<DownloadedNotificationBody />
							)
						}
					})
				},
			)
		}

		intervalFn()

		this._packageDownloadLoopHandle = window.setInterval(intervalFn, 1000 * 60)

		return () => {
			window.clearInterval(this._packageDownloadLoopHandle)
			notificationHandle?.remove()
		}
	}
}

export const PackageSubmissionMutex: Mutex = new Mutex()

export default FormPackageBackgroundSyncService
