import { Check, Save } from '@mui/icons-material'
import Edit from '@mui/icons-material/Edit'
import {
	Button,
	Divider,
	IconButton,
	Tab,
	Tabs,
	TextField,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { observable } from 'mobx'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { NIL } from 'uuid'
import {
	BaseDataProviderInstanceModel,
	DataProviderConfiguration,
	DataProviderInstance,
	DataProviderInstanceSettings,
} from '../../../../api/clients/dataProviders/DTO'
import DataProviderInstancesClient from '../../../../api/clients/dataProviders/DataProviderInstancesClient'
import DataProvidersClient from '../../../../api/clients/dataProviders/DataProvidersClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { StandaloneCellManager } from '../../../../modules/FormBuilderInterop/Runtimes/Standalone'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { ConfigureDataProvider } from './ConfigureDataProvider'
import { TestDataProvider } from './TestDataProvider'

export const DataProviderInstancePage: React.FC = () => {
	const styles = useStyles()
	const { instanceId } = useParams<{ instanceId: string }>()

	const instancesClient = new DataProviderInstancesClient()
	const providersClient = new DataProvidersClient()

	const [instance, setInstance] = useState<DataProviderInstance>()
	const [dataProvider, setDataProvider] = useState<DataProviderConfiguration>()
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [editingName, setEditingName] = useState(false)
	const [name, setName] = useState(instance?.displayName)

	const instanceProperties = useMemo(() => {
		if (instance !== undefined && instance.properties !== undefined)
			return observable(instance.properties as Record<string, unknown>)
	}, [instance?.properties])

	const instanceSettings = useMemo<DataProviderInstanceSettings>(() => {
		if (instance === undefined) return { anonymousAccess: false }
		return observable(instance.settings)
	}, [instance?.settings])

	const getInstance = async () => {
		const { data } = await instancesClient.getInstance(instanceId)
		const instance = data
		setInstance(instance)
		setName(instance.displayName)

		if (data.dataProviderId === NIL) {
			setSelectedIndex(1)
			return
		}

		const response = await providersClient.getDataProvider(
			instance.dataProviderId,
		)
		setDataProvider(response.data)
	}

	const handleTabIndexChanged = useCallback(
		(event: React.SyntheticEvent, newValue: number) => {
			setSelectedIndex(newValue)
		},
		[],
	)

	const handleEditingName = () => {
		setEditingName(true)
	}

	const handleNameChange = (
		event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setName(event.currentTarget.value)
	}

	const handleUpdateName = async () => {
		if (name === undefined) return

		if (instance !== undefined && name !== instance?.displayName) {
			await instancesClient.updateInstance(instanceId, {
				dataProviderId: instance.dataProviderId,
				displayName: name,
				properties: instance.properties ?? {},
				settings: instance.settings,
			})

			toastService.displayToast({
				message: 'Name updated successfully',
				area: 'global',
			})
		}

		setEditingName(false)
	}

	const cellManager = useMemo(() => {
		if (
			instance !== undefined &&
			dataProvider !== undefined &&
			instanceProperties !== undefined
		) {
			return new StandaloneCellManager(dataProvider.formBuilderSchema)
		}
	}, [instance, dataProvider])

	const handleSaveChanges = async () => {
		if (instance === undefined || cellManager === undefined) {
			toastService.displayToast({
				message: 'Failed to save changes',
				area: 'global',
			})
			return
		}

		const baseModel: BaseDataProviderInstanceModel = {
			dataProviderId: instance.dataProviderId,
			displayName: name ?? instance.displayName,
			properties: instanceProperties ?? {},
			settings: instanceSettings,
		}

		const { data } = await instancesClient.updateInstance(instanceId, baseModel)

		setInstance(data)
		toastService.displayToast({
			message: 'Data Provider updated',
			area: 'global',
		})
	}

	useEffect(() => {
		getInstance()
	}, [])

	return (
		<>
			{instance === undefined ? (
				<FullscreenSpinner />
			) : (
				<AdministrationPageContainer
					title={
						<div className={styles.title}>
							{editingName ? (
								<>
									<TextField
										inputProps={{ className: styles.nameInput }}
										defaultValue={name}
										onChange={handleNameChange}
									/>
									<IconButton
										className={styles.nameIcon}
										size="small"
										onClick={handleUpdateName}
									>
										<Check />
									</IconButton>
								</>
							) : (
								<>
									{name}
									{instance.dataProviderId !== NIL && (
										<IconButton
											className={styles.nameIcon}
											size="small"
											onClick={handleEditingName}
										>
											<Edit />
										</IconButton>
									)}
								</>
							)}
						</div>
					}
					actions={
						<div>
							<Button
								color="primary"
								variant="contained"
								onClick={handleSaveChanges}
								startIcon={<Save />}
							>
								Save
							</Button>
						</div>
					}
				>
					<div className={styles.root}>
						<Tabs
							textColor="primary"
							indicatorColor="primary"
							value={selectedIndex}
							onChange={handleTabIndexChanged}
						>
							{instance.dataProviderId !== NIL && (
								<Tab tabIndex={0} key="configure" label="Configure" />
							)}
							<Tab value={1} key="test" label="Test" />
							{/* TODO - come back to statistics! hidden for now*/}
							{/* <Tab value={2} key="statistics" label="Statistics" /> */}
						</Tabs>
						<Divider />
						{instance.dataProviderId !== NIL && selectedIndex === 0 && (
							<ConfigureDataProvider
								instanceProperties={instanceProperties}
								settings={instanceSettings}
							/>
						)}
						{selectedIndex === 1 && <TestDataProvider />}
						{/* {selectedIndex === 2 && 'Statistics coming soon!'} */}
					</div>
				</AdministrationPageContainer>
			)}
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: percent(100),
		minHeight: percent(100),
	},

	title: {
		display: 'flex',
	},

	textField: {
		width: percent(50),
	},

	nameInput: {
		fontWeight: 500,
		fontSize: px(24),
	},

	nameIcon: {
		marginLeft: theme.spacing(1),
	},
}))
