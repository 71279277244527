import { Warning } from '@mui/icons-material'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { action } from 'mobx'
import { useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import {
	AuthenticationProviderInstanceMetadata,
	AuthenticationProviderSettings,
} from '../../../../api/clients/authenticationProviders/DTO'
import DescriptiveSwitch from '../../../../components/inputs/DescriptiveSwitch'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

export const FormsInMotionAuthenticationId = 'FormsInMotion Authentication'

type AuthenticationProviderSettingsDialogProps = {
	originalSettings: AuthenticationProviderSettings
	instances: AuthenticationProviderInstanceMetadata[]
	onConfirm: (settings: AuthenticationProviderSettings) => void
	onCancel: () => void
}

export const AuthenticationProviderSettingsDialog = observer(
	(props: AuthenticationProviderSettingsDialogProps) => {
		const localStore = useLocalObservable(() => ({
			isFimAuthDisabled: props.originalSettings.isFimAuthDisabled,
			defaultProviderId: props.originalSettings.defaultProviderId,

			get defaultInstance() {
				return props.instances.find((v) => v.id === this.defaultProviderId)
			},
		}))

		return (
			<>
				<DialogTitle>Settings</DialogTitle>
				<DialogContent>
					<DescriptiveSwitch
						headerLabel="Disable FormsInMotion Authentication"
						bodyLabel="Require authentication from an external authentication provider"
						checked={localStore.isFimAuthDisabled}
						onChange={action(() => {
							localStore.isFimAuthDisabled = !localStore.isFimAuthDisabled

							console.log(localStore.isFimAuthDisabled)
							// we can't have FIM auth as a default if it's disabled!
							// so choose the first in the list if FIM was the default
							if (
								localStore.isFimAuthDisabled &&
								localStore.defaultProviderId === FormsInMotionAuthenticationId
							) {
								localStore.defaultProviderId = props.instances[0].id
							}
						})}
						color="primary"
						disabled={
							props.instances.filter((v) => !v.isMissingPlugin).length === 0
						} // cannot disable FiM auth if it's the only option
					/>
					<FixedHeaderWithHint
						label="Default Authentication Provider"
						hint="Users will sign in with this provider unless specified otherwise at login"
					>
						<Select fullWidth size="small" value={localStore.defaultProviderId}>
							{props.instances.map((instance) => (
								<MenuItem
									key={instance.id}
									value={instance.id}
									onClick={action(
										() =>
											(localStore.defaultProviderId = instance.id.toString()),
									)}
								>
									<Box alignItems="center" gap={1} display="flex">
										{instance.isMissingPlugin && <Warning color="error" />}
										<Typography
											variant="subtitle1"
											color={instance.isMissingPlugin ? 'error' : ''}
										>
											{instance.displayName}
										</Typography>
									</Box>
								</MenuItem>
							))}
							{!localStore.isFimAuthDisabled && (
								<MenuItem
									value={FormsInMotionAuthenticationId}
									onClick={action(
										() =>
											(localStore.defaultProviderId =
												FormsInMotionAuthenticationId),
									)}
								>
									<Typography variant="subtitle1">
										{FormsInMotionAuthenticationId}
									</Typography>
								</MenuItem>
							)}
						</Select>
					</FixedHeaderWithHint>
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant="outlined" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						disabled={
							(localStore.defaultProviderId ===
								props.originalSettings.defaultProviderId &&
								localStore.isFimAuthDisabled ===
									props.originalSettings.isFimAuthDisabled) ||
							localStore.defaultInstance?.isMissingPlugin
						}
						onClick={() => {
							console.log(localStore.isFimAuthDisabled)
							props.onConfirm({
								isFimAuthDisabled: localStore.isFimAuthDisabled,
								defaultProviderId: localStore.defaultProviderId,
							})
						}}
					>
						Update
					</Button>
				</DialogActions>
			</>
		)
	},
)
