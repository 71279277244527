import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import { UserProperty, UserPropertySimple } from '../../../../api/DTOtemp'
import DescriptiveSwitch from '../../../../components/inputs/DescriptiveSwitch'
import { toastService } from '../../../../services/notifications/ToastService'

type UserPropertyOptionsSectionProps = {
	userProperty: UserProperty
	upperPropertyNames: string[]
	onUpdateUserProperty: (
		propertyId: number,
		updatedProperty: UserPropertySimple,
	) => void
}

export const UserPropertyOptionsSection = observer(
	({
		userProperty,
		upperPropertyNames,
		onUpdateUserProperty,
	}: UserPropertyOptionsSectionProps) => {
		const styles = useStyles()

		const validationSchema = yup.object({
			userPropertyName: yup
				.string()
				.required('Name is a required field')
				.test(
					'is-unique',
					'A user property with this name already exists in your organization',
					(value) => {
						return (
							value !== undefined &&
							(value === userProperty.name ||
								!upperPropertyNames.includes(value.toUpperCase().trim()))
						)
					},
				),
			userEditable: yup.boolean(),
		})

		const formik = useFormik({
			initialValues: {
				userPropertyName: userProperty.name,
				userEditable: userProperty.userEditable,
			},
			validationSchema: validationSchema,

			onSubmit: (values) => {
				try {
					onUpdateUserProperty(userProperty.id, {
						name: values.userPropertyName,
						userEditable: values.userEditable,
					})
					toastService.displayToast({
						message: 'User property updated',
						area: 'global',
					})
				} catch {
					toastService.displayToast({
						message: 'Error updating user property',
						area: 'global',
					})
				}
			},
		})

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Typography
						className={styles.topBottomContent}
						variant="h6"
						color="textPrimary"
					>
						Settings
					</Typography>
					<Divider />
					<div className={styles.formSettingsRoot}>
						<div className={styles.formElement}>
							<TextField
								id="userPropertyName"
								name="userPropertyName"
								value={formik.values.userPropertyName}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								helperText={
									formik.touched.userPropertyName &&
									formik.errors.userPropertyName
								}
								error={
									!!formik.errors.userPropertyName &&
									formik.touched.userPropertyName
								}
								label="Name"
								fullWidth
								autoComplete="off"
							/>
						</div>
						<DescriptiveSwitch
							id="userEditable"
							name="userEditable"
							checked={formik.values.userEditable}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							containerClassName={styles.formElement}
							color="primary"
							headerLabel="User Editable"
							bodyLabel="Allow users to edit their value for this property"
						/>
					</div>
					<div>
						<Divider />
						<div
							className={clsx(styles.topBottomContent, styles.bottomFlexbox)}
						>
							<Button
								color="primary"
								variant="contained"
								className={styles.noTextTransform}
								disabled={!formik.isValid || !formik.dirty}
								onClick={formik.submitForm}
							>
								Save Changes
							</Button>
						</div>
					</div>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	userPropertyContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},
}))
