import { Backdrop, Box, CircularProgress, useTheme } from '@mui/material'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import {
	Fragment,
	PropsWithChildren,
	ReactNode,
	createContext,
	useContext,
} from 'react'

export type BlockingTask = {
	id: number
	complete: () => void
}

type BlockingTaskArgs = {
	show: ReactNode
}

type InternalBlockingTaskMetadata = BlockingTaskArgs & BlockingTask

class BlockingTaskService {
	private _nextId = 0

	private readonly _tasks: InternalBlockingTaskMetadata[] = []

	public get tasks(): InternalBlockingTaskMetadata[] {
		return this._tasks
	}

	constructor() {
		makeAutoObservable(this)
	}

	public addBlockingTask(args: BlockingTaskArgs): BlockingTask {
		const newTaskId = this._nextId++

		const internalTask: InternalBlockingTaskMetadata = {
			...args,
			id: newTaskId,
			complete: () => {
				const index = this._tasks.findIndex((v) => v.id === newTaskId)
				this._tasks.splice(index, 1)
			},
		}

		this._tasks.push(internalTask)

		return internalTask
	}
}

const instance = new BlockingTaskService()

const context = createContext(instance)

export const BlockingOperationBarrierContextProvider = (
	props: PropsWithChildren<unknown>,
) => {
	return (
		<context.Provider value={instance}>
			<BlockingTaskDisplayComponent />
			{props.children}
		</context.Provider>
	)
}

export const useBlockingOperationBarrier = (): BlockingTaskService => {
	const contextInstance = useContext(context)
	return contextInstance
}

const BlockingTaskDisplayComponent = observer(() => {
	const taskLength = instance.tasks.length

	const theme = useTheme()

	return (
		<Backdrop
			open={taskLength !== 0}
			sx={{
				zIndex: (theme) => theme.zIndex.drawer + 1,
			}}
		>
			<Box
				display={'flex'}
				flexDirection={'column'}
				gap={2}
				color={'white'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				{instance.tasks.map((v) => (
					<Fragment key={v.id}>{v.show}</Fragment>
				))}
				<CircularProgress />
			</Box>
		</Backdrop>
	)
})
