import { Box } from '@mui/material'
import { percent, px } from 'csx'
import { PropsWithChildren, ReactNode } from 'react'

type NodeBodyWrapperProps = {
	color: string
	topSection: ReactNode
}

// top section, left vertical box, and small box below the content - for wrapping around a node's body
const NodeBodyWrapper = (props: PropsWithChildren<NodeBodyWrapperProps>) => {
	return (
		<Box display="flex" flexDirection="column">
			<Box bgcolor={props.color} padding={1} width="fit-content">
				{props.topSection}
			</Box>
			<Box display="flex">
				<Box width={px(16)} bgcolor={props.color}></Box>
				<Box minWidth={percent(10)}>
					{/* yea, i don't like minWidth buut without it we have the tiniest little block 
						statement to add to if there is no node there */}
					{props.children}
				</Box>
			</Box>
			<Box width={px(120)} height={px(20)} bgcolor={props.color}></Box>
		</Box>
	)
}

export default NodeBodyWrapper
