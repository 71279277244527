import {
	Check,
	Create,
	Delete,
	Edit,
	Settings,
	Warning,
} from '@mui/icons-material'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import AuthenticationProviderInstancesClient from '../../../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import AuthenticationProvidersClient from '../../../../api/clients/authenticationProviders/AuthenticationProvidersClient'
import {
	AuthenticationProviderInstanceMetadata,
	AuthenticationProviderSettings,
	BaseAuthenticationProviderInstanceModel,
	ConfigurableAuthenticationProvider,
} from '../../../../api/clients/authenticationProviders/DTO'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { useModals } from '../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { AddAuthenticationProviderDialog } from './AddAuthenticationProviderDialog'
import { useAuthenticationProviderContext } from './AuthenticationProviderContext'
import { AuthenticationProviderSettingsDialog } from './AuthenticationProviderSettingsDialog'

export const AuthenticationProvidersDashboard = observer(() => {
	const history = useHistory()
	const modalService = useModals()
	const authProviderContext = useAuthenticationProviderContext()

	const styles = useStyles()

	const authenticationProvidersClient = new AuthenticationProvidersClient()
	const instancesClient = new AuthenticationProviderInstancesClient()

	const localStore = useLocalObservable(() => ({
		instances: [] as AuthenticationProviderInstanceMetadata[],
		providers: [] as ConfigurableAuthenticationProvider[],
		settings: undefined as AuthenticationProviderSettings | undefined,
		requestsComplete: false,
	}))

	useEffect(() => {
		Promise.all([getInstances(), getProviders(), getSettings()]).then(
			action(() => {
				localStore.requestsComplete = true
			}),
		)
	}, [])

	const getInstances = async () => {
		const { data: instances } = await instancesClient.getInstanceMetadata()

		runInAction(() => {
			localStore.instances = instances
		})
	}

	const getProviders = async () => {
		const { data: providers } =
			await authenticationProvidersClient.getAuthenticationProviders()

		runInAction(() => {
			localStore.providers = providers
		})
	}

	const getSettings = async () => {
		const { data: settings } =
			await instancesClient.getOrganizationAuthProviderSettings()

		runInAction(() => {
			localStore.settings = settings
		})
	}

	const handleSettingsClicked = () => {
		modalService
			.showForm((props) => {
				if (localStore.settings === undefined) return <></>

				return (
					<AuthenticationProviderSettingsDialog
						originalSettings={{
							defaultProviderId: localStore.settings.defaultProviderId,
							isFimAuthDisabled: localStore.settings.isFimAuthDisabled,
						}}
						instances={localStore.instances}
						onConfirm={(v) =>
							props.close({
								closeResult: 'okay',
								value: v,
							})
						}
						onCancel={() => props.close({ closeResult: 'cancel' })}
					/>
				)
			})
			.then(async (v) => {
				if (v.closeResult === 'okay') {
					try {
						instancesClient.updateOrganizationAuthProviderSettings(
							v.value as AuthenticationProviderSettings,
						)

						runInAction(() => {
							localStore.settings = v.value as AuthenticationProviderSettings
						})

						toastService.displayToast({
							message: 'Settings updated',
							area: 'global',
						})
					} catch (error) {
						console.log(error)
						toastService.displayToast({
							message: 'Error updating settings',
							area: 'global',
						})
					}
				}
			})
	}

	const handleAddProviderClicked = () => {
		modalService
			.showForm((formProps) => (
				<AddAuthenticationProviderDialog
					providers={localStore.providers}
					upperInstanceNames={authProviderContext.organizationInstanceNames.map(
						(v) => v.toUpperCase(),
					)}
					onConfirm={(v) =>
						formProps.close({
							closeResult: 'okay',
							value: v,
						})
					}
					onCancel={() => formProps.close({ closeResult: 'cancel' })}
				/>
			))
			.then(async (v) => {
				if (v.closeResult === 'okay') {
					try {
						const { data: newInstance } =
							await instancesClient.configureInstance(
								v.value as BaseAuthenticationProviderInstanceModel,
							)
						toastService.displayToast({
							message: <Typography>Authentication provider added</Typography>,
							area: 'global',
						})

						authProviderContext.organizationInstanceNames.push(
							newInstance.displayName,
						)

						history.push(`_authentication-providers/${newInstance.id}`)
					} catch (error) {
						console.log(error)

						toastService.displayToast({
							message: (
								<Typography>Error adding authentication provider</Typography>
							),
							area: 'global',
						})
					}
				}
			})
	}

	const handleEditInstance = useCallback((instanceId: string) => {
		console.log('INSTANCE ID', instanceId)
		history.push(`_authentication-providers/${instanceId}`)
	}, [])

	const handleDeleteInstance = async (
		instance: AuthenticationProviderInstanceMetadata,
	) => {
		try {
			await instancesClient.deleteInstance(instance.id)
			toastService.displayToast({
				message: 'Authentication provider deleted',
				area: 'global',
			})

			// not a big deal if we can't find these values in the lists
			// since we're just going to remove them anyways

			const indexToRemove = localStore.instances.findIndex(
				(v) => v.id === instance.id,
			)
			if (indexToRemove >= 0) localStore.instances.splice(indexToRemove, 1)

			const nameIndex = authProviderContext.organizationInstanceNames.indexOf(
				instance.displayName,
			)
			if (nameIndex >= 0)
				authProviderContext.organizationInstanceNames.splice(nameIndex, 1)
		} catch (error) {
			console.log(error)

			toastService.displayToast({
				message: 'Error deleting authentication provider',
				area: 'global',
			})
		}
	}

	if (!localStore.requestsComplete || localStore.settings === undefined)
		return <FullscreenSpinner />

	return (
		<AdministrationPageContainer
			title="Authentication Providers"
			actions={
				<div>
					<Button
						className={styles.firstButton}
						variant="outlined"
						color="primary"
						startIcon={<Settings />}
						onClick={handleSettingsClicked}
					>
						Settings
					</Button>
					<Button
						variant="contained"
						hidden={localStore.providers.length === 0}
						color="primary"
						onClick={handleAddProviderClicked}
						startIcon={<Create />}
					>
						Add Authentication Provider
					</Button>
				</div>
			}
		>
			{localStore.providers.length === 0 &&
			localStore.instances.length === 0 ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					width={percent(100)}
				>
					<Typography variant="subtitle1">
						No Authentication Provider Plugins Included
					</Typography>
				</Box>
			) : (
				<AuthenticationProviderInstancesTable
					instances={localStore.instances}
					settings={localStore.settings}
					onEditInstance={handleEditInstance}
					onDeleteInstance={handleDeleteInstance}
				/>
			)}
		</AdministrationPageContainer>
	)
})

type AuthenticationProviderInstancesTableProps = {
	instances: AuthenticationProviderInstanceMetadata[]
	settings: AuthenticationProviderSettings
	onEditInstance: (instanceId: string) => void
	onDeleteInstance: (instance: AuthenticationProviderInstanceMetadata) => void
}

const AuthenticationProviderInstancesTable = (
	props: AuthenticationProviderInstancesTableProps,
) => {
	const styles = useStyles()
	const promptService = usePrompts()

	console.log(JSON.stringify(props.instances, null, 2))

	return (
		<Fade in>
			<TableContainer className={styles.tableRoot} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Default</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.instances.map((instance) => (
							<TableRow key={instance.id}>
								<TableCell>
									<Typography
										variant="inherit"
										color={instance.isMissingPlugin ? 'error' : ''}
									>
										{instance.displayName}
									</Typography>
								</TableCell>
								<TableCell>
									{instance.isMissingPlugin ? (
										<Box display="flex" alignItems="center" gap={1}>
											<Warning color="error" fontSize="small" />
											<Typography variant="subtitle2" color="error">
												Plugin Missing
											</Typography>
										</Box>
									) : (
										instance.providerType
									)}
								</TableCell>
								<TableCell>{instance.description}</TableCell>
								<TableCell>
									{props.settings.defaultProviderId === instance.id && (
										<Check />
									)}
								</TableCell>
								<TableCell align="right">
									{!instance.isMissingPlugin && (
										<IconButton
											onClick={() => props.onEditInstance(instance.id)}
										>
											<Edit />
										</IconButton>
									)}

									<IconButton
										onClick={() => {
											promptService
												.showDialog((props) => (
													<ConfirmDeleteInstancePrompt
														instanceName={instance.displayName}
														onCancel={() => props.close('cancel')}
														onConfirm={() => props.close('okay')}
													/>
												))
												.then((v) => {
													if (v == 'okay') props.onDeleteInstance(instance)
												})
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

type ConfirmDeleteInstancePromptProps = {
	instanceName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeleteInstancePrompt = (
	props: ConfirmDeleteInstancePromptProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete Authentication Provider</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to remove the <b>{props.instanceName}</b>{' '}
					authentication provider?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},

	textField: {
		margin: theme.spacing(2, 0),
	},

	firstButton: {
		marginRight: theme.spacing(2),
	},
}))
