import { AxiosResponse } from "axios"
import { FormPackageInfo, FormPackageVersion } from "../../DTOtemp"
import SecureClient from "../SecureClient"

export class FormPackageVersionClient extends SecureClient {
	private _packageId: number

	private _urlBase: string

	constructor(packageId: number) {
		super()
		this._packageId = packageId
		this._urlBase = `FormPackages/${packageId}/versions`
	}

	/**
	 * get a list of all package versions that exist for a package
	 */
	public Versions(): Promise<AxiosResponse<FormPackageVersion[]>> {
		return this._api.get<FormPackageVersion[]>(this._urlBase, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	public UpdateConfiguration(versionInfo: FormPackageInfo) {
		return this._api.post<FormPackageInfo>(this._urlBase, versionInfo, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	public GetVersion(versionNumber: number) {
		return this._api.get<FormPackageInfo>(`${this._urlBase}/${versionNumber}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}

	public GetVersionById(versionId: number) {
		return this._api.get<FormPackageInfo>(`FormPackageVersions/${versionId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})

	}
}