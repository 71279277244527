import AddIcon from '@mui/icons-material/Add'
import { Box, Paper, Theme } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { px } from 'csx'
import { Node, Statement } from 'estree'
import { observer } from 'mobx-react'
import { useMemo, useRef, useState } from 'react'
import { useDrop, XYCoord } from 'react-dnd'
import { DraggableComponent } from '../../draggable/DraggableComponent'
import { useProgramContext } from '../../ProgramContext'
import {
	ASTNodeComponents,
	PartiallyConstructedNode,
	RendererComponent,
	ValidNodeTypes,
	WithFormsInfo,
} from '../../Types'
import { isChildOfTree, NodeGetter, NodeSetter, placeNode } from './NodeApi'

export enum DropMode {
	Replace = 'replace',
	Insert = 'insert',
}

type RendererProps = {
	validNodes: ValidNodeTypes[]
	getter: NodeGetter
	setter: NodeSetter
}

type ReplaceRendererProps = RendererProps & {
	dropMode: DropMode.Replace
}

type InsertRendererProps = RendererProps & {
	dropMode: DropMode.Insert
	nodes: Node[]
	insertionIndex: number
}

type Props = ReplaceRendererProps | InsertRendererProps

const Renderer = observer((props: Props) => {
	const displayZoneRef = useRef<HTMLDivElement>()

	const dropZoneRef = useRef<HTMLDivElement>()

	const [dropLocation, setDropLocation] = useState<
		'top' | 'bottom' | undefined
	>(undefined)

	const programContext = useProgramContext()
	const styles = useStyles()

	const node = props.getter()

	const [{ canDrop, isOver }, dropRef] = useDrop({
		accept: props.validNodes,
		collect: (monitor) => ({
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: !!monitor.canDrop(),
		}),
		hover(item, monitor) {
			if (props.dropMode !== DropMode.Insert) return

			if (!displayZoneRef.current) return
			const displayZoneElement = displayZoneRef.current
			const currentItem = props.getter()

			// do not overwrite yourself
			if (item === currentItem) return

			const hoverBoundingRect = displayZoneElement.getBoundingClientRect()
			const clientOffset = monitor.getClientOffset()

			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

			if (hoverClientY > hoverMiddleY && dropLocation !== 'bottom')
				setDropLocation('bottom')

			if (hoverClientY < hoverMiddleY && dropLocation !== 'top')
				setDropLocation('top')
		},
		canDrop: (item) => {
			if (isChildOfTree(item as Node, props.getter() as Node)) return false

			if (item === undefined || item === null) return false

			const nodeItem = item as PartiallyConstructedNode<Node>

			return (
				props.validNodes.map((v) => v.toString()).includes(nodeItem.type) ||
				props.validNodes
					.map((v) => v.toString())
					.includes(nodeItem.formsInfo?.type ?? '')
			)
		},
		drop(item, monitor) {
			if (monitor.didDrop()) return

			if (props.dropMode === DropMode.Insert) {
				placeNode(
					programContext.programs,
					item as Statement,
					() => undefined,
					(node) => {
						// no need to null check drop location - it's gonna be here if we're in insert mode
						props.nodes.splice(
							dropLocation === 'top'
								? props.insertionIndex
								: props.insertionIndex + 1,
							0,
							node as WithFormsInfo<Node>,
						)
					},
				)
			} else {
				placeNode(
					programContext.programs,
					item as Node,
					props.getter,
					props.setter,
				)
			}
		},
	})

	if (!isOver && dropLocation !== undefined) setDropLocation(undefined)

	const insertComponentClassNames = useMemo(() => {
		return clsx(styles.dropContainerStyle, {
			[styles.showBorder]: isOver && canDrop,
		})
	}, [isOver, canDrop])

	// so we can only show border if we can replace the item
	const elementClassNames = useMemo(() => {
		return clsx(styles.dropContainerStyle, {
			[styles.showBorder]:
				isOver && canDrop && props.dropMode === DropMode.Replace,
		})
	}, [isOver, canDrop, dropLocation])

	dropRef(dropZoneRef)

	return (
		<Box ref={dropZoneRef} position="relative">
			{/* upper drop zone if props are statement */}
			{props.dropMode === DropMode.Insert && dropLocation === 'top' && (
				<Box padding={0.5}>
					<Box
						className={insertComponentClassNames}
						bgcolor={blue[50]}
						height={px(30)}
						width={px(100)}
					/>
				</Box>
			)}

			<Box ref={displayZoneRef}>
				{node !== undefined ? (
					(() => {
						const Element = ASTNodeComponents[node.type] as React.ComponentType<
							RendererComponent<Node>
						>
						if (Element === undefined)
							throw new Error('cannot render a ' + node.type)

						return (
							<DraggableComponent node={node}>
								<Box className={elementClassNames}>
									<Element node={node} />
								</Box>
							</DraggableComponent>
						)
					})()
				) : (
					<Box
						component={Paper}
						display="flex"
						justifyContent="center"
						alignItems="center"
						paddingX={3}
						paddingY={1}
						border={1}
						borderColor={grey[800]}
						borderRadius={4}
						elevation={0}
						bgcolor={(theme) => {
							if ((!canDrop && !isOver) || (!canDrop && isOver))
								return theme.palette.background.paper

							if (canDrop && !isOver) {
								return theme.palette.grey[400]
							}

							return blue[500]
						}}
					>
						<AddIcon color="primary" />
					</Box>
				)}
			</Box>

			{/* lower drop zone if props are statement */}
			{props.dropMode === DropMode.Insert && dropLocation === 'bottom' && (
				<Box padding={0.5} minWidth={px(60)}>
					<Box
						className={insertComponentClassNames}
						bgcolor={blue[50]}
						height={px(30)}
						width={px(100)}
					></Box>
				</Box>
			)}
		</Box>
	)
})

export const useStyles = makeStyles((theme: Theme) => ({
	dropContainerStyle: {
		// we can set this to 1 if we want everything to have a border
		border: 0,
		borderStyle: 'solid',
		borderColor: grey[800],
		display: 'block',
		zIndex: 5,

		cursor: 'move',
	},

	showBorder: {
		borderStyle: 'solid',
		border: 2,
		margin: px(-2), // so that everything doesn't move with a thicker border
		borderColor:
			theme.palette.mode === 'light'
				? theme.palette.primary.light
				: theme.palette.primary.dark,
	},
}))

export default Renderer
