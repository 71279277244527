import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { DataProviderInstancePage } from './DataProviderInstancePage'
import { DataProvidersDashboard } from './DataProvidersDashboard'

export const DataProvidersRouter: React.FC = () => {
	const { path } = useRouteMatch()

	updateBreadcrumbs(`/_administration/_data-providers`, 'Data Providers')

	return (
		<Switch>
			<Route path={makeChildRoute(':instanceId', path)}>
				<DataProviderInstancePage />
			</Route>

			<Route path={makeChildRoute('', path)}>
				<DataProvidersDashboard />
			</Route>
		</Switch>
	)
}
