import { KeyboardArrowDown } from '@mui/icons-material'
import {
	Button,
	Checkbox,
	FormControlLabel,
	Menu,
	MenuItem,
} from '@mui/material'
import { ChangeEvent, useRef, useState } from 'react'

interface MultiSelectProps {
	label: string
	onChange: (value: unknown[]) => void
	options: { label: string; value: unknown }[]
	value: unknown[]
}

// displays a button with a menu that has all the multiselect options
// that can be chosen with checkboxes - inspired by Devias Material Kit Pro
export const MultiSelect = (props: MultiSelectProps) => {
	const anchorRef = useRef<HTMLButtonElement | null>(null)
	const [openMenu, setOpenMenu] = useState<boolean>(false)

	const handleOpenMenu = (): void => {
		setOpenMenu(true)
	}

	const handleCloseMenu = (): void => {
		setOpenMenu(false)
	}

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		let newValue = [...props.value]

		if (event.target.checked) newValue.push(event.target.value)
		else newValue = newValue.filter((item) => item != event.target.value)

		props.onChange(newValue)
	}

	return (
		<>
			<Button
				color="inherit"
				endIcon={<KeyboardArrowDown fontSize="small" />}
				onClick={handleOpenMenu}
				ref={anchorRef}
			>
				{props.label}
			</Button>
			<Menu
				anchorEl={anchorRef.current}
				onClose={handleCloseMenu}
				open={openMenu}
				PaperProps={{ style: { width: 250 } }}
			>
				{props.options.map((option) => (
					<MenuItem key={option.label}>
						<FormControlLabel
							control={
								<Checkbox
									checked={props.value.includes(option.value)}
									onChange={handleChange}
									value={option.value}
								/>
							}
							label={option.label}
							sx={{
								flexGrow: 1,
								mr: 0,
							}}
						/>
					</MenuItem>
				))}
			</Menu>
		</>
	)
}
