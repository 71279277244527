import { isVirtualValueKey } from "../FormBuilderCellInstance";
import { FormBuilderType } from "./CellManager";

/**
 * creates a value object as a snapshot of the current value object
 * @param type the form builder type that will generate a value object
 */
export function createFormBuilderValueObject(type: FormBuilderType, includeProps = false) {
	const stringObject = JSON.stringify(type.rootNode, (key, value) => {
		if (!includeProps && isVirtualValueKey(key))
			return undefined;
		return value;
	})

	return JSON.parse(stringObject);
}