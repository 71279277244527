import { WorkflowStatus } from '../../api/clients/workflows/DTOs'

export const workflowStatuses: Record<
	WorkflowStatus,
	{ label: string; color: string }
> = {
	[WorkflowStatus.Created]: {
		label: 'Created',
		color: 'default',
	},
	[WorkflowStatus.Idle]: {
		label: 'Halted',
		color: 'primary',
	},
	[WorkflowStatus.Queued]: {
		label: 'Queued',
		color: 'info',
	},
	[WorkflowStatus.Executing]: {
		label: 'Executing',
		color: 'secondary',
	},
	[WorkflowStatus.Finished]: {
		label: 'Finished',
		color: 'success',
	},
	[WorkflowStatus.Faulted]: {
		label: 'Faulted',
		color: 'error',
	},
	[WorkflowStatus.Aborted]: {
		label: 'Aborted',
		color: 'warning',
	},
}
