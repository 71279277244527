import { DeleteForeverOutlined, OfflineBoltOutlined } from '@mui/icons-material'
import Security from '@mui/icons-material/Security'
import {
	Button,
	Card,
	Divider,
	IconButton,
	List,
	ListItem,
	Theme,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { action, isObservable } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MonacoEditor } from '../../../CodeEditor/MonacoEditor'
import {
	ConditionalFormAction,
	FormBuilderSchema,
} from '../../../FormBuilderCore/Types'
import { TextInput } from '../../Components/Inputs/TextInput'

type FormBuilderEventEditorProps = {
	schemaReference: FormBuilderSchema
}

export const FormBuilderEventEditor = observer(
	(props: FormBuilderEventEditorProps) => {
		const styles = useStyles()

		const state = useLocalObservable(() => ({
			selectedItem: null as string | null,
		}))

		const actions = props.schemaReference.handlers?.actions ?? []

		const selectedEvent = actions.find((v) => v.id === state.selectedItem)

		return (
			<div className={styles.expressionEditorRoot}>
				<ExistingEventSidebar
					actions={actions}
					selectedActionId={state.selectedItem ?? ''}
					onActionSelected={action((v: string) => {
						state.selectedItem = v
						console.log('item selected')
					})}
				/>
				<Divider orientation="vertical" />
				{selectedEvent !== undefined ? (
					<EventEditor selectedEvent={selectedEvent} />
				) : (
					<Typography
						className={styles.selectEventContainer}
						variant="h4"
						color="textSecondary"
					>
						No Event Selected
					</Typography>
				)}
			</div>
		)
	},
)

const useStyles = makeStyles((theme) => ({
	expressionEditorRoot: {
		flex: 1,
		display: 'flex',
	},
	selectEventContainer: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

type ExistingEventSidebarProps = {
	actions: ConditionalFormAction[]
	selectedActionId: string
	onActionSelected(id: string): void
}

const ExistingEventSidebar = observer((props: ExistingEventSidebarProps) => {
	const styles = useEventPickerStyles()

	const onAddEventClicked = () => {
		props.actions.push({
			name: 'new event',
			id: uuidv4(),
			action: {
				type: 'pureJs',
				text: 'console.log("Hello World!")',
			},
			shouldActivate: {
				type: 'pureJs',
				text: 'return true',
			},
		})
	}

	const removeItem = (v: ConditionalFormAction) => {
		const actions = props.actions
		const index = actions.indexOf(v)
		actions.splice(index, 1)
	}

	console.log('events is observable? ', isObservable(props.actions))

	return (
		<div className={styles.expressionPickerSection}>
			<Typography variant="h5" color="textSecondary">
				Events
			</Typography>
			<Divider />
			<List className={styles.eventList}>
				{props.actions.map((v) => (
					<ListItem
						key={v.id}
						className={styles.eventListItem}
						button
						selected={v.id === props.selectedActionId}
						onClick={() => props.onActionSelected(v.id)}
					>
						<div className={styles.eventListHeader}>
							<OfflineBoltOutlined
								className={styles.actionIcon}
								color={'primary'}
							/>
							<Typography variant="h6">{v.name}</Typography>
							<IconButton
								className={styles.deleteButton}
								onMouseDown={(e) => e.stopPropagation()}
								onClick={(e) => {
									e.stopPropagation()
									removeItem(v)
								}}
								size="large"
							>
								<DeleteForeverOutlined color="primary" />
							</IconButton>
						</div>

						<Typography variant="subtitle1">{v.description}</Typography>
						<Typography variant="subtitle2">
							{v.shouldActivate.type} &amp; {v.action.type}
						</Typography>
					</ListItem>
				))}
			</List>
			<Button
				className={styles.addElementButton}
				variant="contained"
				color="primary"
				onClick={onAddEventClicked}
			>
				Add Item
			</Button>
		</div>
	)
})

const panelWidth = 384

const useEventPickerStyles = makeStyles((theme: Theme) => ({
	expressionPickerSection: {
		display: 'flex',
		flexDirection: 'column',
		width: px(panelWidth),
		backgroundColor: theme.palette.background.paper,
	},
	eventListWrapper: {
		display: 'flex',
		flex: 1,
	},
	eventList: {
		flex: 1,
	},
	eventListItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	eventListHeader: {
		flex: '1',
		width: percent(100),
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	addElementButton: {
		margin: theme.spacing(0, 1, 1, 1),
	},
	deleteButton: {
		marginLeft: 'auto',
	},
	actionIcon: {
		marginRight: theme.spacing(1),
	},
}))

type EventEditorProps = {
	selectedEvent: ConditionalFormAction
}

const EventEditor = observer((props: EventEditorProps) => {
	const styles = useEventEditorStyles()

	const state = useLocalObservable(() => ({
		selectedValue: 'guard' as string,
	}))

	const currentValue = (function () {
		if (state.selectedValue === 'action') return props.selectedEvent.action.text
		else return props.selectedEvent.shouldActivate.text
	})()

	return (
		<div className={styles.eventEditorRoot}>
			<Card className={styles.eventEditorRoot}>
				<TextInput
					value={props.selectedEvent.name}
					label="Name"
					onChange={action((v) => {
						props.selectedEvent.name = v.currentTarget.value
					})}
				/>
				<TextInput
					value={props.selectedEvent.description ?? ''}
					label="Description"
					onChange={action((v) => {
						props.selectedEvent.description = v.currentTarget.value
					})}
				/>
				<div className={styles.editorHeader}>
					<Typography className={styles.editorHeaderName} variant="h6">
						{state.selectedValue === 'guard' ? 'Guard' : 'Event'} Editor
					</Typography>
					<ToggleButtonGroup
						className={styles.editorSectionPicker}
						value={state.selectedValue}
						exclusive
						onChange={action((_v, nextView) => {
							nextView || (nextView = 'guard')
							state.selectedValue = nextView
						})}
					>
						<ToggleButton value={'guard'}>
							<Security /> Guard
						</ToggleButton>
						<ToggleButton value={'action'}>
							<OfflineBoltOutlined /> Event
						</ToggleButton>
					</ToggleButtonGroup>
				</div>
				<MonacoEditor
					language="javascript"
					value={currentValue}
					onChange={action((v) => {
						console.log(
							'change event firing for',
							state.selectedValue,
							'setting',
							v,
						)
						if (state.selectedValue === 'guard')
							props.selectedEvent.shouldActivate.text = v ?? ''
						if (state.selectedValue === 'action')
							props.selectedEvent.action.text = v ?? ''
					})}
				></MonacoEditor>
			</Card>
		</div>
	)
})

const useEventEditorStyles = makeStyles((theme: Theme) => ({
	eventEditorRoot: {
		padding: theme.spacing(2),
		display: 'flex',
		flex: 1,
		overflow: 'hidden',
		flexDirection: 'column',
	},

	editorHeader: {
		paddingTop: theme.spacing(1),

		display: 'flex',
	},
	editorSectionPicker: {
		padding: theme.spacing(1, 0),
		marginLeft: 'auto',
	},
	editorHeaderName: {
		alignSelf: 'flex-end',
	},
}))
