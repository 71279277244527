import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material'

export type DialogChoice = {
	displayName: string
	value: string
}

type AlertDialogProps = {
	header: string
	description: string

	choices: DialogChoice[]
	onSelectValue: (value: string) => void
}

export const AlertDialog = ({
	header,
	description,
	choices,
	onSelectValue,
}: AlertDialogProps) => {
	return (
		<>
			<DialogTitle>{header}</DialogTitle>
			<DialogContent>{description}</DialogContent>

			<DialogActions>
				{choices.map((v, i) => (
					<Button
						key={i}
						onClick={() => onSelectValue(v.value)}
						variant="contained"
					>
						{v.displayName}
					</Button>
				))}
			</DialogActions>
		</>
	)
}
