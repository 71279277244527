import React, { createContext, PropsWithChildren, useContext } from 'react'
import { FormPackageInfo } from '../../../../../../api/DTOtemp'

const formPackagePageContext = createContext<
	FormPackagePageContext | undefined
>(undefined)

export type FormPackagePageContextProps = {
	data: FormPackagePageContext
}

export type FormPackagePageContext = {
	formPackage: FormPackageInfo
}

export const FormPackagePageContextProvider = (
	props: PropsWithChildren<FormPackagePageContextProps>,
) => {
	return (
		<formPackagePageContext.Provider value={props.data}>
			{props.children}
		</formPackagePageContext.Provider>
	)
}

export function useFormPackageContext() {
	const contextData = useContext(formPackagePageContext)
	if (contextData === undefined)
		throw Error('form package context has not been initialized')
	return contextData
}
