import { Button, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { useHistory, useLocation } from 'react-router'

type ErrorPageProps = {
	errorMessage: string
	buttonText: string
	route: string
}

export const ErrorPage = (props: ErrorPageProps) => {
	const styles = useStyles()
	const history = useHistory()
	const location = useLocation()

	const { from } = (location.state as
		| undefined
		| { from: { pathname: string | undefined } }) || {
		from: { pathname: undefined },
	}

	return (
		<div className={styles.wholePage}>
			<Typography variant="h6">{props.errorMessage}</Typography>
			<Button
				color="primary"
				onClick={() => history.push(props.route, { from: from })}
				variant="contained"
				className={styles.spacing}
			>
				{props.buttonText}
			</Button>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	wholePage: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: percent(100),
	},

	spacing: {
		margin: theme.spacing(2, 0),
	},
}))
