import { Tooltip } from '@mui/material'
import {
	PropsWithChildren,
	RefObject,
	useCallback,
	useEffect,
	useState,
} from 'react'

// this displays a tooltip only if the child component overflows
// my source - https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis --caroline

type OverflowTooltipProps = {
	tooltipText: string
	childRef: RefObject<HTMLSpanElement>
}

export const OverflowTooltip = (
	props: PropsWithChildren<OverflowTooltipProps>,
) => {
	const [overflowed, setOverflowed] = useState(false)

	const checkIfOverflowed = useCallback(() => {
		if (!props.childRef.current)
			throw new Error('could not find text element ref')

		setOverflowed(
			props.childRef.current.scrollWidth > props.childRef.current.clientWidth,
		)
	}, [props.childRef.current?.scrollWidth, props.childRef.current?.clientWidth])

	useEffect(() => {
		checkIfOverflowed()
		window.addEventListener('resize', checkIfOverflowed)

		return () => {
			window.removeEventListener('resize', checkIfOverflowed)
		}
	}, [])

	// the tooltip requires us to wrap the children in a div so it's one child 😕
	return (
		<Tooltip title={props.tooltipText} disableHoverListener={!overflowed}>
			<div>{props.children}</div>
		</Tooltip>
	)
}
