import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'
import {
	FieldMappingSource,
	PropertyFieldMapping,
	SourceType,
} from './FieldMappingAction'

type AddFieldMappingDialogProps = {
	open: boolean
	sources: FieldMappingSource[]
	onClose: () => void
	onAddFieldMapping: (fieldMapping: PropertyFieldMapping) => void
}

export const AddFieldMappingDialog = observer(
	(props: AddFieldMappingDialogProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			sourceType: '' as SourceType,
			selectedSourceId: '',
		}))

		const availableSources = useMemo(
			() => props.sources.filter((v) => v.type === localStore.sourceType),
			[localStore.sourceType],
		)

		const validationSchema = yup.object({
			name: yup.string().required(),
		})

		const formik = useFormik({
			initialValues: {
				name: '',
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				/**
				 * check if source exists - if it is a query string, create a source since it's
				 * not a previously defined property. if it's a property, we need to make sure
				 * it's a valid one previously configured so we can actually get the value
				 * out of it
				 */
				const newSource =
					localStore.sourceType === SourceType.QueryString
						? {
								displayName: 'Query String',
								id: localStore.selectedSourceId,
								type: SourceType.QueryString,
						  }
						: props.sources.find(
								(v) =>
									v.id.toString() == localStore.selectedSourceId &&
									v.type === localStore.sourceType,
						  )

				if (newSource === undefined)
					throw new Error('cannot add field mapping with undefined source')

				props.onAddFieldMapping({
					id: uuidv4(),
					name: values.name,
					source: newSource,
					linkedFields: [],
					value: undefined,
				})

				props.onClose()

				resetDialog()
			},
		})

		const handleSourceTypeChange = action((event: SelectChangeEvent) => {
			localStore.sourceType = event.target.value as SourceType
			localStore.selectedSourceId = ''
		})

		const handleSourceChange = action((event: SelectChangeEvent) => {
			localStore.selectedSourceId = event.target.value
		})

		const resetDialog = action(() => {
			formik.resetForm()
			localStore.sourceType = '' as SourceType
			localStore.selectedSourceId = ''
		})

		return (
			<Dialog open={props.open} onClose={props.onClose} fullWidth>
				<DialogTitle>Add Field Mapping</DialogTitle>
				<DialogContent>
					<div>
						<div className={styles.formElement}>
							<FixedHeaderWithHint
								label="Name"
								hint={formik.touched.name && formik.errors.name}
								hintColor="error.main"
							>
								<TextField
									id="name"
									name="name"
									value={formik.values.name}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={!!formik.errors.name && formik.touched.name}
									fullWidth
									autoComplete="off"
								/>
							</FixedHeaderWithHint>
						</div>
						<div className={styles.formElement}>
							<FixedHeader label="Source Type">
								<FormControl fullWidth>
									<Select
										value={localStore.sourceType}
										onChange={handleSourceTypeChange}
									>
										{Object.entries(SourceType).map((type) => (
											<MenuItem key={type[0]} value={type[1]}>
												{type[1]}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</FixedHeader>
						</div>
						<div className={styles.formElement}>
							<FixedHeader label="Source">
								{localStore.sourceType === SourceType.QueryString ? (
									<TextField
										fullWidth
										value={localStore.selectedSourceId}
										onChange={action(
											(evt) => (localStore.selectedSourceId = evt.target.value),
										)}
									/>
								) : (
									<FormControl fullWidth>
										<Select
											value={localStore.selectedSourceId}
											onChange={handleSourceChange}
										>
											{availableSources.map((source) => (
												<MenuItem key={source.id} value={source.id}>
													{source.displayName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							</FixedHeader>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.onClose}>Cancel</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={formik.submitForm}
						disabled={!formik.isValid || localStore.selectedSourceId === ''}
					>
						Add Field Mapping
					</Button>
				</DialogActions>
			</Dialog>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	formElement: {
		padding: theme.spacing(1, 2),
	},

	flexRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
}))
