import { Create, Delete, Edit, Warning } from '@mui/icons-material'
import {
	Box,
	Button,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	BaseSecretModel,
	SecretMetadata,
	VaultRecord,
} from '../../../../api/clients/vaultStorage/DTO'
import SecretsClient from '../../../../api/clients/vaultStorage/SecretsClient'
import VaultStorageClient from '../../../../api/clients/vaultStorage/VaultStorageClient'
import { useModals } from '../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { ConfirmDeleteSecretPrompt } from './ConfirmDeleteSecretPrompt'
import { CreateSecretDialog } from './CreateSecretDialog'

export const VaultStorageDashboard = observer(() => {
	const history = useHistory()
	const modalService = useModals()

	const localStore = useLocalObservable(() => ({
		secrets: [] as SecretMetadata[],
		vaults: [] as VaultRecord[],
	}))

	const secretsClient = new SecretsClient()

	const getSecrets = async () => {
		const { data } = await secretsClient.getSecretMetadata()

		runInAction(() => {
			localStore.secrets = data
		})
	}

	const getVaults = async () => {
		const vaultsClient = new VaultStorageClient()

		const { data } = await vaultsClient.getVaults()

		runInAction(() => {
			localStore.vaults = data
		})
	}

	useEffect(() => {
		getSecrets()
		getVaults()
	}, [])

	const handleEditSecret = useCallback((secretId: number) => {
		history.push(`_vault-storage/${secretId}`)
	}, [])

	const handleDeleteSecret = async (secretId: number) => {
		try {
			await secretsClient.deleteSecret(secretId)

			toastService.displayToast({
				message: 'Secret deleted',
				area: 'global',
				severity: 'success',
			})

			runInAction(() => {
				localStore.secrets = localStore.secrets.filter((v) => v.id !== secretId)
			})
		} catch (e) {
			console.error(e)

			toastService.displayToast({
				message: 'Error deleting secret',
				area: 'global',
				severity: 'error',
			})
		}
	}

	const handleCreateSecretClicked = () => {
		modalService
			.showForm((formProps) => (
				<CreateSecretDialog
					vaults={localStore.vaults}
					existingSecretNames={localStore.secrets.map((v) => v.name)}
					onConfirm={(v) =>
						formProps.close({
							closeResult: 'okay',
							value: v,
						})
					}
					onCancel={() => formProps.close({ closeResult: 'cancel' })}
				/>
			))
			.then(async (v) => {
				if (v.closeResult === 'cancel') return

				try {
					const { data: newSecret } = await secretsClient.createSecret(
						v.value as BaseSecretModel,
					)

					toastService.displayToast({
						message: 'Secret created',
						area: 'global',
						severity: 'success',
					})

					history.push(`_vault-storage/${newSecret.id}`)
				} catch (e) {
					console.error(e)

					toastService.displayToast({
						message: 'Error creating secret',
						area: 'global',
						severity: 'error',
					})
				}
			})
	}

	return (
		<AdministrationPageContainer
			title="Vault Storage"
			actions={
				<div>
					<Button
						variant="contained"
						hidden={localStore.vaults.length === 0}
						startIcon={<Create />}
						color="primary"
						onClick={handleCreateSecretClicked}
					>
						Create Secret
					</Button>
				</div>
			}
		>
			<SecretsTable
				secrets={localStore.secrets}
				onEditSecret={handleEditSecret}
				onDeleteSecret={handleDeleteSecret}
			/>
		</AdministrationPageContainer>
	)
})

type SecretsTableProps = {
	secrets: SecretMetadata[]
	onEditSecret: (secretId: number) => void
	onDeleteSecret: (secretId: number) => Promise<void>
}

const SecretsTable = (props: SecretsTableProps) => {
	const promptService = usePrompts()

	return (
		<Fade in>
			<Box
				component={Paper}
				width="100%"
				marginBottom={3}
				height="fit-content"
				overflow="auto"
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.secrets.map((secret) => (
							<TableRow key={secret.id}>
								<TableCell>
									<Typography
										variant="inherit"
										color={secret.isMissingPlugin ? 'error' : ''}
									>
										{secret.name}
									</Typography>
								</TableCell>
								<TableCell>
									{secret.isMissingPlugin ? (
										<Box display="flex" alignItems="center" gap={1}>
											<Warning color="error" fontSize="small" />
											<Typography variant="subtitle2" color="error">
												Plugin Missing
											</Typography>
										</Box>
									) : (
										secret.vaultType
									)}
								</TableCell>
								<TableCell>{secret.description}</TableCell>
								<TableCell align="right">
									{!secret.isMissingPlugin && (
										<IconButton
											size="small"
											onClick={() => props.onEditSecret(secret.id)}
										>
											<Edit />
										</IconButton>
									)}
									<IconButton
										size="small"
										onClick={() => {
											promptService
												.showDialog((props) => (
													<ConfirmDeleteSecretPrompt
														secretName={secret.name}
														onCancel={() => props.close('cancel')}
														onConfirm={() => props.close('okay')}
													/>
												))
												.then((v) => {
													if (v == 'okay') props.onDeleteSecret(secret.id)
												})
										}}
									>
										<Delete />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		</Fade>
	)
}
