import { Box, Button, Fade, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import ButtonLink from '../../components/links/ButtonLink'

export const LogoutPage = () => {
	const history = useHistory()

	return (
		<Fade in>
			<Box
				width="100%"
				display="flex"
				gap={2}
				flexDirection="column"
				justifyContent="flex-start"
				alignItems="center"
			>
				<Typography variant="h5" sx={{ margin: '16px 0' }}>
					You have been successfully signed out
				</Typography>
				<Box
					component={ButtonLink}
					maxWidth={'fit-content'}
					size="large"
					variant="contained"
					to="/_login/_auth-methods"
				>
					Choose Authentication Method
				</Box>
				<Button
					sx={{ maxWidth: 'fit-content' }}
					size="large"
					variant="contained"
					onClick={() => {
						history.push(`/_login`)
					}}
				>
					Use Default Login
				</Button>
			</Box>
		</Fade>
	)
}
