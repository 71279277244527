import { MenuItem, Select } from '@mui/material'
import { TypedEditor } from './PropertySchematicConfigurator'

type SelectPropertyConfiguratorProps = {
	options: { key: string | number; displayValue: string }[]
} & TypedEditor<string | undefined>

const SelectPropertyConfigurator = (props: SelectPropertyConfiguratorProps) => {
	const options = props.options

	return (
		<Select
			value={props.value ?? ''}
			onChange={(evt) => props.onChange(evt.target.value as string)}
			fullWidth
		>
			{options.map((option) => (
				<MenuItem key={option.key} value={option.key}>
					{option.displayValue}
				</MenuItem>
			))}
		</Select>
	)
}

export default SelectPropertyConfigurator
