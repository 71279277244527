import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material'

type ConfirmDeleteSecretPromptProps = {
	secretName: string
	onConfirm: () => void
	onCancel: () => void
}

export const ConfirmDeleteSecretPrompt = (
	props: ConfirmDeleteSecretPromptProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete</DialogTitle>
			<DialogContent>
				Are you sure you want to delete the <b>{props.secretName}</b> secret?
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}
