import {
	Button,
	Card,
	CardHeader,
	Divider,
	MenuItem,
	Select,
	SelectChangeEvent,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { BaseUser } from '../../../../../../api/DTO'
import { AccessStatus } from '../../../../../../api/DTOtemp'
import { PropertiesList } from '../../../../../../components/lists/PropertiesList'

type UserPageSectionProps = {
	user: BaseUser
	accessStatus: AccessStatus
	onUpdateAssociation: (newAccessStatus: AccessStatus) => void
}

const UserDetailsSection = observer((props: UserPageSectionProps) => {
	const styles = useStyles()

	const [accessStatus, setAccessStatus] = useState(
		props.accessStatus.toString(),
	)

	useEffect(() => {
		setAccessStatus(props.accessStatus.toString())
	}, [props.accessStatus])

	const handleAccessStatusChange = (event: SelectChangeEvent) => {
		setAccessStatus(event.target.value)
	}

	return (
		<Card className={styles.userProfileRoot}>
			<CardHeader title="Details"></CardHeader>
			<Divider />
			<div>
				<PropertiesList
					properties={[
						{ label: 'User ID', value: props.user.id },
						{ label: 'Email Address', value: props.user.emailAddress },
						{
							label: 'Access Status',
							value: (
								<Select
									size="small"
									value={accessStatus}
									onChange={handleAccessStatusChange}
									className={styles.select}
								>
									<MenuItem value={'0'} hidden>
										Awaiting Approval
									</MenuItem>
									<MenuItem value={1}>Locked Out</MenuItem>
									<MenuItem value={2}>Access Permitted</MenuItem>
								</Select>
							),
						},
					]}
				/>
			</div>
			<div className={styles.cardActionSection}>
				<Button
					className={styles.cardActionItem}
					color="primary"
					variant="contained"
					onClick={() => {
						props.onUpdateAssociation(accessStatus as unknown as AccessStatus)
					}}
					disabled={accessStatus === props.accessStatus.toString()}
				>
					Save Changes
				</Button>
			</div>
		</Card>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	userProfileRoot: {
		height: 'fit-content',
		width: percent(100),
	},

	cardActionSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(2, 0),
	},

	cardActionItem: {
		margin: theme.spacing(0, 2),
	},

	select: {
		fontSize: 'small',
		color: 'textSecondary',
	},
}))

export default UserDetailsSection
