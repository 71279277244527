import { Expression, MemberExpression, Node } from "estree"
import { action } from "mobx"
import { PartiallyConstructedNode } from "../../../Types"

export const treeGenerator = (
	node: PartiallyConstructedNode<MemberExpression>,
	tree: (undefined | PartiallyConstructedNode<Node>)[] = [],
): (undefined | PartiallyConstructedNode<Node>)[] => {
	if (node.object?.type === 'MemberExpression')
		treeGenerator(node.object, tree)

	if (tree.length === 0) {
		tree.push(node.object)
	}

	tree.push(node.property)

	return tree
}

export const createMemberExpressionTree = (
	nodeArray: (undefined | PartiallyConstructedNode<Node>)[],
	computed = true
): PartiallyConstructedNode<MemberExpression> => {
	const descendants = nodeArray.slice(0, -1)
	const property = nodeArray.slice(nodeArray.length - 1)[0]

	if (descendants.length === 1) {
		return {
			type: 'MemberExpression',
			computed: computed,
			optional: false,
			object: descendants[0] as Expression,
			property: property as Expression,
		}
	}

	return {
		type: 'MemberExpression',
		computed: computed,
		optional: false,
		object: createMemberExpressionTree(descendants, computed) as Expression,
		property: property as Expression,
	}
}


export const resetNodes = action((path: (undefined | PartiallyConstructedNode<Node>)[], node: PartiallyConstructedNode<MemberExpression>, computed = true) => {
	const tree = createMemberExpressionTree(path, computed)
	node.property = tree.property
	node.object = tree.object
})