import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import {
	CreateOrganizationPropertyModel,
	OrganizationPropertyType,
	OrganizationPropertyValue,
} from '../../../../api/clients/identity/OrganizationPropertiesClient'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../utils/HOC/FixedHeaders'

type CreateOrganizationPropertyDialogProps = {
	open: boolean
	existingPropertyNames: string[]
	onCreateClicked: (userProperty: CreateOrganizationPropertyModel) => void
	onClose: () => void
}

export const CreateOrganizationPropertyDialog = observer(
	(props: CreateOrganizationPropertyDialogProps) => {
		const styles = useStyles()

		const upperPropertyNames: string[] = props.existingPropertyNames.map((v) =>
			v.toUpperCase(),
		)

		const validationSchema = yup.object({
			propertyType: yup.mixed().oneOf(Object.values(OrganizationPropertyType)),
			organizationPropertyName: yup
				.string()
				.required('Name is a required field')
				.test(
					'is-unique',
					'an organization property with this name already exists',
					(value) => {
						return (
							value !== undefined &&
							!upperPropertyNames.includes(value.toUpperCase().trim())
						)
					},
				),
			initialValue: yup.string().when('propertyType', {
				is: OrganizationPropertyType.String,
				then: yup.string().required('Initial value is a required field'),
			}),
		})

		const formik = useFormik({
			initialValues: {
				organizationPropertyName: '',
				initialValue: '',
				propertyType: OrganizationPropertyType.String,
			},
			validationSchema: validationSchema,
			onSubmit: (values, { resetForm }) => {
				const propertyValue: OrganizationPropertyValue =
					values.propertyType === OrganizationPropertyType.String
						? {
								type: OrganizationPropertyType.String,
								value: values.initialValue,
						  }
						: {
								type: OrganizationPropertyType.List,
								value: [],
						  }

				props.onCreateClicked({
					name: values.organizationPropertyName,
					value: propertyValue,
				})

				resetForm()
			},
		})

		return (
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Create a New Organization Property</DialogTitle>
				<DialogContent
					sx={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<Typography>
						Create a new user property within the current organization.
					</Typography>
					<FixedHeaderWithHint
						label="Name"
						hint={
							formik.touched.organizationPropertyName &&
							formik.errors.organizationPropertyName
						}
						hintColor="error.main"
						className={styles.textField}
					>
						<TextField
							fullWidth
							id="organizationPropertyName"
							name="organizationPropertyName"
							value={formik.values.organizationPropertyName}
							error={
								!!formik.errors.organizationPropertyName &&
								formik.touched.organizationPropertyName
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>
					<FixedHeader label="Value Type">
						<Select
							fullWidth
							id="propertyType"
							name="propertyType"
							value={formik.values.propertyType}
							onChange={formik.handleChange}
						>
							<MenuItem value={OrganizationPropertyType.String}>Text</MenuItem>
							<MenuItem value={OrganizationPropertyType.List}>List</MenuItem>
						</Select>
					</FixedHeader>
					{formik.values.propertyType === OrganizationPropertyType.String && (
						<FixedHeaderWithHint
							label="Initial Value"
							className={styles.textField}
							hint={formik.touched.initialValue && formik.errors.initialValue}
							hintColor="error.main"
						>
							<TextField
								fullWidth
								id="initialValue"
								name="initialValue"
								value={formik.values.initialValue}
								error={
									!!formik.errors.initialValue && formik.touched.initialValue
								}
								onChange={(evt) => {
									formik.handleChange(evt)
								}}
								onBlur={formik.handleBlur}
								disabled={formik.isSubmitting}
								autoComplete="off"
							/>
						</FixedHeaderWithHint>
					)}
					<DialogActions>
						<Button color="primary" onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
							onClick={formik.submitForm}
						>
							Create
						</Button>
					</DialogActions>
				</DialogContent>
				{formik.isSubmitting && <LinearProgress />}
			</Dialog>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	textField: {
		margin: theme.spacing(2, 0),
	},
}))
