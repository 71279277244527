import { Checkbox } from '@mui/material'
import { TypedEditor } from './PropertySchematicConfigurator'

const CheckboxPropertyConfigurator = (props: TypedEditor<boolean>) => {

	console.log(props.value)

	return (
		<Checkbox
			value={''}
			checked={props.value}
			onChange={(e) => props.onChange(e.target.checked)}
		/>
	)
}

export default CheckboxPropertyConfigurator
