import { makeAutoObservable } from 'mobx'
import { IFormBuilderFormHost } from '../../../FormBuilderCore/IFormBuilderFormHost'
import {
	FormBuilderSchema,
	FormBuilderStructure,
	isFormBuilderSchema,
} from '../../../FormBuilderCore/Types'
import { FormBuilderCellDefinition } from '../../../FormBuilderCore/cells/FormBuilderCellDefinition'
import { FormBuilderCellInstance } from '../../../FormBuilderCore/cells/FormBuilderCellInstance'
import { LayoutElement } from '../../../FormBuilderCore/cells/FormBuilderCellTree'
import {
	FormBuilderType as FormBuilderSchemaType,
	initializeFormBuilder,
} from '../../../FormBuilderCore/cells/rendering/CellManager'
import { EventBus } from '../../../FormBuilderCore/eventBus/EventBus'
import { EventBusEventConsumer } from '../../../FormBuilderCore/eventBus/EventTypes'
import { evaluateDynamicProgram } from '../../../VisualScripting'

export class StandaloneCellManager implements IFormBuilderFormHost {
	formId = 0

	readonly formBuilderSchemaObject: FormBuilderSchemaType

	get cellDefinitions(): FormBuilderCellDefinition[] {
		return this.formBuilderSchemaObject.cellDefinitions
	}

	get cellInstances(): FormBuilderCellInstance[] {
		return this.formBuilderSchemaObject.cellInstances
	}

	get valueObject(): object {
		return this.formBuilderSchemaObject.valueObject
	}

	get rootNode(): LayoutElement {
		return this.formBuilderSchemaObject.rootNode
	}

	readonly eventBus: EventBus

	readonly isReadonly: boolean

	private readonly _eventIds: string[] = []

	constructor(
		schema: FormBuilderStructure | FormBuilderSchema,
		valueObject?: object,
		isReadonly?: boolean,
		eventBus: EventBus = new EventBus(),
		dependencies?: (formHost: IFormBuilderFormHost) => Record<string, unknown>,
	) {
		this.formBuilderSchemaObject = initializeFormBuilder(
			schema.definitions,
			valueObject,
			schema.instances,
		)

		this.eventBus = eventBus
		this.isReadonly = isReadonly ?? false

		this.registerEventListeners(schema, eventBus, dependencies)

		makeAutoObservable(this)
	}

	private registerEventListeners(
		schema: FormBuilderStructure | FormBuilderSchema,
		eventBus: EventBus,
		dependencies?: (formHost: IFormBuilderFormHost) => Record<string, unknown>,
	) {
		if (isFormBuilderSchema(schema)) {
			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const localThis = this
			const formHostArg = {
				get cellDefinitions(): FormBuilderCellDefinition[] {
					return localThis.cellDefinitions
				},
				get cellInstances(): FormBuilderCellInstance[] {
					return localThis.cellInstances
				},
				get valueObject(): object {
					return localThis.valueObject
				},
			}

			for (const event of schema.handlers?.actions ?? []) {
				const consumer: EventBusEventConsumer = {
					shouldActivateAsync: (
						eventId,
						props,
						originalSource,
						currentSource,
					) => {
						return evaluateDynamicProgram(event.shouldActivate, {
							eventId,
							props,
							originalSource,
							currentSource,
							formHost: formHostArg,
							...dependencies?.call(this, this),
						}) as Promise<boolean>
					},
					actionAsync: (eventId, props, originalEventSource, currentSource) => {
						return evaluateDynamicProgram(event.action, {
							eventId,
							props,
							originalEventSource,
							currentSource,
							formHost: formHostArg,
							...dependencies?.call(this, this),
						}) as Promise<boolean>
					},
				}
				this._eventIds.push(eventBus.registerConsumer(consumer))
				console.log('registered a new consumer!')
			}
		}
	}

	focusCell(id: string): void {
		document.getElementById(id)?.focus()
	}

	dispose(): void {
		for (const eventId of this._eventIds) this.eventBus.removeConsumer(eventId)
	}

	public createSnapshot(includeProps = false) {
		const replacer = (key: string, value: string) => {
			if (!includeProps && /:\$props$/gm.test(key))
				return undefined;
			return value;
		}
		
		const output = JSON.stringify(this.valueObject, replacer)
		return JSON.parse(output);

	}
}
