import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { ComponentProps, useEffect, useRef } from 'react'
import { SignatureStatus, SignatureValue } from '../../../../api/DTOtemp'
import { SignatureHost } from '../../../../controls/specifics/signatures/SignatureHost'
import {
	PropsEditorComponent,
	specifyValueTypeComponent,
	ValuesEditorComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'

type SignatureControlProps = {
	// empty for now
}

export const SignatureInput = observer((props: SignatureControlProps) => {
	const context = useInputCellContext<SignatureValue>()
	const value = context.value as SignatureValue
	console.log('value: ', JSON.stringify(value, null, 2))

	const ipAddressRef = useRef<string>('')

	useEffect(() => {
		;(async () => {
			ipAddressRef.current = await getIpAddress()
		})()
	}, [])

	return (
		<Box height={150} display="flex" justifyContent="center">
			{value !== undefined && value.status === SignatureStatus.Tracked ? (
				<img src={value.image} />
			) : (
				<SignatureHost
					anonymous={false}
					selectedSignature={value?.image ?? ''}
					onSelectSignature={(signature) => {
						if (ipAddressRef.current === '')
							throw new Error('unable to resolve IP address')

						context.onChange({
							emailAddress: signature.email,
							fullName: signature.fullName,
							image: signature.signatureData,
							ipAddress: ipAddressRef.current,
							timestamp: new Date(),
							status: SignatureStatus.Untracked,
						})
					}}
				/>
			)}
		</Box>
	)
})

async function getIpAddress(): Promise<string> {
	const response = await fetch('https://api.ipify.org?format=json')

	const json = await response.json()
	return json.ip
}

export const SignatureInputElementTag = '52165fe9-0467-4f55-9039-134eea1de55b'
export const SignatureInputId = '4fb1a620-08c0-452c-884d-73f79d851f25'

const SignatureInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof SignatureInput>>) => {
	return <></>
}

export const SignatureInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof SignatureInput>>,
) => {
	return <></>
}

export const SignatureInputDefinition = specifyValueTypeComponent(
	SignatureInputId,
	SignatureInput,
	SignatureInputElementTag,
	'Signature',
	FieldType.ReferenceType,
	{
		configurator: SignatureInputPropsEditor,
		defaultProperties: {},
	},
	{
		configurator: SignatureInputValueEditor,
		defaultValue: {
			type: 'static',
			value: undefined,
		},
	},
)
