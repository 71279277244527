import { createContext, PropsWithChildren, useContext } from 'react'

/*
 * This context exists so that we don't have to make so many requests to get all the
 * email domains in the DB and the instances - we can just use the same list for the
 * dashboard and the instance page. The email domain request specifically could potentially
 * end up being really expensive since we're getting all email domains for every organization,
 * so we want to do that as few times as possible.
 */

const AuthenticationProviderContext = createContext<
	AuthenticationProviderContextProviderProps | undefined
>(undefined)

type AuthenticationProviderContextProviderProps = {
	organizationInstanceNames: string[]
}

export const AuthenticationProviderContextProvider = (
	props: PropsWithChildren<AuthenticationProviderContextProviderProps>,
) => {
	return (
		<AuthenticationProviderContext.Provider value={props}>
			{props.children}
		</AuthenticationProviderContext.Provider>
	)
}

export const useAuthenticationProviderContext = () => {
	const context = useContext(AuthenticationProviderContext)
	if (context === undefined)
		throw Error(
			'a value for the authentication provider context was not provided',
		)
	return context
}
