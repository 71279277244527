import { Backdrop, Box, Typography, styled } from '@mui/material'
import { useEffect } from 'react'
import AirplaneSvg from '../../images/fim_paperplane-element.svg'
import { FormPackageSubmitResult } from './PackageHostFooter.Submit'
import './PackageSubmitAnimation.css'

type PackageSubmitBackdropProps = {
	message: string
	onAnimationEnd: () => void

	// undefined is if the package is currently being submitted (no result yet)
	getPackageResult: () => FormPackageSubmitResult | undefined
}

export const PackageSubmitBackdrop = (props: PackageSubmitBackdropProps) => {
	return (
		<PackageSubmitStyledBackdrop open={true}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				width="100%"
				height="100%"
				zIndex={(theme) => theme.zIndex.speedDial}
				paddingY={16}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					gap={1}
					flex={1}
					justifyContent="flex-end"
				>
					<Typography variant="h5">Submitting Forms</Typography>
					<Typography variant="subtitle2" fontStyle="italic">
						This could take some time
					</Typography>
				</Box>
				<Box flex={2}>
					<PackageSubmitAnimation {...props} />
				</Box>
				<Box flex={1} justifyContent="center">
					<Typography variant="subtitle2">{props.message}</Typography>
				</Box>
			</Box>
		</PackageSubmitStyledBackdrop>
	)
}

const PackageSubmitStyledBackdrop = styled(Backdrop)(({ theme }) => ({
	color: '#fff',
	zIndex: theme.zIndex.appBar + 1,
}))

const PackageSubmitAnimation = (props: PackageSubmitBackdropProps) => {
	useEffect(() => {
		const svgContainer = document.querySelector(
			'.svg-container-initial',
		) as HTMLDivElement

		if (svgContainer === undefined) {
			console.warn('svg container undefined')
			return
		}

		const endAnimation = (ev: AnimationEvent) => {
			console.log('animation-end')

			const packageResult = props.getPackageResult()

			if (
				svgContainer.className === 'svg-container-exit' ||
				svgContainer.className === 'svg-container-spin-exit' ||
				packageResult === FormPackageSubmitResult.Canceled
			)
				props.onAnimationEnd()

			if (packageResult === FormPackageSubmitResult.Errored)
				svgContainer.className = 'svg-container-spin-exit'

			if (svgContainer.className === 'svg-container-initial') {
				// if the package is currently being submitted, have the plane spin
				if (packageResult === undefined)
					svgContainer.className = 'svg-container-spin'
				// if it's been submitted, show the plane exit animation
				else if (packageResult === FormPackageSubmitResult.Submitted)
					svgContainer.className = 'svg-container-exit'
			}
		}

		const iterateAnimation = (ev: AnimationEvent) => {
			const packageResult = props.getPackageResult()

			if (svgContainer.className !== 'svg-container-spin') return

			// show the plane exit animation if package was submitted
			if (packageResult === FormPackageSubmitResult.Submitted)
				svgContainer.className = 'svg-container-exit'
			// if package was errored, have plane spin and fall
			else if (packageResult === FormPackageSubmitResult.Errored)
				svgContainer.className = 'svg-container-spin-exit'
			// if package was canceled, end the animation
			else if (packageResult === FormPackageSubmitResult.Canceled)
				props.onAnimationEnd()
		}

		svgContainer.addEventListener('animationend', endAnimation, true)

		svgContainer.addEventListener('animationiteration', iterateAnimation, true)

		return () => {
			svgContainer.removeEventListener('animationend', endAnimation, true)
			svgContainer.removeEventListener(
				'animationiteration',
				iterateAnimation,
				true,
			)
		}
	}, [])

	return (
		<Box width="100%" height="100%">
			<div className="svg-container-initial">
				<img src={AirplaneSvg} />
			</div>
		</Box>
	)
}
