
import axios, {
	AxiosInstance, AxiosRequestConfig
} from "axios"

class ClientBase {
	
	protected _api: AxiosInstance

	constructor() {
		this._api = axios.create({
			baseURL: `${window.location.origin}/api/v1/`,
		})
		// this interceptor is set up to see if we get any new auth headers

	}

	protected join = (...paths: (string | undefined)[]): string => {
		const join2 = (a: string, b: string): string => {
			let first = a

			while (first && first.endsWith("/"))
				first = first.slice(0, first.length - 1)

			let second = b

			while (second && second.startsWith("/"))
				second = second.slice(1)

			return !first ? b || "" : !second ? a || "" : first + "/" + second
		}
		return paths.map(s => s || "").reduce((prev, cur) => join2(prev, cur), "")
	}

	protected get postConfig() : AxiosRequestConfig {
		return {
			headers: {
				'Content-Type': 'application/json',
			}
		}
	}

	protected acceptHeader(headerName = "*/*"): AcceptHeader{
		return {Accept: headerName}
	}



	protected contentTypeHeader(headerValue = "application/json"): ContentTypeHeader {
		return {'Content-Type': headerValue}
	}
}

export type AcceptHeader = {
	'Accept': string
}


export type ContentTypeHeader = {
	'Content-Type': string
}

export default ClientBase
