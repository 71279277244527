import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyValueTypeComponent,
	ValuesEditorComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	CheckboxPropertyConfigurator,
	NumberPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import {
	NumberInput,
	NumberInputElementTag,
} from './InternalNumberInputDefinition'

const NumberInputId = 'b47453d2-f5e7-4450-ac83-0156bb0c1c22'

const NumberInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof NumberInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.allowDecimals}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, allowDecimals: v })
				}
				Editor={CheckboxPropertyConfigurator}
				label="Allow Decimals"
			/>
		</>
	)
}

const NumberInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof NumberInput>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={NumberPropertyConfigurator}
		label="Default Value"
	/>
)

export const NumberInputDefinition = specifyValueTypeComponent(
	NumberInputId,
	NumberInput,
	NumberInputElementTag,
	'Number Input',
	FieldType.Number,
	{
		configurator: NumberInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			allowDecimals: true,
		},
	},
	{
		configurator: NumberInputValueEditor,
		defaultValue: 0,
	},
)
