import { Box, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import { BlockStatement, Expression, IfStatement } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import NodeBodyWrapper from '../../internal/NodeBodyWrapper'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'
import { MemberExpressions } from '../variables/MemberExpressionComponent'

const validTestNodes = [
	Syntax.LogicalExpression,
	Syntax.BinaryExpression,
	Syntax.CallExpression,
	Syntax.Literal,
	...MemberExpressions,
]

const IfStatementComponent = observer(
	(props: RendererComponent<IfStatement>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Logic)

		return (
			<NodeBodyWrapper
				color={color}
				topSection={
					<Box display="flex" gap={3} alignItems="center" padding={1}>
						<Box component={Typography} color={textColor}>
							If
						</Box>
						<Renderer
							dropMode={DropMode.Replace}
							validNodes={validTestNodes}
							getter={() => props.node.test}
							setter={action((node) => (props.node.test = node as Expression))}
						/>
					</Box>
				}
			>
				<Renderer
					dropMode={DropMode.Replace}
					validNodes={[Syntax.BlockStatement]}
					getter={() => props.node.consequent}
					setter={action(
						(node) => (props.node.consequent = node as BlockStatement),
					)}
				/>
			</NodeBodyWrapper>
		)
	},
)

export default IfStatementComponent
