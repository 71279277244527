import { Delete, Upload } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Create from '@mui/icons-material/Create'
import {
	Box,
	Button,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AssignmentType } from '../../../../api/clients/workItems/DTOs'
import { WorkflowDefinition } from '../../../../api/clients/workflows/DTOs'
import WorkflowsClient from '../../../../api/clients/workflows/WorkflowsClient'
import UserChip from '../../../../components/chips/UserChip'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { ConfirmDeleteWorkflowDialog } from './ConfirmDeleteWorkflowDialog'
import { CreateWorkflowDialog } from './CreateWorkflowDialog'
import { UploadWorkflowDialog } from './UploadWorkflowDialog'

type WorkflowsDashboardTableProps = {
	workflowDefinitions: WorkflowDefinition[]
	onViewWorkflowClicked: (workflowId: number) => void
	onDeleteWorkflow?: (workflowId: number) => void
}

export const WorkflowsDashboardPage: React.FC = () => {
	const client = new WorkflowsClient()

	const history = useHistory()

	const [createWorkflowOpen, setCreateWorkflowOpen] = useState(false)
	const [uploadWorkflowOpen, setUploadWorkflowOpen] = useState(false)

	const [publishedWorkflows, setPublishedWorkflows] =
		useState<WorkflowDefinition[]>()

	const getPublishedWorkflows = async (): Promise<void> => {
		const response = await client.getAllWorkflows()
		let unnamedWorkflowCount = 1

		if (response.status === 200) {
			for (const workflowDefinition of response.data) {
				if (workflowDefinition.workflow.name === '') {
					workflowDefinition.workflow.name = `Unnamed Workflow ${unnamedWorkflowCount}`
					unnamedWorkflowCount += 1
				}
			}
			setPublishedWorkflows(response.data)
		}
	}

	const handleUploadWorkflowClicked = () => {
		setUploadWorkflowOpen(true)
	}

	const handleUploadWorkflowClosed = () => {
		setUploadWorkflowOpen(false)
	}

	const handleUploadWorkflow = (wfData: WorkflowDefinition) => {
		const result = client.uploadWorkflowDefinition(wfData)

		result.then((v) => {
			history.push(`/_workflows/${v.data.workflowId}`)
		})

		setUploadWorkflowOpen(false)
	}

	const handleCreateWorkflowClicked = () => {
		setCreateWorkflowOpen(true)
	}

	const handleCreateWorkflowClosed = () => {
		setCreateWorkflowOpen(false)
	}

	const handleCreateWorkflow = (name: string) => {
		client
			.createWorkflow({
				workflowName: name,
				enabled: false,
			})
			.then((v) => v.data)
			.then((v) => {
				handleCreateWorkflowClosed()
				history.push(`/_workflows/${v.id}`)
			})
	}

	const handleViewWorkflowClicked = (workflowId: number) => {
		history.push(`/_administration/_workflows/${workflowId}`)
	}

	const handleDeleteWorkflow = async (workflowId: number) => {
		try {
			await client.deleteWorkflow(workflowId)

			const newPublishedWorkflows = (publishedWorkflows ?? []).filter(
				(v) => v.workflowId !== workflowId,
			)

			setPublishedWorkflows(newPublishedWorkflows)

			toastService.displayToast({ message: 'Workflow deleted', area: 'global' })
		} catch (e) {
			console.log(e)
			toastService.displayToast({
				message: 'Error deleting workflow',
				area: 'global',
			})
		}
	}

	useEffect(() => {
		getPublishedWorkflows()
	}, [])

	return (
		<AdministrationPageContainer
			title="Workflows"
			actions={
				<Box
					display="flex"
					justifyContent={'center'}
					alignItems={'center'}
					gap={2}
				>
					<Button
						variant="outlined"
						color="primary"
						onClick={handleUploadWorkflowClicked}
						startIcon={<Upload />}
					>
						Upload Workflow
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleCreateWorkflowClicked}
						startIcon={<Create />}
					>
						Create Workflow
					</Button>
				</Box>
			}
		>
			{publishedWorkflows === undefined ? (
				<FullscreenSpinner />
			) : (
				<WorkflowsDashboardTable
					workflowDefinitions={publishedWorkflows}
					onViewWorkflowClicked={handleViewWorkflowClicked}
					onDeleteWorkflow={handleDeleteWorkflow}
				/>
			)}

			<CreateWorkflowDialog
				open={createWorkflowOpen}
				onClose={handleCreateWorkflowClosed}
				existingWorkflowNames={
					publishedWorkflows?.map((v) => v.workflow.name) ?? []
				}
				onCreateWorkflow={handleCreateWorkflow}
			/>

			<UploadWorkflowDialog
				open={uploadWorkflowOpen}
				onClose={handleUploadWorkflowClosed}
				existingWorkflowNames={
					publishedWorkflows?.map((v) => v.workflow.name) ?? []
				}
				onCreateWorkflow={handleUploadWorkflow}
			></UploadWorkflowDialog>
		</AdministrationPageContainer>
	)
}

export const WorkflowsDashboardTable: React.FC<WorkflowsDashboardTableProps> =
	observer((props: WorkflowsDashboardTableProps) => {
		const styles = useStyles()
		const promptService = usePrompts()

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Version</TableCell>
								<TableCell>Last Edited</TableCell>
								<TableCell>Edited By</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.workflowDefinitions.map((workflowDefinition, i) => {
								return (
									<TableRow key={i}>
										<TableCell>{workflowDefinition.workflow.name}</TableCell>
										<TableCell>{workflowDefinition.version}</TableCell>
										{workflowDefinition.version === 0 ? (
											<>
												<TableCell>-</TableCell>
												<TableCell>-</TableCell>
											</>
										) : (
											<>
												<TableCell>
													{new Date(
														workflowDefinition.lastModifiedDate,
													).toLocaleString()}
												</TableCell>
												<TableCell>
													<UserChip
														id={workflowDefinition.lastModifiedBy}
														assignmentType={AssignmentType.User}
													/>
												</TableCell>
											</>
										)}
										<TableCell align="right">
											{props.onDeleteWorkflow !== undefined && (
												<IconButton
													onClick={() => {
														promptService
															.showDialog((props) => (
																<ConfirmDeleteWorkflowDialog
																	name={workflowDefinition.workflow.name}
																	onCancel={() => props.close('cancel')}
																	onConfirm={() => props.close('okay')}
																/>
															))
															.then((v) => {
																if (v == 'okay')
																	props.onDeleteWorkflow!(
																		workflowDefinition.workflowId,
																	)
															})
													}}
												>
													<Delete fontSize="small" />
												</IconButton>
											)}
											<IconButton
												onClick={() =>
													props.onViewWorkflowClicked(
														workflowDefinition.workflowId,
													)
												}
											>
												<ArrowForwardIcon fontSize="small" />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
