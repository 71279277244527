import { Clear } from '@mui/icons-material'
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	TextField,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ReactNode } from 'react'

type AddValuesDialog<T> = {
	options: T[]
	getLabel: (v: T) => string
	renderOption: (v: T) => ReactNode
	placeholder: string

	open: boolean
	onClose: () => void
	onCommit: (v: T[]) => void

	header: string // "Add Users to {GroupName}"
	addText: string // "Add Users"
}

export const AddValuesDialog = observer(<T,>(props: AddValuesDialog<T>) => {
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		selectedValues: [] as T[],
		autoCompleteValue: '',
	}))

	const handleClose = action(() => {
		props.onClose()
		localStore.selectedValues = []
	})

	const handleAddValues = action((values: T[]) => {
		props.onCommit(values)
		localStore.selectedValues = []
	})

	const handleUnselectValues = action((value: T) => {
		const index = localStore.selectedValues.indexOf(value)
		localStore.selectedValues.splice(index, 1)
	})

	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			fullWidth
			maxWidth="xs"
			classes={{ paper: styles.rightSideDialog }} // override dialog position
		>
			<DialogTitle>{props.header}</DialogTitle>
			<DialogContent>
				<Autocomplete
					options={props.options.filter(
						(v) => !localStore.selectedValues.includes(v),
					)}
					getOptionLabel={(option: T) => props.getLabel(option)}
					noOptionsText="No results"
					onChange={(event, value) => {
						if (value !== null) {
							localStore.selectedValues.push(value)
						}
					}}
					onInputChange={action((event, value, reason) => {
						if (reason === 'reset') localStore.autoCompleteValue = ''
						else localStore.autoCompleteValue = value
					})}
					inputValue={localStore.autoCompleteValue}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant="outlined"
							placeholder={props.placeholder}
							size="small"
						/>
					)}
					renderOption={(_props, option) => (
						<li {..._props}>
							<div className={styles.userInfo}>
								{props.renderOption(option)}
							</div>
						</li>
					)}
				/>
				<div className={styles.selectedList}>
					{localStore.selectedValues.length > 0 &&
						localStore.selectedValues.map((value) => (
							<>
								<div className={styles.selectedUser}>
									<div className={styles.userInfo}>
										{props.renderOption(value)}
									</div>
									<div>
										<IconButton
											onClick={() => handleUnselectValues(value)}
											size="small"
										>
											<Clear />
										</IconButton>
									</div>
								</div>
								<Divider />
							</>
						))}
				</div>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={localStore.selectedValues.length < 1}
					onClick={() => handleAddValues(localStore.selectedValues)}
				>
					{props.addText}
				</Button>
			</DialogActions>
		</Dialog>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	rightSideDialog: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
	},

	selectedList: {
		marginTop: theme.spacing(3),
	},

	selectedUser: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	userInfo: {
		marginLeft: theme.spacing(1),
	},
}))
