import { TextField } from '@mui/material'
import { FixedHeader } from '../../../utils/HOC/FixedHeaders'
import EmailValidationRegex from '../../../utils/constants/EmailValidationRegex'
import { IFormHost } from '../FormHost/IFormHost'
import { ValidationEventType } from './Types'

//#region validation functions

/*
 * validation functions validate the value and return the error text if not valid
 */

// keep formhost & definition id as parameters in case we need it later for multi step validation
export const required = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters
	if (value === null || value === undefined || value === '') return 'Required'

	return ''
}

export const matches = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters
	const regex = new RegExp(parameters.regex as string)

	const isMatch = regex.test(value as string)

	if (!isMatch) return `Incorrect format`

	return ''
}

export const emailFormat = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters
	const isMatch = EmailValidationRegex.test(value as string)

	if (!isMatch) return `Incorrect format for email address`

	return ''
}

export const tenDigitPhoneFormat = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters

	const isMatch = /^[0-9]{10}$/.test(value as string)

	if (!isMatch)
		return `Phone number must be 10 digits with no special characters`

	return ''
}

export const sevenDigitPhoneNumber = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters

	const isMatch = /^[0-9]{7}$/.test(value as string)

	if (!isMatch)
		return `Phone number must be 7 digits with no special characters`

	return ''
}

export const countryCodePhoneNumber = (
	formHost: IFormHost,
	definitionId: string,
	parameters: { [key: string]: unknown },
): string => {
	const { value } = parameters

	// 11-13 digits b/c 10 digits + country code (can be 2 or 3 digits)
	const isMatch = /^[0-9]{11,13}$/.test(value as string)

	if (!isMatch)
		return `Phone number must be 11-13 digits including country code with no special characters`

	return ''
}

//#endregion

//#region validation events

export const RequiredValidationEvent: ValidationEventType = {
	id: 'required',
	validate: required,
	displayName: 'Required',
	description: 'is required',
}

export const MatchesValidationEvent: ValidationEventType = {
	id: 'matches',
	validate: matches,
	displayName: 'Matches',
	description: 'matches regex',
	parameterConfigurator: (
		parameters: { [key: string]: unknown },
		onChange: (value: { [key: string]: unknown }) => void,
	) => {
		return (
			<FixedHeader label="Regex">
				<TextField
					fullWidth
					value={parameters.regex ?? ''}
					onChange={(evt) => onChange({ ['regex']: evt.currentTarget.value })}
				/>
			</FixedHeader>
		)
	},
}

export const EmailValidationEvent: ValidationEventType = {
	id: 'email',
	validate: emailFormat,
	displayName: 'Email Format',
	description: 'is email format',
}

export const TenDigitPhoneValidationEvent: ValidationEventType = {
	id: 'tenDigitPhone',
	validate: tenDigitPhoneFormat,
	displayName: '10 Digit Phone Number',
	description: 'is 10 digit phone number',
}

export const SevenDigitPhoneValidationEvent: ValidationEventType = {
	id: 'sevenDigitPhone',
	validate: sevenDigitPhoneNumber,
	displayName: '7 Digit Phone Number',
	description: 'is 7 digit phone number',
}

export const CountryCodePhoneValidationEvent: ValidationEventType = {
	id: 'countryCodePhone',
	validate: sevenDigitPhoneNumber,
	displayName: '10 Digit Plus Country Code Phone Number',
	description: 'is 10 digit phone number plus country code',
}

//#endregion

export const ValidationEvents = [
	RequiredValidationEvent,
	MatchesValidationEvent,
	EmailValidationEvent,
	TenDigitPhoneValidationEvent,
	SevenDigitPhoneValidationEvent,
	CountryCodePhoneValidationEvent,
]
