import { Create } from '@mui/icons-material'
import { Button, Divider, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { FormPackageVersionClient } from '../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { FormPackageInfo } from '../../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../../components/feedback/circular'
import { ValidationEventRegistration } from '../../../../../../../modules/FormHost/Validation/Types'
import { useModals } from '../../../../../../../services/notifications/ModalService'
import { toastService } from '../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import { AddFieldValidationDialog } from '../../FormPackagePage/Sections/ValidationSection/FormPackageValidationPage'
import { FormPackageValidationSection } from '../../FormPackagePage/Sections/ValidationSection/FormPackageValidationSection'
import { FormSectionProps } from '../FormPage'

export const FormValidationSection = observer(
	({
		form,
		packageId,
		packageVersionNumber,
		tabLabels,
		onTabChanged,
	}: FormSectionProps) => {
		const styles = useStyles()
		const modalService = useModals()

		const packageVersionClient = new FormPackageVersionClient(packageId)

		const localStore = useLocalObservable(() => ({
			formPackage: undefined as FormPackageInfo | undefined,
			get validationRegistrations() {
				return this.formPackage?.configuration.validationEventRegistrations.filter(
					(v) => v.formId === form.id,
				)
			},
		}))

		useEffect(() => {
			packageVersionClient.GetVersion(packageVersionNumber).then(
				action((response) => {
					localStore.formPackage = response.data
				}),
			)
		}, [])

		const handleAddRegistration = async (
			registration: ValidationEventRegistration,
		) => {
			if (localStore.formPackage === undefined) throw new Error('')

			localStore.formPackage.configuration.validationEventRegistrations.push(
				registration,
			)

			try {
				await packageVersionClient.UpdateConfiguration(localStore.formPackage)
				toastService.displayToast({
					message: 'Field validation added',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error adding field validation',
					area: 'global',
				})
			}
		}

		const handleUpdateRegistration = async (
			registration: ValidationEventRegistration,
		) => {
			if (localStore.formPackage === undefined)
				throw new Error('form package is undefined')

			const index =
				localStore.formPackage.configuration.validationEventRegistrations.findIndex(
					(v) => v.registrationId === registration.registrationId,
				)
			if (index < 0)
				throw new Error(
					`could not find registration ${registration.registrationId} to update`,
				)

			localStore.formPackage.configuration.validationEventRegistrations[index] =
				registration

			try {
				await packageVersionClient.UpdateConfiguration(localStore.formPackage)
				toastService.displayToast({
					message: 'Field validation updated',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error updating field validation',
					area: 'global',
				})
			}
		}

		const handleDeleteRegistration = async (registrationId: string) => {
			if (localStore.formPackage === undefined)
				throw new Error('form package is undefined')

			const indexToRemove =
				localStore.formPackage.configuration.validationEventRegistrations.findIndex(
					(v) => v.registrationId === registrationId,
				)
			if (indexToRemove < 0) return

			localStore.formPackage.configuration.validationEventRegistrations.splice(
				indexToRemove,
				1,
			)

			try {
				await packageVersionClient.UpdateConfiguration(localStore.formPackage)
				toastService.displayToast({
					message: 'Field validation removed',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error removing field validation',
					area: 'global',
				})
			}
		}

		if (
			localStore.validationRegistrations === undefined ||
			localStore.formPackage === undefined
		)
			return <FullscreenSpinner />

		return (
			<AdministrationPageContainer
				title={form.name}
				actions={
					<div>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Create />}
							onClick={() => {
								modalService
									.showForm((modalProps) => {
										return (
											<AddFieldValidationDialog
												forms={[form]}
												registrations={localStore.validationRegistrations ?? []}
												onConfirm={(v) =>
													modalProps.close({
														closeResult: 'okay',
														value: v,
													})
												}
												onCancel={() =>
													modalProps.close({ closeResult: 'cancel' })
												}
											/>
										)
									})
									.then((v) => {
										if (v.closeResult === 'okay') {
											handleAddRegistration(
												v.value as ValidationEventRegistration,
											)
										}
									})
							}}
						>
							Add Field Validation
						</Button>
					</div>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={2}
						tabLabels={tabLabels}
						onTabChanged={onTabChanged}
					/>
					<Divider />
					<FormPackageValidationSection
						cellDefinitions={form.metadata.cellDefinitions}
						validationRegistrations={localStore.validationRegistrations}
						onUpdateRegistration={handleUpdateRegistration}
						onDeleteRegistration={handleDeleteRegistration}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),

		overflow: 'hidden',
	},
}))
