import { AddLink } from '@mui/icons-material'
import { Button, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import {
	DetailForm,
	FormPackageInfo,
	LinkedField,
} from '../../../../../../../../api/DTOtemp'
import {
	FormPackageClient,
	FormsClient,
} from '../../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { FullscreenSpinner } from '../../../../../../../../components/feedback/circular'
import { Scrollbar } from '../../../../../../../../components/scrollbars/Scrollbar'
import { CellDefinition } from '../../../../../../../../modules/FormHost/Types/CellDefinition'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import { getCellDisplayName } from '../../../../../../../../utils/getCellDisplayName'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { updateBreadcrumbs } from '../../../../../Breadcrumbs'
import { FieldMappingOptionSection } from './FieldMappingOptionsSection'
import { LinkFieldsDialog } from './LinkFieldsDialog'
import { LinkedFieldsSection } from './LinkedFieldsSection'

export type Field = {
	formId: number
	formName: string
	cellDefinition: CellDefinition
	displayName: string
	linked: boolean
}

type FieldMappingsPageProps = {
	packageId: number
	packageVersionNumber: number
}

export const FieldMappingsPage = observer((props: FieldMappingsPageProps) => {
	const styles = useStyles()
	const { fieldMappingId } = useParams<{ fieldMappingId: string }>()

	const store = useLocalObservable(() => ({
		addFieldsDialogOpen: false,
		formPackage: undefined as FormPackageInfo | undefined,
		forms: [] as DetailForm[],
		fields: [] as Field[],
	}))

	updateBreadcrumbs(
		`/_administration/_form-packages/${props.packageId}`,
		'Form Packages',
	)

	const formPackageVersionApi = new FormPackageVersionClient(props.packageId)

	useEffect(() => {
		const formsApi = new FormsClient(
			props.packageId,
			props.packageVersionNumber,
		)

		// first get the forms so we can get the fields from each one
		formsApi.GetAllForms().then(
			action((response) => {
				store.forms = response.data

				const innerFields: Field[] = []

				for (const form of response.data) {
					// don't want to link signature fields
					for (const cellDefinition of form.metadata.cellDefinitions.filter(
						(v) => v.elementTag !== 'Signature',
					))
						innerFields.push({
							formId: form.id,
							formName: form.name,
							cellDefinition: cellDefinition,
							displayName: getCellDisplayName(
								form.metadata.cellDefinitions,
								cellDefinition,
							),
							linked: false,
						})
				}
				store.fields = innerFields
			}),
		)
	}, [props.packageId, props.packageVersionNumber])

	useEffect(() => {
		const packageApi = new FormPackageClient()

		// get the form package so we can fetch and update the package's field mappings
		packageApi.GetFormPackage(props.packageId).then(
			action((response) => {
				store.formPackage = response.data

				const packageFieldMappings = response.data.configuration.fieldMappings

				const fieldMappingIndex = packageFieldMappings.findIndex(
					(v) => v.id === fieldMappingId,
				)
				if (fieldMappingIndex < 0)
					throw new Error(
						`could not find field mapping ${fieldMappingId} in form package`,
					)

				const linkedFields =
					packageFieldMappings[fieldMappingIndex].linkedFields

				for (const field of store.fields) {
					// check if field is included in field mapping, if it is, mark it as linked
					if (
						linkedFields
							.filter((v) => v.formId === field.formId)
							.map((v) => v.cellDefinitionId)
							.includes(field.cellDefinition.id)
					)
						field.linked = true
				}
			}),
		)
	}, [store.fields, props.packageId])

	const fieldMapping = useMemo(() => {
		if (store.formPackage === undefined) return undefined

		return store.formPackage.configuration.fieldMappings.find(
			(v) => v.id === fieldMappingId,
		)
	}, [fieldMappingId, store.formPackage])

	const handleUpdateFieldMappingInfo = (name: string, description: string) => {
		if (store.formPackage === undefined)
			throw new Error('form package is undefined')

		if (fieldMapping === undefined)
			throw new Error('cannot update undefined field mapping')

		runInAction(() => {
			fieldMapping.name = name
			fieldMapping.description = description
		})

		formPackageVersionApi
			.UpdateConfiguration(store.formPackage)
			.then(() => {
				toastService.displayToast({
					message: 'Linked fields updated',
					area: 'global',
				})
			})
			.catch((e) => {
				console.error(e)
				toastService.displayToast({
					message: 'Error updating linked fields',
					area: 'global',
				})
			})
	}

	const handleOpenAddFieldsDialog = useCallback(
		action(() => {
			store.addFieldsDialogOpen = true
		}),
		[],
	)

	const handleCloseAddFieldsDialog = useCallback(
		action(() => {
			store.addFieldsDialogOpen = false
		}),
		[],
	)

	const handleLinkFields = (linkedFields: LinkedField[]) => {
		if (store.formPackage === undefined)
			throw new Error('form package is undefined')

		if (fieldMapping === undefined)
			throw new Error('cannot add fields to undefined field mapping')

		// mark the fields as linked
		for (const linkedField of linkedFields) {
			const field = store.fields.find(
				(v) =>
					v.cellDefinition.id === linkedField.cellDefinitionId &&
					v.formId === linkedField.formId,
			)
			if (field === undefined)
				throw new Error('cannot find cell definition in field list to update')

			field.linked = true
		}

		fieldMapping.linkedFields.push(...linkedFields)

		formPackageVersionApi
			.UpdateConfiguration(store.formPackage)
			.then(() => {
				toastService.displayToast({ message: 'Fields linked', area: 'global' })
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error occurred while linking fields',
					area: 'global',
				})
			})
	}

	const handleUnlinkField = action((linkedField: LinkedField) => {
		if (store.formPackage === undefined)
			throw new Error('form package is undefined')

		if (fieldMapping === undefined)
			throw new Error('cannot add fields to undefined field mapping')

		const fieldToUnlink = store.fields.find(
			(v) =>
				v.cellDefinition.id === linkedField.cellDefinitionId &&
				v.formId === linkedField.formId,
		)
		if (fieldToUnlink === undefined) return

		fieldToUnlink.linked = false

		const fieldToRemove = fieldMapping.linkedFields.findIndex(
			(v) =>
				v.formId === linkedField.formId &&
				v.cellDefinitionId === linkedField.cellDefinitionId,
		)

		if (fieldToRemove < 0) return

		fieldMapping.linkedFields.splice(fieldToRemove, 1)

		formPackageVersionApi
			.UpdateConfiguration(store.formPackage)
			.then(() => {
				toastService.displayToast({ message: 'Field unlinked', area: 'global' })
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error occurred while unlinking field',
					area: 'global',
				})
			})
	})

	// this is to prevent adding the same field to multiple link groups
	const availableFields = useMemo(() => {
		if (store.formPackage === undefined) return []

		const packageFieldMappings = store.formPackage.configuration.fieldMappings

		let availableFields = store.fields

		for (const fieldMapping of packageFieldMappings)
			availableFields = availableFields.filter(
				(v) =>
					!fieldMapping.linkedFields
						.filter((x) => x.formId === v.formId)
						.map((v) => v.cellDefinitionId)
						.includes(v.cellDefinition.id),
			)

		return availableFields
	}, [store.fields, store.formPackage])

	if (fieldMapping === undefined || store.formPackage === undefined)
		return <FullscreenSpinner />

	return (
		<AdministrationPageContainer
			title={fieldMapping.name}
			actions={
				<div>
					<Tooltip
						title="Upload forms before linking fields"
						disableHoverListener={store.fields.length > 0}
					>
						<div>
							<Button
								color="primary"
								variant="contained"
								startIcon={<AddLink />}
								onClick={handleOpenAddFieldsDialog}
								disabled={store.fields.length === 0}
							>
								Link Fields
							</Button>
						</div>
					</Tooltip>
				</div>
			}
		>
			<div className={styles.fieldMappingPageContent}>
				<Scrollbar contentClassName={styles.scrollContent}>
					<FieldMappingOptionSection
						fieldMapping={fieldMapping}
						onUpdateFieldMappingInfo={handleUpdateFieldMappingInfo}
					/>
					<LinkedFieldsSection
						linkedFields={store.fields.filter((v) => v.linked)}
						onRemoveField={handleUnlinkField}
					/>
					{store.fields.length > 0 && (
						<LinkFieldsDialog
							open={store.addFieldsDialogOpen}
							onClose={handleCloseAddFieldsDialog}
							onAddFields={handleLinkFields}
							fields={availableFields}
						/>
					)}
				</Scrollbar>
			</div>
		</AdministrationPageContainer>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	fieldMappingPageContent: {
		display: 'flex',
		flexDirection: 'column',
		width: percent(100),
	},

	scrollContent: {
		marginRight: theme.spacing(1),
	},
}))
