import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import AuthenticationProviderInstancesClient from '../../../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { AuthenticationProviderContextProvider } from './AuthenticationProviderContext'
import { AuthenticationProviderInstancePage } from './AuthenticationProviderInstancePage'
import { AuthenticationProvidersDashboard } from './AuthenticationProvidersDashboard'

export const AuthenticationProvidersRouter = observer(() => {
	const { path } = useRouteMatch()

	const localStore = useLocalObservable(() => ({
		organizationInstanceNames: [] as string[],
	}))

	updateBreadcrumbs(
		`/_administration/_authentication-providers`,
		'Authentication Providers',
	)

	useEffect(() => {
		const instancesClient = new AuthenticationProviderInstancesClient()
		instancesClient.getInstances().then(
			action((response) => {
				localStore.organizationInstanceNames = response.data.map(
					(v) => v.displayName,
				)
			}),
		)
	}, [])

	return (
		<AuthenticationProviderContextProvider
			organizationInstanceNames={localStore.organizationInstanceNames}
		>
			<Switch>
				<Route path={makeChildRoute(':instanceId', path)}>
					<AuthenticationProviderInstancePage />
				</Route>

				<Route path={makeChildRoute('', path)}>
					<AuthenticationProvidersDashboard />
				</Route>
			</Switch>
		</AuthenticationProviderContextProvider>
	)
})
