import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	LinearProgress,
	TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import * as yup from 'yup'
import UserClient from '../../../../../../api/clients/identity/UserClient'
import { toastService } from '../../../../../../services/notifications/ToastService'

export const UserPasswordSection = () => {
	const userClient = new UserClient()

	const validationSchema = yup.object({
		currentPassword: yup
			.string()
			.required('Current password is a required field'),
		newPassword: yup
			.string()
			.required('New password is a required field')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
				'Password should have at least one uppercase letter, \r\none lowercase letter, one number, and one special character',
			)
			.test(
				'new-password',
				'New password cannot match current one',
				function (value) {
					return value !== this.parent.currentPassword
				},
			),
		confirmPassword: yup
			.string()
			.required('Confirm password is a required field')
			.test('passwords-match', 'Passwords must match', function (value) {
				return this.parent.newPassword === value
			}),
	})

	const formik = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting, resetForm }) => {
			setSubmitting(true) // formik doesn't recognize when to set isSubmitting to false so we have to do it ourselves

			userClient
				.ChangePassword({
					currentPassword: values.currentPassword,
					newPassword: values.newPassword,
				})
				.then(() =>
					toastService.displayToast({
						message: 'Password changed successfully',
						area: 'global',
					}),
				)
				.then(() => {
					resetForm()
				})
				.catch((e) => {
					console.log(e)
					toastService.displayToast({
						message: 'Incorrect current password', // we know this because this is the only possible error
						area: 'global',
					})

					formik.errors.currentPassword = 'Incorrect password'
				})
				.finally(() => {
					setSubmitting(false)
				})
		},
	})

	return (
		<Box component={Card} display="flex" flexDirection="column">
			<CardHeader title="Change Password" />
			<Divider />
			<Box
				component={CardContent}
				display="flex"
				flexDirection="column"
				gap={2}
			>
				<TextField
					fullWidth
					id="currentPassword"
					type="password"
					name="currentPassword"
					label="Current Password"
					value={formik.values.currentPassword}
					helperText={
						(formik.touched.currentPassword && formik.errors.currentPassword) ??
						' '
					}
					error={
						!!formik.errors.currentPassword && formik.touched.currentPassword
					}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<TextField
					fullWidth
					id="newPassword"
					type="password"
					name="newPassword"
					label="New Password"
					value={formik.values.newPassword}
					helperText={
						(formik.touched.newPassword && formik.errors.newPassword) ?? ' '
					}
					error={!!formik.errors.newPassword && formik.touched.newPassword}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<TextField
					fullWidth
					id="confirmPassword"
					type="password"
					name="confirmPassword"
					label="Confirm Password"
					value={formik.values.confirmPassword}
					helperText={
						(formik.touched.confirmPassword && formik.errors.confirmPassword) ??
						' '
					}
					error={
						!!formik.errors.confirmPassword && formik.touched.confirmPassword
					}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
			</Box>
			<Divider />
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="flex-end"
				alignItems="center"
				padding={2}
			>
				<Box
					component={Button}
					disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
					color="primary"
					variant="contained"
					onClick={formik.submitForm}
					marginY={1}
				>
					Save Changes
				</Box>
			</Box>
			{formik.isSubmitting && <LinearProgress />}
		</Box>
	)
}
