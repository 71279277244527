import { OpenInNew } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import DataProviderInstancesClient from '../../../api/clients/dataProviders/DataProviderInstancesClient'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../utils/HOC/FixedHeaders'
import { MonacoEditor } from '../../CodeEditor/MonacoEditor'
import { DataProviderInfo } from '../Components/Inputs/SelectInput'
import { TypedEditor } from './PropertySchematicConfigurator'

// need to configure data provider (using select executing AllDataProviders data provider)
// parameters, expected key and value

type DataProviderOption = {
	id: number
	name: string
	description: string
}

const DataProviderPropertyConfigurator = observer(
	(props: TypedEditor<DataProviderInfo>) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			dataProviderOptions: [] as DataProviderOption[],
			dialogOpen: false,
			selectedDataProviderId: props.value?.dataProviderId ?? 0,
			parameters: props.value?.parameters ?? ({} as Record<string, string>),
			key: props.value?.key ?? '',
			displayValue: props.value?.displayValue ?? '',
		}))

		useEffect(() => {
			const dataProviderInstancesClient = new DataProviderInstancesClient()

			dataProviderInstancesClient
				.execute(2, {}) // the 2 is hardcoded from the AllDataProviderInstances internal Data Provider ID
				.then(
					action((response) => {
						localStore.dataProviderOptions =
							response.data as DataProviderOption[]
					}),
				)
		}, [])

		const handleMonacoChange = action((v: string | undefined) => {
			if (v === undefined) return

			const parsed = JSON.parse(v)

			localStore.parameters = parsed
		})

		return (
			<>
				<Button
					onClick={action(() => (localStore.dialogOpen = true))}
					color="primary"
					variant="contained"
				>
					Select a Data Provider
				</Button>
				<Dialog fullWidth open={localStore.dialogOpen}>
					<DialogTitle>Configure Data Provider</DialogTitle>
					<DialogContent>
						<FixedHeaderWithHint
							label="Data Provider"
							hint={
								localStore.selectedDataProviderId !== 0 ? (
									<>
										Test this data provider
										<IconButton
											size="small"
											LinkComponent="a"
											href={`/_administration/_data-providers/${localStore.selectedDataProviderId}`}
											target="_blank"
										>
											<OpenInNew fontSize="small" />
										</IconButton>
									</>
								) : (
									''
								)
							}
						>
							<Select
								fullWidth
								value={localStore.selectedDataProviderId}
								onChange={action(
									(evt: SelectChangeEvent<number>) =>
										(localStore.selectedDataProviderId =
											typeof evt.target.value === 'number'
												? evt.target.value
												: parseInt(evt.target.value)),
								)}
							>
								{localStore.dataProviderOptions.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FixedHeaderWithHint>
						<FixedHeader label="Parameters">
							<MonacoEditor
								language="json"
								value={JSON.stringify(localStore.parameters)}
								onChange={(v: string | undefined) => {
									handleMonacoChange(v)
								}}
								height="20vh"
							/>
						</FixedHeader>
						<div className={styles.keyAndDisplayValue}>
							<FixedHeader label="Key" className={styles.textField}>
								<TextField
									fullWidth
									value={localStore.key}
									onChange={action(
										(evt) => (localStore.key = evt.currentTarget.value),
									)}
								/>
							</FixedHeader>
							<FixedHeader label="Display Value" className={styles.textField}>
								<TextField
									fullWidth
									value={localStore.displayValue}
									onChange={action(
										(evt) =>
											(localStore.displayValue = evt.currentTarget.value),
									)}
								/>
							</FixedHeader>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={action(() => (localStore.dialogOpen = false))}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={action(() => {
								localStore.dialogOpen = false
								props.onChange({
									dataProviderId: localStore.selectedDataProviderId,
									key: localStore.key,
									displayValue: localStore.displayValue,
									parameters: localStore.parameters,
								})
							})}
						>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	keyAndDisplayValue: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	textField: {
		width: percent(100),
		padding: theme.spacing(1),
	},
}))

export default DataProviderPropertyConfigurator
