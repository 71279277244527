import { action, computed, observable, makeObservable } from "mobx";
import { Point } from './geometry'
import {TranslationScale} from "./MapInteraction";

export class MapInteractionStore {
    private _translationScale: TranslationScale = {
		scale: 1,
		translation: {
			x: 0,
			y: 0
		}
	};

    private _translationScaleCallbacks: ((translationScale: TranslationScale) => void)[] = []

    constructor() {
        makeObservable<MapInteractionStore, "_translationScale">(this, {
            _translationScale: observable,
            translationScale: computed,
            scale: computed,
            translation: computed,
            setTranslationScale: action
        });
    }

    get translationScale(): Readonly<TranslationScale> {
		return this._translationScale
	}

    get scale(): number {
		return this._translationScale.scale
	}

    get translation(): Readonly<Point> {
		return this._translationScale.translation
	}

    public setTranslationScale(translationScale: TranslationScale) {
		this._translationScale = translationScale

		for (const callback of this._translationScaleCallbacks) {
			callback(translationScale)
		}
	}

    public registerTranslationScaleCallback(callback: ((translationScale: TranslationScale) => void)): void {
		this._translationScaleCallbacks.push(callback)
	}

    public removeTranslationScaleCallback(callback: ((translationScale: TranslationScale) => void)): void {
		const index = this._translationScaleCallbacks.findIndex(_ => callback)
		this._translationScaleCallbacks.splice(index, 1)
	}
}