import { Delete, Edit } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { property } from 'lodash'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import {
	CreateOrganizationPropertyModel,
	OrganizationPropertiesClient,
	OrganizationPropertyModel,
	OrganizationPropertyType,
	OrganizationPropertyValue,
} from '../../../../api/clients/identity/OrganizationPropertiesClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { usePrompts } from '../../../../services/notifications/PromptService'
import { toastService } from '../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { CreateOrganizationPropertyDialog } from './CreateOrganizationPropertyDialog'

export const OrganizationPropertiesPage = observer(() => {
	const organizationPropertiesClient = new OrganizationPropertiesClient()

	const history = useHistory()

	const store = useLocalObservable(() => ({
		properties: undefined as OrganizationPropertyModel[] | undefined,
		createPropertyOpen: false,
	}))

	const handleCreatePropertyClicked = action(() => {
		store.createPropertyOpen = true
	})

	const handleCloseCreateProperty = action(() => {
		store.createPropertyOpen = false
	})

	const handleCreateProperty = async (
		property: CreateOrganizationPropertyModel,
	) => {
		try {
			runInAction(() => {
				store.createPropertyOpen = false
			})

			const { data } =
				await organizationPropertiesClient.AddOrganizationProperty(property)

			runInAction(() => {
				if (store.properties) store.properties.push(data)
			})

			toastService.displayToast({
				message: 'Organization property created',
				area: 'global',
			})

			history.push(`/_administration/_organization-properties/${data.id}`)
		} catch (error) {
			alert(error)

			toastService.displayToast({
				message: 'Error creating organization property',
				area: 'global',
			})
		}
	}

	useEffect(() => {
		organizationPropertiesClient.GetOrganizationProperties().then(
			action((v) => {
				store.properties = v.data
			}),
		)
	}, [])

	return (
		<>
			<AdministrationPageContainer
				title="Organization Properties"
				actions={
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Edit />}
							onClick={handleCreatePropertyClicked}
						>
							Create Property
						</Button>
					</div>
				}
			>
				{store.properties === undefined ? (
					<FullscreenSpinner />
				) : (
					<OrganizationPropertiesTable
						organizationProperties={store.properties}
						onDeleteProperty={(property) => {
							organizationPropertiesClient
								.DeleteOrganizationProperty(property.id)
								.then(
									action((v) => {
										const index = store.properties?.findIndex(
											(v) => v.id == property.id,
										)
										if (index === undefined) return
										store.properties?.splice(index, 1)
									}),
								)
						}}
						onPropertyUpdated={(id, value) => {
							organizationPropertiesClient
								.UpdateOrganizationProperty(id, { name: property.name, value })
								.then(
									action((v) => {
										if (store.properties === undefined)
											throw new Error(
												'store properties are undefined. Should be impossible',
											)
										for (const property of store.properties) {
											if (property.id === id) {
												property.value = value
												toastService.displayToast({
													message: `Updated organization property value`,
													area: 'global',
												})
											}
										}
									}),
								)
								.catch(() => {
									toastService.displayToast({
										message: 'Error updating organization property value',
										area: 'global',
									})
								})
						}}
					/>
				)}
			</AdministrationPageContainer>
			<CreateOrganizationPropertyDialog
				open={store.createPropertyOpen}
				existingPropertyNames={store.properties?.map((v) => v.name) ?? []}
				onCreateClicked={handleCreateProperty}
				onClose={handleCloseCreateProperty}
			/>
		</>
	)
})

type OrganizationPropertiesTableProps = {
	organizationProperties: OrganizationPropertyModel[]
	onDeleteProperty(property: OrganizationPropertyModel): void
	onPropertyUpdated(propertyId: number, value: OrganizationPropertyValue): void
}

const OrganizationPropertiesTable = observer(
	(props: OrganizationPropertiesTableProps) => {
		const promptService = usePrompts()
		const history = useHistory()
		const styles = useStyles()

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Type</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.organizationProperties.map((property) => {
								return (
									<TableRow key={property.id}>
										<TableCell>{property.name}</TableCell>
										<TableCell>
											{property.value?.type === OrganizationPropertyType.String
												? 'Text'
												: 'List'}
										</TableCell>
										<TableCell align="right">
											<IconButton
												size="large"
												onClick={() =>
													history.push(
														`/_administration/_organization-properties/${property.id}`,
													)
												}
											>
												<Edit fontSize="small" />
											</IconButton>
											<IconButton
												size="large"
												onClick={() => {
													promptService
														.showDialog((props) => (
															<ConfirmDeletePropertyPrompt
																propertyName={property.name}
																onCancel={() => props.close('cancel')}
																onConfirm={() => props.close('okay')}
															/>
														))
														.then((v) => {
															if (v == 'okay') props.onDeleteProperty(property)
														})
												}}
											>
												<Delete fontSize="small" />
											</IconButton>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

type ConfirmDeletePropertyPromptProps = {
	propertyName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeletePropertyPrompt = (
	props: ConfirmDeletePropertyPromptProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete Property</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete the <b>{props.propertyName}</b>{' '}
					organization property?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		margin: theme.spacing(0, 0, 3, 0),
		height: 'fit-content',
		overflow: 'auto',
	},
}))
