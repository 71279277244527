import { FieldMappingActionDefinition } from './FieldMappingAction/FieldMappingAction'
import { IEventAction } from './IEventAction'

let registryInitialized = false

export function getGlobalEventActionRegistry(): GlobalEventActionRegistryType {
	if (!registryInitialized) initialize()
	return GlobalEventActionRegistryObject
}

function initialize() {
	registryInitialized = true

	GlobalEventActionRegistryObject.actionCollection = {
		[FieldMappingActionDefinition.id]: FieldMappingActionDefinition,
	} as EventActionRegistryDictionary
}

type GlobalEventActionRegistryType = {
	actionCollection: Record<string, IEventAction>
}

type EventActionRegistryDictionary = Record<string, IEventAction>

const GlobalEventActionRegistryObject: GlobalEventActionRegistryType = {
	actionCollection: {} as EventActionRegistryDictionary,
}
