import { makeAutoObservable } from 'mobx'
import * as React from 'react'
import { ComponentType, createContext, PropsWithChildren } from 'react'
import { CellType } from '../../../../FormHost/Types/CellType'
import { IFormBuilderFormHost } from '../../../IFormBuilderFormHost'
import { ILayoutFormBuilderCellInstance } from '../../FormBuilderCellInstance'
import { LayoutElement } from '../../FormBuilderCellTree'
import { ComponentRegistryDictionary } from '../../GlobalComponentRegistry'
import { renderCells } from '../cellRenderer'
import {
	CellWrapperType,
	ComponentWrapper,
	FormBuilderElementContextProps,
	NullCellNodeType,
	NullContextType,
} from './ContextTypes'

const cellContext = createContext<LayoutCellInstanceManager | undefined>(
	undefined,
)

export const LayoutContextProvider = (
	props: PropsWithChildren<FormBuilderElementContextProps>,
) => {
	console.time('layout')

	const manager = React.useMemo(() => {
		const node = props.node
		if (node.cellType !== CellType.Layout)
			throw new Error('Provided node was not a layout')

		const layoutInstanceManager = new LayoutCellInstanceManager(
			props.componentRegistry,
			props.formHost,
			node,
			props.CellWrapper,
			props.NullCellNode,
		)
		return layoutInstanceManager
	}, [
		props.componentRegistry,
		props.formHost,
		props.node,
		props.CellWrapper,
		props.NullCellNode,
	])
	console.timeEnd('layout')

	return (
		<cellContext.Provider value={manager}>
			{props.children}
		</cellContext.Provider>
	)
}

export function useLayoutCellContext() {
	const context = React.useContext(cellContext)
	if (context === undefined) throw new Error('unable to create layout context')
	return context
}

class LayoutCellInstanceManager {
	private readonly _componentRegistry: ComponentRegistryDictionary

	private readonly _cellManager: IFormBuilderFormHost

	public get cellInstance(): ILayoutFormBuilderCellInstance {
		return this.node.cell
	}

	public readonly node: LayoutElement

	private readonly CellWrapper: ComponentType<
		PropsWithChildren<ComponentWrapper>
	>

	private readonly NullCellNode?: ComponentType<NullContextType>

	public get cellComponents(): JSX.Element[] {
		return renderCells(
			{
				componentRegistry: this._componentRegistry,
				formHost: this._cellManager,
				CellWrapper: this.CellWrapper,
				NullCellNode: this.NullCellNode,
			},
			this.node.children,
		)
	}

	constructor(
		componentRegistry: ComponentRegistryDictionary,
		cellManager: IFormBuilderFormHost,
		node: LayoutElement,
		cellWrapper: CellWrapperType,
		nullCellNode?: NullCellNodeType,
	) {
		makeAutoObservable(this)

		this._componentRegistry = componentRegistry
		this._cellManager = cellManager
		this.node = node
		this.CellWrapper = cellWrapper
		this.NullCellNode = nullCellNode
	}
}
