import {
	DarkMode,
	ExitToApp,
	InfoOutlined,
	LightMode,
	Menu as MenuIcon,
	OpenInNew,
	Settings,
	Tune,
	WifiOff,
} from '@mui/icons-material'
import Description from '@mui/icons-material/Description'
import Home from '@mui/icons-material/Home'
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Chip,
	Dialog,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Theme,
	Toolbar,
	Typography,
	useMediaQuery,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import UserClient from '../../api/clients/identity/UserClient'
import { buildId, buildVersion } from '../../build-id'
import { ThemeContext } from '../../components/App'
import { NotificationCenterIcon } from '../../components/notificationCenter'
import { SessionService } from '../../services/session/SessionService'

const HeaderButton = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		margin: theme.spacing(0, 0.25),
	},
}))(Button)

const OrganizationFrame = (
	props: React.PropsWithChildren<Record<string, unknown>>,
) => {
	const styles = useStyles()
	const sessionService = new SessionService()

	const history = useHistory()

	const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	// for the user menu
	const [userAnchorEl, setUserAnchorEl] = React.useState<HTMLElement | null>(
		null,
	)

	const [infoOpen, setInfoOpen] = React.useState<boolean>(false)

	// for the menu that displays the org name, documentation, and admin when screen is too small
	const [navigationAnchorEl, setNavigationAnchorEl] =
		React.useState<HTMLElement | null>(null)

	const [profilePictureUrl, setProfilePictureUrl] = React.useState<string>()

	useEffect(() => {
		const client = new UserClient()

		const getProfilePicture = async () => {
			const { data } = await client.GetProfilePicture(
				sessionService.authToken.id as number,
			)
			const url = window.URL.createObjectURL(data)
			setProfilePictureUrl(url)
		}

		getProfilePicture()
	}, [])

	return (
		<>
			<AppBar position="absolute" className={styles.appBar} elevation={0}>
				<Toolbar className={styles.toolbar}>
					{matches ? (
						<>
							<IconButton
								onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
									setNavigationAnchorEl(event.currentTarget)
								}}
							>
								<MenuIcon />
							</IconButton>
							<Menu
								anchorEl={navigationAnchorEl}
								onClose={() => setNavigationAnchorEl(null)}
								open={navigationAnchorEl !== null}
							>
								<MenuItem
									onClick={() => {
										history.push(`/_user/_home`)
										setNavigationAnchorEl(null)
									}}
								>
									<ListItemIcon color="inherit">
										<Home />
									</ListItemIcon>
									<ListItemText color="inherit">
										{' '}
										{sessionService.authToken.orgName}
									</ListItemText>
								</MenuItem>
								<MenuItem
									onClick={() => {
										window.location.assign(`/documentation`)
										setNavigationAnchorEl(null)
									}}
								>
									<ListItemIcon color="inherit">
										<Description />
									</ListItemIcon>
									<ListItemText color="inherit">Documentation</ListItemText>
								</MenuItem>
								{(sessionService.authToken.roles ?? []).length != 0 && (
									<MenuItem
										onClick={() => {
											history.push(`/_administration`)
											setNavigationAnchorEl(null)
										}}
									>
										<ListItemIcon color="inherit">
											<Tune />
										</ListItemIcon>
										<ListItemText color="inherit">Administration</ListItemText>
									</MenuItem>
								)}
							</Menu>
						</>
					) : (
						<div>
							<HeaderButton
								startIcon={<Home />}
								color="inherit"
								onClick={() => {
									history.push(`/_user/_home`)
								}}
							>
								{sessionService.authToken.orgName}
							</HeaderButton>
							<HeaderButton
								startIcon={<Description />}
								color="inherit"
								onClick={() => {
									window.location.assign(`/documentation`)
								}}
							>
								Documentation
							</HeaderButton>
							{(sessionService.authToken.roles ?? []).length != 0 && (
								<HeaderButton
									startIcon={<Tune />}
									color="inherit"
									onClick={() => {
										history.push(`/_administration`)
									}}
								>
									Administration
								</HeaderButton>
							)}
						</div>
					)}
					<Box display="flex" gap={3} alignItems="center">
						<OnlineTracker />

						<NotificationCenterIcon />

						<IconButton
							onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
								setUserAnchorEl(event.currentTarget)
							}}
							color="inherit"
						>
							<Avatar src={profilePictureUrl} />
						</IconButton>
						<Menu
							anchorEl={userAnchorEl}
							open={Boolean(userAnchorEl)}
							onClose={() => setUserAnchorEl(null)}
						>
							<MenuItem
								onClick={() => {
									history.push(`/_users/${sessionService.authToken.id}`)
								}}
							>
								<ListItemIcon>
									<Avatar src={profilePictureUrl} />
								</ListItemIcon>
								<ListItemText
									primary={''.concat(
										sessionService.authToken.firstName as string,
										' ',
										sessionService.authToken.lastName as string,
									)}
									secondary={sessionService.authToken.orgName}
								/>
							</MenuItem>
							<Divider variant="middle" />
							<ThemeContext.Consumer>
								{({ theme, toggleTheme }) => (
									<MenuItem onClick={toggleTheme}>
										<ListItemIcon>
											{theme.palette.mode === 'light' ? (
												<DarkMode />
											) : (
												<LightMode />
											)}
										</ListItemIcon>
										Toggle Theme
									</MenuItem>
								)}
							</ThemeContext.Consumer>
							<MenuItem
								onClick={() => {
									history.push(`/_users/${sessionService.authToken.id}`)
								}}
							>
								<ListItemIcon>
									<Settings />
								</ListItemIcon>
								Settings
							</MenuItem>
							<MenuItem
								onClick={() => {
									setInfoOpen(true)
								}}
							>
								<ListItemIcon>
									<InfoOutlined />
								</ListItemIcon>
								About
							</MenuItem>

							<AboutInfo open={infoOpen} onClose={() => setInfoOpen(false)} />

							<Divider variant="middle" />
							<MenuItem
								onClick={() => {
									sessionService.logout()
									history.push(`/_logout`)
								}}
							>
								<ListItemIcon>
									<ExitToApp />
								</ListItemIcon>
								Sign Out
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>
			<div className={styles.organizationFrameRoot}>
				<div className={styles.toolbar} />
				{props.children}
			</div>
		</>
	)
}

const AboutInfo = ({
	open,
	onClose,
}: {
	open: boolean
	onClose: () => void
}) => {
	return (
		<Dialog fullWidth maxWidth="xs" open={open} onClose={() => onClose()}>
			<Typography
				paddingX={2}
				paddingY={1}
				variant="h6"
				display={'flex'}
				gap={1}
				alignItems={'center'}
			>
				<InfoOutlined color="info" /> About Forms inMotion
			</Typography>
			<Divider />
			<Box display={'flex'} paddingX={2} paddingY={1} flexDirection={'column'}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography variant="subtitle2">
						FormsInMotion Release Version
					</Typography>
					<Typography variant="caption" color={'textSecondary'}>
						{buildVersion}
					</Typography>
				</Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Typography variant="subtitle2">Build Identifier</Typography>
					<Typography variant="caption" color={'textSecondary'}>
						{buildId}
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					paddingTop={1}
				>
					<Typography variant="subtitle2">
						View EULA
						<IconButton size="small" href="/_eula" target="blank">
							<OpenInNew fontSize="small" />
						</IconButton>
					</Typography>
				</Box>
			</Box>
		</Dialog>
	)
}

const OnlineTracker = () => {
	const [online, setOnline] = useState(navigator.onLine)

	useEffect(() => {
		const loopHandle = window.setInterval(() => {
			if (online !== navigator.onLine) setOnline(navigator.onLine)
		}, 50)

		return () => {
			window.clearInterval(loopHandle)
		}
	}, [online])

	if (online) return <></>

	return (
		<Box flex={1} display="flex" justifyContent={'center'}>
			<Chip
				color={'error'}
				variant="outlined"
				label={'Offline'}
				icon={<WifiOff />}
			/>
		</Box>
	)
}

export default OrganizationFrame

const useStyles = makeStyles((theme: Theme) => ({
	organizationFrameRoot: {
		display: 'flex',
		flexDirection: 'column',

		backgroundColor: theme.palette.background.default,

		height: percent(100),
		width: percent(100),

		overflowY: 'hidden',
	},

	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		...theme.mixins.toolbar,
	},

	content: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'column',

		height: percent(100),
		width: percent(100),
	},

	contentContainer: {
		flexGrow: 1,

		height: percent(100),
		width: percent(100),

		backgroundColor:
			theme.palette.mode === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[800],
	},
}))
