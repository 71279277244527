import { createEvent } from "../../../FormBuilderCore/eventBus/EventSpecification"
import { EventBusEventSource } from "../../../FormBuilderCore/eventBus/EventTypes"

// TODO - eventually we will need props to tell us what kind of submittal (Submit, Approve, Reject)
export type EventBusBeforeSubmitFormEventProps = Record<string, unknown>

export type EventBusBeforeSubmitFormEventSource = EventBusEventSource

export const beforeSubmitFormEventId = '40363657-01d7-4b18-916c-152d774876f7'

export const [beforeSubmitFormEventEmitter, beforeSubmitFormEventConsumer] = createEvent<
	EventBusBeforeSubmitFormEventProps,
	EventBusBeforeSubmitFormEventSource
>(beforeSubmitFormEventId, 'Before submit form')
