import { Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { ComponentProps } from 'react'
import {
	PropsEditorComponent,
	specifyVoidComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import {
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'

type ParagraphComponentProps = {
	textAlignment: string
	text: string
}

export const ParagraphComponent = observer((props: ParagraphComponentProps) => {
	return (
		<Typography
			textAlign={props.textAlignment as 'left' | 'center' | 'right'}
			variant="body1"
		>
			{props.text}
		</Typography>
	)
})

export const ParagraphComponentElementTag =
	'3ea04e3e-6866-414d-964e-b658f27703ec'

export const ParagraphComponentDefinitionId =
	'c418ecd6-1a42-4c37-abf9-ca347389f5ba'

const ParagraphComponentPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof ParagraphComponent>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.textAlignment}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, textAlignment: v })
				}
				Editor={(props) => (
					<SelectPropertyConfigurator
						options={[
							{ key: 'left', displayValue: 'Left to Right' },
							{ key: 'center', displayValue: 'centered' },
							{ key: 'right', displayValue: 'Right to Left' },
						]}
						{...props}
					/>
				)}
				label="Text Alignment"
			/>
			<PropertySchematicConfigurator
				value={properties.text}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, text: v })}
				Editor={TextPropertyConfigurator}
				label="Text"
			/>
		</>
	)
}

export const ParagraphComponentDefinition = specifyVoidComponent(
	ParagraphComponentDefinitionId,
	ParagraphComponent,
	ParagraphComponentElementTag,
	'Paragraph',
	{
		configurator: ParagraphComponentPropsEditor,
		defaultProperties: {
			textAlignment: 'left',
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		},
	},
)
