import {
	Card,
	CardHeader,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	MenuItem,
	Select,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
	FormConfiguration,
	FormWidthSetting,
} from '../../../../../../api/DTOtemp'

type FormSettingsCardProps = {
	formType: 'HTML' | 'PDF' | 'FB'
	settings: FormConfiguration
	onSettingsChanged: (newSettings: FormConfiguration) => void
}

const FormSettingsCard = ({
	formType,
	settings,
	onSettingsChanged,
}: FormSettingsCardProps) => {
	// there was a check here for html or pdf, but FB forms were hardcoded to say HTML
	// so whenever we make specific settings for FB forms we need to add this check back
	return (
		<UploadedFormSettingsCard
			settings={settings as UploadedFormSettings}
			onSettingsChanged={onSettingsChanged}
		/>
	)

	return <> </>
}

type UploadedFormSettings = {
	downloadImageOnSubmit: boolean
	disableSignatureEmails: boolean
	widthSetting: FormWidthSetting
}

type UploadedFormSettingsProps = {
	settings: UploadedFormSettings
	onSettingsChanged: (newSettings: UploadedFormSettings) => void
}

/**
 * for uploaded forms, HTML or PDF
 */
const UploadedFormSettingsCard = ({
	settings,
	onSettingsChanged,
}: UploadedFormSettingsProps) => {
	const styles = useStyles()

	return (
		<Card className={styles.formPageTopItem}>
			<CardHeader title={<Typography variant="h6">Settings</Typography>} />
			<Divider />
			<div className={styles.formSettingsSection}>
				<FormGroup>
					<FormControlLabel
						label="Prompt the user to download an image of the form when it is submitted"
						control={
							<Checkbox
								checked={settings.downloadImageOnSubmit ?? false}
								onChange={(evt) => {
									const newSettings = { ...settings }
									newSettings.downloadImageOnSubmit = evt.target.checked
									onSettingsChanged(newSettings)
								}}
								color="primary"
							/>
						}
					/>
					<FormControlLabel
						label="Disable sending form signature emails"
						control={
							<Checkbox
								checked={settings.disableSignatureEmails ?? false}
								onChange={(evt) => {
									const newSettings = { ...settings }
									newSettings.disableSignatureEmails = evt.target.checked
									onSettingsChanged(newSettings)
								}}
								color="primary"
							/>
						}
					/>

					<Typography variant="subtitle1" color="textPrimary">
						Form Width
					</Typography>
					<Select
						value={settings.widthSetting}
						onChange={(evt) => {
							const newSettings = { ...settings }
							newSettings.widthSetting = evt.target.value as FormWidthSetting
							onSettingsChanged(newSettings)
						}}
					>
						<MenuItem value={FormWidthSetting.Paper}>Paper</MenuItem>
						<MenuItem value={FormWidthSetting.FullWidth}>Full Width</MenuItem>
					</Select>
				</FormGroup>
			</div>
		</Card>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formPageTopItem: {
		flex: '1',
	},

	formSettingsSection: {
		display: 'flex',
		padding: theme.spacing(2),
	},
}))

export default FormSettingsCard
