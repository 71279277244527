import { Create } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	MenuItem,
	Select,
	SelectChangeEvent,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormsClient } from '../../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { DetailForm } from '../../../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../../../components/feedback/circular'
import { CellDefinition } from '../../../../../../../../modules/FormHost/Types/CellDefinition'
import { ValidationEventRegistration } from '../../../../../../../../modules/FormHost/Validation/Types'
import { ValidationEvents } from '../../../../../../../../modules/FormHost/Validation/ValidationEvents'
import { useModals } from '../../../../../../../../services/notifications/ModalService'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import { FixedHeader } from '../../../../../../../../utils/HOC/FixedHeaders'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'
import { FormPackageValidationSection } from './FormPackageValidationSection'

type FormPackageValidationPageProps = {
	versionNumber: number
} & FormPackageAdministrationPageProps

export const FormPackageValidationPage = observer(
	(props: FormPackageValidationPageProps) => {
		const styles = useStyles()
		const modalService = useModals()

		const { formPackage } = useFormPackageContext()
		const formPackageClient = new FormPackageVersionClient(props.packageId)

		const localStore = useLocalObservable(() => ({
			forms: undefined as DetailForm[] | undefined,
			cellDefinitions: undefined as CellDefinition[] | undefined,
			validationRegistrations:
				formPackage.configuration.validationEventRegistrations,
		}))

		useEffect(() => {
			const client = new FormsClient(props.packageId, props.versionNumber)
			client.GetAllForms().then(
				action((response) => {
					localStore.forms = response.data

					const definitions: CellDefinition[] = []
					for (const form of response.data)
						definitions.push(...form.metadata.cellDefinitions)

					localStore.cellDefinitions = definitions
				}),
			)
		}, [])

		const handleAddRegistration = async (
			registration: ValidationEventRegistration,
		) => {
			runInAction(() => {
				formPackage.configuration.validationEventRegistrations.push(
					registration,
				)
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)
				toastService.displayToast({
					message: 'Field validation added',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error adding field validation',
					area: 'global',
				})
			}
		}

		const handleDeleteRegistration = async (registrationId: string) => {
			const indexToRemove =
				formPackage.configuration.validationEventRegistrations.findIndex(
					(v) => v.registrationId === registrationId,
				)
			if (indexToRemove < 0) return

			formPackage.configuration.validationEventRegistrations.splice(
				indexToRemove,
				1,
			)

			try {
				await formPackageClient.UpdateConfiguration(formPackage)
				toastService.displayToast({
					message: 'Field validation removed',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error removing field validation',
					area: 'global',
				})
			}
		}

		const handleUpdateRegistration = async (
			registration: ValidationEventRegistration,
		) => {
			const index =
				formPackage.configuration.validationEventRegistrations.findIndex(
					(v) => v.registrationId === registration.registrationId,
				)
			if (index < 0)
				throw new Error(
					`could not find registration ${registration.registrationId} to update`,
				)

			runInAction(() => {
				formPackage.configuration.validationEventRegistrations[index] =
					registration
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)
				toastService.displayToast({
					message: 'Field validation updated',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error updating field validation',
					area: 'global',
				})
			}
		}

		if (
			localStore.forms === undefined ||
			localStore.cellDefinitions === undefined
		)
			return <FullscreenSpinner />

		return (
			<AdministrationPageContainer
				title="Form Package Validation"
				actions={
					<div>
						<Button
							color="primary"
							variant="contained"
							startIcon={<Create />}
							disabled={
								localStore.forms === undefined || localStore.forms.length === 0
							}
							onClick={() => {
								modalService
									.showForm((modalProps) => {
										if (localStore.forms === undefined) return <></>
										return (
											<AddFieldValidationDialog
												forms={localStore.forms}
												registrations={localStore.validationRegistrations}
												onConfirm={(v) =>
													modalProps.close({
														closeResult: 'okay',
														value: v,
													})
												}
												onCancel={() =>
													modalProps.close({ closeResult: 'cancel' })
												}
											/>
										)
									})
									.then((v) => {
										if (v.closeResult === 'okay') {
											handleAddRegistration(
												v.value as ValidationEventRegistration,
											)
										}
									})
							}}
						>
							Add Field Validation
						</Button>
					</div>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={6}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<FormPackageValidationSection
						forms={localStore.forms}
						cellDefinitions={localStore.cellDefinitions}
						validationRegistrations={localStore.validationRegistrations}
						onDeleteRegistration={handleDeleteRegistration}
						onUpdateRegistration={handleUpdateRegistration}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type AddFieldValidationDialogProps = {
	forms: DetailForm[]
	registrations: ValidationEventRegistration[]
	onConfirm: (eventRegistration: ValidationEventRegistration) => void
	onCancel: () => void
}

export const AddFieldValidationDialog = observer(
	(props: AddFieldValidationDialogProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			formId: props.forms[0]?.id as number | undefined,
			definitionId: '',
			eventTypeId: '',
			parameters: {} as { [key: string]: unknown },

			get parameterConfigurator() {
				return ValidationEvents.find((v) => v.id === this.eventTypeId)
					?.parameterConfigurator
			},
		}))

		const fields = useMemo<{ name: string; definitionId: string }[]>(() => {
			const form = props.forms.find((v) => v.id === localStore.formId)
			if (form === undefined) return []

			return form.metadata.cellDefinitions.map((v) => ({
				name: v.name,
				definitionId: v.id,
			}))
		}, [localStore.formId])

		// need to make sure we're not creating duplicates of registrations
		const registrationIsUnique = () => {
			const existingRegistration = props.registrations.find(
				(v) =>
					v.eventTypeId === localStore.eventTypeId &&
					v.formId === localStore.formId &&
					v.definitionId === localStore.definitionId &&
					JSON.stringify(v.parameters) ===
						JSON.stringify(localStore.parameters),
			)

			return existingRegistration === undefined
		}

		return (
			<>
				<DialogTitle>Add Field Validation</DialogTitle>
				<DialogContent>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: percent(100),
						}}
					>
						<FixedHeader label="Form" className={styles.inputElement}>
							<Select
								value={localStore.formId ?? ''}
								onChange={action((evt: SelectChangeEvent<number | string>) => {
									localStore.formId = evt.target.value as number
									localStore.definitionId = ''
								})}
								fullWidth
							>
								{props.forms.map((form) => (
									<MenuItem key={form.id} value={form.id}>
										{form.name}
									</MenuItem>
								))}
							</Select>
						</FixedHeader>
						<FixedHeader label="Field" className={styles.inputElement}>
							<Select
								value={localStore.definitionId}
								onChange={action(
									(evt: SelectChangeEvent) =>
										(localStore.definitionId = evt.target.value),
								)}
								disabled={localStore.formId === undefined}
								fullWidth
							>
								{fields.map((field) => (
									<MenuItem key={field.definitionId} value={field.definitionId}>
										{field.name}
									</MenuItem>
								))}
							</Select>
						</FixedHeader>
					</div>
					<FixedHeader label="Validation Type" className={styles.inputElement}>
						<Select
							value={localStore.eventTypeId}
							onChange={action(
								(evt: SelectChangeEvent) =>
									(localStore.eventTypeId = evt.target.value),
							)}
							fullWidth
						>
							{ValidationEvents.map((event) => (
								<MenuItem key={event.id} value={event.id}>
									{event.displayName}
								</MenuItem>
							))}
						</Select>
					</FixedHeader>
					<div className={styles.inputElement}>
						{localStore.parameterConfigurator &&
							localStore.parameterConfigurator(
								localStore.parameters,
								action((v) => (localStore.parameters = v)),
							)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						disabled={
							localStore.formId === undefined ||
							localStore.definitionId === '' ||
							localStore.eventTypeId === ''
						}
						onClick={() => {
							if (!registrationIsUnique()) {
								toastService.displayToast({
									message: 'Validation must be unique',
									area: 'global',
								})
								return
							}
							props.onConfirm({
								formId: localStore.formId!,
								definitionId: localStore.definitionId,
								eventTypeId: localStore.eventTypeId,
								parameters: localStore.parameters,
								registrationId: uuidv4(),
							})
						}}
					>
						Add Field Validation
					</Button>
				</DialogActions>
			</>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	inputElement: {
		width: percent(100),
		padding: theme.spacing(1),
	},
}))
