import loader from '@monaco-editor/loader'
import Editor from '@monaco-editor/react'
import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FullscreenSpinner } from '../../components/feedback/circular'

loader.init().then((monaco) => {
	monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
		target: monaco.languages.typescript.ScriptTarget.ES6,
		allowJs: true,
		allowNonTsExtensions: true,
	})
})

export type MonacoEditorProps = {
	language: string
	value: string
	onChange: (v: string | undefined) => void

	height?: string
	width?: string
	readonly?: boolean
}

export const MonacoEditor = (props: MonacoEditorProps) => {
	const styles = useStyles()

	return (
		<div className={styles.root}>
			<div className={styles.monacoRegion}>
				<Editor
					loading={<FullscreenSpinner />}
					options={{
						automaticLayout: true,
						minimap: {
							enabled: false,
						},
						fontSize: 18,
						readOnly: props.readonly,
						domReadOnly: props.readonly,
					}}
					height={props.height}
					width={props.width}
					language={props.language}
					value={props.value}
					onChange={props.onChange}
				/>
			</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexFlow: 'column',
	},
	monacoRegion: {
		flex: 1,
		borderWidth: 1,
		padding: theme.spacing(1),
		borderColor: theme.palette.grey[500],
		borderStyle: 'solid',
		borderRadius: theme.shape.borderRadius,
	},
}))
