import { NIL } from "uuid"
import { CellType } from "../../FormHost/Types/CellType"
import { FormBuilderCellDefinition } from "../cells/FormBuilderCellDefinition"
import { FormBuilderCellInstance, isValueLikeInstance } from "../cells/FormBuilderCellInstance"

export function instancesToObject(cellDefinitions: FormBuilderCellDefinition[],
	cellInstances: FormBuilderCellInstance[],
	parentInstanceId: string = NIL): Record<string, unknown> {
	
	const childCells = cellInstances.filter(v => v.parentId === parentInstanceId)

	const obj = { } as Record<string, unknown>
	
	for (const cell of childCells) {
		if (!isValueLikeInstance(cell))
			continue
		
		const definition = cellDefinitions.find(v => v.id === cell.definitionId)
		if (definition === undefined)
			throw new Error(`no definition with id ${cell.definitionId} exists`)
		
		if(cell.type === CellType.Value)
			obj[definition.name] = cell.value

		if (cell.type === CellType.Layout)
		{
			obj[definition.name] = instancesToObject(cellDefinitions,
				cellInstances,
				cell.id)
		}

		if (cell.type === CellType.Repeat) {

			obj[definition.name] = deconstructRepeatableSection(cellDefinitions,
				cellInstances,
				cell.id)
		}
	}
	
	return obj
}

function deconstructRepeatableSection(cellDefinitions: FormBuilderCellDefinition[],
	cellInstances: FormBuilderCellInstance[],
	parentInstanceId: string) {
	
	const array = []
	
	const virtualChildren = cellInstances.filter(v => v.parentId === parentInstanceId)

	for (let i = 0; i < virtualChildren.length; i++) {
		/*
		this could cause issues, we use the child's ordinal position instead because
		we could end up with a situation where elements are for some reason not 
		sequential, e.g. 1, 2, 3, 5, 7
		*/
		const virtualChild = virtualChildren[i]
		array[virtualChild.ordinalPosition] = instancesToObject(cellDefinitions,
			cellInstances,
			virtualChild.id)
	}
	return array
}