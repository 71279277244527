import { Box, TextField, TextFieldProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { observer } from 'mobx-react'
import moment from 'moment'
import { ComponentProps } from 'react'
import { FixedHeader } from '../../../../utils/HOC/FixedHeaders'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	CheckboxPropertyConfigurator,
	DatePickerPropertyConfigurator,
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type DatePickerProps = {
	label: string
	format?: string
	disableFuture?: boolean
	disablePast?: boolean
	// inputVariant: "standard" // TODO
}

const DatePicker = observer((props: DatePickerProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()

	console.log('Format! 🎨', props.format)

	return (
		<Box width="100%">
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<FixedHeader label={props.label}>
					<DesktopDatePicker
						value={(context.value as Date) ?? moment()}
						onChange={(date: Date | null) => {
							const oldValue = context.value

							context.onChange(date ?? undefined)

							changeEventEmitter(
								cellManager.eventBus,
								{
									oldValue: oldValue,
									newValue: date,
								},
								{
									formHost: cellManager,
									elementTag: DatePickerElementTag,
									definitionId: context.cellInstance.definitionId,
									instanceId: context.cellInstance.id,
								},
							)
						}}
						onClose={() => {
							focusEventEmitter(
								cellManager.eventBus,
								{
									focusType: 'FocusLost',
								},
								{
									formHost: cellManager,
									elementTag: DatePickerElementTag,
									definitionId: context.cellInstance.definitionId,
									instanceId: context.cellInstance.id,
								},
							)
						}}
						inputFormat={props.format}
						disableFuture={props.disableFuture}
						disablePast={props.disablePast}
						renderInput={(params: TextFieldProps) => (
							<TextField fullWidth {...params} />
						)}
					/>
				</FixedHeader>
			</LocalizationProvider>
		</Box>
	)
})

export const DatePickerElementTag = 'de6ee86c-ab83-4d9e-a8e9-875286444f3c'

export const DatePickerId = '800d3d45-f54c-4fc3-ac08-d9544bc916ca'

const DatePickerPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof DatePicker>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.format}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, format: v })
				}
				Editor={TextPropertyConfigurator}
				label="Format"
			/>
			<PropertySchematicConfigurator
				value={properties.disableFuture}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, disableFuture: v })
				}
				Editor={CheckboxPropertyConfigurator}
				label="Disable Future"
			/>
			<PropertySchematicConfigurator
				value={properties.disablePast}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, disablePast: v })
				}
				Editor={CheckboxPropertyConfigurator}
				label="Disable Past"
			/>
		</>
	)
}

const DatePickerValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof DatePicker>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={DatePickerPropertyConfigurator}
		label="Default Value"
	/>
)

export const DatePickerDefinition = specifyValueTypeComponent(
	DatePickerId,
	DatePicker,
	DatePickerElementTag,
	'Date Picker',
	FieldType.DateTime,
	{
		configurator: DatePickerPropsEditor,
		defaultProperties: {
			label: 'Label',
			format: 'MM/DD/YYYY',
			disableFuture: false,
			disablePast: false,
		},
	},
	{
		configurator: DatePickerValueEditor,
		defaultValue: undefined,
	},
)
