import { Create, Launch, Publish } from '@mui/icons-material'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { NIL } from 'uuid'
import * as yup from 'yup'
import {
	DetailForm,
	FormConfiguration,
	FormInfoModel,
	FormMetadata,
	FormWidthSetting,
} from '../../../../../../../../api/DTOtemp'
import { FormsClient } from '../../../../../../../../api/clients/identity'
import UploadFormDialog from '../../../../../../../../components/forms/FormUploadDialog'
import IconButtonLink from '../../../../../../../../components/links/IconButtonLink'
import { FormBuilderStructure } from '../../../../../../../../modules/FormBuilderCore/Types'
import { createSimpleDefinition } from '../../../../../../../../modules/FormBuilderCore/cells/CreateCellDefinition'
import { TextInputDefinition } from '../../../../../../../../modules/FormBuilderInterop/Components'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'
import { FixedHeaderWithHint } from '../../../../../../../../utils/HOC/FixedHeaders'
import { watchPromise } from '../../../../../../../../utils/WatchPromise'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'
import { FormsManagementTable } from './FormsManagementTable'

type FormsAdministrationPageProps = {
	packageId: number
	packageVersionNumber: number
}

export const FormsAdministrationPage = observer(
	(
		props: FormPackageAdministrationPageProps & FormsAdministrationPageProps,
	) => {
		const styles = useStyles()
		const formPackageContext = useFormPackageContext()

		const localStore = useLocalObservable(() => ({
			forms: [] as DetailForm[],
		}))

		const formList = useMemo(() => {
			const api = new FormsClient(props.packageId, props.packageVersionNumber)
			return watchPromise(
				api.GetAllForms().then(
					action((v) => {
						const sortedForms = v.data.sort(
							(a, b) => a.ordinalPosition - b.ordinalPosition,
						)
						localStore.forms = sortedForms
						return sortedForms
					}),
				),
			)
		}, [props.packageId]) // likely entirely different component if that changes

		const handleFormDelete = useCallback(
			(formId: number) => {
				const api = new FormsClient(props.packageId, props.packageVersionNumber)
				api
					.DeleteForm(formId)
					.then(
						action((v) => {
							localStore.forms = localStore.forms.filter((f) => f.id !== formId)
							toastService.displayToast({
								message: 'Form deleted',
								area: 'global',
							})

							// we need to set the validation registrations, otherwise the Validation tab will fail b/c it can't find the registrations for the deleted form
							formPackageContext.formPackage.configuration.validationEventRegistrations =
								formPackageContext.formPackage.configuration.validationEventRegistrations.filter(
									(r) => r.formId !== formId,
								)
						}),
					)
					.catch(() =>
						toastService.displayToast({
							message: 'Error deleting form',
							area: 'global',
						}),
					)
			},
			[props.packageId, props.packageVersionNumber],
		)

		return (
			<AdministrationPageContainer
				title="Form Package Forms"
				actions={
					<FormsSectionButtons
						packageId={props.packageId}
						packageVersionNumber={props.packageVersionNumber}
						forms={localStore.forms}
					/>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={1}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					{formList.status === 'Pending' && <CircularProgress />}
					{formList.status === 'Fulfilled' && (
						<FormsSection
							packageId={props.packageId}
							packageVersionNumber={props.packageVersionNumber}
							forms={localStore.forms}
							onDeleteForm={handleFormDelete}
						/>
					)}
					{formList.status === 'Rejected' &&
						'There was an error loading your form packages'}
				</div>
			</AdministrationPageContainer>
		)
	},
)

type FormsSectionProps = {
	forms: DetailForm[]
	onDeleteForm: (formId: number) => void
}

const FormsSection = observer(
	(props: FormsSectionProps & FormsAdministrationPageProps) => {
		const store = useLocalObservable(() => ({
			formsPositionsDirty: false,
		}))

		const handleFormPositionsChanged = useCallback(
			(forms: DetailForm[]) => {
				store.formsPositionsDirty = true
			},
			[store],
		)

		const handleFormOrderSaved = useCallback(
			(forms: DetailForm[]) => {
				const api = new FormsClient(props.packageId, props.packageVersionNumber)
				const newPositions = forms.map((v) => ({
					formId: v.id,
					ordinalPosition: v.ordinalPosition,
				}))
				api
					.setFormPositions(newPositions)
					.then(() =>
						toastService.displayToast({
							message: 'Form positions updated',
							area: 'global',
						}),
					)
					.catch(() =>
						toastService.displayToast({
							message: 'Error updating form positions',
							area: 'global',
						}),
					)
				store.formsPositionsDirty = false
			},
			[props.packageId, props.packageVersionNumber],
		)

		return (
			<FormsManagementTable
				forms={props.forms}
				formOrderDirty={store.formsPositionsDirty}
				onFormDeleteClicked={props.onDeleteForm}
				onFormMoved={handleFormPositionsChanged}
				onFormPositionSaveClick={handleFormOrderSaved}
			/>
		)
	},
)

const FormsSectionButtons = observer(
	(
		props: FormsAdministrationPageProps &
			Omit<FormsSectionProps, 'onDeleteForm'>,
	) => {
		const [uploadFormOpen, setUploadFormOpen] = useState(false)
		const [createFormOpen, setCreateFormOpen] = useState(false)

		const defaultFormConfiguration: FormConfiguration = {
			disableSignatureEmails: false,
			downloadImageOnSubmit: false,
			widthSetting: FormWidthSetting.Paper,
		}

		const styles = useButtonsStyles()

		const handleUploadFormClick = () => {
			setUploadFormOpen(true)
			console.log('upload click')
		}

		const handleUploadFormClose = () => {
			setUploadFormOpen(false)
		}

		const handleOpenCreateForm = useCallback(() => {
			setCreateFormOpen(true)
		}, [])

		const handleCreateFormClose = useCallback(() => {
			setCreateFormOpen(false)
		}, [])

		const history = useHistory()

		return (
			<>
				<div>
					<Button
						color="primary"
						variant="outlined"
						onClick={() => {
							history.push(`/_package-host/${props.packageId}`)
						}}
						startIcon={<Launch />}
					>
						View Package
					</Button>
					<Button
						className={styles.buttonMiddleStyle}
						color="primary"
						variant="contained"
						startIcon={<Publish />}
						onClick={handleUploadFormClick}
					>
						Upload Form
					</Button>
					<Button
						color="primary"
						variant="contained"
						startIcon={<Create />}
						onClick={handleOpenCreateForm}
					>
						Create Form
					</Button>
				</div>
				<UploadFormDialog
					packageId={props.packageId}
					packageVersionNumber={props.packageVersionNumber}
					open={uploadFormOpen}
					onClose={handleUploadFormClose}
					existingFormNames={props.forms.map((v) => v.name)}
					formConfiguration={defaultFormConfiguration}
					onFormUploaded={() => {
						// empty
					}}
				/>
				<CreateFormBuilderFormDialog
					packageId={props.packageId}
					packageVersionNumber={props.packageVersionNumber}
					open={createFormOpen}
					onClose={handleCreateFormClose}
					onFormCreated={() => {
						// empty
					}}
				/>
			</>
		)
	},
)

const useButtonsStyles = makeStyles((theme: Theme) => ({
	buttonMiddleStyle: {
		margin: theme.spacing(0, 2),
	},
}))

type FormsTableProps = {
	forms: DetailForm[]

	onFormMoved?: (formId: number, direction: 'earlier' | 'later') => void
	onFormEdit?: (formId: number) => void
	onFormDelete?: (formId: number) => void
}

const FormsTable = observer((props: FormsTableProps) => {
	const styles = useStyles()

	const { path } = useRouteMatch()

	return (
		<Fade in>
			<Paper>
				<TableContainer className={styles.root} component={Paper}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Position</TableCell>
								<TableCell>Form Name</TableCell>
								<TableCell>Form Type</TableCell>
								<TableCell>Active Version</TableCell>
								<TableCell>Last Edit Date</TableCell>
								<TableCell>Edited By</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.forms.map((v, i) => (
								<TableRow key={`${v.id}-${v.ordinalPosition}`}>
									<TableCell>{v.ordinalPosition}</TableCell>
									<TableCell>{v.name}</TableCell>
									<TableCell>{v.contentType}</TableCell>
									<TableCell>{v.activeVersion}</TableCell>
									<TableCell>
										{new Date(v.lastModifiedDate).toLocaleString()}
									</TableCell>
									<TableCell>{v.lastModifiedBy}</TableCell>
									<TableCell align="right">
										<IconButtonLink to={makeChildRoute(`_forms/${v.id}`, path)}>
											<Edit />
										</IconButtonLink>
										{props.onFormDelete && (
											<IconButton
												onClick={() => {
													props.onFormDelete && props.onFormDelete(v.id)
												}}
												size="large"
											>
												<Delete />
											</IconButton>
										)}
										{props.onFormMoved && (
											<>
												<IconButton
													disabled={i === 0}
													onClick={() => {
														props.onFormMoved &&
															props.onFormMoved(v.id, 'earlier')
													}}
													size="large"
												>
													<KeyboardArrowUp />
												</IconButton>
												<IconButton
													disabled={i === props.forms.length - 1}
													onClick={() => {
														props.onFormMoved &&
															props.onFormMoved(v.id, 'later')
													}}
													size="large"
												>
													<KeyboardArrowDown />
												</IconButton>
											</>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Fade>
	)
})

type CreateFormBuilderFormDialogProps = {
	packageId: number
	packageVersionNumber: number
	open: boolean
	onClose: () => void
	onFormCreated: (formId: boolean) => void
}

const CreateFormBuilderFormDialog = (
	props: CreateFormBuilderFormDialogProps,
) => {
	const styles = useCreateFormBuilderStyles()
	const history = useHistory()

	const validationSchema = yup.object({
		formName: yup.string().required(),
	})

	const defaultFormConfiguration: FormConfiguration = {
		disableSignatureEmails: false,
		downloadImageOnSubmit: false,
		widthSetting: FormWidthSetting.Paper,
	}

	const formik = useFormik({
		initialValues: {
			formName: 'New Form',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const api = new FormsClient(props.packageId, props.packageVersionNumber)

			const formBuilderStructure: FormBuilderStructure = {
				definitions: [
					createSimpleDefinition(TextInputDefinition, 'firstName', 0, NIL, {
						label: 'First Name',
					}),
					createSimpleDefinition(TextInputDefinition, 'lastName', 1, NIL, {
						label: 'Last Name',
					}),
				],
			}
			const fakeFile = new Blob([JSON.stringify(formBuilderStructure)], {
				type: 'application/json',
			})

			const metadata: FormMetadata = {
				cellDefinitions: formBuilderStructure.definitions,
				cellOverrides: [],
				dynamicProperties: {},
			}

			const formInfoModel: FormInfoModel = {
				name: values.formName,
				formData: fakeFile,
				metadata: metadata,
				formConfiguration: defaultFormConfiguration,
			}

			await api
				.CreateForm(formInfoModel)
				.then((v) => v.data)
				.then((v) => {
					console.log('form successfully created!')
					toastService.displayToast({ message: `Form created`, area: 'global' })
					history.push(`${props.packageId}/_forms/${v.id}`)
				})
				.catch(() => {
					toastService.displayToast({
						message: 'Error creating form',
						area: 'global',
					})
				})
		},
	})

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Create New Form</DialogTitle>
			<DialogContent className={styles.dialogContent}>
				<Typography variant="body2">
					Before you create your new form, give it a name that uniquely
					identifies it in this form package
				</Typography>
				<FixedHeaderWithHint
					label="Form Name"
					className={styles.spacing}
					hint={formik.touched.formName && formik.errors.formName}
					hintColor="error.main"
				>
					<TextField
						autoFocus
						fullWidth
						id="formName"
						name="formName"
						value={formik.values.formName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.errors.formName !== undefined}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={!formik.isValid || formik.isSubmitting}
					onClick={formik.submitForm}
				>
					Create Form
				</Button>
			</DialogActions>
			{formik.isSubmitting && <LinearProgress />}
		</Dialog>
	)
}

const useCreateFormBuilderStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
	},

	spacing: {
		margin: theme.spacing(1, 0),
	},
}))

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	root: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'hidden',
	},
}))
