import { CircularProgress, CircularProgressProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'

export const FullscreenSpinner = (props: CircularProgressProps) => {
	const styles = useStyles()

	return (
		<div className={styles.fullScreenSpinnerRoot}>
			<CircularProgress {...props} />
		</div>
	)
}

const useStyles = makeStyles(() => ({
	fullScreenSpinnerRoot: {
		display: 'flex',

		justifyContent: 'center',
		alignItems: 'center',

		height: percent(100),
		width: percent(100),
	},
}))
