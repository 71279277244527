import { Upload } from '@mui/icons-material'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import * as hashwasm from 'hash-wasm'
import { ChangeEvent, useCallback, useState } from 'react'
import Dropzone, { DropzoneState } from 'react-dropzone'
import { FormPackageInfo } from '../../../../../api/DTOtemp'
import { BlobUploadClient } from '../../../../../api/clients/blobs/BlobUploadClient'
import { FormPackageClient } from '../../../../../api/clients/identity'
import { toastService } from '../../../../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../../../../utils/HOC/FixedHeaders'

type UploadFormPackageDialogProps = {
	existingPackage?: FormPackageInfo
	existingPackageNames: string[]
	onConfirm: (uploadedPackage: FormPackageInfo) => void
	onCancel: () => void
}

export const UploadFormPackageDialog = (
	props: UploadFormPackageDialogProps,
) => {
	const [file, setFile] = useState<File | undefined>()
	const [isPosting, setIsPosting] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [packageName, setPackageName] = useState(
		props.existingPackage?.name ?? '',
	)

	const styles = useStyles()

	const upperExistingPackageNames =
		props.existingPackageNames.map((v) => v.toUpperCase()) ?? []

	const fileReader = new FileReader()

	const handleDrop = useCallback(
		(acceptedFiles: File[]) => {
			const newFile = acceptedFiles[0]

			try {
				fileReader.readAsArrayBuffer(newFile as Blob)
			} catch (e) {
				console.log(e)

				throw new Error('error reading file as array buffer')
			}

			setFile(newFile)
		},
		[packageName],
	)

	const handleUpload = async () => {
		if (file === undefined) return

		setIsPosting(true)

		validatePackageName(packageName)
		if (errorMessage !== '') return

		const packageClient = new FormPackageClient()

		const blobId = await uploadBlob()
		if (blobId === 0) {
			toastService.displayToast({
				message: 'Error uploading form package',
				area: 'global',
			})
			setIsPosting(false)
			return
		}

		const newPackage = (
			await packageClient.UploadFormPackage(
				{
					blobId,
					packageName,
				},
				props.existingPackage?.id,
			)
		).data

		setIsPosting(false)
		props.onConfirm(newPackage)
	}

	const uploadBlob = async () => {
		if (file === undefined) throw new Error('file is undefined')

		const blobUploadClient = new BlobUploadClient()

		// if the file is <30MB we don't have to worry about chunking, just upload normally
		if (Math.round(file.size / 1024) < 30) {
			try {
				const { data: blobId } = await blobUploadClient.uploadBlob(file)
				return blobId
			} catch (e) {
				console.log(e)
				return 0
			}
		}

		try {
			// hash the original file to make sure the file is uploaded correctly
			const fileHash = await hashFile()
			if (fileHash === undefined)
				throw new Error('could not hash uploaded file')

			const { data: uploadResponse } =
				await blobUploadClient.createBlobForChunkedUpload(file, fileHash)

			for (let i = 0; i < uploadResponse.chunkCount; i++) {
				const offset = i * uploadResponse.chunkSize

				const chunk = file.slice(offset, offset + uploadResponse.chunkSize)
				await blobUploadClient.uploadBlobChunk(
					i,
					uploadResponse.blobId,
					new Blob([chunk]),
				)
			}

			await blobUploadClient.commitUpload(uploadResponse.blobId)

			return uploadResponse.blobId
		} catch (e) {
			console.log(e)
			return 0
		}
	}

	// hash the file in chunks :)
	// https://stackoverflow.com/questions/768268/how-to-calculate-md5-hash-of-a-file-using-javascript/63287199#63287199
	const hashFile = async () => {
		if (file === undefined) return

		let hasher = await hashwasm.createMD5()

		const hashChunk = (chunk: Blob) => {
			return new Promise<void>((resolve) => {
				fileReader.onload = (e) => {
					const view = new Uint8Array(e.target!.result as ArrayBuffer)
					hasher?.update(view)
					resolve()
				}

				fileReader.readAsArrayBuffer(chunk)
			})
		}

		if (hasher) hasher.init()
		else hasher = await hashwasm.createMD5()

		const hashChunkSize = 64 * 1024 * 1024

		const hashChunkNumber = Math.floor(file.size / hashChunkSize)

		for (let i = 0; i <= hashChunkNumber; i++) {
			const chunk = file.slice(
				hashChunkSize * i,
				Math.min(hashChunkSize * (i + 1), file.size),
			)
			await hashChunk(chunk)
		}

		const hash = hasher.digest()
		return Promise.resolve(hash)
	}

	const handleNameChanged = useCallback(
		(evt: ChangeEvent<HTMLInputElement>) => {
			setPackageName(evt.target.value)

			validatePackageName(evt.target.value)
		},
		[upperExistingPackageNames],
	)

	const validatePackageName = (name: string) => {
		if (
			upperExistingPackageNames
				.filter((v) => v !== props.existingPackage?.name.toUpperCase())
				.includes(name.toUpperCase().trim())
		)
			setErrorMessage('Package name must be unique')
		else if (name === '') setErrorMessage('Package name is required')
		else if (!/^[a-zA-Z0-9 _]+$/.test(name))
			setErrorMessage('The package name cannot have any special characters')
		else setErrorMessage('')
	}

	return (
		<>
			<DialogTitle>Upload Form Package</DialogTitle>
			<DialogContent>
				<Box display="flex" flexDirection="column">
					<Dropzone
						onDrop={(files) => handleDrop(files)}
						accept={'.ffp'}
						maxFiles={1}
					>
						{({
							acceptedFiles,
							getRootProps,
							getInputProps,
						}: DropzoneState) => (
							<Box
								component="section"
								flex={1}
								className={clsx(
									styles.verticalSpacing,
									styles.enabledDragAndDrop,
								)}
							>
								<Box height={percent(100)} {...getRootProps()}>
									<input {...getInputProps()} />
									<div className={styles.dragAndDropBody}>
										<Box
											display="flex"
											flexDirection="column"
											justifyContent="center"
											alignItems="center"
											height={percent(100)}
										>
											<Box
												overflow="auto"
												width={percent(100)}
												textAlign="center"
											>
												<Box
													flex="1"
													overflow="hidden"
													display="flex"
													flexDirection="column"
													justifyContent="center"
													alignItems="center"
												>
													<Upload />
													{acceptedFiles.length == 0 ? (
														<Typography>Form Package File</Typography>
													) : (
														acceptedFiles.map((v) => (
															<Typography key={v.name}>{v.name}</Typography>
														))
													)}
												</Box>
											</Box>
										</Box>
									</div>
								</Box>
							</Box>
						)}
					</Dropzone>
					<Box
						component={FixedHeaderWithHint}
						marginY={2}
						label="Form Package Name"
						hint={errorMessage}
						hintColor="error.main"
					>
						<TextField
							autoFocus
							value={packageName}
							onChange={handleNameChanged}
							fullWidth
							autoComplete="off"
							error={errorMessage !== ''}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} color="primary">
					Cancel
				</Button>
				<Button
					onClick={() => handleUpload()}
					color="primary"
					variant="contained"
					disabled={
						errorMessage !== '' || file === undefined || packageName === ''
					}
				>
					Upload
				</Button>
			</DialogActions>
			{isPosting && <LinearProgress />}
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	verticalSpacing: {
		height: '50%',
		flex: 3,
	},
	enabledDragAndDrop: {
		cursor: 'pointer',
	},

	disabledDragAndDrop: {
		opacity: percent(60),
	},

	dragAndDropBody: {
		height: percent(100),
		width: percent(100),
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',

		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: theme.shape.borderRadius,
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: theme.palette.text.secondary,

		overflowWrap: 'break-word',

		padding: theme.spacing(2),

		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(5),
		},
	},
}))
