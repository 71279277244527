import { LockReset } from '@mui/icons-material'
import { TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import AnonymousUserClient from '../../api/clients/identity/AnonymousUserClient'
import { toastService } from '../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../utils/HOC/FixedHeaders'
import { ErrorPage } from '../Error/ErrorPage'
import { AuthenticationWrapper } from './AuthenticationWrapper'

// I did not write the password validation regex (Caroline)
// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

export const ResetPasswordPage = () => {
	const styles = useStyles()
	const history = useHistory()

	const search = location.search
	const params = new URLSearchParams(search)
	const email = params.get('email')
	const resetToken = params.get('resetToken')

	const validationSchema = yup.object({
		newPassword: yup
			.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
				'Password should have at least one uppercase letter, \r\none lowercase letter, one number, and one special character',
			),
		confirmPassword: yup
			.string()
			.test('passwords-match', 'Passwords must match', function (value) {
				return this.parent.newPassword === value
			}),
	})

	const formik = useFormik({
		initialValues: {
			newPassword: '',
			confirmPassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if (email === null) throw new Error('could not find email in route')

			if (resetToken === null)
				throw new Error('could not find reset token in route')

			if (values.newPassword === undefined)
				throw new Error('new password is undefined')

			const userClient = new AnonymousUserClient()

			try {
				await userClient.ResetPassword(
					{
						password: values.newPassword,
						resetToken: resetToken,
					},
					email,
				)

				toastService.displayToast({
					message: 'Successfully reset password',
					area: 'global',
				})

				history.push('/_login')
			} catch (error) {
				console.log(error)

				toastService.displayToast({
					message: 'Error resetting password',
					area: 'global',
				})
			}
		},
	})

	if (email === null || resetToken === null) {
		console.log(
			`email or reset token is null - email: ${email}, reset token: ${resetToken}`,
		)
		return (
			<ErrorPage
				errorMessage="The reset password link is invalid."
				buttonText="Back to Login"
				route="/_login"
			/>
		)
	}

	return (
		<div className={styles.wholePageLayout}>
			<AuthenticationWrapper
				onNextClicked={formik.submitForm}
				nextButtonText="Reset Password"
				avatar={<LockReset />}
				headerText="Reset Password"
				isLoading={formik.isSubmitting}
				nextDisabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
			>
				<FixedHeaderWithHint
					label="New Password"
					hint={formik.touched.newPassword && formik.errors.newPassword}
					hintColor="error.main"
					className={styles.textField}
				>
					<TextField
						fullWidth
						type="password"
						name="newPassword"
						id="newPassword"
						value={formik.values.newPassword}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.newPassword && !!formik.errors.newPassword}
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint
					label="Confirm Password"
					hint={formik.touched.confirmPassword && formik.errors.confirmPassword}
					hintColor="error.main"
					className={styles.textField}
				>
					<TextField
						fullWidth
						type="password"
						name="confirmPassword"
						id="confirmPassword"
						value={formik.values.confirmPassword}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={
							formik.touched.confirmPassword && !!formik.errors.confirmPassword
						}
						onKeyPress={(keyEventArgs) => {
							if (
								keyEventArgs.key === 'Enter' &&
								formik.isValid &&
								!formik.isSubmitting
							)
								formik.submitForm()
						}}
					/>
				</FixedHeaderWithHint>
			</AuthenticationWrapper>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	wholePageLayout: {
		display: 'flex',

		height: percent(100),
		width: percent(100),

		justifyContent: 'center',
		alignItems: 'center',
	},

	textField: {
		margin: theme.spacing(1, 0),
	},

	spacing: {
		margin: theme.spacing(2, 0),
	},
}))
