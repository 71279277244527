// https://github.com/strateos/react-map-interaction/blob/6a5b0f155dca9676f881e90617e817fd686f9fa4/src/makePassiveEventOption.js

// We want to make event listeners non-passive, and to do so have to check
// that browsers support EventListenerOptions in the first place.
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
let passiveSupported = false
try {
	const options: AddEventListenerOptions = {
		get passive() {
			passiveSupported = true
			return passiveSupported
		}
	}
	window.addEventListener("test", randomFunction, options)
	window.removeEventListener("test", randomFunction, options)
	
} catch {
	passiveSupported = false
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function randomFunction(event: Event): void {
	// no-op
}

function makePassiveEventOption(passive: boolean): { passive: boolean} | boolean {
	return passiveSupported ? { passive } : passive
}

export default makePassiveEventOption