

import { Syntax } from "esprima"
import { Directive, MemberExpression, ModuleDeclaration, Node, Program, Statement } from "estree"
import React from "react"
import FieldEventComponent from "./codegen/components/events/FieldEventComponent"
import FormEventComponent from "./codegen/components/events/FormEventComponent"
import BlockStatementComponent from "./codegen/components/internal/BlockStatementComponent"
import ExpressionStatementComponent from "./codegen/components/internal/ExpressionStatementComponent"
import IdentifierComponent from "./codegen/components/internal/IdentifierComponent"
import ProgramComponent from "./codegen/components/internal/ProgramComponent"
import BinaryExpressionComponent from "./codegen/components/logic/BinaryExpressionComponent"
import IfStatementComponent from "./codegen/components/logic/IfStatementComponent"
import LogicalExpressionComponent from "./codegen/components/logic/LogicalExpressionComponent"
import AssignmentExpressionComponent from "./codegen/components/variables/AssignmentExpressionComponent"
import FormFieldComponent from "./codegen/components/variables/FormFieldComponent"
import LiteralComponent from "./codegen/components/variables/LiteralComponent"
import MemberExpressionComponent from "./codegen/components/variables/MemberExpressionComponent"
import OrganizationPropertyComponent from "./codegen/components/variables/OrganizationPropertyComponent"
import QueryStringComponent from "./codegen/components/variables/QueryStringComponent"
import StringLengthComponent from "./codegen/components/variables/StringLengthComponent"
import UserInfoComponent from "./codegen/components/variables/UserInfoComponent"
import UserPropertyComponent from "./codegen/components/variables/UserPropertyComponent"

export type DynamicProgram = JSSyntaxTreeProgram | PureJsProgram

export type JSSyntaxTreeProgram = {
	type: 'jsSyntaxTree'
	syntaxTree: Program
	text: string
}

export type PureJsProgram = {
	type: 'pureJs'
	text: string
}
type FormsMetadata = {
	formsInfo?: FormsInfo
	position?: GridPosition
}

export type WithFormsInfo<T> = { [key in keyof T]: key extends string | number | symbol ? T[key] : WithFormsInfo<T[key]> } & FormsMetadata

export type PartiallyConstructedNode<T extends Node> = Partial<Omit<WithFormsInfo<T>, 'type'>> & { type: T['type'] }

// if you're up for an adventure, change to T extends Node
// export type PartiallyConstructedSyntaxTree<T> = Partial<{ [key in keyof T]: PartiallyConstructedSyntaxTree<T[key]> & { formsInfo?: FormsInfo } }> 

export type FormsInfo = FieldAccess | FormFieldAccess | UserPropertyAccess | StringLength | FormEvent | PackageEvent | FieldEvent | FormAccess | OrganizationPropertyAccess | UserInfoAccess | QueryStringAccess

export type FormsInfoType = FormsInfo['type']

export type FormsBody = Array<WithFormsInfo<Directive | Statement | ModuleDeclaration>>

export type FormsProgram = {
	body: FormsBody
}

export type GridPosition = {
	x: number,
	y: number
}

// the whole deal of the form & its field we're accessing
export type FormFieldAccess = {
	type: 'FormFieldAccess'
	formId: number
	definitionId: string
}

export type FieldAccess = {
	type: 'FieldAccess'
	accessor: string // could be the definition id, 'value', or specific property 
}

export type FormAccess = {
	type: 'FormAccess'
	formId: number
}

export type UserPropertyAccess = {
	type: 'UserPropertyAccess'
	userPropertyId: number
}

export type OrganizationPropertyAccess = {
	type: 'OrganizationPropertyAccess'
	organizationPropertyId: number
}

export type UserInfoAccess = {
	type: 'UserInfoAccess'
	property: string
}

export type QueryStringAccess = {
	type: 'QueryStringAccess'
}

export type StringLength = {
	type: 'String-Length'
}

export type PackageEvent = {
	type: 'PackageEvent'
	packageId: number
	// todo: events whenever we decide on them
}

export enum FormEventType {
	OnLoad = "Load",
	OnBeforeSubmit = "Submit"
}

export type FormEvent = {
	type: 'FormEvent'
	formId: number
	event: FormEventType
}

export enum FieldEventType {
	OnChange = "Change",
	OnBlur = "Blur"
}

export type FieldEvent = {
	type: 'FieldEvent'
	formId: number
	definitionId: string
	event: FieldEventType
}

type DiscriminatedUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never

type MapDiscriminatedUnion<T extends Record<K, string>, K extends keyof T> = { [V in T[K]]: DiscriminatedUnion<T, K, V> }

export type RendererComponent<T extends Node> = { node: PartiallyConstructedNode<T> }

type ASTNodeTransformer<T extends Record<string, Node>> = { [key in keyof T]: React.ComponentType<RendererComponent<T[key]>> }

export type ASTNodeComponentsType = ASTNodeTransformer<MapDiscriminatedUnion<Node, 'type'>>

export const MemberExpressions: Partial<Record<FormsInfo['type'], React.ComponentType<RendererComponent<MemberExpression>>>> = {
	'UserPropertyAccess': UserPropertyComponent,
	'OrganizationPropertyAccess': OrganizationPropertyComponent,
	'QueryStringAccess': QueryStringComponent,
	'String-Length': StringLengthComponent,
	'UserInfoAccess': UserInfoComponent,
	'FormFieldAccess': FormFieldComponent
}

export const Programs: Partial<Record<FormsInfo['type'], React.ComponentType<RendererComponent<Program>>>> = {
	'FieldEvent': FieldEventComponent,
	'FormEvent': FormEventComponent
}

export const ASTNodeComponents: Partial<ASTNodeComponentsType> = {
	'AssignmentExpression': AssignmentExpressionComponent,
	'BinaryExpression': BinaryExpressionComponent,
	'BlockStatement': BlockStatementComponent,
	'ExpressionStatement': ExpressionStatementComponent,
	'Identifier': IdentifierComponent,
	'IfStatement': IfStatementComponent,
	'Literal': LiteralComponent,
	'LogicalExpression': LogicalExpressionComponent,
	'MemberExpression': MemberExpressionComponent,
	'Program': ProgramComponent,	
}

// we unfortunately need this for the TrashDustbin, since React DND doesn't have an "accept all" option
export const allNodes = [...Object.keys(MemberExpressions), ...Object.keys(Programs), ...Object.keys(ASTNodeComponents)]

export type ValidNodeTypes = keyof typeof Syntax | FormsInfo['type']