import Create from '@mui/icons-material/Create'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import Publish from '@mui/icons-material/Publish'
import {
	Alert,
	AlertTitle,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	BaseFormPackageInfoModel,
	FormPackageInfo,
} from '../../../../../api/DTOtemp'
import { FormPackageClient } from '../../../../../api/clients/identity/FormPackageClient'
import { AssignmentType } from '../../../../../api/clients/workItems/DTOs'
import UserChip from '../../../../../components/chips/UserChip'
import { useModals } from '../../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../../services/notifications/PromptService'
import { toastService } from '../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import { updateBreadcrumbs } from '../../Breadcrumbs'
import { PackageCreateDialog } from './FormPackageCreateDialog'
import { UploadFormPackageDialog } from './UploadFormPackageDialog'

export const FormPackagesPage = () => {
	const styles = useStyles()

	const history = useHistory()

	const [createPackageOpen, setCreatePackageOpen] = useState(false)
	const [packageList, setPackageList] = useState<
		BaseFormPackageInfoModel[] | undefined
	>(undefined)

	updateBreadcrumbs('/_administration/_form-packages', 'Form Packages')

	const packageApi = new FormPackageClient()
	const modalService = useModals()

	useEffect(() => {
		const packagePromise = packageApi.GetFormPackages()
		packagePromise
			.then((v) => {
				setPackageList(v.data)
			})
			.catch((v) => {
				console.error(v)
			})
	}, [])

	const handleCreateClicked = () => {
		setCreatePackageOpen(true)
	}

	const handleCreatePackageClosed = () => {
		setCreatePackageOpen(false)
	}

	const handleCreatePackage = (name: string, description: string) => {
		packageApi
			.CreateFormPackage({ name, description })
			.then((response) => {
				toastService.displayToast({
					message: 'Form package created',
					area: 'global',
				})
				return response.data.id
			})
			.then((packageId) => {
				history.push(`/_administration/_form-packages/${packageId}`)
				handleCreatePackageClosed()
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error creating form package',
					area: 'global',
				})
			})
	}

	const handlePackageDeleted = (packageId: number) => {
		const packageDeletePromise = packageApi.DeleteFormPackage(packageId)

		const originalPackageList = packageList
		const newPackageList = (packageList ?? []).filter((v) => v.id !== packageId)

		console.log('new package list: ', newPackageList)

		setPackageList(newPackageList)

		packageDeletePromise
			.then((v) => {
				toastService.displayToast({
					message: 'Form package deleted',
					area: 'global',
				})
			})
			.catch((v) => {
				console.error(v)
				setPackageList(originalPackageList)
				toastService.displayToast({
					message: 'Error deleting form package',
					area: 'global',
				})
			})
	}

	const handleRouteToPackage = (packageId: number) => {
		history.push(`/_administration/_form-packages/${packageId}`)
	}

	return (
		<AdministrationPageContainer
			title="Form Packages"
			actions={
				<div>
					<Button
						className={styles.firstButton}
						variant="outlined"
						color="primary"
						startIcon={<Publish />}
						onClick={() =>
							modalService
								.showForm((props) => (
									<UploadFormPackageDialog
										existingPackageNames={packageList?.map((v) => v.name) ?? []}
										onCancel={() => props.close({ closeResult: 'cancel' })}
										onConfirm={(uploadedPackage) =>
											props.close({
												closeResult: 'okay',
												value: uploadedPackage,
											})
										}
									/>
								))
								.then((response) => {
									if (response.closeResult === 'okay')
										handleRouteToPackage((response.value as FormPackageInfo).id)
								})
						}
					>
						Upload Package
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={handleCreateClicked}
						startIcon={<Create />}
					>
						Create Package
					</Button>
				</div>
			}
		>
			{packageList !== undefined && (
				<FormPackageTable
					packages={packageList}
					onEditClicked={handleRouteToPackage}
					onDeletePackage={handlePackageDeleted}
				/>
			)}
			<PackageCreateDialog
				open={createPackageOpen}
				onClose={handleCreatePackageClosed}
				existingPackageNames={packageList?.map((v) => v.name) ?? []}
				onCreatePackage={handleCreatePackage}
			/>
		</AdministrationPageContainer>
	)
}

type FormPackageTableProps = {
	packages: BaseFormPackageInfoModel[]
	onEditClicked: (packageId: number) => void
	onDeletePackage: (packageId: number) => void
}

const FormPackageTable = (props: FormPackageTableProps) => {
	const styles = useStyles()
	const promptService = usePrompts()

	return (
		<>
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Package Name</TableCell>
								<TableCell>Package Description</TableCell>
								<TableCell>Last Edited</TableCell>
								<TableCell>Edited By</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.packages.map((v) => (
								<TableRow key={v.id}>
									<TableCell>
										<Typography variant="body2">{v.name}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body2">{v.description}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body2">
											{new Date(v.lastModifiedDate).toLocaleString()}
										</Typography>
									</TableCell>
									<TableCell>
										<UserChip
											assignmentType={AssignmentType.User}
											id={v.lastModifiedBy}
										/>
									</TableCell>
									<TableCell align="right">
										<IconButton onClick={() => props.onEditClicked(v.id)}>
											<Edit fontSize="small" />
										</IconButton>
										<IconButton
											onClick={() => {
												promptService
													.showDialog((props) => (
														<ConfirmDeleteFormPackagePrompt
															packageName={v.name}
															onCancel={() => props.close('cancel')}
															onConfirm={() => props.close('okay')}
														/>
													))
													.then((response) => {
														if (response === 'okay') props.onDeletePackage(v.id)
													})
											}}
										>
											<Delete fontSize="small" />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		</>
	)
}

type ConfirmDeleteFormPackagePromptProps = {
	packageName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeleteFormPackagePrompt = (
	props: ConfirmDeleteFormPackagePromptProps,
) => {
	const styles = useStyles()

	return (
		<>
			<DialogTitle>Confirm Delete Form Package</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete <b>{props.packageName}</b>?
				</Typography>
				<Alert variant="outlined" severity="warning" className={styles.alert}>
					<AlertTitle>Warning</AlertTitle>
					This action will delete all forwarded and saved versions of this
					package.
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		height: 'fit-content',
		overflow: 'auto',
	},

	firstButton: {
		marginRight: theme.spacing(2),
	},

	alert: {
		margin: theme.spacing(2, 0),
	},
}))
