import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ComponentProps, useEffect } from 'react'
import { executeDataProvider } from '../../../../utils/FormBuilderDataProviderUtils'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	ColumnType,
	DataProviderPropertyConfigurator,
	OptionsType,
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	StaticDataProviderPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'
import { DataProviderInfo } from './SelectInput'

type RadioControl = {
	label: string
	options: OptionsType[]
	dataProviderType: 'static' | 'dataProvider'
	dataProviderInfo?: DataProviderInfo
}

export const RadioInput = observer((props: RadioControl) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()

	const localStore = useLocalObservable(() => ({
		isExecuting: false,
		dataProviderResults: [] as OptionsType[],

		get options(): OptionsType[] {
			return props.dataProviderType === 'static'
				? props.options
				: this.dataProviderResults
		},
	}))

	useEffect(() => {
		if (
			props.dataProviderInfo == null ||
			props.dataProviderInfo.dataProviderId === 0
		)
			return

		runInAction(() => {
			localStore.isExecuting = true
		})

		executeDataProvider(props.dataProviderInfo)
			.then(action((results) => (localStore.dataProviderResults = results)))
			.finally(
				action(() => {
					localStore.isExecuting = false
				}),
			)
	}, [])

	return (
		<FormControl>
			<Typography variant="subtitle1" color="textPrimary">
				{props.label}
			</Typography>
			{localStore.isExecuting && <CircularProgress />}
			<RadioGroup
				sx={{ paddingLeft: 1 }}
				value={context.value}
				onChange={action((v) => {
					const oldValue = context.value
					const newValue = v.currentTarget.value
					context.onChange(newValue)
					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: oldValue,
							newValue: context.value,
						},
						{
							formHost: cellManager,
							elementTag: RadioInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				})}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: RadioInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
			>
				{localStore.options.map((v) => (
					<FormControlLabel
						key={v.key}
						value={v.key}
						control={<Radio />}
						label={v.displayValue}
					/>
				))}
			</RadioGroup>
		</FormControl>
	)
})

export const RadioInputElementTag = '503c757a-b587-43d5-92b2-8f0ded89649b'

export const RadioInputId = '8b0afbb6-6e10-43d4-8e5b-ceda36e288f1'

const RadioInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof RadioInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => {
					onPropertiesChanged({ ...properties, label: v })
				}}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.dataProviderType}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, dataProviderType: v })
				}
				Editor={(props) => {
					const options = [
						{ key: 'static', displayValue: 'Static' },
						{ key: 'dataProvider', displayValue: 'Data Provider' },
					]
					return <SelectPropertyConfigurator options={options} {...props} />
				}}
				label="Data Provider Type"
			/>
			{properties.dataProviderType === 'static' && (
				<PropertySchematicConfigurator
					value={properties.options}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, options: v })
					}
					Editor={(props) => (
						<StaticDataProviderPropertyConfigurator
							values={{
								key: {
									columnName: 'Key',
									columnType: ColumnType.String,
								},
								displayValue: {
									columnName: 'Display Value',
									columnType: ColumnType.String,
								},
							}}
							{...props}
						/>
					)}
					label="Options"
				/>
			)}
			{properties.dataProviderType === 'dataProvider' && (
				<PropertySchematicConfigurator
					value={properties.dataProviderInfo}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, dataProviderInfo: v })
					}
					Editor={DataProviderPropertyConfigurator}
					label="Options"
				/>
			)}
		</>
	)
}

const RadioInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof RadioInput>>,
) => {
	const options = props.properties.options

	// we can't have a default value if it's a data provider
	// bc values may be different when form is loaded
	if (props.properties.dataProviderType === 'dataProvider') return <></>

	return (
		<PropertySchematicConfigurator
			value={props.value}
			onValueChanged={props.onValueChanged}
			Editor={(props) => (
				<SelectPropertyConfigurator
					options={options}
					{...props}
					value={props.value as string | undefined}
				/>
			)}
			label="Default Value"
		/>
	)
}

export const RadioInputDefinition = specifyValueTypeComponent(
	RadioInputId,
	RadioInput,
	RadioInputElementTag,
	'Radio',
	FieldType.String,
	{
		configurator: RadioInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			options: [],
			dataProviderType: 'static',
			dataProviderInfo: undefined,
		},
	},
	{
		configurator: RadioInputValueEditor,
		defaultValue: {
			type: 'static',
			value: undefined,
		},
	},
)
