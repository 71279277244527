import { ArrowForward } from '@mui/icons-material'
import {
	Divider,
	Fade,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import { useRouteMatch } from 'react-router'
import IconButtonLink from '../../../../../../../../components/links/IconButtonLink'
import { FormEvents } from '../../../../../../../../utils/constants/FormEvents'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'

export const EventsAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps) => {
		const styles = useStyles()

		return (
			<AdministrationPageContainer title="Form Package Events">
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={2}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<EventsSection />
				</div>
			</AdministrationPageContainer>
		)
	},
)

const EventsSection = () => {
	const styles = useStyles()
	const { url } = useRouteMatch()

	return (
		<Fade in unmountOnExit>
			<TableContainer className={styles.tableContainer} component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.keys(FormEvents).map((eventId) => {
							const event = FormEvents[eventId]

							return (
								<TableRow key={eventId}>
									<TableCell>{event.name}</TableCell>
									<TableCell>{event.description}</TableCell>
									<TableCell align="right">
										<IconButtonLink
											to={makeChildRoute(`_events/${eventId}`, url)}
										>
											<ArrowForward />
										</IconButtonLink>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'auto',
	},
}))
