import {
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ComponentProps, useEffect } from 'react'
import { executeDataProvider } from '../../../../utils/FormBuilderDataProviderUtils'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import FieldType from '../../../FormHost/Types/FieldType'
import {
	ColumnType,
	DataProviderPropertyConfigurator,
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import StaticDataProviderPropertyConfigurator, {
	OptionsType,
} from '../../Configuration/StaticDataProviderPropertyConfigurator'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

export type DataProviderInfo = {
	dataProviderId: number
	parameters: Record<string, string>
	key: string
	displayValue: string
}

type SelectControlProps = {
	label: string
	emptyValue?: string
	options: OptionsType[]
	dataProviderType: 'static' | 'dataProvider'
	dataProviderInfo?: DataProviderInfo
}

export const SelectInput = observer((props: SelectControlProps) => {
	const { cellManager } = useFormBuilderContext()
	const context = useInputCellContext()
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		isExecuting: false,
		dataProviderResults: [] as OptionsType[],

		get options(): OptionsType[] {
			return props.dataProviderType === 'static'
				? props.options
				: this.dataProviderResults
		},
	}))

	useEffect(() => {
		if (
			props.dataProviderInfo == null ||
			props.dataProviderInfo.dataProviderId === 0
		)
			return

		runInAction(() => {
			localStore.isExecuting = true
		})

		executeDataProvider(props.dataProviderInfo)
			.then(
				action((results) => {
					localStore.dataProviderResults = results
					localStore.isExecuting = false
				}),
			)
			.finally(
				action(() => {
					if (localStore.isExecuting) localStore.isExecuting = false
				}),
			)
	}, [])

	return (
		<FormControl className={styles.root}>
			<Typography variant="subtitle1" color="textPrimary">
				{props.label}
			</Typography>
			<Select
				value={context.value ?? ''}
				endAdornment={
					localStore.isExecuting && (
						<CircularProgress
							color="primary"
							size={20}
							className={styles.spinner}
						/>
					)
				}
				onChange={(v) => {
					const oldValue = context.value
					const newValue = v.target.value
					context.onChange(newValue)
					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: oldValue,
							newValue: newValue,
						},
						{
							formHost: cellManager,
							elementTag: SelectInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: SelectInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
				displayEmpty={!!props.emptyValue}
				renderValue={(v) => (
					<>
						{props.dataProviderType === 'static'
							? props.options.find((option) => option.key === v)
									?.displayValue || props.emptyValue
							: localStore.dataProviderResults.find(
									(result) => result.key === v,
							  )?.displayValue || props.emptyValue}
					</>
				)}
			>
				{localStore.isExecuting ? (
					<MenuItem disabled>Loading...</MenuItem>
				) : (
					localStore.options.map((v) => (
						<MenuItem key={v.key} value={v.key}>
							{' '}
							{v.displayValue}{' '}
						</MenuItem>
					))
				)}
			</Select>
		</FormControl>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 'auto',
	},
	spinner: {
		margin: theme.spacing(0, 3),
	},
}))

export const SelectInputElementTag = '3edc3103-31d8-4833-93a7-78377e91c1ab'

const SelectInputId = 'd3e728dc-0ad1-45a4-b151-bb392c6a8851'

const SelectInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof SelectInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.emptyValue}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, emptyValue: v })
				}
				Editor={TextPropertyConfigurator}
				label="Empty Value"
			/>
			<PropertySchematicConfigurator
				value={properties.dataProviderType}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, dataProviderType: v })
				}
				Editor={(props) => {
					const options = [
						{ key: 'static', displayValue: 'Static' },
						{ key: 'dataProvider', displayValue: 'Data Provider' },
					]
					return <SelectPropertyConfigurator options={options} {...props} />
				}}
				label="Data Provider Type"
			/>
			{properties.dataProviderType === 'static' && (
				<PropertySchematicConfigurator
					value={properties.options}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, options: v })
					}
					Editor={(props) => (
						<StaticDataProviderPropertyConfigurator
							values={{
								key: {
									columnName: 'Key',
									columnType: ColumnType.String,
								},
								displayValue: {
									columnName: 'Display Value',
									columnType: ColumnType.String,
								},
							}}
							{...props}
						/>
					)}
					label="Options"
				/>
			)}
			{properties.dataProviderType === 'dataProvider' && (
				<PropertySchematicConfigurator
					value={properties.dataProviderInfo}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, dataProviderInfo: v })
					}
					Editor={DataProviderPropertyConfigurator}
					label="Options"
				/>
			)}
		</>
	)
}

const SelectInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof SelectInput>>,
) => {
	const options = props.properties.options

	// we can't have a default value if it's a data provider
	// bc values may be different when form is loaded
	if (props.properties.dataProviderType === 'dataProvider') return <></>

	return (
		<PropertySchematicConfigurator
			value={props.value}
			onValueChanged={props.onValueChanged}
			Editor={(props) => (
				<SelectPropertyConfigurator
					options={options}
					{...props}
					value={props.value as string | undefined}
				/>
			)}
			label="Default Value"
		/>
	)
}

export const SelectInputDefinition = specifyValueTypeComponent(
	SelectInputId,
	SelectInput,
	SelectInputElementTag,
	'Select',
	FieldType.String,
	{
		configurator: SelectInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			emptyValue: '',
			options: [],
			dataProviderType: 'static',
			dataProviderInfo: undefined,
		},
	},
	{
		configurator: SelectInputValueEditor,
		defaultValue: {
			type: 'static',
			value: undefined,
		},
	},
)
