
import { AxiosResponse } from "axios";
import { UserPropertyAssociation, UserPropertySimpleAssociation } from '../../DTOtemp';
import SecureClient from '../SecureClient';

export class UserPropertyAssociationsClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `UserPropertyAssociations`
	}
	
	/**
	 * Get a set of associations with optional parameters restricting the size of the set
	 * @param userId 
	 * @param propertyId 
	 */
	public GetUserPropAssociations( includeNonEditable?: boolean, userId?: number, propertyId?: number)
		: Promise<AxiosResponse<UserPropertyAssociation[]>> {
		
		return this._api.get<UserPropertyAssociation[]>(
			`${this._urlBase}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				},
				params: {
					userId,
					propertyId,
					includeNonEditable,
				}
			}
		)
	}

	/**
	 * Create or update a new user property with the provided model
	 * @param userProp 
	 */
	public AddUserPropAssociation(userProp: UserPropertySimpleAssociation)
		: Promise<AxiosResponse<UserPropertyAssociation>> {
		return this._api.post(
			`${this._urlBase}`,
			JSON.stringify(userProp),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Get an association with the specified id
	 * @param associationId 
	 */
	public GetUserPropAssociation(associationId: number)
		: Promise<AxiosResponse<UserPropertyAssociation>> {
		return this._api.get<UserPropertyAssociation>(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Update the association at the specified route with a new value
	 * @param associationId 
	 * @param value 
	 */
	public UpdateUserPropAssociation(associationId: number, value: string)
		: Promise<AxiosResponse<UserPropertyAssociation>> {
		return this._api.put(
			`${this._urlBase}/${associationId}`,
			JSON.stringify(value),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

	/**
	 * Delete the association at the specified route
	 * @param associationId 
	 */
	public DeleteUserPropAssociation(associationId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

	/**
	 * Add a large set of associations to the database
	 * @param userProps 
	 */
	public BulkAddGroupUserAssociation(userProps: UserPropertySimpleAssociation[])
		: Promise<AxiosResponse> {
		return this._api.post(
			`${this._urlBase}/BatchAddition`,
			JSON.stringify(userProps),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}

}