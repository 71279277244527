import { NIL } from 'uuid'
import { decodeHexString, hexEncodeString } from '../../../utils/HexEncoding'
import { INonVirtualCellInstance } from '../Types/CellInstance'
import { CellType } from '../Types/CellType'
import {
	IUnifiedValueCellInstance,
	UnifiedCellInstance
} from '../Types/UnifiedCellInstance'
import { IUnifiedFormHost } from './IUnifiedFormHost'

/*
		^  proxy each of the form hosts, search for form with this particular id
							^ proxy the form host, and search for all of those that are parented by the root
								element, the one that matches this encoded id is the one we want, otherwise undefined
								return the proxy if we have an encoded id that matches
										^ search the proxy that was returned before, if one exists, to try to find a
											child of the previous layout that matches that encoded id, follow the same rules
											as above
													 ^ search the proxy that was returned before, try to find a child that
														 matches the name "value", if none exists, look through named 
														 properties, and try to return one of those, if that doesn't exist then
														 return undefined
														 
Form Level
	type FormHostProxy = Record<string, ProxyCellInstance | undefined>
1. search for the form with the given id
2 - 
	if in either case we find something, create a proxy around the form host, which is specifically
	targeted to lookup cell instances by their encoded id and (if in the case of a repeat) index
	e.g.
Proxy Level
	type ProxyCellInstance = Record<string, ProxyCellInstance | unknown | undefined>
1a. - if we have a #
	we are treating the cell instance as a repeat, try to find a cell instance that has the given 
	ordinal position
	todo - we are currently not handling repeats, this will need to be done in the future
1b. - if we have a string
	we are treating the cell instance as a layout or the last element in a tree, so
	i   - if it's a layout, search the children, try to find something with the given encoded id, if found
		return a proxy to that cell instance. If not found, look in properties and return something
		if a property matches that key (e.g. 'visible')
	ii  - if it's the last element in the tree, still search to see if there's any children (just 
		because it's easier that way) nothing will be found, so defer to properties, include things
		like 'value' if they exist


Note: we are adding an underscore in front of all form ids/encoded cell definition ids since they begin with a number
and property access like 1.2.value does not work! Also, we are encoding the cell definition ids in hex since they may
contain characters that will not work in property access.
*/


export type ProxyFormHost = Record<string, ProxyCellInstance>

type ProxyCellInstance = {
	[key: string]: ProxyCellInstance | unknown | undefined
	properties: Record<string, unknown>
	value: unknown | undefined
}

function isHexEncoded(test: string) {
	return /^_[0-9a-f]+/i.test(test)
}

export function proxyFormHosts(
	hosts: IUnifiedFormHost[],
): Record<string, ProxyFormHost> {
	return Object.fromEntries(
		hosts.map((i) => [`_${i.formId}`, createProxyFormHost(i)]),
	)
}

export function createProxyFormHost(
	formHost: IUnifiedFormHost,
): Record<string, ProxyCellInstance> {
	const instances = formHost.cellInstances.filter((v) => v.parentId === NIL)

	const nonVirtualInstances = instances.filter(
		(v) => v.type !== CellType.Virtual,
	) as INonVirtualCellInstance[]

	return Object.fromEntries(
		nonVirtualInstances.map((v) => [
			`_${hexEncodeString(v.definitionId)}`,
			createProxyFormCellInstance(formHost, v as UnifiedCellInstance),
		]),
	)
}

function createProxyFormCellInstance(
	formHost: IUnifiedFormHost,
	cellInstance: UnifiedCellInstance,
): ProxyCellInstance {
	const proxyHandler: ProxyHandler<ProxyCellInstance> = {
		get(target, property) {

			console.log('getting:', target, property)

			if (property === undefined) return undefined

			if (isHexEncoded(property.toString()))
				property = decodeHexString(property.toString().substring(1)) // substring to remove underscore

			const descendants = formHost.cellInstances.filter(
				(v) => v.parentId === cellInstance.id && v.type !== CellType.Virtual,
			) as INonVirtualCellInstance[]

			// if property is a cell definition id, get the value! not the whole cell instance
			const candidateValue = descendants.find(
				(v) => v.definitionId === property,
			)

			if (candidateValue !== undefined) return candidateValue

			console.log(Reflect.get(target, property))

			return Reflect.get(target, property)
		},
		set(target, property, newValue, receiver) {

			console.log('setting:', target, property, newValue)

			if (property === undefined) return false

			if (isHexEncoded(property.toString()))
				property = decodeHexString(property.toString().substring(1)) // substring to remove underscore

			const descendants = formHost.cellInstances.filter(
				(v) => v.parentId === cellInstance.id && v.type !== CellType.Virtual,
			) as INonVirtualCellInstance[]
			const candidateValue = descendants.find(
				(v) => v.definitionId === property,
			)

			// we can't change a cell instance, but  if the user doesn't specifically say what part
			// of an instance to assign, we are assuming that they want to update the value
			if (candidateValue !== undefined) {
				if (candidateValue.type !== CellType.Value) return false

				;(candidateValue as IUnifiedValueCellInstance).value = newValue
			}

			return Reflect.set(target, property, newValue, receiver)
		},
	}

	return new Proxy(cellInstance as ProxyCellInstance, proxyHandler)
}

