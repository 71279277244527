import { observable, runInAction } from 'mobx'
import { DetailForm, FormIndex, FormPackageInfo, FormType } from '../../api/DTOtemp'
import {
	FormPackageClient,
	FormPackageInstancesClient,
	FormPackageVersionClient,
	FormsClient
} from '../../api/clients/identity'
import {
	ForwardedFormPackageWorkItemState,
	WorkItemResponse,
	WriteWorkItemModel
} from '../../api/clients/workItems/DTOs'
import { UnifiedCellDefinition } from '../../modules/FormHost/Types/UnifiedCellDefinition'
import { UnifiedCellInstance } from '../../modules/FormHost/Types/UnifiedCellInstance'
import {
	PersistenceStatus,
	ResourceType
} from '../../pages/PackageHostPage/Attachments/AttachFilesDialog'
import {
	OfflineContextType,
	OfflineStatus,
	PackageMetaInfo
} from '../../pages/PackageHostPage/PackageListPageContext'
import { toastService } from '../notifications/ToastService'
import { SessionService } from '../session'
import {
	IndexDbRetrievalFormPackage,
	InstanceCacheFormPackage,
	IntendedUsage,
	RetrievalStorageType
} from './FormPackageStorage'
import { setWorkItem as setCacheWorkItem } from './WorkItemHandling'
import {
	retrieveGlobalDatabase,
	retrieveUserDatabase,
	withGlobalDatabaseCaching
} from './indexedDb'

type getFormPackageCallbacks = {
	onOfflinePackagesDownloaded: (
		offlinePackages: FormPackageInfo[],
		downloadedCount: number,
	) => void
}

export const getFormPackages = async (
	offlineContext: OfflineContextType,
	abortSignal: AbortSignal,
) => {
	try {
		const sessionService = new SessionService
		const formPackageClient = new FormPackageClient()

		console.log('getting form packages')

		let result: FormPackageInfo[] =
			(await withGlobalDatabaseCaching('form-package-collection', async () => {
				const { data } = sessionService.isLoggedIn
					? await formPackageClient.GetFormPackages()
					: await formPackageClient.GetAnonymousFormPackages()
				return data
			})) ?? []

		if (result.length === 0)
			console.warn('package api check indicated user has access to no packages')

		const offlinePackages = result.filter(
			(v) => v.configuration.settings.offlineEnabled,
		)

		// set up tracking so we know what the status of our offline stuff is
		for (const offlinePackage of offlinePackages) {
			const version = await getFormPackageVersion(offlinePackage.versionId)

			const existingOfflineIndex = offlineContext.packageList.get(
				offlinePackage.versionId.toString(),
			)

			if (existingOfflineIndex === undefined) {
				offlineContext.packageList.set(
					offlinePackage.versionId.toString(),
					observable({
						id: offlinePackage.versionId.toString(),
						offlineStatus:
							version !== undefined
								? OfflineStatus.Downloaded
								: OfflineStatus.NotDownloaded,
						rowVersion: version?.rowVersion,
					}),
				)
			} else {
				existingOfflineIndex.offlineStatus =
					version?.rowVersion !== existingOfflineIndex.rowVersion
						? OfflineStatus.NotDownloaded
						: OfflineStatus.Downloaded
				existingOfflineIndex.rowVersion = version?.rowVersion
			}
		}

		if (navigator.onLine) {
			console.log('found ' + offlinePackages.length + ' offline packages')

			const promise = downloadOfflinePackages(
				offlineContext,
				offlinePackages,
				abortSignal,
			)
			promise.then((downloadCount) => {
				const message = 'downloaded ' + downloadCount + ' offline form packages'
				console.log(message)
				if (downloadCount !== 0)
					toastService.displayToast({
						area: 'global',
						message,
						delay: 3,
					})
			})
		}

		if (!navigator.onLine) result = offlinePackages

		console.log('got form packages: ', result)

		return result
	} catch (error) {
		console.error(error)
		return []
	}
}

// we have to assign an id
// have to set status to 'committing'
// have to store commit intent

// at retrieval we don't have commit intent
// id might be null with save, temporary?
// index can be synthesized after download

export type RetrievalPackage = {
	instanceId: number
	packageId: number
	uniqueId: string // use in the IndexDB!

	description?: string
	name: string

	concurrencyToken?: string

	storageType: RetrievalStorageType

	workItem?: WorkItemResponse<ForwardedFormPackageWorkItemState>
	// working on it
}

// TODO - saved or received?
export const getRetrievalFormPackages = async (
	storageType: RetrievalStorageType,
	packageMap: Map<string, PackageMetaInfo>,
	abortSignal: AbortSignal,
): Promise<RetrievalPackage[]> => {

	const sessionService = new SessionService()
	if (!sessionService.isLoggedIn) {
		console.log('User is not logged in')
		return []
	}

	try {
		const database = await retrieveUserDatabase()
		const transaction = database.transaction('storedPackageCache', 'readwrite')

		const cachedPackages = (await transaction.objectStore('storedPackageCache').getAll() as IndexDbRetrievalFormPackage[])
			.filter(v => v.storageType == storageType)

		if (navigator.onLine) {
			const formPackageClient = new FormPackageClient()

			const packageList: {
				id: number
				uniqueId: string
				packageId: number
				versionId: number
				concurrencyToken?: string
				name: string
				description?: string
				workItem?: WorkItemResponse<ForwardedFormPackageWorkItemState>
			}[] = []

			if (storageType === RetrievalStorageType.Saved) {
				const response = await formPackageClient.GetSavedFormPackages()
				const data = response.data

				for (const pkg of data) packageList.push(pkg)
			} else {
				const response = await formPackageClient.GetAssignedFormPackagesWithChangeTokens()
				const responseData = response.data

				for (const responseItem of responseData) {

					const workItem = responseItem.workItem

					setCacheWorkItem(workItem)

					packageList.push({
						id: workItem.workItemState.packageInstanceId,
						uniqueId: workItem.workItemState.uniqueInstanceId,
						name: workItem.workItemState.packageName,
						description: workItem.workItemState.description,
						packageId: workItem.workItemState.packageId,
						versionId: workItem.workItemState.packageVersionId,
						concurrencyToken: responseItem.rowVersion,
						workItem: workItem,
					})
				}
			}

			const versionMap = await (async () => {
				const downloadList = new Map<number, FormPackageInfo>();

				const promiseCollection: Map<number, Promise<{ id: number, info: FormPackageInfo }>> = new Map()
				for (const pkg of packageList) {
					if (promiseCollection.has(pkg.versionId))
						continue;

					const formPackageVersionClient = new FormPackageVersionClient(pkg.packageId);
					const promise = formPackageVersionClient.GetVersionById(pkg.versionId).then(v => ({ id: pkg.versionId, info: v.data }))

					promiseCollection.set(pkg.versionId, promise)
				}

				await Promise.all(promiseCollection.values()).then(v => {
					for (const element of v)
						downloadList.set(element.id, element.info)
				})

				return downloadList
			})()

			for (const key of packageMap.keys()) {
				if (packageList.find(v => v.uniqueId === key) === undefined)
					packageMap.delete(key)
			}

			for (const pkg of packageList) {
				const cachePackage = cachedPackages.find((v) => v.uniqueId === pkg.uniqueId)

				const packageVersionResponse = versionMap.get(pkg.versionId)
				if (packageVersionResponse === undefined)
					throw new Error('package version was not downloaded to map')

				// could this path get any further??
				if (packageVersionResponse.configuration.settings.offlineEnabled) {
					let mapIndex = packageMap.get(pkg.uniqueId)

					if (mapIndex === undefined) {
						mapIndex = observable({
							id: pkg.id.toString(),
							offlineStatus:
								cachePackage !== undefined &&
									cachePackage.concurrencyToken !== undefined &&
									cachePackage.concurrencyToken === pkg.concurrencyToken
									? OfflineStatus.Downloaded
									: OfflineStatus.NotDownloaded,
							rowVersion: pkg.concurrencyToken,
						})
						packageMap.set(pkg.uniqueId, mapIndex)
					} else {
						if (mapIndex.rowVersion !== pkg.concurrencyToken) {
							runInAction(() => {
								mapIndex!.offlineStatus = OfflineStatus.NotDownloaded
								mapIndex!.rowVersion = pkg.concurrencyToken
							})
						}
					}

					if (mapIndex.offlineStatus !== OfflineStatus.Downloaded) {
						downloadFormPackageInstance(
							storageType,
							pkg.uniqueId,
							mapIndex,
							abortSignal,
							packageVersionResponse,
							pkg.workItem,
						)
					}
				}
			}

			return packageList.map((v) => ({
				instanceId: v.id,
				uniqueId: v.uniqueId,
				name: v.name,
				packageId: v.packageId,
				storageType: storageType,
				description: v.description,
				concurrencyToken: v.concurrencyToken,
				workItem: v.workItem,
			}))
		} else {
			return cachedPackages
		}
	} catch (error) {
		console.error(error)
		return []
	}
}

export const getFormPackageInstance = async (
	instanceId: string,
): Promise<InstanceCacheFormPackage | undefined> => {
	const database = await retrieveUserDatabase()
	const transaction = database.transaction('storedPackageCache', 'readonly')
	const storedPackageCacheStore = transaction.objectStore('storedPackageCache')

	const result = await storedPackageCacheStore.get(instanceId)

	if (result === undefined) return undefined

	return result
}

export function downloadFormPackageInstance(
	storageType: RetrievalStorageType,
	instanceId: string,
	trackingIndex: PackageMetaInfo | undefined,
	abortSignal: AbortSignal,
	packageVersion: FormPackageInfo | undefined,
	workItem:
		| WorkItemResponse<ForwardedFormPackageWorkItemState>
		| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		| undefined,
): Promise<InstanceCacheFormPackage | undefined>
export function downloadFormPackageInstance(
	storageType: RetrievalStorageType.Saved,
	instanceId: string,
	trackingIndex: PackageMetaInfo | undefined,
	abortSignal: AbortSignal,
	packageVersion: FormPackageInfo | undefined,
): Promise<InstanceCacheFormPackage | undefined>
export function downloadFormPackageInstance(
	storageType: RetrievalStorageType.Received,
	instanceId: string,
	trackingIndex: PackageMetaInfo | undefined,
	abortSignal: AbortSignal,
	packageVersion: FormPackageInfo | undefined,
	workItem:
		| WorkItemResponse<ForwardedFormPackageWorkItemState>
		| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		| undefined,
): Promise<InstanceCacheFormPackage | undefined>
export async function downloadFormPackageInstance(
	storageType: RetrievalStorageType,
	instanceId: string,
	trackingIndex: PackageMetaInfo | undefined,
	abortSignal: AbortSignal,
	packageVersion: FormPackageInfo | undefined = undefined,
	workItem:
		| WorkItemResponse<ForwardedFormPackageWorkItemState>
		| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		| undefined = undefined,
): Promise<InstanceCacheFormPackage | undefined> {
	if (storageType === RetrievalStorageType.Received && workItem === undefined)
		throw new Error(
			'invariant violated - storage type set to received but work item not provided',
		)

	try {
		const database = await retrieveUserDatabase()

		const instanceClient = new FormPackageInstancesClient()

		const instanceResponse = await instanceClient.GetFormPackageInstance(
			instanceId,
		)
		if (instanceResponse.status !== 200)
			throw new Error('failed to retrieve instance')

		const instance = instanceResponse.data

		if (packageVersion === undefined) {
			const packageVersionClient = new FormPackageVersionClient(
				instance.packageId,
			)
			console.log(instance)
			const versionResponse = await packageVersionClient.GetVersionById(
				instance.versionId,
			)

			packageVersion = versionResponse.data
		}

		const packageInstance: InstanceCacheFormPackage = {
			instanceId: instance.id,
			versionId: instance.versionId,
			packageId: instance.packageId,
			description: instance.description,

			packageVersion: packageVersion,
			configuration: packageVersion.configuration,
			name: packageVersion.name,
			versionNumber: packageVersion.versionNumber,

			previousInstanceId: instance.previousInstanceId,
			concurrencyToken: instance.concurrencyToken,

			workItem: workItem,

			resources: [],
			forms: [],

			isPackageFromServer: true
		}

		const resourcePromises = new Array<Promise<void>>()
		console.log(instance.index.supportingDocumentIndices)
		for (const resource of instance.index.supportingDocumentIndices) {
			const resourcePromise = instanceClient
				.retrieveResource(instance.id, resource.resourceId)
				.then((v) => {
					packageInstance.resources.push({
						file: new File([v.data], resource.name, {
							type: resource.contentType,
						}),
						persistenceStatus: PersistenceStatus.Persisted,
						resourceId: resource.resourceId,
						type: resource.type,
						relationalId: resource.relationalId,
						packageAttachmentType: resource.packageAttachmentType
					})
				})
			resourcePromises.push(resourcePromise)
		}

		await Promise.all(resourcePromises)

		const formsClient = new FormsClient(
			packageInstance.packageId,
			packageInstance.versionNumber,
		)
		const { data: relatedForms } = await formsClient.GetAllForms()

		console.log('related forms', JSON.stringify(relatedForms, null, 2))

		const formPromises = new Array<Promise<void>>()
		for (const form of instance.index.formIndices) {
			const promise = (async () => {
				const detailForm = relatedForms.find((v) => v.id === form.formId)

				if (detailForm === undefined)
					throw new Error(
						'Form package did not contain form with id ' + form.formId,
					)

				// if we have an HTML or PDF form we get the form stream from the past instance
				const formStream = form.type === FormType.FormBuilder ?
					(await formsClient.GetFormStream(form.formId, abortSignal)).data
					: getResourceFormStream(packageInstance, form)

				if (formStream === undefined)
					throw new Error(
						'unable to retrieve stream for form ' +
						form.formId +
						' and type ' +
						form.type,
					)

				packageInstance.forms.push({
					activeVersion: detailForm.activeVersion,
					cellDefinitions: form.definitions as UnifiedCellDefinition[],
					cellInstances: form.instances as UnifiedCellInstance[],
					contentDisposition: detailForm.contentDisposition,
					contentType: detailForm.contentType,
					createdBy: detailForm.createdBy,
					createdDate: detailForm.createdDate,
					versionId: detailForm.versionId,
					formConfiguration: detailForm.formConfiguration,
					id: detailForm.id,
					uniqueId: detailForm.uniqueId,
					lastModifiedBy: detailForm.lastModifiedBy,
					lastModifiedDate: detailForm.lastModifiedDate,
					metadata: detailForm.metadata,
					name: detailForm.name,
					ordinalPosition: detailForm.ordinalPosition,
					rowVersion: detailForm.rowVersion,
					signedFields: form.signedFields,
					stream: formStream,
					type: form.type,
				})
			})()

			formPromises.push(promise)
		}

		await Promise.all(formPromises)

		const transaction = database.transaction('storedPackageCache', 'readwrite')
		const storedPackageCache = transaction.objectStore('storedPackageCache')

		if (!packageInstance.instanceId) {
			throw new Error('package was loaded with intent retrieval that did not have an instance id for use in key')
		}

		if (packageInstance.instanceId === undefined)
			throw new Error('retrieved package was missing instance id')

		const newItem: IndexDbRetrievalFormPackage = {
			...packageInstance,

			isPackageFromServer: true,

			instanceId: packageInstance.instanceId!, // <- watch out for "!"
			key: packageInstance.uniqueInstanceId!, // <- watch out for "!"
			uniqueId: packageInstance.uniqueInstanceId!, // ^ ditto
			intendedUsage: IntendedUsage.Retrieval,
			commitDate: new Date(),
			isTemporaryInstanceId: false,
			storageType: storageType,
		}

		storedPackageCache.put(newItem, packageInstance.instanceId.toString())

		if (trackingIndex)
			runInAction(
				() => (trackingIndex.offlineStatus = OfflineStatus.Downloaded),
			)

		return packageInstance
	} catch (error) {
		console.error(error)

		// this was probably an abort
		if (abortSignal.aborted) throw new Error()

		if (trackingIndex)
			runInAction(
				() => (trackingIndex.offlineStatus = OfflineStatus.DownloadFailed),
			)
	}
}

const downloadOfflinePackages = async (
	offlineContext: OfflineContextType,
	packageList: FormPackageInfo[],
	abortSignal: AbortSignal,
): Promise<number> => {
	let downloadedVersions = 0

	try {
		for (const offlinePackage of packageList) {
			const formPackageVersion = await getFormPackageVersion(
				offlinePackage.versionId,
			)
			if (formPackageVersion === undefined) {
				const metadataTracker = offlineContext.packageList.get(
					offlinePackage.versionId.toString(),
				)
				if (metadataTracker === undefined)
					throw new Error(
						'package was not present in metadata, ' + offlinePackage.versionId,
					)

				runInAction(
					() => (metadataTracker.offlineStatus = OfflineStatus.Downloading),
				)

				try {
					await downloadFormPackage(offlinePackage, abortSignal)
					console.log(`⬇ form package ${offlinePackage.versionId} downloaded`)
					downloadedVersions++

					runInAction(
						() => (metadataTracker.offlineStatus = OfflineStatus.Downloaded),
					)
				} catch (error) {
					console.error('failed to download, error: ', error)
					if (abortSignal.aborted)
						runInAction(
							() =>
								(metadataTracker.offlineStatus = OfflineStatus.DownloadFailed),
						)
				}
			}
		}

		return downloadedVersions
	} catch (error) {
		console.log(error)
		return downloadedVersions
	}
}

const generateFormBlobId = (id: number) => {
	return `form-stream-${id}`
}

export type DetailFormWithData = DetailForm & { stream: Blob }

export type StoredFormPackageDefinition = {
	packageInfo: FormPackageInfo
	forms: DetailFormWithData[]
}

export const getFormPackageVersion = async (formPackageVersionId: number) => {
	const database = await retrieveGlobalDatabase()

	const transaction = database.transaction(['formPackageVersions'], 'readonly')

	const formPackageVersions = transaction.objectStore('formPackageVersions')

	const version = await formPackageVersions.get(formPackageVersionId)
	if (version === undefined) return undefined
	return version
}

export const getFullFormPackageVersion = async (
	formPackageVersionId: number,
): Promise<StoredFormPackageDefinition | undefined> => {
	const database = await retrieveGlobalDatabase()

	const transaction = database.transaction(
		[
			'formPackageVersions',
			'formPackageFormsAssociations',
			'formPackageForms',
			'blobStorage',
		],
		'readonly',
	)

	const formPackageVersions = transaction.objectStore('formPackageVersions')
	const formPackageFormAssociations = transaction.objectStore(
		'formPackageFormsAssociations',
	)
	const formPackageForms = transaction.objectStore('formPackageForms')
	const blobStorage = transaction.objectStore('blobStorage')

	const version = await formPackageVersions.get(formPackageVersionId)
	if (version === undefined) {
		console.warn('could not find form version')
		return undefined
	}

	const returnFormPackage: StoredFormPackageDefinition = {
		packageInfo: version,
		forms: [],
	}

	const formAssociationCollection = await formPackageFormAssociations
		.index('by-package-version-id')
		.getAll(formPackageVersionId)

	console.log('associated forms: ' + formAssociationCollection.length)

	for (const formAssociation of formAssociationCollection) {
		const detailForm = await formPackageForms.get(formAssociation.formId)
		const formBlob = await blobStorage.get(
			generateFormBlobId(formAssociation.formId),
		)

		if (detailForm === undefined)
			throw new Error('could not get for index from local storage')

		if (formBlob === undefined)
			throw new Error('could not get blob for form index')

		console.log('pushing form stream')
		returnFormPackage.forms.push({ ...detailForm, stream: formBlob.data })
	}

	return returnFormPackage
}

export function downloadFormPackage(
	formPackage: FormPackageInfo,
	cancellationToken: AbortSignal,
): Promise<StoredFormPackageDefinition>
export function downloadFormPackage(
	formPackage: number,
	cancellationToken: AbortSignal,
): Promise<StoredFormPackageDefinition>
export async function downloadFormPackage(
	formPackage: number | FormPackageInfo,
	cancellationToken: AbortSignal,
): Promise<StoredFormPackageDefinition> {
	const database = await retrieveGlobalDatabase()

	const sessionService = new SessionService()

	if (typeof formPackage === 'number') {
		const formPackageClient = new FormPackageClient()
		const { data: formPackageData } = await formPackageClient.GetFormPackage(
			formPackage,
		)
		formPackage = formPackageData
	}

	const storedFormPackage: StoredFormPackageDefinition = {
		packageInfo: formPackage,
		forms: [],
	}

	const formsClient = new FormsClient(formPackage.id, formPackage.versionNumber)
	const { data: relatedForms } = sessionService.isLoggedIn
		? await formsClient.GetAllForms()
		: await formsClient.GetAllFormsAnonymous()

	for (const form of relatedForms) {
		const { data: formStream } = sessionService.isLoggedIn ? await formsClient.GetFormStream(
			form.id,
			cancellationToken,
		) : await formsClient.GetFormStreamAnonymous(form.id, cancellationToken)

		storedFormPackage.forms.push({ ...form, stream: formStream })
	}

	const transaction = database.transaction(
		[
			'formPackageVersions',
			'formPackageFormsAssociations',
			'formPackageForms',
			'blobStorage',
		],
		'readwrite',
	)

	transaction.oncomplete = (ev) => {
		console.log('transaction complete')
	}

	cancellationToken.onabort = (ev) => {
		console.log('transaction aborted!')
		transaction.abort()
	}

	const formPackageVersions = transaction.objectStore('formPackageVersions')
	const formPackageFormAssociations = transaction.objectStore(
		'formPackageFormsAssociations',
	)
	const formPackageForms = transaction.objectStore('formPackageForms')
	const blobStorage = transaction.objectStore('blobStorage')

	await formPackageVersions.put(formPackage, formPackage.versionId)
	console.log('❌ form package put')

	for (const { stream, ...formData } of storedFormPackage.forms) {
		formPackageFormAssociations.put(
			{ formId: formData.id, packageVersionId: formPackage.versionId },
			`${formPackage.versionId}-${formData.id}`,
		)
		formPackageForms.put(formData, formData.id)
		blobStorage.put(
			{ relationalKey: formData.id.toString(), data: stream },
			generateFormBlobId(formData.id),
		)

		console.log('❌ added form')
	}

	console.log('committing form package')

	transaction.commit()
	await transaction.done

	console.log('form package downloaded')

	return storedFormPackage
}

const getResourceFormStream = (packageInstance: InstanceCacheFormPackage, form: FormIndex): Blob | undefined => {
	const resourceType = form.type === FormType.Html ? ResourceType.Form : ResourceType.FormImage

	return packageInstance.resources.find(
		(v) =>
			v.relationalId === form.formId.toString() &&
			v.type === resourceType,
	)?.file
}