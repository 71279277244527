import { AxiosRequestHeaders, AxiosResponse } from "axios";
import { CurrentOrganizationUserAssociation, CurrentOrganizationUserModel } from "../../DTOtemp";
import SecureClient from "../SecureClient";

export class CurrentOrganizationUsersClient extends SecureClient {

	// TODO replace all return types here + in backend to just users/access status

	/**
	 * get all the users or one specific user/org association for the current organization
	 * @param userId if specified, limits results to relationship between current organization and this user
	 * @returns
	 */
	public getUsersForCurrentOrganization(
		userId?: number,
		headers: AxiosRequestHeaders | undefined = undefined
	): Promise<AxiosResponse<CurrentOrganizationUserAssociation[]>> {
		return this._api.get('CurrentOrganizationUsers', {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader(),
				...headers
			},
			params: {
				userId,
			},
		})
	}

	public getUser(
		userId: number,
		headers: AxiosRequestHeaders | undefined = undefined
	): Promise<AxiosResponse<CurrentOrganizationUserAssociation> | undefined> {
		return this._api.get(`CurrentOrganizationUsers/${userId}`, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader(),
				...headers
			}
		}).catch(() => undefined)
	}

	public createAssociationForCurrentOrganization(
		model: CurrentOrganizationUserModel,
	): Promise<AxiosResponse<CurrentOrganizationUserModel>> {
		return this._api.post('CurrentOrganizationUsers', model, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader(),
			},
		})
	}

	public updateAssociationForCurrentOrganization(
		model: CurrentOrganizationUserModel,
	): Promise<AxiosResponse<CurrentOrganizationUserModel>> {
		return this._api.put('CurrentOrganizationUsers', model, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader(),
			},
		})
	}

	public deleteAssociationForCurrentOrganization(
		userId: number,
	): Promise<AxiosResponse> {
		return this._api.delete(`CurrentOrganizationUsers/${userId}`, {
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader(),
			},
		})
	}
}