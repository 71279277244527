import { action, observable } from "mobx"

type PromiseStatus = "Pending" | "Rejected" | "Fulfilled"

type ObservablePromiseInfo<TValue> = {
	status: PromiseStatus
	value: TValue | undefined
}

export function watchPromise<TPromiseType>(originalPromise: Promise<TPromiseType>): ObservablePromiseInfo<TPromiseType> {

	const statusObservable = observable.object<ObservablePromiseInfo<TPromiseType>>({
		status: "Pending",
		value: undefined
	})

	originalPromise.then(
		action(v => {
			statusObservable.status = "Fulfilled"
			statusObservable.value = v
			return v
		}),
		action(e => {
			statusObservable.status = "Rejected"
			statusObservable.value = e
			throw e
		})
	)

	return statusObservable
}
