import { Typography } from '@mui/material'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { BaseFormPackageInstanceModel } from '../../api/DTOtemp'
import { FormPackageClient } from '../../api/clients/identity'
import { FormPackageInstancesClient } from '../../api/clients/identity/FormPackageInstancesClient'
import { FullscreenSpinner } from '../../components/feedback/circular'
import {
	DetailFormWithData,
	StoredFormPackageDefinition,
	downloadFormPackage,
	getFullFormPackageVersion,
} from '../../services/offline/FormPackageHandling'
import { TrackedResource } from './Attachments/AttachFilesDialog'
import { PackageApi, PackageApiStatus } from './PackageApi'
import { PackageHost } from './PackageHost'
import { PackageHostErrorPage } from './PackageHostErrorPage'
import { PackageHostPageContextProvider } from './PackageHostPageContext'

const FormPackageHostPage = observer(() => {
	const params = useParams<{ packageId: string }>()
	const packageId = parseInt(params.packageId)

	if (isNaN(packageId)) throw new Error('invalid package id')

	const localStore = useLocalObservable(() => ({
		storedPackage: undefined as StoredFormPackageDefinition | undefined,
		forbidden: false as boolean,
	}))

	const resources: TrackedResource[] = useMemo(() => [], [])

	const packageApi = useMemo(() => {
		return new PackageApi(packageId)
	}, [packageId])

	useEffect(() => {
		const abortController = new AbortController()

		async function fetchFormsData() {
			const formPackageClient = new FormPackageClient()

			const signal = abortController.signal

			try {
				/*
				Because this API call is cached, we don't have to worry about checking
				whether or not we're online
				*/
				const { data: formPackageData } =
					await formPackageClient.GetFormPackage(packageId, abortController)

				let fullFormPackageVersion = await getFullFormPackageVersion(
					formPackageData.versionId,
				)

				if (fullFormPackageVersion === undefined && navigator.onLine) {
					fullFormPackageVersion = await downloadFormPackage(
						formPackageData,
						abortController.signal,
					)
				} else if (fullFormPackageVersion === undefined && !navigator.onLine) {
					console.error('form package is not downloaded in cache')
					runInAction(() => {
						localStore.forbidden = true
					})
					return
				}

				runInAction(() => {
					console.log('signal status: ', signal.aborted)
					if (signal.aborted) return
					localStore.storedPackage = fullFormPackageVersion
				})
			} catch (e) {
				if (signal.aborted) return

				runInAction(() => {
					localStore.forbidden = true
				})
			}
		}

		fetchFormsData()

		return () => {
			abortController.abort()
		}
	}, [packageId])

	if (localStore.forbidden)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<>
						<Typography variant="h6">
							You don't have access to this package
						</Typography>{' '}
						<Typography variant="h6">or this package doesn't exist.</Typography>
					</>
				}
			/>
		)

	if (
		localStore.storedPackage === undefined ||
		packageApi.status !== PackageApiStatus.Complete
	)
		return <FullscreenSpinner />

	if (localStore.storedPackage.forms.length === 0)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<Typography variant="h6">
						There are no forms to fill out in this package.
					</Typography>
				}
			/>
		)

	const storedPackage = localStore.storedPackage

	return (
		<PackageHostPageContextProvider validationErrors={observable({})}>
			<PackageHost
				packageId={packageId}
				packageApi={packageApi}
				formPackage={storedPackage.packageInfo}
				resources={resources}
				forms={storedPackage.forms}
				anonymous={false}
				isPackageFromServer={false}
				onSaveInstance={handleSubmitPackage}
			/>
		</PackageHostPageContextProvider>
	)
})

export default FormPackageHostPage

export const handleSubmitPackage = async (
	baseFormPackageInstance: BaseFormPackageInstanceModel,
	forms: DetailFormWithData[],
	isPackageFromServer: boolean,
	existingInstanceId?: number,
): Promise<number> => {
	const instancesClient = new FormPackageInstancesClient()

	// if an instance exists, overwrite it, else create a new one
	const { data: newInstance } =
		existingInstanceId === undefined ||
		existingInstanceId === 0 ||
		!isPackageFromServer
			? await instancesClient.CreateFormPackageInstance(
					baseFormPackageInstance,
					forms,
			  )
			: await instancesClient.UpdateFormPackageInstance(
					existingInstanceId,
					baseFormPackageInstance,
			  )

	return newInstance.id
}
