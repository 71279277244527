import { Button, Popover, TextField, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useState } from 'react'

type EditValuePopoverProps = {
	anchorEl: HTMLElement | null
	originalValue: string
	onPopoverClose: () => void
	onSaveValue: (value: string) => void
}

// TODO: figure out where this component belongs whenever we merge into the new file structure 🙂
export const EditValuePopover = observer((props: EditValuePopoverProps) => {
	const [newValue, setNewValue] = useState(props.originalValue)
	const styles = useStyles()

	return (
		<Popover
			open={props.anchorEl !== null}
			anchorEl={props.anchorEl}
			onClose={props.onPopoverClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			className={styles.popover}
		>
			<div className={styles.popoverRoot}>
				<Typography className={styles.popoverContent}>Edit Value</Typography>
				<TextField
					defaultValue={props.originalValue}
					onChange={(e) => setNewValue(e.currentTarget.value)}
					className={styles.popoverContent}
					size="small"
					autoFocus
				/>
				<div className={clsx(styles.popoverActions, styles.popoverContent)}>
					<Button color="primary" onClick={props.onPopoverClose} size="small">
						Cancel
					</Button>
					<Button
						color="primary"
						variant="contained"
						size="small"
						onClick={() => {
							props.onSaveValue(newValue)
							props.onPopoverClose()
						}}
					>
						Save
					</Button>
				</div>
			</div>
		</Popover>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	popover: {
		display: 'flex',
		flexDirection: 'column',
	},

	popoverRoot: {
		margin: theme.spacing(2),
	},

	popoverContent: {
		margin: theme.spacing(1),
	},

	popoverActions: {
		textAlign: 'right',
	},
}))
