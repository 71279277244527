import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { calc, percent, px } from 'csx'
import { observer } from 'mobx-react'
import { Redirect, Route, useRouteMatch } from 'react-router-dom'
import { makeChildRoute } from '../../../utils/CreateChildRoute'
import { AuthenticationProvidersRouter } from './AuthenticationProviders/AuthenticationProvidersRouter'
import { resetBreadcrumbs } from './Breadcrumbs'
import { DataProvidersRouter } from './DataProviders/DataProvidersRouter'
import FormPackagesPage from './Forms/FormPackagesPage'
import { GroupsPageRouter } from './Groups/GroupsPageRouter'
import LicensingPage from './Licensing/LicensingPage'
import { OrganizationPropertiesPageRouter } from './OrganizationProperties/OrganizationPropertiesPageRouter'
import PortalsPageRouter from './Portals/PortalsPageRouter'
import { UserPropertiesPageRouter } from './UserProperties/UserPropertiesPageRouter'
import UsersPageRouter from './Users/UsersPage/UsersPageRouter'
import { VaultStorageRouter } from './VaultStorage/VaultStorageRouter'
import { WorkItemsPage } from './WorkItems/WorkItemsPage'
import { WorkflowsRouter } from './Workflows/WorkflowsRouter'

const AdministrationRouter = observer(() => {
	const { path, url } = useRouteMatch()

	resetBreadcrumbs(`/_administration`, 'Administration')

	return (
		<>
			<Route path={makeChildRoute('_form-packages', path)}>
				<FormPackagesPage />
			</Route>
			<Route path={makeChildRoute('_portals', path)}>
				<PortalsPageRouter />
			</Route>
			<Route path={makeChildRoute('_users', path)}>
				<UsersPageRouter />
			</Route>
			<Route path={makeChildRoute('_groups', path)}>
				<GroupsPageRouter />
			</Route>
			<Route path={makeChildRoute('_user-properties', path)}>
				<UserPropertiesPageRouter />
			</Route>
			<Route path={makeChildRoute('_organization-properties', path)}>
				<OrganizationPropertiesPageRouter />
			</Route>
			<Route path={makeChildRoute('_workflows', path)}>
				<WorkflowsRouter />
			</Route>
			<Route path={makeChildRoute('_work-items', path)}>
				<WorkItemsPage />
			</Route>
			<Route path={makeChildRoute('_data-providers', path)}>
				<DataProvidersRouter />
			</Route>
			<Route path={makeChildRoute('_authentication-providers', path)}>
				<AuthenticationProvidersRouter />
			</Route>
			<Route path={makeChildRoute('_licensing', path)}>
				<LicensingPage />
			</Route>
			<Route path={makeChildRoute('_vault-storage', path)}>
				<VaultStorageRouter />
			</Route>

			{/* redirect to form packages page since we don't have dedicated Administration page*/}
			<Route exact path={makeChildRoute('', path)}>
				<Redirect to={makeChildRoute('_form-packages', url)} />
			</Route>
		</>
	)
})

const drawerWidth = 256
const drawerWidthClosed = 60
const transitionDuration = 300

const useStyles = makeStyles((theme: Theme) => ({
	height100: {
		height: '100%',
		overflow: 'hidden',
	},

	adminRoot: {
		position: 'relative',

		display: 'flex',

		height: percent(100),
		width: percent(100),

		padding: theme.spacing(0, 3),

		overflow: 'hidden',
	},

	adminRootWithSidebar: {
		left: px(drawerWidth),
		height: percent(100),
		width: calc(`${percent(100)} - ${px(drawerWidth)}`),
		transition: theme.transitions.create(['width', 'left'], {
			duration: transitionDuration,
		}),
	},

	adminRootWithoutSidebar: {
		left: px(drawerWidthClosed),
		height: percent(100),
		width: calc(`${percent(100)} - ${px(drawerWidthClosed)}`),
		transition: theme.transitions.create(['width', 'left'], {
			duration: transitionDuration,
		}),
	},

	adminRootVertical: {
		display: 'flex',
		flexDirection: 'column',

		alignItems: 'center',

		width: percent(100),
		height: percent(100),
	},

	drawerOpen: {
		width: drawerWidth,
		height: percent(100),
		padding: theme.spacing(0, 1.5),

		overflow: 'hidden',

		'&:hover': {
			overflowY: 'auto',
		},

		'&::-webkit-scrollbar': {
			width: theme.spacing(0.5),
		},
		'&::-webkit-scrollbar-thumb': {
			background:
				theme.palette.mode === 'dark'
					? theme.palette.grey[700]
					: theme.palette.primary.light,
			borderRadius: theme.spacing(4),
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.grey[500],
		},
		transition: theme.transitions.create('width', {
			duration: transitionDuration,
		}),
	},

	drawerClose: {
		width: drawerWidthClosed,
		height: percent(100),
		padding: theme.spacing(0, 1.5),

		overflow: 'hidden',

		'&:hover': {
			overflowY: 'auto',
		},

		'&::-webkit-scrollbar': {
			width: theme.spacing(0.5),
		},
		'&::-webkit-scrollbar-thumb': {
			background:
				theme.palette.mode === 'dark'
					? theme.palette.grey[700]
					: theme.palette.primary.light,
			borderRadius: theme.spacing(4),
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: theme.palette.grey[500],
		},
		transition: theme.transitions.create('width', {
			duration: transitionDuration,
		}),
	},

	toolbarSpacer: {
		...theme.mixins.toolbar,
	},

	// move to another component
	listItemText: {
		fontWeight: 'bold',
		margin: theme.spacing(1, 0),
	},

	collapseButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
}))

export default AdministrationRouter
