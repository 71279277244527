import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import * as yup from 'yup'
import { FormPackageClient } from '../../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import {
	FormPackageEventAction,
	FormPackageInfo,
} from '../../../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../../../components/feedback/circular'
import { Scrollbar } from '../../../../../../../../components/scrollbars/Scrollbar'
import { getGlobalEventActionRegistry } from '../../../../../../../../modules/FormHost/EventActions/EventActionRegistry'
import { IEventActionProps } from '../../../../../../../../modules/FormHost/EventActions/IEventAction'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'

type ConfigureActionPageProps = {
	packageId: number
}

export const ConfigureActionPage = observer(
	(props: ConfigureActionPageProps) => {
		const styles = useStyles()

		const { actionId } = useParams<{ actionId: string }>()

		const formPackageVersionClient = new FormPackageVersionClient(
			props.packageId,
		)

		const localStore = useLocalObservable(() => ({
			formPackage: undefined as FormPackageInfo | undefined,
		}))

		useEffect(() => {
			const api = new FormPackageClient()

			api.GetFormPackage(props.packageId).then(
				action((response) => {
					localStore.formPackage = response.data
				}),
			)
		}, [props.packageId])

		const eventAction = useMemo(() => {
			if (localStore.formPackage === undefined) return undefined

			return localStore.formPackage.configuration.eventActions.find(
				(v) => v.id === actionId,
			)
		}, [actionId, localStore.formPackage])

		const actionDefinition = useMemo(() => {
			if (eventAction === undefined) return undefined

			const registry = getGlobalEventActionRegistry()
			return registry.actionCollection[eventAction.definitionId]
		}, [eventAction])

		const handleUpdateAction = (name: string, description: string) => {
			if (localStore.formPackage === undefined)
				throw new Error('form package is undefined')

			if (eventAction === undefined) throw new Error('action is undefined')

			eventAction.name = name
			eventAction.description = description

			formPackageVersionClient
				.UpdateConfiguration(localStore.formPackage)
				.then(() => {
					toastService.displayToast({
						message: 'Action updated',
						area: 'global',
					})
				})
				.catch(() => {
					toastService.displayToast({
						message: 'Error updating action',
						area: 'global',
					})
				})
		}

		if (
			localStore.formPackage === undefined ||
			eventAction === undefined ||
			actionDefinition === undefined
		)
			return <FullscreenSpinner />

		const handleValueChanged = (newValue: unknown) => {
			if (localStore.formPackage === undefined)
				throw new Error('form package is undefined')

			eventAction.value = newValue

			formPackageVersionClient.UpdateConfiguration(localStore.formPackage)
		}

		const Configurator = actionDefinition.configurator
		const configuratorProps: IEventActionProps = {
			value: eventAction.value,
			onValueChanged: action((newValue: unknown) =>
				handleValueChanged(newValue),
			),
		}

		return (
			<AdministrationPageContainer title={eventAction.name}>
				<div className={styles.contentRoot}>
					<Scrollbar contentClassName={styles.scrollContent}>
						<ActionSettingsSection
							action={eventAction}
							onUpdateAction={handleUpdateAction}
						/>
						<Configurator {...configuratorProps} />
					</Scrollbar>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type ActionSettingsSectionProps = {
	action: FormPackageEventAction
	onUpdateAction: (name: string, description: string) => void
}

const ActionSettingsSection = (props: ActionSettingsSectionProps) => {
	const styles = useStyles()

	const validationSchema = yup.object({
		name: yup.string().required(),
		description: yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			name: props.action.name,
			description: props.action.description,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			props.onUpdateAction(values.name, values.description)
		},
	})

	return (
		<Fade in unmountOnExit>
			<Paper className={styles.insetSpacing}>
				<Typography
					className={styles.topBottomContent}
					variant="h6"
					color="textPrimary"
				>
					Settings
				</Typography>
				<Divider />
				<div className={styles.formSettingsRoot}>
					<div className={styles.formElement}>
						<TextField
							id="name"
							name="name"
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							helperText={formik.touched.name && formik.errors.name}
							error={!!formik.errors.name && formik.touched.name}
							label="Name"
							fullWidth
							autoComplete="off"
						/>
					</div>
					<div className={styles.formElement}>
						<TextField
							id="description"
							name="description"
							value={formik.values.description}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							helperText={
								formik.touched.description && formik.errors.description
							}
							error={!!formik.errors.description && formik.touched.description}
							label="Description"
							fullWidth
							autoComplete="off"
						/>
					</div>
				</div>
				<div>
					<Divider />
					<div className={clsx(styles.topBottomContent, styles.bottomFlexbox)}>
						<Button
							color="primary"
							variant="contained"
							className={styles.noTextTransform}
							disabled={!formik.isValid}
							onClick={formik.submitForm}
						>
							Save Changes
						</Button>
					</div>
				</div>
			</Paper>
		</Fade>
	)
}
const useStyles = makeStyles((theme: Theme) => ({
	contentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	scrollContent: {
		padding: theme.spacing(0, 2, 0, 0),
	},

	insetSpacing: {
		marginTop: theme.spacing(3),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},
}))
