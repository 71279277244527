import { INullHtmlCellDefinition } from '../../FormIntegrations/htmlFormHost/Types/HtmlCellDefinition'
import { INullHtmlCellInstance } from '../../FormIntegrations/htmlFormHost/Types/HtmlCellInstance'
import {
    CellDefinition,
    IConcreteCellDefinition,
    ILayoutCellDefinition,
    IRepeatCellDefinition,
    IValueCellDefinition
} from '../Types/CellDefinition'
import {
    IConcreteCellInstance,
    ILayoutCellInstance,
    IRepeatCellInstance,
    NonVirtualCellInstance
} from '../Types/CellInstance'
import { CellType } from '../Types/CellType'
import { UnifiedCellDefinition } from '../Types/UnifiedCellDefinition'
import { UnifiedCellInstance } from '../Types/UnifiedCellInstance'

type PropertiesType<
	T extends
		| IConcreteCellInstance
		| IRepeatCellInstance
		| ILayoutCellInstance
		| CellDefinition,
> = T['properties']

export function createNonValueCellInstanceProxy<
	T extends IConcreteCellInstance | IRepeatCellInstance | ILayoutCellInstance | INullHtmlCellInstance,
>(
	cellInstance: T,
	propertiesProxy: (properties: PropertiesType<T>) => Record<string, unknown>,
): UnifiedCellInstance {
	const proxy = propertiesProxy(cellInstance.properties)

	const returnValue: UnifiedCellInstance = {
		type: cellInstance.type,
		id: cellInstance.id,
		definitionId: cellInstance.definitionId,
		elementTag: cellInstance.elementTag,
		fieldType: cellInstance.fieldType,
		name: cellInstance.name,
		parentId: cellInstance.parentId,
		properties: proxy,
	}

	return returnValue
}

export function createValueCellInstanceProxy<T extends NonVirtualCellInstance>(
	cellInstance: T,
	propertiesProxy: (properties: PropertiesType<T>) => Record<string, unknown>,
	valueGetter: () => unknown,
	valueSetter: (v: unknown) => void,
): UnifiedCellInstance {
	const proxy = propertiesProxy(cellInstance.properties)

	if (cellInstance.type === CellType.Concrete)
		return {
			...cellInstance,
			properties: proxy,
		}

	const returnValue: UnifiedCellInstance = {
		type: cellInstance.type,
		id: cellInstance.id,
		definitionId: cellInstance.definitionId,
		elementTag: cellInstance.elementTag,
		fieldType: cellInstance.fieldType,
		name: cellInstance.name,
		parentId: cellInstance.parentId,
		properties: proxy,
		get value(): unknown {
			return valueGetter()
		},
		set value(v: unknown) {
			if (v === undefined && cellInstance.type === CellType.Value)
				throw new Error("cannot set a value instance's value to undefined")

			valueSetter(v)
		},
	}

	return returnValue
}

export function createCellDefinitionProxy<
	T extends
		| IValueCellDefinition
		| IRepeatCellDefinition
		| ILayoutCellDefinition
		| IConcreteCellDefinition
		| INullHtmlCellDefinition,
>(
	cellDefinition: T,
	propertiesProxy: (properties: PropertiesType<T>) => Record<string, unknown>,
): UnifiedCellDefinition {
	const proxy = propertiesProxy(cellDefinition.properties)

	const returnValue: UnifiedCellDefinition = {
		type: cellDefinition.type,
		id: cellDefinition.id,
		elementTag: cellDefinition.elementTag,
		fieldType: cellDefinition.fieldType,
		parentId: cellDefinition.parentId,
		name: cellDefinition.name,
		properties: proxy,
	}

	return returnValue
}
