import { NIL } from "uuid"
import { CellDefinition } from "../modules/FormHost/Types/CellDefinition"

// we want to be able to differentiate between fields with the same names, so we display their parent names
export const getCellDisplayName = (
	cellDefinitions: CellDefinition[],
	cellDefinition: CellDefinition,
): string => {
	if (cellDefinition.parentId === NIL) return cellDefinition.name

	const parent = cellDefinitions.find((v) => v.id === cellDefinition.parentId)

	if (parent === undefined) return cellDefinition.name

	let parentName = getCellDisplayName(cellDefinitions, parent)

	if (parentName === '') parentName = parent.id

	return `${parentName} -> ${cellDefinition.name}`
}
