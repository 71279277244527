import { ControlCamera } from '@mui/icons-material'
import {
	Box,
	ButtonBase,
	Card,
	Divider,
	Icon,
	Typography,
	useTheme,
} from '@mui/material'
import { computed, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { ReactNode, useEffect, useMemo } from 'react'
import { useDrag } from 'react-dnd'
import {
	ActivityConfiguration,
	ActivityInstance,
	ActivityInstanceStatus,
	BaseActivityDefinitionModel,
} from '../../api/clients/workflows/DTOs'
import { HtmlTooltip } from '../../components/tooltips/HtmlTooltip'
import { statuses } from './ActivityInstanceStatus'
import { DraggableWorkflowType } from './DraggableWorkflowType'
import { useWorkflowStore } from './stores/WorkflowStore'

type BaseActivityCardProps = {
	icon: string
	activityTitle: ReactNode
	description: ReactNode
}

type ActivityConfigurationCardProps = {
	uniqueId: string
} & BaseActivityCardProps

// the draggable activity card for the Activity Options sidebar
export const ActivityConfigurationCard = (
	props: ActivityConfigurationCardProps,
) => {
	const [_, drag] = useDrag(() => ({
		type: DraggableWorkflowType.ActivityConfiguration,
		item: {
			definitionId: props.uniqueId,
		} as ActivityConfiguration,
	}))

	return (
		<Box ref={drag}>
			<ActivityCard {...props} />
		</Box>
	)
}

type EditorActivityCardProps = {
	definition: BaseActivityDefinitionModel
	onOutcomesUpdated: (outcomes: string[]) => void
	onClick: () => void
} & BaseActivityCardProps

// the card displayed in the workflow editor
export const EditorActivityCard = observer(
	({
		definition,
		onOutcomesUpdated,
		onClick,
		...rest
	}: EditorActivityCardProps) => {
		const { workflowContext, activityConfigurationStore } = useWorkflowStore()
		const theme = useTheme()
		const selected = computed(
			() =>
				workflowContext.selectedActivityDefinitionId === definition.uniqueId,
		)

		const instances = useMemo(
			() =>
				workflowContext.instance?.activityInstances.filter(
					(instance) => instance.referenceId === definition.uniqueId,
				),
			[workflowContext.instance],
		)

		useEffect(() => {
			const activityConfig = activityConfigurationStore.getConfiguration(
				definition.activityTypeId,
			)
			if (activityConfig === undefined)
				throw new Error('idk how this would happen but here we are 🤷‍♀️')

			const activityDescriptionFn = new Function(
				'x',
				`return (${activityConfig.runtimeDescription})(x)`,
			)

			const descriptionDisposer = reaction(
				() => JSON.stringify(definition),
				(v, vLast) => {
					try {
						/* the order of description:
						- if the user has entered a description, ALWAYS use that one
						- if there is a runtime description, use it only if the user 
						   hasn't entered their own description
						- if no user-entered description and no runtime description,
						   use the one configured in the ActivityConfiguration
						*/

						const description = activityDescriptionFn(JSON.parse(v))

						// if we don't have a runtime description (or normal description) we default to the config
						if (description === '') {
							definition.description = activityConfig.description
							return
						}

						// if we have a runtime description and the current one is set to the config's, we overwrite it
						// w/ the runtime description
						if (definition.description === activityConfig.description) {
							definition.description = description
							return
						}

						// if the last description is undefined, we assign to the new runtime description
						if (vLast === undefined) {
							definition.description = description
							return
						}

						const lastDescription = activityDescriptionFn(JSON.parse(vLast))

						// and lastly if the runtime description is being used (desc not set by user) and we update it, make it the new description
						if (lastDescription === definition.description)
							definition.description = description
					} catch {
						// no-op
					}
				},
				{
					fireImmediately: true,
				},
			)

			if (Array.isArray(activityConfig.outcomes) || !activityConfig.outcomes) {
				return () => {
					descriptionDisposer()
				}
			}

			// if we ever change this, make sure it matches with the one in ActivityCard

			let activityOutcomesFn: ReturnType<typeof Function>
			try {
				activityOutcomesFn = new Function(
					'x',
					`
				const outcomes = (${activityConfig.outcomes})
				if (typeof outcomes === 'function')
					return outcomes(x)
				if (typeof outcomes === 'object')
					return outcomes
				return outcomes
				`,
				)
			} catch (error) {
				activityOutcomesFn = new Function(
					'x',
					`const outcomes = '${activityConfig.outcomes}'`,
				)
			}

			// NOTE FOR THESE REACTIONS:
			// JSON.stringify is  literally the only way we can track state
			// and get the definition itself for the effect (as horrible as it is)

			const outcomesDisposer = reaction(
				() => JSON.stringify(definition),
				(v, vLast) => {
					try {
						// we need the definition here, but we're relying on only state changing
						// bc the outcomes with functions are "x => x.state.branches" with x being
						// the definition
						const outcomes = activityOutcomesFn(JSON.parse(v))
						const lastOutcomes = activityOutcomesFn(JSON.parse(vLast))

						// and stringify to compare arrays - we only update outcomes if this is not the same
						if (JSON.stringify(outcomes) !== JSON.stringify(lastOutcomes))
							onOutcomesUpdated(outcomes)
					} catch {
						// no-op
					}
				},
				{
					fireImmediately: false,
				},
			)

			return () => {
				outcomesDisposer()
				descriptionDisposer()
			}
		})

		const getInstanceColor = (instance: ActivityInstance) => {
			switch (instance.status) {
				case ActivityInstanceStatus.Completed:
					return theme.palette.success.main
				case ActivityInstanceStatus.Halted:
					return theme.palette.primary.main
				case ActivityInstanceStatus.Faulted:
					return theme.palette.error.main
				case ActivityInstanceStatus.Cancelled:
					return theme.palette.grey[500]
				default:
					return undefined
			}
		}

		const border = useMemo(() => {
			// if it's a definition and not selected we don't want to show a border at all
			if (workflowContext.instance === undefined) return selected.get() ? 1 : 0

			// if it's an instance we always show a border, thicker when selected
			return selected.get() ? 2 : 1
		}, [selected.get()])

		const borderColor = useMemo(() => {
			if (instances !== undefined)
				// instances always have colors unless they have never been run (definition will
				// have 0 instances if it never ran)
				return instances.length > 0
					? getInstanceColor(instances[instances.length - 1])
					: undefined

			// selected activity definitions have primary borders
			return selected.get() ? theme.palette.primary.main : undefined
		}, [selected.get()])

		return (
			<HtmlTooltip
				title={
					instances === undefined ? (
						''
					) : (
						<Box>
							{instances?.length === 0 && <Typography>Not Run</Typography>}
							{[...new Set(instances)].map((instance) => (
								<Typography
									key={instance.id}
									color={getInstanceColor(instance)}
								>
									{
										instances?.filter((v) => v.status === instance.status)
											.length
									}{' '}
									{statuses[instance.status].label}
								</Typography>
							))}
						</Box>
					)
				}
			>
				<Box
					id={definition.uniqueId}
					left={definition.left}
					top={definition.top}
					position="absolute"
					onClick={onClick}
				>
					<ActivityCard border={border} borderColor={borderColor} {...rest} />
				</Box>
			</HtmlTooltip>
		)
	},
)

type ActivityCardProps = {
	border?: number
	borderColor?: string
} & BaseActivityCardProps

export const ActivityCard = observer((props: ActivityCardProps) => {
	return (
		<Box
			component={Card}
			boxShadow={12}
			display="flex"
			minWidth="275px"
			justifyContent="center"
			flexDirection="column"
			border={props.border}
			borderColor={props.borderColor}
			sx={{ cursor: 'pointer' }}
		>
			<link
				rel="stylesheet"
				href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
			/>
			<Box paddingY={2} paddingX={2} height="100%" width="100%" display="flex">
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					marginLeft={-1}
				>
					{props.icon !== 'TODO' ? (
						<Icon baseClassName="material-symbols-outlined">{props.icon}</Icon>
					) : (
						<ControlCamera fontSize="large" />
					)}
				</Box>
				<Box
					component={Divider}
					orientation="vertical"
					flexItem
					marginY={-2}
					marginX={1}
				/>
				<Box
					component={ButtonBase}
					display="flex"
					flexDirection="column"
					flex={1}
					alignItems="flex-start"
				>
					<Box display="flex" justifyContent="space-between" width="100%">
						<Typography variant="h6" textAlign="start">
							{props.activityTitle}
						</Typography>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						gap={1}
						alignItems="flex-start"
						width="100%"
					>
						<Typography
							variant="caption"
							paragraph
							color="textSecondary"
							marginBottom={0}
							textAlign="start"
						>
							{props.description}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	)
})
