import { action } from "mobx"
import { RegistrationError } from "../../../pages/PackageHostPage/PackageHostPageContext"

export const addValidationError = action((
	instanceErrors: Record<string, RegistrationError[]>,
	formName: string,
	fieldName: string,
	instanceId: string,
	registrationId: string,
	errorText: string) => {
	
	// if field is not currently valid
	// we need to add an entry for this instance if one does not exist
	if (instanceErrors[instanceId] === undefined)
		instanceErrors[instanceId] = []

	// get the object with the registration id & error text for this instance
	const existingRegistration = instanceErrors[instanceId].find(
		(v) => v.registrationId == registrationId,
	)

	if (existingRegistration !== undefined) {
		// if it exists, update the error text
		// existingRegistration.errorText = fieldErrorText
	} else {
		// add onto the error list if this registration currently doesn't have an error
		instanceErrors[instanceId].push({
			registrationId: registrationId,
			errorText: errorText,

			errorBucket: formName,
			errorFieldName: fieldName,
		})
	}
})

export const removeValidationError = action((instanceErrors: Record<string, RegistrationError[]>, key: string, registrationId: string) => {		
	// do we have any errors currently for this registration & instance?
	if (instanceErrors[key] === undefined) return
	// remove the registration that is against this field
	instanceErrors[key] = instanceErrors[key].filter(
		(v) => v.registrationId !== registrationId,
	)

	// remove this instance from the list if there are no more errors for it
	if (instanceErrors[key].length === 0)
		delete instanceErrors[key]
})

export const addAttachmentError = action((
	validationErrors: Record<string, RegistrationError[]>,
	attachmentTypeName: string,
	registrationId: string,
	errorText: string) => {

	// if field is not currently valid
	// we need to add an entry for this instance if one does not exist
	if (validationErrors[attachmentTypeName] === undefined)
		validationErrors[attachmentTypeName] = []

	// get the object with the registration id & error text for this instance
	const existingRegistration = validationErrors[attachmentTypeName].find(
		(v) => v.registrationId == registrationId,
	)

	if (existingRegistration !== undefined) {
		// if it exists, update the error text
		existingRegistration.errorText = errorText
	} else {
		// add onto the error list if this registration currently doesn't have an error
		validationErrors[attachmentTypeName].push({
			registrationId: registrationId,
			errorText: errorText,

			errorBucket: 'Attachment',
			errorFieldName: attachmentTypeName,
		})
	}
})