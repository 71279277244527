import { Select, styled } from '@mui/material'

// to make the text in the select a specified color
const ColoredSelect = styled(Select, {
	shouldForwardProp: (prop) => prop !== 'selectColor',
})<{ selectColor: string }>(({ selectColor }) => ({
	color: selectColor,

	'& .MuiSvgIcon-root ': {
		fill: `${selectColor} !important`,
	},

	'&:before': {
		borderBottomColor: selectColor,
	},
}))

export default ColoredSelect
