import { Box, Paper, Typography } from '@mui/material'

export const Eula = () => {
	return (
		<Box overflow="auto">
			<Box
				component={Paper}
				marginX={16}
				marginY={4}
				padding={4}
				elevation={10}
			>
				<Box display="flex" flexDirection="column" padding={5} gap={1}>
					<Box display="flex" justifyContent="center">
						<Typography variant="h4">
							Forms inMotion End User License Agreement
						</Typography>
					</Box>
					<Box display="flex" justifyContent="center">
						<Typography variant="h5" align="center">
							Terms and Conditions
						</Typography>
					</Box>
				</Box>
				<EulaText />
			</Box>
		</Box>
	)
}

const EulaText = () => {
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Box>
				<b>1. Definitions.</b> Capitalized terms used in this Agreement shall
				have the following meanings:
			</Box>{' '}
			<Box>
				1.1 "Additional License Limitations" shall have the meaning assigned to
				it in Section 2.1 ("Grant of License").
			</Box>{' '}
			<Box>
				1.2 "Authorized Capacity" shall mean the particular capacity based scope
				of use rights granted to Customer and set forth in an Order Form.
				Authorized Capacity may be user based, server based, or any other
				capacity based user rights set forth in the Order Form.
			</Box>{' '}
			<Box>
				1.3 "Authorized Location" shall mean the location identified in the
				applicable Order Form and operated by Customer.
			</Box>
			<Box>
				1.4 "Documentation" shall mean all materials provided to Customer by
				Licensor and designated as documentation which describe the form,
				features or operation of the Software and which are contained in a
				tangible medium, such as written format, tape, magnetic or other media.
				Documentation shall include any Updates of Documentation which Licensor
				may make available to Customer pursuant to any separate agreement for
				Technical Support Services.
			</Box>
			<Box>
				1.5 "Error" shall have the meaning assigned to it in Section 6.1
				("Software Warranty").
			</Box>{' '}
			<Box>
				1.6 "Key" shall mean a numerical or alpha-numerical code which is
				necessary to gain access to certain Software on certain media as
				delivered by Licensor hereunder.
			</Box>{' '}
			<Box>
				1.7 "Order Form" shall mean a written order form executed by Customer
				and approved by Licensor which sets forth the necessary information
				relating to the Software, which may include, without limitation,
				identification of the Software licensed (including whether in object
				code or source code form), the Authorized Location, the Authorized
				Capacity and any Additional License Limitations.
			</Box>{' '}
			<Box>
				1.8 "Professional Services" shall mean any professional services which
				Customer may receive from time to time relating to the Software pursuant
				to a separate agreement between Customer and Licensor or an approved
				reseller of Licensor.
			</Box>{' '}
			<Box>
				1.9 "Software" shall mean the computer software programs described in
				the applicable Order Form, in object code only, including Updates, if
				any, and accompanying Documentation and as specifically licensed
				hereunder. Software shall further include any modifications to the
				Software provided to Customer as a part of any Professional Services and
				shall be subject to the terms and conditions of this Agreement.
			</Box>
			<Box>
				1.10 "Software License" shall have the meaning set forth in Section 2.1
				("Grant of License").
			</Box>{' '}
			<Box>
				1.11 "Specifications" shall mean the technical and performance
				specifications for the Software which are contained in and distributed
				with the Documentation.
			</Box>{' '}
			<Box>
				1.12 "Supported Software" shall mean a Software License for which
				Customer has ordered and paid for Technical Support Services for the
				relevant time period under a separate agreement approved in writing by
				Licensor (including such an agreement with an authorized reseller of
				Licensor). Supported Software shall not include (i) any modifications to
				software by Customer or (ii) any other software or modifications to
				software provided as a part of any Professional Services or pursuant to
				any separate agreement unless otherwise expressly provided for in a
				separate agreement.
			</Box>{' '}
			<Box>
				1.13 "Technical Support Services" shall mean technical support services
				on Supported Software pursuant to a separate agreement approved in
				writing by Licensor (including such an agreement with an authorized
				reseller of Licensor).
			</Box>{' '}
			<Box>
				1.14 "Update" shall mean subsequent releases of the Software which are
				generally made available for Supported Software at no additional charge,
				other than media and handling charges. Updates may include modifications
				of or additions to the Software, but does not include any releases,
				versions, options or future products which Licensor licenses separately.
			</Box>{' '}
			<Box>
				1.15 "Warranty Period" shall have the meaning assigned to it in Section
				6 ("Warranty and Disclaimer").
			</Box>
			<Box>
				<b>2. Grant of Software License.</b>
			</Box>
			<Box>
				2.1 <b>Grant of License.</b> In consideration of all obligations of
				Customer hereunder, including without limitation the payment by Customer
				of Software License Fees, Licensor hereby grants to Customer a
				non-exclusive, non-transferable license (the "Software License") solely
				to (i) install one copy of the Software only at the Authorized Location;
				(ii) use the Software up to and for the Authorized Capacity solely for
				the purpose of serving the internal needs of Customer's organization and
				not the needs of any affiliate or any other person and (iii) use the
				Software subject to any additional license limitations set forth in the
				applicable Order Form (the "Additional License Limitations").
			</Box>{' '}
			<Box>
				2.2 <b>Copies.</b> Customer may make one copy of the Software for
				Customer's internal back-up and archival purposes only, provided that
				such copy shall bear the original and unmodified copyright, patent and
				other intellectual property markings as originally delivered by
				Licensor. Customer may, however, make additional copies of the
				Documentation only as reasonably necessary to use the Software in
				compliance with this Agreement.
			</Box>{' '}
			<Box>
				2.3 <b>Additional Restrictions.</b>
			</Box>
			<Box marginLeft={3}>
				2.3.1 <b>No Implied Licenses.</b> The Software, including all copies
				thereof, are and shall remain at all times the exclusive property of
				Licensor. Customer does not acquire any rights or licenses therein
				except those expressly granted herein and does not receive any title or
				interest to the Software or any changes or modifications thereto or any
				intellectual property rights throughout the world contained therein.
			</Box>{' '}
			<Box marginLeft={3}>
				2.3.2 <b>No Transfer.</b> Customer may not market, distribute,
				sublicense, sell, assign, pledge, lease, transfer or in any way encumber
				the Software.
			</Box>
			<Box marginLeft={3}>
				2.3.3 <b>No Reverse Engineering.</b> Customer hereby acknowledges that
				the Software contains valuable trade secret and confidential information
				of Licensor and others. Customer agrees not to reverse compile, reverse
				engineer, reverse assemble, or otherwise attempt, directly or
				indirectly, to obtain or create source code for the Software for any
				reason.
			</Box>{' '}
			<Box marginLeft={3}>
				2.3.4 <b>Required Proprietary Notices.</b> Customer agrees that as a
				condition of its rights hereunder, each copy of the Software shall
				contain the same proprietary notices which may appear on or in Software
				as provided by Licensor to Customer and as otherwise reasonably required
				by Licensor.
			</Box>{' '}
			<Box marginLeft={3}>
				2.3.5 <b>Unauthorized Distribution or Copying.</b> Customer agrees that
				using, distributing, copying, duplicating or otherwise reproducing all
				or any part of the Software except in strict accordance with this
				Agreement or otherwise violating the provisions of this Section 2.3 will
				be considered a material breach of this Agreement which is incapable of
				cure.
			</Box>{' '}
			<Box>
				2.4 <b>Customer Responsibilities.</b> Customer has sole responsibility
				for Customer's use and operation of the Software including monitoring
				and verifying input and output data, back-up of input and output data,
				providing data for any files or tables of such Software, and for
				maintaining the required Software environment. Customer is responsible
				for installation of the Software and for converting any data files for
				use with the Software.
			</Box>{' '}
			<Box>
				2.5 <b>No Technical Support.</b> Licensor has no obligation to provide
				Technical Support Services under this Agreement. Any Technical Support
				Services would be provided only pursuant to a separate agreement
				approved in writing by Licensor (including such an agreement with an
				authorized reseller of Licensor).
			</Box>{' '}
			<Box>
				<b>3. Keys and Access.</b> Licensor shall provide to Customer the Keys,
				if any, necessary to permit Customer to gain access to the Software for
				the number of licenses which have been properly licensed to Customer
				pursuant to this Agreement. All such Keys shall be considered the
				Confidential Information of Licensor for purposes of Section 5
				("Confidential Information").
			</Box>{' '}
			<Box>
				<b>4. Right to Audit.</b> Customer shall maintain complete and accurate
				written records describing: (a) the use and location of all copies of
				the Software; (b) the number of copies of Software, and machine location
				for each copy of Software; and (c) any other information which may be
				reasonably required by Licensor to determine whether Customer is
				complying with the terms of this Agreement. To ensure compliance with
				the terms of this Agreement, Licensor shall have the right to conduct an
				inspection and audit of the facilities of Customer and all the relevant
				books and records of Customer, and to obtain true and correct
				photocopies thereof, during regular business hours at Customer's offices
				and in such a manner as not to interfere unreasonably with Customer's
				normal business activities. If any such audit should disclose any
				underpayment of software license fees, Customer shall promptly pay
				Licensor such underpaid amount, together with interest thereon at a rate
				of one and one-half percent (1.5%) per month or partial month during
				which each such amount was owed and unpaid, or the highest rate allowed
				by law, whichever is lower. If the amount of such underpayment exceeds
				five percent (5%) of amounts otherwise paid, then Customer shall
				immediately reimburse Licensor for Licensor's reasonable expenses
				associated with such audit.
			</Box>{' '}
			<Box>
				<b>5. Confidential Information.</b> Customer acknowledges that the
				Software, and other related confidential information provided to
				Customer contain unique, confidential and secret information of Licensor
				and are trade secrets and confidential proprietary products of Licensor.
				Customer agrees at all times to protect and preserve in strict
				confidence the confidentiality of the Software, and all other related
				confidential information. Customer further agrees at all times to
				protect and preserve in strict confidence the terms of this Agreement
				(including all Order Forms). Customer agrees not to permit or authorize
				access to, or disclosure of, the Software, or any other related
				confidential information or the terms of this Agreement (including all
				Order Forms) to any person or entity other than employees of Customer
				who have agreed in a written agreement to be bound by the terms of this
				Agreement and have a "need to know" such information in order to use the
				Software for the benefit of Customer.
			</Box>
			<Box>
				<b>6. Warranty and Disclaimer.</b>
			</Box>
			<Box>
				6.1 <b>Software Warranty.</b> Licensor warrants that for a period of
				thirty (30) days from the effective date of the applicable Order Form
				pursuant to which Software is licensed to Customer hereunder (the
				"Warranty Period"), that the Software shall meet Licensor's material
				Specifications for the Software, as described in the Documentation. Each
				instance in which the Software fails to meet such material
				Specifications shall be considered an "Error". If Customer reports to
				Licensor any Errors in such Software during the Warranty Period, and
				provides such detail as Licensor may reasonably require to permit
				Licensor to reproduce such Errors, then Licensor, at its expense, shall,
				as Customer's sole and exclusive remedy, use commercially reasonable
				efforts to modify or replace the Software, or provide Updates to correct
				such Errors. Customer agrees to pay Licensor for all personnel time and
				expense incurred in investigating reported Errors that are not
				reproducible. This Warranty shall not apply to (i) changes or
				modifications made to the Software other than those made by Licensor, or
				(ii) any Software used with hardware or third party software except as
				specified in the Documentation or approved by Licensor in writing.
			</Box>{' '}
			<Box marginLeft={3}>
				6.1.1 <b>Pre-production Releases.</b> If pre-production (i.e., "alpha"
				or "beta") releases of Software are provided to Customer, such copies
				are provided "as-is" without warranty of any kind.
			</Box>{' '}
			<Box>
				6.2{' '}
				<b>
					Disclaimer. THE WARRANTIES SET FORTH IN THIS SECTION 6 ("WARRANTY AND
					DISCLAIMER") STATE LICENSOR'S SOLE AND EXCLUSIVE WARRANTY TO CUSTOMER
					CONCERNING THE SOFTWARE AND THE EXCLUSIVE REMEDY FOR BREACH OF
					WARRANTY. EXCEPT AS EXPRESSLY SET FORTH IN SECTION 6.1 ("SOFTWARE
					WARRANTY"), THE SOFTWARE IS PROVIDED STRICTLY "AS IS," AND LICENSOR
					MAKES NO ADDITIONAL WARRANTIES, EXPRESS, IMPLIED, ARISING FROM COURSE
					OF DEALING OR USAGE OF TRADE, OR STATUTORY, AS TO THE SOFTWARE OR ANY
					MATTER WHATSOEVER. IN PARTICULAR, ANY AND ALL WARRANTIES OF
					MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT
					ARE EXPRESSLY EXCLUDED. THESE WARRANTIES ARE LIMITED AND ARE THE ONLY
					WARRANTIES MADE BY LICENSOR. LICENSOR DOES NOT WARRANT THAT THE
					OPERATION OF THE SOFTWARE WILL BE ERROR FREE OR THAT ALL ERRORS WILL
					BE CORRECTED.
				</b>
			</Box>{' '}
			<Box>
				<b>7. Indemnities.</b>
			</Box>
			<Box>
				7.1 <b>Intellectual Property Indemnity.</b> Licensor agrees to
				indemnify, defend and hold harmless Customer from any costs, damages,
				and reasonable attorneys' fees resulting from any claims by third
				parties that the Software as delivered by Licensor directly infringes
				any United States patent, copyright or trademark, or misappropriates any
				trade secret arising under the laws of the states of the United States;
				provided that Customer: a) gives Licensor prompt written notice of each
				such claim; b) tenders to Licensor the defense or settlement of each
				such claim at Licensor's expense; and c) cooperates with Licensor, at
				Licensor's expense, in defending or settling each such claim. If
				Licensor receives notice of an alleged infringement, or if Customer's
				use of the Software shall be prevented by permanent injunction, Licensor
				may, at its sole option and expense: a) procure for Customer the right
				to continue using the Software as provided hereunder; b) modify the
				Software so that it is no longer infringing; or c) replace the Software
				with computer software of equal or superior functional capability. If
				none of the foregoing is commercially reasonable, Licensor shall have
				the right to terminate any affected Software License and require the
				return of the Software. If Licensor terminates any Software License as
				described above, Licensor shall refund the Software License Fees paid
				less depreciation determined by prorating the software license fees paid
				by Customer over a straight-line three year period.
			</Box>{' '}
			<Box>
				7.2 <b>Intellectual Property Indemnity Limitations.</b> THE RIGHTS
				GRANTED TO CUSTOMER UNDER SECTION 7.1 ("INTELLECTUAL PROPERTY
				INDEMNITY") SHALL BE CUSTOMER'S SOLE AND EXCLUSIVE REMEDY AND LICENSOR'S
				SOLE OBLIGATION FOR ANY ALLEGED INFRINGEMENT OF ANY PATENT, COPYRIGHT,
				TRADEMARK, OR OTHER PROPRIETARY RIGHT. LICENSOR SHALL HAVE NO LIABILITY
				TO CUSTOMER IF ANY ALLEGED INFRINGEMENT OR CLAIM OF INFRINGEMENT IS
				BASED UPON: (A) ANY MODIFIED SOFTWARE; (B) ANY CUSTOMER OR THIRD PARTY
				APPLICATION; (C) ANY THIRD PARTY SOFTWARE INCLUDED WITH LICENSOR'S
				SOFTWARE (D) USE OF THE SOFTWARE IN CONNECTION OR IN COMBINATION WITH
				EQUIPMENT, DEVICES, OR SOFTWARE NOT PROVIDED BY LICENSOR (BUT ONLY TO
				THE EXTENT THAT THE SOFTWARE ALONE WOULD NOT HAVE INFRINGED); (E)
				PROFESSIONAL SERVICES PROVIDED PURSUANT TO ANY AGREEMENT WITH CUSTOMER
				IN COMPLIANCE WITH CUSTOMER'S DESIGN REQUIREMENTS OR SPECIFICATIONS; (F)
				THE USE OF SOFTWARE OTHER THAN AS PERMITTED UNDER THIS AGREEMENT OR IN A
				MANNER FOR WHICH IT WAS NOT INTENDED; OR (G) USE OF OTHER THAN THE MOST
				CURRENT RELEASE OR VERSION OF THE SOFTWARE (IF SUCH CLAIM WOULD HAVE
				BEEN PREVENTED BY THE USE OF SUCH RELEASE OR VERSION).
			</Box>{' '}
			<Box>
				<b>8. Limitation of Liability.</b> LICENSOR SHALL NOT BE LIABLE TO
				CUSTOMER FOR ANY LOSS OF USE, INTERRUPTION OF BUSINESS, LOSS OR
				CORRUPTION OF DATA, OR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
				CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) REGARDLESS OF
				THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
				STRICT PRODUCT LIABILITY, LAW, EQUITY OR OTHERWISE, EVEN IF LICENSOR HAS
				BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITH RESPECT TO ANY
				ORDER FORM, LICENSOR'S MAXIMUM LIABLITY ARISING OUT OF OR RELATING TO
				SUCH ORDER FORM REGARDLESS OF THE FORM OF THE ACTION WHETHER IN
				CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABLITY, LAW,
				EQUITY OR OTHERWISE, SHALL NOT EXCEED THE AGGREGATE SOFTWARE LICENSE
				FEES PAID BY CUSTOMER UNDER SUCH ORDER FORM. IN NO EVENT SHALL LICENSOR
				BE LIABLE FOR ANY DAMAGES BASED UPON THE ACCURACY OF THE LICENSOR
				SOFTWARE OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS. CUSTOMER
				ACKNOWLEDGES THAT LICENSOR HAS SET ITS FEES, AND ENTERED INTO THIS
				AGREEMENT IN RELIANCE UPON THE LIMITATIONS OF LIABILITY AND THE
				DISCLAIMERS OF WARRANTIES AND DAMAGES SET FORTH IN THIS AGREEMENT, AND
				THAT THE SAME FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN THE
				PARTIES. THE FOREGOING LIMITATION OF LIABILITY IS INDEPENDENT OF ANY
				EXCLUSIVE REMEDIES FOR BREACH OF WARRANTY SET FORTH IN THIS AGREEMENT.
			</Box>
			<Box>
				<b>9. Term and Termination.</b>
			</Box>{' '}
			<Box>
				9.1 <b>Term.</b> The term of this Agreement shall commence on the
				Effective Date and shall continue until terminated.
			</Box>{' '}
			<Box>
				9.2 <b>Termination for Material Breach.</b> Either party may terminate
				this Agreement immediately upon written notice for the material breach
				of the other party, which material breach has remained uncured for
				period of thirty (30) days (if curable) from the date of delivery of
				written notice thereof to the breaching party.
			</Box>{' '}
			<Box>
				9.3 <b>Termination for Bankruptcy.</b> In the event a party makes a
				general assignment for the benefit of creditors or files a voluntary
				petition in bankruptcy or petitions for reorganization or arrangement
				under the bankruptcy laws, or if a petition in bankruptcy is filed
				against a party, or if a receiver or trustee is appointed for all or any
				part of the property and assets of a party, the other party may
				terminate this Agreement.
			</Box>{' '}
			<Box>
				9.4 <b>Effect.</b> In the event of any termination of this Agreement,
				all licenses (including, but not limited to, Software Licenses) granted
				by Licensor hereunder shall immediately terminate, and Customer shall
				immediately return to Licensor all material delivered hereunder
				including without limitation all copies of the Software and shall
				promptly certify to Licensor in writing that Customer has done so.
			</Box>{' '}
			<Box>
				<b>10. Survival.</b> In the event of any expiration or termination of
				this Agreement, the provisions of Section 1 ("Definitions"), Section 2.3
				("Additional Restrictions"), Section 4 ("Right to Audit"), Section 5
				("Confidential Information"), Section 6 ("Warranty and Disclaimer"),
				Section 7 ("Indemnities"), Section 8 ("Limitation of Liability"),
				Section 9.4 ("Effect"), Section 10 ("Survival") and Section 11
				("General") shall survive and shall continue to bind the parties.
			</Box>{' '}
			<Box>
				<b>11. General.</b>
			</Box>{' '}
			<Box>
				11.1 <b>Taxes.</b> In addition to any other payments due under this
				Agreement, Customer agrees to pay, and to indemnify and hold Licensor
				harmless from, any sales, use, excise, import or export, value added or
				similar tax or duty not based on Licensor's net income, including any
				penalties and interest, as well as any costs associated with the
				collection or withholding thereof; and all governmental permit fees,
				license fees and customs and similar fees levied upon the delivery by
				Licensor of the Software, which Licensor may incur in respect of this
				Agreement.
			</Box>{' '}
			<Box>
				11.2 <b>Government Customers.</b> This Software is a "commercial item,"
				as that term is defined at 48 C.F.R. 2.101 (OCT 1995), and is
				"commercial computer software" and "commercial computer software
				documentation," as such terms are used in 48 C.F.R. 12.212 (SEPT 1995).
			</Box>{' '}
			<Box>
				11.3 <b>Export Control.</b> The parties acknowledge that the manufacture
				and sale of the Software is subject to the export control laws of the
				United States of America, including the U.S. Bureau of Export
				Administration regulations, as amended, and hereby agree to obey any and
				all such laws.
			</Box>{' '}
			<Box>
				11.4 <b>Governing Law.</b> This Agreement shall be governed in all
				respects by the laws of the United States of America and the State of
				South Carolina without regard to conflicts of law principles. The
				parties agree that the United Nations Convention on Contracts for the
				International Sale of Goods is specifically excluded from application to
				this Agreement.
			</Box>{' '}
			<Box>
				11.5 <b>Forum.</b> All disputes arising under this Agreement shall be
				brought in the Court of Common Pleas, Pickens County, South Carolina or
				the Federal District Court, Greenville Division, District of South
				Carolina, as permitted by law. The Court of Common Pleas, Pickens
				County, South Carolina and the Federal District Court, Greenville
				Division, District of South Carolina shall each have non exclusive
				jurisdiction over disputes under this Agreement. Customer consents to
				the personal jurisdiction of the above courts.
			</Box>{' '}
			<Box>
				11.6 <b>Injunctive Relief.</b> It is understood and agreed that,
				notwithstanding any other provisions of this Agreement, breach of the
				provisions of this Agreement by Customer will cause Licensor irreparable
				damage for which recovery of money damages would be inadequate, and that
				Licensor shall therefore be entitled to obtain timely injunctive relief
				to protect Licensor's rights under this Agreement in addition to any and
				all remedies available at law.
			</Box>{' '}
			<Box>
				11.7 <b>Notices.</b> All notices or reports permitted or required under
				this Agreement shall be in writing and shall be delivered by personal
				delivery or by certified or registered mail, return receipt requested,
				and shall be deemed given upon personal delivery or five (5) days after
				deposit in the U.S. mail. Notices shall be sent to the parties at the
				addresses described on the first page of this Agreement or such other
				address as either party may designate for itself in writing. All notices
				to Licensor must be addressed to its President to be effective.
			</Box>{' '}
			<Box>
				11.8 <b>No Agency.</b> Nothing contained herein shall be construed as
				creating any agency, partnership, or other form of joint enterprise
				between the parties.
			</Box>{' '}
			<Box>
				11.9 <b>Force Majeure.</b> Licensor shall not be liable hereunder by
				reason of any failure or delay in the performance of its obligations
				hereunder on account of strikes, shortages, riots, insurrection, fires,
				flood, storm, explosions, acts of God, war, governmental action, labor
				conditions, earthquakes, material shortages or any other cause which is
				beyond the reasonable control of Licensor .
			</Box>{' '}
			<Box>
				11.10 <b>Waiver.</b> The failure of either party to require performance
				by the other party of any provision hereof shall not affect the full
				right to require such performance at any time thereafter; nor shall the
				waiver by either party of a breach of any provision hereof be taken or
				held to be a waiver of the provision itself.
			</Box>{' '}
			<Box>
				11.11 <b>Severability.</b> In the event that any provision of this
				Agreement shall be unenforceable or invalid under any applicable law or
				be so held by applicable court decision, such unenforceability or
				invalidity shall not render this Agreement unenforceable or invalid as a
				whole, and, in such event, such provision shall be changed and
				interpreted so as to best accomplish the objectives of such
				unenforceable or invalid provision within the limits of applicable law
				or applicable court decisions.
			</Box>{' '}
			<Box>
				11.12 <b>Use of Customer's Name.</b> Customer agrees that Licensor may
				use Customer's name and may disclose that Customer is a licensee of
				Licensor products in Licensor advertising, promotion and similar public
				disclosures with respect to the Software; provided, however, that such
				advertising, promotion or similar public disclosures shall not indicate,
				without Customer's written consent, that Customer in any way endorses
				any Licensor products.
			</Box>{' '}
			<Box>
				11.13 <b>Nondisclosure.</b> Customer promises not to disclose the terms
				and conditions of this Agreement to any third party without the prior
				written consent of Licensor .
			</Box>{' '}
			<Box>
				11.14 <b>Headings.</b> The section headings appearing in this Agreement
				are inserted only as a matter of convenience and in no way define,
				limit, construe, or describe the scope or extent of such section or in
				any way affect this Agreement.
			</Box>{' '}
			<Box>
				11.15 <b>Assignment.</b> Neither this Agreement nor any rights or
				obligations of Customer hereunder may be assigned, sold or otherwise
				transferred by Customer in whole or in part (including by merger,
				reorganization, consolidation, sale of all or any portion of the assets
				of Customer or change in control of Customer) without the prior written
				approval of Licensor. For the purposes of this Section 11.15
				("Assignment"), a change in control means a change in the persons or
				entities who control fifty percent (50%) or more of the equity
				securities or voting interest of Customer as of the date of this
				Agreement. Licensor's rights and obligations, in whole or in part, under
				this Agreement may be assigned by Licensor .
			</Box>{' '}
			<Box>
				11.16 <b>Third Party Beneficiaries.</b> Customer acknowledges that third
				party software is included with the Software and that such third parties
				are beneficiaries to this Agreement and this Agreement is enforceable by
				such third parties.
			</Box>{' '}
			<Box>
				11.17 <b>Counterparts.</b> This Agreement may be executed simultaneously
				in two or more counterparts, each of which will be considered an
				original, but all of which together will constitute one and the same
				instrument.
			</Box>{' '}
			<Box>
				11.18 <b>Entire Agreement.</b> This Agreement together with the
				Schedules hereto completely and exclusively states the agreement of the
				parties regarding its subject matter. It supersedes, and its terms
				govern, all prior proposals, agreements, or other communications between
				the parties, oral or written, regarding such subject matter. The terms
				of any shrink-wrap agreement accompanying any Software delivered by
				Licensor hereunder shall, to the extent inconsistent with the terms of
				this Agreement, have no effect whatsoever. This Agreement shall not be
				modified except by a subsequently dated written amendment or Schedule
				signed on behalf of Licensor and Customer by their duly authorized
				representatives.
			</Box>{' '}
			<Box>~#4828-9488-8465 v.2~</Box>
		</Box>
	)
}
