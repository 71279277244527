import { Box, Typography } from '@mui/material'
import { Syntax } from 'esprima'
import { Expression, MemberExpression, Super } from 'estree'
import { action } from 'mobx'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'

const validNodes = [Syntax.MemberExpression, Syntax.Literal]

const StringLengthComponent = (props: RendererComponent<MemberExpression>) => {
	const [color, textColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<Box
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			gap={2}
			padding={1}
			bgcolor={color}
			width="fit-content"
		>
			<Typography color={textColor} noWrap>
				Length of
			</Typography>
			<Renderer
				dropMode={DropMode.Replace}
				validNodes={validNodes}
				getter={() => props.node.object}
				setter={action(
					(node) => (props.node.object = node as Expression | Super),
				)}
			/>
		</Box>
	)
}

export default StringLengthComponent
