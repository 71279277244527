import { Edit } from '@mui/icons-material'
import {
	Chip,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { BaseUser } from '../../../../../api/DTO'
import {
	UserProperty,
	UserPropertyAssociation,
} from '../../../../../api/DTOtemp'
import { EditValuePopover } from '../EditValuePopover'

type UserPropertyUsersTableProps = {
	userProperty: UserProperty
	users: BaseUser[]
	associations: UserPropertyAssociation[]
	onUpdateUserValue: (associationId: number, newValue: string) => void
	onAddAssociation: (userId: number, value: string) => void
	onDeleteAssociation: (associationId: number) => void
	onGetUserName: (userId: number) => string | undefined
}

export const UserPropertyUsersTable = (props: UserPropertyUsersTableProps) => {
	const styles = useStyles()

	return (
		<TableContainer className={styles.tableRoot}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>User</TableCell>
						<TableCell>Value</TableCell>
						<TableCell>Last Edited</TableCell>
						<TableCell>Edited By</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.users.map((user) => {
						const association = props.associations.find(
							(v) =>
								v.userId === user.id && v.propertyId === props.userProperty.id,
						)

						const lastModifiedByUserName = props.onGetUserName(
							association?.lastModifiedBy ?? 0,
						)

						return (
							<UserPropertyTableRow
								userId={user.id}
								userName={`${user.firstName} ${user.lastName}`}
								association={association}
								onUpdateUserValue={props.onUpdateUserValue}
								onAddAssociation={props.onAddAssociation}
								onDeleteAssociation={props.onDeleteAssociation}
								lastModifiedByUserName={lastModifiedByUserName}
								key={user.id}
							/>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

type UserPropertyTableRowProps = {
	userId: number
	association?: UserPropertyAssociation
	userName: string
	lastModifiedByUserName?: string
	onUpdateUserValue: (associationId: number, newValue: string) => void
	onAddAssociation: (userId: number, value: string) => void
	onDeleteAssociation: (associationId: number) => void
}

const UserPropertyTableRow = observer((props: UserPropertyTableRowProps) => {
	const styles = useStyles()
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
	const [showEdit, setShowEdit] = useState(false)

	const handleMouseEnterValue = () => {
		setShowEdit(true)
	}
	const handleMouseLeaveValue = () => {
		setShowEdit(false)
	}

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleSavePopoverValue = (newValue: string) => {
		if (props.association !== undefined) {
			if (newValue === '') props.onDeleteAssociation(props.association.id)
			else if (props.association.propertyValue !== newValue)
				props.onUpdateUserValue(props.association.id, newValue) // avoid unnecessary api call if we're not really updating anything
		} else {
			props.onAddAssociation(props.userId, newValue)
		}
	}

	return (
		<TableRow>
			<TableCell>
				<Chip label={props.userName} />
			</TableCell>
			<TableCell>
				<div
					onMouseEnter={handleMouseEnterValue}
					onMouseLeave={handleMouseLeaveValue}
					className={styles.valueCell}
				>
					{props.association?.propertyValue ?? '-'}
					<IconButton
						size="small"
						onClick={handlePopoverOpen}
						className={clsx(showEdit === false && styles.hideEditIcon)}
					>
						<Edit fontSize="inherit" />
					</IconButton>
				</div>
			</TableCell>
			<EditValuePopover
				anchorEl={anchorEl}
				originalValue={props.association?.propertyValue ?? ''}
				onPopoverClose={handlePopoverClose}
				onSaveValue={handleSavePopoverValue}
			/>
			<TableCell>
				{props.association === undefined
					? '-'
					: new Date(props.association?.lastModifiedDate).toLocaleString()}
			</TableCell>
			<TableCell>{props.lastModifiedByUserName ?? '-'}</TableCell>
		</TableRow>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	hideEditIcon: {
		visibility: 'hidden',
	},

	tableRoot: {
		width: percent(100),
	},

	valueCell: {
		display: 'flex',
		flexDirection: 'row',
		flex: 1,
	},
}))
