import { Alert, Snackbar } from '@mui/material'
import { observer } from 'mobx-react'
import { toastService } from '../../services/notifications/ToastService'

type ToastWrapperProps = {
	subscriptionArea: string
}

export const ToastWrapper: React.FC<ToastWrapperProps> = observer(
	(props: ToastWrapperProps) => {
		const toast = toastService.toast

		console.log('severity', toast?.severity)
		console.log('toast exists?', toastService.toast)
		console.log('fade out?', toastService.fadeOut)

		return (
			<Snackbar
				style={{
					position: 'absolute',
				}}
				open={
					!toastService.fadeOut &&
					toast !== undefined &&
					props.subscriptionArea == toast.area
				}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert
					variant="filled"
					icon={toast?.icon}
					severity={toast?.severity ?? 'info'}
				>
					{toast?.message}
				</Alert>
			</Snackbar>
		)
	},
)
