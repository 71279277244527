import { Button, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import {
	AuthenticationMethod,
	AuthenticationType,
	ExternalProviderAuthenticationMethod,
} from '../../api/DTO'
import AuthenticationProviderInstancesClient from '../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import { SignInClient } from '../../api/clients/identity'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { LoginSection } from './LoginSection'

// display the auth methods available to an organization for the user to log in with
export const LoginAuthMethodsPage = observer(() => {
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		organizationAuthMethods: undefined as AuthenticationMethod[] | undefined,

		get externalAuthMethods() {
			return this.organizationAuthMethods?.filter(
				(v) => v.authenticationType === AuthenticationType.ExternalProvider,
			) as ExternalProviderAuthenticationMethod[]
		},

		get isFimEnabled() {
			return this.organizationAuthMethods?.some(
				(v) =>
					v.authenticationType ===
					AuthenticationType.FormsInMotionAuthentication,
			)
		},
	}))

	useEffect(() => {
		fetchOrganizationAuthMethods()
	}, [])

	const fetchOrganizationAuthMethods = async () => {
		const authenticationClient = new SignInClient()
		const { data: authMethods } =
			await authenticationClient.getOrganizationAuthenticationMethods()

		runInAction(() => {
			localStore.organizationAuthMethods = authMethods
		})
	}

	const handleSelectAuthProvider = async (id: string) => {
		const authProviderInstancesClient =
			new AuthenticationProviderInstancesClient()

		const { data: authenticationUrl } =
			await authProviderInstancesClient.getAuthenticationUrl(
				id,
				`${window.location.origin}/signin-oidc/${id}`,
			)

		window.location.href = authenticationUrl
	}

	if (
		localStore.organizationAuthMethods === undefined ||
		localStore.externalAuthMethods === undefined
	)
		return <FullscreenSpinner />

	// we need all these conditionals/clsx because if we only display one section we need it
	// to be centered and not stretched out, also don't want to display "or" if there's only
	// one auth option

	return (
		<div className={styles.wholePageLayout}>
			{localStore.isFimEnabled && (
				<div
					className={clsx(
						localStore.externalAuthMethods.length > 0
							? styles.authContent
							: styles.entryManager,
					)}
				>
					<LoginSection />
				</div>
			)}
			{localStore.isFimEnabled && localStore.externalAuthMethods.length > 0 && (
				<div className={styles.contentDivider}>
					<Typography variant="subtitle1">OR</Typography>
				</div>
			)}
			{localStore.externalAuthMethods.length > 0 && (
				<div
					className={clsx(
						localStore.isFimEnabled ? styles.authContent : styles.entryManager,
					)}
				>
					{localStore.externalAuthMethods.map((method) => (
						<ExternalAuthMethodButton
							authMethod={method}
							onClick={handleSelectAuthProvider}
						/>
					))}
				</div>
			)}
		</div>
	)
})

type ExternalAuthMethodButtonProps = {
	authMethod: ExternalProviderAuthenticationMethod
	onClick: (id: string) => void
}

const ExternalAuthMethodButton = (props: ExternalAuthMethodButtonProps) => {
	const styles = useStyles()

	return (
		<Button
			variant="outlined"
			className={styles.authMethod}
			onClick={() =>
				props.onClick(props.authMethod.authenticationProviderInstanceId)
			}
		>
			<Typography component="h3" variant="h6">
				{props.authMethod.name}
			</Typography>
		</Button>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	wholePageLayout: {
		display: 'flex',
		flexDirection: 'row',

		justifyContent: 'center',
		alignItems: 'center',
		width: percent(100),
		height: percent(100),

		padding: theme.spacing(8),
	},

	authMethod: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.spacing(2),
		padding: theme.spacing(3),
		width: percent(100),
		color: theme.palette.text.primary,
	},

	authContent: {
		flex: 3,
	},

	contentDivider: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},

	entryManager: {
		display: 'flex',

		height: '100%',
		//	width: '100%',

		justifyContent: 'center',
		alignItems: 'center',

		flexDirection: 'column',
	},
}))
