import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { specifyInternalComponent } from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'
import { TextInput } from './TextInput'

export type FormBuilderTextInputProps = {
	label?: string
	mask?: string
	hint?: string
}

export const FormBuilderTextInput = observer(
	(props: FormBuilderTextInputProps) => {
		const { cellManager } = useFormBuilderContext()
		const context = useInputCellContext<string>()

		const changeHandler = useCallback(
			action((v: React.ChangeEvent<HTMLInputElement>) => {
				const newValue = v.currentTarget.value
				context.onChange(newValue)
				changeEventEmitter(
					cellManager.eventBus,
					{
						oldValue: context.value,
						newValue: newValue,
					},
					{
						formHost: cellManager,
						elementTag: TextInputElementTag,
						definitionId: context.cellInstance.definitionId,
						instanceId: context.cellInstance.id,
					},
				)
			}),
			[],
		)

		return (
			<TextInput
				label={props.label}
				mask={props.mask}
				hint={props.hint}
				value={context.value ?? ''}
				onChange={changeHandler}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: TextInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
			/>
		)
	},
)

export const TextInputElementTag = '7f342a92-fff3-4715-a535-c44082b34c1d'

export const InternalTextInputId = '371f2cbc-b176-4e00-ae60-712dfebeeb17'

export const InternalTextInputDefinition =
	specifyInternalComponent<FormBuilderTextInputProps>(
		InternalTextInputId,
		FormBuilderTextInput,
		TextInputElementTag,
		FieldType.String,
		{
			label: undefined,
		},
		'',
	)
