import { Box } from '@mui/material'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { LicenseResponse } from '../../api/clients/identity/LicensingClient'
import { makeChildRoute } from '../../utils/CreateChildRoute'
import PackageListsRouter from '../PackageLists/PackageListsRouter'
import SidebarLayoutProvider from '../SidebarLayoutProvider'
import AdministrationRouter from './Administration'
import LicensingCheck from './Administration/Licensing/LicenseCheck'
import { UserProfileEdit } from './Administration/Users/UserPage/UserPage'
import OrganizationFrame from './OrganizationFrame'

const OrganizationRouter = () => {
	console.log('matched at organization router')

	const { path } = useRouteMatch()

	const licensingDemand = (v: LicenseResponse) => {
		const module = v.modules['FormsInMotion']
		if (module === undefined) return false
		const startDate = new Date(module.startDate)
		const endDate = new Date(module.expirationDate)
		const currentDate = new Date()

		return startDate < currentDate && endDate > currentDate
	}

	return (
		<OrganizationFrame>
			<SidebarLayoutProvider>
				<Switch>
					<Route path={makeChildRoute('_form-packages', path)}>
						<LicensingCheck demandCheck={licensingDemand}>
							<PackageListsRouter />
						</LicensingCheck>
					</Route>
					<Route path={makeChildRoute('_administration', path)}>
						<AdministrationRouter />
					</Route>
					<Route path={makeChildRoute('_users/:userId', path)}>
						<Box width="100%" height="100%" sx={{ padding: '0px 24px' }}>
							<UserProfileEdit notAdmin={true} />
						</Box>
					</Route>

					{/* redirect to form packages for now until we have a real home page */}
					<Route path={makeChildRoute('_user/_home', path)}>
						<Redirect to={makeChildRoute('_form-packages', path)} />
					</Route>

					<Route path={makeChildRoute('/', path)}>
						<Redirect to={makeChildRoute('_user/_home', path)} />
					</Route>
				</Switch>
			</SidebarLayoutProvider>
		</OrganizationFrame>
	)
}

export default OrganizationRouter
