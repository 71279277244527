import Save from '@mui/icons-material/Save'
import { Button, Divider, Paper, Tab, Tabs, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { action, isObservable, observable } from 'mobx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DetailForm } from '../../../../../../api/DTOtemp'
import { FormsClient } from '../../../../../../api/clients/identity'
import { FullscreenSpinner } from '../../../../../../components/feedback/circular'
import { FormBuilderSchema } from '../../../../../../modules/FormBuilderCore/Types'
import { FormBuilderDesigner } from '../../../../../../modules/FormBuilderInterop/Runtimes/Designer/FormBuilderDesigner'
import { SimpleFormBuilderViewer } from '../../../../../../modules/FormBuilderInterop/Runtimes/Standalone'
import { toastService } from '../../../../../../services/notifications/ToastService'
import { useSidebarContext } from '../../../../../SidebarLayoutProvider'
import AdministrationPageContainer from '../../../AdministrationPageContainer'

type FormPageFormBuilderEditorProps = {
	packageId: number
	packageVersionNumber: number
	formId: number
}

const FormPageFormBuilderEditor = (props: FormPageFormBuilderEditorProps) => {
	const [formData, setFormData] = useState<FormBuilderSchema | undefined>(
		undefined,
	)
	const [detailForm, setDetailForm] = useState<DetailForm | undefined>(
		undefined,
	)

	const [selectedIndex, setSelectedIndex] = useState(0)

	const formDataRef = useRef<string>('')

	const adminPageContainer = useSidebarContext()

	const styles = useStyles()

	useEffect(
		action(() => {
			adminPageContainer.sidebarVisible = false

			return action(() => {
				adminPageContainer.sidebarVisible = true
			})
		}),
		[],
	)

	useEffect(() => {
		const api = new FormsClient(props.packageId, props.packageVersionNumber)

		const abortController = new AbortController()
		api.GetFormStream(props.formId, abortController.signal).then((v) => {
			v.data.text().then((blobText) => {
				const formObject = JSON.parse(blobText)

				setFormData(observable(formObject as FormBuilderSchema))

				/*
				we want to save the object that we get in response as a JSON string, that way
				we can tell if anything has changed without having to do a deep equality search
				(it kinda is a deep equality search, but shut up) - Conner
				*/
				formDataRef.current = JSON.stringify(v.data)
			})
		})

		api.GetForm(props.formId, abortController.signal).then((v) => {
			setDetailForm(v.data)
		})

		return () => {
			abortController.abort()
		}
	}, [props.formId])

	const handleTabIndexChanged = useCallback(
		(event: React.SyntheticEvent, newValue: number) => {
			setSelectedIndex(newValue)
		},
		[],
	)

	const handleSchemaModified = useCallback((schema: FormBuilderSchema) => {
		setFormData(observable(schema))
	}, [])

	const handleSaveButtonClicked = useCallback(() => {
		if (formData === undefined || detailForm === undefined)
			throw Error('cannot save a null form')

		console.log(formDataRef.current)
		console.log(JSON.stringify(formData))

		const api = new FormsClient(props.packageId, props.packageVersionNumber)

		const fakeFile = new Blob([JSON.stringify(formData)], {
			type: 'application/json',
		})

		api
			.UpdateForm(props.formId, {
				name: detailForm.name,
				formData: fakeFile,
				metadata: {
					cellDefinitions: formData.definitions,
					cellOverrides: [], // overrides are always empty here since they're just for PDFs
					dynamicProperties: {},
				},
				formConfiguration: detailForm.formConfiguration,
			})
			.then((v) => {
				console.log('form updated successfully')
				toastService.displayToast({ message: 'Form updated', area: 'global' })
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error updating form',
					area: 'global',
				})
			})
	}, [formData, detailForm])

	if (formData === undefined || detailForm === undefined)
		return <FullscreenSpinner />

	if (!isObservable(formData)) console.error('NOT OBSERVABLE', formData)

	return (
		<div className={styles.hidden}>
			<AdministrationPageContainer
				title="Form Builder Designer"
				actions={
					<div>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleSaveButtonClicked}
							startIcon={<Save />}
						>
							Save Form
						</Button>
					</div>
				}
			>
				<div className={styles.formDesignerRoot}>
					<Tabs
						textColor="primary"
						indicatorColor="primary"
						value={selectedIndex}
						onChange={handleTabIndexChanged}
					>
						<Tab label="Design" />
						<Tab label="Style" />
						<Tab label="Preview" />
					</Tabs>
					<Divider />
					<div className={styles.contentSection}>
						{/* this is a good place for a demo, if you don't use the callback variant of
					schema modified it will break the state of everything below this, causing react
					to clobber, and all ancestor components to write to the DOM again */}
						{selectedIndex === 0 && (
							<FormBuilderDesigner
								schemaReference={formData}
								onSchemaModified={handleSchemaModified}
							/>
						)}
						{selectedIndex === 2 && <ViewerContainer schema={formData} />}
					</div>
				</div>
			</AdministrationPageContainer>
		</div>
	)
}

type ViewerContainerProps = {
	schema: FormBuilderSchema
}

const ViewerContainer = (props: ViewerContainerProps) => {
	const styles = useStyles()

	return (
		<div className={styles.paperContent}>
			<Paper className={styles.paperInset}>
				<SimpleFormBuilderViewer schema={props.schema} />
			</Paper>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	hidden: {
		height: percent(100),
		width: percent(100),
		overflow: 'hidden',
	},

	formDesignerRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
		overflow: 'hidden',
	},

	paperContent: {
		display: 'flex',
		justifyContent: 'center',

		height: percent(100),
		width: percent(100),

		marginBottom: theme.spacing(2),

		overflowY: 'hidden',
	},

	paperInset: {
		height: percent(100),
		padding: theme.spacing(2),
		width: px(1000),

		overflow: 'auto',
	},

	contentSection: {
		flex: 1,
		padding: theme.spacing(2, 0, 12, 0),

		height: '100%',
		width: '100%',
		overflow: 'hidden',
	},
}))

export default FormPageFormBuilderEditor
