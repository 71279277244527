import { AxiosResponse } from "axios";
import SecureClient from "../SecureClient";
import { ActivityTag, WorkflowDefinition } from "./DTOs";


export default class TagsClient extends SecureClient {

	public queryWorkflows = (tags: ActivityTag[]): Promise<AxiosResponse<WorkflowDefinition[]>> => {
		return this._api.post<WorkflowDefinition[]>(`tags/workflows/query`, tags, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}


}