import { Edit } from '@mui/icons-material'
import {
	Button,
	Checkbox,
	Fade,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback } from 'react'
import { BaseUser } from '../../../../../api/DTO'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import IconButtonLink from '../../../../../components/links/IconButtonLink'
import { makeChildRoute } from '../../../../../utils/CreateChildRoute'

type GroupUsersTableProps = {
	users: BaseUser[]
	onRemoveUsers: (userIds: number[]) => Promise<void>
	isRequesting: boolean
}

export const GroupUsersTable = observer(
	({ users, onRemoveUsers, isRequesting }: GroupUsersTableProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			selectedUsers: [] as number[],
		}))

		// select if unselected, unselect if selected
		const handleSelectOneUser = action((userId: number) => {
			if (!localStore.selectedUsers.includes(userId)) {
				localStore.selectedUsers.push(userId)
				return
			}
			const index = localStore.selectedUsers.indexOf(userId)
			if (index > -1) localStore.selectedUsers.splice(index, 1)
		})

		// select all if some are unselected, unselect all if all are already selected
		const handleSelectAllUsers = action(
			(event: React.SyntheticEvent<HTMLInputElement>) => {
				if (event.currentTarget.checked)
					localStore.selectedUsers = users.map((user) => user.id)
				else localStore.selectedUsers = []
			},
		)

		const userIsChecked = useCallback(
			(userId: number) => {
				return localStore.selectedUsers.includes(userId)
			},
			[localStore.selectedUsers],
		)

		// header checkbox is checked if all users are selected
		const headerCheckboxChecked = useCallback(() => {
			return (
				users.length === localStore.selectedUsers.length && users.length !== 0
			)
		}, [users, localStore.selectedUsers])

		// header checkbox is indeterminate if some users are selected
		const headerCheckboxIndeterminate = useCallback(() => {
			return (
				localStore.selectedUsers.length !== 0 &&
				users.length !== localStore.selectedUsers.length
			)
		}, [users, localStore.selectedUsers])

		const handleRemoveUsers = action(() => {
			onRemoveUsers(localStore.selectedUsers)
			localStore.selectedUsers = []
		})

		if (isRequesting) return <FullscreenSpinner />

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={headerCheckboxChecked()}
										indeterminate={headerCheckboxIndeterminate()}
										onChange={(event) => handleSelectAllUsers(event)}
									/>
								</TableCell>
								{localStore.selectedUsers.length === 0 ? (
									<>
										<TableCell>First Name</TableCell>
										<TableCell>Last Name</TableCell>
										<TableCell>Email</TableCell>
										<TableCell align="right">Actions</TableCell>
									</>
								) : (
									<>
										<TableCell>
											<Button
												color="primary"
												variant="outlined"
												disabled={isRequesting}
												onClick={handleRemoveUsers}
												size="small"
											>
												Remove
											</Button>
										</TableCell>
										<TableCell />
										<TableCell />
										<TableCell />
									</>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((user) => (
								<TableRow key={user.id}>
									<TableCell padding="checkbox">
										<Checkbox
											color="primary"
											checked={userIsChecked(user.id)}
											value={userIsChecked(user.id)}
											onChange={() => handleSelectOneUser(user.id)}
										/>
									</TableCell>
									<TableCell>{user.firstName}</TableCell>
									<TableCell>{user.lastName}</TableCell>
									<TableCell>{user.emailAddress}</TableCell>
									<TableCell align="right">
										<IconButtonLink
											to={makeChildRoute(
												`${user.id}`,
												`/_administration/_users`,
											)}
										>
											<Edit />
										</IconButtonLink>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		overflow: 'auto',
	},
}))
