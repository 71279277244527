import { AxiosResponse } from 'axios'
import { NIL, v4 as uuidv4 } from 'uuid'
import {
	BaseFormPackageInstanceModel,
	CommitIntent as CommitAction,
	FormIndex,
	FormPackageIndex,
	FormPackageInfo,
	FormType,
	SignatureStatus,
	SignatureValue,
	UpdatedPdfFormModel,
} from '../../api/DTOtemp'
import {
	FormPackageInstancesClient,
	FormsClient,
} from '../../api/clients/identity'
import { AnonymousFormPackageInstancesClient } from '../../api/clients/identity/AnonymousFormPackageInstancesClient'
import {
	ForwardedFormPackageWorkItemState,
	ReadWorkItemModel,
	WorkItemPermissions,
	WriteWorkItemModel,
} from '../../api/clients/workItems/DTOs'
import WorkItemsClient from '../../api/clients/workItems/WorkItemsClient'
import { FormBuilderCellDefinition } from '../../modules/FormBuilderCore/cells/FormBuilderCellDefinition'
import { FormBuilderCellInstance } from '../../modules/FormBuilderCore/cells/FormBuilderCellInstance'
import { instancesToObject as formBuilderInstancesToObject } from '../../modules/FormBuilderCore/utilities/instancesToObject'
import { FormHost } from '../../modules/FormHost/FormHost/FormHost'
import { CellType } from '../../modules/FormHost/Types/CellType'
import { UnifiedCellInstance } from '../../modules/FormHost/Types/UnifiedCellInstance'
import { HtmlPackageHostWrapper } from '../../modules/FormIntegrations/htmlFormHost/HtmlPackageHostWrapper'
import { HtmlCellDefinition } from '../../modules/FormIntegrations/htmlFormHost/Types/HtmlCellDefinition'
import { HtmlCellInstance } from '../../modules/FormIntegrations/htmlFormHost/Types/HtmlCellInstance'
import { instancesToObject as htmlInstancesToObject } from '../../modules/FormIntegrations/htmlFormHost/Utilities/instancesToObject'
import { PdfCellDefinition } from '../../modules/FormIntegrations/pdfFormHost/Types/PdfCellDefinition'
import { PdfCellInstance } from '../../modules/FormIntegrations/pdfFormHost/Types/PdfCellInstance'
import { instancesToObject as pdfInstancesToObject } from '../../modules/FormIntegrations/pdfFormHost/Utilities/instancesToObject'
import {
	PersistenceStatus,
	ResourceType,
	TrackedResource,
} from '../../pages/PackageHostPage/Attachments/AttachFilesDialog'
import { FormPackageSubmitResult } from '../../pages/PackageHostPage/PackageHostFooter.Submit'
import { RenderMode, form2pdf } from '../../utils/km-form2pdf'
import { SessionService } from '../session'
import { DetailFormWithData } from './FormPackageHandling'
import {
	CacheDetailForm,
	CacheFormPackage,
	InstanceCacheFormPackage,
	IntendedUsage,
	commitFormPackageToLocalDb,
	deleteFormPackage as deleteCachedFormPackage,
} from './FormPackageStorage'
import { getWorkItem, removeWorkItem } from './WorkItemHandling'

/*

A _lot_ has to happen in this function for this thing to work properly.
The absolute most important piece is that we place everything correctly into the cache
*/

export async function addPackageToCache(params: {
	intent: CommitAction

	formPackage: FormPackageInfo

	formHosts: FormHost[]
	forms: DetailFormWithData[]

	resources: TrackedResource[]

	savedInstanceId?: string
	savedInstanceUniqueId?: string

	description?: string
	workItem?: ReadWorkItemModel<ForwardedFormPackageWorkItemState>

	isPackageFromServer: boolean
}): Promise<[string, InstanceCacheFormPackage]> {
	const signedFields: string[] = []

	for (const formHost of params.formHosts) {
		/*
		We've gotta update the HTML to be the latest. The form builder
		is made up of definitions only so no need to do anything there,
		and the PDF can only be modified by the backend. That may not be
		available here, so HTML is all we care about.
		*/
		if (formHost.formType === 'Html') {
			const htmlFormHost = formHost as HtmlPackageHostWrapper

			const containerElement =
				htmlFormHost.formContainerElement as HTMLIFrameElement
			// I think this is an iframe, here's a check
			if (containerElement.nodeName !== 'HTML')
				throw new Error(
					'node was not html, type was ' + containerElement.nodeName,
				)
		}

		/*
		HTML & Form builder screenshots are available here, but PDFs
		have to reach back to the backend to get theirs. Do HTML & 
		Form builder here, PDFs later.
		*/
		if (formHost.formType === 'Html' || formHost.formType === 'FormBuilder') {
			const detailForm = params.forms.find((v) => v.id === formHost.formId)
			if (detailForm === undefined)
				throw new Error(
					'could not find configuration for form with id ' + formHost.formId,
				)

			if (formHost.formContainerElement !== null) {
				const formContainerElement =
					formHost.formType === FormType.Html
						? (formHost as HtmlPackageHostWrapper).iframeContainer
						: formHost.formContainerElement

				const mode =
					formHost.formType === FormType.Html
						? RenderMode.Html
						: RenderMode.FormBuilder

				const result = await form2pdf(
					formHost.formContainerElement,
					formContainerElement,
					mode,
				)
				const formBlob = result.output('blob')

				const existingResource = params.resources.find(
					(v) =>
						v.relationalId === detailForm.id.toString() &&
						v.type === ResourceType.FormImage,
				)
				const formName = `form-image-${detailForm.id}.pdf`

				if (existingResource !== undefined) {
					existingResource.file = new File([formBlob], formName)
					existingResource.persistenceStatus = PersistenceStatus.Updated
				} else {
					params.resources.push({
						type: ResourceType.FormImage,
						file: new File([formBlob], `form-image-${detailForm.id}.pdf`, {
							type: 'application/pdf',
						}),
						persistenceStatus: PersistenceStatus.New,
						resourceId: uuidv4(),
						relationalId: detailForm.id.toString(),
						packageAttachmentType: 'FormImages',
					})
				}
			}
		}

		const form = params.forms.find((v) => v.id === formHost.formId)

		if (form === undefined)
			throw new Error('form was not present in tracked collection')

		/* 
		We have to make sure that we're shipping over the most updated version of the HTML whenever we're
		sending to the server. This doesn't matter for form builder & PDF
		*/
		if (formHost.formType === FormType.Html) {
			const containerElement = formHost.formContainerElement
			if (containerElement === null)
				throw new Error('unable to download updated version of html form')

			const fileData = new File(
				[containerElement.outerHTML],
				`form-data-${form.id}`,
				{
					type: 'text/html',
				},
			)

			form.stream = fileData

			const existingAttachment = params.resources.find(
				(v) =>
					v.type === ResourceType.Form && v.relationalId === form.id.toString(),
			)
			if (existingAttachment !== undefined) {
				// it's not an update if it's already persisted
				if (
					existingAttachment.persistenceStatus === PersistenceStatus.Persisted
				)
					existingAttachment.persistenceStatus = PersistenceStatus.Updated
				existingAttachment.file = fileData
			} else {
				params.resources.push({
					file: fileData,
					persistenceStatus: PersistenceStatus.New,
					resourceId: uuidv4(),
					type: ResourceType.Form,
					relationalId: form.id.toString(),
					packageAttachmentType: 'FormResources',
				})
			}
		}

		// set all signatures statuses to tracked
		// if we're submitting/approving/rejecting we need to add the signatures to this instance
		if (params.intent !== CommitAction.Save) {
			const signatureCellInstances = formHost.cellInstances.filter(
				(v) =>
					v.type === CellType.Value &&
					isSignature(v.value) &&
					v.value.status === SignatureStatus.Untracked,
			)

			for (const signatureCellInstance of signatureCellInstances) {
				if (
					signatureCellInstance.type !== CellType.Value ||
					!isSignature(signatureCellInstance.value)
				)
					continue

				signedFields.push(signatureCellInstance.id)

				// now set to tracked since we've added to our signedFields list
				signatureCellInstance.value.status = SignatureStatus.Tracked
			}
		}
	}

	const cacheForms: CacheDetailForm[] = params.forms.map((v) => {
		const unifiedFormHosts = params.formHosts.find(
			(innerV) => innerV.formId === v.id,
		)
		if (unifiedFormHosts === undefined)
			throw new Error('could not find form host for form ' + v.id)

		const result: CacheDetailForm = {
			...v,
			type: unifiedFormHosts.formType,
			signedFields: signedFields,
			cellDefinitions: unifiedFormHosts.formHost.cellDefinitions,
			// ! this cast doesn't actually work - the value type is not the same in form builder
			cellInstances: unifiedFormHosts.formHost
				.cellInstances as UnifiedCellInstance[],
		}

		return result
	})

	const cachedFormPackage: CacheFormPackage = {
		...params.formPackage,
		packageVersion: params.formPackage,
		description: params.description,
		packageId: params.formPackage.id,
		action: params.intent,
		resources: params.resources,
		forms: cacheForms,
		workItem: params.workItem,
		savedInstanceId: params.savedInstanceId,
		uniqueInstanceId: params.savedInstanceUniqueId,
		isPackageFromServer: params.isPackageFromServer,
	}

	const key = params.savedInstanceUniqueId ?? uuidv4()

	const intendedUsage =
		params.intent === CommitAction.Save
			? IntendedUsage.Submission | IntendedUsage.Retrieval
			: IntendedUsage.Submission

	await commitFormPackageToLocalDb(cachedFormPackage, intendedUsage, key)

	return [key, cachedFormPackage]
}

export async function sendCachedPackageToServer(
	cachedPackage: CacheFormPackage,
	key: string,
	onSavePackage: (
		instance: BaseFormPackageInstanceModel,
		forms: DetailFormWithData[],
		isPackageFromServer: boolean,
		existingInstanceId?: number,
	) => Promise<number>,
): Promise<FormPackageSubmitResult> {
	console.log('cache work item pre send: ', cachedPackage.workItem)

	const formIndices: FormIndex[] = []

	for (const form of cachedPackage.forms) {
		const formContentType: FormType = (() => {
			if (form.contentType === 'application/json') return FormType.FormBuilder
			else if (form.contentType === 'text/html') return FormType.Html
			else if (form.contentType === 'application/pdf') return FormType.Pdf
			else
				throw new Error(
					'failed to determine form type for content type: ' + form.contentType,
				)
		})()

		const intermediateIndex: FormIndex = {
			formId: form.id,
			formName: form.name,
			type: formContentType,
			definitions: form.cellDefinitions,
			instances: form.cellInstances,
			values: getFormValues(form),
			signedFields: form.signedFields,
		}

		/*
		Because altering the PDF form is only available when online, this section
		has to be located here. While HTML & Form Builder forms are ready before here
		the pdf has not yet been altered. So a big piece is to talk to the server
		and get our new form.
		*/
		if (form.type === 'Pdf') {
			const updatedPdfForm = await getUpdatedPdfForm(
				cachedPackage.packageId,
				cachedPackage.versionNumber,
				form.id,
				{
					cellInstances: form.cellInstances as PdfCellInstance[],
					existingForm: await form.stream,
				},
			)

			const currentAttachment = cachedPackage.resources.find(
				(v) =>
					v.type === ResourceType.FormImage &&
					v.relationalId === form.id.toString(),
			)

			if (currentAttachment !== undefined) {
				currentAttachment.file = new File([updatedPdfForm], `${form.name}.pdf`)
				currentAttachment.persistenceStatus = PersistenceStatus.Updated
			} else {
				cachedPackage.resources.push({
					type: ResourceType.FormImage,
					persistenceStatus: PersistenceStatus.New,
					resourceId: uuidv4(),
					relationalId: form.id.toString(),
					file: new File([updatedPdfForm], `${form.name}.pdf`, {
						type: 'application/pdf',
					}),
					packageAttachmentType: 'FormImages',
				})
			}
		}

		formIndices.push(intermediateIndex)
	}

	if (cachedPackage.action === undefined)
		throw new Error('cached package was supplied without an action')

	const packageInstanceId = await prepareSavePackage({
		action: cachedPackage.action,
		resources: cachedPackage.resources,
		forms: cachedPackage.forms,
		packageId: cachedPackage.packageId,
		packageVersionId: cachedPackage.versionId,
		packageVersionNumber: cachedPackage.versionNumber,
		description: cachedPackage.description,

		savedInstanceId: cachedPackage.instanceId ?? 0,
		uniqueId: cachedPackage.uniqueInstanceId,
		workItem: cachedPackage.workItem,

		isPackageFromServer: cachedPackage.isPackageFromServer,
		onSavePackage: onSavePackage,
	})

	const sessionService = new SessionService()
	const instanceClient = sessionService.isLoggedIn
		? new FormPackageInstancesClient()
		: new AnonymousFormPackageInstancesClient()

	const resourcePromiseCollection: Promise<AxiosResponse>[] = []
	for (const resource of cachedPackage.resources) {
		// if (resource.persistenceStatus === PersistenceStatus.Persisted) continue

		const resourceAdditionPromise = instanceClient
			.addOrUpdateResource(packageInstanceId, resource)
			.then()

		resourcePromiseCollection.push(resourceAdditionPromise)
	}

	await Promise.all(resourcePromiseCollection)

	if (cachedPackage.action === undefined)
		throw new Error('cache package was provided without an action')

	await instanceClient.commitPackage(packageInstanceId, cachedPackage.action)

	console.log('committed package')
	console.log('cached package action: ', cachedPackage.action)

	if (
		cachedPackage.action === CommitAction.Approve ||
		cachedPackage.action === CommitAction.Reject
	) {
		// was this package obtained offline?
		if (
			(
				cachedPackage.workItem as WriteWorkItemModel<ForwardedFormPackageWorkItemState>
			)?.lockPassword === undefined
		) {
			// then try to get the lock

			try {
				const result = await getWorkItem(
					cachedPackage.workItem!.id,
					WorkItemPermissions.Write,
				)

				cachedPackage.workItem =
					result as WriteWorkItemModel<ForwardedFormPackageWorkItemState>
			} catch (error) {
				console.error(error)
			}
		}

		await completeWorkItem(cachedPackage)
	}

	await Promise.all(resourcePromiseCollection)

	await deleteCachedFormPackage(key)
	if (cachedPackage.workItem)
		removeWorkItem(cachedPackage.workItem.id.toString())

	return FormPackageSubmitResult.Submitted
}

const completeWorkItem = async (formPackage: CacheFormPackage) => {
	if (formPackage.workItem === undefined)
		throw new Error('work item is undefined')

	if (
		(
			formPackage.workItem as WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		).lockPassword === undefined
	)
		throw new Error('lease on work item was not acquired')

	const workItem =
		formPackage.workItem as WriteWorkItemModel<ForwardedFormPackageWorkItemState>

	const workItemsClient = new WorkItemsClient()
	const sessionService = new SessionService()

	if (workItem.lockPassword === undefined)
		throw new Error(
			'lock password was not present on work item - lease was not acquired',
		)

	await workItemsClient.completeWorkItem(formPackage.workItem.id, {
		// TODO - this is not guaranteed to be present - we have to guarantee it ourself
		lockPassword: workItem.lockPassword,
		outcome: formPackage.action === CommitAction.Approve ? 'Approve' : 'Reject',
		results: {
			formIndices: formPackage.forms.map((v) => ({
				definitions: v.cellDefinitions,
				instances: v.cellInstances,
				formId: v.id,
				formName: v.name,
				signedFields: v.signedFields,
				type: v.type,
				values: getFormValues(v),
			})),
			supportingDocumentIndices: formPackage.resources.map((v) => ({
				name: v.file.name,
				resourceId: v.resourceId,
				type: v.type,
			})),
		} as FormPackageIndex,
		userId: sessionService.authToken.id,
	})
}

export const getUpdatedPdfForm = async (
	packageId: number,
	packageVersionNumber: number,
	formId: number,
	formModel: UpdatedPdfFormModel,
): Promise<Blob> => {
	const formsClient = new FormsClient(packageId, packageVersionNumber)

	const response = await formsClient.GetUpdatedPdfForm(formId, formModel)

	return response.data
}

type SavePackageOptionsType = {
	action: CommitAction
	packageId: number
	packageVersionNumber: number
	packageVersionId: number
	resources: TrackedResource[]
	forms: CacheDetailForm[]
	isPackageFromServer: boolean

	onSavePackage: (
		instance: BaseFormPackageInstanceModel,
		forms: DetailFormWithData[],
		isPackageFromServer: boolean,
		existingInstanceId?: number,
	) => Promise<number>

	workItem?:
		| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		| ReadWorkItemModel<ForwardedFormPackageWorkItemState>
	description?: string
	savedInstanceId: number
	uniqueId?: string
}

const prepareSavePackage = async (
	options: SavePackageOptionsType,
): Promise<number> => {
	const formIndices: FormIndex[] = []

	for (const form of options.forms) {
		const formName = form?.name ?? 'Unnamed Form ' + form.id

		if (form === undefined) throw new Error('form data could not be found')

		const intermediateIndex: FormIndex = {
			formId: form.id,
			formName: formName,
			type: form.type,
			definitions: form.cellDefinitions,
			instances: form.cellInstances,
			values: {},
			signedFields: [],
		}

		// if we're submitting / approving / rejecting we need to add the signature to this instance
		if (options.action !== CommitAction.Save) {
			const signatureCellInstances = form.cellInstances.filter(
				(v) =>
					v.type === CellType.Value &&
					isSignature(v.value) &&
					v.value.status === SignatureStatus.Untracked,
			)

			for (const signatureInstance of signatureCellInstances) {
				if (
					signatureInstance.type !== CellType.Value ||
					!isSignature(signatureInstance.value)
				)
					continue

				intermediateIndex.signedFields.push(signatureInstance.id)
				signatureInstance.value.status = SignatureStatus.Tracked
			}
		}

		if (form.type === 'Html') {
			intermediateIndex.values = htmlInstancesToObject(
				form.cellDefinitions,
				form.cellInstances,
			)
		}
		if (form.type === FormType.FormBuilder) {
			intermediateIndex.values = formBuilderInstancesToObject(
				form.cellDefinitions as FormBuilderCellDefinition[],
				form.cellInstances as FormBuilderCellInstance[],
			)
		}
		if (form.type === 'Pdf') {
			intermediateIndex.values = pdfInstancesToObject(
				form.cellDefinitions as PdfCellDefinition[],
				form.cellInstances as PdfCellInstance[],
			)
		}

		formIndices.push(intermediateIndex)
	}

	const formPackageIndex: FormPackageIndex = {
		formIndices,
		supportingDocumentIndices: options.resources.map((v) => ({
			name: v.file.name,
			type: v.type,
			resourceId: v.resourceId,
			contentType: v.file.type,
			relationalId: v.relationalId,
			packageAttachmentType: v.packageAttachmentType,
		})),
	}

	const baseFormPackageIndex: BaseFormPackageInstanceModel = {
		id: options.savedInstanceId,
		uniqueId: options.uniqueId ?? NIL,
		packageId: options.packageId,
		versionId: options.packageVersionId,
		index: formPackageIndex,
		description: options.description,
		previousInstanceId: options.workItem?.workItemState.packageInstanceId,
		lifetimeId: options.workItem?.workItemState.lifetimeId,
	}

	const packageInstanceId = await options.onSavePackage(
		baseFormPackageIndex,
		options.forms,
		options.isPackageFromServer,
		options.savedInstanceId,
	)
	return packageInstanceId
}

/**
 * check if a value is a signature
 * @param value the value that we're checking to see if it's a signature
 * @returns true if the value is a signature, false otherwise
 */
const isSignature = (value: unknown): value is SignatureValue => {
	return !!value && (value as SignatureValue).image !== undefined
}

const getFormValues = (form: CacheDetailForm) => {
	if (form.type === 'Html')
		return htmlInstancesToObject(
			form.cellDefinitions as HtmlCellDefinition[],
			form.cellInstances as HtmlCellInstance[],
		)
	else if (form.type === 'FormBuilder')
		return formBuilderInstancesToObject(
			form.cellDefinitions as FormBuilderCellDefinition[],
			form.cellInstances as FormBuilderCellInstance[],
		)
	else if (form.type === 'Pdf')
		return pdfInstancesToObject(
			form.cellDefinitions as PdfCellDefinition[],
			form.cellInstances as PdfCellInstance[],
		)
	else throw new Error('could not retrieve values for form type ' + form.type)
}
