import { Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Edit from '@mui/icons-material/Edit'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { useRouteMatch } from 'react-router'
import { AssignmentType } from '../../../../../../../../api/clients/workItems/DTOs'
import { DetailForm } from '../../../../../../../../api/DTOtemp'
import UserChip from '../../../../../../../../components/chips/UserChip'
import IconButtonLink from '../../../../../../../../components/links/IconButtonLink'
import { usePrompts } from '../../../../../../../../services/notifications/PromptService'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'

type FormsManagementTableProps = {
	forms: DetailForm[]

	formOrderDirty?: boolean

	onFormMoved?: (forms: DetailForm[]) => void
	onFormDeleteClicked?: (formId: number) => void
	onFormPositionSaveClick?: (forms: DetailForm[]) => void
}

export const FormsManagementTable = observer(
	(props: FormsManagementTableProps) => {
		const styles = useStyles()

		const { url: currentRoute } = useRouteMatch()

		const promptService = usePrompts()

		const onFormMoved = (form: DetailForm, direction: 'earlier' | 'later') => {
			const forms = props.forms
			const targetIndex = forms.indexOf(form)

			if (targetIndex === -1)
				throw Error(
					'onFormMoved was passed a form which does not exist in form collection',
				)

			if (targetIndex === 0 && direction === 'earlier') return

			if (targetIndex === forms.length - 1 && direction === 'later') return

			console.log(
				'handling position change request with direction: ',
				direction,
			)

			const nextIndex =
				direction === 'earlier' ? targetIndex - 1 : targetIndex + 1

			const buffer = forms[nextIndex]
			buffer.ordinalPosition = targetIndex
			forms[targetIndex].ordinalPosition = nextIndex

			forms[nextIndex] = forms[targetIndex]
			forms[targetIndex] = buffer

			props.onFormMoved && props.onFormMoved(forms)
		}

		const handleSaveClick = useCallback(() => {
			props.onFormPositionSaveClick &&
				props.onFormPositionSaveClick(props.forms)
		}, [props.onFormPositionSaveClick])

		return (
			<>
				<Fade in>
					<TableContainer className={styles.tableContentRoot} component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Position</TableCell>
									<TableCell>Form Name</TableCell>
									<TableCell>Form Type</TableCell>
									<TableCell>Active Version</TableCell>
									<TableCell>Last Edit Date</TableCell>
									<TableCell>Edited By</TableCell>
									<TableCell align="right">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{props.forms.map((form, i) => (
									<TableRow key={form.id}>
										<TableCell>{form.ordinalPosition}</TableCell>
										<TableCell>{form.name}</TableCell>
										<TableCell>{form.contentType}</TableCell>
										<TableCell>{form.activeVersion}</TableCell>
										<TableCell>
											{new Date(form.lastModifiedDate).toLocaleString()}
										</TableCell>
										<TableCell>
											<UserChip
												assignmentType={AssignmentType.User}
												id={form.lastModifiedBy}
											/>
										</TableCell>
										<TableCell align="right">
											<IconButtonLink
												to={makeChildRoute(`_forms/${form.id}`, currentRoute)}
											>
												<Edit />
											</IconButtonLink>

											{props.onFormDeleteClicked && (
												<IconButton
													onClick={() => {
														//props.onFormDeleteClicked!(form.id)
														promptService
															.showDialog((props) => (
																<ConfirmDeleteFormPrompt
																	formName={form.name}
																	onCancel={() => props.close('cancel')}
																	onConfirm={() => props.close('okay')}
																/>
															))
															.then((v) => {
																if (v === 'okay')
																	props.onFormDeleteClicked!(form.id)
															})
													}}
													size="large"
												>
													<Delete />
												</IconButton>
											)}
											{props.onFormMoved && (
												<>
													<IconButton
														disabled={i === 0}
														onClick={() => onFormMoved(form, 'earlier')}
														size="large"
													>
														<KeyboardArrowUp />
													</IconButton>
													<IconButton
														disabled={i === props.forms.length - 1}
														onClick={() => onFormMoved(form, 'later')}
														size="large"
													>
														<KeyboardArrowDown />
													</IconButton>
												</>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Fade>
				{props.onFormPositionSaveClick && (
					<div className={clsx(styles.fullWidth, styles.lowerFlexBox)}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleSaveClick}
							disabled={!props.formOrderDirty}
							className={styles.noTextTransform}
						>
							Save New Positions
						</Button>
					</div>
				)}
			</>
		)
	},
)

type ConfirmDeleteFormPromptProps = {
	formName: string
	onConfirm: () => void
	onCancel: () => void
}

const ConfirmDeleteFormPrompt = (props: ConfirmDeleteFormPromptProps) => {
	return (
		<>
			<DialogTitle>Confirm Delete Form</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to delete <b>{props.formName}</b>?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button variant="contained" color="primary" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	fullWidth: {
		width: percent(100),
	},

	lowerFlexBox: {
		display: 'flex',
		flexDirection: 'row-reverse',

		padding: theme.spacing(2),
	},

	noTextTransform: {
		textTransform: 'none',
	},

	tableContentRoot: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'auto',
	},
}))
