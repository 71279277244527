import { Warning } from '@mui/icons-material'
import { Box, Divider, Fade, Paper, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
	DataProviderConfiguration,
	DataProviderInstance,
	DataProviderInstanceSettings,
} from '../../../../api/clients/dataProviders/DTO'
import DataProviderInstancesClient from '../../../../api/clients/dataProviders/DataProviderInstancesClient'
import DataProvidersClient from '../../../../api/clients/dataProviders/DataProvidersClient'
import { ClickToCopyButton } from '../../../../components/buttons/ClickToCopyButton'
import DescriptiveSwitch from '../../../../components/inputs/DescriptiveSwitch'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../../../../modules/FormBuilderInterop/Runtimes/Standalone'
import { FixedHeader } from '../../../../utils/HOC/FixedHeaders'

type ConfigureDataProviderProps = {
	instanceProperties: Record<string, unknown> | undefined
	settings: DataProviderInstanceSettings
}

export const ConfigureDataProvider = observer(
	(props: ConfigureDataProviderProps) => {
		const styles = useStyles()
		const { instanceId } = useParams<{ instanceId: string }>()

		const instancesClient = new DataProviderInstancesClient()

		const providersClient = new DataProvidersClient()

		const [instance, setInstance] = useState<DataProviderInstance>()
		const [dataProvider, setDataProvider] =
			useState<DataProviderConfiguration>()

		const cellManager = useMemo(() => {
			if (
				instance !== undefined &&
				dataProvider !== undefined &&
				props.instanceProperties !== undefined
			) {
				return new StandaloneCellManager(
					dataProvider.formBuilderSchema,
					props.instanceProperties,
				)
			}
		}, [instance, dataProvider])

		const getInstance = async () => {
			const { data } = await instancesClient.getInstance(instanceId)
			const instance = data
			setInstance(instance)

			const response = await providersClient.getDataProvider(
				instance.dataProviderId,
			)
			setDataProvider(response.data)
		}

		useEffect(() => {
			getInstance()
		}, [])

		return (
			<Fade in unmountOnExit>
				<Paper className={styles.insetSpacing}>
					<Box display="flex" flexDirection="column" gap={2}>
						<FixedHeader
							label="Data Provider ID"
							className={styles.instanceIdSection}
						>
							<div className={styles.copyInstanceId}>
								<Typography>{instanceId}</Typography>
								<ClickToCopyButton value={instanceId} />
							</div>
						</FixedHeader>
						{cellManager !== undefined && (
							<FormBuilderViewer cellManager={cellManager} />
						)}
						<Divider />
						<Box display="flex" flexDirection="column" gap={1}>
							<Typography variant="subtitle1">Settings</Typography>
							<DescriptiveSwitch
								headerLabel="Anonymous Access"
								bodyLabel={
									<Box display="flex" flexDirection="column" gap={0.5}>
										<Typography variant="body2" color="textSecondary">
											Allow this data provider to be executed by users who are
											not logged in
										</Typography>
										{props.settings.anonymousAccess && (
											<Box
												display="flex"
												flexDirection="row"
												alignItems="center"
												gap={0.5}
											>
												<Warning fontSize="small" color="error" />
												<Typography variant="body2" color="error">
													Warning: This allows any user to make unlimited calls
													to this data provider. Make sure no private
													information is present and that this data provider is
													not subject to injection attacks.
												</Typography>
											</Box>
										)}
									</Box>
								}
								checked={props.settings.anonymousAccess}
								onChange={() => {
									props.settings.anonymousAccess =
										!props.settings.anonymousAccess
								}}
							/>
						</Box>
					</Box>
				</Paper>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(0, 2),
		width: percent(100),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	bottomFlexbox: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},

	noTextTransform: {
		textTransform: 'none',
		marginLeft: 'auto',
	},

	formSettingsRoot: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	formElement: {
		padding: theme.spacing(1, 2),
	},

	instanceIdSection: {
		padding: theme.spacing(2, 0),
		display: 'flex',
		flexDirection: 'column',
	},

	copyInstanceId: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}))
