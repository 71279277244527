import { Box, TextField } from '@mui/material'
import { Literal } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import useColorScheme from '../../internal/useColorScheme'

const StringLiteralComponent = observer((props: RendererComponent<Literal>) => {
	const [color, contrastColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<Box bgcolor={contrastColor} padding={1}>
			<TextField
				sx={{ input: { color: color } }}
				variant="standard"
				value={props.node.value?.toString() ?? ''}
				onChange={action((evt) => {
					props.node.value = evt.currentTarget.value
				})}
			/>
		</Box>
	)
})

export default StringLiteralComponent
