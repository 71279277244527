import { AxiosResponse } from "axios";
import { SessionService } from "../../../services/session";
import ClientBase from "../ClientBase";
import { AuthorizeHeader } from "../SecureClient";

export class LicensingClient extends ClientBase {
	private _urlBase: string

	protected readonly _sessionService = new SessionService()
	private readonly AuthorizationHeaderName: string = "authorization"

	// authorization header is not required, but if we have it, use it to get user info!
	protected get authorizationHeader(): AuthorizeHeader | undefined {
		if (this._sessionService.authToken.rawToken)
			return { Authorization: this._sessionService.authToken.rawToken }
	}

	constructor() {
		super();
		this._urlBase = `Licensing`

		this._api.interceptors.response.use(config => {
			if (config.headers[this.AuthorizationHeaderName])
				this._sessionService.authToken.rawToken = config.headers[this.AuthorizationHeaderName]

			return config
		})
	}

	public GetLicense(): Promise<AxiosResponse<LicenseResponse>> {

		return this._api.get<LicenseResponse>(this._urlBase, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		});
	}

	public SetLicense(licenseKey: string): Promise<AxiosResponse<LicenseResponse>> {

		const formData = new FormData();

		formData.set('licenseKey', licenseKey)

		return this._api.post(this._urlBase, formData, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader()
			}
		})
	}
}

export type LicenseResponse = {
	modules: Record<string, Module | undefined>,
	licenseId: string
}

export type Module = SubscriptionModule

export type SubscriptionModule = {
	type: 'Subscription',
	startDate: string,
	expirationDate: string,
	additionalDetails: string
}