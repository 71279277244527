import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { OrganizationPropertiesPage } from './OrganizationPropertiesPage'
import { OrganizationPropertyPage } from './OrganizationPropertyPage'

export const OrganizationPropertiesPageRouter = () => {
	const { path } = useRouteMatch()

	updateBreadcrumbs(
		`/_administration/_organization-properties`,
		'Organization Properties',
	)

	return (
		<Switch>
			<Route path={makeChildRoute(':propertyId', path)}>
				<OrganizationPropertyPage />
			</Route>
			<Route path={makeChildRoute('', path)}>
				<OrganizationPropertiesPage />
			</Route>
		</Switch>
	)
}
