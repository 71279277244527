import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { getDependencyTree, isObservable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { NIL } from 'uuid'
import { FormBuilderSchema } from '../../../FormBuilderCore/Types'
import { renderCells } from '../../../FormBuilderCore/cells/rendering/cellRenderer'
import { FormBuilderContextProvider } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { normalizeDefinitionArray } from '../../../FormBuilderCore/cells/rendering/normalizeDefinitions'
import { getGlobalComponentRegistry } from '../../ComponentRegistry/GlobalComponentRegistry'
import { DragAndDropCellWrapper } from './DragAndDrop'
import {
	FormBuilderDragAndDropContextProvider,
	useFormBuilderDragAndDropContext,
} from './DragAndDrop/DragAndDropContext'
import {
	InsertDustbinComponent,
	NullDustbinComponent,
} from './DragAndDrop/Dustbins'
import { DraggableFormHost } from './DraggableFormHost'
import { PropertyEditor } from './Sidebar/ComponentEditorPanel'
import ComponentSourcePanel from './Sidebar/ComponentSourcePanel'

type FormBuilderDesignerProps = {
	schemaReference: FormBuilderSchema

	onSchemaModified: (schema: FormBuilderSchema) => void
}

export const FormBuilderDesigner = observer(
	({ schemaReference, onSchemaModified }: FormBuilderDesignerProps) => {
		const styles = useStyles()

		console.log('evaluating schema')

		const observableSchema = useMemo(() => {
			console.log('recomputing memo')
			if (!isObservable(schemaReference))
				throw new Error('schema reference must be observable')

			return schemaReference
		}, [schemaReference])

		return (
			<div className={styles.layoutStyle}>
				<FormBuilderDragAndDropContextProvider
					currentlySelectedDefinition={NIL}
					schema={observableSchema}
				>
					<FormBuilderDesignerInternal onSchemaModified={onSchemaModified} />
				</FormBuilderDragAndDropContextProvider>
			</div>
		)
	},
)

type FormBuilderDesignerInternalProps = {
	onSchemaModified: (schema: FormBuilderSchema) => void
}

const FormBuilderDesignerInternal = ({
	onSchemaModified,
}: FormBuilderDesignerInternalProps) => {
	const styles = useStyles()

	const { componentCollection } = getGlobalComponentRegistry()
	const { schema } = useFormBuilderDragAndDropContext()

	const formHost = useMemo(() => {
		schema.definitions = normalizeDefinitionArray(
			componentCollection,
			schema.definitions,
		)

		return new DraggableFormHost(componentCollection, schema, (v) => {
			console.log('schema modified!')
			schema.definitions = normalizeDefinitionArray(
				componentCollection,
				v.definitions,
			)
			onSchemaModified(schema)
		})
	}, [])

	return (
		<DndProvider backend={HTML5Backend}>
			<FormBuilderContextProvider cellManager={formHost}>
				<div className={styles.formBuilderPropertiesSection}>
					<ComponentSourcePanel />
				</div>

				<div className={styles.viewerSection}>
					<FormBuilderViewerSection host={formHost} />
				</div>

				<div className={styles.formBuilderPropertiesSection}>
					<PropertyEditor schema={schema} onSchemaModified={onSchemaModified} />
				</div>
			</FormBuilderContextProvider>
		</DndProvider>
	)
}

type FormBuilderViewerProps = {
	host: DraggableFormHost
}

const FormBuilderViewerSection = observer(
	({ host: formHost }: FormBuilderViewerProps) => {
		const { componentCollection } = getGlobalComponentRegistry()

		const [elements, setElements] = useState<JSX.Element[]>([])

		useEffect(() => {
			const disposer = reaction(
				() => formHost.cellInstances,
				() => {
					/**
					 * !this is a huge issue
					 * this thing will cause an entire re-render because the cellInstances
					 * array is not re-used when one of the inner elements is modified
					 *
					 * once the cell instances are recomputed their ids, and thus their keys
					 * are all new. This means that react thinks it needs to do the work to
					 * rebuild all of the child elements
					 *
					 * performance then goes to crap because of that
					 */

					// mobx spy for events except error, remove, delete, splice and report-end
					// spy((event) => {
					// 	if (event.type === 'action') {
					// 		console.log(
					// 			`MOBX SPY: ${event.type} ${event.name} with args: ${event.arguments} made on object ${event.object}`,
					// 		)
					// 	} else if (event.type === 'scheduled-reaction') {
					// 		console.log(`MOBX SPY: ${event.type} ${event.name}`)
					// 	} else if (event.type === 'reaction') {
					// 		console.log(`MOBX SPY: ${event.type} ${event.name}`)
					// 	} else if (event.type === 'update') {
					// 		console.log(
					// 			`MOBX SPY: ${event.type} ${event.object}: ${event.oldValue} to ${event.newValue}`,
					// 		)
					// 	} else if (event.type === 'add') {
					// 		console.log(
					// 			`MOBX SPY: ${event.type} ${event.object} with name: ${
					// 				event.debugObjectName
					// 			} with value: ${event.newValue ?? ''}`, // this line causes errors for some reason
					// 		)
					// 	}
					// })
					normalizeDefinitionArray(
						componentCollection,
						formHost.cellDefinitions,
					)

					const instances = formHost.cellInstances.filter(
						(v) => v.parentId === NIL,
					)

					const cells = renderCells(
						{
							componentRegistry: componentCollection,
							formHost: formHost,
							CellWrapper: DragAndDropCellWrapper,
							NullCellNode: NullDustbinComponent,
						},
						formHost.rootNode.children,
					)

					setElements(cells)
				},
				{
					fireImmediately: true,
				},
			)

			const dependencyTree = getDependencyTree(disposer)
			console.log('dependency tree: ', dependencyTree)

			return () => disposer()
		}, [formHost])

		return (
			<Fragment>
				{elements.map((v, i) => (
					<Fragment key={v.key}>
						<InsertDustbinComponent
							formHost={formHost}
							insertionIndex={i}
							parentDefinitionId={NIL}
						/>
						{v}
					</Fragment>
				))}

				<InsertDustbinComponent
					formHost={formHost}
					insertionIndex={elements.length}
					parentDefinitionId={NIL}
				/>
			</Fragment>
		)
	},
)

// TODO replace with media query
const panelWidth = 384

const useStyles = makeStyles((theme: Theme) => ({
	layoutStyle: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row-reverse',
		height: '100%',
		width: '100%',
		overflowY: 'hidden',
	},

	formBuilderFieldsSection: {
		height: percent(100),
		width: px(panelWidth),
		display: 'flex',
		overflow: 'auto',
	},

	formBuilderPropertiesSection: {
		height: percent(100),
		width: px(panelWidth),
		overflow: 'auto',
	},

	viewerSection: {
		flex: '1',
		overflowY: 'auto',
		padding: theme.spacing(1),
		margin: theme.spacing(0, 2, 1, 2),
		backgroundColor: theme.palette.background.paper,
		borderRadius: theme.spacing(1),
	},
}))
