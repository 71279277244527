import { Divider, Tab, Tabs, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { observer } from 'mobx-react'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { BaseUser } from '../../../../../api/DTO'
import { AccessStatus } from '../../../../../api/DTOtemp'
import { CurrentOrganizationUsersClient } from '../../../../../api/clients/identity/CurrentOrganizationUsersClient'
import UserClient from '../../../../../api/clients/identity/UserClient'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import { toastService } from '../../../../../services/notifications/ToastService'
import { SessionService } from '../../../../../services/session'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import UserDetailsSection from './Sections/UserDetailsSection'
import { UserPageCard } from './Sections/UserPageCard'
import { UserPasswordSection } from './Sections/UserPasswordSection'
import UserProfileSection from './Sections/UserProfileSection'
import { UserPropertiesSection } from './Sections/UserPropertiesSection'

const UserPage = observer(() => {
	return (
		<AdministrationPageContainer title="Account">
			<UserProfileEdit notAdmin={false} />
		</AdministrationPageContainer>
	)
})

export const UserProfileEdit = (props: { notAdmin: boolean }) => {
	const styles = useStyles()
	console.log(styles.userPageRoot)

	const params = useParams<{ userId: string }>()

	const userId = parseInt(params.userId)
	if (isNaN(userId)) throw new Error('invalid user id')

	const orgUsersClient = new CurrentOrganizationUsersClient()
	const userClient = new UserClient()

	const [user, setUser] = useState<BaseUser>()
	const [accessStatus, setAccessStatus] = useState<AccessStatus>(1)
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)

	const handleTabIndexChanged = useCallback(
		(event: SyntheticEvent, newValue: number) => {
			setSelectedTabIndex(newValue)
		},
		[],
	)

	const sessionService = new SessionService()
	const isCurrentUser = sessionService.authToken.id === user?.id

	const onUpdateAssociation = (newAccessStatus: AccessStatus) => {
		orgUsersClient
			.updateAssociationForCurrentOrganization({
				userId: userId,
				accessStatus: newAccessStatus,
			})
			.then(() => {
				setAccessStatus(newAccessStatus)
				toastService.displayToast({
					message: 'User updated',
					area: 'global',
					severity: 'success',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error updating user',
					area: 'global',
					severity: 'error',
				})
			})
	}

	const tabSelector = (user: BaseUser) => {
		if (selectedTabIndex === 0) {
			return isCurrentUser ? (
				<CurrentUserDashboard user={user} handleUpdateUser={handleUpdateUser} />
			) : (
				<UserDashboard
					user={user}
					accessStatus={accessStatus}
					onUpdateAssociation={onUpdateAssociation}
				/>
			)
		}
		if (selectedTabIndex === 1) return <div></div>
		throw new Error(
			`the selected tab index ${selectedTabIndex} is out of bounds`,
		)
	}

	useEffect(() => {
		orgUsersClient.getUsersForCurrentOrganization(userId).then((response) => {
			setUser(response.data[0].user)
			setAccessStatus(response.data[0].accessStatus)
		})
	}, [])

	const handleUpdateUser = async (user: BaseUser): Promise<void> => {
		userClient
			.UpdateUser(user)
			.then(() => {
				setUser(user)
				toastService.displayToast({ message: 'User updated', area: 'global' })
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error updating user',
					area: 'global',
				})
			})
	}

	if (user === undefined) return <FullscreenSpinner />

	return (
		<div className={styles.userPageRoot}>
			<Tabs
				value={selectedTabIndex}
				indicatorColor="primary"
				textColor="primary"
				onChange={handleTabIndexChanged}
			>
				<Tab label="General" />
				<Tab label="Notifications" />
			</Tabs>
			<Divider />
			{tabSelector(user)}
		</div>
	)
}

type CurrentUserDashboardProps = {
	user: BaseUser
	handleUpdateUser: (user: BaseUser) => Promise<void>
}

const CurrentUserDashboard = ({
	user,
	handleUpdateUser,
}: CurrentUserDashboardProps) => {
	const styles = useStyles()

	return (
		<div className={styles.contentSection}>
			<div className={styles.userPageCard}>
				<UserPageCard user={user} isCurrentUser={true} />
				<UserPasswordSection />
			</div>
			<div className={styles.userPageRest}>
				<UserProfileSection user={user} onUpdateUser={handleUpdateUser} />
				<div className={styles.userPropertiesSection}>
					<UserPropertiesSection user={user} />
				</div>
			</div>
		</div>
	)
}

type UserDashboardProps = {
	user: BaseUser
	accessStatus: AccessStatus
	onUpdateAssociation: (newAccessStatus: AccessStatus) => void
}

// for viewing other users that are not yourself
const UserDashboard = ({
	user,
	accessStatus,
	onUpdateAssociation,
}: UserDashboardProps) => {
	const styles = useStyles()

	return (
		<div className={styles.contentSection}>
			<div className={styles.userPageCard}>
				<UserPageCard user={user} isCurrentUser={false} />
				<UserPropertiesSection user={user} />
			</div>
			<div className={styles.userPageRest}>
				<UserDetailsSection
					user={user}
					accessStatus={accessStatus}
					onUpdateAssociation={onUpdateAssociation}
				/>
			</div>
		</div>
	)
}

export default UserPage

const useStyles = makeStyles((theme: Theme) => ({
	userPageRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: percent(100),
	},

	contentSection: {
		display: 'flex',
		flexWrap: 'wrap',

		width: percent(100),
		height: 'fit-content',

		margin: theme.spacing(3, 0),
	},

	userPageCard: {
		display: 'flex',

		height: 'fit-content',

		flexDirection: 'column',

		gap: theme.spacing(3),

		[theme.breakpoints.up('md')]: {
			width: percent(33),
		},

		[theme.breakpoints.down('md')]: {
			width: percent(100),
		},

		marginBottom: theme.spacing(3),
	},

	userPropertiesSection: {
		display: 'flex',
		flex: 1,

		height: 'fit-content',
		width: percent(100),
	},

	userPageRest: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		gap: theme.spacing(3),

		[theme.breakpoints.up('md')]: {
			marginLeft: theme.spacing(3),
		},

		[theme.breakpoints.down('md')]: {
			marginTop: theme.spacing(3),
		},

		marginBottom: theme.spacing(3),
	},
}))
