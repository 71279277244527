import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { updateBreadcrumbs } from '../../Breadcrumbs'
import FormPackagePage from './FormPackagePage'
import { FormPackagesPage } from './FormPackagesPage'

export const FormPackagesPageRouter = () => {
	const { path } = useRouteMatch()

	const makeChildRoute = (ancestorPath = '') => {
		return `${path}/${ancestorPath}`
	}

	updateBreadcrumbs(`/_administration/_form-packages`, 'Form Packages')

	return (
		<Switch>
			<Route path={makeChildRoute(':packageId')}>
				<FormPackagePage />
			</Route>

			<Route path={makeChildRoute()}>
				<FormPackagesPage />
			</Route>
		</Switch>
	)
}
