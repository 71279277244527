import { Check, Delete, Edit } from '@mui/icons-material'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import { FormPackageAttachmentType } from '../../../../../../../../api/DTOtemp'
import { useModals } from '../../../../../../../../services/notifications/ModalService'
import { usePrompts } from '../../../../../../../../services/notifications/PromptService'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../../AdministrationPageTabs'
import { FormPackageAdministrationPageProps } from '../../FormPackagePage'
import { useFormPackageContext } from '../../FormPackagePageContext'
import { EditAttachmentTypeDialog } from './EditAttachmentTypeDialog'

export const AttachmentTypesAdministrationPage = observer(
	(props: FormPackageAdministrationPageProps) => {
		const styles = useStyles()
		const modalService = useModals()

		const { formPackage } = useFormPackageContext()
		const formPackageClient = new FormPackageVersionClient(props.packageId)

		const localStore = useLocalObservable(() => ({
			attachmentTypes: formPackage.configuration.attachmentTypes,
		}))

		const handleAddAttachmentType = async (
			attachmentType: FormPackageAttachmentType,
		) => {
			runInAction(() => {
				formPackage.configuration.attachmentTypes.push(attachmentType)
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)

				toastService.displayToast({
					message: 'Attachment type added',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error adding attachment type',
					area: 'global',
				})
			}
			return
		}

		const handleEditAttachmentType = async (
			originalName: string,
			attachmentType: FormPackageAttachmentType,
		) => {
			const indexToUpdate = formPackage.configuration.attachmentTypes.findIndex(
				(v) => v.name === originalName,
			)
			if (indexToUpdate < 0)
				throw new Error(
					`could not find attachment type with name ${originalName} to update`,
				)

			runInAction(() => {
				formPackage.configuration.attachmentTypes[indexToUpdate] =
					attachmentType
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)

				toastService.displayToast({
					message: 'Attachment type updated',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error updating attachment type',
					area: 'global',
				})
			}
		}

		const handleDeleteAttachmentType = async (name: string) => {
			const indexToRemove = formPackage.configuration.attachmentTypes.findIndex(
				(v) => v.name === name,
			)
			if (indexToRemove < 0) return // don't worry about deleting if we can't find it anyways

			runInAction(() => {
				formPackage.configuration.attachmentTypes.splice(indexToRemove, 1)
			})

			try {
				await formPackageClient.UpdateConfiguration(formPackage)

				toastService.displayToast({
					message: 'Attachment type removed',
					area: 'global',
				})
			} catch (error) {
				console.log(error)
				toastService.displayToast({
					message: 'Error removing attachment type',
					area: 'global',
				})
			}
		}

		return (
			<AdministrationPageContainer
				title="Form Package Attachment Types"
				actions={
					<div>
						<Button
							variant="contained"
							startIcon={<Edit />}
							onClick={() => {
								modalService
									.showForm((modalProps) => (
										<EditAttachmentTypeDialog
											existingNames={localStore.attachmentTypes.map(
												(v) => v.name,
											)}
											onConfirm={(v) =>
												modalProps.close({
													closeResult: 'okay',
													value: v,
												})
											}
											onCancel={() =>
												modalProps.close({
													closeResult: 'cancel',
												})
											}
										/>
									))
									.then((v) => {
										if (v.closeResult === 'okay')
											handleAddAttachmentType(
												v.value as FormPackageAttachmentType,
											)
									})
							}}
						>
							Add Attachment Type
						</Button>
					</div>
				}
			>
				<div className={styles.formContentRoot}>
					<AdministrationPageTabs
						currentTab={7}
						onTabChanged={props.onTabChanged}
						tabLabels={props.tabLabels}
					/>
					<Divider />
					<AttachmentTypesSection
						attachmentTypes={localStore.attachmentTypes}
						onEditAttachmentType={handleEditAttachmentType}
						onDeleteAttachmentType={handleDeleteAttachmentType}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type AttachmentTypesSectionProps = {
	attachmentTypes: FormPackageAttachmentType[]
	onEditAttachmentType: (
		originalName: string,
		attachmentType: FormPackageAttachmentType,
	) => void
	onDeleteAttachmentType: (name: string) => void
}

const AttachmentTypesSection = observer(
	(props: AttachmentTypesSectionProps) => {
		const styles = useStyles()
		const promptService = usePrompts()
		const modalService = useModals()

		return (
			<Fade in unmountOnExit>
				<TableContainer className={styles.tableContainer} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Description</TableCell>
								<TableCell>Required</TableCell>
								<TableCell>Max Attachment Count</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.attachmentTypes
								.filter((v) => v.name !== 'General Attachments')
								.map((attachmentType) => (
									<TableRow key={attachmentType.name}>
										<TableCell>{attachmentType.name}</TableCell>
										<TableCell>{attachmentType.description}</TableCell>
										<TableCell>
											{attachmentType.required && <Check />}
										</TableCell>
										<TableCell>
											{attachmentType.maxAttachmentCount ?? 'No Max'}
										</TableCell>
										<TableCell align="right">
											<div>
												<IconButton
													onClick={() =>
														modalService
															.showForm((modalProps) => (
																<EditAttachmentTypeDialog
																	existingNames={props.attachmentTypes.map(
																		(v) => v.name,
																	)}
																	attachmentType={attachmentType}
																	onConfirm={(v) =>
																		modalProps.close({
																			closeResult: 'okay',
																			value: v,
																		})
																	}
																	onCancel={() =>
																		modalProps.close({
																			closeResult: 'cancel',
																		})
																	}
																/>
															))
															.then((v) => {
																if (v.closeResult === 'okay')
																	props.onEditAttachmentType(
																		attachmentType.name,
																		v.value as FormPackageAttachmentType,
																	)
															})
													}
												>
													<Edit />
												</IconButton>
												<IconButton
													onClick={() =>
														promptService
															.showDialog((props) => (
																<DeleteAttachmentTypePrompt
																	attachmentTypeName={attachmentType.name}
																	onCancel={() => props.close('cancel')}
																	onConfirm={() => props.close('okay')}
																/>
															))
															.then((response) => {
																if (response === 'okay')
																	props.onDeleteAttachmentType(
																		attachmentType.name,
																	)
															})
													}
												>
													<Delete />
												</IconButton>
											</div>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

type DeleteAttachmentTypePromptProps = {
	attachmentTypeName: string
	onConfirm: () => void
	onCancel: () => void
}

const DeleteAttachmentTypePrompt = (props: DeleteAttachmentTypePromptProps) => {
	return (
		<>
			<DialogTitle>Confirm Delete Attachment Type</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to remove the{' '}
					<b>{props.attachmentTypeName}</b> attachment type from the form
					package?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},

	tableContainer: {
		margin: theme.spacing(2, 0, 0, 0),
		overflow: 'hidden',
	},
}))
