/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, computed, makeObservable } from "mobx";
import SessionData, { FormsJwtAccessToken, FormsJwtBody, TokenService, WindowId } from "./SessionData";

// 1000 ms -> 60s -> 10m
const pollingPeriod = 1000 * 60 * 10

// if another service is polling we use this to avoid duplicating 
// the requests 
// 1000 ms -> 60s -> 11m
const delayedPollingPeriod = 1000 * 60 * 11

// if we're loading the service then use a quick 1s refresh
// if we don't do this then the history service instantiates
// and requests a value from us before we're done
const quickRefreshDelay = 1000

type StorageEntry<T> = {

	sourceId: number
	value: T

}

export class SessionService {

	// current time in nanoseconds, used to register our id for other tabs
	// https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
	public static readonly WindowId = window.performance?.now() ?? 0

	private static instance: SessionService | null

	public static getInstance(): SessionService {
		if (SessionService.instance == null)
			SessionService.instance = new this()
		return SessionService.instance
	}

	get authToken() {
		return SessionData.authToken
	}

	constructor() {
		makeObservable<SessionService>(this, {
			isLoggedIn: computed,
			isAnonymous: computed,
			logout: action,
		});

		this.registerTokenListeners()
	}

	/**
	 * TODO make sure you validate the token lifetime
	 * whether or not the current session is authenticated
	 */
	public get isLoggedIn(): boolean {
		return !!SessionData.authToken?.rawToken && !SessionData.authToken.expired
	}

	public get isAnonymous(): boolean {
		// todo this needs to be a special validation check
		// return if signed in or not for the time being
		return !!SessionData.authToken?.rawToken
	}

	public logout(): void {
		SessionData.authToken.logout()
	}

	private registerTokenListeners(): void {
		// TODO this needs to return a function that wraps `this` as a closure

		const tokenUpdatedEvent = (e: StorageEvent): void => {

			let tokenService: TokenService<FormsJwtBody> | null

			if (e.key == FormsJwtAccessToken.tokenName)
				tokenService = SessionData.authToken
			else {
				return
			}

			const storageValue = e.newValue
				? JSON.parse(e.newValue) as StorageEntry<string>
				: null

			SessionService.updateFormsToken(tokenService, storageValue)
		}

		window.addEventListener('storage', tokenUpdatedEvent, false)
	}

	private static updateFormsToken(token: TokenService<FormsJwtBody>,
		storedValue: StorageEntry<string> | null): void {
		// if we are the same then we want to make sure that the polling
		// period is reduced

		console.log(`received update event from ${storedValue?.sourceId} current ${WindowId} `)

		if (WindowId == storedValue?.sourceId ?? 0) {
			token.setPollingPeriod(pollingPeriod)
			return
		}

		console.log('token updated from external provider')

		token.setPollingPeriod(delayedPollingPeriod)
		// if we don't have a value that's akin to logging out
		token.setToken(storedValue?.value ?? "")
	}

}


