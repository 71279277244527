import { useLocalObservable } from 'mobx-react'
import React, { createContext, PropsWithChildren } from 'react'
import ActivityConfigurationStore from './ActivityConfigurationStore'
import WorkflowContext from './WorkflowContext'

function createWorkflowStore(
	workflowId: number,
	definitionId: number | undefined,
	instanceId: number | undefined,
) {
	return {
		workflowContext: new WorkflowContext(workflowId, definitionId, instanceId),
		activityConfigurationStore: new ActivityConfigurationStore(),
	}
}

type WorkflowStoreType = ReturnType<typeof createWorkflowStore>
const workflowStoreContext = createContext<WorkflowStoreType | undefined>(
	undefined,
)

type WorkflowStoreProviderProps = {
	workflowId: number
	definitionId?: number
	instanceId?: number
}

export const WorkflowStoreProvider = ({
	children,
	workflowId,
	definitionId,
	instanceId,
}: PropsWithChildren<WorkflowStoreProviderProps>) => {
	const store = useLocalObservable(() =>
		createWorkflowStore(workflowId, definitionId, instanceId),
	)
	return (
		<workflowStoreContext.Provider value={store}>
			{' '}
			{children}{' '}
		</workflowStoreContext.Provider>
	)
}

export const useWorkflowStore = () => {
	const store = React.useContext(workflowStoreContext)

	if (!store) throw 'useStore must be used within a store provider'

	return store
}
