import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { PropsWithChildren, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { ComponentWrapper } from '../../../../../FormBuilderCore/cells/rendering/contexts/ContextTypes'
import { DraggableFormHost } from '../../DraggableFormHost'
import { isDraggableComponent, isDraggableDefinition } from '../../Types'
import { DraggableItemType } from '../../Types/DraggableItemType'
import { useFormBuilderDragAndDropContext } from '../DragAndDropContext'
import { useGeneralStyles } from './General'
import { isRecursiveCycle } from './Utils'

export const DustbinComponent = observer(
	(props: PropsWithChildren<ComponentWrapper>) => {
		const styles = useStyles()
		const dragAndDropContext = useFormBuilderDragAndDropContext()

		const generalStyles = useGeneralStyles()

		const [{ isOver, canDrop }, drop] = useDrop({
			accept: DraggableItemType.FormBuilderComponent,
			collect: (monitor) => ({
				isOver: !!monitor.isOver({ shallow: true }),
				canDrop: !!monitor.canDrop(),
			}),
			canDrop: (item) => {
				if (isDraggableDefinition(item)) {
					if (
						isRecursiveCycle(
							item,
							props.cellInstance.definitionId,
							props.formHost as DraggableFormHost,
						)
					)
						return false

					if (item.definitionId === props.cellInstance.definitionId)
						return false
				}

				return true
			},
			drop: (item, monitor) => {
				if (monitor.didDrop()) return

				const formHost = props.formHost as DraggableFormHost

				if (isDraggableDefinition(item)) {
					formHost.swapCells(item.definitionId, props.cellInstance.definitionId)
				}

				if (isDraggableComponent(item)) {
					formHost.swapComponent(
						props.cellInstance.definitionId,
						item.componentRegistrationId,
					)
				}
			},
		})

		const handleClickCapture = useCallback(
			action(() => {
				if (
					dragAndDropContext.currentlySelectedDefinition !==
					props.cellInstance.definitionId
				)
					dragAndDropContext.currentlySelectedDefinition =
						props.cellInstance.definitionId
			}),
			[dragAndDropContext],
		)

		return (
			<div
				className={clsx(generalStyles.dropContainerStyle, {
					[generalStyles.selectedDropContainerStyle]:
						dragAndDropContext.currentlySelectedDefinition ===
						props.cellInstance.definitionId,
					// show a red border around components that don't have names
					[generalStyles.unnamedDropContainerStyle]:
						props.cellInstance.name === '' &&
						dragAndDropContext.currentlySelectedDefinition !==
							props.cellInstance.definitionId,
				})}
				ref={drop}
				onMouseDownCapture={handleClickCapture}
				onClickCapture={handleClickCapture}
			>
				<div
					className={clsx(styles.overlay, {
						[styles.overlayColor]: isOver && canDrop,
					})}
				>
					{props.children}
				</div>
			</div>
		)
	},
)

const useStyles = makeStyles((theme) => ({
	overlay: {
		display: 'flex',
		flex: '1',
	},

	overlayColor: {
		backgroundColor: 'aliceblue',
	},
}))
