import { createContext, PropsWithChildren, useContext } from 'react'
import { DetailForm } from '../../../../../../../../api/DTOtemp'

// if we're on the form page, we should know what the current form is
type FormPageFormEventsContextType = {
	type: 'Form'
	form: DetailForm
	// we need to know other forms in the package for some components
	forms: DetailForm[]
}

// on the package page, can deal with all forms in the package
type FormPackageFormEventsContextType = {
	type: 'FormPackage'
	forms: DetailForm[]
}

type FormEventsContextType =
	| FormPageFormEventsContextType
	| FormPackageFormEventsContextType

const FormEventsContext = createContext<FormEventsContextType | undefined>(
	undefined,
)

export const FormEventsContextProvider = (
	props: PropsWithChildren<FormEventsContextType>,
) => {
	return (
		<FormEventsContext.Provider value={props}>
			{props.children}
		</FormEventsContext.Provider>
	)
}

export function useFormEventsContext() {
	const context = useContext(FormEventsContext)
	if (context === undefined)
		throw new Error('Form events context was undefined')

	return context
}
