import { Add, ArrowForward, Remove } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { px } from 'csx'
import { Syntax } from 'esprima'
import { MemberExpression, Node } from 'estree'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PropsWithChildren, useEffect } from 'react'
import { ComponentCategory } from '../../../draggable/NodeComponents'

import { RendererComponent, ValidNodeTypes } from '../../../Types'
import Renderer, { DropMode } from '../../internal/Renderer'
import useColorScheme from '../../internal/useColorScheme'
import FormFieldComponent from './FormFieldComponent'
import { resetNodes, treeGenerator } from './MemberExpressionComponent.assets'
import OrganizationPropertyComponent from './OrganizationPropertyComponent'
import QueryStringComponent from './QueryStringComponent'
import StringLengthComponent from './StringLengthComponent'
import UserInfoComponent from './UserInfoComponent'
import UserPropertyComponent from './UserPropertyComponent'

export const MemberExpressions: ValidNodeTypes[] = [
	'UserPropertyAccess',
	'String-Length',
	'OrganizationPropertyAccess',
	'FormFieldAccess',
	'UserInfoAccess',
	'QueryStringAccess',
]

const MemberExpressionComponent = observer(
	(props: RendererComponent<MemberExpression>) => {
		const [color, textColor] = useColorScheme(ComponentCategory.Variables)

		const renderMemberExpression = (formsInfoType: string) => {
			switch (formsInfoType) {
				case 'String-Length':
					return (
						<MemberExpressionContainer label="">
							<StringLengthComponent node={props.node} />
						</MemberExpressionContainer>
					)
				case 'FormFieldAccess':
					return (
						<MemberExpressionContainer label="Form Field">
							<FormFieldComponent node={props.node} />
						</MemberExpressionContainer>
					)
				case 'UserPropertyAccess':
					return (
						<MemberExpressionContainer label="User Property">
							<UserPropertyComponent node={props.node} />
						</MemberExpressionContainer>
					)
				case 'OrganizationPropertyAccess':
					return (
						<MemberExpressionContainer label="Organization Property">
							<OrganizationPropertyComponent node={props.node} />
						</MemberExpressionContainer>
					)
				case 'UserInfoAccess':
					return (
						<MemberExpressionContainer label="User Info">
							<UserInfoComponent node={props.node} />
						</MemberExpressionContainer>
					)
				case 'QueryStringAccess':
					return (
						<MemberExpressionContainer label="Query String">
							<QueryStringComponent node={props.node} />
						</MemberExpressionContainer>
					)
				default:
					return (
						<MemberExpressionContainer label="Member Expression">
							<Box display="flex" alignItems="center" gap={2} bgcolor={color}>
								<Box display="flex" gap={1} alignItems="center">
									{path.map((v, i) => {
										if (i === path.length - 1) {
											return (
												<Renderer
													key={i}
													dropMode={DropMode.Replace}
													validNodes={[Syntax.Literal, Syntax.MemberExpression]}
													getter={() => v as Node | undefined}
													setter={action((value) => {
														path[i] = value
														resetNodes(path, props.node)
													})}
												/>
											)
										} else {
											return (
												<Box display="flex" alignItems="center" gap={1} key={i}>
													<Renderer
														key={i}
														dropMode={DropMode.Replace}
														validNodes={[
															Syntax.Literal,
															Syntax.MemberExpression,
														]}
														getter={() => v as Node | undefined}
														setter={action((value) => {
															path[i] = value
															resetNodes(path, props.node)
														})}
													/>
													<ArrowForward sx={{ color: textColor }} />
												</Box>
											)
										}
									})}
								</Box>
								<Box display="flex" gap={1} alignItems="center">
									<Box
										component={IconButton}
										color={red[500]}
										width={px(32)}
										height={px(32)}
										borderRadius={1}
										border={1}
										borderColor="red"
										onClick={() => addNode()}
									>
										<Add fontSize="small" />
									</Box>
									<Box
										disabled={path.length <= 2}
										component={IconButton}
										color={red[500]}
										width={px(32)}
										height={px(32)}
										borderRadius={1}
										border={1}
										borderColor="red"
										onClick={() => removeNode()}
									>
										<Remove fontSize="small" />
									</Box>
								</Box>
							</Box>
						</MemberExpressionContainer>
					)
			}
		}

		const path = observable(treeGenerator(props.node))

		const addNode = action(() => {
			path.push(undefined)
			resetNodes(path, props.node)
		})

		const removeNode = action(() => {
			path.pop()
			resetNodes(path, props.node)
		})

		// need to reset nodes on first render (if we're working with a pure
		// member expression component) otherwise we get an undefined property!
		useEffect(() => {
			if (props.node.formsInfo?.type === undefined) resetNodes(path, props.node)
		}, [])

		return (
			<Box display="flex" flexDirection="column">
				{renderMemberExpression(props.node.formsInfo?.type ?? '')}
			</Box>
		)
	},
)

type MemberExpressionContainerProps = {
	label: string
}

const MemberExpressionContainer = (
	props: PropsWithChildren<MemberExpressionContainerProps>,
) => {
	const [, textColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<>
			<Typography color={textColor} fontSize="small">
				{props.label}
			</Typography>
			{/* we have to use sx here :/ borderColor on box does not work with a non-palette color */}
			<Box sx={{ borderColor: textColor }} border={1} padding={1}>
				{props.children}
			</Box>
		</>
	)
}

export default MemberExpressionComponent
