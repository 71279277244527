import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../../Breadcrumbs'
import UserPage from '../UserPage/UserPage'
import UsersPage from './UsersPage'

const UsersPageRouter = () => {
	const { path } = useRouteMatch()
	updateBreadcrumbs(`/_administration/_users`, 'Users')

	return (
		<Switch>
			<Route path={makeChildRoute(':userId', path)}>
				<UserPage />
			</Route>
			<Route path={makeChildRoute('', path)}>
				<UsersPage />
			</Route>
		</Switch>
	)
}

export default UsersPageRouter
