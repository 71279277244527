import {
	Chip,
	CircularProgress,
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ComponentProps, useEffect } from 'react'
import {
	DataProviderInfo,
	executeDataProvider,
} from '../../../../utils/FormBuilderDataProviderUtils'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import { useFormBuilderContext } from '../../../FormBuilderCore/cells/rendering/contexts/FormBuilderContext'
import { useInputCellContext } from '../../../FormBuilderCore/cells/rendering/contexts/InputContextProvider'
import { FieldType } from '../../../FormHost/Types/FieldType'
import {
	ColumnType,
	DataProviderPropertyConfigurator,
	MultiSelectPropertyConfigurator,
	OptionsType,
	PropertySchematicConfigurator,
	SelectPropertyConfigurator,
	StaticDataProviderPropertyConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import { changeEventEmitter } from '../../EventBus/BuiltInEvents/ChangeEvent'
import { focusEventEmitter } from '../../EventBus/BuiltInEvents/FocusEvent'

type MultiSelectControlProps = {
	label: string
	emptyValue?: string
	options: OptionsType[]
	dataProviderType: 'static' | 'dataProvider'
	dataProviderInfo?: DataProviderInfo
}

export const MultiSelectInput = observer((props: MultiSelectControlProps) => {
	const context = useInputCellContext()
	const { cellManager } = useFormBuilderContext()

	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		isExecuting: false,
		dataProviderResults: [] as OptionsType[],

		get options(): OptionsType[] {
			return props.dataProviderType === 'static'
				? props.options
				: this.dataProviderResults
		},
	}))

	useEffect(() => {
		if (
			props.dataProviderInfo == null ||
			props.dataProviderInfo.dataProviderId === 0
		)
			return

		runInAction(() => {
			localStore.isExecuting = true
		})

		executeDataProvider(props.dataProviderInfo)
			.then(action((results) => (localStore.dataProviderResults = results)))
			.finally(
				action(() => {
					localStore.isExecuting = false
				}),
			)
	}, [])

	return (
		<FormControl className={styles.root}>
			<Typography variant="subtitle1" color="textPrimary">
				{props.label}
			</Typography>
			<Select
				value={context.value ?? []}
				// per mui, value is always an array
				endAdornment={
					localStore.isExecuting && (
						<CircularProgress
							color="primary"
							size={20}
							className={styles.spinner}
						/>
					)
				}
				onChange={action((v: SelectChangeEvent<unknown>) => {
					const oldValue = context.value
					const newValue = v.target.value
					context.onChange(newValue)
					changeEventEmitter(
						cellManager.eventBus,
						{
							oldValue: oldValue,
							newValue: newValue,
						},
						{
							formHost: cellManager,
							elementTag: MultiSelectInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				})}
				onBlur={() => {
					focusEventEmitter(
						cellManager.eventBus,
						{
							focusType: 'FocusLost',
						},
						{
							formHost: cellManager,
							elementTag: MultiSelectInputElementTag,
							definitionId: context.cellInstance.definitionId,
							instanceId: context.cellInstance.id,
						},
					)
				}}
				multiple
				renderValue={(selected) => {
					const selectedArray = selected as string[]
					if (selectedArray.length > 0)
						return (selected as string[]).map((v) => (
							<Chip
								key={v}
								label={
									localStore.options.find((option) => option.key === v)
										?.displayValue ?? v
								}
							/>
						))
					return props.emptyValue
				}}
			>
				{localStore.isExecuting ? (
					<MenuItem disabled>Loading...</MenuItem>
				) : (
					localStore.options.map((v) => (
						<MenuItem key={v.key} value={v.key}>
							{v.displayValue}
						</MenuItem>
					))
				)}
			</Select>
		</FormControl>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 'auto',
	},
	spinner: {
		margin: theme.spacing(0, 3),
	},
}))
export const MultiSelectInputElementTag = '5636644a-b856-48e2-bcf6-efc7b87a6cbd'

const MultiSelectInputId = 'b6f895d0-48b0-45e9-92c9-def5bf8bb688'

const MultiSelectInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof MultiSelectInput>>) => {
	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.emptyValue}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, emptyValue: v })
				}
				Editor={TextPropertyConfigurator}
				label="Empty Value"
			/>
			<PropertySchematicConfigurator
				value={properties.dataProviderType}
				onValueChanged={(v) =>
					onPropertiesChanged({ ...properties, dataProviderType: v })
				}
				Editor={(props) => {
					const value = [
						{ key: 'static', displayValue: 'Static' },
						{ key: 'dataProvider', displayValue: 'Data Provider' },
					]
					return <SelectPropertyConfigurator options={value} {...props} />
				}}
				label="Data Provider Type"
			/>
			{properties.dataProviderType === 'static' && (
				<PropertySchematicConfigurator
					value={properties.options}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, options: v })
					}
					Editor={(props) => (
						<StaticDataProviderPropertyConfigurator
							values={{
								key: {
									columnName: 'Key',
									columnType: ColumnType.String,
								},
								displayValue: {
									columnName: 'Display Value',
									columnType: ColumnType.String,
								},
							}}
							{...props}
						/>
					)}
					label="Options"
				/>
			)}
			{properties.dataProviderType === 'dataProvider' && (
				<PropertySchematicConfigurator
					value={properties.dataProviderInfo}
					onValueChanged={(v) =>
						onPropertiesChanged({ ...properties, dataProviderInfo: v })
					}
					Editor={DataProviderPropertyConfigurator}
					label="Options"
				/>
			)}
		</>
	)
}

const MultiSelectInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof MultiSelectInput>>,
) => {
	const options = props.properties.options

	// we can't have a default value if it's a data provider
	// bc values may be different when form is loaded
	if (props.properties.dataProviderType === 'dataProvider') return <></>

	return (
		<PropertySchematicConfigurator
			value={props.value as string[] | undefined}
			onValueChanged={props.onValueChanged}
			Editor={(props) => (
				<MultiSelectPropertyConfigurator
					options={options}
					value={props.value as string[] | undefined}
					onChange={props.onChange}
					hint={props.hint}
					hintColor={props.hintColor}
				/>
			)}
			label="Default Value"
		/>
	)
}

export const MultiSelectInputDefinition = specifyValueTypeComponent(
	MultiSelectInputId,
	MultiSelectInput,
	MultiSelectInputElementTag,
	'Multi-Select Input',
	FieldType.String,
	{
		configurator: MultiSelectInputPropsEditor,
		defaultProperties: {
			label: 'label',
			emptyValue: '',
			options: [],
			dataProviderType: 'static',
			dataProviderInfo: undefined,
		},
	},
	{
		configurator: MultiSelectInputValueEditor,
		defaultValue: [],
	},
)
