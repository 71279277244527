import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { TrackingEntity } from '../../DTO'
import SecureClient from '../SecureClient'

export class OrganizationPropertiesClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()

		this._urlBase = `OrganizationProperties`
	}

	public GetOrganizationProperties(): Promise<
		AxiosResponse<OrganizationPropertyModel[]>
	> {
		const headers: AxiosRequestHeaders = {
			...this.acceptHeader(),
		}

		if (this.hasAuthorizationHeader)
			headers['Authorization'] = this.authorizationHeader.Authorization

		return this._api.get<OrganizationPropertyModel[]>(this._urlBase, {
			headers: headers,
		})
	}

	public GetProperty(
		propertyId: number,
	): Promise<AxiosResponse<OrganizationPropertyModel>> {
		return this._api.get<OrganizationPropertyModel>(
			`${this._urlBase}/${propertyId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
				},
			},
		)
	}

	public GetPropertyByName(
		propertyName: string
	): Promise<AxiosResponse<OrganizationPropertyModel>> {
		return this._api.get<OrganizationPropertyModel>(
			`${this._urlBase}/Name/${propertyName}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader()
			}
		}
		)
	}

	public AddOrganizationProperty(
		newProperty: CreateOrganizationPropertyModel,
	): Promise<AxiosResponse<OrganizationPropertyModel>> {
		return this._api.post<OrganizationPropertyModel>(
			this._urlBase,
			newProperty,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
				},
			},
		)
	}

	public UpdateOrganizationProperty(propertyId: number, model: CreateOrganizationPropertyModel): Promise<AxiosResponse<OrganizationPropertyModel>> {
		return this._api.put<OrganizationPropertyModel>(
			`${this._urlBase}/${propertyId}`,
			model,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}

	public DeleteOrganizationProperty(propertyId: number) {
		return this._api.delete(`${this._urlBase}/${propertyId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader,
			},
		})
	}
}

export type OrganizationPropertyModel = {
	normalizedPropertyName: string
	name: string
	value: OrganizationPropertyValue
} & TrackingEntity

export type CreateOrganizationPropertyModel = {
	name: string
	value: OrganizationPropertyValue
}

export type OrganizationPropertyListItem = {
	label: string
	value: string
}

export enum OrganizationPropertyType {
	String = 0,
	List = 1
}

export type OrganizationPropertyValue = StringPropertyValue | ListPropertyValue

type StringPropertyValue = {
	type: OrganizationPropertyType.String
	value: string
}

type ListPropertyValue = {
	type: OrganizationPropertyType.List
	value: OrganizationPropertyListItem[]
}