import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	LinearProgress,
	TextField,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { BaseUser } from '../../../../../../api/DTO'

type UserPageSectionProps = {
	user: BaseUser
	onUpdateUser: (user: BaseUser) => Promise<void>
}

const validationSchema = yup.object({
	firstName: yup.string().required('First Name is a required field'),
	lastName: yup.string().required('Last Name is a required field'),
	phoneNumber: yup.string(),
	emailAddress: yup
		.string()
		.email(
			(e) =>
				`The given email address '${e.value}' is not a valid email address`,
		)
		.required('The email address field is required'),
})

const UserProfileSection = (props: UserPageSectionProps) => {
	const styles = useStyles()

	const formik = useFormik({
		initialValues: {
			firstName: props.user.firstName,
			lastName: props.user.lastName,
			phoneNumber: '',
			emailAddress: props.user.emailAddress,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: (values, { setSubmitting }) => {
			setSubmitting(true) // formik doesn't recognize when to set isSubmitting to false so we have to do it ourselves

			props
				.onUpdateUser({
					id: props.user.id,
					firstName: values.firstName,
					lastName: values.lastName,
					emailAddress: values.emailAddress,
				})
				.finally(() => {
					setSubmitting(false)
				})
		},
	})

	return (
		<Card className={styles.userProfileRoot}>
			<CardHeader title="Profile"></CardHeader>
			<Divider />
			<CardContent>
				<div className={styles.cardBody}>
					<TextField
						className={styles.leftItemSpacing}
						id="firstName"
						name="firstName"
						label="First Name"
						value={formik.values.firstName}
						helperText={
							(formik.touched.firstName && formik.errors.firstName) ?? ' '
						}
						error={!!formik.errors.firstName && formik.touched.firstName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<TextField
						className={styles.rightItemSpacing}
						id="lastName"
						name="lastName"
						label="Last Name"
						value={formik.values.lastName}
						helperText={
							(formik.touched.lastName && formik.errors.lastName) ?? ' '
						}
						error={!!formik.errors.lastName && formik.touched.lastName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<TextField
						className={styles.leftItemSpacing}
						id="phoneNumber"
						name="phoneNumber"
						label="Phone Number"
						value={formik.values.phoneNumber}
						helperText={
							(formik.touched.phoneNumber && formik.errors.phoneNumber) ??
							'This will only be used for notification purposes'
						}
						error={!!formik.errors.phoneNumber && formik.touched.phoneNumber}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<TextField
						className={styles.rightItemSpacing}
						id="emailAddress"
						name="emailAddress"
						label="Email Address"
						value={formik.values.emailAddress}
						helperText={
							(formik.touched.emailAddress && formik.errors.emailAddress) ?? ' '
						}
						error={!!formik.errors.emailAddress && formik.touched.emailAddress}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
				</div>
			</CardContent>
			<Divider />
			<div className={styles.cardActionSection}>
				<Button
					className={styles.cardActionItem}
					disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
					color="primary"
					variant="contained"
					onClick={formik.submitForm}
				>
					Save Changes
				</Button>
			</div>
			{formik.isSubmitting && <LinearProgress />}
		</Card>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	userProfileRoot: {
		height: 'fit-content',
		width: percent(100),
	},

	cardBody: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
	},

	leftItemSpacing: {
		marginRight: theme.spacing(2),
	},

	rightItemSpacing: {
		marginLeft: theme.spacing(2),
	},

	cardActionSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(2, 0),
	},

	cardActionItem: {
		margin: theme.spacing(0, 2),
	},
}))

export default UserProfileSection
