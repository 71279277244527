import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'
import * as yup from 'yup'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type CreateWorkflowDialogProps = {
	open: boolean
	existingWorkflowNames: string[]
	onClose: () => void
	onCreateWorkflow: (name: string) => void
}

export const CreateWorkflowDialog: React.FC<CreateWorkflowDialogProps> =
	observer((props: CreateWorkflowDialogProps) => {
		const styles = useStyles()

		const upperWorkflowNames = props.existingWorkflowNames.map((v) =>
			v.toUpperCase(),
		)

		const validationSchema = yup.object({
			workflowName: yup
				.string()
				.required('Workflow name is a required field')
				.test(
					'is-unique',
					'A workflow with this name already exists in your organization',
					(value) => {
						return (
							value !== undefined &&
							!upperWorkflowNames.includes(value.toUpperCase().trim())
						)
					},
				),
		})

		const formik = useFormik({
			initialValues: {
				workflowName: '',
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				props.onCreateWorkflow(values.workflowName)
			},
		})

		return (
			<Dialog open={props.open} onClose={props.onClose} fullWidth>
				<DialogTitle>Create Workflow</DialogTitle>
				<DialogContent className={styles.mainDiv}>
					<FixedHeaderWithHint
						label="Workflow Name"
						className={styles.textFieldStyle}
						hint={formik.touched.workflowName && formik.errors.workflowName}
						hintColor="error.main"
					>
						<TextField
							fullWidth
							id="workflowName"
							name="workflowName"
							value={formik.values.workflowName}
							error={
								!!formik.errors.workflowName && formik.touched.workflowName
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>

					<DialogActions>
						<Button color="primary" onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							onClick={formik.submitForm}
							disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
							color="primary"
							variant="contained"
						>
							Create
						</Button>
					</DialogActions>
				</DialogContent>
				{formik.isSubmitting && <LinearProgress />}
			</Dialog>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},
}))
