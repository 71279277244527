
import { AxiosResponse } from "axios"
import { BaseRole } from "../../DTO"
import SecureClient from '../SecureClient'

export class RolesClient extends SecureClient {

	public GetAllRoles()
		: Promise<AxiosResponse<BaseRole[]>> {
		return this._api.get<BaseRole[]>(
			`Roles`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}
}