import { NIL, v4 as uuidv4 } from 'uuid'
import {
	IConcreteCellDefinition,
	ILayoutCellDefinition,
	INullCellDefinition,
	IRepeatCellDefinition,
	IValueCellDefinition,
} from '../../FormHost/Types/CellDefinition'
import { CellType } from '../../FormHost/Types/CellType'
import FieldType from '../../FormHost/Types/FieldType'

type IFormBuilderCellDefinition = {
	properties: Record<string, unknown>
	ordinalPosition: number
}

export type IConcreteFormBuilderCellDefinition = IFormBuilderCellDefinition &
	IConcreteCellDefinition

export type INullFormBuilderCellDefinition = IFormBuilderCellDefinition & INullCellDefinition

export type ILayoutFormBuilderCellDefinition = IFormBuilderCellDefinition &
	ILayoutCellDefinition

export type IRepeatFormBuilderCellDefinition = {
	defaultRepeatCount: number
} & IFormBuilderCellDefinition &
	IRepeatCellDefinition

export type IValueFormBuilderCellDefinition = {
	value: unknown
} & IFormBuilderCellDefinition &
	IValueCellDefinition

export type ValueLikeFormBuilderCellDefinition =
	| IValueFormBuilderCellDefinition
	| ILayoutFormBuilderCellDefinition
	| IRepeatFormBuilderCellDefinition

export type FormBuilderCellDefinition =
	| IConcreteFormBuilderCellDefinition
	| ValueLikeFormBuilderCellDefinition
	| INullFormBuilderCellDefinition

export function isValueLikeDefinition(
	cell: FormBuilderCellDefinition,
): cell is ValueLikeFormBuilderCellDefinition {
	return (
		cell.type === CellType.Value ||
		cell.type === CellType.Layout ||
		cell.type === CellType.Repeat
	)
}

export function createNullDefinition(
	parentId: string,
	position: number,
): INullFormBuilderCellDefinition {
	return {
		type: CellType.Null,
		id: uuidv4(),
		name: '',
		parentId,
		fieldType: FieldType.None,
		elementTag: NIL,
		properties: {},
		ordinalPosition: position,
	}
}
