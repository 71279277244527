import { ActivityConfiguration, BaseActivityDefinitionModel } from "../../../api/clients/workflows/DTOs"

export function createOutcomes(configuration: ActivityConfiguration, activity: BaseActivityDefinitionModel): string[] | undefined {
	console.log(configuration.outcomes)
	console.log({ ...activity.state })

	if (!configuration.outcomes)
		return

	if (Array.isArray(configuration.outcomes))
		return configuration.outcomes

	
	// if we ever change this, make sure it matches with the one in ActivityCard

	let output: unknown = {}
	try {
		const outcomes = configuration.outcomes
		if (typeof outcomes === 'string') {
			if (!outcomes.startsWith('[') && !outcomes.startsWith('x'))
				return [outcomes]
		}

		let activityOutcomesFn: ReturnType<typeof Function>
		try {
			activityOutcomesFn = new Function(
				'x',
				`
				const outcomes = (${configuration.outcomes})
				if (typeof outcomes === 'function')
					return outcomes(x)
				if (typeof outcomes === 'object')
					return outcomes
				return outcomes
				`,
			)
		} catch (error) {
			activityOutcomesFn = new Function(
				'x',
				`const outcomes = '${configuration.outcomes}'`
			)
		}

		output = activityOutcomesFn(activity)
		console.log('output: ', output)
	} catch (e) {
		console.warn(`failed to parse or execute function for output {{${output}}} `, e)
		output = configuration.outcomes
	}

	if (typeof output === "function")
		return output(activity) as string[]

	if (Array.isArray(output))
		return output
	
	if (typeof output === "string")
		return [output]
	
	throw `invalid type for configuration ${configuration.definitionId}`

}