import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Tab,
	Tabs,
	TextField,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent, px } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import React, { SyntheticEvent, useEffect } from 'react'
import { mediaWidths } from '../../../styles'
import { DrawSignature } from './DrawSignature'
import { Signature } from './SignatureContext'
import {
	SignatureStoreProvider,
	useSignatureStore,
} from './SignatureModalStore'
import { SignatureView } from './SignatureView'

type SignatureProps = {
	onCancel: () => void
	onApply: (signature: Signature) => Promise<void>
}

export const SignatureWrapper: React.FC<SignatureProps> = (
	props: SignatureProps,
) => {
	return (
		<SignatureStoreProvider>
			<SignatureModal {...props} />
		</SignatureStoreProvider>
	)
}

// TabPanel component from Material UI https://codesandbox.io/s/kz25m?file=/demo.tsx:1170-1175

type TabPanelProps = {
	children?: React.ReactNode
	index: unknown
	value: unknown
}

const TabPanel: React.FC<TabPanelProps> = ({
	children,
	index,
	value,
}: TabPanelProps) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && (
				<Box
					sx={{
						p: 3,
					}}
				>
					{children}
				</Box>
			)}
		</div>
	)
}

export const SignatureModal: React.FC<SignatureProps> = observer(
	(props: SignatureProps) => {
		const styles = useStyles()

		const modalStore = useSignatureStore()

		const localStore = useLocalObservable(() => ({
			selectedTabIndex: 0,
			emptySignature:
				modalStore.signatureData.viewSignatureValue === undefined &&
				modalStore.signatureData.drawSignatureValue === undefined,
			isApplying: false,
		}))

		useEffect(
			action(() => {
				if (localStore.selectedTabIndex == 0)
					localStore.emptySignature =
						modalStore.signatureData.viewSignatureValue === undefined
				else
					localStore.emptySignature =
						modalStore.signatureData.drawSignatureValue === undefined
			}),
			[
				localStore.selectedTabIndex,
				modalStore.signatureData.viewSignatureValue,
				modalStore.signatureData.drawSignatureValue,
			],
		)

		// allows extended latin characters as well as "-", "." and "'"
		// with a space inbetween, but no trailing space
		// does *not* allow for any non-latin characters ex: korean, chinese, russian
		// but does allow accents ex: María José Carreño Quiñones
		const nameRegex =
			/^[\u00c0-\u01ffa-zA-Z'-.]{2,}(?: [\u00c0-\u01ffa-zA-Z'-.]+){0,}$/

		const textIsValid = (): boolean => {
			return (
				nameRegex.test(modalStore.fullName) &&
				/^[^a-z]{2,}$/.test(modalStore.initials) &&
				/^\S+@\S+\.\S{2,}$/.test(modalStore.email)
			)
		}

		const handleApplySignature = action(() => {
			localStore.isApplying = true

			props
				.onApply({
					fullName: modalStore.fullName,
					initials: modalStore.initials,
					email: modalStore.email,
					signatureData:
						localStore.selectedTabIndex == 0
							? modalStore.signatureData.viewSignatureValue ?? ''
							: modalStore.signatureData.drawSignatureValue ?? '',
					id: 0,
				})
				.finally(() => {
					localStore.isApplying = false
				})
		})

		return (
			<Dialog open={true} fullWidth>
				<DialogTitle>Create New Signature</DialogTitle>
				<DialogContent>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginTop: px(20),
						}}
					>
						<ValidatedInput
							text={modalStore.fullName}
							className={styles.fullNameText}
							label={'Full Name'}
							validator={(s) => {
								if (!nameRegex.test(s)) return 'Please enter a valid full name'
							}}
							onChange={action((s) => (modalStore.fullName = s))}
						/>
						<ValidatedInput
							text={modalStore.initials}
							className={styles.initialsText}
							label={'Initials'}
							validator={(s) => {
								if (!/^[^a-z]{2,}$/.test(s))
									return 'Initials must be capitalized'
							}}
							onChange={action((s) => (modalStore.initials = s))}
						/>
					</div>
					<div style={{ marginTop: px(20), marginBottom: px(20) }}>
						<ValidatedInput
							text={modalStore.email}
							label={'Email'}
							className={styles.emailText}
							validator={(s) => {
								if (!/^\S+@\S+\.\S{2,}$/.test(s))
									return 'Please enter a valid email address'
							}}
							onChange={action((s) => (modalStore.email = s))}
						/>
					</div>
					<Tabs
						indicatorColor="secondary"
						textColor="primary"
						value={localStore.selectedTabIndex}
						onChange={action((event: SyntheticEvent, v: number) => {
							localStore.selectedTabIndex = v
						})}
					>
						<Tab label="View" />
						<Tab label="Draw" />
					</Tabs>
					<TabPanel value={localStore.selectedTabIndex} index={0}>
						<SignatureView />
					</TabPanel>
					<TabPanel value={localStore.selectedTabIndex} index={1}>
						<DrawSignature />
					</TabPanel>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={props.onCancel}
							disabled={localStore.isApplying}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleApplySignature}
							disabled={
								localStore.emptySignature ||
								!textIsValid() ||
								localStore.isApplying
							}
						>
							Apply
						</Button>
					</DialogActions>
				</DialogContent>

				{localStore.isApplying && <LinearProgress />}
			</Dialog>
		)
	},
)

const ValidatedInput = (props: {
	text: string
	label: string
	className: string
	validator: (s: string) => string | undefined
	onChange: (s: string) => void
}) => {
	const validationResult = props.validator(props.text)

	// poor man's validated text field
	return (
		<TextField
			required
			value={props.text}
			label={props.label}
			onChange={(v: React.ChangeEvent<HTMLInputElement>) =>
				props.onChange(v.currentTarget.value)
			}
			error={validationResult !== undefined}
			helperText={validationResult ?? ''}
			className={props.className}
		/>
	)
}

const useStyles = makeStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexFlow: 'column',
	},
	modal: {
		width: percent(100),
	},
	card: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexFlow: 'row wrap',
		[`@media (max-width: ${mediaWidths.medium.maxWidth}px)`]: {
			maxWidth: px(300),
			minWidth: px(300),
		},
		[`@media (min-width: ${mediaWidths.medium.minWidth}px)`]: {
			maxWidth: px(850),
			minWidth: px(850),
		},
		minHeight: px(500),
		paddingTop: px(10),
		paddingBottom: px(10),
	},
	saveModal: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		flexFlow: 'row wrap',
		[`@media (max-width: ${mediaWidths.medium.maxWidth}px)`]: {
			maxWidth: px(200),
			minWidth: px(200),
		},
		[`@media (min-width: ${mediaWidths.medium.minWidth}px)`]: {
			maxWidth: px(400),
			minWidth: px(400),
		},
		minHeight: px(200),
		paddingTop: px(10),
		paddingBottom: px(10),
	},
	fullNameText: {
		marginRight: px(5),
		width: percent(100),
	},
	initialsText: {
		marginLeft: px(5),
		width: percent(50),
	},
	emailText: {
		width: percent(66),
	},
})
