//#region Primary entities

import { FormBuilderSchema } from "../../../modules/FormBuilderCore/Types"
import { TrackingEntity } from "../../DTO"

export type BaseActivityDefinitionModel = {
	uniqueId: string
	workflowDefinitionId: number
	activityTypeId: string
	displayName: string
	description: string
	left: number
	top: number
	lifetime: Lifetime
	state: State
}

export type ActivityDefinition = {
	id: number
	uniqueId: string
	workflowDefinitionId: number
	activityTypeId: string
	displayName: string
	description: string
	left: number
	top: number
	lifetime: Lifetime
	state: State
} 

export type ActivityInstance = {
	id: number
	referenceId: string
	activityDefinitionId: number
	state: State
	status: ActivityInstanceStatus
	activityLog: ActivityInstanceLogEntry[]
	activitySequenceNumber: number
	startedAt: Date
	finishedAt: Date
}

export type LogEntry = {
	eventId: string
	logLevel: LogLevel
	message: string
	eventDate: Date
	eventData: unknown[]
	exception: string
}

export type ActivityInstanceLogEntry = {
	activityInstanceId: number
} & LogEntry

export type BaseConnectionDefinitionModel = {
	uniqueId: string
	workflowDefinitionId: number
	sourceActivityId: string
	destinationActivityId: string
	outcome: string
}

export type ConnectionDefinition = {
	id: number
} & BaseConnectionDefinitionModel

export type WorkflowLogEntry = {
	workflowInstanceId: number
} & LogEntry

export type WorkflowInstanceBase = {
	id: number
	workflowDefinitionId: number
	createdAt: Date
	startedAt: Date
	finishedAt: Date
	faultedAt: Date
	abortedAt: Date
	status: WorkflowStatus
}

export type WorkflowInstance = WorkflowInstanceBase & {
	workflowDefinition: WorkflowDefinition
	activityInstances: ActivityInstance[]
	workflowLog: WorkflowLogEntry[]
	transitionLog: WorkflowTransition[]
} 

export type Workflow = {
	name: string
	id: number
	uniqueId: string,
	enabled: boolean
}

export type WorkflowTransition = {
	sequenceNumber: number
	from: string
	to: string
	scopeId: string
	scheduledAt: Date	
}

export type WorkflowDefinitionBase = {
	workflowId: number
	version: number
	isPublished: boolean
	isDraft: boolean
} & TrackingEntity

export type WorkflowDefinition = WorkflowDefinitionBase & {
	workflow: Workflow
	activityDefinitions: BaseActivityDefinitionModel[]
	connectionDefinitions: BaseConnectionDefinitionModel[]
}

export type BaseWorkflowModel = {
	workflowName: string
	enabled: boolean
}

//#endregion

//#region Activity Definition Schema

export type ActivityConfiguration = {
	definitionId: string
	displayName: string
	description: string
	category: string
	outcomes: string | string[] | unknown
	runtimeDescription: string
	initialState: State
	formBuilderSchema: FormBuilderSchema
	icon: string
}

//#endregion

export type State = {
	[key: string]: unknown | undefined
	name: string | undefined
	title: string | undefined
	description: string | undefined
}

export type ActivityTag = {
	class: string,
	value: string
}



export enum Lifetime {
	Singleton = 0,
	Transient = 1
}

export enum ActivityInstanceStatus {
	NotRun = 0,
	Halted = 1,
	Completed = 2,
	Cancelled = 3,
	Faulted = 4
}

export enum WorkflowStatus {
	Created = 0,
	Idle = 1,
	Queued = 2,
	Executing = 3,
	Finished = 4,
	Faulted = 5,
	Aborted = 6
}

export enum LogLevel {
	Trace = 0,
	Debug = 1,
	Information = 2,
	Warning = 3,
	Error = 4,
	Critical = 5,
	None = 6
}
