import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import {
	CommitIntent,
	FormPackageConfiguration,
	FormPackageInfo,
	FormType
} from '../../api/DTOtemp'
import {
	ForwardedFormPackageWorkItemState,
	ReadWorkItemModel,
	WriteWorkItemModel
} from '../../api/clients/workItems/DTOs'
import { UnifiedCellDefinition } from '../../modules/FormHost/Types/UnifiedCellDefinition'
import { UnifiedCellInstance } from '../../modules/FormHost/Types/UnifiedCellInstance'
import { TrackedResource } from '../../pages/PackageHostPage/Attachments/AttachFilesDialog'
import { DetailFormWithData } from './FormPackageHandling'
import { retrieveScopedDatabase } from './indexedDb'

// flags!
export enum IntendedUsage {
	Retrieval = 1, // saved & forwarded
	Submission = 2, // needs to be updated @ the server
	AutoSave = 4,
	Delete = 8,
}


/*
! This is all the stuff we want for retrieval
*/

export type CacheFormPackage = InstanceCacheFormPackage


export type BaseCacheFormPackage = {
	name: string
	packageVersion: FormPackageInfo
	configuration: FormPackageConfiguration
	versionNumber: number
	versionId: number
	packageId: number
	forms: CacheDetailForm[]
	resources: TrackedResource[]

	isPackageFromServer: boolean

	workItem?:
		| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
		| ReadWorkItemModel<ForwardedFormPackageWorkItemState>
	description?: string
}

export type InstanceCacheFormPackage = BaseCacheFormPackage & {

	// has to be set so that we know whether we'll be submitting, saving, approving or rejecting
	action?: CommitIntent

	/*
	we're going to have to do a check to see if this is missing for saved & forwarded
	*/
	instanceId?: number // the instance id of the form -- from the server
	uniqueInstanceId?: string // the GUID unique id for the package instance, temporary is given if not able to be loaded
	previousInstanceId?: number // the previous instance id that a loaded form had
	savedInstanceId?: string // the id of a saved instance
	concurrencyToken?: string

	workItem?:
	| ReadWorkItemModel<ForwardedFormPackageWorkItemState>
	| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
	| undefined
}

export type CacheDetailForm = DetailFormWithData & {
	type: FormType
	signedFields: string[]
	cellDefinitions: UnifiedCellDefinition[]
	cellInstances: UnifiedCellInstance[]
}

/*
! This is all the stuff we keep in the IndexDb
*/

export type IndexDbFormPackage = CacheFormPackage & {
	key: string
	intendedUsage: IntendedUsage
	commitDate: Date
}

export enum RetrievalStorageType {
	Received = 'received',
	Saved = 'saved',
}

export type IndexDbRetrievalFormPackage = IndexDbFormPackage & {
	instanceId: number
	uniqueId: string // used as key in index db
	isTemporaryInstanceId: boolean

	intendedUsage: IntendedUsage.Retrieval
	storageType: RetrievalStorageType
}

export const removeFormPackageFromDb = async (key: string) => {
	const database = await retrieveScopedDatabase()

	const transaction = database.transaction(['storedPackageCache'], 'readwrite')
	const storedPackageCache = transaction.objectStore('storedPackageCache')

	await storedPackageCache.delete(key)
	transaction.commit()

	await transaction.done
}

export const commitFormPackageToLocalDb = async (
	formPackage: CacheFormPackage,
	intendedUsage: IntendedUsage,
	packageKey: undefined | string,
): Promise<IndexDbFormPackage> => {
	try {
		const database = await retrieveScopedDatabase()

		const transaction = database.transaction(
			['storedPackageCache'],
			'readwrite',
		)
		const packageCache = transaction.objectStore('storedPackageCache')

		packageKey ??= uuidv4()

		if (packageKey !== formPackage.uniqueInstanceId && formPackage.uniqueInstanceId !== undefined)
			console.warn('form package instance id was populated and has been overwritten, old value: ', formPackage.instanceId, ' new value: ', packageKey)
			

		let dbFormPackage: IndexDbFormPackage
		if (intendedUsage & IntendedUsage.Retrieval) {
			const newValue: IndexDbRetrievalFormPackage = {
				key: packageKey,
				intendedUsage: IntendedUsage.Retrieval | intendedUsage,
				commitDate: new Date(), 

				/* 
				has to be saved b/c only saved packages can be immediately viewed in the saved
				list. Forwarded forms have to go through the server
				*/
				storageType: RetrievalStorageType.Saved, 

				...formPackage,

				// this is scary.
				// are we possibly overwriting an instance id?
				instanceId: 0,
				uniqueId: packageKey,
				
				isTemporaryInstanceId: true,

			}
			dbFormPackage = newValue
		}
		else {
			dbFormPackage = {
				key: packageKey,
				intendedUsage: intendedUsage,
				commitDate: new Date(),
				...formPackage,
			} as IndexDbFormPackage 
		}
		

		dbFormPackage = createSerializableIndexDbFormPackage(dbFormPackage)
		packageCache.put(dbFormPackage, dbFormPackage.key.toString())

		transaction.commit()
		await transaction.done

		return dbFormPackage
	} catch (e) {
		console.error(e)

		throw new Error()
	}
}

/**
 * Create a brand new version of the form package, that has no mobx observables
 * @param originalFormPackage the original form package that will be cloned
 * @returns a cloned version of the form package without mobx observables
 */
const createSerializableIndexDbFormPackage = (
	originalFormPackage: IndexDbFormPackage,
): IndexDbFormPackage => {
	const newFormPackage: IndexDbFormPackage = { ...originalFormPackage }

	// Clone forms - remove observability
	const cloneForms = (forms: CacheDetailForm[]): CacheDetailForm[] => {
		const output: CacheDetailForm[] = []

		for (const form of forms) {
			// things that aren't serializable
			const stream = form.stream
			// serialize to JSON
			const stringFormIndex = JSON.stringify(form)
			const resultForm: CacheDetailForm = JSON.parse(stringFormIndex)

			resultForm.stream = stream

			output.push(resultForm)
		}

		return output
	}

	const cloneAttachments = (
		attachments: TrackedResource[],
	): TrackedResource[] => {
		const output: TrackedResource[] = []

		for (const attachment of attachments) {
			const newAttachment: TrackedResource = {
				file: attachment.file,
				persistenceStatus: attachment.persistenceStatus,
				resourceId: attachment.resourceId,
				type: attachment.type,
				relationalId: attachment.relationalId,
				packageAttachmentType: attachment.packageAttachmentType
			}
			output.push(newAttachment)
		}

		return output
	}

	const cloneConfiguration = (
		settings: FormPackageConfiguration,
	): FormPackageConfiguration => {
		const serializedConfiguration = JSON.stringify(settings)
		return JSON.parse(serializedConfiguration)
	}

	const cloneVersion = (version: FormPackageInfo): FormPackageInfo => {
		return cloneDeep(version)
	}

	const cloneWorkItem = (
		workItem: ReadWorkItemModel<ForwardedFormPackageWorkItemState>,
	): ReadWorkItemModel<ForwardedFormPackageWorkItemState> => {
		return cloneDeep(workItem)
	}

	newFormPackage.forms = cloneForms(originalFormPackage.forms)
	newFormPackage.resources = cloneAttachments(originalFormPackage.resources)
	newFormPackage.configuration = cloneConfiguration(
		originalFormPackage.configuration,
	)
	newFormPackage.packageVersion = cloneVersion(
		originalFormPackage.packageVersion,
	)
	if (originalFormPackage.workItem !== undefined)
		newFormPackage.workItem = cloneWorkItem(originalFormPackage.workItem)

	return newFormPackage
}

export const updateFormPackage = async (
	intendedUsage: IntendedUsage,
	formPackage: CacheFormPackage,
	key: string,
) => {
	await deleteFormPackage(key)
	await commitFormPackageToLocalDb(formPackage, intendedUsage, key)
}

export const deleteFormPackage = async (formPackageKey: string) => {
	const database = await retrieveScopedDatabase()

	const transaction = database.transaction(['storedPackageCache'], 'readwrite')
	const packageCache = transaction.objectStore('storedPackageCache')

	const cachedPackage = await packageCache.get(formPackageKey)
	if (cachedPackage === undefined) {
		transaction.commit()
		await transaction.done
		return
	}

	await packageCache.delete(formPackageKey)

	transaction.commit()
	await transaction.done
}

export const retrieveFormPackageFromLocalDb = async (
	formPackageKey: string,
) => {
	const database = await retrieveScopedDatabase()

	const transaction = database.transaction(['storedPackageCache'], 'readonly')
	const packageCache = transaction.objectStore('storedPackageCache')

	const dbFormPackage: IndexDbFormPackage | undefined = await packageCache.get(
		formPackageKey,
	)
	if (dbFormPackage === undefined) return undefined

	transaction.commit()
	await transaction.done

	return dbFormPackage
}

export const retrieveBlobFromIndexedDb = async (key: string): Promise<Blob> => {
	const database = await retrieveScopedDatabase()

	const transaction = database.transaction(['blobStorage'], 'readonly')

	const blobStorage = transaction.objectStore('blobStorage')

	const result: Blob | undefined = (await blobStorage.get(key))?.data as
		| Blob
		| undefined
	if (result === undefined)
		throw new Error('no blob with key ' + key + ' was found')

	return result
}

export const removeFormPackageFromLocalDb = async (formPackageKey: string) => {
	const database = await retrieveScopedDatabase()

	const transaction = database.transaction(['storedPackageCache'], 'readwrite')

	const packageCache = transaction.objectStore('storedPackageCache')

	const formPackage: IndexDbFormPackage | undefined = await packageCache.get(
		formPackageKey,
	)

	if (formPackage === undefined) return

	await packageCache.delete(formPackageKey)

	transaction.commit()
	await transaction.done
}
