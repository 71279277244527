import { ComponentProps, useState } from 'react'
import {
	PropsEditorComponent,
	ValuesEditorComponent,
	specifyValueTypeComponent,
} from '../../../FormBuilderCore/cells/ComponentSpecification'
import FieldType from '../../../FormHost/Types/FieldType'
import {
	PropertySchematicConfigurator,
	TextPropertyConfigurator,
} from '../../Configuration'
import {
	FormBuilderTextInput,
	TextInputElementTag,
} from './InternalTextInputDefinition'

export const TextInputId = '5b336c02-4008-11eb-b378-0242ac130002'

const TextInputPropsEditor = ({
	properties,
	onPropertiesChanged,
}: PropsEditorComponent<ComponentProps<typeof FormBuilderTextInput>>) => {
	const [hint, setMaskHint] = useState<string>()

	return (
		<>
			<PropertySchematicConfigurator
				value={properties.label}
				onValueChanged={(v) => onPropertiesChanged({ ...properties, label: v })}
				Editor={TextPropertyConfigurator}
				label="Label"
			/>
			<PropertySchematicConfigurator
				value={properties.mask}
				onValueChanged={(v) => {
					if (v === undefined) {
						setMaskHint(undefined)
						onPropertiesChanged({ ...properties, mask: '' })
						return
					}
					// the mask will throw errors and fail if we don't have the following checks
					if (v.includes('{')) {
						// this will always cause a max call size exceeded for the mask
						setMaskHint('Invalid character')
						return
					}

					if (v.startsWith(')') || v.startsWith(']') || v.startsWith('|')) {
						setMaskHint('Invalid start character')
						return
					}

					// if there are more ]'s than ['s or )'s than ('s
					if (
						(v.match(/\]/g) ?? []).length > (v.match(/\[/g) ?? []).length ||
						(v.match(/\)/g) ?? []).length > (v.match(/\(/g) ?? []).length
					) {
						setMaskHint(
							'Closing characters must have a matching opening character',
						)
						return
					}
					setMaskHint(undefined)
					onPropertiesChanged({ ...properties, mask: v })
				}}
				Editor={TextPropertyConfigurator}
				label="Input Mask"
				hint={hint}
				hintColor="error.main"
			/>
		</>
	)
}

const TextInputValueEditor = (
	props: ValuesEditorComponent<ComponentProps<typeof FormBuilderTextInput>>,
) => (
	<PropertySchematicConfigurator
		value={props.value}
		onValueChanged={props.onValueChanged}
		Editor={TextPropertyConfigurator}
		label="Default Value"
	/>
)

export const TextInputDefinition = specifyValueTypeComponent(
	TextInputId,
	FormBuilderTextInput,
	TextInputElementTag,
	'Input',
	FieldType.String,
	{
		configurator: TextInputPropsEditor,
		defaultProperties: {
			label: 'Label',
			mask: undefined,
			hint: undefined,
		},
	},
	{
		configurator: TextInputValueEditor,
		defaultValue: '',
	},
)
