
import { AxiosResponse } from "axios"
import { GroupRoleAssociation, GroupRoleSimpleAssociation } from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class GroupRoleAssociationsClient extends SecureClient {
	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `GroupRoleAssociation`
	}

	/**
	 * Get all associations between groups and roles in the specified organization, with the group and 
	 * role ids being optional constraints
	 * @param groupId 
	 * @param roleId 
	 */
	public GetGroupRoles(groupId?: number, roleId?: number)
		: Promise<AxiosResponse<GroupRoleAssociation[]>> {
		
		return this._api.get<GroupRoleAssociation[]>(
			`${this._urlBase}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				},
				params: {
					roleId,
					groupId
				}
			}
		)
	}

	/**
	 * add a single association between a group and a role in the organization
	 * @param groupRole 
	 */
	public AddGroupRoleAssociation(groupRole: GroupRoleSimpleAssociation)
		: Promise<AxiosResponse> {
		return this._api.post(
			`${this._urlBase}`,
			JSON.stringify(groupRole),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}
	
	/**
	 * Get one association
	 * @param associationId 
	 */
	public GetGroupRoleAssociation(associationId: number)
		: Promise<AxiosResponse<GroupRoleAssociation>> {
		return this._api.get<GroupRoleAssociation>(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
					...this.acceptHeader()
				}
			}
		)
	}

	/**
	 * Remove an association from an organization
	 * @param associationId 
	 */
	public DeleteGroupRoleAssociation(associationId: number)
		: Promise<AxiosResponse> {
		return this._api.delete(
			`${this._urlBase}/${associationId}`,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

	/**
	 * Adds more than one association for an organization
	 * @param groupUsers 
	 */
	public BulkAddGroupRoleAssociation(groupRoles: GroupRoleSimpleAssociation[])
		: Promise<AxiosResponse<number>> {
		return this._api.post(
			`${this._urlBase}/BulkAddition`,
			JSON.stringify(groupRoles),
			{
				headers: {
					...this.authorizationHeader,
					...this.contentTypeHeader()
				}
			}
		)
	}/**
	 * Remove more than one association from an organization
	 * @param ids the ids of the associations to be deleted
	 */
	public BulkDeleteGroupRoleAssociations(ids: number[])
		: Promise<AxiosResponse<number>> {
		return this._api.post(
			`${this._urlBase}/BulkDelete`,
			ids,
			{
				headers: {
					...this.authorizationHeader,
				}
			}
		)
	}

}