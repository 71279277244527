import { AxiosResponse } from "axios"
import SecureClient from "../SecureClient"
import { DataProviderConfiguration } from "./DTO"


export default class DataProvidersClient extends SecureClient {

	/**
	 *
	 */
	constructor() {
		super()
		
	}

	public getDataProviders = (): Promise<AxiosResponse<DataProviderConfiguration[]>> => {
		return this._api.get<DataProviderConfiguration[]>(`DataProviders`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}

	public getDataProvider = (dataProviderId: string): Promise<AxiosResponse<DataProviderConfiguration>> => {
		return this._api.get<DataProviderConfiguration>(`DataProviders/${dataProviderId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader()
			}
		})
	}


}