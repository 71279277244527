import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { observer, useLocalObservable } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import {
	BaseDataProviderInstanceModel,
	DataProviderConfiguration,
} from '../../../../api/clients/dataProviders/DTO'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type CreateDataProviderInstanceDialogProps = {
	dataProviders: DataProviderConfiguration[]
	instanceNames: string[]
	onConfirm: (baseModel: BaseDataProviderInstanceModel) => void
	onCancel: () => void
}

export const CreateDataProviderInstanceDialog: React.FC<CreateDataProviderInstanceDialogProps> =
	observer((props: CreateDataProviderInstanceDialogProps) => {
		const styles = useStyles()

		const upperInstanceNames: string[] = []

		for (const name of props.instanceNames)
			upperInstanceNames.push(name.toUpperCase())

		const localStore = useLocalObservable(() => ({
			instanceName: '',
			dataProvider: props.dataProviders[0] ?? undefined,
			isPosting: false,
			nameExists: false,
		}))

		const handleNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
			localStore.instanceName = event.target.value

			if (
				upperInstanceNames.includes(
					localStore.instanceName.toUpperCase().trim(),
				)
			)
				localStore.nameExists = true
			else localStore.nameExists = false
		}

		const handleDataProviderTypeChanged = (
			event: SelectChangeEvent<string>,
		) => {
			localStore.dataProvider =
				props.dataProviders.find(
					(v) => v.dataProviderId === event.target.value,
				) ?? props.dataProviders[0]
		}

		return (
			<>
				<DialogTitle>Create Data Provider Instance</DialogTitle>
				<DialogContent className={styles.mainDiv}>
					<FixedHeaderWithHint
						label="Name"
						hint={
							localStore.nameExists &&
							'A data provider with this name already exists'
						}
						hintColor="error.main"
						className={styles.textFieldStyle}
					>
						<TextField
							fullWidth
							error={localStore.nameExists}
							value={localStore.instanceName}
							onChange={handleNameChanged}
							disabled={localStore.isPosting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>

					<Select
						className={styles.textFieldStyle}
						defaultValue={localStore.dataProvider?.dataProviderId ?? ''}
						value={localStore.dataProvider?.dataProviderId ?? ''}
						onChange={handleDataProviderTypeChanged}
					>
						{props.dataProviders.map((dataProvider, i) => (
							<MenuItem key={i} value={dataProvider.dataProviderId ?? ''}>
								<div>
									<Typography className={styles.dataProviderName}>
										{dataProvider.displayName}
									</Typography>
									<Typography className={styles.dataProviderDescription}>
										{dataProvider.description}
									</Typography>
								</div>
							</MenuItem>
						))}
					</Select>
					<DialogActions>
						<Button color="primary" onClick={props.onCancel}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={localStore.nameExists || localStore.instanceName === ''}
							onClick={() =>
								props.onConfirm({
									displayName: localStore.instanceName,
									dataProviderId: localStore.dataProvider.dataProviderId,
									properties: {},
									settings: {
										anonymousAccess: false,
									},
								})
							}
						>
							Create
						</Button>
					</DialogActions>
				</DialogContent>
				{localStore.isPosting && <LinearProgress />}
			</>
		)
	})

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},

	dataProviderName: {
		fontWeight: 'bold',
	},

	dataProviderDescription: {
		fontSize: 'small',
		fontStyle: 'italic',
	},
}))
