import { Typography } from '@mui/material'
import { observable, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { BaseFormPackageInstanceModel } from '../../api/DTOtemp'
import { FormPackageInstancesClient } from '../../api/clients/identity/FormPackageInstancesClient'
import {
	ForwardedFormPackageWorkItemState,
	WorkItemPermissions,
	WorkItemResponse,
	WriteWorkItemModel,
} from '../../api/clients/workItems/DTOs'
import { FullscreenSpinner } from '../../components/feedback/circular'
import {
	DetailFormWithData,
	downloadFormPackageInstance,
	getFormPackageInstance,
} from '../../services/offline/FormPackageHandling'
import {
	InstanceCacheFormPackage,
	RetrievalStorageType,
} from '../../services/offline/FormPackageStorage'
import { getWorkItem } from '../../services/offline/WorkItemHandling'
import { SessionService } from '../../services/session'
import { PackageApi, PackageApiStatus } from './PackageApi'
import { PackageHost } from './PackageHost'
import { PackageHostErrorPage } from './PackageHostErrorPage'
import { PackageHostPageContextProvider } from './PackageHostPageContext'
import { useOfflinePackageContext } from './PackageListPageContext'

/**
 * for forwarded forms -- user can be logged in or anonymous
 * route is /_package-host/:packageId/_received/:workItemId for logged in and anonymous
 */
export const ReceivedFormPackageHostPage = observer(() => {
	const params = useParams<{
		packageId: string
		workItemId: string
	}>()

	const offlinePackageContext = useOfflinePackageContext()

	const packageId = parseInt(params.packageId)
	const workItemId = parseInt(params.workItemId)

	const localStore = useLocalObservable(() => ({
		storedPackage: undefined as InstanceCacheFormPackage | undefined,

		forbidden: false,
	}))

	if (isNaN(packageId)) throw new Error('invalid package id')
	if (isNaN(workItemId)) throw new Error('invalid work item id')

	if (
		localStore.storedPackage !== undefined &&
		localStore.storedPackage.workItem === undefined
	) {
		throw new Error(
			'invariant failed - received form package work item was missing',
		)
	}

	const sessionService = new SessionService()
	const isAnonymousUser = !sessionService.isLoggedIn

	const packageApi = useMemo(() => {
		return new PackageApi(packageId)
	}, [packageId])

	useEffect(() => {
		const abortController = new AbortController()

		async function fetchFormsData() {
			try {
				// note that if we're offline then this will get the cached
				// work item, so that means that if we're talking about  locks
				// we have to request the lock again for the form package
				const workItem = (await getWorkItem(
					workItemId,
					WorkItemPermissions.Write,
				)) as
					| WorkItemResponse<ForwardedFormPackageWorkItemState>
					| WriteWorkItemModel<ForwardedFormPackageWorkItemState>
					| undefined
				if (workItem === undefined)
					throw new Error('unable to resolve work item with id ' + workItemId)

				const receivedItemIndex = offlinePackageContext.receivedPackageList.get(
					workItem.workItemState.packageInstanceId.toString(),
				)

				let packageInstance: InstanceCacheFormPackage | undefined =
					await getFormPackageInstance(workItem.workItemState.uniqueInstanceId)

				if (navigator.onLine) {
					// TODO - possible error - where is the package instance id coming from?
					try {
						packageInstance = await downloadFormPackageInstance(
							RetrievalStorageType.Received,
							workItem.workItemState.uniqueInstanceId,
							receivedItemIndex,
							abortController.signal,
							undefined,
							workItem,
						)
					} catch (error) {
						console.error(error)
					}
				} else if (packageInstance === undefined && !navigator.onLine) {
					console.error('form package is not downloaded in cache')
					runInAction(() => {
						localStore.forbidden = true
					})
					return
				}

				runInAction(() => {
					if (abortController.signal.aborted) return

					localStore.storedPackage = packageInstance
					localStore.forbidden = false
				})
			} catch (e) {
				console.error(e)

				// we don't want to do anything about cancelled because
				// it could be the component re-mounting

				if (abortController.signal.aborted) {
					console.log(
						'ignoring error as cancelled status indicates method wil re-run',
					)
					return
				}

				runInAction(() => {
					localStore.forbidden = true
				})
			}
		}

		fetchFormsData()

		return () => {
			abortController.signal
		}
	}, [packageId])

	if (localStore.forbidden)
		return (
			<PackageHostErrorPage
				packageId={packageId}
				errorMessage={
					<>
						<Typography variant="h6">
							You don't have access to this package
						</Typography>{' '}
						<Typography variant="h6">or this package doesn't exist.</Typography>
					</>
				}
			/>
		)

	if (
		localStore.storedPackage === undefined ||
		packageApi.status !== PackageApiStatus.Complete
	)
		return <FullscreenSpinner />

	// TODO - have to handle saved instance id

	const packageInstance = localStore.storedPackage

	console.log('stored package work item: ', localStore.storedPackage.workItem)

	return (
		<PackageHostPageContextProvider validationErrors={observable({})}>
			<PackageHost
				packageId={packageId}
				packageApi={packageApi}
				formPackage={packageInstance.packageVersion}
				resources={packageInstance.resources}
				forms={packageInstance.forms}
				workItem={localStore.storedPackage.workItem}
				savedInstanceId={packageInstance.uniqueInstanceId}
				onSaveInstance={handleCompleteApproval}
				anonymous={isAnonymousUser}
				isPackageFromServer={true}
			/>
		</PackageHostPageContextProvider>
	)
})

// create new instance for approved/rejected package
export const handleCompleteApproval = async (
	baseFormPackageInstance: BaseFormPackageInstanceModel,
	forms: DetailFormWithData[],
): Promise<number> => {
	const instancesClient = new FormPackageInstancesClient()
	if (instancesClient === undefined)
		throw new Error('instances client is undefined')

	const { data: newInstance } = await instancesClient.CreateFormPackageInstance(
		baseFormPackageInstance,
		forms,
	)

	return newInstance.id
}
