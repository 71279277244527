import { Theme } from '@mui/material'
import { blue } from '@mui/material/colors'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useCallback, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { NIL } from 'uuid'
import { DraggableFormHost } from '../../DraggableFormHost'
import {
	DraggableItemType,
	isDraggableComponent,
	isDraggableDefinition,
} from '../../Types'
import { useFormBuilderDragAndDropContext } from '../DragAndDropContext'
import { useGeneralStyles } from './General'

type InsertDustbinProps = {
	parentDefinitionId: string
	insertionIndex: number

	formHost: DraggableFormHost
}

export const InsertDustbinComponent = (props: InsertDustbinProps) => {
	const styles = useStyles()
	const standardStyles = useGeneralStyles()

	const context = useFormBuilderDragAndDropContext()

	const [{ anythingDragging, isOver, canDrop }, drop] = useDrop({
		accept: DraggableItemType.FormBuilderComponent,
		collect: (monitor) => ({
			anythingDragging: !!monitor.getItem(),
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: !!monitor.canDrop(),
		}),
		drop: (item, monitor) => {
			if (monitor.didDrop()) return

			if (isDraggableDefinition(item)) {
				props.formHost.insertDefinition(
					props.parentDefinitionId,
					item.definitionId,
					props.insertionIndex,
				)
			}

			if (isDraggableComponent(item)) {
				props.formHost.insertComponent(
					props.parentDefinitionId,
					item.componentRegistrationId,
					props.insertionIndex,
				)
			}
		},
	})

	const classNames = useMemo(
		() =>
			clsx(
				standardStyles.dropContainerStyle,
				styles.hiddenDustbin,
				{ [styles.visibleDropZone]: anythingDragging },
				{ [styles.hideBorder]: !anythingDragging },
			),
		[anythingDragging],
	)

	const handleClickCapture = useCallback(() => {
		context.currentlySelectedDefinition = NIL
	}, [context])

	return (
		<div className={classNames} ref={drop} onClickCapture={handleClickCapture}>
			<div className={isOver && canDrop ? styles.hoverOverlay : styles.noHover}>
				<div className={styles.textTheme}></div>
			</div>
		</div>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	hideBorder: {
		borderStyle: 'none',
		// margin: theme.spacing(.5) // so a 2px border corresponds with a 6px margin 🤷‍♂️
	},

	visibleDropZone: {
		backgroundColor: blue[50],
	},

	hiddenDustbin: {
		minHeight: theme.spacing(2),
		cursor: 'auto',
	},

	textTheme: {
		display: 'flex',
		flex: '1',

		textAlign: 'center',
		justifyContent: 'center',

		color:
			theme.palette.mode === 'light'
				? theme.palette.grey[400]
				: theme.palette.grey[600],
	},

	noHover: {
		visibility: 'hidden',
	},

	hoverOverlay: {
		flex: 1,

		opacity: '1',
		backgroundColor: blue[400],
	},

	iconStyle: {
		margin: theme.spacing(1),
	},
}))
