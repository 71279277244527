import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { InstanceIdAndName } from './DataProvidersDashboard'

type DeleteDataProviderDialogProps = {
	instance: InstanceIdAndName
	onConfirm: () => void
	onCancel: () => void
}

export const DeleteDataProviderDialog: React.FC<
	DeleteDataProviderDialogProps
> = (props: DeleteDataProviderDialogProps) => {
	return (
		<>
			<DialogContent>
				<Typography>
					Are you sure you want to delete <b>{props.instance.name}</b>?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Delete
				</Button>
			</DialogActions>
		</>
	)
}
