import { Delete } from '@mui/icons-material'
import { FormControl, IconButton, MenuItem, Select, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { px } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import React, { useEffect } from 'react'
import SignaturePad from 'signature_pad'
import { useSignatureStore } from './SignatureModalStore'

type DrawSignatureProps = Record<string, unknown>

export const DrawSignature: React.FC<DrawSignatureProps> = observer(() => {
	const store = useSignatureStore()
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		color: 'black',
	}))

	const generatorCanvasReference = React.createRef<HTMLCanvasElement>()

	type Clear = {
		onClear?: () => void
	}

	const clearObject: Clear = {}

	useEffect(
		action(() => {
			if (!generatorCanvasReference) throw 'cannot find canvas'

			const generatorCanvas = generatorCanvasReference.current
			if (!generatorCanvas) throw 'the canvas was null'

			const generatorCanvasContext = generatorCanvas.getContext('2d')
			if (!generatorCanvasContext) throw 'failed to fetch canvas context'

			// we need a 3:1 aspect ratio so the signature will fit better on the form
			generatorCanvas.width = generatorCanvas.height * 3

			generatorCanvasContext.fillStyle = '#ffffff'
			generatorCanvasContext.fillRect(
				0,
				0,
				generatorCanvas.width,
				generatorCanvas.height,
			)
			generatorCanvasContext.fillStyle = '#000'

			const image = new Image()
			image.src = store.signatureData.drawSignatureValue ?? ''

			image.onload = () => {
				generatorCanvasContext.drawImage(image, 0, 0, image.width, image.height)
			}

			const signaturePad = new SignaturePad(generatorCanvas, {
				backgroundColor: 'white',
				penColor: localStore.color,
			})

			const saveDrawing = action(() => {
				store.signatureData.drawSignatureValue = signaturePad.toDataURL()
			})

			signaturePad.addEventListener('endStroke', saveDrawing)

			clearObject.onClear = action(() => {
				signaturePad.clear()

				store.signatureData.drawSignatureValue = ''
			})

			return () => {
				signaturePad.off()
			}
		}),
	)

	return (
		<div className={styles.drawSection}>
			<canvas
				id={'signature-drawing'}
				ref={generatorCanvasReference}
				className={styles.canvas}
			></canvas>
			<div className={styles.canvasActions}>
				<FormControl>
					<Select
						value={localStore.color}
						sx={{
							color: localStore.color,
							backgroundColor: (theme) => theme.palette.grey[200],
						}}
						size="small"
					>
						<MenuItem
							onClick={action(() => (localStore.color = 'black'))}
							value={'black'}
						>
							Black
						</MenuItem>
						<MenuItem
							style={{ color: 'red' }}
							onClick={action(() => (localStore.color = 'red'))}
							value={'red'}
						>
							Red
						</MenuItem>
						<MenuItem
							style={{ color: 'blue' }}
							onClick={action(() => (localStore.color = 'blue'))}
							value={'blue'}
						>
							Blue
						</MenuItem>
					</Select>
				</FormControl>
				<IconButton
					onClick={() => clearObject.onClear && clearObject.onClear()}
				>
					<Delete />
				</IconButton>
			</div>
		</div>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	drawSection: {
		margin: theme.spacing(2),
		display: 'grid',
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridTemplateRows: 'repeat(4, 1fr)',
		gridColumnGap: px(0),
		gridRowGap: px(0),
	},

	canvas: {
		border: 'solid',
		gridArea: '1 / 2 / 4 / 5',
		placeSelf: 'center',
	},

	canvasActions: {
		gridArea: ' 4 / 4 / 5 / 5',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}))
