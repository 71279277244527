import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import { GroupReduced } from '../../../../api/DTOtemp'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type CreateGroupDialogProps = {
	open: boolean
	groupNames: string[]
	createGroup: (group: GroupReduced) => void
	onClose: () => void
}

export const CreateGroupDialog = observer((props: CreateGroupDialogProps) => {
	const styles = useStyles()
	const upperGroupNames: string[] = []

	// list used to check if a group name already exists
	for (const name of props.groupNames) upperGroupNames.push(name.toUpperCase())

	const validationSchema = yup.object({
		groupName: yup
			.string()
			.required('Name is a required field')
			.test(
				'is-unique',
				'A group with this name already exists in your organization',
				(value) => {
					return (
						value !== undefined &&
						!upperGroupNames.includes(value.toUpperCase().trim())
					)
				},
			),
		description: yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			groupName: '',
			description: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			props.createGroup({
				name: values.groupName,
				description: values.description,
			})
		},
	})

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Create a New Group</DialogTitle>
			<DialogContent className={styles.mainDiv}>
				<Typography>
					Create a new group within the current organization.
				</Typography>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.touched.groupName && formik.errors.groupName}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						fullWidth
						id="groupName"
						name="groupName"
						value={formik.values.groupName}
						error={!!formik.errors.groupName && formik.touched.groupName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint
					label="Description"
					hint={formik.touched.description && formik.errors.description}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						fullWidth
						id="description"
						name="description"
						value={formik.values.description}
						error={!!formik.errors.description && formik.touched.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<DialogActions>
					<Button color="primary" onClick={props.onClose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						disabled={formik.isSubmitting || !formik.dirty}
						onClick={formik.submitForm}
					>
						Create
					</Button>
				</DialogActions>
			</DialogContent>
			{formik.isSubmitting && <LinearProgress />}
		</Dialog>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},
}))
