import { Add, Delete, Edit } from '@mui/icons-material'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action } from 'mobx'
import { useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { FormPackageClient } from '../../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../../api/clients/identity/FormPackageVersionsClient'
import {
	FormPackageEventAction,
	FormPackageInfo,
} from '../../../../../../../../api/DTOtemp'
import IconButtonLink from '../../../../../../../../components/links/IconButtonLink'
import { getGlobalEventActionRegistry } from '../../../../../../../../modules/FormHost/EventActions/EventActionRegistry'
import { IEventAction } from '../../../../../../../../modules/FormHost/EventActions/IEventAction'
import { toastService } from '../../../../../../../../services/notifications/ToastService'
import { FormEvents } from '../../../../../../../../utils/constants/FormEvents'
import { makeChildRoute } from '../../../../../../../../utils/CreateChildRoute'
import {
	FixedHeader,
	FixedHeaderWithHint,
} from '../../../../../../../../utils/HOC/FixedHeaders'
import AdministrationPageContainer from '../../../../../AdministrationPageContainer'

type EventPageProps = {
	packageId: number
}

const eventActionDefinitions = getGlobalEventActionRegistry().actionCollection

export const EventPage = observer((props: EventPageProps) => {
	const styles = useStyles()
	const { eventId } = useParams<{ eventId: string }>()
	const { url } = useRouteMatch()
	const history = useHistory()

	const eventName = FormEvents[eventId].name

	const formPackageVersionClient = new FormPackageVersionClient(props.packageId)

	const localStore = useLocalObservable(() => ({
		formPackage: undefined as FormPackageInfo | undefined,
		actions: [] as FormPackageEventAction[],
	}))

	useEffect(() => {
		const formPackageClient = new FormPackageClient()

		formPackageClient.GetFormPackage(props.packageId).then(
			action((response) => {
				localStore.formPackage = response.data
				localStore.actions = response.data.configuration.eventActions.filter(
					(v) => v.eventId === eventId,
				)
			}),
		)
	}, [props.packageId])

	const handleAddAction = (action: FormPackageEventAction) => {
		if (localStore.formPackage === undefined)
			throw new Error('form package is undefined')

		if (localStore.formPackage.configuration.eventActions !== undefined)
			localStore.formPackage.configuration.eventActions.push(action)

		formPackageVersionClient
			.UpdateConfiguration(localStore.formPackage)
			.then(() => {
				toastService.displayToast({
					message: 'Action added',
					area: 'global',
				})

				history.push(
					`/_administration/_form-packages/${props.packageId}/_events/${action.eventId}/_actions/${action.id}`,
				)
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error adding action',
					area: 'global',
				})
			})
	}

	const handleDeleteAction = (actionId: string) => {
		if (localStore.formPackage === undefined)
			throw new Error('form package is undefined')

		const index = localStore.formPackage.configuration.eventActions.findIndex(
			(v) => v.id === actionId,
		)
		if (index < 0) return

		localStore.formPackage.configuration.eventActions.splice(index, 1)
		localStore.actions = localStore.formPackage.configuration.eventActions

		formPackageVersionClient.UpdateConfiguration(localStore.formPackage)
	}

	return (
		<AdministrationPageContainer
			title={`${eventName} Event`}
			actions={<ActionsButtons onAddAction={handleAddAction} />}
		>
			<Fade in unmountOnExit>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Action</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{localStore.actions.map((action) => (
								<TableRow key={action.id}>
									<TableCell>{action.name}</TableCell>
									<TableCell>{action.description}</TableCell>
									<TableCell align="right">
										<IconButtonLink
											to={makeChildRoute(`_actions/${action.id}`, url)}
										>
											<Edit />
										</IconButtonLink>
										<IconButton onClick={() => handleDeleteAction(action.id)}>
											<Delete />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		</AdministrationPageContainer>
	)
})

type ActionsButtonsProps = {
	onAddAction: (action: FormPackageEventAction) => void
}

export const ActionsButtons = (props: ActionsButtonsProps) => {
	const [dialogOpen, setDialogOpen] = useState(false)

	const handleOpenDialog = useCallback(() => {
		setDialogOpen(true)
	}, [])

	const handleCloseDialog = useCallback(() => {
		setDialogOpen(false)
	}, [])

	return (
		<>
			<div>
				<Button
					color="primary"
					variant="contained"
					onClick={handleOpenDialog}
					startIcon={<Add />}
				>
					Add Action
				</Button>
			</div>
			<AddActionDialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				actions={eventActionDefinitions}
				onAddAction={props.onAddAction}
			/>
		</>
	)
}

type AddActionDialogProps = {
	open: boolean
	actions: Record<string, IEventAction>
	onClose: () => void
	onAddAction: (action: FormPackageEventAction) => void
}

const AddActionDialog = observer((props: AddActionDialogProps) => {
	const styles = useStyles()
	const params = useParams<{ packageId: string; eventId: string }>()

	const packageId = parseInt(params.packageId)
	const eventId = params.eventId

	if (isNaN(packageId)) throw new Error('invalid package id')

	const localStore = useLocalObservable(() => ({
		selectedActionId: '',
		formPackage: undefined as FormPackageInfo | undefined,
	}))

	useEffect(() => {
		const formPackageClient = new FormPackageClient()
		formPackageClient.GetFormPackage(packageId).then((response) => {
			localStore.formPackage = response.data
		})
	}, [packageId])

	const validationSchema = yup.object({
		name: yup.string().required(),
		description: yup.string(),
	})

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			const definition = eventActionDefinitions[localStore.selectedActionId]

			props.onAddAction({
				id: uuidv4(),
				definitionId: localStore.selectedActionId,
				name: values.name,
				description: values.description,
				eventId: eventId,
				value: definition.defaultValue,
			})
		},
	})

	const handleEventChange = action((event: SelectChangeEvent) => {
		localStore.selectedActionId = event.target.value as string
	})

	return (
		<Dialog fullWidth open={props.open} onClose={props.onClose}>
			<DialogTitle>Add Action</DialogTitle>
			<DialogContent>
				<FixedHeaderWithHint
					label="Name"
					hint={formik.touched.name && formik.errors.name}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						error={!!formik.errors.name && formik.touched.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint
					label="Description"
					hint={formik.touched.description && formik.errors.description}
					hintColor="error.main"
					className={styles.textFieldStyle}
				>
					<TextField
						fullWidth
						id="description"
						name="description"
						value={formik.values.description}
						error={!!formik.errors.description && formik.touched.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeader label="Action Type" className={styles.textFieldStyle}>
					<FormControl fullWidth>
						<Select
							value={localStore.selectedActionId}
							onChange={handleEventChange}
						>
							{Object.keys(props.actions).map((actionId) => (
								<MenuItem key={actionId} value={actionId}>
									<div className={styles.actionType}>
										<Typography color="textPrimary" variant="subtitle2">
											{props.actions[actionId].actionName}
										</Typography>
										<Typography color="textSecondary" variant="body2">
											{props.actions[actionId].description}
										</Typography>
									</div>
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</FixedHeader>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onClose}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={formik.submitForm}>
					Add Action
				</Button>
			</DialogActions>
		</Dialog>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},

	actionType: {
		display: 'flex',
		flexDirection: 'column',
	},
}))
