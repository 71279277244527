import { observer } from 'mobx-react'
import { Redirect, Route, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../utils/CreateChildRoute'
import { resetBreadcrumbs } from '../Organization/Administration/Breadcrumbs'
import ReceivedPackageListPage from './ReceivedPackageListPage'
import { SavedPackageListPage } from './SavedPackageListPage'
import { UserPackageListPage } from './UserPackageListPage'

const PackageListRouter = observer(() => {
	const { path, url } = useRouteMatch()

	resetBreadcrumbs('/_form-packages', 'Form Packages')

	return (
		<>
			<Route path={makeChildRoute('_allowed-form-packages', path)}>
				<UserPackageListPage />
			</Route>
			<Route path={makeChildRoute('_saved-form-packages', path)}>
				<SavedPackageListPage />
			</Route>
			<Route path={makeChildRoute('_received-form-packages', path)}>
				<ReceivedPackageListPage />
			</Route>

			<Route exact path={makeChildRoute('', path)}>
				<Redirect to={makeChildRoute('_allowed-form-packages', url)} />
			</Route>
		</>
	)
})

export default PackageListRouter
