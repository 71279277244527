import { observer } from 'mobx-react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { makeChildRoute } from '../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../Breadcrumbs'
import { SecretPage } from './SecretPage'
import { VaultStorageDashboard } from './VaultStorageDashboard'

export const VaultStorageRouter = observer(() => {
	const { path } = useRouteMatch()

	updateBreadcrumbs(`/_administration/_vault-storage`, 'Vault Storage')

	return (
		<Switch>
			<Route path={makeChildRoute(':secretId', path)}>
				<SecretPage />
			</Route>
			<Route path={makeChildRoute('')}>
				<VaultStorageDashboard />
			</Route>
		</Switch>
	)
})
