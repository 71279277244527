import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { GroupsClient } from '../../../../api/clients/identity'
import { BaseGroup } from '../../../../api/DTO'
import { GroupReduced } from '../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { GroupOptionsAdministrationPage } from './GroupOptionsAdministrationPage'
import { GroupRolesAdministrationPage } from './GroupRolesSection/GroupRolesAdministrationPage'
import { GroupUsersAdministrationPage } from './GroupUsersSection/GroupUsersAdministrationPage'

// the props for the GroupUsers and GroupRoles Administration Pages
export type GroupAdministrationPageProps = {
	group: BaseGroup
	tabLabels: string[]
	onTabChanged: (event: React.SyntheticEvent, newValue: number) => void
}

export const GroupPage = observer(() => {
	const params = useParams<{ groupId: string }>()
	const groupId = parseInt(params.groupId)
	if (isNaN(groupId)) throw new Error('invalid group id')

	const groupsClient = new GroupsClient()

	const localStore = useLocalObservable(() => ({
		group: undefined as BaseGroup | undefined,
		selectedTabIndex: 0,
		upperGroupNames: [] as string[], // uppercase - to be passed into Options to make sure names are unique when changed
	}))

	const handleTabIndexChanged = action(
		useCallback((event: React.SyntheticEvent, newValue: number) => {
			localStore.selectedTabIndex = newValue
		}, []),
	)

	const handleUpdateGroup = action(
		(groupId: number, updatedGroup: GroupReduced) => {
			if (localStore.group === undefined) throw new Error('group is undefined')

			groupsClient.UpdateGroup(updatedGroup, groupId)

			const index = localStore.upperGroupNames.indexOf(
				localStore.group.name.toUpperCase(),
			)

			localStore.upperGroupNames[index] = updatedGroup.name.toUpperCase()
			localStore.group.name = updatedGroup.name
		},
	)

	useEffect(() => {
		groupsClient.GetGroup(groupId).then(
			action((response) => {
				localStore.group = response.data
			}),
		)

		groupsClient.GetAllGroups().then(
			action((response) => {
				localStore.upperGroupNames = response.data.map((v) =>
					v.name.toUpperCase(),
				)
			}),
		)
	}, [localStore])

	const tabLabels = ['Options', 'Users', 'Roles']

	const tabSelector = (group: BaseGroup) => {
		if (localStore.selectedTabIndex === 0)
			return (
				<GroupOptionsAdministrationPage
					group={group}
					onTabChanged={handleTabIndexChanged}
					tabLabels={tabLabels}
					groupNames={localStore.upperGroupNames}
					onUpdateGroup={handleUpdateGroup}
				/>
			)
		if (localStore.selectedTabIndex === 1)
			return (
				<GroupUsersAdministrationPage
					group={group}
					onTabChanged={handleTabIndexChanged}
					tabLabels={tabLabels}
				/>
			)
		if (localStore.selectedTabIndex === 2)
			return (
				<GroupRolesAdministrationPage
					group={group}
					onTabChanged={handleTabIndexChanged}
					tabLabels={tabLabels}
				/>
			)

		throw new Error(
			`the selected tab index ${localStore.selectedTabIndex} is out of bounds`,
		)
	}

	return (
		<>
			{localStore.group === undefined ? (
				<FullscreenSpinner />
			) : (
				tabSelector(localStore.group)
			)}
		</>
	)
})
