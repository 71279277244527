import { Box, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Node } from 'estree'
import { observer } from 'mobx-react'
import useColorScheme from '../codegen/internal/useColorScheme'
import { PartiallyConstructedNode } from '../Types'
import { DraggableComponent } from './DraggableComponent'
import { ComponentCategory } from './NodeComponents'

type NodeComponentEntryProps = {
	defaultNode: PartiallyConstructedNode<Node>
	displayName: string
	category: ComponentCategory
}

// not the actual node - what we drag over when we want to place a node somewhere
const NodeComponentEntry = observer((props: NodeComponentEntryProps) => {
	const styles = useStyles()

	const [color, textColor] = useColorScheme(props.category)

	return (
		<DraggableComponent node={props.defaultNode}>
			<Box
				component={Paper}
				padding={1}
				elevation={1}
				className={styles.draggable}
				bgcolor={color}
			>
				<Typography color={textColor}>{props.displayName}</Typography>
			</Box>
		</DraggableComponent>
	)
})

const useStyles = makeStyles(() => ({
	draggable: {
		cursor: 'move',
	},
}))

export default NodeComponentEntry
