import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	LinearProgress,
	TextField,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'
import { FixedHeaderWithHint } from '../../../../../utils/HOC/FixedHeaders'
import { getPackageValidationSchema } from '../FormPackageUtils'

type PackageCreateDialogProps = {
	open: boolean
	existingPackageNames: string[]
	onCreatePackage: (name: string, description: string) => void
	onClose: () => void
}

export const PackageCreateDialog = observer(
	(props: PackageCreateDialogProps) => {
		const styles = useStyles()

		const upperPackageNames = props.existingPackageNames.map((v) =>
			v.toUpperCase(),
		)

		const formik = useFormik({
			initialValues: {
				name: '',
				description: '',
			},
			validationSchema: getPackageValidationSchema(upperPackageNames),
			onSubmit: (values) => {
				props.onCreatePackage(values.name, values.description)
			},
		})

		return (
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Create Form Package</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Create a new form package with the specified name and description.
						Name must be unique.
					</DialogContentText>
					<div className={styles.mainDiv}>
						<FixedHeaderWithHint
							label="Package Name"
							className={styles.textFieldStyle}
							hint={formik.errors.name}
							hintColor="error.main"
						>
							<TextField
								fullWidth
								id="name"
								name="name"
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={!!formik.errors.name && formik.touched.name}
								disabled={formik.isSubmitting}
								autoComplete="off"
							/>
						</FixedHeaderWithHint>
						<FixedHeaderWithHint
							label="Description"
							className={styles.textFieldStyle}
							hint={formik.errors.description}
							hintColor="error.main"
						>
							<TextField
								fullWidth
								id="description"
								name="description"
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								multiline
								error={
									!!formik.errors.description && formik.touched.description
								}
								disabled={formik.isSubmitting}
								autoComplete="off"
							/>
						</FixedHeaderWithHint>
					</div>
					<DialogActions>
						<Button color="primary" onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							onClick={formik.submitForm}
							color="primary"
							variant="contained"
							disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
						>
							Create
						</Button>
					</DialogActions>
				</DialogContent>
				{formik.isSubmitting && <LinearProgress />}
			</Dialog>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},
}))
