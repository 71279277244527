import {
	Button,
	Checkbox,
	Fade,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { ChangeEvent, useCallback } from 'react'
import { BaseRole } from '../../../../../api/DTO'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'

type GroupRolesTableProps = {
	roles: BaseRole[]
	onRemoveRoles: (roleIds: number[]) => Promise<void>
	isRequesting: boolean
}

export const GroupRolesTable = observer(
	({ roles, onRemoveRoles, isRequesting }: GroupRolesTableProps) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			selectedRoles: [] as number[],
		}))

		// select if unselected, unselect if already selected
		const handleSelectOneRole = action((roleId: number) => {
			if (!localStore.selectedRoles.includes(roleId)) {
				localStore.selectedRoles.push(roleId)
				return
			}
			const index = localStore.selectedRoles.indexOf(roleId)
			if (index > -1) localStore.selectedRoles.splice(index, 1)
		})

		// select all if some are unselected, unselect if all are already selected
		const handleSelectAllRoles = action(
			(event: ChangeEvent<HTMLInputElement>) => {
				if (event.target.checked)
					localStore.selectedRoles = roles.map((role) => role.id)
				else localStore.selectedRoles = []
			},
		)

		const roleIsChecked = useCallback(
			(roleId: number) => {
				return localStore.selectedRoles.includes(roleId)
			},
			[localStore.selectedRoles],
		)

		// header checkbox is checked if all roles are selected
		const headerCheckboxChecked = useCallback(() => {
			return (
				roles.length === localStore.selectedRoles.length && roles.length !== 0
			)
		}, [roles, localStore.selectedRoles])

		// header checkbox is indeterminate if some roles are selected
		const headerCheckboxIndeterminate = useCallback(() => {
			return (
				localStore.selectedRoles.length !== 0 &&
				roles.length !== localStore.selectedRoles.length
			)
		}, [roles, localStore.selectedRoles])

		const handleRemoveRoles = action(() => {
			onRemoveRoles(localStore.selectedRoles)
			localStore.selectedRoles = []
		})

		if (isRequesting) return <FullscreenSpinner />

		return (
			<Fade in>
				<TableContainer className={styles.tableRoot} component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox">
									<Checkbox
										color="primary"
										checked={headerCheckboxChecked()}
										indeterminate={headerCheckboxIndeterminate()}
										onChange={(event) => handleSelectAllRoles(event)}
									/>
								</TableCell>
								{localStore.selectedRoles.length === 0 ? (
									<>
										<TableCell>Name</TableCell>
										<TableCell>Description</TableCell>
									</>
								) : (
									<>
										<TableCell>
											<Button
												color="primary"
												variant="outlined"
												onClick={handleRemoveRoles}
												disabled={isRequesting}
												size="small"
											>
												Remove
											</Button>
										</TableCell>
										<TableCell />
									</>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{roles.map((role) => (
								<TableRow key={role.id}>
									<TableCell padding="checkbox">
										<Checkbox
											color="primary"
											checked={roleIsChecked(role.id)}
											value={roleIsChecked(role.id)}
											onChange={() => handleSelectOneRole(role.id)}
										/>
									</TableCell>
									<TableCell>{role.friendlyName}</TableCell>
									<TableCell>{role.description}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Fade>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	tableRoot: {
		width: percent(100),
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		overflow: 'auto',
	},
}))
