import { observable } from "mobx"
import React, { createContext, PropsWithChildren, useContext } from "react"
import { FormBuilderSchema } from "../../../../FormBuilderCore/Types"

type ContextType = {
	currentlySelectedDefinition: string
	schema: FormBuilderSchema
}

const dragAndDropContext = createContext<ContextType | undefined>(undefined)

export const FormBuilderDragAndDropContextProvider = (props: PropsWithChildren<ContextType>) => {
	console.log('creating new dnd provider')
	const value: ContextType = observable({
		currentlySelectedDefinition: props.currentlySelectedDefinition,
		schema: props.schema
	})
	return (<dragAndDropContext.Provider
		value={value}
	>
		{props.children}
	</dragAndDropContext.Provider>)
}

export function useFormBuilderDragAndDropContext() {
	const context = useContext(dragAndDropContext)
	if (context === undefined)
		throw `drag and drop context was undefined`
	return context
}