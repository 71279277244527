import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material'

type ConfirmDeleteWorkflowDialogProps = {
	name: string
	onConfirm: () => void
	onCancel: () => void
}

export const ConfirmDeleteWorkflowDialog = (
	props: ConfirmDeleteWorkflowDialogProps,
) => {
	return (
		<>
			<DialogTitle>Confirm Delete Workflow</DialogTitle>
			<DialogContent>
				<Typography>
					Are you sure that you want to remove the <b>{props.name}</b> workflow?
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button color="primary" variant="contained" onClick={props.onConfirm}>
					Confirm
				</Button>
			</DialogActions>
		</>
	)
}
