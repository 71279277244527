import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	LinearProgress,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import * as yup from 'yup'
import { UserPropertySimple } from '../../../../api/DTOtemp'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'

type CreateUserPropertyDialogProps = {
	open: boolean
	userPropertyNames: string[]
	createUserProperty: (userProperty: UserPropertySimple) => void
	onClose: () => void
}

export const CreateUserPropertyDialog = observer(
	(props: CreateUserPropertyDialogProps) => {
		const styles = useStyles()
		const upperPropertyNames: string[] = []

		// list used to check if a user property name already exists
		for (const name of props.userPropertyNames)
			upperPropertyNames.push(name.toUpperCase())

		const validationSchema = yup.object({
			userPropertyName: yup
				.string()
				.required('Name is a required field')
				.test(
					'is-unique',
					'A user property with this name already exists',
					(value) => {
						return (
							value !== undefined &&
							!upperPropertyNames.includes(value.toUpperCase().trim())
						)
					},
				),
			userEditable: yup.boolean(),
		})

		const formik = useFormik({
			initialValues: {
				userPropertyName: '',
				userEditable: false,
			},
			validationSchema: validationSchema,
			onSubmit: (values) => {
				props.createUserProperty({
					name: values.userPropertyName,
					userEditable: values.userEditable,
				})
			},
		})

		return (
			<Dialog open={props.open} onClose={props.onClose}>
				<DialogTitle>Create a New User Property</DialogTitle>
				<DialogContent className={styles.mainDiv}>
					<Typography>
						Create a new user property within the current organization.
					</Typography>
					<FixedHeaderWithHint
						label="Name"
						hint={
							formik.touched.userPropertyName && formik.errors.userPropertyName
						}
						hintColor="error.main"
						className={styles.textFieldStyle}
					>
						<TextField
							fullWidth
							id="userPropertyName"
							name="userPropertyName"
							value={formik.values.userPropertyName}
							error={
								!!formik.errors.userPropertyName &&
								formik.touched.userPropertyName
							}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>

					<FormControlLabel
						control={
							<Checkbox
								className={styles.textFieldStyle}
								id="userEditable"
								name="userEditable"
								value={formik.values.userEditable}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								disabled={formik.isSubmitting}
							/>
						}
						label="User Editable"
					/>
					<DialogActions>
						<Button color="primary" onClick={props.onClose}>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
							onClick={formik.submitForm}
						>
							Create
						</Button>
					</DialogActions>
				</DialogContent>
				{formik.isSubmitting && <LinearProgress />}
			</Dialog>
		)
	},
)

const useStyles = makeStyles((theme: Theme) => ({
	mainDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	textFieldStyle: {
		margin: theme.spacing(2, 0),
	},
}))
