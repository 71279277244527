import { NIL } from "uuid"
import { IConcreteCellInstance } from "../../../FormHost/Types/CellInstance"
import { CellType } from "../../../FormHost/Types/CellType"
import FieldType, { isValueType } from "../../../FormHost/Types/FieldType"
import { HtmlCellDefinition } from "../Types/HtmlCellDefinition"
import { HtmlCellInstance } from "../Types/HtmlCellInstance"

export function instancesToObject(
	cellDefinitions: HtmlCellDefinition[],
	cellInstances: HtmlCellInstance[],
	parentInstanceId: string = NIL,
): Record<string, unknown> {
	const childCells = cellInstances.filter(
		(v) => v.parentId === parentInstanceId,
	)

	const obj = {} as Record<string, unknown>

	for (const cell of childCells) {
		const recastCell = cell as IConcreteCellInstance

		const definition = cellDefinitions.find(
			(v) => v.id === recastCell.definitionId,
		)
		if (definition === undefined)
			throw Error(`no definition with id ${recastCell.definitionId} exists`)

		if (isValueType(cell.fieldType) && cell.type === CellType.Value)
			obj[definition.name] = cell.value

		if (cell.fieldType === FieldType.Object)
			obj[definition.name] = instancesToObject(
				cellDefinitions,
				cellInstances,
				cell.id,
			)

		if (cell.fieldType === FieldType.ObjectArray)
			obj[definition.name] = deconstructRepeatableSection(
				cellDefinitions,
				cellInstances,
				cell.id,
			)
	}

	return obj
}

function deconstructRepeatableSection(
	cellDefinitions: HtmlCellDefinition[],
	cellInstances: HtmlCellInstance[],
	parentInstanceId: string,
) {
	const array = []

	const virtualChildren = cellInstances.filter(
		(v) => v.parentId === parentInstanceId,
	)

	for (const virtualChild of virtualChildren) {
		if (virtualChild.type === CellType.Virtual)
			// only virtual children should be parented by repeatable sections
			array[virtualChild.ordinalPosition] = instancesToObject(
				cellDefinitions,
				cellInstances,
				virtualChild.id,
			)
	}

	return array
}
