import { DynamicProgram } from "./Types";
export * from "./Types";

export function evaluateDynamicProgram(program: DynamicProgram, args: Record<string, unknown>): Promise<unknown> {
	const jsCode = `
	const promise = (async function() { \n${program.text} \n})()
	return promise
	`
	const f = new Function('$', jsCode)
	return f(args)
}