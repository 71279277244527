import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import { FormPackageClient } from '../../../../../../api/clients/identity'
import { FormPackageInfo } from '../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../components/feedback/circular'
import { makeChildRoute } from '../../../../../../utils/CreateChildRoute'
import { updateBreadcrumbs } from '../../../Breadcrumbs'
import FormsPageRouter from '../FormsPage/FormsPageRouter'
import FormPackagePage from './FormPackagePage'
import { EventsRouter } from './Sections/EventsSection/EventsRouter'
import { FieldMappingsPage } from './Sections/LinkedFieldsSection/FieldMappingsPage'

const FormPackagePageRouter = observer(() => {
	const [packageInfo, setPackageInfo] = useState<FormPackageInfo | undefined>(
		undefined,
	)

	const { path } = useRouteMatch()

	const params = useParams<{ packageId: string }>()
	const packageId = parseInt(params.packageId)

	useEffect(() => {
		const packageApi = new FormPackageClient()

		packageApi.GetFormPackage(packageId).then((v) => {
			setPackageInfo(v.data)
		})
	}, [packageId])

	const breadcrumb = updateBreadcrumbs(
		`/_administration/_form-packages/${packageId}`,
		packageInfo?.name ?? '',
	)
	if (
		breadcrumb.pageName !== (packageInfo?.name ?? '') &&
		packageInfo !== undefined
	)
		breadcrumb.pageName = packageInfo.name

	if (packageInfo == undefined) return <FullscreenSpinner />

	return (
		<Switch>
			<Route path={makeChildRoute('_forms/:formId', path)}>
				<FormsPageRouter packageId={packageId} />
			</Route>
			<Route path={makeChildRoute('_events/:eventId', path)}>
				<EventsRouter packageId={packageId} />
			</Route>
			<Route path={makeChildRoute('_linked-groups/:fieldMappingId', path)}>
				<FieldMappingsPage
					packageId={packageId}
					packageVersionNumber={packageInfo.versionNumber}
				/>
			</Route>
			<Route>
				<FormPackagePage packageId={packageId} />
			</Route>
		</Switch>
	)
})

export default FormPackagePageRouter
