import { AxiosResponse } from 'axios'
import SecureClient from '../SecureClient'
import { ConfigurableAuthenticationProvider } from './DTO'

export default class AuthenticationProvidersClient extends SecureClient {
	/**
	 *
	 */
	constructor() {
		super()
	}

	/**
	 * get all the available configurable authentication providers (ex. Azure AD)
	 * @returns 
	 */
	public getAuthenticationProviders = (): Promise<
		AxiosResponse<ConfigurableAuthenticationProvider[]>
	> => {
		return this._api.get<ConfigurableAuthenticationProvider[]>('AuthenticationProviders', {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}

	/**
	 * get one configurable authentication provider
	 * @param authenticationProviderId 
	 * @returns 
	 */
	public getAuthenticationProvider = (
		authenticationProviderId: string,
	): Promise<AxiosResponse<ConfigurableAuthenticationProvider>> => {
		return this._api.get<ConfigurableAuthenticationProvider>(
			`AuthenticationProviders/${authenticationProviderId}`,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				},
			},
		)
	}
}
