import { action, observable, runInAction } from 'mobx'
import React, { createContext, useContext, useMemo } from 'react'
import { v4 } from 'uuid'
import {
	NotificationCenterContextApi,
	NotificationCenterNotification,
	NotificationRegistration,
} from './Types'

export const notificationCenterCollection: NotificationRegistration[] =
	observable([])

const notificationCenterContext = createContext<NotificationRegistration[]>(
	notificationCenterCollection,
)

export const NotificationCenterContextProvider = (
	props: React.PropsWithChildren<unknown>,
) => {
	return (
		<notificationCenterContext.Provider value={notificationCenterCollection}>
			{props.children}
		</notificationCenterContext.Provider>
	)
}

export const useNotificationCenterCollection = () => {
	const context = useContext(notificationCenterContext)

	if (context === undefined)
		throw new Error('notificationCenterContext had no provider')

	return context
}

export const registerNotificationCenterNotification = action(
	function registerNotification(notification: NotificationCenterNotification) {
		const registration = observable({
			id: v4(),
			notification,
			isRemoved: false as boolean,
			remove() {
				console.log('called remove')
				const index = notificationCenterCollection.findIndex(
					(v) => v.id === this.id,
				)

				console.log('got index ', index)

				if (index === -1) return

				this.isRemoved = true

				runInAction(() => {
					notificationCenterCollection.splice(index, 1)
				})
			},
		})

		runInAction(() => {
			notificationCenterCollection.push(registration)
		})

		return registration
	},
)

export const useNotificationCenterApi = () => {
	const context = useContext(notificationCenterContext)

	if (context === undefined)
		throw new Error('notificationCenterContext had no provider')

	const contextApi: NotificationCenterContextApi = useMemo(
		action(() => ({
			registerNotification(notification) {
				console.log('made registration')
				const registration = observable({
					id: v4(),
					notification,
					isRemoved: false as boolean,
					remove() {
						console.log('called remove')
						const index = context.findIndex((v) => v.id === this.id)

						console.log('got index ', index)

						if (index === -1) return

						this.isRemoved = true

						runInAction(() => {
							context.splice(index, 1)
						})
					},
				})

				runInAction(() => {
					context.push(registration)
				})

				return registration
			},
		})),
		[context],
	)

	return contextApi
}
