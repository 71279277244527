import { Save } from '@mui/icons-material'
import { Box, Button, Divider, Paper } from '@mui/material'
import { percent } from 'csx'
import * as escodegen from 'escodegen'
import { Program } from 'estree'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { FormsClient } from '../../../../../../../api/clients/identity'
import { FormPackageVersionClient } from '../../../../../../../api/clients/identity/FormPackageVersionsClient'
import {
	DetailForm,
	FormPackageAdvancedEvent,
	FormPackageInfo,
} from '../../../../../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../../../../../components/feedback/circular'
import { WithFormsInfo } from '../../../../../../../modules/VisualScripting'
import { ProgramContextProvider } from '../../../../../../../modules/VisualScripting/ProgramContext'
import { VisualScriptEditor } from '../../../../../../../modules/VisualScripting/VisualScriptEditor'
import { toastService } from '../../../../../../../services/notifications/ToastService'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import { FormEventsContextProvider } from '../../FormPackagePage/Sections/AdvancedEventsSection/FormEventsContext'
import { FormSectionProps } from '../FormPage'

export const FormAdvancedEventsSection = observer(
	({
		form,
		onTabChanged,
		packageId,
		packageVersionNumber,
		tabLabels,
	}: FormSectionProps) => {
		const formPackageVersionClient = new FormPackageVersionClient(packageId)
		const formsClient = new FormsClient(packageId, packageVersionNumber)

		const localStore = useLocalObservable(() => ({
			formPackage: undefined as FormPackageInfo | undefined,
			allFormsInPackage: [] as DetailForm[],
			advancedEventPrograms: undefined as WithFormsInfo<Program>[] | undefined,
		}))

		useEffect(() => {
			formPackageVersionClient.GetVersion(packageVersionNumber).then(
				action((response) => {
					localStore.formPackage = response.data
					localStore.advancedEventPrograms =
						response.data.configuration.advancedEvents
							.map((v) => v.eventProgram)
							.filter((v) => {
								if (v.formsInfo === undefined) return false

								// only show form and field events here
								if (
									v.formsInfo.type !== 'FieldEvent' &&
									v.formsInfo.type !== 'FormEvent'
								)
									return false

								return v.formsInfo.formId === form.id
							})
				}),
			)

			formsClient.GetAllForms().then(
				action((response) => {
					localStore.allFormsInPackage = response.data
				}),
			)
		}, [packageId, packageVersionNumber])

		const handleSaveEvents = async () => {
			const updatedAdvancedEvents: FormPackageAdvancedEvent[] = []

			// will be undefined if form package hasn't loaded yet, in which case the save button will not appear anyway
			if (
				localStore.advancedEventPrograms === undefined ||
				localStore.formPackage === undefined
			)
				return

			for (const program of localStore.advancedEventPrograms) {
				try {
					const code = escodegen.generate(program, {
						format: {
							compact: false,
						},
					})

					updatedAdvancedEvents.push({
						eventProgram: program,
						eventScript: code,
					})
				} catch (e) {
					console.log(e)

					toastService.displayToast({
						message: 'Invalid entries. Make sure all inputs are filled.',
						area: 'global',
					})
				}
			}

			runInAction(() => {
				if (localStore.formPackage !== undefined) {
					// we don't want to overwrite events that aren't in this form
					const nonCurrentFormEvents =
						localStore.formPackage.configuration.advancedEvents.filter(
							(v) =>
								(v.eventProgram.formsInfo?.type === 'FormEvent' ||
									v.eventProgram.formsInfo?.type === 'FieldEvent') &&
								v.eventProgram.formsInfo.formId !== form.id,
						)

					localStore.formPackage.configuration.advancedEvents = [
						...nonCurrentFormEvents,
						...updatedAdvancedEvents,
					]
				}
			})

			try {
				await formPackageVersionClient.UpdateConfiguration(
					localStore.formPackage,
				)

				toastService.displayToast({
					message: 'Advanced events updated',
					area: 'global',
				})
			} catch (e) {
				console.log(e)

				toastService.displayToast({
					message: 'Error updating the advanced events',
					area: 'global',
				})
			}
			return
		}

		return (
			<AdministrationPageContainer
				title={form.name}
				actions={
					<div>
						<Button
							variant="contained"
							color="primary"
							startIcon={<Save />}
							onClick={handleSaveEvents}
						>
							Save
						</Button>
					</div>
				}
			>
				<Box
					display="flex"
					flexDirection="column"
					width={percent(100)}
					height={percent(100)}
					overflow="hidden"
				>
					<AdministrationPageTabs
						currentTab={3}
						onTabChanged={onTabChanged}
						tabLabels={tabLabels}
					/>
					<Divider />
					{localStore.formPackage === undefined ||
					localStore.allFormsInPackage === undefined ||
					localStore.advancedEventPrograms === undefined ? (
						<FullscreenSpinner />
					) : (
						<Box
							display="flex"
							flexDirection="column"
							component={Paper}
							height={percent(100)}
							gap={0.5}
							marginTop={2}
							marginBottom={2}
							overflow="hidden"
						>
							<ProgramContextProvider
								programs={localStore.advancedEventPrograms}
							>
								<FormEventsContextProvider
									type="Form"
									form={form}
									forms={localStore.allFormsInPackage}
								>
									<VisualScriptEditor />
								</FormEventsContextProvider>
							</ProgramContextProvider>
						</Box>
					)}
				</Box>
			</AdministrationPageContainer>
		)
	},
)
