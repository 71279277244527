import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { FormPackageAttachmentType } from '../../../../../../../../api/DTOtemp'
import DescriptiveSwitch from '../../../../../../../../components/inputs/DescriptiveSwitch'
import { FixedHeaderWithHint } from '../../../../../../../../utils/HOC/FixedHeaders'

type EditAttachmentTypeDialog = {
	existingNames: string[]
	attachmentType?: FormPackageAttachmentType // if we are updating an existing one
	onConfirm: (attachmentType: FormPackageAttachmentType) => void
	onCancel: () => void
}

// used for updating or adding an attachment type
export const EditAttachmentTypeDialog = (props: EditAttachmentTypeDialog) => {
	const validationSchema = yup.object({
		name: yup
			.string()
			.required('Name is required')
			.test(
				'is-not-general',
				'All form packages already have a General Attachments type',
				(value) => {
					return (
						value !== undefined &&
						value.toUpperCase().trim() !== 'GENERAL ATTACHMENTS'
					)
				},
			)
			.test(
				'is-reserved',
				'This is a reserved attachment type name',
				(value) => {
					return (
						value !== undefined &&
						value.toUpperCase().trim() !== 'FORMIMAGES' &&
						value.toUpperCase().trim() !== 'SIGNATUREIMAGES' &&
						value.toUpperCase().trim() !== 'FORMRESOURCES'
					)
				},
			)
			.test(
				'is-unique',
				'An attachment type with this name already exists',
				(value) => {
					return (
						value !== undefined &&
						!props.existingNames
							.map((v) => v.toUpperCase())
							.filter((v) => v !== props.attachmentType?.name.toUpperCase())
							.includes(value.toUpperCase().trim())
					)
				},
			),
		description: yup.string(),
		required: yup.boolean(),
		acceptedFileTypes: yup.string(),
		maxAttachments: yup.boolean(),
		maxAttachmentCount: yup
			.number()
			.notRequired()
			.min(1, 'Max count must be greater than 0')
			.integer('Max count must be a whole number'),
	})

	const formik = useFormik({
		initialValues: {
			name: props.attachmentType?.name ?? '',
			description: props.attachmentType?.description ?? '',
			acceptedFileTypes: props.attachmentType?.acceptedFileTypes ?? '',
			required: props.attachmentType?.required ?? false,
			maxAttachments: false,
			maxAttachmentCount: 1,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			props.onConfirm({
				name: values.name,
				description: values.description,
				required: values.required,
				acceptedFileTypes: values.acceptedFileTypes,
				maxAttachmentCount: values.maxAttachments
					? values.maxAttachmentCount
					: undefined,
			})
		},
	})

	console.log('formik values: ', formik.values)

	return (
		<>
			<DialogTitle>
				{props.attachmentType !== undefined
					? 'Update Attachment Type'
					: 'Add Attachment Type'}
			</DialogTitle>
			<DialogContent>
				<FixedHeaderWithHint
					label="Name"
					hintColor="error.main"
					hint={formik.touched.name && formik.errors.name}
				>
					<TextField
						fullWidth
						id="name"
						name="name"
						value={formik.values.name}
						error={!!formik.errors.name && formik.touched.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint label="Description">
					<TextField
						fullWidth
						id="description"
						name="description"
						value={formik.values.description}
						error={!!formik.errors.description && formik.touched.description}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<FixedHeaderWithHint
					label="Accepted File Types"
					hint="A valid HTML input accept type. Leave blank to accept all input types."
				>
					<TextField
						fullWidth
						id="acceptedFileTypes"
						name="acceptedFileTypes"
						value={formik.values.acceptedFileTypes}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						autoComplete="off"
					/>
				</FixedHeaderWithHint>
				<DescriptiveSwitch
					id="required"
					name="required"
					value={formik.values.required}
					checked={formik.values.required}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					disabled={formik.isSubmitting}
					headerLabel={<Typography variant="subtitle1">Required</Typography>}
					bodyLabel="Make this attachment type required to submit the form package"
				/>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<DescriptiveSwitch
						id="maxAttachments"
						name="maxAttachments"
						value={formik.values.maxAttachments}
						checked={formik.values.maxAttachments}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						disabled={formik.isSubmitting}
						headerLabel={
							<Typography variant="subtitle1">
								Maximum Attachment Count
							</Typography>
						}
						bodyLabel="Set a maximum attachment count allowed for this attachment type"
					/>
					{formik.values.maxAttachments && (
						<FixedHeaderWithHint
							hintColor="error.main"
							hint={
								formik.touched.maxAttachmentCount &&
								formik.errors.maxAttachmentCount
							}
						>
							<TextField
								type="number"
								id="maxAttachmentCount"
								name="maxAttachmentCount"
								value={formik.values.maxAttachmentCount ?? 1}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								disabled={formik.isSubmitting}
								inputProps={{
									min: 0,
									step: 1,
									inputMode: 'numeric',
									pattern: '[0-9]*',
								}}
							/>
						</FixedHeaderWithHint>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="outlined" onClick={props.onCancel}>
					Cancel
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={formik.submitForm}
					disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
				>
					{props.attachmentType !== undefined
						? 'Update Attachment Type'
						: 'Add Attachment Type'}
				</Button>
			</DialogActions>
		</>
	)
}
