import { Edit, Save } from '@mui/icons-material'
import Publish from '@mui/icons-material/Publish'
import { Button, Divider, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { useCallback, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { DetailForm, FormConfiguration } from '../../../../../../../api/DTOtemp'
import { FormsClient } from '../../../../../../../api/clients/identity'
import FormUploadDialog, {
	UploadType,
} from '../../../../../../../components/forms/FormUploadDialog'
import ToggleTextInput from '../../../../../../../components/inputs/ToggleTextInput'
import ButtonLink from '../../../../../../../components/links/ButtonLink'
import { toastService } from '../../../../../../../services/notifications/ToastService'
import { makeChildRoute } from '../../../../../../../utils/CreateChildRoute'
import AdministrationPageContainer from '../../../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../../../AdministrationPageTabs'
import FormInfoCard from '../FormInfoPanel'
import { FormSectionProps } from '../FormPage'
import FormSettingsCard from '../FormSettingsCard'

type FormSettingsSectionProps = {
	formType: 'HTML' | 'PDF' | 'FB'
	onUpdateForm: (newForm: DetailForm) => void
}

// page for HTML/PDF forms
const FormSettingsSection = ({
	packageId,
	packageVersionNumber,
	formType,
	form,
	onTabChanged,
	tabLabels,
	onUpdateForm,
}: FormSettingsSectionProps & FormSectionProps) => {
	const styles = useStyles()
	const { url } = useRouteMatch()

	const [settings, setSettings] = useState<FormConfiguration>(
		form.formConfiguration,
	)

	const [uploadFormOpen, setUploadFormOpen] = useState(false)

	const [formName, setFormName] = useState(form.name)

	const handleUploadFormOpen = useCallback(() => {
		setUploadFormOpen(true)
	}, [])

	const handleUploadFormClose = useCallback(() => {
		setUploadFormOpen(false)
	}, [])

	const handleSaveSettings = useCallback(() => {
		const api = new FormsClient(packageId, packageVersionNumber)
		api
			.UpdateFormSettings(form.id, {
				name: formName,
				settings: settings,
			})
			.then(() => {
				console.log('settings successfully updated!')
				toastService.displayToast({
					message: 'Settings updated',
					area: 'global',
				})
			})
			.catch(() => {
				toastService.displayToast({
					message: 'Error updating settings',
					area: 'global',
				})
			})
	}, [formName, settings])

	const getErrorMessage = (value: string) => {
		const error = !value || !/^[a-zA-Z0-9 _]+$/.test(value)

		return error
			? 'The form name must be at least one character without any special characters'
			: ''
	}

	const saveDisabled = useMemo(() => {
		/* 
			I feel weird using JSON.stringify() for comparing the new and original settings but it 
			works here because the order won't change - just the true/false values. Comparing them by
			themselves does not work because it checks for referential equality and the objects have
			different references. We could also go through the keys and check equality there
			but this is more succinct and works and the order of the settings will not change. -Caroline
		*/
		return (
			formName === form.name &&
			JSON.stringify(settings) === JSON.stringify(form.formConfiguration)
		)
	}, [formName, settings])

	return (
		<AdministrationPageContainer
			title={
				<ToggleTextInput
					fullWidth
					value={formName}
					onChecked={setFormName}
					getErrorMessage={getErrorMessage}
				/>
			}
			actions={
				<div>
					{formType === 'FB' ? (
						<ButtonLink
							className={styles.actionButtonsLeftSpacing}
							to={makeChildRoute('_editor', url)}
							variant="outlined"
							color="primary"
							startIcon={<Edit />}
						>
							Edit Form
						</ButtonLink>
					) : (
						<Button
							className={styles.actionButtonsLeftSpacing}
							variant="outlined"
							color="primary"
							startIcon={<Publish />}
							onClick={handleUploadFormOpen}
						>
							Upload
						</Button>
					)}
					<Button
						variant="contained"
						color="primary"
						startIcon={<Save />}
						onClick={handleSaveSettings}
						disabled={saveDisabled}
					>
						Save
					</Button>
				</div>
			}
		>
			<div className={styles.formContentRoot}>
				<AdministrationPageTabs
					currentTab={0}
					onTabChanged={onTabChanged}
					tabLabels={tabLabels}
				/>
				<Divider />
				<div className={styles.uploadedFormPageRoot}>
					<div className={styles.formPageTop}>
						<div className={styles.formInfoCard}>
							<FormInfoCard form={form} />
						</div>
						<div className={styles.formSettingsCard}>
							<FormSettingsCard
								formType={formType}
								settings={settings}
								onSettingsChanged={setSettings}
							/>
						</div>
					</div>
				</div>
			</div>
			<FormUploadDialog
				packageId={packageId}
				packageVersionNumber={packageVersionNumber}
				open={uploadFormOpen}
				formId={form.id}
				formName={form.name}
				formType={formType === 'HTML' ? UploadType.html : UploadType.pdf}
				onClose={handleUploadFormClose}
				formConfiguration={settings}
				onFormUploaded={(newForm: DetailForm) => {
					onUpdateForm(newForm)
				}}
			/>
		</AdministrationPageContainer>
	)
}

export default FormSettingsSection

const useStyles = makeStyles((theme: Theme) => ({
	actionButtonsLeftSpacing: {
		marginRight: theme.spacing(2),
	},

	formContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),

		overflow: 'hidden',
	},

	uploadedFormPageRoot: {
		display: 'flex',
		flexDirection: 'column',

		paddingTop: theme.spacing(2),

		minHeight: percent(100),
		width: percent(100),
	},

	formPageTop: {
		display: 'flex',
		flexWrap: 'wrap',
	},

	formPageBottom: {
		marginTop: theme.spacing(2),
	},

	formPageTopItem: {
		flex: '1',
	},

	formInfoCard: {
		[theme.breakpoints.up('lg')]: {
			width: percent(33),
		},

		[theme.breakpoints.down('lg')]: {
			width: percent(100),
		},
	},

	formSettingsCard: {
		flex: 1,

		[theme.breakpoints.up('lg')]: {
			marginLeft: theme.spacing(1),
		},

		[theme.breakpoints.down('lg')]: {
			margin: theme.spacing(3, 0),
		},
	},
}))
