import { FormControl, styled } from '@mui/material'

// to make the underline in the select a specified color
const ColoredFormControl = styled(FormControl, {
	shouldForwardProp: (prop) => prop !== 'formControlColor',
})<{ formControlColor: string }>(({ formControlColor }) => ({
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderBottomColor: formControlColor,
	},
}))

export default ColoredFormControl
