import { FormType } from '../../api/DTOtemp'
import { IUnifiedFormHost } from '../FormHost/FormHost/IUnifiedFormHost'
import { CellType } from '../FormHost/Types/CellType'
import { UnifiedCellDefinition } from '../FormHost/Types/UnifiedCellDefinition'
import { UnifiedCellInstance } from '../FormHost/Types/UnifiedCellInstance'
import {
	createCellDefinitionProxy,
	createNonValueCellInstanceProxy,
	createValueCellInstanceProxy,
} from '../FormHost/Utilities/CellProxyUtilities'
import { EventBus } from './eventBus/EventBus'
import { IFormBuilderFormHost } from './IFormBuilderFormHost'

export interface IFormBuilderPackageHostWrapper extends IUnifiedFormHost {
	readonly formType: FormType.FormBuilder
	readonly formHost: IFormBuilderFormHost
}

export class FormBuilderPackageHostWrapper
	implements IFormBuilderPackageHostWrapper
{
	readonly formHost: IFormBuilderFormHost

	readonly formId: number
	readonly formType: FormType.FormBuilder = FormType.FormBuilder

	eventBus: EventBus
	dispose: () => void

	cellDefinitions: UnifiedCellDefinition[]
	cellInstances: UnifiedCellInstance[]

	get formContainerElement() {
		return document.getElementById(`form-${this.formId}`)
	}

	constructor(formHost: IFormBuilderFormHost) {
		this.formHost = formHost
		this.cellDefinitions = []
		this.cellInstances = []
		this.formId = formHost.formId
		this.eventBus = formHost.eventBus
		this.dispose = formHost.dispose

		/* proxy the formBuilder cell definitions/instances so that they can match the
			 unified types for the package host */
		this.proxyCellDefinitions()
		this.proxyCellInstances()
	}

	private proxyCellDefinitions() {
		for (const definition of this.formHost.cellDefinitions) {
			this.cellDefinitions.push(
				createCellDefinitionProxy(definition, propertiesProxy),
			)
		}
	}

	private proxyCellInstances() {
		for (const instance of this.formHost.cellInstances) {
			if (instance.type === CellType.Virtual) {
				this.cellInstances.push(instance) // don't need to proxy because no properties or value on a virtual instance
			}
			else if (
				instance.type === CellType.Concrete ||
				instance.type === CellType.Layout ||
				instance.type === CellType.Repeat
			) {
				this.cellInstances.push(createNonValueCellInstanceProxy(instance, propertiesProxy))
			}
			else if (instance.type === CellType.Value) {
				const valueGetter = () => instance.value;
				const valueSetter = (v: unknown | undefined) => instance.value = v;

				const unifiedCellInstance = createValueCellInstanceProxy(
					instance,
					propertiesProxy,
					valueGetter,
					valueSetter
				)

				this.cellInstances.push	(unifiedCellInstance)
			}
		}
	}
}

const propertiesProxy = (properties: Record<string, unknown>) =>
	new Proxy(properties, handler)

const handler: ProxyHandler<Record<string, unknown>> = {
	get: function (properties: unknown, propertyName: string) {
		return Reflect.get(properties as object, propertyName)
	},
	set: function (properties: unknown, propertyName: string, value: unknown) {
		return Reflect.set(properties as object, propertyName, value)
		return true
	},
}
