import { Home, ZoomIn, ZoomOut } from '@mui/icons-material'
import { Box, Theme, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { px, rem } from 'csx'
import jsPlumb, {
	ConnectionMadeEventInfo,
	DragEventCallbackOptions,
	Endpoint,
	EndpointOptions,
	OnConnectionBindInfo,
} from 'jsplumb'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { Ref, useEffect } from 'react'
import {
	ActivityInstanceStatus,
	BaseActivityDefinitionModel,
} from '../../api/clients/workflows/DTOs'
import HostedMapInteraction from '../../utils/MapInteractions/HostedMapInteraction'
import {
	TranslationScale,
	defaultProps,
} from '../../utils/MapInteractions/MapInteraction'
import { MapInteractionStore } from '../../utils/MapInteractions/MapInteractionStore'
import { EditorActivityCard } from './ActivityCard'
import * as JsPlumbUtils from './JsPlumbUtils'
import { MissingPluginActivity } from './MissingPluginActivity'
import { EditorDisplayType } from './Types'
import { useWorkflowStore } from './stores/WorkflowStore'
import { createOutcomes } from './stores/WorkflowUtilities'

type WorkflowEditorProps = {
	displayType: EditorDisplayType
	canvasRef: Ref<HTMLDivElement>
	onSelectActivity: (id: string) => void
}

export const WorkflowEditor = observer((props: WorkflowEditorProps) => {
	const { workflowContext, activityConfigurationStore } = useWorkflowStore()
	const theme = useTheme()
	const styles = useStyles()

	const localStore = useLocalObservable(() => ({
		mapInteractionStore: new MapInteractionStore(),
		isDragging: false,
	}))

	const container = 'container'

	const jsPlumb = JsPlumbUtils.createInstance(container, false)

	// UTILS

	const findActivityByElement = (
		elementId: string,
	): BaseActivityDefinitionModel => {
		const activities = workflowContext.activityDefinitions
		const activity = activities.find((v) => v.uniqueId == elementId)
		if (activity === undefined) throw `could not find activity ${elementId}`
		return activity
	}

	const setupOutcomes = async (element: HTMLElement, outcomes?: string[]) => {
		const activity = findActivityByElement(element.id)

		// get the existing endpoints for the activity - we don't want to duplicate an outcome
		const existingEndpoints = jsPlumb.getEndpoints(element)

		// if existingOutcomes includes one that outcomes doesn't include, remove it
		for (const endpoint of existingEndpoints.filter(
			(v) => !outcomes?.includes(v.getParameter('outcome')),
		)) {
			const index = existingEndpoints.indexOf(endpoint)
			if (index < 0)
				throw new Error('endpoint not found in existing endpoints list')

			// first delete the endpoint
			jsPlumb.deleteEndpoint(endpoint)

			// and now delete any connections reliant on this outcome that was removed
			const existingConnection = workflowContext.connections.find(
				(v) =>
					v.sourceActivityId === element.id &&
					v.outcome === endpoint.getParameter('outcome'),
			)
			if (existingConnection !== undefined)
				workflowContext.removeConnection(existingConnection)

			existingEndpoints.splice(index, 1)
		}

		const configuration = activityConfigurationStore.getConfiguration(
			activity.activityTypeId,
		)
		if (!configuration)
			throw `❌ no activity configuration has an id of ${activity.activityTypeId}`
		if (!outcomes) outcomes = createOutcomes(configuration, activity) ?? []

		// now add any outcomes that don't already exist
		for (const outcome of outcomes.filter(
			(v) =>
				!existingEndpoints.map((v) => v.getParameter('outcome')).includes(v),
		)) {
			let executed = false
			if (
				workflowContext.instance !== undefined &&
				workflowContext.activityInstances.length > 0
			) {
				// make sure this is actually the executed branch
				// by getting the next instances and seeing which was run
				const connections =
					workflowContext.workflow?.connectionDefinitions.filter(
						(v) => v.sourceActivityId === activity.uniqueId,
					)

				// this is an instance, we don't want to show the connection
				// dots if the activity doesn't have any connections
				// and it's readonly anyways
				if (connections?.length === 0) return

				const nextInstances = workflowContext.activityInstances.filter(
					(v) =>
						connections
							?.map((v) => v.destinationActivityId)
							.includes(v.referenceId) &&
						v.status !== ActivityInstanceStatus.NotRun,
				)

				for (const instance of nextInstances) {
					const sourceActivity = workflowContext.activityInstances.find(
						(v) =>
							v.status !== ActivityInstanceStatus.NotRun &&
							v.referenceId === element.id,
					)

					if (
						connections?.find(
							(v) =>
								sourceActivity &&
								v.outcome === outcome &&
								v.destinationActivityId === instance.referenceId,
						) !== undefined
					)
						executed = true
				}
			}

			const sourceEndpointOptions: EndpointOptions =
				JsPlumbUtils.getSourceEndpointOptions(
					theme,
					activity.uniqueId,
					outcome,
					executed,
				)
			const endpointOptions: EndpointOptions = {
				connectorOverlays: [
					['Label', { label: outcome, cssClass: styles.connectionLabel }],
				],
				maxConnections: 1, //good luck getting to that number!
				enabled: props.displayType === EditorDisplayType.ActiveVersion,
			}

			jsPlumb.addEndpoint(element, endpointOptions, sourceEndpointOptions)
		}
	}

	useEffect(() => {
		jsPlumb.setContainer(container)

		jsPlumb.repaintEverything()

		const setupJsPlumb = () => {
			jsPlumb.reset()
			jsPlumb.batch(() => {
				for (const activity of workflowContext.activityDefinitions) {
					const element = document.getElementById(activity.uniqueId)
					if (element === null) throw 'failed to find element'
					setupActivityElement(element)
				}
				setupConnections()
				setupJsPlumbEventHandlers()
			})
		}

		const setupActivityElement = (element: HTMLElement) => {
			if (props.displayType === EditorDisplayType.ActiveVersion)
				setupDragDrop(element)

			setupTargets(element)
			setupOutcomes(element)
			jsPlumb.revalidate(element)
		}

		const setupDragDrop = (element: HTMLElement) => {
			type DraggableProps = {
				left: number
				top: number
			}

			let props: DraggableProps | undefined = undefined

			jsPlumb.draggable(element, {
				start: (params: DragEventCallbackOptions) => {
					props = { left: params.e.screenX, top: params.e.screenY }

					runInAction(() => {
						localStore.isDragging = true
					})
				},
				stop: async (params: DragEventCallbackOptions) => {
					const hasDragged =
						props?.left !== params.e.screenX || props?.top !== params.e.screenY

					if (!hasDragged || props === undefined) return

					const activity = findActivityByElement(element.id)

					runInAction(() => {
						activity.left = params.pos[0]
						activity.top = params.pos[1]
					})
				},
			})
		}

		const setupTargets = (element: HTMLElement) => {
			jsPlumb.makeTarget(element, {
				dropOptions: { hoverClass: 'hover' },
				anchor: 'Continuous',
				endpoint: ['Blank', { radius: 4 }],
			})
		}

		const setupConnections = () => {
			console.log('adding connections')
			for (const connection of workflowContext.connections) {
				/*
				 * right now we're using the 'outcome one' string from the outcomes array in setupOutcomes, changing this
				 * without fixing workflows will break old connections
				 */
				const sourceEndpointId: string = JsPlumbUtils.createEndpointUuid(
					connection.sourceActivityId,
					connection.outcome ?? '',
				)
				const sourceEndpoint: Endpoint = jsPlumb.getEndpoint(sourceEndpointId)

				jsPlumb.connect({
					source: sourceEndpoint,
					target: connection.destinationActivityId,
				})
			}
		}

		const setupJsPlumbEventHandlers = () => {
			jsPlumb.bind('connection', connectionCreated)
			jsPlumb.bind('connectionDetached', connectionDetached)
		}

		const connectionCreated = async (info: ConnectionMadeEventInfo) => {
			console.log('connection created handler executed')

			const sourceEndpoint: jsPlumb.Endpoint = info.sourceEndpoint
			const outcome: string = sourceEndpoint.getParameter('outcome')

			// check if connection already exists
			const sourceActivity: BaseActivityDefinitionModel = findActivityByElement(
				info.source.id,
			)
			const destinationActivity: BaseActivityDefinitionModel =
				findActivityByElement(info.target.id)
			const wfConnection = workflowContext.connections.find(
				(v) =>
					v.sourceActivityId == sourceActivity.uniqueId &&
					v.destinationActivityId == destinationActivity.uniqueId,
			)

			if (!wfConnection) {
				workflowContext.createConnection({
					sourceActivityId: sourceActivity.uniqueId,
					destinationActivityId: destinationActivity.uniqueId,
					outcome: outcome,
				})
			}
		}

		const connectionDetached = async (
			info: OnConnectionBindInfo,
		): Promise<void> => {
			console.log('detached handler executed')
			const sourceEndpoint: jsPlumb.Endpoint = info.sourceEndpoint
			const outcome: string = sourceEndpoint.getParameter('outcome')
			const fromActivity: BaseActivityDefinitionModel = findActivityByElement(
				info.source.id,
			)
			const toActivity: BaseActivityDefinitionModel = findActivityByElement(
				info.target.id,
			)
			workflowContext.removeConnection({
				sourceActivityId: fromActivity.uniqueId,
				destinationActivityId: toActivity.uniqueId,
				outcome: outcome,
			})
		}

		// START_TAG
		setupJsPlumb()

		const zoom = (translationScale: TranslationScale) => {
			jsPlumb.setZoom(translationScale.scale)
		}

		localStore.mapInteractionStore.registerTranslationScaleCallback(zoom)

		return () => {
			localStore.mapInteractionStore.removeTranslationScaleCallback(zoom)
		}
	})

	return (
		<>
			<HostedMapInteraction
				mapInteractionStore={localStore.mapInteractionStore}
				minScale={0.05}
				maxScale={3}
				showControls={false}
				translationBounds={{}}
				disableZoom={false}
				disablePan={false}
			>
				<div
					id={container}
					style={{
						position: 'absolute',
						backgroundImage: 'radial-gradient(#000, 10%, transparent 11%)',
						backgroundSize: '60px 60px',
						backgroundPosition: '0 0, 30px 30px',
						backgroundRepeat: 'repeat',
					}}
					ref={props.canvasRef}
				>
					{workflowContext.activityDefinitions.map((v) => {
						const configuration = activityConfigurationStore.getConfiguration(
							v.activityTypeId,
						)
						if (!configuration)
							return (
								<MissingPluginActivity
									definition={v}
									onRemoveDefinition={() =>
										workflowContext.removeDefinition(v.uniqueId)
									}
								/>
							)

						return (
							<Box key={v.uniqueId}>
								<EditorActivityCard
									definition={v}
									activityTitle={v.state.title || configuration.displayName}
									description={
										v.state.description ||
										v.description ||
										configuration.description
									}
									icon={configuration.icon}
									onOutcomesUpdated={(outcomes) => {
										const element = document.getElementById(v.uniqueId)
										if (element === null) throw 'failed to find element'
										setupOutcomes(element, outcomes)
										jsPlumb.revalidate(element)
									}}
									onClick={action(() => {
										if (!localStore.isDragging)
											// to get around opening the drawer when we drag something
											props.onSelectActivity(v.uniqueId)
										else localStore.isDragging = false
									})}
								/>
							</Box>
						)
					})}
				</div>
			</HostedMapInteraction>

			<div className={styles.controls}>
				<ZoomIn
					style={{ margin: px(5), cursor: 'pointer' }}
					onClick={() => {
						if (
							defaultProps.maxScale &&
							localStore.mapInteractionStore.scale < defaultProps.maxScale
						)
							localStore.mapInteractionStore.setTranslationScale({
								scale: localStore.mapInteractionStore.scale + 0.5,
								translation: localStore.mapInteractionStore.translation,
							})
					}}
				/>
				<ZoomOut
					style={{ margin: px(5), cursor: 'pointer' }}
					onClick={() => {
						if (
							defaultProps.minScale &&
							localStore.mapInteractionStore.scale > defaultProps.minScale
						)
							localStore.mapInteractionStore.setTranslationScale({
								scale: localStore.mapInteractionStore.scale - 0.5,
								translation: localStore.mapInteractionStore.translation,
							})
					}}
				/>
				<Home
					style={{ margin: px(5), cursor: 'pointer' }}
					onClick={() => {
						localStore.mapInteractionStore.setTranslationScale({
							scale: 1,
							translation: {
								x: 0,
								y: 0,
							},
						})
					}}
				/>
			</div>
		</>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	controls: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'flex-end',
		position: 'absolute',
		right: 4,
		bottom: 4,
		zIndex: theme.zIndex.speedDial,
	},
	connectionLabel: {
		borderRadius: theme.spacing(0.5),
		padding: theme.spacing(0.5, 1),
		fontSize: rem(0.75),
		textAlign: 'center',
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.secondary,
	},
}))
