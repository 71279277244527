import { CssBaseline } from '@mui/material'
import {
	createTheme,
	Direction,
	responsiveFontSizes,
	StyledEngineProvider,
	Theme,
	ThemeProvider,
} from '@mui/material/styles'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo, useState } from 'react'
import BackgroundServices from '../../services/BackgroundServices'
import { BlockingOperationBarrierContextProvider } from '../../services/notifications/BlockingTaskService'
import { ModalContextProvider } from '../../services/notifications/ModalService'
import { PromptContextProvider } from '../../services/notifications/PromptService'
import { RootRouter } from './RootRouter'
import { baseThemeOptions } from './Themes/BaseThemeOptions'
import { darkThemeOptions } from './Themes/DarkThemeOptions'
import { lightThemeOptions } from './Themes/LightThemeOptions'

interface Neutral {
	100: string
	200: string
	300: string
	400: string
	500: string
	600: string
	700: string
	800: string
	900: string
}

declare module '@mui/material/styles' {
	interface Palette {
		neutral?: Neutral
	}

	interface PaletteOptions {
		neutral?: Neutral
	}
}

interface ThemeConfig {
	direction?: Direction
	responsiveFontSizes?: boolean
	mode: 'light' | 'dark'
}

export const createCustomTheme = (config: ThemeConfig): Theme => {
	let theme = createTheme(
		baseThemeOptions,
		config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
		{
			direction: config.direction,
		},
	)

	if (config.responsiveFontSizes) {
		theme = responsiveFontSizes(theme)
	}

	return theme
}

type ThemeContextType = {
	theme: Theme
	toggleTheme: () => void
}

export const ThemeContext = React.createContext<ThemeContextType>({
	theme: createCustomTheme({ mode: 'light' }),
	toggleTheme: () => {
		return
	},
})

export const App: React.FC = observer(() => {
	const store = window.localStorage
	if (store.getItem('theme') != 'dark' && store.getItem('theme') != 'light') {
		if (
			window.matchMedia &&
			window.matchMedia('(prefers-color-scheme: dark)').matches
		) {
			store.setItem('theme', 'dark')
		} else {
			store.setItem('theme', 'light')
		}
	}

	const [theme, setTheme] = useState(store.getItem('theme'))

	const themeObject = useMemo(
		() => createCustomTheme({ mode: theme as 'light' | 'dark' }),
		[theme],
	)

	const toggleTheme = useCallback(() => {
		const newTheme = theme === 'dark' ? 'light' : 'dark'
		setTheme(newTheme)
		store.setItem('theme', newTheme)
		console.log('theme set to ', newTheme)
	}, [theme])

	const providerObject = useMemo<ThemeContextType>(
		() => ({
			theme: themeObject,
			toggleTheme: toggleTheme,
		}),
		[theme],
	)

	return (
		<ThemeContext.Provider value={providerObject}>
			<AppInternal />
		</ThemeContext.Provider>
	)
})

const AppInternal: React.FC = () => {
	const themeContext = React.useContext(ThemeContext)

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themeContext.theme}>
				<CssBaseline>
					<ModalContextProvider>
						<PromptContextProvider>
							<BlockingOperationBarrierContextProvider>
								<BackgroundServices>
									<RootRouter />
								</BackgroundServices>
							</BlockingOperationBarrierContextProvider>
						</PromptContextProvider>
					</ModalContextProvider>
				</CssBaseline>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}
