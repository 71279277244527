import { Button, Fade, Paper, TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { percent } from 'csx'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import AuthenticationProviderInstancesClient from '../../../../api/clients/authenticationProviders/AuthenticationProviderInstancesClient'
import AuthenticationProvidersClient from '../../../../api/clients/authenticationProviders/AuthenticationProvidersClient'
import {
	AuthenticationProviderInstance,
	BaseAuthenticationProviderInstanceModel,
	ConfigurableAuthenticationProvider,
} from '../../../../api/clients/authenticationProviders/DTO'
import { ClickToCopyButton } from '../../../../components/buttons/ClickToCopyButton'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import ToggleTextInput from '../../../../components/inputs/ToggleTextInput'
import {
	FormBuilderViewer,
	StandaloneCellManager,
} from '../../../../modules/FormBuilderInterop/Runtimes/Standalone'
import { toastService } from '../../../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'
import { compareState } from '../../../../utils/compareState'
import AdministrationPageContainer from '../AdministrationPageContainer'
import { useAuthenticationProviderContext } from './AuthenticationProviderContext'

export const AuthenticationProviderInstancePage = observer(() => {
	const params = useParams<{ instanceId: string }>()
	const authProviderContext = useAuthenticationProviderContext()
	const styles = useStyles()

	const instanceId = params.instanceId

	const localStore = useLocalObservable(() => ({
		instance: undefined as AuthenticationProviderInstance | undefined,
		authenticationProvider: undefined as
			| ConfigurableAuthenticationProvider
			| undefined,
		displayName: '',
		instanceState: {} as Record<string, unknown>,
	}))

	const providersClient = new AuthenticationProvidersClient()
	const instancesClient = new AuthenticationProviderInstancesClient()

	useEffect(() => {
		fetchInstance()
	}, [instanceId])

	const fetchInstance = async () => {
		const { data: instance } = await instancesClient.getInstance(instanceId)

		const { data: provider } = await providersClient.getAuthenticationProvider(
			instance.authenticationProviderId,
		)

		runInAction(() => {
			localStore.instance = instance
			localStore.displayName = instance.displayName
			localStore.authenticationProvider = provider
			localStore.instanceState = instance.state as Record<string, unknown>
		})
	}

	const handleUpdateName = action((name: string) => {
		localStore.displayName = name
		handleUpdateInstance()
	})

	const handleUpdateInstance = async () => {
		if (localStore.instance === undefined) return

		const baseModel: BaseAuthenticationProviderInstanceModel = {
			authenticationProviderId: localStore.instance.authenticationProviderId,
			displayName: localStore.displayName,
			// TODO - the following is sending form builder info too
			state: localStore.instanceState,
		}

		if (localStore.displayName !== localStore.instance.displayName) {
			const index = authProviderContext.organizationInstanceNames.indexOf(
				localStore.instance.displayName,
			)
			// don't worry about it if the old name isn't in the list, we don't need it now anyways
			if (index >= 0)
				authProviderContext.organizationInstanceNames.splice(index, 1)
		}

		try {
			const { data: updatedInstance } = await instancesClient.updateInstance(
				instanceId,
				baseModel,
			)

			authProviderContext.organizationInstanceNames.push(
				updatedInstance.displayName,
			)

			runInAction(() => {
				localStore.instance = updatedInstance
			})

			toastService.displayToast({
				message: 'Authentication provider updated',
				area: 'global',
			})
		} catch (error) {
			console.log(error)

			toastService.displayToast({
				message: 'Error updating authentication provider',
				area: 'global',
			})
		}
	}

	const cellManager = useMemo(() => {
		if (
			localStore.instance === undefined ||
			localStore.authenticationProvider === undefined
		)
			return undefined

		return new StandaloneCellManager(
			localStore.authenticationProvider.formBuilderSchema,
			localStore.instanceState,
		)
	}, [
		localStore.instance,
		localStore.instanceState,
		localStore.authenticationProvider,
	])

	const getErrorMessage = (value: string) => {
		// make sure name is unique (not counting the name the instance already has)
		if (
			authProviderContext.organizationInstanceNames
				.map((v) => v.toUpperCase())
				.includes(value.toUpperCase().trim()) &&
			localStore.instance?.displayName !== value
		)
			return 'Name must be unique'

		return value === '' ? 'Name is required' : ''
	}

	const isDisabled = (): boolean => {
		// if no values have changed we want the button to be disabled
		if (localStore.instance === undefined) return true

		if (
			!compareState(
				localStore.instance.state as Record<string, unknown>,
				localStore.instanceState,
			)
		)
			return false

		return localStore.displayName === localStore.instance?.displayName
	}

	if (localStore.instance === undefined) return <FullscreenSpinner />

	return (
		<AdministrationPageContainer
			title={
				<ToggleTextInput
					value={localStore.displayName}
					onChecked={handleUpdateName}
					getErrorMessage={getErrorMessage}
				/>
			}
		>
			<Fade in>
				<Paper className={styles.insetSpacing}>
					<div>
						<FixedHeaderWithHint
							label="Redirect Uri"
							hint="Paste this URI in the Redirect URIs section of your app registration"
						>
							<div className={styles.redirectUriSection}>
								<TextField
									value={`${window.location.origin}/signin-oidc/${localStore.instance.id}`}
									size="small"
									fullWidth
								/>
								<ClickToCopyButton
									value={`${window.location.origin}/signin-oidc/${localStore.instance.id}`}
								/>
							</div>
						</FixedHeaderWithHint>
						{cellManager !== undefined && (
							<FormBuilderViewer cellManager={cellManager} />
						)}
					</div>

					<div className={styles.topBottomContent}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleUpdateInstance}
							disabled={isDisabled()}
						>
							Save Changes
						</Button>
					</div>
				</Paper>
			</Fade>
		</AdministrationPageContainer>
	)
})

const useStyles = makeStyles((theme: Theme) => ({
	insetSpacing: {
		margin: theme.spacing(2, -2),
		padding: theme.spacing(0, 2),
		width: percent(100),
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
		display: 'flex',
		justifyContent: 'flex-end',
	},

	redirectUriSection: {
		display: 'flex',
		flexDirection: 'row',
		width: percent(100),
	},
}))
