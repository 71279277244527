import { Dialog } from '@mui/material'
import { action, makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react'
import React, { PropsWithChildren, createContext, useContext } from 'react'

export type CloseResult = 'okay' | 'cancel' | 'error'

export type FormProps<T> = {
	close(result: FormResult<T>): void
}

export type FormResult<T> = {
	closeResult: CloseResult
	value?: T
}

export type DialogProps = {
	close(result: CloseResult): void
}

type DialogTracker<T> = {
	Viewer(props: FormProps<T>): React.ReactElement
	resolvePromise(value: FormResult<T> | PromiseLike<FormResult<T>>): void
}

class ModalService {
	public dialogTrackers: DialogTracker<unknown>[] = []

	constructor() {
		makeAutoObservable(this)
	}

	public showForm<T>(
		display: (props: FormProps<T>) => React.ReactElement,
	): Promise<FormResult<T>> {
		// @ts-expect-error - we have to have this assignment here, JS spec says ctor of promise is sync so we can export
		let promiseResolve: (
			value: FormResult<T> | PromiseLike<FormResult<T>>,
		) => void = undefined

		const promise = new Promise<FormResult<T>>((resolve, reject) => {
			promiseResolve = resolve
		})

		this.dialogTrackers.push({
			Viewer: display,
			resolvePromise: promiseResolve,
		})

		promise.then(
			action(() => {
				this.dialogTrackers.shift()
			}),
		)

		return promise
	}
}

const modalService = new ModalService()

const context = createContext(modalService)

export const ModalContextProvider = (props: PropsWithChildren<unknown>) => {
	return (
		<context.Provider value={modalService}>
			<PromptDisplayComponent>{props.children}</PromptDisplayComponent>
		</context.Provider>
	)
}

export type ModalServiceType = {
	showForm<T>(
		display: (props: FormProps<T>) => React.ReactElement,
	): Promise<FormResult<T>>
}

export const useModals = (): ModalServiceType => {
	const contextInstance = useContext(context)
	return contextInstance
}

const PromptDisplayComponent = (props: PropsWithChildren<unknown>) => {
	return (
		<>
			<PromptDisplayDialog />
			{props.children}
		</>
	)
}

const PromptDisplayDialog = observer(() => {
	const length = modalService.dialogTrackers.length

	if (length === 0) {
		return <></>
	}

	const firstTracker = modalService.dialogTrackers[0]

	return (
		<Dialog fullWidth open={true}>
			<firstTracker.Viewer
				close={(v) => {
					console.log('got v ', v)
					firstTracker.resolvePromise(v as FormResult<unknown>)
				}}
			/>
		</Dialog>
	)
})
