import { action } from 'mobx'
import { useCallback, useMemo, useState } from 'react'
import { DetailForm } from '../../../../../../api/DTOtemp'
import { FormAdvancedEventsSection } from './FormPageSections/FormAdvancedEventsSection'
import FormSettingsSection from './FormPageSections/FormSettingsSection'
import { FormValidationSection } from './FormPageSections/FormValidationSection'
import { FormVersionsSection } from './FormPageSections/FormVersionsSection'
import { PDFFormFields } from './FormPageSections/PDFFormFields'

export type FormSectionProps = {
	form: DetailForm
	packageId: number
	packageVersionNumber: number
	tabLabels: string[]
	onTabChanged: (event: React.SyntheticEvent, newValue: number) => void
}

type FormPageProps = {
	packageId: number
	packageVersionNumber: number
	form: DetailForm
	formType: 'HTML' | 'PDF' | 'FB'
	onUpdateForm: (newForm: DetailForm) => void
}

export const FormPage = (props: FormPageProps) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)

	const handleTabIndexChanged = action(
		useCallback((event: React.SyntheticEvent, newValue: number) => {
			setSelectedTabIndex(newValue)
		}, []),
	)

	const tabLabels = useMemo(() => {
		let temp = ['Settings', 'Versions', 'Validation', 'Advanced Events']
		if (props.formType === 'PDF') temp = temp.concat('Overrides')
		return temp
	}, [props.formType])

	const tabSelector = () => {
		if (selectedTabIndex === 0)
			return (
				<FormSettingsSection
					tabLabels={tabLabels}
					onTabChanged={handleTabIndexChanged}
					{...props}
				/>
			)
		if (selectedTabIndex === 1)
			return (
				<FormVersionsSection
					tabLabels={tabLabels}
					onTabChanged={handleTabIndexChanged}
					{...props}
				/>
			)
		if (selectedTabIndex === 2)
			return (
				<FormValidationSection
					tabLabels={tabLabels}
					onTabChanged={handleTabIndexChanged}
					{...props}
				/>
			)

		if (selectedTabIndex === 3)
			return (
				<FormAdvancedEventsSection
					tabLabels={tabLabels}
					onTabChanged={handleTabIndexChanged}
					{...props}
				/>
			)
		if (selectedTabIndex === 4)
			return (
				<PDFFormFields
					tabLabels={tabLabels}
					onTabChanged={handleTabIndexChanged}
					{...props}
				/>
			)
		throw new Error(
			`the selected tab index ${selectedTabIndex} is out of bounds`,
		)
	}

	return <>{tabSelector()}</>
}
