import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { PortalsClient } from '../../../../../api/clients/identity/PortalsClient'
import { FullscreenSpinner } from '../../../../../components/feedback/circular'
import {
	PortalPageContext,
	PortalPageContextProvider,
} from './PortalPageContext'
import { PortalSettingsPage } from './PortalSettingsPage'

export const PortalAdminPage = () => {
	const portalId = useParams<{ portalId: string }>().portalId
	const [portalData, setPortalData] = useState<PortalPageContext>()
	const [upperPortalNames, setUpperPortalNames] = useState<string[]>([])

	useEffect(() => {
		async function SetPortalContext() {
			const api = new PortalsClient()

			try {
				const versions = await api.GetLatestPortalVersions()
				const portal = await api.GetPortal(portalId)

				const version = versions.data.find((v) => v.portalId === portal.data.id)
				if (version === undefined)
					throw new Error(
						`Cannot find a version for portal with id ${portal.data.id}`,
					)
				const contextInternal: PortalPageContext = {
					version: version,
					portal: portal.data,
				}

				setPortalData(contextInternal)
				setUpperPortalNames(versions.data.map((d) => d.name.toUpperCase()))
			} catch (err) {
				console.error(err)
			}
		}

		SetPortalContext()
	}, [portalId])

	if (portalData === undefined) return <FullscreenSpinner />
	return (
		<PortalPageContextProvider data={portalData}>
			<PortalSettingsPage portalNames={upperPortalNames} />
		</PortalPageContextProvider>
	)
}
