import { AddCircle, ArrowCircleLeft } from '@mui/icons-material'
import { Box, Drawer, IconButton, Tooltip } from '@mui/material'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { ActivityOptions } from './ActivityOptions'
import { EditActivity } from './EditActivity'
import { EditorDisplayType } from './Types'
import { ViewActivityInstance } from './ViewActivityInstance'
import { useWorkflowStore } from './stores/WorkflowStore'

enum WorkflowDrawerType {
	AddActivity = 0,
	EditActivity = 1,
	ViewInstance = 2,
}

type ActivityDrawerProps = {
	displayType: EditorDisplayType
	onRemoveActivity: (id: string) => void
	onCloseDrawer: () => void
}

export const ActivityDrawer = observer((props: ActivityDrawerProps) => {
	const { workflowContext, activityConfigurationStore } = useWorkflowStore()

	const localStore = useLocalObservable(() => ({
		drawerType: undefined as WorkflowDrawerType | undefined,
	}))

	useEffect(() => {
		if (workflowContext.selectedActivityDefinitionId !== undefined)
			runInAction(() => {
				if (props.displayType === EditorDisplayType.Instance)
					localStore.drawerType = WorkflowDrawerType.ViewInstance
				else localStore.drawerType = WorkflowDrawerType.EditActivity
			})
	}, [workflowContext.selectedActivityDefinitionId])

	const drawerContent = useMemo(() => {
		if (localStore.drawerType === undefined) return <></>

		if (localStore.drawerType === WorkflowDrawerType.AddActivity)
			return <ActivityOptions />

		const definition = workflowContext.activityDefinitions.find(
			(v) => v.uniqueId === workflowContext.selectedActivityDefinitionId,
		)
		if (definition === undefined) return <></>

		const configuration = activityConfigurationStore.getConfiguration(
			definition.activityTypeId,
		)

		if (!configuration)
			throw `❌ no configurations match type id ${definition.activityTypeId}`

		if (localStore.drawerType === WorkflowDrawerType.EditActivity) {
			return (
				<EditActivity
					activity={definition}
					configuration={configuration}
					onRemoveActivity={action((id) => {
						props.onRemoveActivity(id)
						localStore.drawerType = undefined
					})}
					readonly={props.displayType === EditorDisplayType.ReadonlyVersion}
				/>
			)
		}

		const instances = workflowContext.instance?.activityInstances.filter(
			(instance) => instance.referenceId === definition.uniqueId,
		)

		if (localStore.drawerType === WorkflowDrawerType.ViewInstance)
			return (
				<ViewActivityInstance
					definition={definition}
					instances={instances ?? []}
				/>
			)
	}, [localStore.drawerType, workflowContext.selectedActivityDefinitionId])

	return (
		<>
			<Box
				component={Drawer}
				transitionDuration={300}
				anchor="left"
				open={localStore.drawerType !== undefined}
				elevation={0}
				PaperProps={{ sx: { width: '25%' } }}
				variant="persistent"
				flexShrink={0}
			>
				<Tooltip
					title={
						localStore.drawerType === WorkflowDrawerType.EditActivity &&
						props.displayType === EditorDisplayType.ActiveVersion
							? 'Add an Activity'
							: 'Close sidebar'
					}
				>
					<Box
						component={IconButton}
						position={'absolute'}
						right={0}
						top={0}
						onClick={action(() => {
							props.onCloseDrawer()
							if (
								localStore.drawerType === WorkflowDrawerType.EditActivity &&
								props.displayType === EditorDisplayType.ActiveVersion
							) {
								localStore.drawerType = WorkflowDrawerType.AddActivity
							} else {
								localStore.drawerType = undefined
							}
						})}
						color="primary"
					>
						<ArrowCircleLeft fontSize="small" />
					</Box>
				</Tooltip>
				<Box>{drawerContent}</Box>
			</Box>
			{props.displayType === EditorDisplayType.ActiveVersion && (
				<Tooltip title="Add an Activity">
					<Box
						component={IconButton}
						position="absolute"
						left={0}
						top="50%"
						zIndex={(theme) => theme.zIndex.speedDial}
						onClick={action(() => {
							console.log('on click')
							localStore.drawerType = WorkflowDrawerType.AddActivity
						})}
						color="primary.main"
					>
						<AddCircle />
					</Box>
				</Tooltip>
			)}
		</>
	)
})
