import { Add } from '@mui/icons-material'
import { Button, Divider, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { percent } from 'csx'
import { runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { GroupUserAssociationsClient } from '../../../../../api/clients/identity'
import { CurrentOrganizationUsersClient } from '../../../../../api/clients/identity/CurrentOrganizationUsersClient'
import { BaseUser } from '../../../../../api/DTO'
import { GroupUserSimpleAssociation } from '../../../../../api/DTOtemp'
import { toastService } from '../../../../../services/notifications/ToastService'
import pluralizeString from '../../../../../utils/PluralizeString'
import AdministrationPageContainer from '../../AdministrationPageContainer'
import { AdministrationPageTabs } from '../../AdministrationPageTabs'
import { AddValuesDialog } from '../AddValuesDialog'
import { GroupAdministrationPageProps } from '../GroupPage'
import { GroupUsersTable } from './GroupUsersTable'

export const GroupUsersAdministrationPage = observer(
	({ group, tabLabels, onTabChanged }: GroupAdministrationPageProps) => {
		const styles = useStyles()

		const groupUserClient = new GroupUserAssociationsClient()

		const localStore = useLocalObservable(() => ({
			groupUsers: [] as BaseUser[],
			organizationUsers: [] as BaseUser[],

			// get the users in the organization that don't belong in the group to display when adding new users
			get filteredOrganizationUsers() {
				return this.organizationUsers.filter(
					(orgUser) =>
						!this.groupUsers
							.map((groupUser) => groupUser.id)
							.includes(orgUser.id),
				)
			},
			isRequesting: false,
		}))

		const getGroupUsers = useCallback(() => {
			return groupUserClient
				.GetGroupUserAssociations(undefined, group.id)
				.then((response) => response.data.map((v) => v.user))
		}, [])

		const getOrganizationUsers = useCallback(() => {
			const organizationUsersClient = new CurrentOrganizationUsersClient()

			return organizationUsersClient
				.getUsersForCurrentOrganization(undefined, {
					'cache-control': 'reload',
				})
				.then((response) => response.data.map((v) => v.user))
		}, [])

		useEffect(() => {
			;(async () => {
				const groupUsersPromise = getGroupUsers()
				const organizationUsersPromise = getOrganizationUsers()

				runInAction(() => {
					localStore.isRequesting = true
				})

				const groupUsers = await groupUsersPromise
				const organizationUsers = await organizationUsersPromise

				runInAction(() => {
					localStore.groupUsers = groupUsers
					localStore.organizationUsers = organizationUsers
					localStore.isRequesting = false
				})
			})()

			getGroupUsers()
		}, [])

		const handleAddUsers = async (users: BaseUser[]) => {
			const associations: GroupUserSimpleAssociation[] = []
			for (const user of users)
				associations.push({ userId: user.id, groupId: group.id })

			try {
				runInAction(() => {
					localStore.isRequesting = true
				})
				const addedCount = (
					await groupUserClient.BulkAddGroupUserAssociation(associations)
				).data
				const groupUsers = await getGroupUsers()

				runInAction(() => {
					localStore.groupUsers = groupUsers
				})

				toastService.displayToast({
					message: `${pluralizeString(addedCount, 'user')} added`,
					area: 'global',
				})
			} catch {
				toastService.displayToast({
					message: 'Error adding users',
					area: 'global',
				})
			} finally {
				runInAction(() => {
					localStore.isRequesting = false
				})
			}
		}

		const handleRemoveUsers = async (userIds: number[]) => {
			try {
				runInAction(() => {
					localStore.isRequesting = true
				})

				// get the ids of the associations to remove
				const { data } = await groupUserClient.GetGroupUserAssociations(
					undefined,
					group.id,
				)

				const associationsToRemove = data
					.filter((v) => userIds.includes(v.userId))
					.map((v) => v.id)

				const removedCount = (
					await groupUserClient.BulkDeleteGroupUserAssociations(
						associationsToRemove,
					)
				).data

				const groupUsers = await getGroupUsers()

				runInAction(() => {
					localStore.groupUsers = groupUsers
				})

				toastService.displayToast({
					message: `${pluralizeString(removedCount, 'user')} removed`,
					area: 'global',
				})
			} catch {
				toastService.displayToast({
					message: 'Error removing users',
					area: 'global',
				})
			} finally {
				runInAction(() => {
					localStore.isRequesting = false
				})
			}
		}

		return (
			<AdministrationPageContainer
				title={group.name}
				actions={
					<GroupUsersSectionButtons
						groupName={group.name}
						onAddUsers={handleAddUsers}
						filteredOrganizationUsers={localStore.filteredOrganizationUsers}
						isRequesting={localStore.isRequesting}
					/>
				}
			>
				<div className={styles.groupContentRoot}>
					<AdministrationPageTabs
						currentTab={1}
						onTabChanged={onTabChanged}
						tabLabels={tabLabels}
					/>
					<Divider />
					<GroupUsersTable
						users={localStore.groupUsers}
						onRemoveUsers={handleRemoveUsers}
						isRequesting={localStore.isRequesting}
					/>
				</div>
			</AdministrationPageContainer>
		)
	},
)

type GroupUsersSectionButtonsProps = {
	groupName: string
	filteredOrganizationUsers: BaseUser[]
	onAddUsers: (users: BaseUser[]) => void
	isRequesting: boolean
}

const GroupUsersSectionButtons = observer(
	(props: GroupUsersSectionButtonsProps) => {
		const [addUserOpen, setAddUserOpen] = useState(false)

		const handleOpenAddUser = useCallback(() => {
			setAddUserOpen(true)
		}, [])

		const handleCloseAddUser = useCallback(() => {
			setAddUserOpen(false)
		}, [])

		const handleAddUsers = useCallback((users: BaseUser[]) => {
			props.onAddUsers(users)
			setAddUserOpen(false)
		}, [])

		return (
			<div>
				<Button
					color="primary"
					variant="contained"
					startIcon={<Add />}
					onClick={handleOpenAddUser}
					disabled={props.isRequesting}
				>
					Add Users
				</Button>
				<AddValuesDialog<BaseUser>
					open={addUserOpen}
					onClose={handleCloseAddUser}
					getLabel={(v: BaseUser) => `${v.firstName} ${v.lastName}`}
					header={`Add Users to ${props.groupName}`}
					renderOption={(user) => {
						return (
							<>
								<Typography color="textPrimary" variant="subtitle2">
									{user.firstName} {user.lastName}
								</Typography>
								<Typography color="textSecondary" variant="body2">
									{user.emailAddress}
								</Typography>
							</>
						)
					}}
					options={props.filteredOrganizationUsers}
					onCommit={handleAddUsers}
					placeholder="Search users"
					addText="Add Users"
				/>
			</div>
		)
	},
)

const useStyles = makeStyles((theme) => ({
	groupContentRoot: {
		display: 'flex',
		flexDirection: 'column',

		width: percent(100),
		height: percent(100),
	},
}))
