import { Box, TextField } from '@mui/material'
import { Literal } from 'estree'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { ComponentCategory } from '../../../draggable/NodeComponents'
import { RendererComponent } from '../../../Types'
import useColorScheme from '../../internal/useColorScheme'

const NumberLiteralComponent = observer((props: RendererComponent<Literal>) => {
	const [color, contrastColor] = useColorScheme(ComponentCategory.Variables)

	return (
		<Box bgcolor={contrastColor} padding={1}>
			<TextField
				sx={{ input: { color: color } }}
				variant="standard"
				type="number"
				size="medium"
				value={props.node.value}
				onChange={action(
					(evt) => (props.node.value = parseInt(evt.currentTarget.value)),
				)}
			/>
		</Box>
	)
})

export default NumberLiteralComponent
