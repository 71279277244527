import { createEvent } from "../../../FormBuilderCore/eventBus/EventSpecification"
import { EventBusEventSource } from "../../../FormBuilderCore/eventBus/EventTypes"

export type EventBusFormLoadEventProps = Record<string, unknown>

export type EventBusFormLoadEventSource = EventBusEventSource

export const formLoadEventId = '98ee7d9c-d87d-47c5-aff6-45676487936b'

export const [formLoadEventEmitter, formLoadEventConsumer] = createEvent<
	EventBusFormLoadEventProps,
	EventBusFormLoadEventSource
>(formLoadEventId, 'Form load')
