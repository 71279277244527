import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import SecureClient from '../SecureClient'
import {
	BaseDataProviderInstanceModel,
	DataProviderInstance,
	DataProviderInstanceMetadata,
} from './DTO'

export default class DataProviderInstancesClient extends SecureClient {
	/**
	 *
	 */
	constructor() {
		super()
	}

	public getDataProviderInstances = (): Promise<
		AxiosResponse<DataProviderInstance[]>
	> => {
		return this._api.get<DataProviderInstance[]>(`DataProviderInstances`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader,
			},
		})
	}

	public getMetadataForInstances = (): Promise<
		AxiosResponse<DataProviderInstanceMetadata[]>
	> => {
		return this._api.get<DataProviderInstanceMetadata[]>(
			`DataProviderInstances/Metadata`,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
					...this.authorizationHeader,
				},
			},
		)
	}

	public getInstance = (
		instanceId: string,
	): Promise<AxiosResponse<DataProviderInstance>> => {
		return this._api.get<DataProviderInstance>(
			`DataProviderInstances/${instanceId}`,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
					...this.authorizationHeader,
				},
			},
		)
	}

	public getInstanceByName = (
		name: string,
	): Promise<AxiosResponse<DataProviderInstance>> => {
		return this._api.get<DataProviderInstance>(
			`DataProviderInstances/Name/${name}`,
			{
				headers: {
					...this.acceptHeader,
					...this.contentTypeHeader,
					...this.authorizationHeader,
				},
			},
		)
	}

	public configure = (
		baseModel: BaseDataProviderInstanceModel,
	): Promise<AxiosResponse<DataProviderInstance>> => {
		return this._api.post<DataProviderInstance>(
			`DataProviderInstances`,
			baseModel,
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
					...this.authorizationHeader,
				},
			},
		)
	}

	public updateInstance = (
		instanceId: string,
		baseModel: BaseDataProviderInstanceModel,
	): Promise<AxiosResponse<DataProviderInstance>> => {
		return this._api.put(`DataProviderInstances/${instanceId}`, baseModel, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader,
			},
		})
	}

	public execute = (
		instanceId: number,
		parameters: Record<string, string>,
	): Promise<AxiosResponse<Record<string, unknown>[]>> => {
		const headers: AxiosRequestHeaders = {
			...this.acceptHeader(),
			...this.contentTypeHeader()
		}

		if (this.hasAuthorizationHeader)
			headers['Authorization'] = this.authorizationHeader.Authorization

		return this._api.post<Record<string, unknown>[]>(
			`DataProviderInstances/${instanceId}`,
			parameters,
			{
				headers: headers
			},
		)
	}

	public executeByName = (
		name: string,
		parameters: Record<string, string>,
	): Promise<AxiosResponse<Record<string, unknown>[]>> => {
		const headers: AxiosRequestHeaders = {
			...this.acceptHeader(),
			...this.contentTypeHeader()
		}

		if (this.hasAuthorizationHeader)
			headers['Authorization'] = this.authorizationHeader.Authorization

		return this._api.post<Record<string, unknown>[]>(
			`DataProviderInstances/Name/${name}`,
			parameters,
			{
				headers: headers,
			},
		)
	}

	public deleteInstance = (instanceId: string): Promise<AxiosResponse> => {
		return this._api.delete(`DataProviderInstances/${instanceId}`, {
			headers: {
				...this.acceptHeader(),
				...this.contentTypeHeader(),
				...this.authorizationHeader,
			},
		})
	}
}
