import { Check } from '@mui/icons-material'
import Edit from '@mui/icons-material/Edit'
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material'
import { ChangeEvent, useCallback, useState } from 'react'

type ToggleTextInputProps = {
	onChecked: (value: string) => void
	getErrorMessage: (value: string) => string
} & TextFieldProps

const ToggleTextInput = ({
	onChecked,
	getErrorMessage,
	value,
	...rest
}: ToggleTextInputProps) => {
	const [textFieldEditable, setTextFieldEditable] = useState(false)
	const [newValue, setNewValue] = useState<string>(value as string)
	const [errorMessage, setErrorMessage] = useState('')

	const toggleFieldEditableState = useCallback(() => {
		setTextFieldEditable(!textFieldEditable)

		if (textFieldEditable) onChecked(newValue)
	}, [textFieldEditable, newValue])

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setNewValue(event.target.value)

		handleGetErrorMessage(event.target.value)
	}

	const handleGetErrorMessage = useCallback(
		(value: string) => {
			setErrorMessage(getErrorMessage(value))
		},
		[newValue],
	)

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			<>
				{textFieldEditable ? (
					<TextField
						{...rest}
						value={newValue}
						onChange={handleChange}
						error={errorMessage !== ''}
						helperText={errorMessage}
						autoFocus
					/>
				) : (
					value
				)}
				<IconButton
					onClick={toggleFieldEditableState}
					size="large"
					disabled={errorMessage !== ''}
				>
					{textFieldEditable ? <Check /> : <Edit />}
				</IconButton>
			</>
		</Box>
	)
}

export default ToggleTextInput
