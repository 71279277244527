import { Delete } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { BaseActivityDefinitionModel } from '../../api/clients/workflows/DTOs'
import { ActivityCard } from './ActivityCard'

type MissingPluginActivityProps = {
	definition: BaseActivityDefinitionModel
	onRemoveDefinition: () => void
}

export const MissingPluginActivity = ({
	definition,
	onRemoveDefinition,
}: MissingPluginActivityProps) => {
	return (
		<Box
			left={definition.left}
			top={definition.top}
			position="absolute"
			minWidth="310px" // to fit title and the delete icon
		>
			<ActivityCard
				icon={'Warning'}
				activityTitle={
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						gap={1}
					>
						Activity Plugin Missing{' '}
						<Tooltip title="Delete">
							<IconButton onClick={onRemoveDefinition}>
								<Delete />
							</IconButton>
						</Tooltip>
					</Box>
				}
				description={
					'This workflow cannot be saved and will not run unless this activity is deleted'
				}
				border={2}
				borderColor="error.main"
			/>
		</Box>
	)
}
