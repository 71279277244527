import { runInAction } from 'mobx'
import { SignedInUser } from '../../../../api/DTO'
import { FieldMapping } from '../../../../api/DTOtemp'
import {
	PackageApi,
	PackageApiStatus,
} from '../../../../pages/PackageHostPage/PackageApi'
import { EventBusEventSource } from '../../../FormBuilderCore/eventBus/EventTypes'
import { IUnifiedFormHost } from '../../FormHost/IUnifiedFormHost'
import { IEventAction } from '../IEventAction'
import { FieldMappingConfigurator } from './FieldMappingConfigurator'

export enum SourceType {
	OrganizationProperty = 'Organization Property',
	UserProperty = 'User Property',
	CurrentUserInfo = 'Current User Info',
	QueryString = 'Query String',
}

export type FieldMappingSource = {
	type: SourceType
	id: number | string
	displayName: string
}

export type PropertyFieldMapping = {
	source: FieldMappingSource
} & Omit<FieldMapping, 'description'>

export const FieldMappingActionId = 'a3d3a77c-b101-4081-a464-b23b2dbd1660'

export const FieldMappingActionDefinition: IEventAction<
	PropertyFieldMapping[]
> = {
	id: FieldMappingActionId,
	actionName: 'Field Mapping',
	description: 'Map properties to form fields',
	configurator: FieldMappingConfigurator,
	defaultValue: [],
	shouldActivateAsync: (
		eventId: string,
		_props: unknown,
		value: PropertyFieldMapping[],
		_originalEventSource: EventBusEventSource,
		_currentEventSource: EventBusEventSource,
		packageApi: PackageApi,
	) => {
		// if there are no field mappings there's no need to go thru with the action
		if (value.length === 0) return Promise.resolve(false)

		// this shouldn't be an issue b/c the form isn't loaded until the status is
		// complete but better safe than sorry!
		if (packageApi.status !== PackageApiStatus.Complete) {
			console.log('not performing field mapping, package not loaded')
			return Promise.resolve(false)
		}

		console.log('package api status: ', packageApi.status)

		return Promise.resolve(true)
	},
	actionAsync: (
		_eventId: string,
		_props: unknown,
		value: PropertyFieldMapping[],
		_originalEventSource: EventBusEventSource,
		currentEventSource: EventBusEventSource,
		packageApi: PackageApi,
	) => {
		for (const fieldMapping of value) {
			console.log(
				'evaluating field mapping with type: ' + fieldMapping.source.type,
			)
			switch (fieldMapping.source.type) {
				case SourceType.OrganizationProperty: {
					const organizationProperty = packageApi.organizationProperties.find(
						(v) => v.id === fieldMapping.source.id,
					)

					fieldMapping.value = organizationProperty?.value.value
					break
				}
				case SourceType.UserProperty: {
					const userProperty = packageApi.userPropertyAssociations.find(
						(v) =>
							v.id === fieldMapping.source.id &&
							v.userId === packageApi.currentUser?.id,
					)

					fieldMapping.value = userProperty?.propertyValue
					break
				}
				case SourceType.CurrentUserInfo: {
					const currentUser = packageApi.currentUser

					if (currentUser === undefined) return Promise.resolve(false)

					// we don't have a full name property so we have to construct it
					if (fieldMapping.source.id === 'fullName')
						fieldMapping.value =
							currentUser.firstName + ' ' + currentUser.lastName
					else
						fieldMapping.value =
							currentUser[fieldMapping.source.id as keyof SignedInUser]

					break
				}
				case SourceType.QueryString: {
					const queryParams = new URLSearchParams(location.search)

					if (!queryParams.has(fieldMapping.source.id as string)) {
						fieldMapping.value = ''
						break
					}

					const value = queryParams.get(fieldMapping.source.id as string)
					fieldMapping.value = value
					break
				}
				default: {
					return Promise.resolve(false)
				}
			}

			setInstanceValuesAsync(
				fieldMapping,
				currentEventSource.formHost as IUnifiedFormHost,
			)
		}

		return Promise.resolve()
	},
}

const setInstanceValuesAsync = async (
	fieldMapping: PropertyFieldMapping,
	formHost: IUnifiedFormHost,
) => {
	const linkedInstances = formHost.cellInstances.filter(
		(v) =>
			v.type === 'value' &&
			fieldMapping.linkedFields
				.filter((v) => v.formId === formHost.formId)
				.map((v) => v.cellDefinitionId)
				.includes(v.definitionId),
	)

	// set each instance's value to the new value
	for (const instance of linkedInstances) {
		if (instance.type === 'value' && instance.value !== fieldMapping.value)
			runInAction(() => {
				instance.value = fieldMapping.value
			})
	}
}
