import Editor from '@monaco-editor/react'
import { Error } from '@mui/icons-material'
import {
	Box,
	Button,
	Chip,
	ChipProps,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { useCallback, useMemo, useState } from 'react'
import { LogEntry, LogLevel } from '../../api/clients/workflows/DTOs'

type LogDialogProps = {
	open: boolean
	logTitle: string
	logEntries: LogEntry[]
	onClose: () => void
}

const getLogLevelLabel = (logLevel: LogLevel) => {
	switch (logLevel) {
		case LogLevel.Trace:
			return 'Trace'
		case LogLevel.Debug:
			return 'Debug'
		case LogLevel.Information:
			return 'Information'
		case LogLevel.Warning:
			return 'Warning'
		case LogLevel.Error:
			return 'Error'
		case LogLevel.Critical:
			return 'Critical'
		default:
			return logLevel.toString()
	}
}

export const LogDialog = (props: LogDialogProps) => {
	const [logDisplay, setLogDisplay] = useState<'table' | 'json'>('table')

	const renderLogDisplay = useCallback(() => {
		if (logDisplay === 'table')
			return (
				<LogTable
					logEntries={props.logEntries.sort(
						(a, b) =>
							new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime(),
					)}
				/>
			)

		return <LogEditor logEntries={props.logEntries} />
	}, [logDisplay, props.logEntries])

	return (
		<Dialog open={props.open} maxWidth="lg">
			<Box
				component={DialogTitle}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				{props.logTitle}
				<Select
					value={logDisplay}
					size="small"
					onChange={(evt) =>
						setLogDisplay(evt.target.value as 'table' | 'json')
					}
				>
					<MenuItem value="table">Table</MenuItem>
					<MenuItem value="json">JSON</MenuItem>
				</Select>
			</Box>
			<Box component={DialogContent} overflow="hidden">
				{renderLogDisplay()}
			</Box>
			<DialogActions>
				<Button variant="outlined" onClick={props.onClose}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}

type LogEditorProps = {
	logEntries: LogEntry[]
}

const LogEditor = (props: LogEditorProps) => {
	return (
		<Box width="100%">
			<Editor
				language="json"
				height="50vh"
				width="100vh"
				value={JSON.stringify(props.logEntries, null, 2)}
				options={{
					automaticLayout: true,
					minimap: {
						enabled: false,
					},
					fontSize: 16,
					domReadOnly: true,
					readOnly: true,
					lineNumbers: false,
					scrollBeyondLastLine: false,
				}}
			/>
		</Box>
	)
}

type LogTableProps = {
	logEntries: LogEntry[]
}

const LogTable = observer((props: LogTableProps) => {
	const [pageNumber, setPageNumber] = useState(0)
	const [pageSize, setPageSize] = useState(10)

	// replace message params with the actual values from eventData
	const getFormattedMessage = action(
		(message: string, eventData: unknown[]): string =>
			message.replace(/{[0-9]+}/g, (match: string, key: string) => {
				console.log('running replacer', match, key)
				const strIndex = match.substring(1, match.length - 1)
				console.log(strIndex)
				const index = parseInt(strIndex)
				return eventData[index] !== undefined
					? (eventData[index] as string)
					: match
			}),
	)

	return (
		<>
			<Box component={TableContainer} maxHeight="60vh">
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Event</TableCell>
							<TableCell>Level</TableCell>
							<TableCell>Time</TableCell>
							<TableCell>Message</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(pageSize > 0
							? props.logEntries.slice(
									pageNumber * pageSize,
									pageNumber * pageSize + pageSize,
							  )
							: props.logEntries
						).map((entry, i) => (
							<TableRow key={i}>
								<TableCell>{entry.eventId}</TableCell>
								<TableCell>
									<LogLevelChip logLevel={entry.logLevel} />
								</TableCell>
								<TableCell>{new Date(entry.eventDate).toISOString()}</TableCell>
								<Box
									component={TableCell}
									sx={{ wordWrap: 'break-word', whiteSpace: 'none' }}
								>
									{entry.eventData !== undefined
										? getFormattedMessage(
												entry.message,
												entry.eventData as unknown[],
										  )
										: entry.message}
								</Box>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
			<TablePagination
				component="div"
				count={props.logEntries.length}
				page={pageNumber}
				onPageChange={(_, page) => setPageNumber(page)}
				rowsPerPage={pageSize}
				onRowsPerPageChange={(evt) => {
					setPageSize(parseInt(evt.target.value, 10))
					setPageNumber(0)
				}}
			/>
		</>
	)
})

type LogLevelChipProps = {
	logLevel: LogLevel
}

const LogLevelChip = ({ logLevel }: LogLevelChipProps) => {
	const color = useMemo<ChipProps['color']>(() => {
		switch (logLevel) {
			case LogLevel.Trace:
				return 'info'
			case LogLevel.Debug:
				return 'primary'
			case LogLevel.Information:
				return 'success'
			case LogLevel.Warning:
				return 'warning'
			case LogLevel.Error:
				return 'error'
			case LogLevel.Critical:
				return 'error'
			default:
				return 'default'
		}
	}, [])

	return (
		<Chip
			label={getLogLevelLabel(logLevel)}
			color={color}
			icon={logLevel === LogLevel.Critical ? <Error /> : undefined}
		/>
	)
}
