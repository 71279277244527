import { AxiosResponse } from 'axios'
import { TrackedResource } from '../../../pages/PackageHostPage/Attachments/AttachFilesDialog'
import { DetailFormWithData } from '../../../services/offline/FormPackageHandling'
import {
	BaseFormPackageInstanceModel,
	CommitIntent, FormPackageInstance
} from '../../DTOtemp'
import SecureClient from '../SecureClient'

export class FormPackageInstancesClient extends SecureClient {

	private _urlBase: string

	constructor() {
		super()
		this._urlBase = `FormPackages/instances`
	}

	public CreateFormPackageInstance(
		baseModel: BaseFormPackageInstanceModel,
		forms: DetailFormWithData[],
	): Promise<AxiosResponse<FormPackageInstance>> {
		const formData = new FormData()

		for (const form of forms)
			formData.append('forms', form.stream, form.id.toString())

		formData.set('instanceModel', JSON.stringify(baseModel))

		return this._api.post(this._urlBase, formData, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}

	public UpdateFormPackageInstance(
		id: number,
		baseModel: BaseFormPackageInstanceModel,
	): Promise<AxiosResponse<FormPackageInstance>> {
		const formData = new FormData()
		
		formData.set('instanceModel', JSON.stringify(baseModel))
		return this._api.put(`${this._urlBase}/${id}`, formData, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}

	public GetFormPackageInstance(
		id: number | string,
	): Promise<AxiosResponse<BaseFormPackageInstanceModel>> {
		return this._api.get(`${this._urlBase}/${id}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
				...this.contentTypeHeader(),
			},
		})
	}



	public addOrUpdateResource(
		instanceId: number,
		attachment: TrackedResource,
	): Promise<AxiosResponse<Blob>> {
		const url = `${this._urlBase}/${instanceId}/resources/${attachment.resourceId}`

		console.log('attachment file type: ', attachment.file.type)

		const formData = new FormData()

		formData.append('attachment', attachment.file)
		formData.append('supportingDocumentType', attachment.type)
		formData.append('persistenceStatus', attachment.persistenceStatus.toString())

		return this._api.put(url, formData, {
			responseType: 'blob',
			headers: {
				...this.authorizationHeader,
				...this.contentTypeHeader('multipart/form-data'),
			},
		})
	}

	public retrieveResource(instanceId: number, resourceId: string): Promise<AxiosResponse<Blob>> {
		const url = `${this._urlBase}/${instanceId}/resources/${resourceId}`

		return this._api.get(url, {
			responseType: 'blob',
			headers: {
				...this.authorizationHeader,
			}
		})
	}

	public commitPackage(
		instanceId: number,
		intent: CommitIntent,
	): Promise<AxiosResponse> {
		const url = `${this._urlBase}/${instanceId}/commit`

		return this._api.post(url, intent, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
			params: {
				intent,
			},
		})
	}

	public deleteFormPackageInstance(instanceId: number): Promise<AxiosResponse> {
		return this._api.delete(`${this._urlBase}/${instanceId}`, {
			headers: {
				...this.authorizationHeader,
				...this.acceptHeader(),
			},
		})
	}
}
