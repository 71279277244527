import { AxiosResponse } from "axios";
import ClientBase from "../ClientBase";

export class AdministrationClient extends ClientBase {
	public ValidateDatabase = (): Promise<AxiosResponse> => {
		return this._api.post('/Administration/ValidateDatabase',
			{
				headers: {
					...this.acceptHeader(),
					...this.contentTypeHeader(),
				}
			})
	}
}