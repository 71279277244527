
export type BaseUser = {
	id: number
	firstName: string
	lastName: string
	emailAddress: string
}

export type AdminRegisteredUsersModel = {
	baseUser: BaseUser
	errors: string[]
}

export type CompositeKeyEntity = {
	id: number
	uniqueId: string
}

export type RoleLicense = BaseLicense & {
	roles: BaseRole[]
}

export type ConcurrentEntity = CompositeKeyEntity & {
	rowVersion: string
}

export type TrackingEntity = ConcurrentEntity & {
	createdDate: Date
	createdBy: number
	lastModifiedDate: Date
	lastModifiedBy: number
}

export type BaseRole = CompositeKeyEntity & {
	friendlyName: string
	description: string
}

export type AuthenticationMethod = FormsInMotionAuthenticationMethod | ExternalProviderAuthenticationMethod

export type FormsInMotionAuthenticationMethod = {
	authenticationType: AuthenticationType.FormsInMotionAuthentication
	name: string
}

export type ExternalProviderAuthenticationMethod = {
	authenticationType: AuthenticationType.ExternalProvider
	authenticationProviderInstanceId: string
	name: string
}

export enum AuthenticationType {
	FormsInMotionAuthentication = 0,
	ExternalProvider = 1
}

export type LocalUserAuthentication = {
	username: string
	password: string
}

export type BaseGroup = TrackingEntity & {
	name: string
	description: string
}

export type GroupNameId = {
	name: string
	id: number
}

export type BaseLicense = {
	id: number
	startDate: Date
	endDate: Date
	productName: string
}

export type BaseOrganization = TrackingEntity & {
	name: string
	subDomain: string
	description: string
}

export type ProductPurchase = {
	id: string
	startDate: Date
	endDate: Date
}

export type BaseProduct = CompositeKeyEntity & {
	name: string
	description: string
}

export type RoleProduct = BaseProduct & {
	roles: BaseRole[]
}

export type ExpandedGroup = BaseGroup & {
	users: BaseUser[]
	roles: BaseRole[]
}

export type NewUser = {
	firstName: string
	lastName: string
	emailAddress: string
	password: string
	confirmPassword: string
}

export type SignedInUser = BaseUser

export type BaseFormPackage = TrackingEntity & {
	name: string
}

export type CreateUsers = {
	users: BaseCreateUsers[]
}
export type BaseCreateUsers =  {
	firstName: string
	lastName: string
	emailAddress: string
}

export type SelfRegister = {
	registrationCode: string
	firstName: string
	lastName: string
	emailAddress: string
	password: string
}