import {
	Box,
	FormControlLabel,
	Switch,
	SwitchProps as MaterialUiSwitchProps,
	Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { ReactNode } from 'react'

type DescriptiveSwitchProps = {
	headerLabel: ReactNode
	bodyLabel: ReactNode

	containerClassName?: string
} & MaterialUiSwitchProps

const DescriptiveSwitch = ({
	headerLabel,
	bodyLabel,
	containerClassName,
	...rest
}: DescriptiveSwitchProps) => {
	const styles = useStyles()

	return (
		<div className={containerClassName}>
			<FormControlLabel
				className={styles.formControlLabelStyleOverride}
				labelPlacement="top"
				label={
					<>
						<Box fontWeight={500}>
							<Typography
								className={styles.formControlLabelFontWeightInherit}
								component="h6"
								variant="body2"
							>
								{headerLabel}
							</Typography>
						</Box>
						<Typography variant="body2" color="textSecondary">
							{bodyLabel}
						</Typography>
					</>
				}
				control={<Switch {...rest} />}
			/>
		</div>
	)
}

const useStyles = makeStyles((theme) => ({
	formControlLabelStyleOverride: {
		margin: 0,
		alignItems: 'start',
	},

	formControlLabelFontWeightInherit: {
		fontWeight: 'inherit',
	},
}))

export default DescriptiveSwitch
