import { Chip } from '@mui/material'
import { useEffect, useState } from 'react'
import { GroupsClient } from '../../api/clients/identity'
import { CurrentOrganizationUsersClient } from '../../api/clients/identity/CurrentOrganizationUsersClient'
import { AssignmentType } from '../../api/clients/workItems/DTOs'

type UserChipProps = {
	id: number
	assignmentType: AssignmentType
}

const UserChip = (props: UserChipProps) => {
	const [chipLabel, setChipLabel] = useState<string>()

	const getUserName = async (): Promise<void> => {
		const organizationUsersClient = new CurrentOrganizationUsersClient()

		const response = await organizationUsersClient.getUser(props.id)

		if (response === undefined) {
			setChipLabel('unknown')
			return
		}

		setChipLabel(
			`${response.data.user.firstName} ${response.data.user.lastName}`,
		)
	}

	const getGroupName = async (): Promise<void> => {
		const groupClient = new GroupsClient()
		const { data } = await groupClient.GetGroupName(props.id)
		setChipLabel(data.name)
	}

	useEffect(() => {
		if (props.assignmentType === AssignmentType.User) getUserName()
		else if (props.assignmentType === AssignmentType.Group) getGroupName()
		else if (props.assignmentType === AssignmentType.AnonymousLink)
			setChipLabel('Anonymous')
		else
			throw new Error(
				'invalid assignment type provided ' +
					(props as { assignmentType: number }).assignmentType,
			)
	}, [props.id, props.assignmentType])

	return <Chip label={chipLabel} />
}

export default UserChip
