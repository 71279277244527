import { CalendarMonth, Numbers, TextFields } from '@mui/icons-material'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BoxProps } from '@mui/system'
import clsx from 'clsx'
import { percent, px } from 'csx'
import { action, runInAction } from 'mobx'
import { useLocalObservable } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { DocumentInitParameters } from 'pdfjs-dist/types/src/display/api'
import React, {
	MutableRefObject,
	RefObject,
	createRef,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { SignatureStatus, SignatureValue } from '../../../api/DTOtemp'
import { FullscreenSpinner } from '../../../components/feedback/circular'
import { Signature } from '../../../controls/specifics/signatures/SignatureContext'
import { SignatureHost } from '../../../controls/specifics/signatures/SignatureHost'
import { useRuntimePackageContext } from '../../../pages/PackageHostPage/RuntimePackageContext'
import { CacheDetailForm } from '../../../services/offline/FormPackageStorage'
import { MergeDefinitionOverrides } from '../../../utils/PdfOverrides'
import { EventBus } from '../../FormBuilderCore/eventBus/EventBus'
import { changeEventConsumer } from '../../FormBuilderInterop/EventBus/BuiltInEvents/ChangeEvent'
import { FormIntegrationProps } from '../Types'
import { PdfFormManager } from './PdfFormHost'
import { createDeferredPromise } from './PdfFormViewer.assets'
import { PdfPackageHostWrapper } from './PdfPackageHostWrapper'
import {
	DefinitionCoordinates,
	PdfCellDefinition,
} from './Types/PdfCellDefinition'
import { PdfCellInstance } from './Types/PdfCellInstance'

type PdfPage = {
	pageNumber: number
	dataUrl: string
	height: number
	width: number
	cellInstances: PdfCellInstance[]
}

enum PdfLoadState {
	NotLoaded,
	Loading,
	Loaded,
}

type PdfFormProps = {
	containerRef: RefObject<HTMLDivElement>
} & FormIntegrationProps

export const PdfForm = observer((props: PdfFormProps) => {
	const { eventBus } = useRuntimePackageContext()

	const hostRef = React.createRef<HTMLDivElement>()
	const formManagerRef = React.useRef<PdfFormManager | undefined>()

	const loadedPagesRef = React.useRef<number>(0)
	const loadedPagesDeferredPromiseRef = useRef(createDeferredPromise<void>())
	const [loadedPagesPromise, resolvePagesLoaded, _] =
		loadedPagesDeferredPromiseRef.current

	const localStore = useLocalObservable(() => ({
		pdfPages: [] as PdfPage[],
		pdfLoadState: PdfLoadState.NotLoaded as PdfLoadState,
	}))

	const scale = 3

	const styles = useStyles()

	useEffect(() => {
		if (localStore.pdfLoadState !== PdfLoadState.NotLoaded) return

		const currentElement = hostRef.current
		if (currentElement === null) return

		const currentDocument = currentElement.ownerDocument

		async function fetchFormData() {
			const definitions = MergeDefinitionOverrides(
				props.form.metadata.cellDefinitions,
				props.form.metadata.cellOverrides,
			) as PdfCellDefinition[]

			let cellInstances: PdfCellInstance[] = []
			const castForm = props.form as CacheDetailForm
			if (castForm.cellInstances !== undefined)
				cellInstances = castForm.cellInstances as PdfCellInstance[]

			const formManager = new PdfFormManager(
				definitions,
				cellInstances,
				eventBus,
				currentDocument,
			)
			formManager.formId = props.formId

			formManagerRef.current = formManager

			runInAction(() => (localStore.pdfLoadState = PdfLoadState.Loading))

			await renderDocument(formManager.cellInstances, scale)
				.then(
					action(() => {
						localStore.pdfLoadState = PdfLoadState.Loaded
					}),
				)
				.then(() => loadedPagesPromise)
				.then(
					action(() => {
						props.onFormLoaded(
							new PdfPackageHostWrapper(formManager, currentDocument),
						)
					}),
				)
		}

		fetchFormData()
	}, [localStore.pdfLoadState])

	useEffect(() => {
		if (localStore.pdfLoadState !== PdfLoadState.Loaded) return

		if (formManagerRef.current === undefined) return

		formManagerRef.current.setSavedInstanceValues()
	}, [localStore.pdfLoadState])

	const handleSelectSignature = (instanceId: string, signature: Signature) => {
		const instance = formManagerRef.current?.cellInstances.find(
			(v) => v.id === instanceId,
		)

		if (instance === undefined) return // todo or throw error

		instance.value = {
			emailAddress: signature.email,
			fullName: signature.fullName,
			image: signature.signatureData,
			status: SignatureStatus.Untracked,
		} as SignatureValue
	}

	const dataUrl = useMemo(
		() => URL.createObjectURL(props.form.stream),
		[props.form.stream],
	)

	const renderDocument = async (
		cellInstances: PdfCellInstance[],
		scale: number,
	) => {
		/* load the new empty version of the form if we're not loading a form package instance.
			 load the forwarded form otherwise so we have the forwarded field values/signatures */

		await import('pdfjs-dist').then(async (pdfjsLib) => {
			pdfjsLib.GlobalWorkerOptions.workerSrc = await import(
				'pdfjs-dist/build/pdf.worker.entry'
			)

			const loadingTask = pdfjsLib.getDocument({
				url: dataUrl,
			} as DocumentInitParameters)
			console.log('created loading task')
			const pdf = await loadingTask.promise
			console.log('pdf loaded')

			runInAction(() => (localStore.pdfPages.length = pdf.numPages))

			const renderPage = async (
				pdfDoc: typeof pdf,
				pageNumber: number,
				cellInstances: PdfCellInstance[],
			) => {
				const page = await pdfDoc.getPage(pageNumber)

				const viewport = page.getViewport({ scale: scale })

				const canvas = document.createElement('canvas')
				const context = canvas.getContext('2d')
				if (context == null) throw new Error('context was null')

				canvas.height = viewport.height
				canvas.width = viewport.width

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				}

				const renderTask = page.render(renderContext)
				await renderTask.promise.then(() => {
					runInAction(() => {
						const page = {
							pageNumber,
							dataUrl: canvas.toDataURL('image/jpeg', 1),
							height: canvas.height,
							width: canvas.width,
							cellInstances: cellInstances.filter(
								(v) =>
									v.properties.pageNumber === pageNumber ||
									v.properties.options?.filter(
										(v) => v.pageNumber === pageNumber,
									),
							),
						}

						localStore.pdfPages[pageNumber - 1] = page

						return page
					})
				})
			}

			const promiseArray: Promise<void>[] = []
			for (let i = 1; i <= pdf.numPages; i++)
				promiseArray.push(renderPage(pdf, i, cellInstances))
			await Promise.all(promiseArray)
			console.log('all pages rendered')
		})
	}

	/* we need to make sure all fields are loaded before loading the form host
		because otherwise if we are setting values on form load, we will be trying
		to set values in elements that don't exist yet*/
	const onFieldsLoaded = async () => {
		loadedPagesRef.current++
		if (loadedPagesRef.current !== localStore.pdfPages.length) return

		resolvePagesLoaded()
	}

	return (
		<div className={styles.document} ref={hostRef} id={`form-${props.formId}`}>
			{localStore.pdfLoadState !== PdfLoadState.Loaded && <FullscreenSpinner />}
			{localStore.pdfLoadState === PdfLoadState.Loaded &&
				localStore.pdfPages.map((page) => (
					<PdfPage
						scale={scale}
						key={page.pageNumber}
						pageNumber={page.pageNumber}
						dataUrl={page.dataUrl}
						cellInstances={page.cellInstances}
						height={page.height}
						width={page.width}
						containerRef={props.containerRef}
						anonymous={props.anonymous}
						eventBus={eventBus}
						onSelectSignature={handleSelectSignature}
						onFieldsLoaded={onFieldsLoaded}
					/>
				))}
		</div>
	)
})

type PdfPageProps = {
	pageNumber: number
	dataUrl: string
	cellInstances: PdfCellInstance[]
	height: number
	width: number
	containerRef: RefObject<HTMLDivElement>
	anonymous: boolean
	scale: number
	eventBus: EventBus
	onSelectSignature: (cellInstanceId: string, signature: Signature) => void
	onFieldsLoaded: () => void
}

const PdfPage = (props: PdfPageProps) => {
	const styles = useStyles()

	const containerDivRef = React.createRef<HTMLDivElement>()
	const fieldDivRef = React.createRef<HTMLDivElement>()
	const imgRef = React.createRef<HTMLImageElement>()
	const ratioRef = React.useRef<number>(1)

	const loadedFieldsCountRef = useRef(0)

	const [scale, setScale] = useState(1)

	useEffect(() => {
		const intervals = {
			refreshInterval: 0,
			sizeInterval: 0,
		}

		const updateInterval = 100

		if (fieldDivRef.current === null || containerDivRef.current === null)
			throw new Error('div does not exist')

		if (imgRef.current === null) throw new Error('image does not exist')

		intervals.sizeInterval = startResizeLoop(
			containerDivRef.current,
			fieldDivRef.current,
			props.containerRef,
			updateInterval,
			ratioRef,
			props.height,
			props.width,
			props.scale,
		)

		return () => {
			window.clearInterval(intervals.refreshInterval)
			window.clearInterval(intervals.sizeInterval)
		}
	}, [props.containerRef, props.height, props.width])

	// this is here in case we aren't going to receive any events from the child cells
	useEffect(() => {
		if (props.cellInstances.length === 0) props.onFieldsLoaded()
	})

	const onFieldLoad = action(() => {
		loadedFieldsCountRef.current++

		if (loadedFieldsCountRef.current === props.cellInstances.length)
			props.onFieldsLoaded()
	})

	return (
		<div className={styles.pageContainer}>
			<div className={styles.page}>
				<img ref={imgRef} src={props.dataUrl} className={styles.image} />
				<div ref={containerDivRef} className={styles.containerDiv}>
					<div
						ref={fieldDivRef}
						style={{
							position: 'relative',
							height: 0,
							width: props.width,
						}}
					>
						{props.cellInstances.map((instance) => {
							return instance.elementTag === 'Signature' ? (
								<SignatureField
									key={instance.id}
									instance={instance}
									anonymous={props.anonymous}
									onSelectSignature={props.onSelectSignature}
									onFieldLoad={onFieldLoad}
								/>
							) : (
								<EditableField
									key={instance.id}
									scale={scale}
									instance={instance}
									pageNumber={props.pageNumber}
									eventBus={props.eventBus}
									onFieldLoad={onFieldLoad}
								/>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

type EditableFieldProps = {
	instance: PdfCellInstance
	pageNumber: number
	scale: number
	eventBus: EventBus
	onFieldLoad: () => void
}

const EditableField = ({
	pageNumber,
	instance,
	scale,
	eventBus,
	onFieldLoad,
}: EditableFieldProps) => {
	const styles = fieldStyles()

	if (
		instance.properties === undefined ||
		instance.properties.coordinates === undefined
	)
		throw new Error('could not get editable field coordinates')

	const fontSize = instance.properties.coordinates.height - 4

	useEffect(() => {
		onFieldLoad()
	}, [])

	const getInputStyle = (coordinates: DefinitionCoordinates): BoxProps => {
		return {
			position: 'absolute',
			fontSize: fontSize > 20 ? 20 : fontSize,
			top: coordinates.top * (1 / scale),
			left: coordinates.left * (1 / scale),
			height: coordinates.height,
			width: coordinates.width,
		}
	}

	if (instance.elementTag === 'Text') {
		const mask = instance.properties.mask
		const inputRef = createRef<HTMLInputElement>()
		const textAreaRef = createRef<HTMLTextAreaElement>()

		useEffect(() => {
			if (mask === undefined || mask === '' || inputRef.current === null) return

			const inputMask = new Inputmask({
				mask: mask,
			})

			inputMask.mask(inputRef.current)
		}, [mask])

		useEffect(() => {
			if (textAreaRef.current === null) return
			const textArea = textAreaRef.current

			// for some reason textArea.style doesn't pick up that we set a font size in makestyles
			textArea.style.fontSize = '14px'

			// resize text area text to fit its container
			changeEventConsumer(
				eventBus,
				(_eventId, _props, originalSource, currentSource) => {
					return Promise.resolve(
						originalSource.instanceId === currentSource.instanceId,
					)
				},
				() => {
					const ratio = textArea.clientHeight / textArea.scrollHeight

					if (textArea.clientHeight < textArea.scrollHeight)
						textArea.style.fontSize = px(
							parseInt(textArea.style.fontSize) - ratio,
						) as string
					else {
						if (parseInt(textArea.style.fontSize) >= 14)
							return Promise.resolve()
						textArea.style.fontSize = px(
							parseInt(textArea.style.fontSize) + ratio,
						) as string
					}
					return Promise.resolve()
				},
			)
		}, [])

		return fontSize > 20 ? (
			<Box {...getInputStyle(instance.properties.coordinates)}>
				<textarea
					defaultValue={instance.value as string}
					id={instance.id}
					className={clsx(styles.inputField, styles.textArea)}
					autoComplete="off"
					ref={textAreaRef}
				/>
			</Box>
		) : (
			<Box {...getInputStyle(instance.properties.coordinates)}>
				<input
					type="text"
					id={instance.id}
					className={styles.inputField}
					autoComplete="off"
					ref={inputRef}
				/>
				<Box className={styles.iconBox}>
					<TextFields className={styles.icon} color="action" />
				</Box>
			</Box>
		)
	}

	if (instance.elementTag === 'DateTime') {
		const userAgentString = navigator.userAgent

		return (
			<Box {...getInputStyle(instance.properties.coordinates)}>
				<input
					type="date"
					id={instance.id}
					className={styles.inputField}
					autoComplete="off"
					required
				/>
				{userAgentString.indexOf('Firefox') > -1 ? (
					<Box className={styles.iconBox}>
						<CalendarMonth className={styles.icon} color="action" />
					</Box>
				) : (
					<></>
				)}
			</Box>
		)
	}

	if (instance.elementTag === 'Number') {
		return (
			<Box {...getInputStyle(instance.properties.coordinates)}>
				<input
					type="number"
					id={instance.id}
					className={styles.numberInput}
					autoComplete="off"
				/>
				<Box className={styles.iconBox}>
					<Numbers className={styles.icon} color="action" />
				</Box>
			</Box>
		)
	}

	if (instance.elementTag === 'Checkbox')
		return (
			<input
				defaultChecked={instance.value as boolean}
				type="checkbox"
				id={instance.id}
				style={
					getInputStyle(instance.properties.coordinates) as React.CSSProperties
				}
			/>
		)

	if (instance.elementTag === 'Radio') {
		if (instance.properties.options === undefined)
			throw new Error('no options found for radio')

		return (
			<>
				{instance.properties.options
					.filter((v) => v.pageNumber === pageNumber)
					.map((option) => (
						<input
							name={instance.id}
							key={option.value}
							type="radio"
							id={`${instance.id}-${option.value}`}
							style={getInputStyle(option.coordinates) as React.CSSProperties}
							value={option.value}
						/>
					))}
			</>
		)
	}

	return <></>
}

const fieldStyles = makeStyles((theme: Theme) => ({
	inputField: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		borderWidth: 1,
		backgroundColor: 'bisque',
	},

	numberInput: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		borderWidth: 1,
		backgroundColor: 'bisque',
		'-moz-appearance': 'textfield',
	},

	iconBox: {
		position: 'absolute',
		padding: 0.125,
		right: 0,
		height: '100%',
	},

	icon: {
		width: '100%',
		height: '100%',
		opacity: '50%',
	},

	textArea: {
		fontSize: px(14),
		resize: 'none',
		overflow: 'hidden',
	},
}))

type SignatureFieldProps = {
	instance: PdfCellInstance
	anonymous: boolean
	onSelectSignature: (cellInstanceId: string, signature: Signature) => void
	onFieldLoad: () => void
}

const SignatureField = ({
	instance,
	anonymous,
	onSelectSignature,
	onFieldLoad,
}: SignatureFieldProps) => {
	const signatureValue = instance.value as SignatureValue

	const [signatureImage, setSignatureImage] = useState(
		signatureValue.image ?? '',
	)

	useEffect(() => {
		onFieldLoad()
	}, [])

	const coordinates = instance.properties.coordinates

	const signatureStyle: React.CSSProperties = {
		position: 'absolute',
		borderWidth: 1,
		backgroundColor: 'white',
		top: coordinates.top,
		left: coordinates.left,
		height: coordinates.height,
		width: coordinates.width,
	}

	// meaning the signature is burned into the form
	const existingSignature =
		instance.value !== undefined &&
		instance.value !== '' &&
		signatureValue.ipAddress !== undefined

	// don't display anything if the image is already displayed on the form, or if it should be hidden
	if (existingSignature || instance.id === 'FIM_HIDDEN_SIGNATURE_FIELD')
		return <></>

	return (
		<div id={instance.id} style={signatureStyle}>
			<SignatureHost
				anonymous={anonymous}
				onSelectSignature={(signature) => {
					setSignatureImage(signature.signatureData)
					onSelectSignature(instance.id, signature)
				}}
				selectedSignature={signatureImage}
			/>
		</div>
	)
}

const startResizeLoop = (
	containerDiv: HTMLDivElement,
	innerDiv: HTMLDivElement,
	formContainerDivRef: RefObject<HTMLDivElement>,
	interval: number,
	currentRatio: MutableRefObject<number>,
	expectedHeight: number,
	expectedWidth: number,
	scale: number,
) => {
	const resizeFn = () => {
		const formContainerDiv = formContainerDivRef.current

		if (formContainerDiv === null) return

		const divClientWidth = innerDiv.clientWidth
		const formContainerClientWidth = formContainerDiv.clientWidth

		if (divClientWidth === 0)
			// avoid divide by zero
			return

		const ratio = formContainerClientWidth / divClientWidth

		if (ratio === currentRatio.current) return

		containerDiv.style.height = `${expectedHeight * ratio}px`
		containerDiv.style.width = `${expectedWidth * ratio}px`

		currentRatio.current = ratio

		if (innerDiv.style.transform !== `scale(${ratio * scale})`) {
			innerDiv.style.transform = `scale(${ratio * scale})`
			innerDiv.style.transformOrigin = 'top left'
		}
	}

	resizeFn()
	const intervalId = window.setInterval(() => {
		resizeFn()
	}, interval)

	return intervalId
}

const useStyles = makeStyles((theme: Theme) => ({
	document: {
		display: 'flex',
		flexDirection: 'column',
		width: percent(100),
	},

	pageContainer: {
		margin: theme.spacing(2, 0),
	},

	page: {
		width: percent(100),
		position: 'relative',
	},

	containerDiv: {
		position: 'relative',
	},

	image: {
		position: 'absolute',
		width: percent(100),
		objectFit: 'contain',
		top: 0,
		left: 0,
	},
}))
