import { Paper, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { percent, px } from 'csx'
import html2canvas from 'html2canvas'
import { action, computed, keys, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useLocalObservable } from 'mobx-react-lite'
import React, {
	ReactNode,
	RefObject,
	createRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react'
import { useHistory } from 'react-router'
import { keyframes } from 'typestyle'
import { NIL } from 'uuid'
import {
	BaseFormPackageInstanceModel,
	DetailForm,
	FieldMapping,
	FormPackageAdvancedEvent,
	FormPackageInfo,
	FormPackageInstance,
	FormWidthSetting,
} from '../../api/DTOtemp'
import { OrganizationPropertyListItem } from '../../api/clients/identity/OrganizationPropertiesClient'
import {
	ForwardedFormPackageWorkItemState,
	WorkItemResponse,
} from '../../api/clients/workItems/DTOs'
import { FullscreenSpinner } from '../../components/feedback/circular'
import { ToastWrapper } from '../../components/toast/ToastWrapper'
import {
	Signature,
	SignatureContextProvider,
} from '../../controls/specifics/signatures/SignatureContext'
import { EventBus } from '../../modules/FormBuilderCore/eventBus/EventBus'
import { EventBusEventConsumer } from '../../modules/FormBuilderCore/eventBus/EventTypes'
import { beforeSubmitFormEventConsumer } from '../../modules/FormBuilderInterop/EventBus/BuiltInEvents/BeforeSubmitFormEvent'
import { changeEventConsumer } from '../../modules/FormBuilderInterop/EventBus/BuiltInEvents/ChangeEvent'
import { focusEventConsumer } from '../../modules/FormBuilderInterop/EventBus/BuiltInEvents/FocusEvent'
import {
	formLoadEventConsumer,
	formLoadEventEmitter,
} from '../../modules/FormBuilderInterop/EventBus/BuiltInEvents/FormLoadEvent'
import { getGlobalEventActionRegistry } from '../../modules/FormHost/EventActions/EventActionRegistry'
import { FormHost } from '../../modules/FormHost/FormHost/FormHost'
import {
	ProxyFormHost,
	proxyFormHosts,
} from '../../modules/FormHost/FormHost/FormHostProxy'
import { IFormHost } from '../../modules/FormHost/FormHost/IFormHost'
import { IUnifiedFormHost } from '../../modules/FormHost/FormHost/IUnifiedFormHost'
import { CellType } from '../../modules/FormHost/Types/CellType'
import {
	addValidationError,
	removeValidationError,
} from '../../modules/FormHost/Utilities/FieldValidationUtilities'
import { ValidationEventRegistration } from '../../modules/FormHost/Validation/Types'
import { ValidationEvents } from '../../modules/FormHost/Validation/ValidationEvents'
import { FormBuilder } from '../../modules/FormIntegrations/formBuilderFormHost/FormBuilderFormViewer'
import { HtmlForm } from '../../modules/FormIntegrations/htmlFormHost/HtmlFormViewer'
import { PdfForm } from '../../modules/FormIntegrations/pdfFormHost/PdfFormViewer'
import { FieldEventType, FormEventType } from '../../modules/VisualScripting'
import { useModals } from '../../services/notifications/ModalService'
import { toastService } from '../../services/notifications/ToastService'
import { DetailFormWithData } from '../../services/offline/FormPackageHandling'
import { TrackedResource } from './Attachments/AttachFilesDialog'
import { AttachmentsApiContext } from './Attachments/AttachmentsApi'
import { PackageApi } from './PackageApi'
import { PackageFormSwapper } from './PackageFormSwapper'
import { PackageHostFooter } from './PackageHostFooter'
import { FormPackageSubmitResult } from './PackageHostFooter.Submit'
import { usePackageHostPageContext } from './PackageHostPageContext'
import { PackageSubmitBackdrop } from './PackageSubmitBackdrop'
import { RuntimePackageContextProvider } from './RuntimePackageContext'
;(window as unknown as Record<string, unknown>).html2canvas = html2canvas

/*
 * There's an interesting caveat that has to happen here. So - because we
 * require that all of our forms remain in memory whenever they're here, we
 * have to somehow ensure that they don't get unloaded by the DOM.
 * Unfortunately that's pretty antithetical to the way that React likes people
 * to develop software, they want us to forward props and cause re-renders.
 *
 * Somehow, there needs to be a way for us to set the visibility state of a form
 * via ref and callback, so we can apply the reference to the div
 */

type FormReference = {
	ref: RefObject<HTMLDivElement>
	form: DetailForm
	formManager: IUnifiedFormHost | undefined
}

export type PackageHostProps = {
	packageId: number
	packageApi: PackageApi
	formPackage: FormPackageInfo
	forms: DetailFormWithData[]
	resources: TrackedResource[]
	savedInstanceId?: string
	anonymous: boolean
	workItem?: WorkItemResponse<ForwardedFormPackageWorkItemState>
	isPackageFromServer: boolean
	onSaveInstance: (
		baseFormPackageInstance: BaseFormPackageInstanceModel,
		formInstances: DetailFormWithData[],
		isPackageFromServer: boolean,
		existingInstanceId?: number,
	) => Promise<number>
}

const createReferenceCollection = (forms: DetailForm[]): FormReference[] => {
	const localReferenceCollection = []
	// ensure that the forms are ordered by ordinal position
	forms = observable(
		forms.slice().sort((a, b) => a.ordinalPosition - b.ordinalPosition),
	)
	for (const form of forms) {
		localReferenceCollection.push({
			ref: createRef<HTMLDivElement>(),
			form: form,
			formManager: undefined,
		})
	}
	return localReferenceCollection
}

type RuntimeAdvancedEvent = FormPackageAdvancedEvent & {
	fn?: (advancedEventApi: AdvancedEventApi) => void
}

type AdvancedEventApi = {
	formProxy: Record<string, ProxyFormHost> // todo rename to just formProxy?
	userPropertiesProxy: Record<string, string>
	organizationPropertiesProxy: Record<
		string,
		string | OrganizationPropertyListItem[]
	>
	userInfoProxy: Record<string, string>
	queryStringProxy: URLSearchParams
}

export const PackageHost = observer((props: PackageHostProps) => {
	const styles = useViewerStyles()
	const theme = useTheme()
	const history = useHistory()

	const context = usePackageHostPageContext()

	const currentElement = useRef<number>(0)
	const appBarRef = React.createRef<HTMLDivElement>()

	const animationStyles = useAnimationStyles()
	const modalService = useModals()

	const localStore = useLocalObservable(() => ({
		formHosts: [] as FormHost[],
		formPackageInstance: undefined as FormPackageInstance | undefined,
		savedSignatures: [] as Signature[],
		isSubmitting: false,

		get formProxy(): Record<string, ProxyFormHost> {
			localStore.formHosts.length // <= stupid
			const proxy = proxyFormHosts(localStore.formHosts)
			return proxy
		},

		// any canceled submission workflow task ids so we don't re-fetch them
		canceledWorkflowTaskIds: [] as number[],

		displaySubmitAnimation: false,
		submitAnimationText: '',

		formPackageResult: undefined as FormPackageSubmitResult | undefined,
	}))

	useEffect(() => {
		async function getIpAddress(): Promise<string> {
			if (!navigator.onLine) return 'offline'

			try {
				const response = await fetch('https://api.ipify.org?format=json')

				const json = await response.json()

				return json.ip
			} catch (error) {
				console.error('could not obtain ip address', error)
				return 'no ip address found'
			}
		}

		getIpAddress()
			.then((address) => (context.ipAddress = address))
			.catch(() => (context.ipAddress = 'unknown'))
	}, [context])

	useEffect(() => {
		async function getLocation(): Promise<GeolocationPosition> {
			const promise = new Promise<GeolocationPosition>((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve(position)
					},
					(positionError) => {
						console.warn(
							'could not obtain location, reason: ' + positionError.message,
						)
						reject(positionError)
					},
					{
						enableHighAccuracy: false,
					},
				)
			})

			return promise
		}

		getLocation()
			.then((location) => (context.location = location))
			.catch(() => (context.location = undefined))
	}, [context])

	const referenceCollectionRef = useRef<FormReference[]>(
		createReferenceCollection(props.forms),
	)
	const referenceCollection = referenceCollectionRef.current

	useEffect(() => {
		updateTabIndex(0, true)
	}, [props.forms])

	useEffect(() => {
		/**
		 * since the app bar is overlayed on the form viewer w/ absolute positioning, we need to move the
		 * form viewer down a bit so the app bar doesn't cover it. This adds margin using the height of the app bar
		 * so that the app bar does not look like it's on top of the form.
		 */

		if (appBarRef.current === null)
			throw new Error(
				'could not find ref for app bar to fix margin of form viewer',
			)

		// add padding to formViewerRoot depending on height of app bar
		const formViewerRoot = document.getElementById('form-viewer-root')

		if (formViewerRoot === null)
			throw new Error(
				'could not find element with id `form-viewer-root` to fix margin of',
			)

		formViewerRoot.style.marginTop = `${appBarRef.current.clientHeight}px`
	}, [appBarRef.current?.clientHeight])

	const onFormLoaded = useCallback(
		action((formHost: FormHost) => {
			const formReferenceIndex = referenceCollection.findIndex(
				(v) => v.form.id === formHost.formId,
			)
			const formReference = referenceCollection[formReferenceIndex]

			if (formReferenceIndex < 0)
				throw new Error(
					`no form exists in reference collection with id ${formHost.formId}`,
				)

			if (localStore.formHosts.find((v) => v.formId === formHost.formId))
				throw new Error('duplicate form host added for form ' + formHost.formId)

			// the reference collection is guaranteed to be ordered by form ordinal position

			const intermediateFormHosts = localStore.formHosts.slice()
			intermediateFormHosts.push(formHost)

			localStore.formHosts = intermediateFormHosts.sort(
				(a, b) =>
					formReferenceIndex -
					referenceCollection.findIndex((b) => b.form.ordinalPosition),
			)

			formReference.formManager = formHost

			formLoadEventEmitter(
				formHost.eventBus,
				{},
				{
					formHost: formHost,
					elementTag: NIL,
					definitionId: NIL,
					instanceId: NIL,
				},
			)

			if (formHost.controlApi && formHost.controlApi.onFormLoaded)
				formHost.controlApi.onFormLoaded()
		}),
		[referenceCollection],
	)

	function updateTabIndex(index: number, firstLoad = false) {
		console.log('updating tab indexes with values: ', {
			index: index,
			firstLoad: firstLoad,
		})
		console.log('reference collection length: ', referenceCollection.length)

		if (referenceCollection.length === 0) return

		const initialIndex = currentElement.current

		if (initialIndex === index && !firstLoad) return

		function assignFormPositions() {
			for (let i = 0; i < referenceCollection.length; i++) {
				const reference = referenceCollection[i]
				const elementRef = reference.ref.current
				if (!elementRef) throw Error('reference not established to div object')

				elementRef.className = clsx(styles.animationRoot, {
					[styles.hiddenForm]: i - index !== 0,
				})

				elementRef.style.left = percent((i - index) * 100) as string
			}
		}

		if (firstLoad) {
			assignFormPositions()
			// the rest of this is animations, we don't want that on first load
			return
		}

		function delay(ms: number) {
			return new Promise((resolve) => setTimeout(resolve, ms))
		}

		const toLeft = index > initialIndex
		const animationToUse = toLeft
			? animationStyles.slideToLeftAnimation
			: animationStyles.slideToRightAnimation

		const elementRef = referenceCollection[index].ref.current
		if (!elementRef) throw Error('reference not established to div object')

		const oldElementRef = referenceCollection[initialIndex].ref.current
		if (!oldElementRef) throw Error('reference not established to div object')

		new Promise<void>((resolve) => {
			elementRef.className = clsx(
				styles.animationRoot,
				animationStyles.centerAnimation,
			)
			oldElementRef.className = clsx(styles.animationRoot, animationToUse)

			resolve()
		})
			.then(async () => await delay(theme.transitions.duration.enteringScreen))
			.then(assignFormPositions)

		currentElement.current = index

		document.getElementById('form-viewer-root')?.scrollTo(0, 0)
	}

	const eventBus = useMemo(() => {
		return new EventBus()
	}, [props.packageId, props.formPackage.versionNumber])

	for (const action of props.formPackage.configuration.eventActions ?? []) {
		const definition =
			getGlobalEventActionRegistry().actionCollection[action.definitionId]

		const consumer: EventBusEventConsumer = {
			eventId: action.eventId,
			shouldActivateAsync: (
				eventId,
				eventProps,
				originalEventSource,
				currentEventSource,
			) => {
				if (action.eventId !== eventId) return Promise.resolve(false)

				return definition.shouldActivateAsync(
					eventId,
					eventProps,
					action.value,
					originalEventSource,
					currentEventSource,
					props.packageApi,
				)
			},
			actionAsync: (
				eventId,
				eventProps,
				originalEventSource,
				currentEventSource,
			) => {
				definition.actionAsync(
					eventId,
					eventProps,
					action.value,
					originalEventSource,
					currentEventSource,
					props.packageApi,
				)
				return Promise.resolve()
			},
		}

		eventBus.registerConsumer(consumer)
	}

	/* updates the link value itself along with the values of instances that are linked*/
	function updateLinkedField(link: FieldMapping, value: unknown) {
		if (props.formPackage === undefined)
			throw new Error('form package is undefined')

		// get all the instances in this package that are linked in this field mapping
		for (const formHost of localStore.formHosts) {
			const linkedInstances = formHost.cellInstances.filter(
				(v) =>
					v.type === 'value' &&
					link.linkedFields
						.filter((v) => v.formId === formHost.formId)
						.map((v) => v.cellDefinitionId)
						.includes(v.definitionId),
			)

			// set each instance's value to the new value
			for (const instance of linkedInstances) {
				if (instance.type === 'value' && instance.value !== value)
					runInAction(() => {
						instance.value = value
					})
			}
		}

		link.value = value
	}

	const validateField = action(
		(
			registration: ValidationEventRegistration,
			formHost: IFormHost | IUnifiedFormHost,
			definitionId: string,
			instanceId: string,
		) => {
			const event = ValidationEvents.find(
				(v) => v.id === registration.eventTypeId,
			)
			if (event === undefined)
				throw new Error(
					`could not find validation event with id ${registration.eventTypeId}`,
				)

			const runtimeParams = registration.parameters

			const unifiedFormHost = localStore.formHosts.find(
				(v) => v.formId === formHost.formId,
			)
			if (unifiedFormHost === undefined)
				throw new Error(
					`could not find unified form host from source with form id ${formHost.formId}`,
				)

			const sourceInstance = unifiedFormHost.cellInstances.find(
				(v) => v.id === instanceId,
			)
			if (sourceInstance === undefined)
				throw new Error(`could not find instance with id ${instanceId}`)

			if (sourceInstance.type !== 'value')
				throw new Error(`cannot validate non-value instance ${instanceId}`)

			runtimeParams['value'] = sourceInstance.value

			const errorText = event.validate(formHost, definitionId, runtimeParams)

			const validationErrors = context.validationErrors

			if (errorText === '')
				removeValidationError(
					validationErrors,
					sourceInstance.id,
					registration.registrationId,
				)
			else
				addValidationError(
					validationErrors,
					props.forms.find((v) => v.id === registration.formId)?.name ??
						'Unnamed Form',
					sourceInstance.name,
					sourceInstance.id,
					registration.registrationId,
					errorText,
				)

			if (formHost.controlApi && formHost.controlApi.onValidationStateChange)
				formHost.controlApi.onValidationStateChange(instanceId, errorText)

			return errorText !== ''
		},
	)

	useEffect(() => {
		/* pick up any changes to cell instance values so we can update their link values.
		 any linked cell instances will have their values changed when the link values change.
	*/
		const consumer = changeEventConsumer(
			eventBus,
			(_eventId, _props, source, currentSource) => {
				return Promise.resolve(
					source.definitionId === currentSource.definitionId,
				)
			},
			(_eventId, changeEventProps, source) => {
				const links = props.formPackage.configuration.fieldMappings

				const linkToUpdate = links?.find((v) =>
					v.linkedFields
						.filter((v) => v.formId === source.formHost.formId)
						.map((v) => v.cellDefinitionId)
						.includes(source.definitionId),
				)

				if (linkToUpdate === undefined) return Promise.resolve()

				updateLinkedField(linkToUpdate, changeEventProps.newValue)

				return Promise.resolve()
			},
		)
		return () => {
			eventBus.removeConsumer(consumer)
		}
	}, [eventBus])

	useEffect(() => {
		const consumer = changeEventConsumer(
			eventBus,
			(_eventId, _props, source, currentSource) => {
				if (source.definitionId !== currentSource.definitionId)
					return Promise.resolve(false)

				if (context.validationErrors[source.instanceId] === undefined)
					return Promise.resolve(false)

				return Promise.resolve(
					context.validationErrors[source.instanceId] !== undefined,
				)
			},
			(_eventId, _props, source) => {
				const registrations =
					props.formPackage.configuration.validationEventRegistrations.filter(
						(v) => v.definitionId === source.definitionId,
					)

				for (const registration of registrations)
					validateField(
						registration,
						source.formHost,
						source.definitionId,
						source.instanceId,
					)

				return Promise.resolve()
			},
		)
		return () => {
			eventBus.removeConsumer(consumer)
		}
	}, [eventBus, props.formPackage.configuration.validationEventRegistrations])

	useEffect(() => {
		// Validate on blur
		const consumerId = focusEventConsumer(
			eventBus,
			(_eventId, focusProps, source, currentSource) => {
				if (
					source.definitionId !== currentSource.definitionId ||
					focusProps.focusType !== 'FocusLost'
				)
					return Promise.resolve(false)

				// if there's a validation registration for this cell definition
				return Promise.resolve(
					props.formPackage.configuration.validationEventRegistrations
						.map((v) => v.definitionId)
						.includes(source.definitionId),
				)
			},
			(_eventId, _props, source) => {
				const registrations =
					props.formPackage.configuration.validationEventRegistrations.filter(
						(v) => v.definitionId === source.definitionId,
					)

				if (registrations.length === 0) return Promise.resolve()

				for (const registration of registrations)
					validateField(
						registration,
						source.formHost,
						source.definitionId,
						source.instanceId,
					)

				return Promise.resolve()
			},
		)
		return () => {
			eventBus.removeConsumer(consumerId)
		}
	}, [eventBus])

	// Validate fields before submit
	beforeSubmitFormEventConsumer(
		eventBus,
		(_eventId, _props) => {
			return Promise.resolve(
				props.formPackage.configuration.validationEventRegistrations.length !==
					0,
			)
		},
		() => {
			// sort by form id so all errors for one form are grouped together
			const sortedRegistrations =
				props.formPackage.configuration.validationEventRegistrations.sort(
					(a, b) => a.formId - b.formId,
				)

			for (const registration of sortedRegistrations) {
				const formHost = localStore.formHosts.find(
					(v) => v.formId === registration.formId,
				)
				if (formHost === undefined)
					throw new Error(
						`could not find form host for form ${registration.formId}`,
					)

				const instance = formHost.cellInstances.find(
					(v) =>
						v.type === CellType.Value &&
						v.definitionId === registration.definitionId,
				)
				if (instance === undefined)
					throw new Error(
						`could not find instance with definition id ${registration.definitionId}`,
					)

				validateField(
					registration,
					formHost,
					registration.definitionId,
					instance.id,
				)
			}

			return Promise.resolve()
		},
	)

	const fireAdvancedEvent = (advancedEvent: FormPackageAdvancedEvent) => {
		try {
			const runtimeEvent = advancedEvent as RuntimeAdvancedEvent

			if (runtimeEvent.fn === undefined)
				runtimeEvent.fn = new Function(
					'advancedEventApi',
					`(() => { 
						${advancedEvent.eventScript}
						})()
					`,
				) as (advancedEventApi: AdvancedEventApi) => void

			console.log(runtimeEvent.eventScript)

			const advancedEventApi: AdvancedEventApi = {
				userPropertiesProxy: props.packageApi.userPropertiesProxy,
				formProxy: localStore.formProxy,
				organizationPropertiesProxy:
					props.packageApi.organizationPropertiesProxy,
				userInfoProxy: props.packageApi.userInfoProxy,
				queryStringProxy: props.packageApi.queryStringProxy,
			}

			runInAction(() => {
				runtimeEvent.fn!.call(null, advancedEventApi)
			})
		} catch (e) {
			console.log(e)
			console.log('failed to evaluate script:', advancedEvent.eventScript)
		}
	}

	// Watch all form/field events and fire based on advanced events configured for this package!
	useEffect(() => {
		const advancedEvents = props.formPackage.configuration.advancedEvents
		if (advancedEvents.length === 0) return

		const formLoadEvents = advancedEvents.filter(
			(v) =>
				v.eventProgram.formsInfo?.type === 'FormEvent' &&
				v.eventProgram.formsInfo.event === FormEventType.OnLoad,
		)

		const formSubmitEvents = advancedEvents.filter(
			(v) =>
				v.eventProgram.formsInfo?.type === 'FormEvent' &&
				v.eventProgram.formsInfo.event === FormEventType.OnBeforeSubmit,
		)

		const fieldChangeEvents = advancedEvents.filter(
			(v) =>
				v.eventProgram.formsInfo?.type === 'FieldEvent' &&
				v.eventProgram.formsInfo.event === FieldEventType.OnChange,
		)

		const fieldBlurEvents = advancedEvents.filter(
			(v) =>
				v.eventProgram.formsInfo?.type === 'FieldEvent' &&
				v.eventProgram.formsInfo.event === FieldEventType.OnBlur,
		)

		const eventConsumers: string[] = []

		// form load
		eventConsumers.push(
			formLoadEventConsumer(
				eventBus,
				(_eventId, _props, source, currentSource) => {
					if (source.definitionId !== currentSource.definitionId)
						return Promise.resolve(false)

					return Promise.resolve(
						formLoadEvents.filter(
							(v) =>
								v.eventProgram.formsInfo?.type === 'FormEvent' &&
								v.eventProgram.formsInfo.formId === source.formHost.formId,
						).length !== 0,
					)
				},
				(_eventId, _props, source) => {
					for (const formLoadEvent of formLoadEvents.filter(
						(v) =>
							v.eventProgram.formsInfo?.type === 'FormEvent' &&
							v.eventProgram.formsInfo.formId === source.formHost.formId,
					)) {
						fireAdvancedEvent(formLoadEvent)
					}

					return Promise.resolve()
				},
			),
		)

		// before submit form
		eventConsumers.push(
			beforeSubmitFormEventConsumer(
				eventBus,
				(_eventId, _props, source, currentSource) => {
					if (source.definitionId !== currentSource.definitionId)
						return Promise.resolve(false)

					return Promise.resolve(
						formSubmitEvents.filter(
							(v) =>
								v.eventProgram.formsInfo?.type === 'FormEvent' &&
								v.eventProgram.formsInfo.formId === source.formHost.formId,
						).length !== 0,
					)
				},
				(_eventId, _props, source) => {
					for (const formSubmitEvent of formSubmitEvents.filter(
						(v) =>
							v.eventProgram.formsInfo?.type === 'FormEvent' &&
							v.eventProgram.formsInfo.formId === source.formHost.formId,
					)) {
						fireAdvancedEvent(formSubmitEvent)
					}

					return Promise.resolve()
				},
			),
		)

		// field change event
		eventConsumers.push(
			changeEventConsumer(
				eventBus,
				(_eventId, _props, source, currentSource) => {
					if (source.definitionId !== currentSource.definitionId)
						return Promise.resolve(false)

					return Promise.resolve(
						fieldChangeEvents.filter(
							(v) =>
								v.eventProgram.formsInfo?.type === 'FieldEvent' &&
								v.eventProgram.formsInfo.formId === source.formHost.formId &&
								v.eventProgram.formsInfo.definitionId === source.definitionId,
						).length !== 0,
					)
				},
				(_eventId, _props, source) => {
					for (const fieldChangeEvent of fieldChangeEvents.filter(
						(v) =>
							v.eventProgram.formsInfo?.type === 'FieldEvent' &&
							v.eventProgram.formsInfo.formId === source.formHost.formId &&
							v.eventProgram.formsInfo.definitionId === source.definitionId,
					)) {
						fireAdvancedEvent(fieldChangeEvent)
					}

					return Promise.resolve()
				},
			),
		)

		// field blur event
		eventConsumers.push(
			focusEventConsumer(
				eventBus,
				(_eventId, props, source, currentSource) => {
					if (
						source.definitionId !== currentSource.definitionId ||
						props.focusType !== 'FocusLost'
					)
						return Promise.resolve(false)

					return Promise.resolve(
						fieldBlurEvents.filter(
							(v) =>
								v.eventProgram.formsInfo?.type === 'FieldEvent' &&
								v.eventProgram.formsInfo.formId === source.formHost.formId &&
								v.eventProgram.formsInfo.definitionId === source.definitionId,
						).length !== 0,
					)
				},
				(_eventId, _props, source) => {
					for (const fieldBlurEvent of fieldBlurEvents.filter(
						(v) =>
							v.eventProgram.formsInfo?.type === 'FieldEvent' &&
							v.eventProgram.formsInfo.formId === source.formHost.formId &&
							v.eventProgram.formsInfo.definitionId === source.definitionId,
					)) {
						fireAdvancedEvent(fieldBlurEvent)
					}

					return Promise.resolve()
				},
			),
		)

		return () => {
			for (const consumerId of eventConsumers)
				eventBus.removeConsumer(consumerId)
		}
	}, [eventBus, props.formPackage.configuration.advancedEvents])

	const isValid = (index: number) => {
		const currentForm = referenceCollection[index].formManager

		// check if the current form has any errors in the instance error record
		const hasValidationErrorsComputed = computed(
			() =>
				keys(context.validationErrors).filter((v) =>
					currentForm?.cellInstances.map((v) => v.id).includes(v.toString()),
				).length > 0,
		)

		// validates all fields if there aren't existing errors
		if (!hasValidationErrorsComputed.get()) {
			const registrations =
				props.formPackage.configuration.validationEventRegistrations.filter(
					(v) => v.formId === currentForm?.formId,
				)

			for (const registration of registrations) {
				if (currentForm === undefined)
					throw new Error(
						`could not find form host for form ${registration.formId}`,
					)
				const instance = currentForm.cellInstances.find(
					(v) =>
						v.type === CellType.Value &&
						v.definitionId === registration.definitionId,
				)
				if (instance === undefined)
					throw new Error(
						`could not find instance with definition id ${registration.definitionId}`,
					)

				validateField(
					registration,
					currentForm,
					registration.definitionId,
					instance.id,
				)
			}
		}
		return !hasValidationErrorsComputed.get()
	}

	const handleSubmitCancelled = action(() => {
		localStore.isSubmitting = false
		localStore.formPackageResult = FormPackageSubmitResult.Canceled
		localStore.submitAnimationText = 'Canceling submission...'

		toastService.displayToast({
			severity: 'warning',
			message: 'Form package submission canceled',
			area: 'formPackage',
			delay: 7,
		})
	})

	const handleSubmitError = action((message: ReactNode) => {
		localStore.isSubmitting = false
		localStore.formPackageResult = FormPackageSubmitResult.Errored
		localStore.submitAnimationText = 'Submission failed...'

		toastService.displayToast({
			severity: 'error',
			message: message,
			area: 'formPackage',
			delay: 7,
		})
	})

	const handleSubmitFinished = action(() => {
		localStore.submitAnimationText = 'Packaging forms...'
		localStore.isSubmitting = false
		localStore.formPackageResult = FormPackageSubmitResult.Submitted
	})

	const handleSubmitAnimationEnd = useCallback(
		action(() => {
			localStore.displaySubmitAnimation = false
			localStore.submitAnimationText = ''
			if (localStore.formPackageResult === FormPackageSubmitResult.Submitted)
				history.push(`/_user/_home`)

			localStore.formPackageResult = undefined
		}),
		[localStore.formPackageResult],
	)

	console.log(
		'form package status: ',
		props.formPackage === undefined,
		props.forms === undefined,
	)

	if (props.formPackage === undefined || props.forms === undefined) {
		return <FullscreenSpinner />
	}

	console.log('using package host')

	return (
		<RuntimePackageContextProvider eventBus={eventBus}>
			<AttachmentsApiContext
				existingAttachments={props.resources}
				package={props.formPackage}
			>
				<PackageFormSwapper
					forms={props.forms}
					onSelectForm={updateTabIndex}
					ref={appBarRef}
					isCurrentFormValid={isValid}
					isWizardMode={props.formPackage.configuration.settings.wizardMode}
				/>
				<div className={styles.formViewerRoot}>
					<SignatureContextProvider signatures={localStore.savedSignatures}>
						<div id="form-viewer-root" className={styles.formViewerContent}>
							{props.forms.map((form, i) => (
								<div
									key={form.id}
									className={styles.animationRoot}
									ref={referenceCollection[i].ref}
								>
									<FormContainer
										packageId={props.packageId}
										packageVersionNumber={props.formPackage.versionNumber ?? 0}
										form={form}
										onFormLoaded={onFormLoaded}
										anonymous={props.anonymous}
									/>
								</div>
							))}
						</div>
						<div className={styles.toastDiv}>
							<ToastWrapper subscriptionArea="formPackage" />
						</div>
						<PackageHostFooter
							{...props}
							formHosts={localStore.formHosts}
							getCurrentFormReference={() =>
								referenceCollection[currentElement.current]
							}
							onSubmit={action(() => {
								localStore.submitAnimationText = 'Submitting form package...'
								localStore.formPackageResult = undefined
								localStore.isSubmitting = true
								localStore.displaySubmitAnimation = true
							})}
							onSubmitError={handleSubmitError}
							onSubmitFinished={handleSubmitFinished}
							onSubmitCanceled={handleSubmitCancelled}
						/>
					</SignatureContextProvider>
				</div>
				{localStore.displaySubmitAnimation && (
					<PackageSubmitBackdrop
						getPackageResult={() => localStore.formPackageResult}
						message={localStore.submitAnimationText}
						onAnimationEnd={handleSubmitAnimationEnd}
					/>
				)}
			</AttachmentsApiContext>
		</RuntimePackageContextProvider>
	)
})

type FormContainerProps = {
	packageId: number
	packageVersionNumber: number
	form: DetailFormWithData
	anonymous: boolean
	onFormLoaded: (formHost: FormHost) => void
}

const FormContainer = (props: FormContainerProps) => {
	const formContainerRef = createRef<HTMLDivElement>()
	const styles = useViewerStyles()

	const widthSetting = props.form.formConfiguration.widthSetting

	console.log('width setting: ', widthSetting)

	return (
		<Paper
			className={clsx({
				[styles.formContainerRoot]: true,
				[styles.formContainerPaper]: widthSetting == FormWidthSetting.Paper,
				[styles.formContainerFullWidth]:
					widthSetting == FormWidthSetting.FullWidth,
			})}
			elevation={3}
			ref={formContainerRef}
		>
			<div className={styles.formContainerHorizontal}>
				<FormLoader
					packageId={props.packageId}
					packageVersionNumber={props.packageVersionNumber}
					form={props.form}
					onFormLoaded={props.onFormLoaded}
					containerRef={formContainerRef}
					anonymous={props.anonymous}
				/>
			</div>
		</Paper>
	)
}

type FormLoaderProps = {
	packageId: number
	packageVersionNumber: number
	form: DetailFormWithData
	containerRef: RefObject<HTMLDivElement>
	anonymous: boolean
	onFormLoaded: (formHost: FormHost) => void
}

const FormLoader = (props: FormLoaderProps) => {
	console.log('loading form with content type = ', props.form.contentType)

	if (props.form.contentType === 'application/json')
		return (
			<FormBuilder
				packageId={props.packageId}
				packageVersionNumber={props.packageVersionNumber}
				formId={props.form.id}
				onFormLoaded={props.onFormLoaded}
				anonymous={props.anonymous}
				form={props.form}
			/>
		)

	if (props.form.contentType === 'text/html')
		return (
			<HtmlForm
				packageId={props.packageId}
				packageVersionNumber={props.packageVersionNumber}
				formId={props.form.id}
				formName={props.form.name}
				onFormLoaded={props.onFormLoaded}
				anonymous={props.anonymous}
				form={props.form}
			/>
		)

	if (props.form.contentType === 'application/pdf')
		return (
			<PdfForm
				packageId={props.packageId}
				packageVersionNumber={props.packageVersionNumber}
				formId={props.form.id}
				onFormLoaded={props.onFormLoaded}
				containerRef={props.containerRef}
				anonymous={props.anonymous}
				form={props.form}
			/>
		)

	return <> </>
}

const slideToCenterAnimation = keyframes({
	$debugName: 'slideToCenterAnimation',
	'100%': {
		left: '0%',
	},
})

const slideToRightAnimation = keyframes({
	$debugName: 'slideToRightAnimation',
	'100%': {
		left: '100%',
	},
})

const slideToLeftAnimation = keyframes({
	$debugName: 'slideToLeftAnimation',
	'100%': {
		left: '-100%',
	},
})

const useAnimationStyles = makeStyles((theme: Theme) => ({
	centerAnimation: {
		overflow: 'hidden',
		animationName: slideToCenterAnimation,
		animationDuration: `${theme.transitions.duration.enteringScreen}ms`,
		animationFillMode: 'forwards',
	},
	slideToRightAnimation: {
		overflow: 'hidden',
		animationName: slideToRightAnimation,
		animationDuration: `${theme.transitions.duration.leavingScreen}ms`,
		animationFillMode: 'forwards',
	},
	slideToLeftAnimation: {
		overflow: 'hidden',
		animationName: slideToLeftAnimation,
		animationDuration: `${theme.transitions.duration.leavingScreen}ms`,
		animationFillMode: 'forwards',
	},
}))

const useViewerStyles = makeStyles((theme: Theme) => ({
	toastDiv: {
		position: 'relative',
		bottom: 0,
		right: 0,
	},

	formViewerRoot: {
		width: percent(100),

		display: 'flex',
		flexDirection: 'column',

		overflow: 'hidden',
	},

	formViewerContent: {
		flex: '1 1 auto',
		overflowX: 'hidden',
		overflowY: 'auto',
		display: 'flex',

		justifyContent: 'center',

		// height: percent(100),

		// padding: theme.spacing(2, 0),

		position: 'relative',
	},

	animationRoot: {
		flex: '1',
		display: 'flex',
		justifyContent: 'center',

		width: percent(100),
		height: percent(100),

		padding: theme.spacing(2, 0),

		position: 'absolute',
	},

	hiddenForm: {
		overflow: 'hidden',
	},

	formContainerHorizontal: {
		flex: '1',
		flexDirection: 'column',
	},

	formContainerRoot: {
		flex: '1',
		display: 'flex',
		justifyContent: 'center',

		height: 'fit-content',

		padding: theme.spacing(2),
	},

	formContainerFullWidth: {
		width: '100%',
	},

	formContainerPaper: {
		// bootstrap widths
		[theme.breakpoints.up('xs')]: {
			maxWidth: percent(100),
		},

		[theme.breakpoints.up('sm')]: {
			maxWidth: px(540),
		},

		[theme.breakpoints.up('md')]: {
			maxWidth: px(720),
		},

		[theme.breakpoints.up('lg')]: {
			maxWidth: px(960),
		},

		[theme.breakpoints.up('xl')]: {
			maxWidth: px(1140),
		},
	},
}))
