import { Clear } from '@mui/icons-material'
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { action } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { toastService } from '../../../services/notifications/ToastService'
import { TypedEditor } from './PropertySchematicConfigurator'

export enum ColumnType {
	String,
	Boolean,
	Number,
}

export type OptionsType = {
	key: string
	displayValue: string
}

type ColumnConfiguration = {
	columnName: string
	columnType: ColumnType
	key: string
}

export type ColumnsAdapter<T> = {
	[K in keyof T]: {
		columnName: string
		columnType: ColumnType
	}
}

type StaticDataProviderConfiguratorProps<T> = {
	values: ColumnsAdapter<T>
} & TypedEditor<T[] | undefined>

enum ErrorType {
	EmptyValue = 'Value is required',
	NotUnique = 'Value must be unique',
}

type Error = {
	columnKey: string
	errorMessage: string
}

// This component is used to configure options for form builder components

/*
 * I feel like there could be some confusion later on with all the variable names since "value" (of type T) represents a whole value
 * (like key + displayValue together, aka a whole Option, but not key and displayValue separately).
 * So one value of type T represents a whole row, and when combined with a column key, it represents a cell in the table,
 * like Key or DisplayValue in Option. I tried to clarify some when talking about values so you can visualize the rows vs individual cells.
 * Just picture anything of type T as a whole row and anything indexed with a column key as a cell. -Caroline
 */

const StaticDataProviderPropertyConfigurator = observer(
	<T,>(props: StaticDataProviderConfiguratorProps<T>) => {
		const styles = useStyles()

		const localStore = useLocalObservable(() => ({
			dialogOpen: false,
			values: props.value,
			saveEnabled: false,
			erroredValues: {
				[ErrorType.EmptyValue]: [] as { index: number; columnKey: string }[],
				[ErrorType.NotUnique]: [] as { index: number; columnKey: string }[],
			},
		}))

		// get the columns we're expecting based on the values, with keys being the keys from the values
		const columns = Object.entries(props.values).map(
			(v) =>
				({
					key: v[0],
					...(v[1] as { columnName: string; columnType: ColumnType }),
				} as ColumnConfiguration),
		)

		// this adds a new element to the T[]
		const handleCreateRow = action(() => {
			const newValue = createRow(columns)
			if (localStore.values === undefined) localStore.values = []

			localStore.values = [...localStore.values, newValue as T]
		})

		const handleValueChange = action((oldValue: T, newValue: T) => {
			if (localStore.values === undefined)
				throw new Error('cannot update value because values list is undefined')

			localStore.saveEnabled = true

			const index = localStore.values.indexOf(oldValue)
			if (index < 0) throw new Error('cannot find value to update')

			localStore.values[index] = newValue
		})

		const handleDeleteValue = action((value: T) => {
			if (localStore.values === undefined)
				throw new Error('cannot delete a value from undefined values list')
			localStore.saveEnabled = true

			const index = localStore.values.indexOf(value)
			if (index < 0) return

			localStore.values.splice(index, 1)
		})

		// validate values and if there are no errors, save the values
		const handleUpdateValues = action(() => {
			if (localStore.values === undefined)
				throw new Error('values is undefined')

			// start off with no errors for each type
			localStore.erroredValues = {
				[ErrorType.EmptyValue]: [],
				[ErrorType.NotUnique]: [],
			}
			let errorCount = 0

			localStore.erroredValues

			// loop through all the values (rows)
			for (const value of localStore.values) {
				const valueObject = value as Record<string, unknown> // so we can access the column key
				const valueIndex = localStore.values.indexOf(value)

				// loop through each column for the value (row)
				// if the specific cell is empty we want to display an error
				for (const column of columns)
					if (valueObject[column.key] === '') {
						localStore.erroredValues[ErrorType.EmptyValue].push({
							index: valueIndex,
							columnKey: column.key,
						})
						errorCount++
					}

					// we want to make sure all cell values are unique for their column
					else if (
						localStore.values
							.filter((v) => v !== valueObject)
							.find(
								(v) =>
									(v as Record<string, unknown>)[column.key] ===
									valueObject[column.key],
							)
					) {
						localStore.erroredValues[ErrorType.NotUnique].push({
							index: valueIndex,
							columnKey: column.key,
						})
						errorCount++
					}
			}

			// disable the save button
			localStore.saveEnabled = false

			// we don't want to save the values if there are errors
			if (errorCount > 0) return

			props.onChange(localStore.values)
			toastService.displayToast({ message: 'Values updated', area: 'global' })
		})

		/**
		 * get the error messages and specific columns where the errors are occurring for the value
		 * @param valueIndex the index of the value (row) within the values array
		 * @returns an array of objects with the column key and the error message if there are errors for this value (row)
		 */
		const getErrors = (valueIndex: number): Error[] => {
			const errors = [] as Error[]

			// loop through the error types so we can get specific cells that have errors
			for (const error of Object.entries(localStore.erroredValues)) {
				const errorMessage = error[0] // key - the error type/message (since it's an enum)
				const erroredValues = error[1] // the cells that have this error (index of value + column key)

				// get any errors for each cell of this value/row
				for (const value of erroredValues.filter((v) => v.index === valueIndex))
					errors.push({
						columnKey: value.columnKey,
						errorMessage: errorMessage,
					})
			}

			return errors
		}

		return (
			<div className={styles.root}>
				<Button
					variant="contained"
					color="primary"
					onClick={action(() => (localStore.dialogOpen = true))}
				>
					Configure a Static Data Provider
				</Button>
				<Dialog
					fullWidth
					open={localStore.dialogOpen}
					onClose={action(() => (localStore.dialogOpen = false))}
				>
					<DialogTitle>Configure a Static Data Provider</DialogTitle>
					<DialogContent>
						<Table>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell key={column.key}>{column.columnName}</TableCell>
									))}
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{localStore.values !== undefined &&
									Object.entries(localStore.values).map(([key, value]) => {
										// get any errors for the current row
										const index = localStore.values!.indexOf(value)
										const errors = getErrors(index)

										return (
											<StaticDataProviderRow
												key={key}
												columns={columns}
												value={value}
												onChangeValue={(newValue: T) =>
													handleValueChange(value, newValue)
												}
												onDeleteValue={handleDeleteValue}
												errors={errors}
											/>
										)
									})}
							</TableBody>
						</Table>
					</DialogContent>
					<DialogActions className={styles.dialogActions}>
						<Button
							onClick={handleCreateRow}
							color="secondary"
							variant="outlined"
						>
							Create Row
						</Button>
						<div>
							<Button onClick={action(() => (localStore.dialogOpen = false))}>
								Cancel
							</Button>
							<Button
								onClick={handleUpdateValues}
								color="primary"
								variant="contained"
								disabled={!localStore.saveEnabled}
							>
								Save Values
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			</div>
		)
	},
)

type StaticDataProviderRowProps<T> = {
	columns: ColumnConfiguration[]
	value: T
	errors: Error[]
	onChangeValue: (newValue: T) => void
	onDeleteValue: (value: T) => void
}

const StaticDataProviderRow = observer(
	<T,>(props: StaticDataProviderRowProps<T>) => {
		// this makes indexing based on the column key possible, we'll convert back to T when we update the value
		const valueObject = props.value as Record<string, unknown>

		// get specific error message for cell
		const getErrorMessage = (columnKey: string) => {
			const error = props.errors.find((v) => v.columnKey === columnKey)
			return error?.errorMessage
		}

		return (
			<TableRow>
				{props.columns.map((column) => (
					<TableCell key={column.key}>
						<SingleDataProviderCell
							column={column}
							value={valueObject[column.key]}
							onChange={action((newValue) => {
								valueObject[column.key] = newValue
								props.onChangeValue(valueObject as T)
							})}
							errorMessage={getErrorMessage(column.key)}
						/>
					</TableCell>
				))}
				<TableCell>
					<IconButton onClick={() => props.onDeleteValue(valueObject as T)}>
						<Clear />
					</IconButton>
				</TableCell>
			</TableRow>
		)
	},
)

type SingleDataProviderCellProps = {
	column: ColumnConfiguration
	value: unknown
	errorMessage?: string
	onChange: (newValue: unknown) => void
}

const SingleDataProviderCell = observer(
	({ column, value, errorMessage, onChange }: SingleDataProviderCellProps) => {
		if (column.columnType === ColumnType.String)
			return (
				<TextField
					value={value}
					onChange={(evt) => onChange(evt.currentTarget.value)}
					error={errorMessage !== undefined}
					helperText={errorMessage}
				/>
			)
		else if (column.columnType === ColumnType.Number)
			return (
				<TextField
					type="number"
					value={value}
					onChange={(evt) => onChange(evt.currentTarget.value)}
				/>
			)
		else if (column.columnType === ColumnType.Boolean)
			return (
				<Checkbox
					value={value}
					onChange={(evt) => onChange(evt.currentTarget.value)}
				/>
			)

		throw new Error(`could not map type ${column.columnType} to component`)
	},
)

function createRow(columns: ColumnConfiguration[]) {
	const value: Record<string, unknown> = {}

	for (const column of columns) {
		if (column.columnType === ColumnType.String) value[column.key] = ''
		else if (column.columnType === ColumnType.Boolean) value[column.key] = false
		else if (column.columnType === ColumnType.Number) value[column.key] = 0
	}

	return value
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flex: 'auto',
	},

	dialogActions: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: theme.spacing(3),
	},
}))

export default StaticDataProviderPropertyConfigurator
