import {
	Button,
	Divider,
	Fade,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { percent } from 'csx'
import { useFormik } from 'formik'
import { action, runInAction } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import * as yup from 'yup'
import {
	CreateOrganizationPropertyModel,
	OrganizationPropertiesClient,
	OrganizationPropertyListItem,
	OrganizationPropertyModel,
	OrganizationPropertyType,
} from '../../../../api/clients/identity/OrganizationPropertiesClient'
import { FullscreenSpinner } from '../../../../components/feedback/circular'
import { useModals } from '../../../../services/notifications/ModalService'
import { toastService } from '../../../../services/notifications/ToastService'
import { FixedHeaderWithHint } from '../../../../utils/HOC/FixedHeaders'
import AdministrationPageContainer from '../AdministrationPageContainer'
import {
	EditOrganizationPropertyListDialog,
	OrganizationPropertyTableSection,
} from './OrganizationPropertyTableSection'

export const OrganizationPropertyPage = observer(() => {
	const params = useParams<{ propertyId: string }>()
	const propertyId = parseInt(params.propertyId)

	if (isNaN(propertyId)) throw new Error('invalid property id')

	const styles = useStyles()
	const modalService = useModals()

	const organizationPropertiesClient = new OrganizationPropertiesClient()

	const localStore = useLocalObservable(() => ({
		organizationProperty: undefined as OrganizationPropertyModel | undefined,
		upperOrganizationPropertyNames: [] as string[],
	}))

	useEffect(() => {
		fetchProperty()
		fetchPropertyNames()
	}, [propertyId])

	const fetchProperty = async () => {
		const { data: property } = await organizationPropertiesClient.GetProperty(
			propertyId,
		)

		runInAction(() => {
			localStore.organizationProperty = property
		})
	}

	const fetchPropertyNames = async () => {
		const { data: properties } =
			await organizationPropertiesClient.GetOrganizationProperties()

		runInAction(() => {
			localStore.upperOrganizationPropertyNames = properties.map((v) =>
				v.name.toUpperCase(),
			)
		})
	}

	const handleUpdateProperty = async (
		property: CreateOrganizationPropertyModel,
	) => {
		try {
			const { data: updatedProperty } =
				await organizationPropertiesClient.UpdateOrganizationProperty(
					propertyId,
					property,
				)

			runInAction(() => {
				localStore.organizationProperty = updatedProperty
			})

			toastService.displayToast({
				message: 'Organization property updated',
				area: 'global',
			})
		} catch (e) {
			console.log(e)

			toastService.displayToast({
				message: 'Error updating organization property',
				area: 'global',
			})
		}
	}

	const handleEditListItem = (
		oldListItem: OrganizationPropertyListItem,
		updatedListItem: OrganizationPropertyListItem,
	) => {
		if (
			localStore.organizationProperty === undefined ||
			localStore.organizationProperty.value?.type !==
				OrganizationPropertyType.List
		)
			return

		const innerList = localStore.organizationProperty.value.value
		const index = innerList.indexOf(oldListItem)
		if (index < 0) throw new Error('could not find list item to update')

		innerList[index] = updatedListItem

		handleUpdateProperty({
			name: localStore.organizationProperty.name,
			value: { type: OrganizationPropertyType.List, value: innerList },
		})
	}

	const handleDeleteListItem = (listItem: OrganizationPropertyListItem) => {
		if (
			localStore.organizationProperty === undefined ||
			localStore.organizationProperty.value?.type !==
				OrganizationPropertyType.List
		)
			return

		const innerList = localStore.organizationProperty.value.value

		const index = innerList.indexOf(listItem)
		if (index < 0) return

		innerList.splice(index, 1)

		handleUpdateProperty({
			name: localStore.organizationProperty.name,
			value: { type: OrganizationPropertyType.List, value: innerList },
		})
	}

	if (
		localStore.organizationProperty === undefined ||
		localStore.organizationProperty.value === undefined
	)
		return <FullscreenSpinner />

	return (
		<AdministrationPageContainer
			title={localStore.organizationProperty.name}
			actions={
				localStore.organizationProperty.value.type ===
					OrganizationPropertyType.List && (
					<div>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								modalService
									.showForm((props) => (
										<EditOrganizationPropertyListDialog
											initialLabel=""
											initialValue=""
											otherListItems={
												(localStore.organizationProperty?.value
													?.value as OrganizationPropertyListItem[]) ?? []
											}
											onConfirm={(v) =>
												props.close({
													closeResult: 'okay',
													value: v,
												})
											}
											onCancel={() =>
												props.close({
													closeResult: 'cancel',
												})
											}
										/>
									))
									.then(
										action((v) => {
											if (v.closeResult === 'okay') {
												if (localStore.organizationProperty === undefined)
													throw new Error(
														`organization property with id ${propertyId} is undefined`,
													)

												handleUpdateProperty({
													name: localStore.organizationProperty.name,
													value: {
														type: OrganizationPropertyType.List,
														value: [
															...((localStore.organizationProperty.value
																?.value as OrganizationPropertyListItem[]) ??
																[]),
															v.value as OrganizationPropertyListItem,
														],
													},
												})
											}
										}),
									)
							}}
						>
							Add List Item
						</Button>
					</div>
				)
			}
		>
			<div className={styles.organizationPropertyRoot}>
				<OrganizationPropertyOptionsSection
					property={localStore.organizationProperty}
					upperPropertyNames={localStore.upperOrganizationPropertyNames}
					onUpdateProperty={handleUpdateProperty}
					stringValue={
						localStore.organizationProperty.value.type ===
						OrganizationPropertyType.String
							? localStore.organizationProperty.value.value
							: undefined
					}
				/>
				{localStore.organizationProperty.value.type ===
					OrganizationPropertyType.List && (
					<OrganizationPropertyTableSection
						propertyName={localStore.organizationProperty.name}
						listItems={localStore.organizationProperty.value.value}
						onUpdateListItem={handleEditListItem}
						onDeleteListItem={handleDeleteListItem}
					/>
				)}
			</div>
		</AdministrationPageContainer>
	)
})

type OrganizationPropertyOptionsSectionProps = {
	property: OrganizationPropertyModel
	upperPropertyNames: string[] // make sure we don't get duplicate names
	stringValue?: string // only here if type of value is string!
	onUpdateProperty: (property: CreateOrganizationPropertyModel) => Promise<void>
}

export const OrganizationPropertyOptionsSection = (
	props: OrganizationPropertyOptionsSectionProps,
) => {
	const styles = useStyles()

	const validationSchema = yup.object({
		name: yup
			.string()
			.required('Name is required')
			.test(
				'is-unique',
				'An organization property with this name already exists',
				(value) => {
					return (
						value !== undefined &&
						(value === props.property.name ||
							!props.upperPropertyNames.includes(value.toUpperCase().trim()))
					)
				},
			),
		value: yup.string().required('Value is required'),
	})

	const formik = useFormik({
		initialValues: {
			name: props.property.name,
			value: props.stringValue,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			if (props.property.value?.type === OrganizationPropertyType.String)
				// how many times can i use the word value
				return props.onUpdateProperty({
					name: values.name,
					value: {
						type: OrganizationPropertyType.String,
						value: values.value ?? '',
					},
				})
			else {
				props.onUpdateProperty({
					name: values.name,
					value: props.property.value,
				})
			}
		},
	})

	return (
		<Fade in>
			<Paper className={styles.insetSpacing}>
				<Typography
					className={styles.topBottomContent}
					variant="h6"
					color="textPrimary"
				>
					Settings
				</Typography>
				<Divider />
				<div className={styles.formSettingsRoot}>
					<FixedHeaderWithHint
						label="Name"
						hint={formik.touched.name && formik.errors.name}
						hintColor="error.main"
						className={styles.textField}
					>
						<TextField
							fullWidth
							id="name"
							name="name"
							value={formik.values.name}
							error={!!formik.errors.name && formik.touched.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							disabled={formik.isSubmitting}
							autoComplete="off"
						/>
					</FixedHeaderWithHint>
					{props.stringValue && (
						<FixedHeaderWithHint
							label="Value"
							hint={formik.touched.value && formik.errors.value}
							hintColor="error.main"
							className={styles.textField}
						>
							<TextField
								fullWidth
								id="value"
								name="value"
								value={formik.values.value}
								error={!!formik.errors.value && formik.touched.value}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								disabled={formik.isSubmitting}
								autoComplete="off"
							/>
						</FixedHeaderWithHint>
					)}
				</div>
				<div>
					<Divider />
					<div className={clsx(styles.actionFlexbox, styles.topBottomContent)}>
						<Button
							color="primary"
							variant="contained"
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
							onClick={formik.submitForm}
						>
							Save Changes
						</Button>
					</div>
				</div>
			</Paper>
		</Fade>
	)
}

const useStyles = makeStyles((theme: Theme) => ({
	organizationPropertyRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: percent(100),
		height: percent(100),
	},

	insetSpacing: {
		margin: theme.spacing(3, 0),
		overflow: 'hidden',
	},

	topBottomContent: {
		padding: theme.spacing(2, 2),
	},

	formSettingsRoot: {
		margin: theme.spacing(1, -2),
		padding: theme.spacing(0, 2),
	},

	actionFlexbox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},

	textField: {
		padding: theme.spacing(1, 2),
	},
}))
