import { TextField, TextFieldProps } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment'
import { TypedEditor } from './PropertySchematicConfigurator'

const TimePickerPropertyConfigurator = (
	props: TypedEditor<Moment | undefined>,
) => {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<TimePicker
				value={props.value ?? moment()}
				onChange={(v: Moment | null) => props.onChange(v as Moment)}
				renderInput={(params: TextFieldProps) => (
					<TextField fullWidth {...params} />
				)}
			/>
		</LocalizationProvider>
	)
}

export default TimePickerPropertyConfigurator
